import React from 'react'
import Select from 'react-select'

import {
  formattedCountiesForSelect,
  stateShortToFIPS,
} from '../../../reggora_shared_ui/constants/locations'

export default function CountySelectInput(props) {
  const getCountiesForSelectedState = () => {
    const { stateShort } = props
    const stateFIPS = stateShortToFIPS[stateShort]
    const counties = formattedCountiesForSelect[stateFIPS]
    return counties
  }

  return <Select
    name='selectedCounty'
    valueKey={'countyFIP'}
    labelKey={'countyName'}
    inputClassName='create-loan-select'
    placeholder={'Please select county'}
    options={getCountiesForSelectedState()}
    onChange={val => props.handleSelect('selectedCounty', val ? val.countyFIP : val)}
    value={props.selectedCounty}
    required
  />
}
