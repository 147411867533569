import React, { Component } from 'react'
import Async from 'react-select'
import PropTypes from 'prop-types'

const propTypes = {
  inputClassName: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  required: PropTypes.bool,
  isDisabled: PropTypes.bool,
  clearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
}
const defaultProps = {
  inputClassName: 'select-custom',
  clearable: false,
  isMulti: false,
  isSearchable: false,
}

export default class AsyncSelectInput extends Component {
  getOptions = (inputValue) => {
    if (this.companyTimeout) {
      clearTimeout(this.companyTimeout)
      this.companyTimeout = setTimeout(() => {
        this.props.filterOptions(inputValue)
      }, 200)
    } else {
      this.companyTimeout = setTimeout(() => {
        this.props.filterOptions(inputValue)
      }, 200)
    }
  };

  handleChange = (selectedOption) => {
    this.props.onChange(selectedOption)
  }

  render() {
    const {
      inputClassName,
      placeholder,
      name,
      required,
      value,
      options,
      isDisabled,
      clearable,
      isMulti,
      isSearchable,
      valueKey,
      labelKey,
      multi,
      size,
      optionComponent,
      optionRenderer,
      valueComponent,
      removeSelected,
      noResultsText,
    } = this.props
    return (
      <Async
        name={name}
        className={inputClassName}
        placeholder={placeholder}
        value={value}
        options={options}
        onInputChange={this.getOptions}
        onChange={this.handleChange}
        required={required}
        isDisabled={isDisabled}
        clearable={clearable}
        isMulti={isMulti}
        isSearchable={isSearchable}
        valueKey={valueKey}
        labelKey={labelKey}
        multi={multi}
        size={size}
        optionComponent={optionComponent}
        optionRenderer={optionRenderer}
        valueComponent={valueComponent}
        removeSelected={removeSelected}
        noResultsText={noResultsText}
      />
    )
  }
}

AsyncSelectInput.propTypes = propTypes
AsyncSelectInput.defaultProps = defaultProps
