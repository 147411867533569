import React, { Fragment } from 'react'
import { ModalHeader, ModalBody, Col, ModalFooter, Button, Row } from 'reactstrap'
import { checkTableData } from '../../common/utils/helpers'
import moment from 'moment'
import Loader from '../../common/layout/components/Loader'
import Rephrase from '../../common/utils/Rephrase'

export default function ModalConfirmLoanFileChanges(props) {
  let readable_due_date
  let old_readable_due_date
  let old_readable_products
  let new_readable_products
  const loading = props.loanUpdate.isLoading

  if (props.loanUpdate && props.loanUpdate.data && props.loanUpdate.data.due_date_penalty) {
    old_readable_due_date = checkTableData(props.order_due_date)
    readable_due_date = checkTableData(moment(props.order_due_date).add(props.loanUpdate.data.due_date_penalty, 'days'))
  }

  if (props.loanUpdate && props.loanUpdate.data && props.loanUpdate.data.product) {
    old_readable_products = props.loanUpdate.data.product.old.map(i => i.description).join(', ')
    new_readable_products = props.loanUpdate.data.product.new.map(i => i.description).join(', ')
  }

  return (
    <Fragment>
      <ModalHeader toggle={props.onHide}>Confirm Changes</ModalHeader>
      <ModalBody>
        <Row>
          {loading ? <div className='m-5'><Loader /></div> : <Col xs='12'>
            <Rephrase>
              The edits you have made to this loan file will affect this order.
               We will automatically inform the appraiser of the changes to this loan file.
            </Rephrase>

            <div className='my-2'>
              { props.loanUpdate && props.loanUpdate.data && props.loanUpdate.data.product &&
                <div>One of the changes you made caused
                  different products to be matched. The products of the order will be changing from <b>{
                  old_readable_products
                }</b> to <b>{new_readable_products}</b>.</div>}
            </div>

            <div className='my-2'>
              { props.loanUpdate.data.fee && <div>One of the changes you made caused a fee recalculation.
                  The fee of the order is changing from <b>${props.loanUpdate.data.fee.old}</b> to <b>${
                props.loanUpdate.data.fee.new
              }</b>.</div>}
            </div>
            <div className='my-2'>
              { props.loanUpdate && props.loanUpdate.data && props.loanUpdate.data.due_date_penalty > 0 &&
                <div><Rephrase>An appraiser has already started on this order,
                  so we are going to need to give them some extra time to consider the changes you have requested.
                  The due date will be moved from <b>{
                  old_readable_due_date
                }</b> to <b>{readable_due_date}</b>.</Rephrase></div>}
            </div>
          </Col> }
        </Row>
      </ModalBody>
      <ModalFooter className="border-0">
        <Button onClick={props.onHide}>Cancel</Button>
        <Button onClick={props.onSubmit} color='primary'>Confirm</Button>
      </ModalFooter>
    </Fragment>
  )
}
