import { of } from 'rxjs/observable/of'

import {
  UPDATE_RESOURCE
} from '../../common/utils/resource'
import {
  setResourceDataFromSocket
} from '../../common/socket/notificationHandler'

export default function updateResource(action$, store) {
  return action$.ofType(UPDATE_RESOURCE).mergeMap(action => {
    const {
      objectId,
      namespace,
      update
    } = action
    return of(setResourceDataFromSocket(namespace, objectId, update))
  })
}
