import React, { useState } from 'react'
import { Alert, Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap'
import { CloseSquareOutlined } from '@ant-design/icons'
import { compose } from 'redux'
import { connectResource } from '../../common/utils/resource'
import { connect } from 'react-redux'
import { apiToReadable } from '../../common/utils/helpers'

const priority_to_color_map = {
  1: 'warning',
  2: 'warning',
  3: 'danger',
}

const IndividualOrderAlert = ({
  integrationLog,
  integrationUpdate,
  integrationRetry,
  refreshIntegrationLogs,
  orderData,
}) => {
  const [isPopoversVisible, setIsPopoversVisible] = useState(false)

  const handleIntegrationRetry = async () => {
    integrationRetry.post().then(_ => {
      refreshIntegrationLogs()
    })
  }

  const handleTogglePopovers = () => setIsPopoversVisible(!isPopoversVisible)

  const handleIntegrationUpdate = async () => {
    const updateRequest = { ...integrationLog }
    if (integrationLog && integrationLog.status) {
      updateRequest.status = 'hidden'
    }
    integrationUpdate.put(updateRequest).then(_ => refreshIntegrationLogs())
  }

  const isActiveCompanyMatch = orderData &&
  orderData.assignment_list &&
  orderData.assignment_list.find(request => request.company.id === integrationLog.active_company_id)

  const alertMessage = 'Cannot retry because this integration is no longer active.'

  return (
    <Alert
      key={integrationLog.id}
      color={priority_to_color_map[integrationLog.priority]}
    >
      <>
        <CloseSquareOutlined
          color="gray"
          id={`integration-log-${integrationLog.id}`}
          className="flag-item-anticon mt-1 hide-integration-button"
          onClick={handleTogglePopovers}
        />
        <Popover
          placement="top"
          isOpen={isPopoversVisible}
          target={`integration-log-${integrationLog.id}`}
          toggle={handleTogglePopovers}
        >
          <PopoverHeader>Dismiss log</PopoverHeader>
          <PopoverBody>
            <p>
              This action cannot be undone. Please confirm your intention.
            </p>
            <div className="hide-buttons-container">
              <Button
                color="=info"
                className="order-header-button"
                onClick={handleTogglePopovers}
              >
              No
              </Button>
              <Button
                color="warning"
                className="order-header-button"
                onClick={handleIntegrationUpdate}
              >
              Yes
              </Button>

            </div>
          </PopoverBody>
        </Popover>
        <p>
          <b>{apiToReadable(integrationLog.type)}</b> action failed in{' '}
          {apiToReadable(integrationLog.integration_type)}
        </p>
        {integrationLog.last_attempt &&
    integrationLog.last_attempt.error_message_readable && (
          <p>
        Message: &quot;
            {integrationLog.last_attempt.error_message_readable}
        &quot;
          </p>
        )}
        {!isActiveCompanyMatch && <p>{alertMessage}</p>}
        <Button
          color="dark"
          className="order-header-button"
          onClick={handleIntegrationRetry}
          disabled={!isActiveCompanyMatch}
        >
          Retry
        </Button>
      </>
    </Alert>
  )
}


const mapStateToProps = (state, props) => ({
  integration_log_id: props.integrationLogId,
})

export default compose(
  connect(mapStateToProps),
  connectResource({
    prefetch: false,
    namespace: 'integrationRetry',
    endpoint: 'integration-log/:integration_log_id/retry',
    successMessage: {
      POST: 'Integration action retried.',
    },
    apiVersion: 2,
  }),
  connectResource({
    prefetch: false,
    namespace: 'integrationUpdate',
    endpoint: 'integration-log/:integration_log_id',
    successMessage: {
      PUT: 'Integration action updated',
    },
    apiVersion: 2,
  }),
)(IndividualOrderAlert)
