import React from 'react'
import PropTypes from 'prop-types'

import { Select } from 'antd'

const { Option } = Select


const RecipientDropdown = ({
  userCanApproveMessage,
  handleRecipientChange,
  recipient,
  conversationCompany,
}) => {
  /** RecipientDropdown renders Recipient dropdown for new messages */
  const options = []
  options.push({ value: 'vendor', label: userCanApproveMessage ? 'Vendor Only' : 'Vendor' })
  options.push({ value: 'internal', label: 'Internal Only' })
  if (userCanApproveMessage) {
    options.push({ value: 'everyone', label: 'Everyone' })
  }
  return (
    <Select
      className='mr-2 input-select'
      value={recipient}
      placeholder="Select Recipient"
      onChange={handleRecipientChange}
    >
      {
        options.map((option, i) => <Option key={i} value={option.value}>{option.label}</Option>)
      }
    </Select>

  )
}


RecipientDropdown.propTypes = {
  handleRecipientChange: PropTypes.func,
  userCanApproveMessage: PropTypes.bool,
  recipient: PropTypes.string,
}

export default RecipientDropdown
