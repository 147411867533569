import React, { Component } from 'react'
import { Motion, spring, presets } from 'react-motion'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Rephrase from '../../../utils/Rephrase'

import { handleErrors, handleSuccess } from '../../../utils/resource'

class StatusMessage extends Component {
    state = {
      visibleStatus: [],
    };

    interval = null;

    componentDidMount() {
      this.interval = setInterval(this.secondCheck, 1000)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      const {
        errors,
        success,
        actions,
      } = this.props

      if (errors !== nextProps.errors && nextProps.errors.length) {
        let handledErrors = 0
        nextProps.errors.forEach(err => {
          let msg = 'We are sorry but we could not complete this action. If this problem persists, please contact us.'
          if (err.error.error) {
            msg = err.error.error
          }

          this.setState(prevState => {
            return {
              visibleStatus: [
                ...prevState.visibleStatus,
                {
                  message: msg,
                  remainingDuration: 5,
                  type: 'danger',
                },
              ],
            }
          })


          handledErrors++
          if (handledErrors === nextProps.errors.length) {
            actions.handleErrors()
          }
        })
      }

      if (success !== nextProps.success && nextProps.success.length) {
        let handledSuccess = 0
        nextProps.success.forEach(status => {
          let msg = 'Success.'
          if (status.message) {
            msg = status.message
          }
          this.setState(prevState => {
            return {
              visibleStatus: [
                ...prevState.visibleStatus,
                {
                  message: msg,
                  remainingDuration: 5,
                  type: 'success',
                },
              ],
            }
          })
          handledSuccess++
          if (handledSuccess === nextProps.success.length) {
            actions.handleSuccess()
          }
        })
      }
    }

    componentWillUnmount() {
      clearInterval(this.interval)
    }

    secondCheck = () => {
      const {
        visibleStatus,
      } = this.state
      if (visibleStatus.length === 0) {
        return
      }
      const newStatus = []
      visibleStatus.forEach(status => {
        const newDuration = status.remainingDuration - 1
        if (newDuration > 0) {
          newStatus.push({
            message: status.message,
            remainingDuration: newDuration,
            type: status.type,
          })
        }
      })
      this.setState({
        visibleStatus: newStatus,
      })
    }

    render() {
      const {
        visibleStatus,
      } = this.state
      return (<div className='status-notification'>
        {visibleStatus.map((status, key) => (
          <Motion key={key} defaultStyle={{ x: 250 }} style={{ x: spring(-30, presets.gentle) }}>
            {({ x }) =>
              <div className='status-notification-notice text-muted' style={{
                WebkitTransform: `translate3d(${x}px, 0, 0)`,
                transform: `translate3d(${x}px, 0, 0)`,
              }}>
                {
                  status.type === 'danger'
                    ? <i style={{ fontSize: 30, paddingRight: '3%', color: 'red' }} className="fas fa-times-circle"/>
                    : <i style={{ fontSize: 30, paddingRight: '3%', color: 'green' }} className="fas fa-check-circle"/>
                }
                <Rephrase>{status.message}</Rephrase>
              </div>
            }
          </Motion>
        ))}
      </div>)
    }
}

function mapStateToProps(state) {
  return {
    errors: state.resource.error,
    success: state.resource.successMessage,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      handleErrors,
      handleSuccess,
    }, dispatch),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(StatusMessage)
