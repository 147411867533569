export const SENT_FIELD = 'submission_sent_to_consumer'
const DENIED_FIELD = 'denied_electronic_consent'
const CONSENT_FIELD = 'electronic_consent'
const DOWNLOADED_FIELD = 'submission_downloaded'
const MAILED_FIELD = 'mailed_by_info_list'

// fields on order that hold delivery related timestamps
export const DELIVERY_TIMESTAMP_FIELDS = {SENT_FIELD, DENIED_FIELD, CONSENT_FIELD, DOWNLOADED_FIELD, MAILED_FIELD}

// delivery fields to the readable label we display to the users
export const DELIVERY_FIELD_TO_LABEL = {
  [SENT_FIELD]: 'E-submission Sent',
  [DENIED_FIELD]: 'Denied Electronic Consent',
  [CONSENT_FIELD]: 'Electronic Consent Accepted',
  [DOWNLOADED_FIELD]: 'Submission Downloaded',
  [MAILED_FIELD]: 'Mailed Date',
}

export const CONSUMER_ROLES = [
  { name: 'Borrower' },
  { name: 'Coborrower' },
  { name: 'Buyer Broker' },
  { name: 'Listing Broker' },
  { name: 'Other' },
]
