import React from 'react'
import PaymentsReceived from '../payments/PaymentsReceived'
import AccountsPayable from '../payments/AccountsPayable'
import PaymentSummary from '../payments/PaymentSummary'
import { checkPermission, orderHasCompanyDirectPayment } from '../../common/utils/helpers'
import { Alert } from 'reactstrap'

const PaymentsTab = (props) => {
  const {
    orderData,
    currentUserID,
    loanData,
    refreshOrder,
    use_up_to_amount,
    currentUser,
    address,
    is_amc_lender,
    lenderData,
  } = props

  const isEligibleForReassignmentBanner = (order) => {
    const orderAvailableStatusKeys = order.available_statuses.map(orderStatus => orderStatus.statusKey)
    const currentStatusIndex = orderAvailableStatusKeys.findIndex(orderStatus => orderStatus === order.statusKey)
    const inspectionCompletedStatusIndex = orderAvailableStatusKeys.findIndex(orderStatus => orderStatus === 'inspection_completed')
    const inspectionIsNotCompleted = currentStatusIndex < inspectionCompletedStatusIndex
    const acceptedCompanyIsAMC = order.accepted && order.accepted.is_amc
    const hasConsumerPayment = order.schedule && order.schedule.payments && order.schedule.payments.length > 0

    return checkPermission('order_reassign') && acceptedCompanyIsAMC && hasConsumerPayment && inspectionIsNotCompleted && !orderHasCompanyDirectPayment(order)
  }

  const newReassignmentProcessMessage = 'You don\'t need to refund the payment before reassigning at this time. Simply go to Order Actions and select Reassign.'


  return (
    <>
      {isEligibleForReassignmentBanner(orderData) && (
        <Alert color='primary'>
          <h6><strong>New Reassignment Process</strong></h6>
          <p style={{ marginBottom: 0 }}>
            {newReassignmentProcessMessage}
          </p>
        </Alert>
      )}
      {checkPermission('payments_summary_view') && (
        <PaymentSummary
          orderData={orderData}
          disabled={!orderData}
          isAmcLender={is_amc_lender}
        />
      )}
      {checkPermission('payments_received_view') && (
        <PaymentsReceived
          currentUserId={currentUserID}
          loanData={loanData}
          orderData={orderData}
          orderId={orderData.id}
          refreshOrder={refreshOrder}
          use_up_to_amount={use_up_to_amount}
          disabled={!orderData}
          currentUser={currentUser}
        />
      )}
      {
        checkPermission('payments_accounts_payable_view') &&
        (orderData.job_type !== 'amc' || is_amc_lender) &&
        (
          <AccountsPayable
            id={orderData.id}
            orderData={orderData}
            address={address}
            refreshOrder={refreshOrder}
            is_amc_lender={is_amc_lender}
            lenderData={lenderData}
            currentUser={currentUser}
          />
        )
      }
    </>
  )
}

export default PaymentsTab
