import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from 'antd'
import { selectReviewNotesByVersion } from '../../../../../store/reducers/submissionReviews/submissionReviewsSelectors'
import { toggleReviewNotes } from '../../../../../store/reducers/submissionReviews/submissionReviewsSlice'

export const ReviewNotesButton = (props) => {
  const [visible, setVisible] = useState(false)
  const dispatch = useDispatch()

  const reviewNotes = useSelector((state) =>
    selectReviewNotesByVersion(state, props.orderId, props.submissionVersion))

  useEffect(() => {
    if (reviewNotes?.hasNotes) {
      setVisible(true)
    } else {
      setVisible(false)
    }
  }, [reviewNotes])

  const toggleNotes = () => {
    dispatch(toggleReviewNotes({ orderId: props.orderId, submissionVersion: props.submissionVersion }))
  }

  if (!visible) {
    return (
      <></>
    )
  }

  return (
    <>
      <Button className='ml-auto' onClick={() => toggleNotes()}>Review Notes</Button>
    </>
  )
}
