import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { setOnlyErrors, setSuccessMessage } from '../../common/utils/resource'
import API from '../../api'

export const useApiRequest = () => {
  const dispatch = useDispatch()
  return useCallback(async ({
    endpoint,
    apiVersion = 2,
    method,
    params = {},
    body = {},
    successMessage,
  }) => {
    try {
      const response = await API(endpoint, apiVersion).request(
        method,
        params,
        body,
      )

      if (successMessage) {
        dispatch(setSuccessMessage(successMessage))
      }

      return response
    } catch (error) {
      dispatch(setOnlyErrors(error))
      throw error
    }
  }, [dispatch])
}
