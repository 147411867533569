import React from 'react'
import { Button } from 'reactstrap'
import { Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import ModalTrigger from '../../common/modals/ModalTrigger'
import ModalCreateOrderContainer from './ModalCreateOrderContainer'
import { checkPermission } from '../../common/utils/helpers'

const EditOrderButton = (props) => {
  const { loanData, orderData, refreshOrder, history, flags } = props

  const { preventOrderEditAfterSubmission } = flags || {}
  const isPreventOrderEdit =
    !checkPermission('order_edit_post_submission') &&
    preventOrderEditAfterSubmission &&
    orderData &&
    ['submitted', 'amp_review', 'under_review'].includes(orderData.statusKey)

  const isDisabled = () => {
    return (
      !orderData ||
      orderData.lender_canceled ||
      !orderData.requested ||
      isPreventOrderEdit
    )
  }

  return (
    <ModalTrigger
      selectedLoan={loanData}
      orderData={orderData}
      component={ModalCreateOrderContainer}
      modalClassName="modal-primary modal-xl"
      backdrop="static"
      consumer_payment_style={loanData.consumer_payment_style}
      refreshOrder={refreshOrder}
      history={history}
    >
      <div className="d-flex">
        <Button
          color="dark"
          disabled={isDisabled()}
          className={`ml-2 order-header-button btn ${isPreventOrderEdit ? '' : 'order-action-buttons-padding'}`}
        >
          Edit Order
        </Button>
        {isPreventOrderEdit &&
          <Tooltip
            title="Submission lock: Orders cannot be edited post appraiser submission. You can initiate a new order on the loan by creating a follow up order."
          >
            <InfoCircleOutlined className="ml-1 mr-1" />
          </Tooltip>
        }
      </div>
    </ModalTrigger>
  )
}

export default EditOrderButton
