import React, { Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import Loader from '../layout/components/Loader'
import { CheckAccess } from '../session'
import { getRedirectLink } from '../utils/helpers'

export default function RouteRecursive({
  access,
  layout: Layout,
  component: Component,
  routes,
  redirectTo,
  ...route
}) {
  let renderRoute = null
  if (routes && routes.length > 0) {
    // eslint-disable-next-line react/display-name
    renderRoute = function(props) {
      return (
        <Suspense fallback={<Loader />}>
          <Switch>
            {routes.map((r, i) => {
              return (
                <RouteRecursive
                  key={i}
                  {...r}
                  path={relativePath(route.path, r.path)}
                />
              )
            })}
            {// fallback
              Component ? (
                <Route>
                  <Component {...props} />
                </Route>
              ) : (
              // :''
                <Redirect to="/404" />
              )
            }
          </Switch>
        </Suspense>
      )
    }
  }

  if (redirectTo) {
    // eslint-disable-next-line react/display-name
    renderRoute = function(props) {
      let newPath = props.location.pathname
      if (props.location.pathname === '/') {
        newPath = getRedirectLink()
      } else if (newPath.startsWith(props.match.path)) {
        newPath = redirectTo + newPath.substr(props.match.path.length)
      } else {
        newPath = redirectTo
      }
      return <Redirect to={newPath} />
    }
  }

  let rendered = (
    <Route
      key={`${route.url}-${route.key}`}
      {...route}
      component={renderRoute ? null : Component}
      render={renderRoute}
    />
  )

  if (Layout) {
    rendered = <Layout>{rendered}</Layout>
  }

  return (
    <CheckAccess
      {...route}
      fallback={<Redirect to={route.accessRedirectTo || '/auth/login'} />}
    >
      {rendered}
    </CheckAccess>
  )
}

function relativePath(root = '', path = '') {
  return (root + path).split('//').join('/')
}
