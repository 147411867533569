import React, { Fragment } from 'react'
import Loader from '../../common/layout/components/Loader'
import { ModalHeader, ModalBody, ModalFooter, Button, Label, InputGroup, Input, FormGroup, Col, InputGroupAddon } from 'reactstrap'
import Select from 'react-select'


export default function SwitchAccount(props) {
  const loader = props.lenderSelect.isLoading && <Loader/>

  return (
    <Fragment>
      <ModalHeader toggle={props.actions.hideSwitchAccount}>Switch Account</ModalHeader>
      <form onSubmit={props.onSubmit}>
        <ModalBody>
          {loader}
          <FormGroup row className="mt-2 mb-1 px-3">
            <Col>
              <h6>Select Lender</h6>
              <Select
                name='switchLender'
                placeholder={'Select Lender'}
                options={props.lenderSelect.data || []}
                valueKey={'value'}
                labelKey={'name'}
                value={props.selectedLender}
                clearable={false}
                onChange={(selected) => props.handleSelectChange(selected.value)}
              />
              {props.passwordEnabled && <div className='mt-3'>
                <Label>Password</Label>
                <br />
                <InputGroup>
                  <Input autoFocus
                    name="password"
                    type={!props.passwordDisplay ? 'password' : 'text'}
                    placeholder="Password"
                    className="form-control"
                    onChange={props.handleChange}
                    value={props.password}
                  />
                  <InputGroupAddon style={{ marginTop: 7, zIndex: 10 }}>
                    <a style={{ marginLeft: -25 }} onClick={props.handlePasswordDisplay}>
                      { !props.passwordDisplay ? <i className="fas fa-eye"></i> : <i className='fas fa-eye-slash'></i> }
                    </a>
                  </InputGroupAddon>
                </InputGroup>
              </div>}
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="border-0">
          <FormGroup row className="px-3">
            <Button className="mr-2" onClick={props.actions.hideSwitchAccount} >Cancel</Button>
            <Button color='primary'>Submit</Button>
          </FormGroup>
        </ModalFooter>
      </form>
    </Fragment>
  )
}
