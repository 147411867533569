import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap'

class RefreshModal extends Component {
  render() {
    const {
      showRefreshModal,
    } = this.props
    return (<Modal isOpen={showRefreshModal}>
      <ModalHeader>
        New Version of Reggora
      </ModalHeader>
      <ModalBody>
        We just pushed an update to Reggora. Click the button below to reload and get the latest version.
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => window.location.reload(true)}>Reload</Button>
      </ModalFooter>
    </Modal>)
  }
}

function mapStateToProps(state) {
  return {
    showRefreshModal: state.app.showRefreshModal,
  }
}

export default connect(mapStateToProps)(RefreshModal)
