import React, { useState } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  FormGroup,
  Label,
  Col,
  Button,
} from 'reactstrap'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { Input, InputNumber, Select as SelectAntd, Radio, Form } from 'antd'
import classnames from 'classnames'
import Select from 'react-select'

import ModalConfirmation from '../../common/modals/ModalConfirmation'
import { states } from '../../common/utils/helpers'
import Loader from '../../common/layout/components/Loader'

const { TextArea } = Input

const stateOptions = states.map(state => ({ value: state.short, label: state.long }))

const RECONSIDERATION_REASONS = [
  'Appraisal is inaccurate/unsupported',
  'Appraisal is deficient',
  'Appraisal reflects prohibited discriminatory practices',
]

const ROVReasonForm = (props) => {
  return (
    <Form hidden={!props.show} layout='vertical' onFinish={() => props.setCurrentStep(props.includeComparables ? 2 : 3)}>
      <ModalBody>
        <Form.Item
          label='Reason for Reconsideration of Value'
          name='categories'
          rules={[{ required: true, message: 'Please select reason for reconsideration of value.' }]}
          initialValue={props.categories}
        >
          <SelectAntd
            className='w-50'
            style={{ minWidth: 400 }}
            options={RECONSIDERATION_REASONS.map((reason) => ({ label: reason, value: reason }))}
            placeholder='Select...'
            disabled={props.isReadOnly}
            onChange={(value) => props.setCategories(value)}
            mode='multiple'
            value={props.categories} />
        </Form.Item>
        <Form.Item
          label='Explanation for Reconsideration of Value'
          name='reason'
          rules={[{ required: true, message: 'Please provide an explanation for the reconsideration of value.' }]}
          initialValue={props.reason}
        >
          <TextArea
            name='reason'
            value={props.reason}
            onChange={e => props.onTextChange(e)}
            placeholder='Reasoning...'
            disabled={props.isReadOnly} />
        </Form.Item>
        <Form.Item
          className='item__rov-checkbox is-borrower-initated'
          label='Is ROV borrower initiated?'
          name='isBorrowerInitiated'
          style={{ flexDirection: 'row' }}
          rules={[{ required: true, message: "Please check the 'ROV borrower initiated' field." }]}
          initialValue={props.isBorrowerInitiated}
        >
          <Radio.Group
            className='ml-3'
            style={{ marginBottom: '10px' }}
            disabled={props.isReadOnly}
            value={props.isBorrowerInitiated}
            onChange={(e) => props.setIsBorrowerInitiated(e.target.value)}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          className='item__rov-checkbox include-comparables'
          label='Do you want to include comparables?'
          name='includeComparables'
          style={{ flexDirection: 'row' }}
          rules={[{ required: true, message: "Please check the 'include comparables' field." }]}
          initialValue={props.includeComparables}
        >
          <Radio.Group
            className='ml-3'
            style={{ marginBottom: '10px' }}
            disabled={props.isReadOnly}
            value={props.includeComparables}
            onChange={(e) => props.setIncludeComparables(e.target.value)}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
      </ModalBody>
      <ModalFooter>
        <Button onClick={props.onHide}>Cancel</Button>
        <Button htmlType='submit' color='primary'>Next</Button>
      </ModalFooter>
    </Form>
  )
}

const ROVCompsForm = (props) => {
  return (
    <Form hidden={!props.show} layout='vertical' onFinish={() => props.setCurrentStep(3)}>
      <ModalBody>
        <Nav tabs>
          {props.comps.map((comp, idx) => (
            <NavItem key={idx}>
              <NavLink
                className={classnames({ active: props.tab === `comp #${idx + 1}`, nav_flex: true })}
                onClick={() => props.changeTab(`comp #${idx + 1}`)}
                disabled={props.isReadOnly}
              >
                {`comp #${idx + 1}`}
                <CloseOutlined aria-label='close' className='ml-2' onClick={_ => props.onCloseComp(idx)} />
                <Modal isOpen={props.showCloseModal[idx]} >
                  <ModalBody>
                    <ModalConfirmation
                      confirmationText={'Are you sure you want to remove this Comp?'}
                      onConfirm={_ => props.onRemoveComp(idx)}
                      onHide={_ => props.onHideCompModal(idx)}
                      confirmBtn='Delete'
                      dismissBtn='Cancel'
                      modalClassName='modal-primary'
                    />
                  </ModalBody>
                </Modal>
              </NavLink>
            </NavItem>
          ))}
          <NavItem>
            <NavLink
              className={classnames({ nav_flex: true })}
              onClick={props.addComp}
              disabled={props.isReadOnly}
            >
              <PlusOutlined />
              Add Comp
            </NavLink>
          </NavItem>
        </Nav>
        {props.comps.map((comp, idx) => (
          <FormGroup key={idx} className={classnames({ 'd-none': props.tab !== `comp #${idx + 1}`, 'mt-4': true })}>
            <Form.Item
              label='Property Address'
              name={`property_address_${idx}`}
              rules={[{ required: true, message: 'Please enter property address.' }]}
              initialValue={comp.property_address}
            >
              <Input
                name='property_address'
                value={comp.property_address}
                onChange={e => props.onTextChange(e, idx)}
                placeholder='Address'
                disabled={props.isReadOnly}
              />
            </Form.Item>
            <FormGroup row className="mt-3 mb-3">
              <Col xs="4">
                <Label>City</Label>
                <Input
                  name='city'
                  value={comp.city}
                  onChange={e => props.onTextChange(e, idx)}
                  placeholder='City'
                  disabled={props.isReadOnly}
                />
              </Col>
              <Col xs="4">
                <Label>State</Label>
                <Select
                  value={comp.state}
                  onChange={value => props.onSelect(value, idx)}
                  options={stateOptions}
                  isSearchable={true}
                  placeholder='State'
                  disabled={props.isReadOnly}
                />
              </Col>
              <Col xs="4">
                <Label>Zip</Label>
                <Input
                  name='zip'
                  value={comp.zip}
                  onChange={e => props.onTextChange(e, idx)}
                  placeholder='Zip'
                  disabled={props.isReadOnly}
                />
              </Col>
            </FormGroup>
            <FormGroup row className="mt-3 mb-3">
              <Col xs="6">
                <Label>Sales Price</Label>
                <InputNumber
                  name='sales_price'
                  value={comp.sales_price}
                  onChange={value => props.onTextChange({ target: { value, name: 'sales_price' } }, idx)}
                  placeholder='Sales Price'
                  disabled={props.isReadOnly}
                />
              </Col>
              <Col xs="6">
                <Label>Date of Sale</Label>
                <Input
                  name='date_of_sale'
                  value={comp.date_of_sale}
                  onChange={e => props.onTextChange(e, idx)}
                  placeholder='Date of Sale'
                  disabled={props.isReadOnly}
                />
              </Col>
            </FormGroup>
            <FormGroup row className="mt-3 mb-3">
              <Col xs="4">
                <Label>Reported Heated Sq. Footage</Label>
                <InputNumber
                  name='reported_heated_sq_footage'
                  value={comp.reported_heated_sq_footage}
                  onChange={value => props.onTextChange({ target: { value, name: 'reported_heated_sq_footage' } }, idx)}
                  placeholder='Sq. Footage'
                  disabled={props.isReadOnly}
                />
              </Col>
              <Col xs="4">
                <Label>Reported Actual Age</Label>
                <InputNumber
                  name='reported_actual_age'
                  value={comp.reported_actual_age}
                  onChange={value => props.onTextChange({ target: { value, name: 'reported_actual_age' } }, idx)}
                  placeholder='Actual Age'
                  disabled={props.isReadOnly}
                  precision={0}
                />
              </Col>
              <Col xs="4">
                <Label>Reported Site Size</Label>
                <InputNumber
                  name='reported_site_size'
                  value={comp.reported_site_size}
                  onChange={value => props.onTextChange({ target: { value, name: 'reported_site_size' } }, idx)}
                  placeholder='Site Size'
                  disabled={props.isReadOnly}
                />
              </Col>
            </FormGroup>
            <FormGroup row className="mt-3 mb-3">
              <Col xs="12">
                <Label>Source of Data</Label>
                <Input
                  name='source_of_data'
                  value={comp.source_of_data}
                  onChange={e => props.onTextChange(e, idx)}
                  placeholder='Source of Data'
                  disabled={props.isReadOnly}
                />
              </Col>
            </FormGroup>
            <FormGroup row className="mt-3 mb-3">
              <Col xs="12">
                <Label>APN/MLS#</Label>
                <Input
                  name='apn_mls_number'
                  value={comp.apn_mls_number}
                  onChange={e => props.onTextChange(e, idx)}
                  placeholder='APN/MLS#'
                  disabled={props.isReadOnly}
                />
              </Col>
            </FormGroup>
            <FormGroup row className="mt-3 mb-3">
              <Col xs="12">
                <Label>Additional Comments/Data</Label>
                <TextArea
                  name='additional_comments'
                  value={comp.additional_comments}
                  onChange={e => props.onTextChange(e, idx)}
                  placeholder='Additional Comments/Data...'
                  disabled={props.isReadOnly}
                />
              </Col>
            </FormGroup>
          </FormGroup>
        ))}
      </ModalBody>
      <ModalFooter>
        <Button onClick={props.onHide}>Cancel</Button>
        <Button hidden={props.currentStep === 1} onClick={() => props.setCurrentStep(1)}>Back</Button>
        <Button htmlType='submit' color='primary'>Next</Button>
      </ModalFooter>
    </Form>
  )
}

const ROVConfirm = (props) => {
  return (
    <div hidden={!props.show}>
      <ModalBody>
        <div className='container'>
          <h5 className='row'>Are you sure you want to create a reconsideration of value with these attributes?</h5>
          <div className='container'>
            <span className='mt-3 row'>Reason for Reconsideration of Value</span>
            <ul className='mt-1 mb-0'>
              {props.categories.map((category, idx) => (
                <b key={idx}><li>{category}</li></b>
              ))}
            </ul>
            <span className='mt-3 row'>Explanation for Reconsideration of Value</span>
            <b className='row mt-1 ml-4' style={{ whiteSpace: 'pre-wrap' }}>{props.reason}</b>
            <div className='mt-3 row'>
              <span>Is ROV borrower initiated?&nbsp;&nbsp;<b>{props.isBorrowerInitiated ? 'Yes' : 'No'}</b></span>
            </div>
            <div className='mt-3 row'>
              <span>Do you want to include comparables?&nbsp;&nbsp;<b>{props.includeComparables ? 'Yes' : 'No'}</b></span>
            </div>
            <ol hidden={!props.includeComparables} className='mt-1 mb-0'>
              {props.comps.map((comp, idx) => (
                <b key={idx}><li>{comp.property_address}</li></b>
              ))}
            </ol>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button disabled={props.loading} onClick={props.onHide}>Cancel</Button>
        <Button disabled={props.loading} onClick={() => props.setCurrentStep(props.includeComparables ? 2 : 1)}>
          Back
        </Button>
        <Button disabled={props.loading || props.isReadOnly} onClick={props.onSubmit} color='primary'>Confirm</Button>
      </ModalFooter>
    </div>
  )
}

export const ModalROV = props => {
  const [currentStep, setCurrentStep] = useState(1)
  return (
    <div className='rov-modal'>
      <ModalHeader color='primary' toggle={props.onHide}>Reconsideration Of Value</ModalHeader>
      {props.loading && <Loader />}
      {ROVReasonForm({ ...props, show: currentStep === 1, setCurrentStep })}
      {ROVCompsForm({ ...props, show: currentStep === 2, setCurrentStep })}
      {ROVConfirm({ ...props, show: currentStep === 3, setCurrentStep })}
    </div>
  )
}
