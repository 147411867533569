import React, { useEffect, useState } from 'react'
import {
  Tooltip,
  Button as AntButton,
} from 'antd'
import {
  CheckOutlined,
  CloseOutlined,
  StopOutlined,
  UndoOutlined,
} from '@ant-design/icons'

import { useSelector, useDispatch } from 'react-redux'
import { selectCurrentUser } from '../../../../../store/reducers/user/userSelectors'
import { postRuleOverride } from '../../../../../store/reducers/submissionReviews/submissionReviewsSlice'
import { checkPermission } from '../../../../../common/utils/helpers'

export default function ResultTableActions(props) {
  const { fieldData, isPdfView } = props
  const { id, submissionReviewId, isEditable, outcome, engine_outcome } = fieldData
  const [acceptButtonClick, setAcceptButtonClick] = useState(false)
  const [rejectButtonClick, setRejectButtonClick] = useState(false)
  const [naButtonClick, setNaButtonClick] = useState(false)
  const [isPassVisible, setIsPassVisible] = useState(false)
  const [isFailVisible, setIsFailVisible] = useState(false)
  const [isNaVisible, setIsNaVisible] = useState(false)
  const currentUser = useSelector(selectCurrentUser)
  const dispatch = useDispatch()

  const handleButtonClick = (outcome, setButtonState) => {
    dispatch(
      postRuleOverride({
        outcome,
        ruleId: id,
        submissionReviewId: submissionReviewId,
        user_id: currentUser.id,
        user_type: 'LENDER',
      })
    ).then(() => {
      setAcceptButtonClick(false)
      setRejectButtonClick(false)
      setNaButtonClick(false)
      if (setButtonState) {
        setButtonState(true)
      }
    })
  }
  const handleUndo = () => {
    handleButtonClick(engine_outcome, null)
  }
  const handleNaClick = () => {
    handleButtonClick('SKIP', setNaButtonClick)
  }
  const handleRejectClick = () => {
    handleButtonClick('REJECTED', setRejectButtonClick)
  }
  const handleAcceptClick = () => {
    handleButtonClick('ACCEPTED', setAcceptButtonClick)
  }
  const ActionButton = ({ onClick, Icon, iconClass, toolTipLabel, actionLabel }) => (
    <div className={isPdfView ? 'action-label action-label-pdf' : 'action-label'}>
      {toolTipLabel ? (
        <Tooltip placement="topLeft" title={toolTipLabel} arrowPointAtCenter>
          <AntButton onClick={onClick}>
            <Icon className={iconClass} style={{ fontWeight: 'bold' }}/>
          </AntButton>
        </Tooltip>
      ) : (
        <AntButton onClick={onClick}>
          <Icon className={iconClass} style={{ fontWeight: 'bold' }}/>
          {(isPdfView && engine_outcome !== 'MANUAL') && <span style={{ marginLeft: 5 }}>{actionLabel}</span>}
        </AntButton>
      )}
    </div>
  )
  useEffect(() => {
    const passes = ['PASS', 'ACCEPTED', 'MANUAL', 'ERROR']
    const failures = ['SOFT_STOP', 'HARD_STOP', 'NO_STOP', 'REJECTED', 'MANUAL', 'ERROR']
    const currentOutcome = outcome ?? engine_outcome

    if (currentOutcome && isEditable) {
      setIsFailVisible(passes.includes(engine_outcome))
      if (isFailVisible && outcome) {
        if (engine_outcome === 'MANUAL' || engine_outcome === 'ERROR') {
          if (passes.includes(outcome) && outcome !== engine_outcome) {
            setAcceptButtonClick(true)
          }
        } else {
          if (failures.includes(outcome) && outcome !== engine_outcome) {
            setRejectButtonClick(true)
          }
        }
      }
      setIsPassVisible(failures.includes(engine_outcome))
      if (isPassVisible && outcome) {
        if (engine_outcome === 'MANUAL' || engine_outcome === 'ERROR') {
          if (failures.includes(outcome) && outcome !== engine_outcome) {
            setRejectButtonClick(true)
          }
        } else {
          if (passes.includes(outcome) && outcome !== engine_outcome) {
            setAcceptButtonClick(true)
          }
        }
      }
      setIsNaVisible(engine_outcome === 'MANUAL' || engine_outcome === 'ERROR')
      if (isNaVisible && outcome) {
        if ((outcome === 'SKIP' || outcome === 'ERROR') && outcome !== engine_outcome) {
          setNaButtonClick(true)
        }
      }
      if (engine_outcome === 'MANUAL' || engine_outcome === 'ERROR') {
        setIsFailVisible(true)
        setIsPassVisible(true)
        setIsNaVisible(true)
      }
    } else {
      setIsFailVisible(false)
      setIsPassVisible(false)
      setIsNaVisible(false)
    }
  }, [
    outcome,
    engine_outcome,
    isEditable,
    isPassVisible,
    isFailVisible,
    isNaVisible,
  ])

  return (
    <>
      {checkPermission('reggora_review_action') && (
        <div className={isPdfView ? 'action-container-pdf' : 'action-container'}>
          {isPassVisible && (
            acceptButtonClick ? (
              <ActionButton onClick={handleUndo} Icon={UndoOutlined} toolTipLabel="Undo"/>
            ) : (
              <ActionButton onClick={handleAcceptClick} Icon={CheckOutlined} iconClass="accept-check" actionLabel="Accept" />
            )
          )}
          {isFailVisible && (
            rejectButtonClick ? (
              <ActionButton onClick={handleUndo} Icon={UndoOutlined} toolTipLabel="Undo"/>
            ) : (
              <ActionButton onClick={handleRejectClick} Icon={CloseOutlined} iconClass="reject-x" actionLabel="Reject"/>
            )
          )}
          {isNaVisible && (
            naButtonClick ? (
              <ActionButton onClick={handleUndo} Icon={UndoOutlined} toolTipLabel="Undo"/>
            ) : (
              <ActionButton onClick={handleNaClick} Icon={StopOutlined} iconClass="not-applicable" toolTipLabel="N/A"/>
            )
          )}
        </div>
      )}
    </>
  )
}
