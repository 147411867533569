import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'reactstrap'

const propTypes = {
  inputClassName: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  rows: PropTypes.number,
  cols: PropTypes.number,
  name: PropTypes.string,
  value: PropTypes.string,
}
const defaultProps = {
  inputClassName: 'input-custom',
}

export default class TextAreaInput extends Component {
  render() {
    const {
      inputClassName, placeholder, disabled,
      required, rows, cols, name, value,
    } = this.props
    return (
      <Input
        data-testid="textarea"
        name={name}
        className={inputClassName}
        placeholder={placeholder}
        disabled={disabled}
        required={required}
        cols={cols}
        value={value}
        rows={ rows || 9 }
        onChange={(e) => { this.props.onChange(e.target.value) }}
      />
    )
  }
}

TextAreaInput.propTypes = propTypes
TextAreaInput.defaultProps = defaultProps
