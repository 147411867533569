import React, { useState } from 'react'
import { ChatFeed } from 'react-chat-ui'
import NewMessageContainer from './NewMessageContainer'
import UnansweredMessageInfo from './UnansweredMessageInfo'
import ReggoraChatBubble from '../../common/widgets/ReggoraBubble'
import CommentTemplateDropdown from './CommentTemplateDropdown'

const MessagesTab = (props) => {
  const {
    messagesBubbles,
    messages,
    conversationParticipation,
    conversation,
    markReadMessages,
    is_amc_lender,
    isAppraiserMessages,
    subOrderId,
    conversationType,
    newMessageTitle,
    orderData,
  } = props

  const [textToInsert, setTextToInsert] = useState('')
  const onNewMessageFocus = () => markReadMessages(conversation.id, conversationType)
  return (
    <div className="d-flex flex-column align-content-between chat-controls" name={conversationType}>
      <div className="chat-avatars">{messagesBubbles}</div>
      <ChatFeed
        messages={messages}
        chatBubble={ReggoraChatBubble}
        showSenderName={false}
      />
      {conversationParticipation && (
        is_amc_lender && isAppraiserMessages ? (
          <>
            <NewMessageContainer
              conversation_id={conversation.id}
              conversationType={conversationType}
              is_revision={false}
              modal={false}
              subOrderId={subOrderId}
              onFocus={onNewMessageFocus}
              newMessageTitle={newMessageTitle}
              orderData={orderData}
              is_amc_lender={is_amc_lender}
              textToInsert={textToInsert}
              resetTextToInsert={() => setTextToInsert('')}
            />
            <CommentTemplateDropdown
              name={'appraiser-messages-templates-list'}
              onHide={props.onHide}
              refreshOrder={props.refreshOrder}
              setTextToInsert={setTextToInsert}
            />
          </>
        ) : <NewMessageContainer
          conversation_id={conversation.id}
          conversationType={conversationType}
          is_revision={false}
          modal={false}
          subOrderId={subOrderId}
          onFocus={onNewMessageFocus}
          newMessageTitle={newMessageTitle}
          orderData={orderData}
          is_amc_lender={is_amc_lender}
        />
      )}
      {isAppraiserMessages && (
        <UnansweredMessageInfo
          primaryConversation={conversation || {}}
          is_amc_lender={is_amc_lender}
          shouldGetMessages={true}
        />
      )}
    </div>
  )
}

export default MessagesTab
