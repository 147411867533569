import { access } from '../../common/session'
import { loadableWithRetry } from '../../common/utils/lazyLoad'

const routes = [
  {
    path: '/',
    exact: true,
    name: 'users',
    component: loadableWithRetry(() => import('./UsersContainer')),
    access: access.F_ADMINISTRATION,
    accessRedirectTo: '/dashboard',
  },
  {
    path: '/roles',
    name: 'userRoles',
    component: loadableWithRetry(() => import('./UserRolesContainer')),
    access: access.F_ADMINISTRATION,
    accessRedirectTo: '/dashboard',
  },
  {
    path: '/teams',
    name: 'userTeams',
    component: loadableWithRetry(() => import('./UserTeamsContainer')),
    access: access.F_ADMINISTRATION,
    accessRedirectTo: '/dashboard',
  },
  {
    path: '/:id',
    name: 'singleUser',
    component: loadableWithRetry(() => import('./SingleUserContainer')),
    access: access.F_ADMINISTRATION,
    accessRedirectTo: '/dashboard',
  },
]

export default routes
