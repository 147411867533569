import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert } from 'antd'

import {
  countInspectionDetails,
  getInspectionDetail,
  sortLevelsByLevelNumber,
  getValueFromInspectionDetail,
} from '../../../../helpers/inspections'
import {
  fetchInspection,
} from '../../../store/reducers/inspection/inspectionSlice'

import {
  selectInspectionData,
  selectInspectionError,
  selectIsInspectionLoading,
  selectInspectionDetailsData,
  selectInspectionDetailsError,
  selectIsInspectionDetailsLoading,
  selectInspectionDetailsDataMap,
  selectCreateInspectionDetailsError,
  selectUpdateInspectionDetailError,
} from '../../../store/reducers/inspection/inspectionSelectors'

import FloorplanParkingForm from './FloorplanParkingForm'
import FloorplanUploadForm from './FloorplanUploadForm'
import StructureForm from './StructureForm'
import Loader from '../../../common/layout/components/Loader'


const getStructureDetailsWithNumberedLevels = (inspectionDetailsDataMap) => {
  const structures = []
  const structureCount = countInspectionDetails(inspectionDetailsDataMap, 'structure.{counter}.structure_type@setup.0')
  for (let structurePosition = 0; structurePosition < structureCount; structurePosition++) {
    const structure = {
      ...getInspectionDetail(inspectionDetailsDataMap, `structure.${structurePosition}.structure_type@setup.0`),
      levels: [],
      structureLocation: `structure.${structurePosition}`,
    }
    const levelCount = countInspectionDetails(
      inspectionDetailsDataMap,
      `structure.${structurePosition}.structure-level.{counter}.level_type@setup.0`
    )
    for (let levelPosition = 0; levelPosition < levelCount; levelPosition++) {
      const levelTypeInspectionDetail = getInspectionDetail(
        inspectionDetailsDataMap,
        `structure.${structurePosition}.structure-level.${levelPosition}.level_type@setup.0`
      ) || {}
      structure.levels.push(levelTypeInspectionDetail)
    }
    structure.levels = sortLevelsByLevelNumber(structure.levels)
    structures.push(structure)
  }
  return structures
}

const getGarageTypeInspectionDetails = (inspectionDetailsDataMap) => {
  const garages = []
  const garageCount = countInspectionDetails(inspectionDetailsDataMap, 'parking.{counter}.parking_garage_type@setup.0')
  for (let garagePosition = 0; garagePosition < garageCount; garagePosition++) {
    const garageTypeInspectionDetail = getInspectionDetail(
      inspectionDetailsDataMap,
      `parking.${garagePosition}.parking_garage_type@setup.0`
    )
    const garageType = getValueFromInspectionDetail(garageTypeInspectionDetail)
    if (garageType !== 'driveway' && garageType !== 'parking_lot') {
      garages.push(garageTypeInspectionDetail)
    }
  }
  return garages
}

const FloorplanTab = ({ order: { id: orderId } }) => {
  const dispatch = useDispatch()

  const inspectionData = useSelector(selectInspectionData)
  const inspectionError = useSelector(selectInspectionError)
  const isInspectionLoading = useSelector(selectIsInspectionLoading)

  const inspectionDetailsData = useSelector(selectInspectionDetailsData)
  const inspectionDetailsError = useSelector(selectInspectionDetailsError)
  const isInspectionDetailsLoading = useSelector(selectIsInspectionDetailsLoading)

  const inspectionDetailsDataMap = useSelector(selectInspectionDetailsDataMap)
  const structureDetailsWithLevels = getStructureDetailsWithNumberedLevels(inspectionDetailsDataMap)
  const garageTypeInspectionDetails = getGarageTypeInspectionDetails(inspectionDetailsDataMap)

  const createInspectionDetailsError = useSelector(selectCreateInspectionDetailsError)
  const updateInspectionDetailError = useSelector(selectUpdateInspectionDetailError)

  const renderStructures = () => {
    return structureDetailsWithLevels.map((structure) => (
      <StructureForm
        key={structure.structureLocation}
        structure={structure}
      />
    ))
  }

  const renderParking = () => {
    return garageTypeInspectionDetails.map((detail) => (
      <FloorplanParkingForm key={detail.question_location} garageTypeInspectionDetail={detail} />
    ))
  }

  useEffect(() => {
    const fetchInspectionData = async () => {
      await dispatch(fetchInspection(orderId))
    }

    if (orderId) {
      fetchInspectionData()
    }
  }, [dispatch, orderId])

  if (isInspectionLoading || isInspectionDetailsLoading) {
    return <Loader />
  }

  if (!inspectionData._id) {
    return (
      <div>
        <p>No inspection found.</p>
        <p>{inspectionError?.message || ''}</p>
      </div>
    )
  }

  if (!inspectionDetailsData.length) {
    return (
      <div>
        <p>No inspection details found.</p>
        <p>{inspectionDetailsError?.message || ''}</p>
      </div>
    )
  }

  return (
    <div>
      {createInspectionDetailsError ? <Alert type="error" message="Error creating inspection detail" /> : null}
      {updateInspectionDetailError ? <Alert type="error" message="Error updating inspection detail" /> : null}
      <FloorplanUploadForm inspectionId={inspectionData._id} orderId={orderId} />
      {renderStructures()}
      {renderParking()}
    </div>
  )
}

export default FloorplanTab
