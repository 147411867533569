import React, { Component, Fragment } from 'react'
import { ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { Input } from 'antd'
const { TextArea } = Input


class OnHoldModal extends Component {
    state = {
      cancelDisabled: false,
      submitDisabled: true,
      body: '',
    };

    handleChange = (e) => {
      let submitDisabled = true
      if (e.target.value.length > 0) { submitDisabled = false }

      this.setState({
        [e.target.name]: e.target.value,
        submitDisabled,
      })
    }

    onSubmit = () => {
      this.setState({
        cancelDisabled: true,
        submitDisabled: true,
      })

      const onHoldReason = this.state.body
      this.props.updateStatus('Hold', onHoldReason)
      this.props.onHide()
    }

    onCancel = () => {
      this.props.onHide()
    }

    render() {
      const { ...props } = this.props
      const {
        cancelDisabled,
        submitDisabled,
        body,
      } = this.state

      return (
        <Fragment>
          <ModalHeader toggle={props.toggle}>Place Order on Hold</ModalHeader>
          <ModalBody>
            <p>Please provide the reason for placing this order on hold below:</p>
            <TextArea type="text" name="body" value={body} onChange={this.handleChange}/>

          </ModalBody>
          <ModalFooter className="border-0">
            <Button disabled={cancelDisabled} onClick={this.onCancel} color='danger'>Cancel</Button>
            <Button disabled={submitDisabled} type='button' color='success' onClick={this.onSubmit}>Submit</Button>
          </ModalFooter>
        </Fragment>
      )
    }
}

export default OnHoldModal
