import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectOrderAuditLogData,
} from 'app/store/reducers/order/orderSelectors'
import {
  fetchOrderAuditLogData,
} from 'app/store/reducers/order/orderSlice'
import OrderActivityLog from './OrderActivityLog'
import { SmallLoader } from '../../../common/layout/components/Loader'

const OrderActivityLogContainer = ({ orderId }) => {
  const orderAuditLogData = useSelector(selectOrderAuditLogData)
  const [transformedAuditLogData, setTransformedAuditLogData] = useState()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchOrderAuditLogData({
      orderId,
    }))
  }, [orderId, dispatch])

  useEffect(() => {
    if (orderAuditLogData) {
      const transformedData = orderAuditLogData.flatMap(auditLog =>
        auditLog.deltas.map(delta => ({
          ...auditLog,
          ...delta,
        }))
      )
      setTransformedAuditLogData(transformedData)
    }
  }, [orderAuditLogData])

  return (
    <div className="order-activity-log-container">
      { transformedAuditLogData ? (
        <OrderActivityLog auditLogData={transformedAuditLogData}/>
      ) : <SmallLoader/> }
    </div>
  )
}

export default OrderActivityLogContainer
