import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

import Header from './Header'


class HeaderContainer extends Component {
  sidebarToggle = (e) => {
    this.props.toggleSidebar()
  }

  mobileSidebarToggle = (e) => {
    this.props.toggleSidebarMobile()
  }

  render() {
    const { ...props } = this.props
    return <Header
      {...props}
      sidebarToggle={this.sidebarToggle}
      mobileSidebarToggle={this.mobileSidebarToggle}
      toggleMinimizeSidebar={this.props.toggleMinimizeSidebar}
    />
  }
}

export default compose(
  connect((state, props) => {
    return ({
      loanIdForEncompass: state.app.loanIdForEncompass,
    })
  }, null),
)(HeaderContainer)
