import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import SendBorrowerByMail from './SendBorrowerByMailModal'
import { connectResource } from '../../../common/utils/resource'

class SendBorrowerByMailModalContainer extends Component {
  state = {
    selectedConsumers: [],
    mailedDatetime: moment(),
    mailedBy: this.props.fullname,
    isDisabled: true,
  }

  onSubmit = () => {
    const {
      selectedConsumers,
      mailedDatetime,
      mailedBy,
    } = this.state

    const users = selectedConsumers.map((consumer) => consumer.id)
    const data = {
      consumers: users,
      order_id: this.props.orderData.id,
      mailed_by: mailedBy,
      mailed_datetime: mailedDatetime,
      submission_version: this.props.version,
    }
    this.props.mailedByInfo.create(data)
      .then(_ => {
        this.props.onHide()
        this.props.refreshOrder()
      })
  }

  handleChange = (selectedConsumers) => {
    this.setState({ selectedConsumers })
  }

  handleDateChange = (mailedDatetime) => this.setState({ mailedDatetime })

  handleRadio = ({ target: { value, name } }) => this.setState({ [name]: value })

  onChange = (event) => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  render() {
    const { ...props } = this.props
    const {
      selectedConsumers,
    } = this.state

    let consumers = []

    if (props.orderData.schedule && props.orderData.schedule.consumers && props.orderData.schedule.consumers.length) {
      consumers = props.orderData.schedule.consumers.filter(c => !c.hidden)
    }

    return <SendBorrowerByMail
      {...props}
      selectedConsumers={selectedConsumers}
      handleChange={this.handleChange}
      handleRadio={this.handleRadio}
      mailedDateTime={this.state.mailedDatetime}
      mailedBy={this.state.mailedBy}
      handleDateChange={this.handleDateChange}
      consumers={consumers}
      isDisabled={this.isDisabled}
      onSubmit={this.onSubmit}
      onChange={this.onChange}
    />
  }
}

export default compose(
  connect((state, props) => ({
    fullname: state.resource.user.data.firstname + ' ' + state.resource.user.data.lastname,
  })),
  connectResource({
    namespace: 'mailedByInfo',
    endpoint: 'order/create-mailed-by-receipt',
    prefetch: false,
    apiVersion: 2,
    successMessage: { POST: 'sent!' },
  }),

)(SendBorrowerByMailModalContainer)
