import React, { Fragment } from 'react'
import { Field } from 'react-final-form'
import { Col, Row } from 'reactstrap'
import LoanOrderContacts from './LoanOrderContacts'
import { CONSUMER_ROLES } from '../../common/constants/consumer'
import Loader from '../../common/layout/components/Loader'

const NewConsumerForm = (props) => {
  const {
    appraisal_delivery_status_fields_toggles_settings,
    propertyAddress,
    fullSelectedLoan,
    setLoading,
    hasPrimaryContact,
  } = props
  const { data: loanData } = fullSelectedLoan

  const getOptions = () => {
    return CONSUMER_ROLES.map((item, i) => <option key={i}>{item.name}</option>)
  }
  if (fullSelectedLoan.isLoading) {
    return <Loader />
  }

  return (
    <Fragment>
      <Row>
        <span className="primary-contact-header-text">
          Please designate a primary contact to the order by selecting the star
          on any valid contact or providing new contact details.
        </span>
      </Row>
      <Row>
        <Col className="modal-create-order-contacts primary-contact-cards-col">
          <div className="existing-contacts-label">
                    Existing Contacts
          </div>
          {
            fullSelectedLoan.data && fullSelectedLoan.data.consumers.length !== 0 &&
                        <LoanOrderContacts
                          loanData={fullSelectedLoan.data}
                          address={propertyAddress}
                          loan={fullSelectedLoan}
                          appraisal_delivery_status_fields_toggles_settings={
                            appraisal_delivery_status_fields_toggles_settings
                          }
                          setLoader={setLoading}
                          refreshOrder={fullSelectedLoan.fetch}
                          hideFeatures={true}
                        />
          }
          {
            loanData.consumers.length === 0 &&
                        <div className="primary-contact-header-text">
                            There are no existing contacts.
                        </div>
          }
        </Col>
        <Col className="modal-create-order">
          <div className="new-contacts-label">
                    Provide New Contact
          </div>
          <div>
            <label>First Name</label>
            <Field
              name="firstName"
              component="input"
              type="text"
              placeholder="First Name"
              required={!hasPrimaryContact}
            />
          </div>
          <div>
            <label>Last Name</label>
            <Field
              name="lastName"
              component="input"
              type="text"
              placeholder="Last Name"
              required={!hasPrimaryContact}
            />
          </div>
          <div>
            <label>Role</label>
            <Field name="role" component="select" required={!hasPrimaryContact}>
              <option />
              {getOptions()}
            </Field>
          </div>
          <div>
            <label>Email</label>
            <Field
              name="email"
              component="input"
              type="text"
              placeholder="Email"
            />
          </div>
          <div>
            <label>Work Phone</label>
            <Field
              name="workPhone"
              component="input"
              type="text"
              placeholder=""
            />
          </div>
          <div>
            <label>Home Phone</label>
            <Field
              name="homePhone"
              component="input"
              type="text"
              placeholder=""
            />
          </div>
          <div>
            <label>Cell Phone</label>
            <Field
              name="cellPhone"
              component="input"
              type="text"
              placeholder=""
            />
          </div>
        </Col>
      </Row>
    </Fragment>
  )
}

export default NewConsumerForm
