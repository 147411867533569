import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import API, { API_VERSION, REQUEST_METHOD } from '../../../api'
import STATUS from '../../../common/constants/reducer_statuses'

export const initialState = {
  panelistNotes: {
    status: STATUS.IDLE,
    data: null,
    error: null,
  },
  panelistNotesUpdate: {
    status: STATUS.IDLE,
    error: {
      error: null,
    },
  },
  panelistNotesEvault: {
    status: STATUS.IDLE,
    data: null,
    error: null,
  },
}

export const fetchPanelistNotes = createAsyncThunk('panelist/fetchPanelistNotes', async (panelistId, { rejectWithValue }) => {
  try {
    return await API(
            `panelists/v1/${panelistId}/notes`,
            API_VERSION.SERVICE
    ).request(REQUEST_METHOD.GET, {}, {})
  } catch (err) {
    return rejectWithValue(err)
  }
})

export const updatePanelistNotes = createAsyncThunk('panelist/updatePanelistNotes', async (kwargs, { rejectWithValue }) => {
  try {
    return await API(
            `panelists/v1/${kwargs.panelistId}/notes`,
            API_VERSION.SERVICE
    ).request(REQUEST_METHOD.PUT, {}, kwargs.body)
  } catch (err) {
    console.log('error saving panelist notes', err)
    return rejectWithValue(err)
  }
})

export const fetchPanelistNotesEvault = createAsyncThunk('panelist/fetchPanelistNotesEvault', async (evaultId, { rejectWithValue }) => {
  try {
    return await API(
            `panelists/v1/notes/evault/${evaultId}`,
            API_VERSION.SERVICE
    ).request(REQUEST_METHOD.GET, {}, {})
  } catch (err) {
    return rejectWithValue(err)
  }
})

export const deletePanelistNotesEvaultDoc = createAsyncThunk('panelist/deletePanelistNotesEvaultDoc', async (kwargs, { rejectWithValue }) => {
  try {
    return await API(
            `panelists/v1/notes/evault/${kwargs.evaultId}/document/${kwargs.docId}`,
            API_VERSION.SERVICE
    ).request(REQUEST_METHOD.DELETE, {}, {})
  } catch (err) {
    console.log('error deleting panelist notes evault doc', err)
    return rejectWithValue(err)
  }
})

export const uploadPanelistNotesEvaultDoc = createAsyncThunk('panelist/uploadPanelistNotesEvaultDoc', async (kwargs, { rejectWithValue }) => {
  try {
    return await API(
            `panelists/v1/notes/evault/${kwargs.evaultId}/document`,
            API_VERSION.SERVICE
    ).request(REQUEST_METHOD.POST, {}, kwargs.body)
  } catch (err) {
    console.log('error uploading panelist notes evault doc', err)
    return rejectWithValue(err)
  }
})

export const downloadPanelistNotesEvaultDoc = createAsyncThunk('panelist/downloadPanelistNotesEvaultDoc', async (kwargs, { rejectWithValue }) => {
  try {
    return await API(
            `panelists/v1/notes/evault/${kwargs.evaultId}/document/${kwargs.docId}`,
            API_VERSION.SERVICE
    ).request(REQUEST_METHOD.GET, {}, {})
  } catch (err) {
    console.log('error downloading panelist notes evault doc', err)
    return rejectWithValue(err)
  }
})
const panelistSlice = createSlice({
  name: 'panelist',
  initialState,
  reducers: {
    resetPanelistNotes: state => {
      state.panelistNotes = initialState.panelistNotes
    },
    resetPanelistNotesEvault: state => {
      state.panelistNotesEvault = initialState.panelistNotesEvault
    },
  },
  extraReducers: {
    [fetchPanelistNotes.pending]: state => {
      state.panelistNotes.status = STATUS.LOADING
    },
    [fetchPanelistNotes.fulfilled]: (state, action) => {
      state.panelistNotes.status = STATUS.SUCCEEDED
      state.panelistNotes.data = action.payload
    },
    [fetchPanelistNotes.rejected]: (state, action) => {
      state.panelistNotes.status = STATUS.FAILED
      state.panelistNotes.error = action.payload.message
      state.panelistNotes.data = null
    },
    [updatePanelistNotes.pending]: state => {
      state.panelistNotesUpdate.status = STATUS.LOADING
    },
    [updatePanelistNotes.fulfilled]: (state, action) => {
      state.panelistNotes.data = action.payload
      state.panelistNotesUpdate.status = STATUS.SUCCEEDED
      state.panelistNotesUpdate.error = null
    },
    [updatePanelistNotes.rejected]: (state, action) => {
      state.panelistNotesUpdate.status = STATUS.FAILED
      state.panelistNotesUpdate.error.error = action.payload.message
    },
    [fetchPanelistNotesEvault.pending]: state => {
      state.panelistNotesEvault.status = STATUS.LOADING
    },
    [fetchPanelistNotesEvault.fulfilled]: (state, action) => {
      state.panelistNotesEvault.status = STATUS.SUCCEEDED
      state.panelistNotesEvault.data = action.payload
    },
    [fetchPanelistNotesEvault.rejected]: (state, action) => {
      state.panelistNotesEvault.status = STATUS.FAILED
      state.panelistNotesEvault.error = action.error.message
      state.panelistNotesEvault.data = null
    },
  },
})

export const { resetPanelistNotes, resetPanelistNotesEvault } = panelistSlice.actions
export default panelistSlice.reducer
