import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'reactstrap'

const propTypes = {
  modalClassName: PropTypes.string,
  ModalComponent: PropTypes.element,
  onHide: PropTypes.func,
  show: PropTypes.bool,
  title: PropTypes.string,
}

/**
 * @typedef {Object} propTypes
 * @property {string} modalClassName - The CSS class for the modal
 * @property {React.Component} ModalComponent - The component to be rendered in the modal
 * @property {function} onHide - The function to be called when the modal is closed
 * @property {boolean} propTypes.show - Whether the modal is shown or not
 * @property {string} title - The title of the modal
 * @property {('static'|boolean)} backdrop - Determines whether the modal should close when clicking outside. 'static' means the modal will not close. Default is true.
 */
export default function ModalWrapper(props) {
  const {
    backdrop,
    component: ModalComponent,
    modalClassName,
    onHide,
    onHideConfirmReassignmentModal,
    show,
  } = props
  const backdropValue = backdrop || true

  return (
    <Modal
      backdrop={backdropValue}
      className={modalClassName}
      isOpen={show}
      toggle={onHide}
      onClosed={() => {
        if (onHideConfirmReassignmentModal) {
          onHideConfirmReassignmentModal()
        }
      }}>
      <ModalComponent {...props} />
    </Modal>
  )
}

ModalWrapper.propTypes = propTypes
