import { fromPromise } from 'rxjs/observable/fromPromise'
import { of } from 'rxjs/observable/of'
import analytics from '../../helpers/segment'
import {
  setData,
  SET_DATA,
} from '../common/utils/resource'
import 'rxjs/add/operator/switchMap'
// constants
const LOGOUT = 'LOGOUT'
const NOT_FOUND = 'NOT_FOUND'
const CHECK_ACTIVE = 'CHECK_ACTIVE'

// ac
const logout = () => {
  localStorage.setItem('reggora_lender_auth_token', '')
  analytics.userLogOut()
  return { type: LOGOUT }
}
const notFound = () => ({ type: NOT_FOUND })
const checkActive = (token) => ({ type: CHECK_ACTIVE, payload: { token } })

// epics
const checkActiverEpic = (action$, store, { API }) =>
  action$.ofType(CHECK_ACTIVE)
    .mergeMap(action =>
      fromPromise(
        API(`users/activate/${action.payload.token}`, 2)
          .get()
      )
        .switchMap(
          res => of(
            setData({ ...res }, { resource: { namespace: 'activate' } })
          )
        )
    )

const afterSetActivateEpic = (action$, store, { API }) => {
  return action$.ofType(SET_DATA).filter(action => action.meta.resource.namespace === 'activate')
    .mergeMap(action => {
      const activateState = store.getState().resource.activate
      const user = activateState.data && activateState.data.user
      if (!user) {
        return []
      }
    })
}

export {
  LOGOUT,
  NOT_FOUND,
  CHECK_ACTIVE,
  logout,
  notFound,
  checkActive,
  checkActiverEpic,
  afterSetActivateEpic,
}
