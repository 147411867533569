import React, { Component } from 'react'

import {
  zipByState, // {<state fips>: [<zip code>]}
  zipByCountyByState // {<state fips>: {<county fips>: [<zip code>]}}
} from '../../../constants/locations'

import CoverageDisplay from '../../DataDisplay/Coverage'
import LayeredMapSelector from '../LayeredMapSelector'
import { Button } from 'antd'
import './style.scss'

export default class CoverageEditor extends Component {
  state = {
    currentCoverage: this.props.currentIndividualCoverageArea ? this.props.currentIndividualCoverageArea : this.props.companyCoverage,
    zoomedInOnState: null,
    zoomedInOnCounty: null,
    currentView: 'national',
    level: this.props.currentIndividualCoverageLevel ? this.props.currentIndividualCoverageLevel : 'company'
  };


  componentWillReceiveProps(nextProps) {
    const {
      companyCoverage,
      currentIndividualCoverageArea
    } = this.props
    if(companyCoverage !== nextProps.companyCoverage && nextProps.companyCoverage) {
      this.setState({
        currentCoverage: nextProps.companyCoverage
      })
    }
    if(currentIndividualCoverageArea !== nextProps.currentIndividualCoverageArea && nextProps.currentIndividualCoverageArea) {
      this.setState({
        currentCoverage: nextProps.currentIndividualCoverageArea
      })
    }
  }

  onFeatureClick = (feature, featureType) => {
    const {
      getMapOverlay
    } = this.props
    if(featureType === 'state') {
      const state = feature.layer.feature.properties.STATE
      getMapOverlay('county', [state], [])
      this.setState({
        zoomedInOnState: state,
        zoomedInOnCounty: null
      })
    } else if(featureType === 'county') {
      const county = feature.layer.feature.properties.COUNTY
      const state = feature.layer.feature.properties.STATE
      getMapOverlay('zip', [state], [county])
      this.setState({
        zoomedInOnState: state,
        zoomedInOnCounty: county,
      })
    }
  }

  onShiftClick = (feature, featureType) => {
    const {
      zoomedInOnCounty,
      zoomedInOnState,
      currentCoverage,
    } = this.state
    let newCoverage
    let filterCoverage
    if(featureType === 'state') {
      const state = feature.layer.feature.properties.STATE
      // find state zips
      const stateZIPs = zipByState[state]
      // figure out percent covered
      let numCovered = 0
      let uncovered = []
      for(let zip of stateZIPs) {
        if(currentCoverage.some(cov => cov.zip === zip && cov.state === state)) {
          numCovered++
        } else {
          uncovered.push(zip)
        }
      }
      const fullyCovered = numCovered === stateZIPs.length
      if(fullyCovered) {
        // deselect it all.
        filterCoverage = cov => cov.state !== state
      } else {
        // select it all
        newCoverage = [];
        uncovered.forEach(zip => {
          for(let aCounty in zipByCountyByState[state]){
            if(zipByCountyByState[state][aCounty].indexOf(zip) > -1) {
              newCoverage.push(
                {
                  zip,
                  county: aCounty,
                  state
                }
              )
            }
          }
        })
      }
    } else if(featureType === 'county') {
      const state = feature.layer.feature.properties.STATE
      const county = feature.layer.feature.properties.COUNTY
      // find zips in county
      const countyZIPs = zipByCountyByState[state][county]
      // figure out fraction of covered zips
      let numCovered = 0
      let uncovered = []
      for(let zip of countyZIPs) {
        if(currentCoverage.some(cov => cov.zip === zip && cov.county === county && cov.state === state)) {
          numCovered++
        } else {
          uncovered.push(zip)
        }
      }
      const fullyCovered = numCovered === countyZIPs.length
      if(fullyCovered) {
        // fully covered so deselect everything
        filterCoverage = cov => cov.state !== state || cov.county !== county
      } else {
        // partially covered or uncovered so select everything
        newCoverage = uncovered.map(zip => {
          return {
            zip,
            county,
            state
          }
        })
      }
    } else if(featureType === 'zip') {
      const zip = feature.layer.feature.properties.ZCTA5CE10
      const isCoveredZIP = currentCoverage.some(cov => cov.zip === zip && 
        cov.state === zoomedInOnState && cov.county === zoomedInOnCounty)
      if(isCoveredZIP) {
        filterCoverage = cov => cov.zip !== zip || cov.county !== zoomedInOnCounty || cov.state !== zoomedInOnState
      } else {
        newCoverage = [{
          state: zoomedInOnState,
          county: zoomedInOnCounty,
          zip,
        }]
      }
    }
    if(newCoverage) {
      this.setState(prevState => {
        const currentCoverage = [
          ...prevState.currentCoverage,
          ...newCoverage
        ]
        return { currentCoverage, level: 'user' }
      })
    } else if(filterCoverage) {
      this.setState(prevState => {
        const currentCoverage = prevState.currentCoverage.filter(filterCoverage)
        return { currentCoverage }
      })
    }
  }

  setCurrentView = (view) => {
    this.setState({ currentView: view })
    if(view === 'national') {
      this.setState({
        zoomedInOnState: null
      })
    } else if(view === 'state') {
      this.setState({
        zoomedInOnCounty: null
      })
    }
  }

  onSidebarRemove = (zip, county, state, featureType) => {
    let filterCoverage
    if(featureType === 'zip') {
      filterCoverage = cov => cov.zip !== zip || cov.county !== county || cov.state !== state
    } else {
      console.log('CoverageEditor\'s onSidebarRemove has been called with an unexpected featureType:', featureType)
    }
    if(filterCoverage) {
      this.setState(prevState => {
        return {
          currentCoverage: prevState.currentCoverage.filter(filterCoverage),
          level: 'user'
        }
      })
    }
  }

  onSubmit = () => {
    const {
      onSubmit
    } = this.props
    const {
      currentCoverage,
      level
    } = this.state
    onSubmit(currentCoverage, level)
  }

  renderInstructions = () => {
    const {
      renderInstructions
    } = this.props
    if(renderInstructions) {
      return renderInstructions()
    } else if(this.props.currentIndividualCoverageArea && this.props.currentIndividualCoverageLevel){
      return this.renderInstructionsForIndividualCoverage()
    }
    const firm_instruction_message = 'Please select the areas covered by the firm.'

    return (
      <div className='alert-display'>
        <span>
          {firm_instruction_message}
          <ul>
            <li><b>Click</b> to zoom in on a territory</li>
            <li><b>Shift</b> + <b>Click</b> to select and unselect</li>
          </ul>
        </span>
      </div>)
  }

  onResetButtonClick = () => {
    if(this.props.companyCoverage){
      this.setState({
        currentCoverage: this.props.companyCoverage,
        level: 'company'
      })
    }
  }

  onRevertButtonClick = () => {
    if(this.state.level === 'company'){
      this.setState({
        currentCoverage: this.props.companyCoverage,
        level: 'company'
      })
    } else if(this.state.level === 'user'){
      this.setState({
        currentCoverage: this.props.currentIndividualCoverageArea,
        level: this.props.currentIndividualCoverageLevel
      })
    }
  }

  renderInstructionsForIndividualCoverage = () => {
    return(
      <div className='alert-display'>
          <span>
          {`Please select the areas covered by ${this.props.currentAppraiserName}`}
            <ul>
              <li><b>Click</b> to zoom in on a territory</li>
              <li><b>Shift</b> + <b>Click</b> to select and unselect</li>

              <div>
                <li>Click <b>Finish & Save</b> to set individual's coverage area</li>
                <li>Click <b>Reset</b> button to remove all individual coverage area settings</li>
                <li>Click <b>Revert</b> button to revert all recent changes</li>
              </div>
            </ul>
          </span>

          <span>
            <h5>Notes:</h5>
            <ul>
              <li><b>Highlighted areas</b> are the firm's Coverage Area.</li>
              <li><b>Edit the list below</b> or <b>Highlight areas on the map</b> to set/update Individual's Coverage Area</li>
            </ul>
          </span>
        </div>
    )
  }

  renderSidebarContents = () => {
    const {
      readOnly
    } = this.props
    const {
      currentCoverage,
      level
    } = this.state

    return (
      <div>
        {this.props.currentIndividualCoverageArea && this.props.currentIndividualCoverageLevel ?
        <div className='set-coverage-group'>
          <Button size='small' className='revert-button' onClick={this.onRevertButtonClick}>Revert</Button>
          <Button size='small' className='reset-button' onClick={this.onResetButtonClick}>Reset</Button>
        </div>
        : null}
        <br/>
        <CoverageDisplay
          readOnly={readOnly}
          onRemove={this.onSidebarRemove}
          coverage={currentCoverage}
          level={level}
          />
      </div>
    )
  }

  styleFeature = (feature, featureType) => {
    const {
      readOnly
    } = this.props
    const {
      zoomedInOnState,
      zoomedInOnCounty,
      currentCoverage } = this.state

    // background is features outside of the one currently zoomed in on
    const backgroundColor = '#919191'
    const darkBackgroundColor = '#454545' // Darkened 30%
    // base is the subfeatures of current zoom (so viewing national, the states take on baseColor)
    const baseColor = '#3355bb'
    const darkBaseColor = '#000055'
    const darkActiveBaseColor = '#000066' // Darkened 60%
    const greenCountyBorderColor = '#228B22'
    if(featureType === 'state') {
      const stateStyle = {
        color: backgroundColor,
        fillColor: backgroundColor,
        weight: 1,
        opacity: 1,
        fillOpacity: 0.2,
        unselectedFillColor: backgroundColor,
        unselectedFillOpacity: 0.2,
        selectedFillColor: baseColor,
        selectedFillOpacity: 0.8,
        hoveredFillOpacity: 0.9,
        checked: false,
      }

      const state = feature.properties.STATE

      const stateZIPs = zipByState[state]
      const numCovered = currentCoverage.filter(cov => stateZIPs.indexOf(cov.zip) > -1).length
      // fullyCovered means all zips are covered, whether via this state or not
      const fullyCovered = numCovered === stateZIPs.length
      // partiallyCovered means there is at least one coverage area with this state
      const partiallyCovered = currentCoverage.some(cov => cov.state === state)
      // Detirmine opacity and color based on the coverage
      if(fullyCovered) {
        // stateStyle.color = activeBaseColor;
        stateStyle.darkColor = darkActiveBaseColor

        stateStyle.fillColor = baseColor
        stateStyle.darkFillColor = darkBaseColor

        stateStyle.fillOpacity = 0.8
        stateStyle.checked = true
      } else if(partiallyCovered) {
        // stateStyle.color = activeBaseColor;
        stateStyle.darkColor = darkActiveBaseColor

        stateStyle.fillColor = baseColor
        stateStyle.darkFillColor = darkBaseColor

        stateStyle.fillOpacity = 0.6
      }

      // If we are zoomed in on a state then slightly change the coloration and opacity
      if(zoomedInOnState) {
        if(zoomedInOnState === state) {
          stateStyle.fillOpacity = 0
          stateStyle.weight = 2
        } else {
          // We are zoomed in on a state, but these states are the ones who arent the focus, Will wants these guys to be darker
          stateStyle.color = stateStyle.darkColor
          stateStyle.fillColor = stateStyle.darkFillColor
        }
      }

      return stateStyle
    } else if(featureType === 'county') {
      const state = feature.properties.STATE
      const county = feature.properties.COUNTY
      const countyStyle = {
        fillOpacity: 0.2,
        color: greenCountyBorderColor,
        fillColor: backgroundColor,
        unselectedFillColor: backgroundColor,
        unselectedFillOpacity: 0.2,
        selectedFillColor: baseColor,
        selectedFillOpacity: 0.8,
        hoveredFillOpacity: 0.9,
        checked: false,
      }

      const countyZIPs = zipByCountyByState[state][county]
      const numCovered = currentCoverage.filter(cov => countyZIPs.indexOf(cov.zip) > -1).length
      const fullyCovered = numCovered === countyZIPs.length
      const partiallyCovered = currentCoverage.some(cov => cov.state === state && cov.county === county)

      if(fullyCovered) {
        countyStyle.fillColor = baseColor
        countyStyle.fillOpacity = 0.8
        countyStyle.checked = true
      } else if(partiallyCovered) {
        countyStyle.fillColor = baseColor
        countyStyle.fillOpacity = 0.6
      }

      if(zoomedInOnCounty) {
        if(zoomedInOnCounty === county) {
          countyStyle.fillOpacity = 0
          countyStyle.color = greenCountyBorderColor
          countyStyle.weight = 5
          countyStyle.className = 'county-x-cursor'
        } else {
        }
      }

      return countyStyle
    } else if(featureType === 'zip') {
      const zipStyle = {
        color: darkBackgroundColor,
        fillColor: darkBackgroundColor,
        fillOpacity: 0.3,
        className: readOnly ? 'county-x-cursor' : 'zip-feature',
        unselectedFillColor: darkBackgroundColor,
        unselectedFillOpacity: 0.3,
        selectedFillColor: baseColor,
        selectedFillOpacity: 0.9,
        hoveredFillOpacity: 0.9,
        checked: false,
      }
      const zip = feature.properties.ZCTA5CE10

      // check if in this zone
      const isCoveredZIP = currentCoverage.some(cov => cov.zip === zip 
        && cov.state === zoomedInOnState && cov.county === zoomedInOnCounty)
      if(isCoveredZIP) {
        zipStyle.fillColor = baseColor
        zipStyle.fillOpacity = 0.7
        zipStyle.checked = true
      }

      return zipStyle
    }
  }


  render() {
    const {
      countyOverlay,
      zipOverlay,
      onCancel,
      submitText,
      disableSubmitWhenEmpty,
      readOnly
    } = this.props
    const {
      currentView,
      currentCoverage
    } = this.state

    return (<div className='coverage-editor-map-container'>
      <LayeredMapSelector
        countyOverlay={countyOverlay}
        zipOverlay={zipOverlay}
        onFeatureClick={this.onFeatureClick}
        onShiftClick={this.onShiftClick}
        setCurrentView={this.setCurrentView}
        renderInstructions={this.renderInstructions}
        renderSidebarContents={this.renderSidebarContents}
        styleFeature={this.styleFeature}
        currentView={currentView}
        onSubmit={this.onSubmit}
        onCancel={onCancel}
        title='Coverage Area'
        submitText={submitText}
        disableSubmit={disableSubmitWhenEmpty && currentCoverage.length === 0}
        readOnly={readOnly}
        currentCoverage={this.state.currentCoverage}
      />
    </div>)
  }
}
