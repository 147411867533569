import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import API, { API_VERSION, REQUEST_METHOD } from '../../../api'
import STATUS from '../../../common/constants/reducer_statuses'

const initialState = {
  inspections: {
    status: STATUS.IDLE,
    data: [],
    error: null,
  },
  inspection: {
    status: STATUS.IDLE,
    data: {},
    error: null,
  },
  form: {
    status: STATUS.IDLE,
    data: {},
    error: null,
  },
  inspectionDetails: {
    status: STATUS.IDLE,
    data: [],
    dataMap: {},
    error: null,
  },
  createInspectionDetails: {
    status: STATUS.IDLE,
    data: [],
    error: null,
  },
  updateInspectionDetail: {
    status: STATUS.IDLE,
    data: {},
    error: null,
  },
  createInspectionSubmission: {
    status: STATUS.IDLE,
    data: {},
    error: null,
  },
  inspectionSubmissions: {
    status: STATUS.IDLE,
    data: [],
    error: null,
  },
  fanniePropertyStatus: {
    status: STATUS.IDLE,
    data: {},
    error: null,
  },
}

export const fetchInspections = createAsyncThunk('inspections/fetchInspections', async (orderId, { dispatch, rejectWithValue }) => {
  try {
    const response = await API(`inspections/v1?order_id=${orderId}`, API_VERSION.SERVICE).request(REQUEST_METHOD.GET)
    return response.results
  } catch (err) {
    return rejectWithValue(err._error)
  }
})

export const fetchInspection = createAsyncThunk('inspections/fetchInspection', async (orderId, { dispatch, rejectWithValue }) => {
  try {
    const response = await API(`inspections/v1?order_id=${orderId}`, API_VERSION.SERVICE).request(REQUEST_METHOD.GET)
    const inspections = response.results
    if (!inspections?.length) {
      return {}
    }
    const inspection = inspections[0]

    await dispatch(fetchInspectionDetails(inspection._id))

    return inspection
  } catch (err) {
    return rejectWithValue(err._error)
  }
})

export const fetchInspectionDetails = createAsyncThunk('inspections/fetchInspectionDetails', async (inspectionId, { rejectWithValue }) => {
  try {
    const response = await API(`inspections/v1/${inspectionId}/details?limit=9999`, API_VERSION.SERVICE).request(REQUEST_METHOD.GET)
    return response.results
  } catch (err) {
    return rejectWithValue(err._error)
  }
})

export const createInspectionDetails = createAsyncThunk('inspections/createInspectionDetails', async (kwargs, { rejectWithValue }) => {
  try {
    const response = await API(`inspections/v1/${kwargs.inspectionId}/details`, API_VERSION.SERVICE).request(REQUEST_METHOD.POST, {}, kwargs.details)
    return response
  } catch (err) {
    return rejectWithValue(err._error)
  }
})

export const updateInspectionDetail = createAsyncThunk('inspections/updateInspectionDetail', async (kwargs, { rejectWithValue }) => {
  try {
    const response = await API(`inspections/v1/${kwargs.inspectionId}/details/${kwargs.inspectionDetailId}`, API_VERSION.SERVICE).request(REQUEST_METHOD.PUT, {}, kwargs.detail)
    return response
  } catch (err) {
    return rejectWithValue(err._error)
  }
})

export const createInspectionSubmission = createAsyncThunk('inspections/createInspectionSubmission', async (kwargs, { rejectWithValue }) => {
  try {
    const response = await API(`inspections/v1/${kwargs.inspectionId}/submissions`, API_VERSION.SERVICE).request(REQUEST_METHOD.POST, {}, kwargs.submission)
    return response
  } catch (err) {
    return rejectWithValue(err._error)
  }
})

export const fetchInspectionSubmissions = createAsyncThunk('inspections/fetchInspectionSubmissions', async (inspectionId, { dispatch, rejectWithValue }) => {
  try {
    const response = await API(`inspections/v1/${inspectionId}/submissions`, API_VERSION.SERVICE).request(REQUEST_METHOD.GET)
    return response.results
  } catch (err) {
    return rejectWithValue(err._error)
  }
})

export const fetchForm = createAsyncThunk('inspections/fetchForm', async (formId, { rejectWithValue }) => {
  try {
    const response = await API(`inspections/forms/v1/${formId}`, API_VERSION.SERVICE).request(REQUEST_METHOD.GET)
    return response
  } catch (err) {
    return rejectWithValue(err._error)
  }
})

export const fetchFanniePropertyStatus = createAsyncThunk('inspections/fetchFanniePropertyStatus', async (propertyDataId, { dispatch, rejectWithValue }) => {
  try {
    const response = await API(`inspections/fannie/property/${propertyDataId}/status`, API_VERSION.SERVICE).request(REQUEST_METHOD.GET)
    return response
  } catch (err) {
    return rejectWithValue(err._error)
  }
})

const inspectionsSlice = createSlice({
  name: 'inspections',
  initialState,
  reducers: {
    resetCreateInspectionSubmission: state => {
      state.createInspectionSubmission.status = initialState.createInspectionSubmission.status
      state.createInspectionSubmission.data = initialState.createInspectionSubmission.data
      state.createInspectionSubmission.error = initialState.createInspectionSubmission.error
    },
    resetInspectionSubmissions: state => {
      state.inspectionSubmissions.status = initialState.inspectionSubmissions.status
      state.inspectionSubmissions.data = initialState.inspectionSubmissions.data
      state.inspectionSubmissions.error = initialState.inspectionSubmissions.error
    },
    resetFanniePropertyStatus: state => {
      state.fanniePropertyStatus.status = initialState.fanniePropertyStatus.status
      state.fanniePropertyStatus.data = initialState.fanniePropertyStatus.data
      state.fanniePropertyStatus.error = initialState.fanniePropertyStatus.error
    },
    resetInspections: state => {
      state.inspections.status = initialState.inspections.status
      state.inspections.data = initialState.inspections.data
      state.inspections.error = initialState.inspections.error
    },
  },
  extraReducers: {
    [fetchInspections.pending]: state => {
      state.inspections.status = STATUS.LOADING
    },
    [fetchInspections.fulfilled]: (state, action) => {
      state.inspections.status = STATUS.SUCCEEDED
      state.inspections.data = action.payload
    },
    [fetchInspections.rejected]: (state, action) => {
      state.inspections.status = STATUS.FAILED
      state.inspections.error = action.error
    },
    [fetchInspection.pending]: state => {
      state.inspection.status = STATUS.LOADING
    },
    [fetchInspection.fulfilled]: (state, action) => {
      state.inspection.status = STATUS.SUCCEEDED
      state.inspection.data = action.payload
    },
    [fetchInspection.rejected]: (state, action) => {
      state.inspection.status = STATUS.FAILED
      state.inspection.error = action.error
    },
    [fetchInspectionDetails.pending]: state => {
      state.inspectionDetails.status = STATUS.LOADING
    },
    [fetchInspectionDetails.fulfilled]: (state, action) => {
      const { payload } = action
      state.inspectionDetails.status = STATUS.SUCCEEDED
      state.inspectionDetails.data = payload
      state.inspectionDetails.dataMap = payload.reduce((acc, detail) => ({
        ...acc,
        [detail.question_location]: detail,
      }), {})
    },
    [fetchInspectionDetails.rejected]: (state, action) => {
      state.inspectionDetails.status = STATUS.FAILED
      state.inspectionDetails.error = action.error
    },
    [createInspectionDetails.pending]: state => {
      state.createInspectionDetails.status = STATUS.LOADING
    },
    [createInspectionDetails.fulfilled]: (state, action) => {
      const { payload } = action
      state.createInspectionDetails.status = STATUS.SUCCEEDED
      state.createInspectionDetails.data = payload
      // Update inspection details state with created details
      state.inspectionDetails.data = [
        ...state.inspectionDetails.data,
        ...payload,
      ]
      state.inspectionDetails.dataMap = {
        ...state.inspectionDetails.dataMap,
        ...payload.reduce((acc, detail) => ({
          ...acc,
          [detail.question_location]: detail,
        }), {}),
      }
    },
    [createInspectionDetails.rejected]: (state, action) => {
      state.createInspectionDetails.status = STATUS.FAILED
      state.createInspectionDetails.error = action.error
    },
    [updateInspectionDetail.pending]: state => {
      state.updateInspectionDetail.status = STATUS.LOADING
    },
    [updateInspectionDetail.fulfilled]: (state, action) => {
      const { payload } = action
      state.updateInspectionDetail.status = STATUS.SUCCEEDED
      state.updateInspectionDetail.data = payload
      // Update inspection details state with updated detail
      state.inspectionDetails.data = [
        ...state.inspectionDetails.data.filter(detail => detail.question_location !== payload.question_location),
        payload,
      ]
      state.inspectionDetails.dataMap = {
        ...state.inspectionDetails.dataMap,
        [payload.question_location]: payload,
      }
    },
    [updateInspectionDetail.rejected]: (state, action) => {
      state.updateInspectionDetail.status = STATUS.FAILED
      state.updateInspectionDetail.error = action.error
    },
    [createInspectionSubmission.pending]: state => {
      state.createInspectionSubmission.status = STATUS.LOADING
    },
    [createInspectionSubmission.fulfilled]: (state, action) => {
      state.createInspectionSubmission.status = STATUS.SUCCEEDED
      state.createInspectionSubmission.data = action.payload
    },
    [createInspectionSubmission.rejected]: (state, action) => {
      state.createInspectionSubmission.status = STATUS.FAILED
      state.createInspectionSubmission.error = action.error
    },
    [fetchInspectionSubmissions.pending]: state => {
      state.inspectionSubmissions.status = STATUS.LOADING
    },
    [fetchInspectionSubmissions.fulfilled]: (state, action) => {
      state.inspectionSubmissions.status = STATUS.SUCCEEDED
      state.inspectionSubmissions.data = action.payload
    },
    [fetchInspectionSubmissions.rejected]: (state, action) => {
      state.inspectionSubmissions.status = STATUS.FAILED
      state.inspectionSubmissions.error = action.error
    },
    [fetchFanniePropertyStatus.pending]: state => {
      state.fanniePropertyStatus.status = STATUS.LOADING
    },
    [fetchFanniePropertyStatus.fulfilled]: (state, action) => {
      state.fanniePropertyStatus.status = STATUS.SUCCEEDED
      state.fanniePropertyStatus.data = action.payload
    },
    [fetchFanniePropertyStatus.rejected]: (state, action) => {
      state.fanniePropertyStatus.status = STATUS.FAILED
      state.fanniePropertyStatus.error = action.error
    },
    [fetchForm.pending]: state => {
      state.form.status = STATUS.LOADING
    },
    [fetchForm.fulfilled]: (state, action) => {
      state.form.status = STATUS.SUCCEEDED
      state.form.data = action.payload
    },
    [fetchForm.rejected]: (state, action) => {
      state.form.status = STATUS.FAILED
      state.form.error = action.error
    },
  },
})

export const {
  resetCreateInspectionSubmission,
  resetInspectionSubmissions,
  resetFanniePropertyStatus,
  resetInspections,
} = inspectionsSlice.actions

export default inspectionsSlice.reducer
