import React, { Component } from 'react'
import { Table as BSTable } from 'reactstrap'

export default class Table extends Component {
  state = {
    columns: this.configure(this.props.children, this.getSortProps(this.props)),
  }

  // TODO this allows to reconfigure table on the fly
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      columns: this.configure(nextProps.children, this.getSortProps(nextProps)),
    })
  }

  getSortProps(props) {
    const {
      onSort,
      sortDirection,
      sortedField,
    } = props
    return {
      onSort,
      sortDirection,
      sortedField,
    }
  }

  configure(children, newProps) {
    const columns = children.map(child => {
      return {
        type: child.type,
        ...child.props,
        ...newProps,
      }
    })

    return columns
  }

  makeTitles() {
    const columns = this.state.columns

    return columns.map(({ type: Type, ...props }, index) => (
      <Type
        key={index}
        isTitle={true}
        list={this.props.list}
        {...this.getSortProps(this.props)}
        {...props}
      />
    ))
  }

  makeRow = (item, index) => {
    const columns = this.state.columns
    const rowClassName = this.props.rowClassName || function() { return '' }
    const onRowClick = this.props.onRowClick || null
    if (onRowClick) {
      return (
        <tr key={index} id={item.id} className={rowClassName(item) + ' table-select'} onClick={() => onRowClick(item)}>
          {columns.map(({ type: Type, ...props }, i) => (
            <Type
              className={props.field ? props && props.field : ''}
              key={i}
              item={item}
              index={index}
              list={this.props.list}
              {...props}
            />
          ))}
        </tr>
      )
    } else {
      return (
        <tr
          className={rowClassName(item)}
          id={item.id}
          key={index}
        >
          {columns.map(({ type: Type, ...props }, i) => (
            <Type
              className={props.field ? props && props.field : ''}
              index={index}
              item={item}
              key={i}
              list={this.props.list}
              {...props}
            />
          ))}
        </tr>
      )
    }
  }

  render() {
    const {
      list,
      notFixed,
      // exclude sort-related props from restProps to prevent React warnings
      onSort,
      sortDirection,
      sortedField,
      ...restProps
    } = this.props
    const titles = this.makeTitles()
    const tableStyle = {}
    if (!notFixed) {
      tableStyle.tableLayout = 'fixed'
    }

    return (
      <BSTable {...restProps} style={tableStyle}>
        <thead>
          <tr>
            { titles }
          </tr>
        </thead>
        <tbody className={'td-hidden'}>
          {list?.results?.map?.(this.makeRow)}
        </tbody>
      </BSTable>
    )
  }
}
