export const reviewLogType = Object.freeze({
  INITIAL: 'INITIAL',
  PERSISTED_CLEARED: 'PERSISTED_CLEARED',
  PERSISTED_RETAINED: 'PERSISTED_RETAINED',
})


export const DisplayOutcome = Object.freeze({
  // This value is Not from the API, but added for internal use to track changes.
  CHANGED: 'Changed',

  // These are from the API.
  ACCEPTED: 'Accepted',
  ERROR: 'Error',
  FAILED: 'Failed',
  NA: 'N/A',
  PASSED: 'Passed',
  REJECTED: 'Rejected',
  UNKNOWN: 'Unknown',
})

export const SubmissionReviewWorkflow = Object.freeze({
  POST_SUBMISSION: 'post_submission',
})
