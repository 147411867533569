import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { utcNow } from 'app/common/utils/helpers'
import { connectResource } from 'app/common/utils/resource'
import { newMessage, submitMessage } from 'app/store/reducers/conversation/conversationSlice'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap'

import { NewMessageWrapper } from './NewMessageWrapper'
import { USER_TYPES } from 'app/common/constants/userTypes'
class NewMessageContainer extends Component {
    /**
    NewMessageContainer component renders UI for creating a message and handles request to backend when message is submitted by user.
    */
    state = {
      newMessageValue: '',
      modalIsOpen: false,
      disableSubmit: false,
      recipient: null,
    }

    UNSAFE_componentWillReceiveProps = (newProps) => {
      if (newProps.textToInsert) {
        let newMessageValue = ''
        if (this.state.newMessageValue) {
          // insert a space if the existing message does not end in one
          if (this.state.newMessageValue.slice(-1) === ' ') {
            newMessageValue = this.state.newMessageValue + newProps.textToInsert
          } else {
            newMessageValue = this.state.newMessageValue + ' ' + newProps.textToInsert
          }
        } else {
          newMessageValue = newProps.textToInsert
        }
        newProps.resetTextToInsert()
        this.setState({ newMessageValue })
      }
    }

    onNewMessageSend = ({
      userCanApproveMessage = false,
      isUnifiedConversation = false,
      userCanChooseRecipient = false,
    }) => {
      const {
        currentUserID,
        currentUserInfo,
        conversation_id,
        conversationType,
        orderData,
        newMessage,
        is_revision,
        is_amc_lender,
        addVendorNote,
        messageAdd,
        submitMessage,
      } = this.props

      const {
        newMessageValue,
        recipient: selectedRecipient,
      } = this.state
      if (!newMessageValue) {
        return
      }

      const sender_type = is_amc_lender ? USER_TYPES.AMC : USER_TYPES.LENDER

      const recipient = userCanChooseRecipient ? selectedRecipient : 'vendor'

      const message = {
        message: newMessageValue,
        is_revision: is_revision,
        conversation_id: conversation_id,
        order_id: orderData.id,
        sender_type: sender_type,
        conversation_type: conversationType,
        sent_time: utcNow(),
        recipient,
      }

      let promiseFunction = null
      if (isUnifiedConversation) {
        promiseFunction = () => submitMessage({ conversationId: conversation_id, messageRecord: message })
      } else {
        const namespace = conversationType === 'current_vendor_messages' ? addVendorNote : messageAdd
        promiseFunction = () => namespace.put(message)
      }
      this.setState({
        disableSubmit: true,
      })
      promiseFunction()
        .then(() => {
          newMessage({
            orderId: orderData.id,
            message: this.buildMessage({
              currentUserInfo,
              currentUserID,
              recipient,
              userCanApproveMessage,
              message,
              sender_type,
              isUnifiedConversation,
            }),
            conversationType,
          })
        })
        .catch(() => null)
        .finally(() => {
          this.setState({
            newMessageValue: '',
            modalIsOpen: false,
            disableSubmit: false,
            recipient: null,
          })
        })
    }

    buildMessage = ({
      currentUserInfo,
      currentUserID,
      recipient,
      userCanApproveMessage,
      isUnifiedConversation,
      message,
      sender_type,
    }) => {
      /**
      Builds message object that will be added to the store for a newly sent message.

      This means we do not need to refetch the entire conversation when a user sends a message.
      */
      let newMessage = {
        ...message,
        sender: {
          ...currentUserInfo,
          id: currentUserID,
          type: sender_type,
        },
        visible_to_vendor: true,
        visible_to_internal: true,
        requires_approval: false,
      }
      if (isUnifiedConversation) {
        newMessage = {
          ...newMessage,
          visible_to_internal: false,
          visible_to_vendor: false,
          requires_approval: false,
        }
        if (sender_type === USER_TYPES.AMC) {
          // AMP is the vendor
          newMessage.visible_to_vendor = true
          newMessage.requires_approval = true
        } else if (userCanApproveMessage) {
          if (recipient === 'vendor') {
            newMessage.visible_to_vendor = true
          } else if (recipient === 'everyone') {
            newMessage.visible_to_vendor = true
            newMessage.visible_to_internal = true
          } else if (recipient === 'internal') {
            newMessage.visible_to_internal = true
          }
        } else {
          if (recipient === 'vendor') {
            newMessage.requires_approval = true
            newMessage.visible_to_internal = true
          } else if (recipient === 'internal') {
            newMessage.visible_to_internal = true
          }
        }
      }
      return newMessage
    }

    handleInputChange = (event) => {
      this.setState({
        newMessageValue: event.target.value,
      })
    }

    toggle = () => {
      this.setState({
        modalIsOpen: !this.state.modalIsOpen,
      })
    }

    onFocus = () => {
      const { onFocus } = this.props
      if (onFocus) { onFocus() }
    }

    handleRecipientChange = (value) => {
      this.setState({
        recipient: value,
      })
    }

    render() {
      const {
        modal,
        orderData,
        newMessageTitle,
        conversationType,
        conversationCompany,
        is_amc_lender,
      } = this.props
      const { newMessageValue, disableSubmit, recipient } = this.state

      if (!modal) {
        if (orderData.has_been_reassigned) {
          // Hide the message input if the order has been reassigned
          return <></>
        } else {
          return (
            <NewMessageWrapper
              onSubmit={ this.onNewMessageSend }
              onFocus={this.onFocus}
              newMessageValue={newMessageValue}
              handleInputChange={this.handleInputChange}
              newMessageTitle={newMessageTitle}
              orderData={orderData}
              handleRecipientChange={this.handleRecipientChange}
              recipient={recipient}
              conversationType={conversationType}
              conversationCompany={conversationCompany}
              isAMPUser={is_amc_lender}
            />
          )
        }
      }

      return (
        <div className='w-100'>
          <Button color="danger" onClick={this.toggle}>Request Revision</Button>
          <Modal isOpen={this.state.modalIsOpen} toggle={this.toggle} >
            <ModalHeader toggle={this.toggle}>Request Revision</ModalHeader>
            <ModalBody>
              <textarea className='w-100' type='text' value={newMessageValue} onChange={this.handleInputChange}/>
            </ModalBody>
            <ModalFooter>
              <Button disabled={disableSubmit} color="primary" onClick={this.onNewMessageSend}>Send</Button>{' '}
              <Button disabled={disableSubmit} color="secondary" onClick={this.toggle}>Cancel</Button>
            </ModalFooter>
          </Modal>
        </div>
      )
    }
}

function mapStateToProps(state) {
  return {
    currentUserID: state.resource.user.data.id,
    currentUserInfo: {
      firstname: state.resource.user.data.firstname,
      lastname: state.resource.user.data.lastname,
      username: state.resource.user.data.username,
    },
  }
}

export default compose(
  connectResource({
    namespace: 'messageAdd',
    endpoint: 'order/message',
    prefetch: false,
    navigateAfterSubmit: false,
    excludeID: true,
    apiVersion: 2,
  }),
  connectResource({
    namespace: 'addVendorNote',
    endpoint: 'order/current-vendor-message',
    prefetch: false,
    navigateAfterSubmit: false,
    excludeID: true,
    apiVersion: 2,
  }),
  connect(mapStateToProps, { newMessage, submitMessage }),
)(NewMessageContainer)
