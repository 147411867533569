import React, { Fragment, useState, useEffect } from 'react'
import { Button, Input, Form } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { apiToReadable } from '../../../common/utils/helpers'
import { getInspectionDetail, getInspectionDetailValue } from '../../../../helpers/inspections'
import {
  selectInspectionDetailsDataMap,
  selectIsCreateInspectionDetailsLoading,
  selectIsUpdateInspectionDetailLoading,
} from '../../../store/reducers/inspection/inspectionSelectors'
import Loader from '../../../common/layout/components/Loader'
import { updateInspectionDetail, createInspectionDetails } from '../../../store/reducers/inspection/inspectionSlice'
import FloorplanLevelForm from './FloorplanLevelForm'
import { THIRD_PARTY } from './constants'

const StructureForm = ({ structure }) => {
  const inspectionId = structure.inspection_id
  const dispatch = useDispatch()
  const [structureArea, setStructureArea] = useState('')
  const inspectionDetailsDataMap = useSelector(selectInspectionDetailsDataMap)
  const structureAreaQuestionLocation = `${structure.structureLocation}.structure_area.0`
  const existingStructureAreaDetail = getInspectionDetail(inspectionDetailsDataMap, structureAreaQuestionLocation)
  const isCreateInspectionDetailsLoading = useSelector(selectIsCreateInspectionDetailsLoading)
  const isUpdateInspectionDetailLoading = useSelector(selectIsUpdateInspectionDetailLoading)

  useEffect(() => {
    if (existingStructureAreaDetail) {
      setStructureArea(getInspectionDetailValue(inspectionDetailsDataMap, structureAreaQuestionLocation))
    }
  }, [
    structureAreaQuestionLocation,
    existingStructureAreaDetail,
    inspectionDetailsDataMap,
  ])

  const handleSave = () => {
    if (existingStructureAreaDetail) {
      dispatch(updateInspectionDetail({
        inspectionId,
        inspectionDetailId: existingStructureAreaDetail._id,
        detail: {
          ...existingStructureAreaDetail,
          values: [structureArea],
        },
      }))
    } else {
      dispatch(createInspectionDetails({
        inspectionId,
        details: [
          {
            question_location: structureAreaQuestionLocation,
            inspection_id: inspectionId,
            values: [structureArea],
            metadata: {
              source: THIRD_PARTY,
            },
          },
        ],
      }))
    }
  }

  return (
    <Fragment>
      {isCreateInspectionDetailsLoading || isUpdateInspectionDetailLoading ? <Loader /> : null}
      <p>
        Structure: {apiToReadable(getInspectionDetailValue(inspectionDetailsDataMap, structure.question_location))}
      </p>
      <Form layout="inline">
        <Form.Item label="Structure Area" style={{ maxWidth: '300px' }}>
          <Input value={structureArea} type="number" onChange={e => setStructureArea(e.target.value)}/>
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={handleSave} disabled={!structureArea}>
            Save Structure Area
          </Button>
        </Form.Item>
      </Form>
      {structure.levels.map((level, index) => (
        <FloorplanLevelForm
          key={level.question_location}
          levelTypeInspectionDetail={level}
          levelNumber={index + 1}
        />
      ))}
      <hr />
    </Fragment>
  )
}

export default StructureForm
