import { useEffect, useRef, useState, useCallback } from 'react'
import { DisplayOutcome } from '../ReviewConstants'

/**
 * Compute the frequency of each outcome in the review results.
 * @param {Object.<string, any>} [reviewResults]
 * @return {{id: string, label: string}[]}
 */
const useAvailableTags = (reviewResults) => {
  const availableTagsGenerated = useRef([])
  const [availableTags, setAvailableTags] = useState([])

  useEffect(() => {
    if (!reviewResults) { return }

    // Compute the frequency of each outcome in the review results.
    const countByOutcome = Object.values(reviewResults).reduce((acc, rule) => {
      acc[rule.display_outcome] = (acc[rule.display_outcome] || 0) + 1

      if (rule.hasSystemOverride) {
        acc[DisplayOutcome.CHANGED] = (acc[DisplayOutcome.CHANGED] || 0) + 1
      }
      return acc
    }, {})

    // If any are now missing, add them back in
    const resultOutcomeKeys = Object.keys(countByOutcome)
    const missingTags = availableTagsGenerated.current.reduce((acc, oldTag) => {
      if (!resultOutcomeKeys.includes(oldTag)) {
        acc[oldTag] = 0
      }
      return acc
    }, {})

    const outcomeMappings = { ...countByOutcome, ...missingTags }
    const tags = Object.entries(outcomeMappings)
      .map(([key, value]) => ({ id: key, label: `${key} (${value})` }))
      .sort((a, b) => {
        const sortOrder = {
          [DisplayOutcome.CHANGED]: 0,
          Failed: 1,
          Accepted: 2,
          Rejected: 3,
          Unknown: 4,
          Error: 5,
          Passed: 6,
          'N/A': 7,
        }

        return sortOrder[a.id] - sortOrder[b.id]
      })
    availableTagsGenerated.current = tags.map(tag => tag.id)
    setAvailableTags(tags)
  }, [reviewResults])

  return availableTags
}

/**
 * @param {{id: string, label: string}[]} availableTags
 * @param {number} [submissionVersion]
 */
const useSelectedTags = (availableTags, submissionVersion) => {
  const initialSelectedTagsSet = useRef(false)
  const [selectedTags, setSelectedTags] = useState([])


  useEffect(() => {
    if (!availableTags.length || initialSelectedTagsSet.current) { return }

    const selectedTagsFiltered = availableTags
      .filter(tag => ![DisplayOutcome.NA, DisplayOutcome.PASSED].includes(tag.id))
      .map(tag => tag.id)

    setSelectedTags(selectedTagsFiltered)
    initialSelectedTagsSet.current = true
  }, [availableTags, submissionVersion])

  return { selectedTags, setSelectedTags }
}

/**
 * Filter the rules by the selected tags.
 * @param {string[]} selectedTags
 */
const useFilterRulesByOutcome = (selectedTags) => {
  return useCallback((rulesToFilter) => {
    if (!selectedTags.length) { return rulesToFilter }

    return rulesToFilter?.filter(rule =>
      selectedTags.includes(rule.fieldData?.display_outcome) ||
        (selectedTags.includes(DisplayOutcome.CHANGED) && rule.fieldData?.hasSystemOverride))
  }, [selectedTags])
}

/**
 * @param {Object.<string, any>} [reviewResults]
 * @param {number} [submissionVersion]
 */
export const useResultStatusTags = (reviewResults, submissionVersion) => {
  const availableTags = useAvailableTags(reviewResults)
  const { selectedTags, setSelectedTags } = useSelectedTags(availableTags, submissionVersion)
  const filterRulesByOutcome = useFilterRulesByOutcome(selectedTags)

  return { availableTags, filterRulesByOutcome, setSelectedTags, selectedTags }
}
