import React from 'react'
import { Row, Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'

const OrderTabs = (props) => {
  const {
    orderTabs,
    activeOrderTab,
    toggleOrder,
  } = props

  return (
    <Row className="pb-3 pt-4 order-header-container">
      <Nav tabs className="w-100">
        {orderTabs.map(item => {
          return (
            <NavItem key={item.key}>
              <NavLink
                className={classnames({
                  active: activeOrderTab === item.key,
                  nav_flex: true,
                })}
                onClick={() => {
                  toggleOrder(item.key)
                }}
              >
                <span className="nav-img nav-img--doc" />
                {item.value}
              </NavLink>
            </NavItem>
          )
        })}
      </Nav>
    </Row>
  )
}

export default OrderTabs
