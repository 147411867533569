import React, { Fragment } from 'react'
import Select from 'react-select'
import { Button } from 'reactstrap'


/**
 * Displays a UI for editing a list of Users.
 * Intended to be used in the modal to edit Users assigned to an Order.
 * UI allows users to add rows, each row containing a Select input to select a User. Rows can be removed.
 */
const EditUserList = ({
  autoFocus,
  onAddAssignedRow,
  onRowRemove,
  onSelectUser,
  selectedAssigned,
  selectedAssignedIds,
  showAddButton,
  unselectedUsers,
}) => {
  const onRemoveKeyDown = (event, rowKey) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      onRowRemove(rowKey)
    }
  }

  return (<Fragment>
    Below are the assigned users who are able to view this order.
    {" They will also receive notifications about the order's updates."}
    {selectedAssigned.map((assigned, key) => {
      return (
        <div key={key} className="m-2 row processor-select">
          <div className="col-10">
            <Select
              name="assigned"
              labelKey="fullname"
              valueKey="id"
              value={assigned && assigned.id}
              clearable={false}
              onChange={value => onSelectUser(value, key)}
              options={assigned ? [...unselectedUsers, assigned] : unselectedUsers}
              autoFocus={autoFocus}
              required
            />
          </div>
          <a
            role="button"
            className="col-2"
            aria-label={`Remove ${assigned?.fullname || 'Row'}`}
            onClick={() => onRowRemove(key)}
            onKeyDown={(event) => onRemoveKeyDown(event, key)}
            tabIndex="0"
          >
            <i
              className="fa fa-minus-circle fa-2x text-muted col-1 mt-2 pl-1"
              aria-hidden="true"
            ></i>
          </a>
        </div>
      )
    })}
    {showAddButton && (
      <Button
        className="my-2 mb-3 btn-sm btn-light"
        type="button"
        onClick={onAddAssignedRow}
      >
        Add Another Assignee
      </Button>
    )}
  </Fragment>)
}

export default EditUserList
