// import '@babel/polyfill'
// import ;
// import smoothScroll from 'smoothscroll-polyfill'
// smoothScroll.polyfill()

// should be after React import for IE11
// 'require' used because inside condition
if (!window.fetch) { // IE11 check
  // require('es6-promise').polyfill();
  require('core-js')
  require('core-js/modules/es.symbol')
  require('core-js/modules/es.object.assign')
  require('whatwg-fetch')
  // require('react-app-polyfill/ie11')
}
