import { SmallLoader } from '../Loader'
import React from 'react'
import styles from './OverlayLoader.module.css'
import classNames from 'classnames'

/**
 * Displays a loading spinner overlay over a given component.
 *
 * @component
 * @param {Object} props - The props object.
 * @param {React.ReactNode} props.children - The child components over which the overlay is displayed.
 * @param {boolean} props.isLoading - A flag indicating whether the loading spinner should be displayed.
 */
export const OverlayLoader = ({ children, isLoading }) => {
  return (
    <div style={{ position: 'relative' }}>

      {
        isLoading && (
          <div className={classNames(styles.overlay, { [styles.overlayVisible]: isLoading })}>
            <SmallLoader />
          </div>
        )
      }
      {children}
    </div>
  )
}
