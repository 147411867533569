import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { connectResource } from '../../../common/utils/resource'
import { ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import Loader from '../../../common/layout/components/Loader'
import Rephrase from '../../../common/utils/Rephrase'

class StopAutoSendAppraisalToBorrowerModal extends Component {
  onSubmit = () => {
    const { orderData, version } = this.props
    this.props.stopAutoSend.put({ id: orderData.id, version: version }).then(_ => {
      this.props.onHide()
      this.props.refreshOrder()
    })
  }

  render() {
    const { ...props } = this.props
    const loading = props.stopAutoSend.isLoading

    return (
      <Fragment>
        <ModalHeader toggle={props.toggle}>Stop Auto Send</ModalHeader>
        <ModalBody>
          { loading ? <Loader />
            : <Rephrase>
                Are you sure that you want to stop this appraisal from automatically being sent to the borrower?
            </Rephrase>
          }
        </ModalBody>
        <ModalFooter className="border-0">
          <Button disabled={loading} type='button' color='success' onClick={this.onSubmit}>Yes</Button>
          <Button disabled={loading} onClick={props.onHide} color='danger'>Cancel</Button>
        </ModalFooter>
      </Fragment>
    )
  }
}

export default compose(
  connectResource({
    namespace: 'stopAutoSend',
    endpoint: 'order/stop-auto-send',
    prefetch: false,
    apiVersion: 2,
    successMessage: { POST: 'Auto send stopped!' },
  }),

)(StopAutoSendAppraisalToBorrowerModal)
