import { loadableWithRetry } from '../../common/utils/lazyLoad'

const routes = [
  {
    path: '/',
    exact: true,
    name: 'profile',
    redirectTo: 'profile/main',
  },
  {
    path: '/main',
    name: 'main',
    component: loadableWithRetry(() => import('./MainContainer')),
  },
  {
    path: '/password',
    name: 'password',
    component: loadableWithRetry(() => import('./PasswordContainer')),
  },
]

export default routes
