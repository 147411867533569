import React, { Fragment, useState } from 'react'
import Select from 'react-select'
import { ModalHeader, ModalBody, FormGroup, Col, Label, ModalFooter, Button } from 'reactstrap'

export default function PaymentTransferModal(props) {
  const [selectedOrderOption, setSelectedOrderOption] = useState(null)

  return (
    <Fragment>
      <ModalHeader toggle={props.onHide}>Transfer Payment</ModalHeader>
      <ModalBody>
        <FormGroup row>
          <Col xs="12">
            <Label>Destination Order</Label>
            <Select
              value={selectedOrderOption}
              valueKey='key'
              labelKey='value'
              onChange={setSelectedOrderOption}
              options={props.orderOptions}
            />
          </Col>
        </FormGroup>
      </ModalBody>
      <ModalFooter className="border-0">
        <Button onClick={props.onHide} color="secondary">Cancel</Button>
        <Button
          disabled={!selectedOrderOption}
          onClick={() => props.transferPayment(selectedOrderOption.key)}>Transfer</Button>
      </ModalFooter>
    </Fragment>
  )
}
