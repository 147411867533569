import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input, Button, Form } from 'antd'
import { getInspectionDetail, getInspectionDetailValue } from '../../../../helpers/inspections'
import { apiToReadable } from '../../../common/utils/helpers'
import { THIRD_PARTY } from './constants'

import {
  createInspectionDetails,
  updateInspectionDetail,
} from '../../../store/reducers/inspection/inspectionSlice'

import {
  selectInspectionDetailsDataMap,
  selectIsCreateInspectionDetailsLoading,
  selectIsUpdateInspectionDetailLoading,
} from '../../../store/reducers/inspection/inspectionSelectors'
import Loader from '../../../common/layout/components/Loader'

const FloorplanParkingForm = ({ garageTypeInspectionDetail }) => {
  const dispatch = useDispatch()
  const [spaceArea, setSpaceArea] = useState('')

  const inspectionId = garageTypeInspectionDetail.inspection_id
  const parkingQuestionLocationPrefix = garageTypeInspectionDetail.question_location.split('.').splice(0, 2).join('.')
  const spaceAreaQuestionLocation = `${parkingQuestionLocationPrefix}.space_area.0`

  const inspectionDetailsDataMap = useSelector(selectInspectionDetailsDataMap)
  const existingSpaceAreaDetail = getInspectionDetail(inspectionDetailsDataMap, spaceAreaQuestionLocation)

  const garageType = getInspectionDetailValue(inspectionDetailsDataMap, garageTypeInspectionDetail.question_location)

  const isCreateInspectionDetailsLoading = useSelector(selectIsCreateInspectionDetailsLoading)
  const isUpdateInspectionDetailLoading = useSelector(selectIsUpdateInspectionDetailLoading)

  useEffect(() => {
    if (existingSpaceAreaDetail) {
      setSpaceArea(getInspectionDetailValue(inspectionDetailsDataMap, spaceAreaQuestionLocation))
    }
  }, [
    existingSpaceAreaDetail,
    inspectionDetailsDataMap,
    spaceAreaQuestionLocation,
  ])

  const handleSave = () => {
    if (existingSpaceAreaDetail) {
      dispatch(updateInspectionDetail({
        inspectionId,
        inspectionDetailId: existingSpaceAreaDetail._id,
        detail: {
          ...existingSpaceAreaDetail,
          values: [spaceArea],
        },
      }))
    } else {
      dispatch(createInspectionDetails({
        inspectionId,
        details: [
          {
            question_location: spaceAreaQuestionLocation,
            inspection_id: inspectionId,
            values: [spaceArea],
            metadata: {
              source: THIRD_PARTY,
            },
          },
        ],
      }))
    }
  }

  return (
    <div>
      {isCreateInspectionDetailsLoading || isUpdateInspectionDetailLoading ? <Loader /> : null}
      <p>Parking: {apiToReadable(garageType)}</p>
      <Form layout="inline">
        <Form.Item label="Space Area">
          <Input value={spaceArea} type="number" onChange={e => setSpaceArea(e.target.value)} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={handleSave} disabled={!spaceArea}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
export default FloorplanParkingForm
