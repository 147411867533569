import React, { Component } from 'react'
import { RollbackOutlined } from '@ant-design/icons'
import { Popover } from 'antd'
import { Link } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import { PaymentStatuses } from '@app/common/constants/paymentStatuses'

const PaymentStatusStatusObjectMap = {
  [PaymentStatuses.PAYMENT_STATUS_NO_PAYMENT_REQUESTED]: {
    title: 'No Payment Requested',
    iconClassName: 'far fa-credit-card order-alert-badge size-up-fa-credit-card red-consumer-alert',
  },
  [PaymentStatuses.PAYMENT_STATUS_PAID_IN_FULL]: {
    title: 'Order Paid in Full',
    iconClassName: 'fa fa-check fa-lg fully-paid green-consumer-alert order-alert-badge',
  },
  [PaymentStatuses.PAYMENT_STATUS_PARTIAL_PAYMENT]: {
    title: 'Partial Payment',
    iconClassName: 'far fa-credit-card order-alert-badge size-up-fa-credit-card pending-payment yellow-consumer-alert',
  },
  [PaymentStatuses.PAYMENT_STATUS_PENDING_PAYMENTS]: {
    title: 'Pending Payments',
    iconClassName: 'far fa-credit-card order-alert-badge size-up-fa-credit-card payment-sent-to-consumer opacity-3',
  },
  [PaymentStatuses.PAYMENT_STATUS_OVERPAID]: {
    title: 'Order Overpaid',
    iconClassName: 'fa fa-credit-card order-alert-badge size-up-fa-credit-card violet-consumer-alert',
  },
}

const roundTwoDecimals = value => Math.round(value * 100) / 100

export default class ConsumerAlerts extends Component {
  getOrderPaymentInfo = () => {
    const payments = this.props.order.consumer_payments
    const order = this.props.order

    const _pendingPayments = []
    const _refundedPayments = []
    let _totalPaid = 0
    let _totalPendingPayments = 0
    let _totalRefund = 0

    if (payments) {
      payments.forEach((payment) => {
        const paymentInfo = {
          amount: parseFloat(payment.amount / 100),
          consumerName: payment.consumer_name,
        }
        if (order.id === payment.order_id && !payment.is_deleted) {
          if (payment.paid) {
            _totalPaid += payment.amount
          } else {
            _totalPendingPayments += payment.amount
            _pendingPayments.push(paymentInfo)
          }

          if (payment.is_refunded) {
            if (payment.refund_amount) {
              _totalRefund += payment.refund_amount
            } else if (payment.is_refunded) {
              _totalRefund += payment.amount
            }
            _refundedPayments.push(paymentInfo)
          }
        }
      })
    }

    const _formattedTotalRefund = parseFloat(_totalRefund / 100)
    const _formattedTotalPaid = parseFloat(_totalPaid / 100)
    const _formattedTotalPendingPayments = parseFloat(
      _totalPendingPayments / 100
    )
    const _totalDue =
      order.order_fee - _formattedTotalPaid + _formattedTotalRefund

    return {
      pendingPayments: _pendingPayments,
      refundedPayments: _refundedPayments,
      totalDue: roundTwoDecimals(_totalDue),
      totalPendingPayments: roundTwoDecimals(_formattedTotalPendingPayments),
      totalRefund: roundTwoDecimals(_formattedTotalRefund),
    }
  }

  renderAlerts = (paymentInfo) => {
    const { order } = this.props
    // Do not show the payment status icon for: Cancelled orders and collect at close but no payment link sent orders.
    if (order.status !== 'Cancelled' && (order.consumer_payment_style !== 'closing' || (order.consumer_payments && order.consumer_payments.length > 0)) && order.consumer_payment_style) {
      return (
        <Container>
          <Row>
            <Col className='p-0 consumer-alert-container'>
              <PaymentAlert
                clientName={order.client_name}
                consumerPayments={order.consumer_payments}
                consumerPaymentStyle={order.consumer_payment_style}
                orderAddress={order.address}
                orderFee={order.order_fee}
                orderId={order.id}
                pendingPayments={paymentInfo.pendingPayments}
                totalDue={paymentInfo.totalDue}
                paymentStatus={order.payment_status}
              />
            </Col>
            <Col className='p-0 consumer-alert-container'>
              <RefundAlert
                clientName={order.client_name}
                orderAddress={order.address}
                orderId={order.id}
                refundedPayments={paymentInfo.refundedPayments}
                totalRefund={paymentInfo.totalRefund}
              />
            </Col>
          </Row>
        </Container>
      )
    }
  }

  render() {
    const {
      order,
    } = this.props
    const paymentInfo = this.getOrderPaymentInfo()

    if (order.job_type === 'review') {
      // for now review orders have no payments
      return <article className="consumer-icons-container" />
    }

    return (
      <article className="consumer-icons-container">
        {this.renderAlerts(paymentInfo)}
      </article>
    )
  }
}

class ConsumerAlert extends Component {
  renderContent = () => {
    return (
      <div className='consumer-alert-overflow'>{this.props.content}</div>
    )
  }

  renderTitle = () => {
    return (
      <div className='consumer-alert-overflow'><strong>{this.props.title}</strong> for {this.props.orderAddress}</div>
    )
  }

  render() {
    return (
      <Popover
        content={this.renderContent()}
        title={this.renderTitle()}
      >
        <Link to={`/orders/${this.props.orderId}`} className="payment-icon">{this.props.icon}</Link>
      </Popover>
    )
  }
}

class PaymentAlert extends Component {
  getBalanceText = () => {
    return this.props.totalDue >= 0 ? `$${this.props.totalDue}` : `-$${Math.abs(this.props.totalDue)}`
  }

  renderContent = (title) => {
    return (
      <React.Fragment>
        {this.renderContentHeader()}
        {this.renderContentBody(title)}
        {(this.props.pendingPayments.length > 0) ? this.renderPendingPaymentsContent() : null}
      </React.Fragment>
    )
  }

  renderIcon = (iconClassName) => {
    return <i className={iconClassName}/>
  }

 renderPendingPaymentsContent = () => {
   return (
     <React.Fragment>
       <Row className='mt-2'>
         <Col><strong>Payment links were sent to:</strong></Col>
       </Row>
       {
         this.props.pendingPayments.map((payment) => {
           return (
             <Row key={payment.id}>
               <Col sm={{ size: 7, offset: 1 }}>
                 {payment.consumerName}:
               </Col>
               <Col sm={{ size: 4, offset: 0 }}>
                ${roundTwoDecimals(payment.amount)}
               </Col>
             </Row>
           )
         })
       }
     </React.Fragment>
   )
 }

  renderContentBody = (title) => {
    if (title === 'No Payment Requested') {
      return (
        <Row className='mt-2'>
          <Col>
            <Link to={`/orders/${this.props.orderId}`} className="payment-requires-attention">
              Send the consumer a payment link
            </Link>
          </Col>
        </Row>
      )
    }
  }

  renderContentHeader = () => {
    return (
      <React.Fragment>
        <Row>
          <Col><strong>Total Order Amount: </strong>${roundTwoDecimals(this.props.orderFee)}</Col>
        </Row>
        <Row>
          <Col><strong>Total Due: </strong>{this.getBalanceText()}</Col>
        </Row>
      </React.Fragment>
    )
  }

  getAlertStatus = () => {
    const { paymentStatus } = this.props
    return PaymentStatusStatusObjectMap[paymentStatus] || {
      title: null,
      iconClassName: 'hidden-consumer-alert',
    }
  }

  render() {
    const alertStatus = this.getAlertStatus()
    return (
      <ConsumerAlert
        content={this.renderContent(alertStatus.title)}
        icon={this.renderIcon(alertStatus.iconClassName)}
        title={alertStatus.title}
        orderAddress={this.props.orderAddress}
        orderId={this.props.orderId}
      />
    )
  }
}

class RefundAlert extends Component {
  getAlertStatus = () => {
    const status = {
      title: null,
      iconClassName: 'hidden-consumer-alert',
    }

    if (this.props.totalRefund > 0) {
      this.setRefundStatus(status)
    }

    return status
  }

  renderContent = () => {
    return (
      <React.Fragment>
        {this.renderStatusHeader()}
        {this.renderRefundedPayments()}
      </React.Fragment>
    )
  }

  renderIcon = (iconClassName) => {
    if (iconClassName === 'hidden-consumer-alert') {
      return <i className={iconClassName}/>
    }
    return <RollbackOutlined className={iconClassName} />
  }

  renderRefundedPayments = () => {
    return (
      <React.Fragment>
        <Row className='mt-2'>
          <Col><strong>Refunds were sent to:</strong></Col>
        </Row>
        {
          this.props.refundedPayments.map((payment) => {
            return (
              <Row key={payment.id}>
                <Col sm={{ size: 7, offset: 1 }}>
                  {payment.consumerName}:
                </Col>
                <Col sm={{ size: 4, offset: 0 }}>
                  ${payment.amount}
                </Col>
              </Row>
            )
          })
        }
      </React.Fragment>
    )
  }

  renderStatusHeader = () => {
    return (
      <Row>
        <Col><strong>Total Amount Refunded:</strong> ${this.props.totalRefund}</Col>
      </Row>
    )
  }

  setRefundStatus = (status) => {
    status.iconClassName = 'order-alert-badge refund-alert'
    status.title = 'Payment Refund'
  }

  render() {
    const alertStatus = this.getAlertStatus()
    return (
      <ConsumerAlert
        content={this.renderContent()}
        icon={this.renderIcon(alertStatus.iconClassName)}
        title={alertStatus.title}
        orderAddress={this.props.orderAddress}
        orderId={this.props.orderId}
      />
    )
  }
}
