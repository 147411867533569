import React, { useState } from 'react'
import { Button } from 'reactstrap'
import { Menu } from 'antd'
import { useApiRequest } from '../../common/hooks'
import ModalTrigger from '../../common/modals/ModalTrigger'
import AntdModalTrigger from '../../common/modals/antd/ModalTrigger'
import ModalCreateOrderContainer from './ModalCreateOrderContainer'
import ModalConfirmation from '../../common/modals/ModalConfirmation'
import ConsumerPremissionsModalContainer from './ConsumerPremissionsModalContainer'
import ModalSetInspectionDate from './ModalSetInspectionDate'
import OnHoldModal from './OnHoldModal'
import OrderActions from './OrderActions'
import EditOrderButton from './EditOrderButton'
import {
  checkPermission,
} from '../../common/utils/helpers'
import FlagContainer from './FlagContainer'
import ModalManualAppraisalUpload from './ModalManualAppraisalUpload'
import ModalUpdateVAOrderStatus from './ModalUpdateVAOrderStatus'
import Rephrase from '../../common/utils/Rephrase'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useDispatch } from 'react-redux'
import { editScore } from '../../store/reducers/vendorScore/vendorScoreSlice'

const getHoldButton = (orderData, updateOrderStatus) => {
  if (!orderData) {
    return
  }
  const holdText = orderData.on_hold
    ? 'Remove Hold On Order'
    : 'Place Order On Hold'
  const newHold = orderData.on_hold ? 'Unhold' : 'Hold'
  if (
    ([
      'validating_coe',
      'coe_issued',
      'finding_appraisers',
      'accepted',
      'inspection_scheduled',
      'inspection_completed',
      'submitted',
      'under_review',
      'revision_requested',
    ].indexOf(orderData.statusKey) > -1 &&
    !orderData.lender_canceled) ||
    orderData.on_hold
  ) {
    const onHoldClick = () => updateOrderStatus(newHold)

    if (orderData.on_hold) {
      return (
        <Button
          className="text-left order-dropdown-button"
          onClick={onHoldClick}
          disabled={orderData.appraiser_requested_cancel}
          block
        >
          <i
            className="dropdown-icon-size mr-2 fa fa-pause-circle-o"
            aria-hidden="true"
          ></i>
          {holdText}
        </Button>
      )
    } else {
      return (
        <ModalTrigger
          updateStatus={updateOrderStatus}
          component={OnHoldModal}
          show={false}
          modalClassName="modal-primary"
          orderData={orderData}
          order_id={orderData.id}
        >
          <div className="border border-light border-top-0">
            <Button
              className="text-left order-dropdown-button"
              disabled={orderData.appraiser_requested_cancel}
              block
            >
              <i
                className="dropdown-icon-size mr-2 fa fa-pause-circle-o"
                aria-hidden="true"
              ></i>
              {holdText}
            </Button>
          </div>
        </ModalTrigger>
      )
    }
  }
}

const renderMenu = (
  orderData,
  using_amc_lender,
  loanData,
  updateOrderStatus,
  props,
  isRequesting,
  flags,
  dispatch
) => {
  const {
    onReassignOrder,
    is_amc_lender,
    is_review_appraiser,
    refreshOrder,
    history,
  } = props

  const holdButton = getHoldButton(orderData, updateOrderStatus)

  const disableTopButtons =
    orderData &&
    (['submitted'].indexOf(orderData.statusKey) > -1 ||
      orderData.lender_canceled)

  const { preventSubmittedCancel, vendorRating, canReassignAmpOrders } = flags || {}
  const disableCancelOrderButton =
    preventSubmittedCancel && orderData &&
    [
      'submitted',
      'amp_review',
      'under_review',
    ].includes(orderData.statusKey)

  const disableReassignButton =
    orderData &&
    ((orderData.job_type === 'amc' && !is_amc_lender && !canReassignAmpOrders) ||
      (orderData.job_type === 'automatically' && !orderData.accepted) ||
      [
        'waiting_for_payment',
        'inspection_completed',
        'submitted',
        'under_review',
        'revision_requested',
      ].indexOf(orderData.statusKey) > -1 ||
        orderData.lender_canceled ||
      orderData.appraisal_scope_appraisal_id ||
      orderData.valutrac_id)

  const hasApprovedSubmission = (orderData.submissions || []).some(submission => submission.lender_approved)
  const canEditVendorScore = vendorRating && is_amc_lender && orderData.job_type === 'amc' && hasApprovedSubmission
  const handleEditScore = () => dispatch(editScore({ orderId: orderData.id, panelistId: orderData.accepted?.id }))

  const menu = []
  if (
    is_review_appraiser ||
    (orderData && orderData.job_type === 'review')
  ) {
    return menu
  } else {
    if (orderData) {
      if (
        !using_amc_lender &&
        !disableTopButtons &&
        checkPermission('consumer_permissons_edit')
      ) {
        menu.push(
          <Menu.Item className="order-action-dropdown-div" key="schedule">
            <ModalTrigger
              component={ConsumerPremissionsModalContainer}
              loan={loanData}
              refreshOrder={refreshOrder}
              orderData={orderData}
              orderId={orderData.id}
              modalClassName="modal-primary modal-lg"
              sentReceipts={orderData.scheduling_information_sent}
            >
              <div className="border border-light">
                <Button className="text-left order-dropdown-button" block>
                  <i className="mr-2 fa fa-calendar" aria-hidden="true"></i>
                  Schedule
                </Button>
              </div>
            </ModalTrigger>
          </Menu.Item>
        )
      }

      if (
        checkPermission('order_set_inspection_date') &&
        (orderData.statusKey === 'accepted' ||
          orderData.statusKey === 'inspection_scheduled')
      ) {
        menu.push(
          <Menu.Item className="order-action-dropdown-div" key="inspectionDate">
            <ModalTrigger
              component={ModalSetInspectionDate}
              loan={loanData}
              orderData={orderData}
              refreshOrder={refreshOrder}
              backdrop='static'
            >
              <div className="border border-light" color="light">
                <Button className="text-left order-dropdown-button" block>
                  <i className="mr-2 fa fa-calendar" aria-hidden="true"></i>
                  {orderData.inspection_date ? 'Edit' : 'Set'} Inspection Date
                </Button>
              </div>
            </ModalTrigger>
          </Menu.Item>
        )
      }

      if (checkPermission('order_hold') && holdButton) {
        menu.push(
          <Menu.Item className="order-action-dropdown-div" key="holdButton">
            {holdButton}
          </Menu.Item>
        )
      }

      if (!disableReassignButton && checkPermission('order_reassign')) {
        // const hasCompanyDirectPayment = orderHasCompanyDirectPayment(orderData)
        menu.push(
          <Menu.Item className="order-action-dropdown-div" key="reassignOrder">
            <ModalTrigger
              // component={hasCompanyDirectPayment ? ModalConfirmReassignment : ModalCreateOrderContainer}
              // modalClassName={hasCompanyDirectPayment ? 'reassginment-confirmation-modal' : 'modal-primary modal-xl'}
              component={ModalCreateOrderContainer}
              modalClassName={'modal-primary modal-xl'}
              onReassignOrder={onReassignOrder}
              selectedLoan={loanData}
              is_reassign_order={true}
              orderData={orderData}
              consumer_payment_style={orderData.consumer_payment_style}
              refreshOrder={refreshOrder}
              history={history}>
              <div className='border border-light'>
                <Button className='text-left order-dropdown-button' block>
                  <i className='dropdown-icon-size mr-2 fa fa-clone' aria-hidden='true'/>
                  Reassign Order
                </Button>
              </div>
            </ModalTrigger>
          </Menu.Item>
        )
      }

      if (!disableCancelOrderButton && checkPermission('order_cancel') && !orderData.lender_canceled) {
        const confirmationText = 'Are you sure you want to cancel this order? Orders cannot be reactivated once they are cancelled.'
        const conformationBody = (<div style={{ minWidth: 650 }}>
          <p>Please review the following and ensure that you have completed all relevant
             actions as certain actions cannot be completed post-cancellation.</p>
          <ul>
            <li>Confirm the vendor&apos;s trip fee, if applicable, and update the order accordingly.</li>
            <li>Ensure that the total order fee is accurate or update the order fee if necessary.</li>
            <li>Confirm that all payments, including fee escalations, have been received.</li>
            <li>Ensure all vendor payouts have been completed/initiate any required vendor payments.</li>
            <li>Confirm that any borrower refunds have been completed/initiate any required refunds.</li>
          </ul>
        </div>)

        menu.push(
          <Menu.Item className="order-action-dropdown-div" key="cancelOrder">
            <ModalTrigger
              component={ModalConfirmation}
              onConfirm={() => updateOrderStatus('Cancelled')}
              confirmationText={confirmationText}
              textBody={conformationBody}
              dismissBtn="No"
              confirmBtn="Yes"
              modalClassName="modal-primary"
              confirmDisabled={isRequesting}
            >
              <div className="border border-light border-top-0">
                <Button className="text-left order-dropdown-button" block>
                  <i
                    className="dropdown-icon-size mr-2 fa fa-times-circle-o"
                    aria-hidden="true"
                  ></i>
                  Cancel Order
                </Button>
              </div>
            </ModalTrigger>
          </Menu.Item>
        )
      }

      if (
        checkPermission('order_create_follow_up') &&
        !orderData.is_follow_up &&
        orderData.requested &&
        !is_amc_lender
      ) {
        menu.push(
          <Menu.Item className="order-action-dropdown-div" key="createFollowUpOrder">
            <ModalTrigger
              selectedLoan={loanData}
              component={ModalCreateOrderContainer}
              modalClassName="modal-primary modal-xl"
              is_follow_up={true}
              refreshOrder={refreshOrder}
              originalOrder={orderData}
              consumer_payment_style={loanData.consumer_payment_style}
              backdrop="static"
              history={history}
            >
              <div className="border border-light border-top-0">
                <Button className="mr-4 text-left order-dropdown-button" block>
                  <i
                    className="dropdown-icon-size mr-2 fa fa-repeat"
                    aria-hidden="true"
                  ></i>
                  Create Follow-up Order
                </Button>
              </div>
            </ModalTrigger>
          </Menu.Item>
        )
      }

      if (
        checkPermission('submission_upload') &&
        orderData.job_type === 'va'
      ) {
        menu.push(
          <Menu.Item className="order-action-dropdown-div" key="manualAppraisalUpload">
            <AntdModalTrigger
              modal={{
                component: ModalManualAppraisalUpload,
                size: 'large',
              }}
              orderId={orderData.id}
              refreshOrder={refreshOrder}
            >
              <div className="border border-light border-top-0">
                <Button className="text-left order-dropdown-button" block>
                  <i className="mr-2 fa fa-upload" aria-hidden="true"></i>
                  <Rephrase>Manual Appraisal Upload</Rephrase>
                </Button>
              </div>
            </AntdModalTrigger>
          </Menu.Item>
        )
      }

      if (
        checkPermission('update_va_order_status') &&
        orderData.job_type === 'va'
      ) {
        menu.push(
          <Menu.Item className="order-action-dropdown-div" key="manuallyMoveOrderStatus">
            <ModalTrigger
              component={ModalUpdateVAOrderStatus}
              orderData={orderData}
              refreshOrder={refreshOrder}
              modalClassName="modal-primary modal-lg"
              onHide={props.onHide}
              is_amc_lender={props.is_amc_lender}
            >
              <div className="border border-light border-top-0">
                <Button className="text-left order-dropdown-button" block>
                  <i
                    className="dropdown-icon-size mr-2 fa fa-exchange"
                    aria-hidden="true"
                  ></i>
                  Update Order Status
                </Button>
              </div>
            </ModalTrigger>
          </Menu.Item>
        )
      }

      if (canEditVendorScore) {
        menu.push(
          <Menu.Item className="order-action-dropdown-div">
            <Button
              className="text-left order-dropdown-button"
              onClick={handleEditScore}
              block
            >
              <i
                className="dropdown-icon-size mr-2 fa fa-edit"
                aria-hidden="true"
              ></i>
              Edit Quality Score
            </Button>
          </Menu.Item>
        )
      }

      return menu
    }
  }
}

const OrderHeaderButtons = (props) => {
  const {
    loanData,
    orderData,
    using_amc_lender,
    refreshOrder,
    history,
    loanIdForEncompass,
  } = props

  const apiRequest = useApiRequest()
  const dispatch = useDispatch()
  const [isRequesting, setIsRequesting] = useState(false)

  const updateOrderStatus = async (status, on_hold_reason) => {
    setIsRequesting(true)
    await apiRequest({
      endpoint: 'order/status',
      method: 'PUT',
      body: { order_id: orderData.id, status, on_hold_reason },
      successMessage: 'Your order has been updated.',
    })
    refreshOrder()
    setIsRequesting(false)
  }

  const flags = useFlags()
  const menu = renderMenu(
    orderData,
    using_amc_lender,
    loanData,
    updateOrderStatus,
    props,
    isRequesting,
    flags,
    dispatch,
  )

  return (
    <>
      <FlagContainer order={orderData} refresh={props.refreshOrder} fontSize='25px' />
      {checkPermission('order_edit') && (
        <EditOrderButton
          loanData={loanData}
          orderData={orderData}
          refreshOrder={refreshOrder}
          history={history}
          flags={flags}
        />
      )}
      {menu.length > 0 && (
        <OrderActions menu={menu} />
      )}
      {loanIdForEncompass && (
        <Button
          className="btn btn-primary"
          onClick={refreshOrder}
        >
          <i className="fa fa-refresh" title="Refresh"></i>
        </Button>
      )}
    </>
  )
}

export default OrderHeaderButtons
