import React from 'react'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { Badge } from 'reactstrap'

const TabLabel = (props) => {
  const { iconType, label, badgeCount } = props
  return <>
    <LegacyIcon type={iconType} />
    {label}
    {badgeCount > 0 && (
      <Badge style={{ marginLeft: '5px' }} pill color={'danger'}>
        {badgeCount}
      </Badge>
    )}
  </>
}

export default TabLabel
