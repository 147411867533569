import { apiToReadable } from '../../common/utils/helpers'

// possible elastic index names
export const INDEXABLE_LENDER_APPRAISAL_PANELIST = 'IndexableLenderAppraisalPanelist'
export const INDEXABLE_APPRAISAL_USER = 'IndexableAppraisalUser'
export const INDEXABLE_LOAN = 'IndexableLoan'
export const INDEXABLE_ORDER = 'IndexableOrder'

// NOTE: IF YOU CHANGE THE VALUES OF EXISTING RULES, YOU WILL NEED TO MIGRATE EXISTING RULES IN THE DB

// Data types for the field
// NOTE: not used by the query builder, only to determine
// which fields can be compared to each other.
export const DATA_TYPES = {
  STRING: 'string',
  NUMBER: 'number',
  BOOLEAN: 'boolean',
  DATE: 'date',
}

export const COMPARABLE_DATA_TYPES = [
  DATA_TYPES.DATE,
]

// Field types for custom_los_loan_fields that we need to map to
// the correct data type
export const CUSTOM_FIELD_TYPES = {
  STRING: 'STRING',
  NUMBER: 'NUMBER',
  BOOLEAN: 'BOOLEAN',
  DATE_STRING_MM_DD_YY: 'DATE_STRING_MM_DD_YY',
}

const MAPPING_TYPE_ELASTICSEARCH = 'ELASTICSEARCH'

export const CUSTOM_FIELD_TYPE_DATA_TYPE_MAP = {
  [CUSTOM_FIELD_TYPES.STRING]: DATA_TYPES.STRING,
  [CUSTOM_FIELD_TYPES.NUMBER]: DATA_TYPES.NUMBER,
  [CUSTOM_FIELD_TYPES.BOOLEAN]: DATA_TYPES.BOOLEAN,
  [CUSTOM_FIELD_TYPES.DATE_STRING_MM_DD_YY]: DATA_TYPES.DATE,
}

// supported operators
export const OPERATORS = [
  { value: '==', label: 'is equal to' },
  { value: '!=', label: 'is not equal to' },
  { value: '<', label: 'is less than' },
  { value: '>', label: 'is greater than' },
  { value: '<=', label: 'is less than or equal to' },
  { value: '>=', label: 'is greater than or equal to' },
  { value: 'in', label: 'is one of the following' },
  { value: 'is_empty', label: 'is empty' },
  { value: 'is_not_empty', label: 'is not empty' },
]

export const OPERATORS_TO_DATE_READABLE = {
  [OPERATORS[0].value]: 'is equal to',
  [OPERATORS[1].value]: 'is not equal to',
  [OPERATORS[2].value]: 'is before',
  [OPERATORS[3].value]: 'is after',
  [OPERATORS[4].value]: 'is before or on',
  [OPERATORS[5].value]: 'is after or on',
  [OPERATORS[6].value]: 'is one of the following',
}

// Elastic Search Date Math Reference
// https://www.elastic.co/guide/en/elasticsearch/reference/current/common-options.html#date-math
export const RELATIVE_DATE_RANGES = [
  { value: 'now+30d/d', title: '30 days from now' },
  { value: 'now+14d/d', title: '14 days from now' },
  { value: 'now+7d/d', title: '7 days from now' },
  { value: 'now+6d/d', title: '6 days from now' },
  { value: 'now+5d/d', title: '5 days from now' },
  { value: 'now+4d/d', title: '4 days from now' },
  { value: 'now+3d/d', title: '3 days from now' },
  { value: 'now+2d/d', title: '2 days from now' },
  { value: 'now+24h', title: '24 hours from now' },
  { value: 'now+12h', title: '12 hours from now' },
  { value: 'now/d', title: 'Today' },
  { value: 'now-12h', title: '12 hours ago' },
  { value: 'now-24h', title: '24 hours ago' },
  { value: 'now-2d/d', title: '2 days ago' },
  { value: 'now-3d/d', title: '3 days ago' },
  { value: 'now-4d/d', title: '4 days ago' },
  { value: 'now-5d/d', title: '5 days ago' },
  { value: 'now-6d/d', title: '6 days ago' },
  { value: 'now-7d/d', title: '7 days ago' },
  { value: 'now-14d/d', title: '14 days ago' },
  { value: 'now-30d/d', title: '30 days ago' },
]

export const DATE_FIELD = {
  type: 'select',
  dataType: DATA_TYPES.DATE,
  valueSources: ['value', 'field'],
  fieldSettings: {
    listValues: RELATIVE_DATE_RANGES,
  },
  widgets: {
    select: {
      operators: [
        'select_equals', 'select_not_equals', 'less', 'less_or_equal', 'greater', 'greater_or_equal',
      ],
    },
  },
  preferWidgets: ['select'],
}

export const MULTI_FIELD = {
  type: 'multiselect',
  valueSources: ['value'],
  operators: ['multiselect_equals', 'multiselect_not_equals'],
  fieldSettings: {
    listValues: [],
    allowCustomValues: true,
  },
  preferWidgets: ['multiselect'],
}

export const FINDING_VENDORS_VALUE = { value: 'Finding Vendors', title: 'Finding Vendors' }

export const AMC_ORDER_STATUS = 'amc_order_status'
export const CLIENT_LENDER_ORDER_STATUS = 'client_lender_order_status'

export const CLIENT_LENDER_INDEXABLE_ORDER_FIELDS = {
  [CLIENT_LENDER_ORDER_STATUS]: {
    label: 'Status',
    type: 'select',
    valueSources: ['value'],
    operators: ['select_equals', 'select_not_equals', 'select_any_in'],
    fieldSettings: {
      listValues: [
        { value: 'Pending Approval', title: 'Pending Approval' },
        { value: 'Waiting for Payment', title: 'Waiting for Payment' },
        { value: 'Finding Appraisers', title: 'Finding Appraisers' },
        { value: 'Accepted', title: 'Accepted' },
        { value: 'Inspection Scheduled', title: 'Inspection Scheduled' },
        { value: 'Inspection Completed', title: 'Inspection Completed' },
        { value: 'AMP Review', title: 'AMP Review' },
        { value: 'Under Review', title: 'Under Review' },
        { value: 'Submitted', title: 'Submitted' },
        { value: 'Cancelled', title: 'Cancelled' },
        { value: 'Requested Cancellation', title: 'Appraiser Requested Cancellation' },
        { value: 'Hold', title: 'Hold' },
        { value: 'Revision Requested', title: 'Revision Requested' },
        { value: 'Revision Submitted', title: 'Revision Submitted' },
        { value: 'Validating COE', title: 'Validating COE' },
        { value: 'COE Issued', title: 'COE Issued' },
      ],
    },
  },
}

export const AMC_LENDER_INDEXABLE_ORDER_FIELDS = {
  [AMC_ORDER_STATUS]: {
    label: 'Status',
    type: 'select',
    dataType: DATA_TYPES.STRING,
    valueSources: ['value'],
    operators: ['select_equals', 'select_not_equals', 'select_any_in'],
    fieldSettings: {
      listValues: [
        { value: 'Pending Approval', title: 'Pending Approval' },
        { value: 'Waiting for Payment', title: 'Waiting for Payment' },
        { value: 'Finding Appraisers', title: 'Finding Appraisers' },
        { value: 'Accepted', title: 'Accepted' },
        { value: 'Inspection Scheduled', title: 'Inspection Scheduled' },
        { value: 'Inspection Completed', title: 'Inspection Completed' },
        { value: 'AMP Review', title: 'AMP Review' },
        { value: 'Under Review', title: 'Under Review' },
        { value: 'Submitted', title: 'Submitted' },
        { value: 'Cancelled', title: 'Cancelled' },
        { value: 'Requested Cancellation', title: 'Appraiser Requested Cancellation' },
        { value: 'Hold', title: 'Hold' },
        { value: 'Revision Requested', title: 'Revision Requested' },
        { value: 'Lender Revisions Requested', title: 'Lender Revisions Requested' },
        { value: 'Revision Submitted', title: 'Revision Submitted' },
        { value: 'Validating COE', title: 'Validating COE' },
        { value: 'COE Issued', title: 'COE Issued' },
      ],
    },
  },
  lender_due_date: {
    label: 'Lender Due Date',
    type: 'select',
    dataType: DATA_TYPES.DATE,
    valueSources: ['value', 'field'],
    fieldSettings: {
      listValues: RELATIVE_DATE_RANGES,
    },
    widgets: {
      select: {
        operators: [
          'select_equals', 'select_not_equals', 'less', 'less_or_equal', 'greater', 'greater_or_equal',
        ],
      },
    },
    preferWidgets: ['select'],
  },
  rucc_code: {
    label: 'RUCC Code',
    type: 'select',
    dataType: DATA_TYPES.STRING,
    operators: ['select_equals', 'select_not_equals', 'select_any_in'],
    valueSources: ['value'],
    fieldSettings: {
      listValues: [
        { value: '1', title: '1 - Counties in metro areas of 1 million population or more' },
        { value: '2', title: '2 - Counties in metro areas of 250,000 to 1 million population' },
        { value: '3', title: '3 - Counties in metro areas of fewer than 250,000 population' },
        { value: '4', title: '4 - Urban population of 20,000 or more, adjacent to a metro area' },
        { value: '5', title: '5 - Urban population of 20,000 or more, not adjacent to a metro area' },
        { value: '6', title: '6 - Urban population of 5,000 to 20,000, adjacent to a metro area' },
        { value: '7', title: '7 - Urban population of 5,000 to 20,000, not adjacent to a metro area' },
        { value: '8', title: '8 - Urban population of fewer than 5,000, adjacent to a metro area' },
        { value: '9', title: '9 - Urban population of fewer than 5,000, not adjacent to a metro area' },
      ],
    },
  },
}

export const INDEXABLE_ORDER_FIELDS = {
  job_type: {
    label: 'Job Type',
    type: 'select',
    valueSources: ['value'],
    operators: ['select_equals', 'select_not_equals', 'select_any_in'],
    fieldSettings: {
      listValues: [
        { value: 'amc', title: 'AMC' },
        { value: 'va', title: 'VA' },
        { value: 'review', title: 'Review' },
        { value: 'lender_choice', title: 'Lender Choice' },
        { value: 'manually', title: 'Manually' },
        { value: 'automatically', title: 'Automatically' },
      ],
    },
  },
  created: {
    label: 'Created Date',
    ...DATE_FIELD,
  },
  due_date: {
    label: 'Due Date',
    ...DATE_FIELD,
  },
  inspection_date: {
    label: 'Inspection Date',
    ...DATE_FIELD,
  },
  included_forms: {
    label: 'Included Forms',
    type: 'select',
    valueSources: ['value'],
    operators: ['select_equals', 'select_not_equals', 'select_any_in'],
    fieldSettings: {
      listValues: [
        { value: 'urar', title: '1004' },
        { value: 'mc', title: '1004MC' },
        { value: 'gpar', title: 'GPAR' },
        { value: 'individual_condo_form', title: '1073 Condo' },
        { value: 'ext_coop_form', title: '1075 Ext. Condo' },
        { value: 'ext_res_form', title: '2055 Ind. Ext.' },
        { value: 'resedential_form', title: '2000 Two to Four' },
        { value: 'reo_addendum', title: 'REO' },
        { value: 'residential_income', title: '71b Residential Income' },
        { value: 'pdr', title: 'PDR' },
      ],
    },
  },
  priority: {
    label: 'Priority',
    type: 'select',
    valueSources: ['value'],
    operators: ['select_equals', 'select_not_equals'],
    fieldSettings: {
      listValues: [
        { value: 'Normal', title: 'Normal' },
        { value: 'Rush', title: 'Rush' },
      ],
    },
  },
  'products.product_name': {
    label: 'Product Name',
    mainWidgetProps: {
      valuePlaceholder: 'The product name must match exactly. Case sensitive.',
    },
    tooltip: 'The product name must match exactly. Case sensitive.',
    ...MULTI_FIELD,
  },
  accepted_firm_name: {
    label: 'Appraiser Name',
    type: 'text',
    tooltip: 'The appraiser name must match exactly. Case sensitive.',
    valueSources: ['value'],
    operators: ['equal', 'not_equal'],
    mainWidgetProps: {
      valuePlaceholder: 'The appraiser name must match exactly. Case sensitive.',
    },
  },
  cu_score: {
    label: 'CU Score',
    type: 'number',
    tooltip: 'The cu score must be an integer. Ex. 2',
    valueSources: ['value'],
    operators: ['equal', 'not_equal', 'less', 'less_or_equal', 'greater', 'greater_or_equal'],
    mainWidgetProps: {
      valuePlaceholder: 'The cu score must be an integer. Ex. 2',
    },
  },
  lender_attention_required: {
    label: 'Lender Attention Required',
    type: 'boolean',
    operators: ['equal', 'not_equal'],
    valueSources: ['value'],
  },
  lender_canceled: {
    label: 'Lender Canceled',
    type: 'boolean',
    operators: ['equal', 'not_equal'],
    valueSources: ['value'],
  },
  on_hold: {
    label: 'On Hold',
    type: 'boolean',
    operators: ['equal', 'not_equal'],
    valueSources: ['value'],
  },
  appraiser_requested_cancel: {
    label: 'Appraiser Requested Cancel',
    type: 'boolean',
    operators: ['equal', 'not_equal'],
    valueSources: ['value'],
  },
  'offers.status': {
    label: 'Offer Status',
    type: 'select',
    valueSources: ['value'],
    operators: ['select_equals', 'select_not_equals', 'select_any_in'],
    fieldSettings: {
      listValues: [
        { value: 'pending', title: 'Pending' },
        { value: 'processing', title: 'Processing' },
        { value: 'accepted', title: 'Accepted' },
        { value: 'denied', title: 'Denied' },
      ],
    },
  },
  'lender_attentions.reason': {
    label: 'Lender Attention Reason',
    type: 'select',
    valueSources: ['value'],
    operators: ['select_equals', 'select_not_equals'],
    fieldSettings: {
      listValues: [
        { value: 'DEFAULT', title: 'No appraisers available' },
        { value: 'FEE_ESCALATION', title: 'Fee Escalation submitted' },
        { value: 'COUNTER_OFFER', title: 'Counter offer submitted' },
        { value: 'COMMENT_ACTION_REQUIRED', title: 'Comment action required' },
        { value: 'NEW_DUE_DATE', title: 'New due date request submitted' },
        { value: 'FEE_ESCALATION_AND_NEW_DUE_DATE', title: 'Fee escalation and due date request submitted' },
        { value: 'NO_ACCEPTED_APPRAISERS_AND_NO_DENIED_COMPANIES', title: 'No appraisers available; no denied companies' },
        { value: 'NO_ACCEPTED_APPRAISERS_AND_HAS_DENIED_COMPANIES', title: 'No appraisers available; has denied companies' },
        { value: 'VA_PORTAL_ISSUE', title: 'Issue submitted to VA portal' },
        { value: 'SUBMISSION_MATCHED_EXCLUSIONARY_LIST', title: 'Submissions from excluded appraiser' },
        { value: 'CONSUMER_DELIVERY_FAILED', title: 'Appraisal report not delivered to borrower' },
        { value: 'SAME_APPRAISER_IS_INVALID', title: 'Follow up appraiser is no longer valid' },
        { value: 'SAME_APPRAISER_ASSIGNED_TO_JUMBO_LOAN_AMP_ORDER', title: 'Jumbo Loan - appraiser already assigned to another order for the same loan' },
        { value: 'LOW_VALUE_APPRAISAL', title: 'A low value appraisal was submitted. Please review the appraisal report.' },
        { value: 'INVALID_ZIP_CODE', title: 'This order or loan has an invalid ZIP code.' },
      ],
    },
  },
  order_request_method: {
    label: 'Order Request Method',
    type: 'select',
    valueSources: ['value'],
    operators: ['select_equals', 'select_not_equals'],
    fieldSettings: {
      listValues: [
        { value: 'broadcast', title: 'Broadcast' },
        { value: 'individually', title: 'Individually' },
      ],
    },
  },
  completion_date: {
    label: 'First Appraisal Submitted Time',
    ...DATE_FIELD,
  },
  'last_submission.created': {
    label: 'Last Appraisal Submitted Time',
    ...DATE_FIELD,
  },
  latest_submission_approval_date: {
    label: 'Last Submission Approved Time',
    ...DATE_FIELD,
  },
  ucdp_submission_status: {
    label: 'UCDP Submission Status',
    type: 'select',
    valueSources: ['value'],
    operators: ['select_equals', 'select_not_equals', 'select_any_in'],
    fieldSettings: {
      listValues: [
        { value: 'success', title: 'Success' },
        { value: 'error', title: 'Error' },
        { value: 'processing', title: 'Processing' },
        { value: 'ready_to_submit', title: 'Ready to Submit' },
      ],
    },
  },
  ead_submission_status: {
    label: 'EAD Submission Status',
    type: 'select',
    valueSources: ['value'],
    operators: ['select_equals', 'select_not_equals', 'select_any_in'],
    fieldSettings: {
      listValues: [
        { value: 'success', title: 'Success' },
        { value: 'error', title: 'Error' },
        { value: 'processing', title: 'Processing' },
        { value: 'ready_to_submit', title: 'Ready to Submit' },
      ],
    },
  },
  'consumer_payments.card_information_captured': {
    label: 'Borrower Card Captured',
    type: 'boolean',
    operators: ['equal', 'not_equal'],
    valueSources: ['value'],
  },
  'consumer_payments.paid': {
    label: 'Borrower Payment Paid',
    type: 'boolean',
    operators: ['equal', 'not_equal'],
    valueSources: ['value'],
  },
  submission_sent_to_consumer: {
    label: 'Borrower Sent Submission',
    type: 'boolean',
    operators: ['equal', 'not_equal'],
    valueSources: ['value'],
  },
  submission_downloaded: {
    label: 'Borrower Downloaded Submission',
    type: 'boolean',
    operators: ['equal', 'not_equal'],
    valueSources: ['value'],
  },
  'parsed_appraisal_data.property_appraised_value_amount': {
    label: 'Property Appraised Value Amount',
    type: 'number',
    operators: ['equal', 'not_equal', 'less', 'less_or_equal', 'greater', 'greater_or_equal'],
    valueSources: ['value'],
    tooltip: 'The value amount must not include commas. Ex. 365000',
    mainWidgetProps: {
      valuePlaceholder: 'The value amount must not include commas. Ex. 365000',
    },
  },
  'parsed_appraisal_data.valuation_reconciliation_condition_of_appraisal_type': {
    label: 'Valuation Reconciliation Condition of Appraisal Type',
    type: 'select',
    valueSources: ['value'],
    operators: ['select_equals', 'select_not_equals', 'select_any_in'],
    fieldSettings: {
      listValues: [
        { value: 'AsIs', title: 'As Is' },
        { value: 'SubjectToCompletion', title: 'Subject To Completion' },
        { value: 'SubjectToRepairs', title: 'Subject To Repairs' },
        { value: 'SubjectToInspections', title: 'Subject To Inspections' },
      ],
    },
  },
  'parsed_appraisal_data.appraisal_effective_date': {
    label: 'Effective Date of Appraisal',
    ...DATE_FIELD,
  },
  'parsed_appraisal_data.appraisal_form_type': {
    label: 'Appraisal Form Type',
    type: 'select',
    valueSources: ['value'],
    operators: ['select_equals', 'select_not_equals', 'select_any_in'],
    fieldSettings: {
      listValues: [
        { value: 'FNM1004', title: '1004' },
        { value: 'FNM1004C', title: '1004C' },
        { value: 'FNM1004D', title: '1004D' },
        { value: 'FNM1025', title: '1025' },
        { value: 'FNM1073', title: '1073' },
        { value: 'FNM1075', title: '1075' },
        { value: 'FNM2055', title: '2055' },
        { value: 'FNM2090', title: '2090' },
      ],
    },
  },
  'parsed_appraisal_data.structure_accessory_unit_exists_indicator': {
    label: 'Accessory Unit Exists',
    type: 'boolean',
    operators: ['equal', 'not_equal'],
    valueSources: ['value'],
  },
  'parsed_appraisal_data.gse_overall_condition_type_subject': {
    label: 'GSE Overall Condition Type (Subject)',
    type: 'select',
    valueSources: ['value'],
    operators: ['select_equals', 'select_not_equals', 'select_any_in'],
    fieldSettings: {
      listValues: [
        { value: 'C1', title: 'C1' },
        { value: 'C2', title: 'C2' },
        { value: 'C3', title: 'C3' },
        { value: 'C4', title: 'C4' },
        { value: 'C5', title: 'C5' },
        { value: 'C6', title: 'C6' },
      ],
    },
  },
  'parsed_appraisal_data.building_status_type': {
    label: 'Building Status Type',
    type: 'select',
    valueSources: ['value'],
    operators: ['select_equals', 'select_not_equals', 'select_any_in'],
    fieldSettings: {
      listValues: [
        { value: 'Existing', title: 'Existing' },
        { value: 'Proposed', title: 'Proposed' },
        { value: 'UnderConstruction', title: 'Under Construction' },
      ],
    },
  },
  'parsed_appraisal_data.property_analysis_conforms_exists_indicator': {
    label: 'Property Analysis Exists - Conforms To Neighborhood',
    type: 'boolean',
    operators: ['equal', 'not_equal'],
    valueSources: ['value'],
  },
  'parsed_appraisal_data.property_analysis_physicaldeficiencies_exists_indicator': {
    label: 'Property Analysis Exists - Physical Deficiencies',
    type: 'boolean',
    operators: ['equal', 'not_equal'],
    valueSources: ['value'],
  },
  'parsed_appraisal_data.property_analysis_adversesiteconditions_exists_indicator': {
    label: 'Property Analysis Exists - Adverse Site Conditions',
    type: 'boolean',
    operators: ['equal', 'not_equal'],
    valueSources: ['value'],
  },
  'parsed_appraisal_data.foundation_condition_exists_indicator_dampness': {
    label: 'Foundation Condition Exists - Dampness',
    type: 'boolean',
    operators: ['equal', 'not_equal'],
    valueSources: ['value'],
  },
  'parsed_appraisal_data.foundation_condition_exists_indicator_settlement': {
    label: 'Foundation Condition Exists - Settlement',
    type: 'boolean',
    operators: ['equal', 'not_equal'],
    valueSources: ['value'],
  },
  'parsed_appraisal_data.foundation_condition_exists_indicator_infestation': {
    label: 'Foundation Condition Exists - Infestation',
    type: 'boolean',
    operators: ['equal', 'not_equal'],
    valueSources: ['value'],
  },
  'parsed_appraisal_data.site_zoning_compliance_type': {
    label: 'Site Zoning Compliance Type',
    type: 'select',
    valueSources: ['value'],
    operators: ['select_equals', 'select_not_equals', 'select_any_in'],
    fieldSettings: {
      listValues: [
        { value: 'Legal', title: 'Legal' },
        { value: 'Nonconforming', title: 'Nonconforming' },
        { value: 'Illegal', title: 'Illegal' },
        { value: 'NoZoning', title: 'NoZoning' },
      ],
    },
  },
  'parsed_appraisal_data.highest_best_use_indicator': {
    label: 'Highest Best Use',
    type: 'boolean',
    operators: ['equal', 'not_equal'],
    valueSources: ['value'],
  },
  has_failed_integration_action: {
    label: 'Integration Action Failed',
    type: 'boolean',
    operators: ['equal', 'not_equal'],
    valueSources: ['value'],
  },
  borrower_consented_to_submission: {
    label: 'Borrower Consented To Submission',
    type: 'boolean',
    operators: ['equal', 'not_equal'],
    valueSources: ['value'],
  },
  submission_marked_as_sent_by_mail: {
    label: 'Submission Marked As "Sent by Mail"',
    type: 'boolean',
    operators: ['equal', 'not_equal'],
    valueSources: ['value'],
  },
  lca_score: {
    label: 'LCA Score',
    type: 'number',
    tooltip: 'The lca score can be an integer (e.g., 5) or a decimal (e.g., 3.5)',
    operators: ['equal', 'not_equal', 'less', 'less_or_equal', 'greater', 'greater_or_equal'],
    valueSources: ['value'],
    mainWidgetProps: {
      valuePlaceholder: 'The lca score can be an integer (e.g., 5) or a decimal (e.g., 3.5)',
    },
  },
  updated: {
    label: 'Last Status Change',
    ...DATE_FIELD,
  },
  'current_reviewer_assignment.reviewer_user_id': {
    label: 'Current Reviewer Assignment',
    type: 'boolean',
    operators: ['is_empty', 'is_not_empty'],
    valueSources: ['value'],
  },
  lender_name: {
    label: 'Lender Name',
    mainWidgetProps: {
      valuePlaceholder: 'The lender name must match exactly. Case sensitive.',
    },
    type: 'text',
    operators: ['equal'],
    valueSources: ['value'],
  },
  payment_status: {
    label: 'Payment Status',
    type: 'select',
    valueSources: ['value'],
    operators: ['select_equals', 'select_not_equals', 'select_any_in'],
    fieldSettings: {
      listValues: [
        { title: 'No Payment Requested', value: 'No Payment Requested' },
        { title: 'Order Paid in Full', value: 'Order Paid in Full' },
        { title: 'Partial Payment', value: 'Partial Payment' },
        { title: 'Pending Payments', value: 'Pending Payments' },
        { title: 'Payment Refund', value: 'Payment Refund' },
        { title: 'Order Overpaid', value: 'Order Overpaid' },
      ],
    },
  },
  has_reconsideration_of_value: {
    label: 'Has Reconsideration of Value',
    type: 'boolean',
    operators: ['equal', 'not_equal'],
    valueSources: ['value'],
  },
  latest_unresolved_revision_date: {
    label: 'Latest Revision Date',
    tooltip: 'Created date of most recent unresolved revision',
    ...DATE_FIELD,
  },
}

export const INDEXABLE_LOAN_FIELDS = {
  loan_type_lower: {
    label: 'Loan Type',
    tooltip: 'The loan type must be all lower case.',
    ...MULTI_FIELD,
    mainWidgetProps: {
      valuePlaceholder: 'The loan type must be all lower case.',
    },
  },
  appraisal_type_lower: {
    label: 'Appraisal Type',
    tooltip: 'The appraisal type must be all lower case.',
    ...MULTI_FIELD,
    mainWidgetProps: {
      valuePlaceholder: 'The appraisal type must be all lower case.',
    },
  },
  subject_property_state_lower: {
    label: 'State',
    type: 'select',
    operators: ['select_equals', 'select_not_equals', 'select_any_in'],
    valueSources: ['value'],
    fieldSettings: {
      listValues: [{
        value: 'al',
        title: 'Alabama',
      }, {
        value: 'ak',
        title: 'Alaska',
      }, {
        value: 'az',
        title: 'Arizona',
      }, {
        value: 'ar',
        title: 'Arkansas',
      }, {
        value: 'ca',
        title: 'California',
      }, {
        value: 'co',
        title: 'Colorado',
      }, {
        value: 'ct',
        title: 'Connecticut',
      }, {
        value: 'de',
        title: 'Delaware',
      }, {
        value: 'fl',
        title: 'Florida',
      }, {
        value: 'ga',
        title: 'Georgia',
      }, {
        value: 'hi',
        title: 'Hawaii',
      }, {
        value: 'id',
        title: 'Idaho',
      }, {
        value: 'il',
        title: 'Illinois',
      }, {
        value: 'in',
        title: 'Indiana',
      }, {
        value: 'ia',
        title: 'Iowa',
      }, {
        value: 'ks',
        title: 'Kansas',
      }, {
        value: 'ky',
        title: 'Kentucky',
      }, {
        value: 'la',
        title: 'Louisiana',
      }, {
        value: 'me',
        title: 'Maine',
      }, {
        value: 'md',
        title: 'Maryland',
      }, {
        value: 'ma',
        title: 'Massachusetts',
      }, {
        value: 'mi',
        title: 'Michigan',
      }, {
        value: 'mn',
        title: 'Minnesota',
      }, {
        value: 'ms',
        title: 'Mississippi',
      }, {
        value: 'mo',
        title: 'Missouri',
      }, {
        value: 'mt',
        title: 'Montana',
      }, {
        value: 'ne',
        title: 'Nebraska',
      }, {
        value: 'nv',
        title: 'Nevada',
      }, {
        value: 'nh',
        title: 'New Hampshire',
      }, {
        value: 'nj',
        title: 'New Jersey',
      }, {
        value: 'nm',
        title: 'New Mexico',
      }, {
        value: 'ny',
        title: 'New York',
      }, {
        value: 'nc',
        title: 'North Carolina',
      }, {
        value: 'nd',
        title: 'North Dakota',
      }, {
        value: 'oh',
        title: 'Ohio',
      }, {
        value: 'ok',
        title: 'Oklahoma',
      }, {
        value: 'or',
        title: 'Oregon',
      }, {
        value: 'pa',
        title: 'Pennsylvania',
      }, {
        value: 'ri',
        title: 'Rhode Island',
      }, {
        value: 'sc',
        title: 'South Carolina',
      }, {
        value: 'sd',
        title: 'South Dakota',
      }, {
        value: 'tn',
        title: 'Tennessee',
      }, {
        value: 'tx',
        title: 'Texas',
      }, {
        value: 'ut',
        title: 'Utah',
      }, {
        value: 'vt',
        title: 'Vermont',
      }, {
        value: 'va',
        title: 'Virginia',
      }, {
        value: 'wa',
        title: 'Washington',
      }, {
        value: 'wv',
        title: 'West Virginia',
      }, {
        value: 'wi',
        title: 'Wisconsin',
      }, {
        value: 'wy',
        title: 'Wyoming',
      }, {
        value: 'as',
        title: 'American Samoa',
      }, {
        value: 'dc',
        title: 'District of Columbia',
      }, {
        value: 'fm',
        title: 'Federated States of Micronesia',
      }, {
        value: 'gu',
        title: 'Guam',
      }, {
        value: 'mh',
        title: 'Marshall Islands',
      }, {
        value: 'mp',
        title: 'Northern Mariana Islands',
      }, {
        value: 'pw',
        title: 'Palau',
      }, {
        value: 'pr',
        title: 'Puerto Rico',
      }, {
        value: 'vi',
        title: 'Virgin Islands',
      }],
    },
  },
  purchase_price: {
    label: 'Purchase',
    type: 'number',
    tooltip: 'The value amount must not include commas. Ex. 365000',
    operators: ['equal', 'not_equal', 'less', 'less_or_equal', 'greater', 'greater_or_equal'],
    valueSources: ['value'],
    mainWidgetProps: {
      valuePlaceholder: 'The value amount must not include commas. Ex. 365000',
    },
  },
  estimated_value: {
    label: 'Estimated Value',
    type: 'number',
    tooltip: 'The value amount must not include commas. Ex. 365000',
    operators: ['equal', 'not_equal', 'less', 'less_or_equal', 'greater', 'greater_or_equal'],
    valueSources: ['value'],
    mainWidgetProps: {
      valuePlaceholder: 'The value amount must not include commas. Ex. 365000',
    },
  },
  geo_county_lower: {
    label: 'County',
    tooltip: 'The county must be all lower case.',
    ...MULTI_FIELD,
    mainWidgetProps: {
      valuePlaceholder: 'The county must be all lower case.',
    },
  },
  subject_property_type_lower: {
    label: 'Property Type',
    tooltip: 'The property type must be all lower case.',
    ...MULTI_FIELD,
    mainWidgetProps: {
      valuePlaceholder: 'The property type must be all lower case.',
    },
  },
  subject_property_zip_lower: {
    label: 'Zip Code',
    ...MULTI_FIELD,
    mainWidgetProps: {
      valuePlaceholder: 'Enter zip code. Ex. 02330',
    },
  },
}

/**
* Sort object properties (only own properties will be sorted).
* @param {object} obj object to sort properties
* @param {string|int} sortedBy 1 - sort object properties by specific value.
* @param {bool} reverse false - reverse sorting.
* @returns {Object} Sorted Objects
*/
export function sortProperties(obj, sortedBy, reverse) {
  sortedBy = sortedBy || 1 // by default first key
  reverse = reverse || false // by default no reverse
  const reversed = (reverse) ? -1 : 1
  const sortable = []
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) { // eslint-disable-line no-prototype-builtins
      sortable.push([key, obj[key]])
    }
  }
  sortable.sort(function(a, b) {
    const x = a[1][sortedBy].toLowerCase()
    const y = b[1][sortedBy].toLowerCase()
    return x < y ? reversed * -1 : x > y ? reversed : 0
  })
  return sortable.reduce((obj, ent) => { obj[ent[0]] = ent[1]; return obj }, /** @type {Any} */({}))
}

const buildCustomDateField = (key, fieldName) => {
  return {
    [key]: {
      label: apiToReadable(fieldName),
      type: 'select',
      dataType: DATA_TYPES.DATE,
      valueSources: ['value', 'field'],
      fieldSettings: {
        listValues: RELATIVE_DATE_RANGES,
      },
      widgets: {
        select: {
          operators: [
            'select_equals', 'select_not_equals', 'less', 'less_or_equal', 'greater', 'greater_or_equal',
          ],
        },
      },
      preferWidgets: ['select'],
    },
  }
}

export const buildCustomFields = (customFields, config) => {
  if (!customFields || !config) {
    return {}
  }

  // Build out each custom field object accord to its Type.
  // For now, only handling 'date' types
  return customFields.reduce((acc, { field_name, field_type, mapping_field_name, mapping_type }) => {
    // NOTE: Current we only support DATE_STRING_MM_DD_YY field_types
    // In the future we will likely expand to new types and should implement a factory here.
    if (mapping_type === MAPPING_TYPE_ELASTICSEARCH && mapping_field_name) {
      if (field_type === CUSTOM_FIELD_TYPES.DATE_STRING_MM_DD_YY) {
        return {
          ...acc,
          ...buildCustomDateField(mapping_field_name, field_name),
        }
      }
    } else {
      acc[`additional_property_data__${field_name}`] = {
        label: apiToReadable(field_name),
        type: 'text',
        valueSources: ['value'],
        operators: ['equal', 'not_equal'],
      }
    }
    return acc
  }, {})
}
