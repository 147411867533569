import { ModalHeader, ModalBody } from 'reactstrap'

export default function ModalInformation(props) {
  const { onHide, title, body } = props
  return (
    <div>
      <ModalHeader toggle={onHide}>{title}</ModalHeader>
      <ModalBody>{body}</ModalBody>
    </div>
  )
}
