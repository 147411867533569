import TextareaAutosize from 'react-textarea-autosize'
import React from 'react'
import PropTypes from 'prop-types'
import RecipientDropdown from './RecipientDropdown'


function onKeyPress(e, onSubmit) {
  if (e.key === 'Enter') {
    e.preventDefault()
    onSubmit(null)
  }
}

export default function NewMessageTem({
  isVendorMessage,
  onFocus,
  newMessageValue,
  handleInputChange,
  newMessageTitle,
  onSubmit,
  userCanApproveMessage,
  userCanChooseRecipient,
  handleRecipientChange,
  recipient,
  conversationCompany,
}) {
  /**
  NewMessageTem component renders UI for creating a message for Unified Conversations.

  NewMessageTem is the equivalent of NewMessage.

  This UI includes a text area for the user to input a message body, a dropdown to select the intended recipient, and a Send button.
  */
  const onSubmitWrapper = () => {
    if (userCanChooseRecipient && !recipient) {
      // extra protection to prevent sending message to Unified Conversation without selecting a recipient
      return
    }
    const isUnifiedConversation = true
    onSubmit({
      userCanApproveMessage,
      isUnifiedConversation,
      userCanChooseRecipient,
    })
  }
  const disableSubmit = newMessageValue === '' || (userCanChooseRecipient && !recipient)
  return (
    <main className='send-message'>
      <div className='w-100'>
        <section className="input-group my-3 send-message-input">
          <TextareaAutosize
            type='text'
            onKeyPress={(e) => onKeyPress(e, onSubmitWrapper)}
            onFocus={onFocus}
            value={newMessageValue}
            onChange={handleInputChange}
            className="form-control mr-2"
            placeholder=""
            title={newMessageTitle}
          />
          <article className="input-group-append">
            {userCanChooseRecipient && (
              <RecipientDropdown
                userCanApproveMessage={userCanApproveMessage}
                handleRecipientChange={handleRecipientChange}
                recipient={recipient}
                onFocus={onFocus}
                conversationCompany={conversationCompany}
              />
            )}
            <button
              onClick={onSubmitWrapper}
              className="btn btn-primary"
              disabled={disableSubmit}
              type="submit"
            >
            Send
            </button>
          </article>
        </section>
      </div>
    </main>)
}


NewMessageTem.propTypes = {
  onFocus: PropTypes.func,
  newMessageValue: PropTypes.string,
  handleInputChange: PropTypes.func,
  newMessageTitle: PropTypes.string,
  onSubmit: PropTypes.func,
  handleRecipientChange: PropTypes.func,
  userCanApproveMessage: PropTypes.bool,
  userCanChooseRecipient: PropTypes.bool,
  recipientValue: PropTypes.string,
  conversationCompany: PropTypes.string,
}
