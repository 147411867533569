import React, { Component } from 'react'
import routes from '../../routes'
import StatusMessage from './components/StatusMessage/StatusMessage'
import RefreshModal from './components/RefreshModal/RefreshModal'
import Versions from './components/Versions'
import UserTokenErrorModal from 'app/common/layout/components/UserTokenErrorModal'
import { RouteRecursive } from '../router'
import { Worker } from '@react-pdf-viewer/core'

class AppContainer extends Component {
  render() {
    return (<div>
      <Worker workerUrl="/pdf.worker.js">
        <StatusMessage />
        <RouteRecursive routes={routes} />
        <RefreshModal />
        <Versions />
        <UserTokenErrorModal/>
      </Worker>
    </div>)
  }
}

export default AppContainer
