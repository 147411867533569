import React from 'react'
import { Button, Table } from 'reactstrap'
import Files from 'react-files'
import axios from 'axios'
import FileSaver from 'file-saver'
import { Alert } from 'antd'
import { utcToLocal } from '../../utils/helpers'
import Rephrase from '../../utils/Rephrase'

const downloadFile = (url, fileName) => {
  const token = localStorage.getItem('reggora_lender_auth_token')
  axios({
    headers: { Authorization: `bearer ${token}`, 'Cache-Control': 'no-cache' },
    url,
    method: 'GET',
    responseType: 'blob', // important
  }).then((response) => {
    FileSaver.saveAs(new Blob([response.data]), fileName)
  })
}

const downloadEvault = (evaultId, documentId, fileName) => {
  const token = localStorage.getItem('reggora_lender_auth_token')
  axios({
    headers: { Authorization: `bearer ${token}`, 'Cache-Control': 'no-cache' },
    url: `${API2_URL}evault/${evaultId}/${documentId}`,
    method: 'GET',
    responseType: 'blob', // important
  }).then((response) => {
    FileSaver.saveAs(new Blob([response.data]), fileName)
  })
}

export default function FileUploader(props) {
  const isDisabled = props.isDisabled
  const onFileDownload = props?.onFileDownload
  const buttonStyle = isDisabled ? { cursor: 'default' } : {}
  const disabledTitle = props?.disabledTitle ?? ''

  return (
    <div>
      {props.hasUploadPermission && <Files
        className='files-dropzone'
        onChange={props.onFilesChange}
        onError={props.onFilesError}
        multiple
        maxFiles={10}
        minFileSize={0}
        clickable={!isDisabled}
      >
        <div
          className='w-100 d-flex flex-row'
          title={disabledTitle}>
          <Button
            onClick={(e) => e.preventDefault()}
            color='success'
            disabled={isDisabled}
            style={buttonStyle}
          >{props.overrideUploadButtonText ? props.overrideUploadButtonText : 'Drop files here or click to upload'}</Button>
        </div>
      </Files>}

      <Table className='my-2'>
        <tbody>
          {props.existing_files && props.existing_files.length > 0
            ? props.existing_files.map((file, idx) => {
              if (props.deleted_files && props.deleted_files.indexOf(file.id) > -1) {
                return null
              }
              return (<tr key={file.id}>
                <td className="w-100">
                  <p className="font-weight-bold mb-0">{file.document_name}
                    <span className="font-weight-normal">    {utcToLocal(file.upload_datetime).format('llll')}</span>
                  </p>
                </td>
                <td className="td--padding-button d-flex">
                  {props.hasRemovePermission &&
                    <Button onClick={(event) => props.existingFileRemove(idx, file, props.p_and_s)} color="danger  margin-right-5">Remove</Button>
                  }
                  <Button
                    type='button'
                    color="primary"
                    onClick={() => {
                      if (onFileDownload) {
                        onFileDownload(file.evault_id, file.id, file.document_name)
                      } else if (file.evault_id) {
                        props.hasDownloadPermission && downloadEvault(file.evault_id, file.id, file.document_name)
                      } else {
                        props.hasDownloadPermission && downloadFile(file.url, file.document_name)
                      }
                    }}
                  >
                    Download
                  </Button>
                </td>
              </tr>)
            }
            )
            : null}
          {props.hasUploadPermission &&
            (!props.existing_files || props.existing_files.length === 0) &&
            props.showEmptyMessage ? (
              <tr>
                <td className="w-100">
                  <Alert
                    message={
                      !props.team_documents
                        ? <Rephrase>
                        No files have been uploaded yet.
                        Click the button above to share files with this appraisal firm.
                        </Rephrase>
                        : 'No files have been uploaded yet. Click the button above to share files within your team.'
                    }
                    type="info"
                  />
                </td>
              </tr>
            ) : !props.hasUploadPermission &&
            (!props.existing_files || props.existing_files.length === 0) &&
            props.showEmptyMessage ? (
                <tr>
                  <td className="w-100">
                    <Alert
                      message="No files have been uploaded yet. Update your users permissions to upload files."
                      type="info"
                    />
                  </td>
                </tr>
              ) : null}
        </tbody>
      </Table>

      <Table className='my-2'>
        <tbody>
          {props.files && props.files.length > 0
            ? props.files.map((file, idx) =>
              <tr key={file.id}>
                <td className="w-100">
                  <p className="font-weight-bold mb-0">{file.name}
                    <span className="font-weight-normal">{file.sizeReadable}</span>
                  </p>
                </td>
                {props.fileRemove
                  ? <td className="td--padding-button d-flex">
                    <Button onClick={(event) => props.fileRemove(idx, file)} id={file.id} color="danger  margin-right-5">Remove</Button>
                  </td>
                  : null}
              </tr>
            )
            : null}
        </tbody>
      </Table>
    </div>
  )
}
