import { of } from 'rxjs/observable/of'

import {
  NOTIFICATION_DECLINE
} from '../../common/utils/resource'
import {
  setOrderDataFromSocket,
  getOrderFromResourceState
} from '../../common/socket/notificationHandler'

export default function notificationsDecline(action$, store) {
  return action$.ofType(NOTIFICATION_DECLINE).mergeMap(action => {
    const orderID = action.notification.notification_object_id
    const resourceState = store.getState().resource
    const order = getOrderFromResourceState(resourceState, orderID)
    if(order) {
      let companyID = action.notification.object_delta.company_id
      const newRequested = []
      let deniedCompany
      for(let i = 0; i < order.requested.length; i++) {
        if(order.requested[i].company === companyID) {
          deniedCompany = order.requested[i]
        } else {
          newRequested.push(order.requested[i])
        }
      }
      if(deniedCompany) {
        const update = {
          denied: [
            ...order.denied,
            deniedCompany
          ],
          requested: newRequested,
          conversation: null
        }
        return of(setOrderDataFromSocket(orderID, update))
      }
    }
    return []
  })
}
