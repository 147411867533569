import { of } from 'rxjs/observable/of'

import {
  NEW_FILE,
} from '../../common/utils/resource'
import {
  setOrderDataFromSocket,
  getOrderFromResourceState,
} from '../../common/socket/notificationHandler'

export default function newFile(action$, store) {
  return action$.ofType(NEW_FILE).mergeMap(action => {
    const {
      orderID,
      file,
    } = action

    const resourceState = store.getState().resource
    const order = getOrderFromResourceState(resourceState, orderID)

    order.evault.documents.push(file)

    if (order) {
      const update = {
        evault: {
          id: order.evault.id,
          documents: order.evault.documents,
        },
      }

      return of(setOrderDataFromSocket(orderID, update))
    };
  })
}
