import React from 'react'
import { useSelector } from 'react-redux'
import { Card } from 'antd'
import { Row, Col, Button } from 'reactstrap'
import ConsumerContact from './ConsumerContact'
import EditConsumerModal from '../loans/EditConsumerModal'
import ModalTrigger from '../../common/modals/ModalTrigger'
import UserCardContainer from '../../common/widgets/UserCardContainer'
import {
  checkPermission,
  checkCanViewAppraiserNameOnOrder,
} from '../../common/utils/helpers'
import { isPdrOrder } from '../../../helpers/pdr_orders'

import { selectCurrentVeteranId, selectCurrentPrimaryObligorId } from '../../store/reducers/order/orderSelectors'

const LoanOrderContacts = (props) => {
  const {
    loanData,
    hideFeatures,
    orderData,
    address,
    loan,
    appraisal_delivery_status_fields_toggles_settings,
    setLoader,
    refreshOrder,
  } = props

  const currentVeteranId = useSelector(selectCurrentVeteranId)
  const currentPrimaryObligor = useSelector(selectCurrentPrimaryObligorId)
  const isPDR = isPdrOrder(orderData)

  let appraiserCard

  // If valid permission & order has valid accepted data, then set data needed to render the key contact "Accepted" appraiserCard
  if (
    orderData &&
    orderData.accepted &&
    checkCanViewAppraiserNameOnOrder(orderData)
  ) {
    const name = orderData.accepted.panelist_name
    const phone = orderData.accepted.phone
    const email = orderData.accepted.email
    const company_name = orderData.accepted.company_name || null

    appraiserCard = (
      <UserCardContainer
        position={isPDR ? 'Vendor' : 'Appraiser'}
        name={name}
        id={orderData.accepted && orderData.accepted.id}
        workPhone={phone}
        email={email}
        company_name={company_name}
      />
    )
  }

  const renderCreateConsumer = () => {
    if (!hideFeatures) {
      return (
        checkPermission('consumer_create') && (
          <div className="card mr-4 d-flex">
            <ModalTrigger
              component={EditConsumerModal}
              modalClassName="modal-primary"
              loan_id={loanData.id}
              refetch={loan ? loan.fetch : props.refreshOrder}
              is_va_consumer={loanData.is_va_loan}
              setLoader={props.setLoader}
              // If there is already either of these, then we cant have multiple on the loan so disable the boxes
              disable_select_veteran={currentVeteranId}
              disable_select_primary_obligor={currentPrimaryObligor}
            >
              <Button className="btn btn-light create-consumer-btn">
                <small>Create New Contact</small>
              </Button>
            </ModalTrigger>
          </div>
        )
      )
    }
  }

  const renderLoanOfficers = () => {
    if (!hideFeatures) {
      return (
        loanData.officer && (
          <UserCardContainer
            position="Loan Officer"
            name={`${loanData.officer.firstname || ''} ${loanData.officer
              .lastname || ''}`}
            id={loanData.officer.id}
            workPhone={loanData.officer.phone_number}
            email={loanData.officer.email}
          />
        )
      )
    }
  }

  return (
    <Row>
      <Col xs="12" sm="12" md="12">
        <Card className="card-accent-primary">
          <div className="scrolling-wrapper-flexbox">
            {renderLoanOfficers()}
            {appraiserCard}
            {loanData.consumers
              .filter((c) => !c.hidden)
              .map((consumer, i) => (
                <ConsumerContact
                  key={consumer.id}
                  loanData={loanData}
                  orderData={orderData}
                  loan={loan}
                  consumer={consumer}
                  address={address}
                  appraisal_delivery_status_fields_toggles_settings={
                    appraisal_delivery_status_fields_toggles_settings
                  }
                  setLoader={setLoader}
                  refreshOrder={refreshOrder}
                  current_veteran={currentVeteranId}
                  current_primary_obligor={currentPrimaryObligor}
                  hideFeatures={props.hideFeatures}
                />
              ))}
            {renderCreateConsumer()}
          </div>
        </Card>
      </Col>
    </Row>
  )
}

export default LoanOrderContacts
