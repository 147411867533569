import React, { Fragment } from 'react'
import { ModalHeader, ModalBody, FormGroup, Col, Label, ModalFooter, Button, Badge, Input } from 'reactstrap'
import moment from 'moment'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import Rephrase from '../../../common/utils/Rephrase'


export default function SendBorrowerByMail(props) {
  const mailInfo = [...props.mailedByInformation]
  const mailedByInformation = mailInfo.reverse()

  return (
    <Fragment>
      <ModalHeader toggle={props.toggle}><Rephrase> Send Appraisal By Mail To Consumers </Rephrase></ModalHeader>
      <ModalBody>
        <FormGroup row>
          <Col xs="12">
            <Label htmlFor="select">Consumer(s)</Label>
            <Select
              name='users'
              valueKey={'id'}
              labelKey={'email'}
              placeholder={'Please select'}
              options={props.consumers.filter(consumer => consumer.email && consumer.email !== '')}
              multi={true}
              value={props.selectedConsumers}
              onChange={props.handleChange}
              removeSelected={true}
              optionRenderer={option => {
                let color = 'secondary'
                let badgeText = 'Not marked as sent'
                const sentConfirmation = mailedByInformation.find(s => {
                  return s.consumer_id && (s.consumer_id === option.id)
                })

                let name = ''
                let role = ''
                if (option.role) {
                  role = option.role[0].toUpperCase() + option.role.slice(1)
                }

                if (option.name) {
                  name = option.name
                } else {
                  name = option.firstname + ' ' + option.lastname
                }

                if (sentConfirmation) {
                  color = 'success'
                  badgeText = `Marked sent ${moment(sentConfirmation.time).format('MM/DD/YYYY')} by ${sentConfirmation.mailed_by.substr(0, 15)}`
                }

                return (<span>
                  {name} | {role}
                  <Badge className='ml-1' color={color}>{badgeText}</Badge>
                </span>)
              }}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col xs="12">
            <Label>Mailed Date</Label>
          </Col>
          <Col xs='12'>
            <div className='mailedDateTime'>
              <DatePicker
                name='mailedDateTime'
                onChange={props.handleDateChange}
                showTimeSelect
                selected={props.mailedDateTime}
                className='form-control'
                timeIntervals={15}
                timeCaption='Time'
                dateFormat='MM/DD/YYYY h:mm a'
              ></DatePicker>
            </div>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col xs="12">
            <Label>Mailed By</Label>
          </Col>
          <Col xs="12">
            <Input name='mailedBy' value={props.mailedBy} onChange={props.onChange} />
          </Col>
        </FormGroup>

      </ModalBody>
      <ModalFooter className="border-0">
        <Button onClick={props.onHide}>Cancel</Button>
        <Button disabled={props.selectedConsumers.length < 1} color='primary' onClick={props.onSubmit}>Save</Button>
      </ModalFooter>
    </Fragment>
  )
}
