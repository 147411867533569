import { loadableWithRetry } from '../../common/utils/lazyLoad'

const routes = [
  {
    path: '/review-rule-sets',
    component: loadableWithRetry(() => import('./ReviewRuleSets')),
    name: 'reviewRuleSets',
    publicRoute: true,
    exact: true,
  },
  {
    path: '/review-rule-sets/:id',
    name: 'singleReviewRuleSet',
    component: loadableWithRetry(() => import('./SingleReviewRuleSet')),
    publicRoute: true,
    exact: true,
  },
]

export default routes
