import React from 'react'
import { F_PUBLIC, userLevelSelector } from './access'
import { checkPermission } from '../utils/helpers'

function CheckAccess({ access = F_PUBLIC, fallback = null, children, name, publicRoute, ...props }) {
  const level = userLevelSelector()
  const childrenWithProps = React.Children.map(children, child => {
    return React.cloneElement(child, props)
  })

  if (publicRoute) {
    return childrenWithProps
  } else if (!name) {
    return level && access ? childrenWithProps : fallback
  } else {
    return checkPermission(`${name}_route`) ? childrenWithProps : fallback
  }
}

export default CheckAccess
