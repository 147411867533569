import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { connectResource } from '../../../common/utils/resource'
import { GSE_HARDSTOP_CODES_TO_OVERRIDE_CODES, GSE_OVERRIDES_REQUIRING_COMMENT } from '../../../common/constants/gse'
import { Tooltip, Input } from 'antd'
import Select from 'react-select'
import Rephrase from '../../../common/utils/Rephrase'

import {
  Button,
  Card,
  CardBody,
  Col,
  Label,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
} from 'reactstrap'
const { TextArea } = Input

const updateArrayHelper = require('immutability-helper')

class SubmitOverrideRequestModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      overrideForm: this.generateOverrideForm(),
    }
  }

  generateOverrideForm = () => {
    return this.props.hardStopsCodes.map((hardStopsCode, idx) => {
      const defaultHardstopReasons = GSE_HARDSTOP_CODES_TO_OVERRIDE_CODES[hardStopsCode.code]
        ? GSE_HARDSTOP_CODES_TO_OVERRIDE_CODES[hardStopsCode.code] : []
      return {
        hardStopsCode: hardStopsCode.code,
        requiresComment: GSE_OVERRIDES_REQUIRING_COMMENT.includes(defaultHardstopReasons[0]?.value),
        reasonForOverride: defaultHardstopReasons[0]?.value,
        comments: '',
      }
    })
  }


  onSubmit = async () => {
    const {
      gseType,
      version,
      appraisalNumber,
      orderId,
      eadOverrideResource,
      ucdpOverrideResource,
      refreshOrder,
      onHide,
    } = this.props

    await Promise.all(this.state.overrideForm.map(async form => {
      if (gseType === 'Ucdp') {
        return await ucdpOverrideResource.create({
          order_id: orderId,
          version: version,
          appraisal_number: appraisalNumber,
          hardstop_code: form.hardStopsCode,
          override_code: form.reasonForOverride,
          override_comment: form.comments,
        })
      }

      if (gseType === 'Ead') {
        return await eadOverrideResource.create({
          order_id: orderId,
          version: version,
          appraisal_number: appraisalNumber,
          hardstop_code: form.hardStopsCode,
          override_code: form.reasonForOverride,
          override_comment: form.comments,
        })
      }
    }))
    onHide()
    refreshOrder()
  }

  handleSelectChange = (selected, idx) => {
    const overrideForm = this.state.overrideForm
    const requiresComment = GSE_OVERRIDES_REQUIRING_COMMENT.includes(selected.value) && overrideForm[idx].comments === ''
    const updatedForm = updateArrayHelper(overrideForm[idx],
      {
        reasonForOverride: { $set: selected.value },
        requiresComment: { $set: requiresComment },
      })
    var newOverrideForm = updateArrayHelper(overrideForm, {
      $splice: [[idx, 1, updatedForm]],
    })

    this.setState({
      overrideForm: newOverrideForm,
    })
  }

  handleInputComment = (event, idx) => {
    const target = event.target
    const value = target.value
    const overrideForm = this.state.overrideForm
    const requiresComment = GSE_OVERRIDES_REQUIRING_COMMENT.includes(overrideForm[idx].reasonForOverride) && value === ''

    const updatedForm = updateArrayHelper(overrideForm[idx],
      {
        comments: { $set: value },
        requiresComment: { $set: requiresComment },
      })
    var newOverrideForm = updateArrayHelper(overrideForm, {
      $splice: [[idx, 1, updatedForm]],
    })

    this.setState({
      overrideForm: newOverrideForm,
    })
  }

  renderOverrideRequest = (hardStopsCode, idx) => {
    return (<Card className='mb-4 mt-1' key={idx}>
      <CardBody>
        <Label>Code: <b>{hardStopsCode.code}</b> </Label><br/>
        <Label className='mt-3'>Reason for Manual Override:</Label>
        <Select
          name='reasonForOverride'
          clearable={false}
          options={GSE_HARDSTOP_CODES_TO_OVERRIDE_CODES[hardStopsCode.code]}
          onChange={(e) => this.handleSelectChange(e, idx)}
          value={this.state.overrideForm[idx]?.reasonForOverride}
          required
        />
        <Label className='mt-3'>Comments:</Label>
        <TextArea
          placeholder='Comments...'
          className='w-100'
          autoSize={{ minRows: 4, maxRows: 18 }}
          type="text"
          name="comments"
          value={this.state.overrideForm[idx]?.comments}
          onChange={(e) => this.handleInputComment(e, idx)}
          maxLength={250}
        />

      </CardBody>
    </Card>)
  }

  render() {
    const {
      onHide,
      hardStopsCodes,
    } = this.props


    const requiresComment = this.state.overrideForm.some((form) => {
      return form.requiresComment === true
    })

    return (<Fragment>
      <ModalHeader toggle={onHide}>Manual GSE Error Override Request </ModalHeader>
      <ModalBody>
        <Row>
          <Col xs='12'>
            <p>Please select a reason and provide a comment if you choose &quot;Other.&quot;</p>
            {hardStopsCodes.map(this.renderOverrideRequest)}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onHide}>Cancel</Button>
        <Tooltip trigger={ requiresComment ? 'click' : ''} placement="top" title="Comment required when “other” is selected">
          <span style={{ cursor: requiresComment ? 'not-allowed' : 'pointer' }} >
            <Button color='primary' onClick={this.onSubmit} disabled={requiresComment} style={{ pointerEvents: requiresComment ? 'none' : 'auto' }}><Rephrase>Re-submit appraisal to GSEs</Rephrase></Button>
          </span>
        </Tooltip>
      </ModalFooter>
    </Fragment>)
  }
}

export default compose(
  connectResource({
    prefetch: false,
    namespace: 'ucdpOverrideResource',
    endpoint: 'order/ucdp-override',
    apiVersion: 2,
    successMessage: {
      POST: 'Your Override Request is being processed and will be sent to GSE shortly',
    },
  }),
  connectResource({
    prefetch: false,
    namespace: 'eadOverrideResource',
    endpoint: 'order/ead-override',
    apiVersion: 2,
    successMessage: {
      POST: 'Your Override Request is being processed and will be sent to GSE shortly',
    },
  })
)(SubmitOverrideRequestModal)
