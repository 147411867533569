import React, { Fragment } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  FormGroup,
  Label,
  Col,
  Button,
} from 'reactstrap'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import classnames from 'classnames'
import Select from 'react-select'

import ModalConfirmation from '../../common/modals/ModalConfirmation'
import { states } from '../../common/utils/helpers'
import Loader from '../../common/layout/components/Loader'

const { TextArea } = Input

const stateOptions = states.map(state => ({ value: state.short, label: state.long }))

export const ModalROVOld = props => {
  // Only the AMP lender is allowed to edit existing ROVs
  const isReadOnly = props.existingRevision && !props.is_amc_lender

  return (
    <div>
      <ModalHeader color='primary' toggle={props.onHide}>Reconsideration Of Value</ModalHeader>
      {props.loading && <Loader />}
      <Fragment>
        <ModalBody>
          <FormGroup >
            <Label>Reason for Reconsideration of Value</Label>
            <TextArea
              name='reason'
              value={props.reason}
              onChange={e => props.onTextChange(e)}
              placeholder='Reasoning...'
              disabled={isReadOnly}
            />
          </FormGroup>

          <Nav tabs>

            {props.comps.map((comp, idx) => (
              <NavItem key={idx}>
                <NavLink
                  className={classnames({ active: props.tab === `comp #${idx + 1}`, nav_flex: true })}
                  onClick={() => props.changeTab(`comp #${idx + 1}`)}
                  disabled={isReadOnly}
                >
                  {`comp #${idx + 1}`}
                  <CloseOutlined aria-label='close' className='ml-2' onClick={_ => props.onCloseComp(idx)} />
                  <Modal isOpen={props.showCloseModal[idx]} >
                    <ModalBody>
                      <ModalConfirmation
                        confirmationText={'Are you sure you want to remove this Comp?'}
                        onConfirm={_ => props.onRemoveComp(idx)}
                        onHide={_ => props.onHideCompModal(idx)}
                        confirmBtn='Delete'
                        dismissBtn='Cancel'
                        modalClassName='modal-primary'
                      />
                    </ModalBody>
                  </Modal>
                </NavLink>
              </NavItem>
            ))}
            <NavItem>
              <NavLink
                className={classnames({ nav_flex: true })}
                onClick={props.addComp}
                disabled={isReadOnly}
              >
                <PlusOutlined className='mr-' />
              Add Comp
              </NavLink>
            </NavItem>
          </Nav>
          {props.comps.map((comp, idx) => (
            <FormGroup key={idx} className={classnames({ 'd-none': props.tab !== `comp #${idx + 1}`, 'mt-4': true })}>
              <Label>Property Address</Label>
              <Input
                name='property_address'
                value={comp.property_address}
                onChange={e => props.onTextChange(e, idx)}
                placeholder='Address'
                disabled={isReadOnly}
              />
              <FormGroup row className="mt-3 mb-3">
                <Col xs="4">
                  <Label>City</Label>
                  <Input
                    name='city'
                    value={comp.city}
                    onChange={e => props.onTextChange(e, idx)}
                    placeholder='City'
                    disabled={isReadOnly}
                  />
                </Col>
                <Col xs="4">
                  <Label>State</Label>
                  <Select
                    value={comp.state}
                    onChange={value => props.onSelect(value, idx)}
                    options={stateOptions}
                    isSearchable={true}
                    placeholder='State'
                    disabled={isReadOnly}
                  />
                </Col>
                <Col xs="4">
                  <Label>Zip</Label>
                  <Input
                    name='zip'
                    value={comp.zip}
                    onChange={e => props.onTextChange(e, idx)}
                    placeholder='Zip'
                    disabled={isReadOnly}
                  />
                </Col>
              </FormGroup>
              <FormGroup row className="mt-3 mb-3">
                <Col xs="6">
                  <Label>Sales Price</Label>
                  <Input
                    name='sales_price'
                    value={comp.sales_price}
                    onChange={e => props.onTextChange(e, idx)}
                    placeholder='Sales Price'
                    disabled={isReadOnly}
                  />
                </Col>
                <Col xs="6">
                  <Label>Date of Sale</Label>
                  <Input
                    name='date_of_sale'
                    value={comp.date_of_sale}
                    onChange={e => props.onTextChange(e, idx)}
                    placeholder='Date of Sale'
                    disabled={isReadOnly}
                  />
                </Col>
              </FormGroup>
              <FormGroup row className="mt-3 mb-3">
                <Col xs="4">
                  <Label>Reported Heated Sq. Footage</Label>
                  <Input
                    name='reported_heated_sq_footage'
                    value={comp.reported_heated_sq_footage}
                    onChange={e => props.onTextChange(e, idx)}
                    placeholder='Sq. Footage'
                    disabled={isReadOnly}
                  />
                </Col>
                <Col xs="4">
                  <Label>Reported Actual Age</Label>
                  <Input
                    name='reported_actual_age'
                    value={comp.reported_actual_age}
                    onChange={e => props.onTextChange(e, idx)}
                    placeholder='Actual Age'
                    disabled={isReadOnly}
                  />
                </Col>
                <Col xs="4">
                  <Label>Reported Site Size</Label>
                  <Input
                    name='reported_site_size'
                    value={comp.reported_site_size}
                    onChange={e => props.onTextChange(e, idx)}
                    placeholder='Site Size'
                    disabled={isReadOnly}
                  />
                </Col>
              </FormGroup>
              <FormGroup row className="mt-3 mb-3">
                <Col xs="12">
                  <Label>Source of Data</Label>
                  <Input
                    name='source_of_data'
                    value={comp.source_of_data}
                    onChange={e => props.onTextChange(e, idx)}
                    placeholder='Source of Data'
                    disabled={isReadOnly}
                  />
                </Col>
              </FormGroup>
              <FormGroup row className="mt-3 mb-3">
                <Col xs="12">
                  <Label>APN/MLS#</Label>
                  <Input
                    name='apn_mls_number'
                    value={comp.apn_mls_number}
                    onChange={e => props.onTextChange(e, idx)}
                    placeholder='APN/MLS#'
                    disabled={isReadOnly}
                  />
                </Col>
              </FormGroup>
              <FormGroup row className="mt-3 mb-3">
                <Col xs="12">
                  <Label>Additional Comments/Data</Label>
                  <TextArea
                    name='additional_comments'
                    value={comp.additional_comments}
                    onChange={e => props.onTextChange(e, idx)}
                    placeholder='Additional Comments/Data...'
                    disabled={isReadOnly}
                  />
                </Col>
              </FormGroup>
            </FormGroup>
          ))}
          <Modal isOpen={props.showConfirmsubmit} >
            <ModalBody>
              <ModalConfirmation
                confirmationText={props.confirmationText}
                onConfirm={props.onSubmit}
                onHide={props.onHideSubmit}
                confirmBtn='Submit ROV'
                dismissBtn='Go back'
              />
            </ModalBody>
          </Modal>
        </ModalBody>
      </Fragment>
      <ModalFooter>
        <Button disabled={props.loading} onClick={props.onHide}>Cancel</Button>
        <Button disabled={props.loading || isReadOnly} onClick={props.onConfirmSubmit} color='primary'>
          {props.existingRevision ? 'Save' : 'Create'}
        </Button>
      </ModalFooter>
    </div>
  )
}
