import { of } from 'rxjs/observable/of'
import { concat } from 'rxjs/observable/concat'
import { fromPromise } from 'rxjs/observable/fromPromise'
import { switchMap, catchError } from 'rxjs/operators'
import { setData, setErrors } from '@common/utils/resource'
import { UPDATE_APPRAISER_EXCLUSIONARY_LIST } from '@app/pages/appraisers/actions'


export default function updateAppraiserExclusionaryListEpic(action$, store, { API }) {
  return action$.ofType(UPDATE_APPRAISER_EXCLUSIONARY_LIST).mergeMap(action => {
    const namespace = 'exclusionaryList'
    const currentStore = store.getState()
    const currentData = currentStore.resource.exclusionaryList.data
    const currentCount = currentStore.resource.exclusionaryList.count
    const updatedData = [...currentData]
    return concat(
      of(setData(
        { results: currentData, count: currentCount, isLoading: true },
        { resource: { namespace, list: true } },
      )),
      fromPromise(API('appraiser/exclusionary', 2).request('PUT', undefined, {
        ...action.data,
        first_name: action.data.first_name.trim(),
        last_name: action.data.last_name.trim(),
        license_number: action.data.license_number.trim(),
        license_state: action.data.license_state?.short || action.data.license_state,
      })).pipe(
        switchMap(response => {
          if (response.created) {
            updatedData.unshift(response.created)
          }
          if (response.updated) {
            const updatedIndex = updatedData.findIndex((exclusion) => exclusion.id === response.updated.id)
            updatedData[updatedIndex] = response.updated
          }
          return of(setData(
            { results: updatedData, count: currentCount },
            { resource: { namespace, successMessage: { PUT: response.success }, list: true }, type: 'PUT' },
          ))
        }),
        catchError(error => of(setErrors({ error: error.error }, { resource: { namespace } }))),
      ),
      of(setData(
        { results: updatedData, count: currentCount, isLoading: false },
        { resource: { namespace, list: true } },
      )),
    )
  })
}
