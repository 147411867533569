import { Button, Tooltip } from 'antd'

import { ExportOutlined } from '@ant-design/icons'
import ModalTrigger from '@app/common/modals/antd/ModalTrigger'
import React from 'react'
import { ReviewLogModal } from './ReviewLogModal'
import styles from './ReviewLog.module.css'
import { USER_TYPES } from '@app/common/constants/userTypes'

export const ReviewLog = ({ fieldData }) => {
  const hasLogs = fieldData.outcome_override_logs?.length > 1
  const hasSystemLogs = fieldData.outcome_override_logs?.some(log => log.user_type === USER_TYPES.SYSTEM)

  if (!hasLogs || !hasSystemLogs) { return null }

  return (
    <ModalTrigger
      modal={{
        component: ReviewLogModal,
      }}
      fieldData={fieldData}
    >
      <Tooltip
        arrowPointAtCenter
        placement="topLeft"
        title={'Review Log'}
      >
        <Button type='default' className={styles.btn}>
          <ExportOutlined />
        </Button>
      </Tooltip>
    </ModalTrigger>
  )
}
