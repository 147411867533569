const privateLDAttributeNames = ['email']
const getLDUser = function(userData) {
  return {
    key: userData?.id,
    email: userData?.email,
    custom: {
      type: 'LENDER_USER',
      role: userData?.role?.name,
      lender: userData?.lender?.name,
      lender_name: userData?.lender?.name,
      lender_id: userData?.lender?.id,
    },
    privateLDAttributeNames,
  }
}

export { privateLDAttributeNames, getLDUser }
