import React from 'react'
import { Container } from 'reactstrap'
import LoanOrderBreadCrumbs from './LoanOrderBreadCrumbs'
import OrderToolbar from './OrderToolbar'
import OrderTabs from './OrderTabs'


const OrderHeader = (props) => {
  const {
    loanNumber,
    county,
    orderTabs,
    activeOrderTab,
    address,
    orderData,
    loanData,
    using_amc_lender,
    toggleOrder,
    handleDelete,
    refreshOrder,
    history,
    onReassignOrder,
    is_amc_lender,
    loanIdForEncompass,
  } = props


  return (
    <Container fluid className="reggora-bb bg-white mb-3">
      <div className="dashboard-top animated fadeIn">
        <div>
          <LoanOrderBreadCrumbs
            linkTo="/orders"
            linkText="Orders"
            loanNumber={loanNumber}
          />
          {orderTabs && orderTabs.length > 0 && (
            <OrderTabs
              orderTabs={orderTabs}
              activeOrderTab={activeOrderTab}
              toggleOrder={toggleOrder}
            />
          )}
          <OrderToolbar
            loanNumber={loanNumber}
            county={county}
            address={address}
            loanData={loanData}
            orderData={orderData}
            using_amc_lender={using_amc_lender}
            handleDelete={handleDelete}
            refreshOrder={refreshOrder}
            history={history}
            onReassignOrder={onReassignOrder}
            is_amc_lender={is_amc_lender}
            loanIdForEncompass={loanIdForEncompass}
          />
        </div>
      </div>
    </Container>
  )
}

export default OrderHeader
