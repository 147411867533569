import React, { useState, useEffect } from 'react'
import { Alert, Button } from 'reactstrap'
import { useSelector } from 'react-redux'
import FeeEscalationAlert from './FeeEscalationAlert'
import VAAlerts from './VAAlerts'
import { checkPermission } from '../../common/utils/helpers'
import IndividualOrderAlert from './individualOrderAlert'
import { useApiRequest } from '../../common/hooks'
import { ESCALATION_ALERT } from '../../common/constants/alerts'
import Rephrase from '../../common/utils/Rephrase'
import { selectLenderId } from '@app/store/reducers/user/userSelectors'
import { selectLatestLenderAttention } from '@app/store/reducers/order/orderSelectors'

const OrderAlertsActionable = (props) => {
  const {
    orderData,
    loanData,
    refreshOrder,
    is_amc_lender,
    overrideWait,
    pending_approval_status_help_text,
    integrationLogs,
    refreshIntegrationLogs,
    borrower_payments_enabled,
    currentUserID,
  } = props

  const lenderId = useSelector(selectLenderId)
  const lenderAttention = useSelector(state => selectLatestLenderAttention(
    state,
    is_amc_lender ? null : lenderId,
    ESCALATION_ALERT,
  ))
  const [orderStatusText, setOrderStatusText] = useState('')

  useEffect(() => {
    const getOrderRequirementsStatusText = () => {
      if (pending_approval_status_help_text && orderData.requirements_status_text) {
        return `${pending_approval_status_help_text} - ${orderData.requirements_status_text}`
      }
      if (pending_approval_status_help_text !== '') {
        return pending_approval_status_help_text
      }
      if (orderData.requirements_status_text !== '') {
        return orderData.requirements_status_text
      }

      return ''
    }

    setOrderStatusText(getOrderRequirementsStatusText())
  }, [pending_approval_status_help_text, orderData])

  const apiRequest = useApiRequest()

  const handlePending = async (orderId, action) => {
    await apiRequest({
      endpoint: 'order/pending',
      method: 'PUT',
      body: { order_id: orderId, action },
    })

    refreshOrder()
  }

  const onOverrideWaitClick = () => {
    overrideWait(orderData.id)
  }

  const onApprovePending = () => {
    handlePending(orderData.id, 'approve')
  }

  const onDeclinePending = () => {
    handlePending(orderData.id, 'decline')
  }

  // check if total order fee is covered by the payments
  // if so, the payment banner will display different text
  let totalFeeCovered = false

  let totalPaymentsPaid = 0
  if (orderData) {
    // get order fee in cents
    const orderFee = orderData.consumer_fee * 100

    orderData &&
      orderData.schedule &&
      orderData.schedule.payments &&
      orderData.schedule.payments.forEach((payment) => {
        if (payment.paid === true) {
          totalPaymentsPaid += parseFloat(payment.amount)
        }
      })

    if (totalPaymentsPaid >= orderFee) {
      totalFeeCovered = true
    }
  }

  return (
    <>
      {orderData && orderData.job_type === 'va' && orderData.va_actions && (
        <VAAlerts
          id={orderData.id}
          va_actions={orderData.va_actions}
          refreshOrder={refreshOrder}
          vaFieldsToReadable={loanData.loan_va_fields_to_readable}
        />
      )}

      {orderData &&
      orderData.statusKey === 'waiting_for_payment' &&
      !orderData.lender_canceled &&
      checkPermission('order_override_waiting_for_payment') &&
      !is_amc_lender &&
      checkPermission('user_view_order_fee') && (
        <Alert color="primary" className="my-2">
          {!totalFeeCovered ? (
            <>
              <p>
                <Rephrase>
                  This order is still awaiting payment. If you would like to
                  override this status and send the order to an Appraiser, click
                  the button below
                </Rephrase>
              </p>
              <Button
                color="dark"
                className="order-header-button"
                onClick={onOverrideWaitClick}
              >
                Override Waiting For Payment
              </Button>
            </>
          ) : (
            'Payment is being processed'
          )}
        </Alert>
      )}
      {orderData &&
      orderData.statusKey === 'pending_approval' &&
      !orderData.lender_canceled &&
      orderStatusText && (
        <Alert color="danger">{orderStatusText}</Alert>
      )}

      {integrationLogs.map((integrationLog) => {
        return (
          <IndividualOrderAlert
            orderData={orderData}
            key={integrationLog.id}
            integrationLog={integrationLog}
            integrationLogId={integrationLog.id}
            refreshIntegrationLogs={refreshIntegrationLogs}
          />
        )
      })}

      {lenderAttention.reason && (
        <FeeEscalationAlert
          orderData={orderData}
          orderId={orderData.id}
          totalDue={orderData.total_appraiser_fee}
          totalPaid={orderData.payment_summary.total_paid}
          refreshOrder={refreshOrder}
          currentUserID={currentUserID}
          borrowerPaymentsEnabled={borrower_payments_enabled}
          loan_file={loanData}
          is_amc_lender={is_amc_lender}
        />
      )}

      {orderData &&
      orderData.statusKey === 'pending_approval' &&
      !orderData.lender_canceled &&
      checkPermission('order_approve_pending') &&
      !is_amc_lender && (
        <Alert color="primary">
          <>
            <p>
              This order is pending approval. Use the buttons below to respond.
            </p>
            <Button
              color="dark"
              className="order-header-button"
              onClick={onApprovePending}
            >
              Approve Pending Order
            </Button>
            <Button
              color="dark"
              className="order-header-button ml-2"
              onClick={onDeclinePending}
            >
              Decline Pending Order
            </Button>
          </>
        </Alert>
      )}
    </>
  )
}

export default OrderAlertsActionable
