import { newMessage } from '../../store/reducers/conversation/conversationSlice'
import { orderSocketData } from '../../store/reducers/order/orderSlice'
import { APPRAISER_CANCELLATION_REQUEST } from '../constants/alerts'
import {
  SET_DATA_FROM_SOCKET,
  NOTIFICATION_DECLINE,
  NEW_NOTIFICATION,
  UPDATE_NOTIFICATIONS,
} from '../utils/resource'


function notificationDecline(notification) {
  return {
    type: NOTIFICATION_DECLINE,
    notification,
  }
}

function newNotification(notification) {
  return {
    type: NEW_NOTIFICATION,
    notification,
  }
}

export function updateNotificationsActionCreator(notifications) {
  return {
    type: UPDATE_NOTIFICATIONS,
    payload: {
      notifications,
    },
  }
}

export function setOrderDataFromSocket(orderID, updateData, shouldIncrementStatus) {
  return {
    type: orderSocketData.type,
    meta: {
      resource: {
        namespace: 'orders',
        individualNamespace: 'order',
      },
    },
    payload: {
      objectID: orderID,
      objectUpdate: updateData,
      shouldIncrementStatus,
    },
  }
}

export function setResourceDataFromSocket(namespace, objectID, updateData) {
  return {
    type: SET_DATA_FROM_SOCKET,
    meta: {
      resource: {
        namespace: namespace + 's',
        individualNamespace: namespace,
      },
    },
    payload: {
      objectID: objectID,
      objectUpdate: updateData,
    },
  }
}

export function getOrderFromResourceState(resourceState, orderID) {
  let order

  if (resourceState.order && resourceState.order.data && resourceState.order.data.id === orderID) {
    order = resourceState.order.data
  } else if (resourceState.order && resourceState.order.data && resourceState.order.data.follow_up_orders) {
    const followUpOrders = resourceState.order.data.follow_up_orders

    for (let i = 0; i < followUpOrders.length; i++) {
      const thisOrder = followUpOrders[i]
      if (thisOrder.id === orderID) {
        order = thisOrder
        break
      }
    }
  }
  return order
}
export function getMinimumOrderFromResourceState(resourceState, orderID) {
  let order

  if (
    resourceState.minimumOrder &&
    resourceState.minimumOrder.data &&
    resourceState.minimumOrder.data.order &&
    resourceState.minimumOrder.data.order.id === orderID
  ) {
    order = resourceState.minimumOrder.data.order
  } else if (
    resourceState.minimumOrder &&
    resourceState.minimumOrder.data &&
    resourceState.minimumOrder.data.order &&
    resourceState.minimumOrder.data.order.follow_up_orders
  ) {
    const followUpOrders =
      resourceState.minimumOrder.data.order.follow_up_orders

    for (let i = 0; i < followUpOrders.length; i++) {
      const thisOrder = followUpOrders[i]
      if (thisOrder.id === orderID) {
        order = thisOrder
        break
      }
    }
  }
  return order
}

export default function handleNotification(notification, dispatch) {
  const orderID = notification.notification_object_id
  let order
  let suborder
  let message


  switch (notification.notification_type) {
    case 'consumer_submission_download_alerts':
      dispatch(setOrderDataFromSocket(orderID, { alerts: notification.object_delta.alerts }))
      break
    case 'order_approved':
    case 'order_reapproved':
      dispatch(setOrderDataFromSocket(orderID, { ...notification.object_delta.order }))
      break
    case 'order_loan_change':
      dispatch(setOrderDataFromSocket(orderID, { ...notification.object_delta.order }))
      break
    case 'order_conversation_message_no_view':
    case 'order_conversation_message':
      order = notification.notification_object_id
      suborder = null

      message = {
        ...notification.object_delta,
        sender: {
          ...notification.initiator,
        },
      }


      dispatch(
        newMessage({
          orderId: order,
          message,
          conversationType: message.conversation_type,
          suborder,
          increment_unread: 1,
        }),
      )
      break
    case 'order_status_change':
    case 'order_status_change_no_company':
    case 'waiting_for_payment_complete': {
      const statusUpdate = { ...notification.object_delta }
      statusUpdate.status = notification.object_delta.new_status
      statusUpdate.statusKey = notification.object_delta.new_statusKey
      dispatch(setOrderDataFromSocket(orderID, statusUpdate))
      break
    }
    case 'order_requires_lender_attention': {
      const updateData = {
        lender_attention_required: true,
        lender_attention_required_reason: notification.object_delta.lender_attention_required_reason,
      }
      if (notification.object_delta.timeline_events) {
        updateData.timeline_events = notification.object_delta.timeline_events
      }
      if (notification.object_delta.va_actions) { updateData.va_actions = notification.object_delta.va_actions }
      dispatch(setOrderDataFromSocket(orderID, updateData))
      break
    }
    case 'order_appraisal_report_ready':
      dispatch(setOrderDataFromSocket(orderID, { ...notification.object_delta.order }))
      break
    case 'order_internal_note_created':
      dispatch(setOrderDataFromSocket(orderID, { notes: notification.object_delta.notes }))
      break
    case 'order_inspection_date_update':
      dispatch(setOrderDataFromSocket(orderID, {
        inspection_date: notification.object_delta.inspection_date,
        timeline_events: notification.object_delta.timeline_events,
      }, true))
      break
    case 'payment_status_update':
      dispatch(setOrderDataFromSocket(orderID, { payments: notification.object_delta.payments }))
      break
    case 'order_company_assignment_decline':
      dispatch(notificationDecline(notification))
      break
    case 'order_payment_complete': {
      const data = { schedule: notification.object_delta.schedule }
      if (notification.object_delta.pending_offers && notification.object_delta.pending_offers === 'pending') {
        data.lender_attention_required = true
        data.lender_attention_required_reason = notification.object_delta.lender_attention_required_reason
      } else if (notification.object_delta.pending_offers && notification.object_delta.pending_offers === 'accepted') {
        data.lender_attention_required = false
        data.assignment_list_request = notification.object_delta.assignment_list_request
      }
      dispatch(setOrderDataFromSocket(orderID, data))
      break
    }
    case 'order_counter_offer':
    case 'order_offer':
      dispatch(setOrderDataFromSocket(orderID, { counter_offers: notification.object_delta.counter_offers }))
      break
    case 'evault_upload':
      dispatch(setOrderDataFromSocket(orderID, { evault: notification.object_delta.evault }))
      break
    case 'order_cancel_request':
      order = notification.notification_object_id
      suborder = null

      if (notification.object_delta && notification.object_delta.primary_order_id) {
        suborder = notification.notification_object_id
        order = notification.object_delta.primary_order_id
      }

      message = {
        ...notification.object_delta,
        sender: {
          ...notification.initiator,
        },
      }

      dispatch(newMessage({ orderId: order, message, conversationType: 'conversation', suborder, increment_unread: 1 }))
      dispatch(setOrderDataFromSocket(orderID, {
        appraiser_requested_cancel: true,
        can_change_requested_appraiser: true,
        lender_attention_required: true,
        lender_attention_required_reason: APPRAISER_CANCELLATION_REQUEST,
      }))
      break
    case 'amc_order_created':
      console.log('TODO implement a handler so that we can add an extra order into the "orders" namespace.')
      break
    case 'order_hold_change':
    case 'order_placed_on_hold':
      dispatch(setOrderDataFromSocket(orderID, { on_hold: notification.object_delta.on_hold }))
      break
    default:
      console.log('notification handler has encountered an unexpected notification type', notification)
  }
  dispatch(newNotification(notification))
}
