// libraries
import React from 'react'

// UI components
import {
  Button as AntButton,
  Dropdown,
  Menu,
} from 'antd'
import {
  DownloadOutlined,
  DownOutlined,
} from '@ant-design/icons'
// originally built with reactstrap button but once submissionsActionsUx flag is deprecated we should probably switch to the antd Button
import {
  Button,
} from 'reactstrap'

/**
 * Component renders a button with a dropdown to download a file from a list of items
 */
const DownloadDropdown = ({
  items = [],
  onItemSelect = (item) => {},
  title = ' Download ',
  classNames = '',
  buttonColor = 'light',
  showIcon = true,
  useAntButton = false,
}) => {
  const menu = (<Menu>
    {items.map((item, i) => (
      <Menu.Item
        key={i}
        onClick={(e) => { onItemSelect(item) }}
      >
        {item.label}
      </Menu.Item>
    ))}
  </Menu>)

  const ButtonComponent = useAntButton ? AntButton : Button

  return (
    <Dropdown
      overlay={menu}
      trigger='click'
      placement='bottomRight'
    >
      <ButtonComponent
        className={classNames}
        color={buttonColor}
      >
        {showIcon && <DownloadOutlined className='mr-1' />}
        {title}
        <DownOutlined className='ml-1' />
      </ButtonComponent>
    </Dropdown>
  )
}

export default DownloadDropdown
