// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ruleBadge_c51a75 {\n  align-items: center;\n  display: flex;\n  justify-content: center;\n}\n", "",{"version":3,"sources":["webpack://src/app/pages/orders/SubmissionsTab/ReggoraReview/RuleBadge/RuleBadge.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,uBAAuB;AACzB","sourcesContent":[".ruleBadge {\n  align-items: center;\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ruleBadge": "ruleBadge_c51a75"
};
export default ___CSS_LOADER_EXPORT___;
