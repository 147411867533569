import React, { Fragment } from 'react'

const additional_fields = {
  primary_contact: 'Primary Contact',
  occupancy_type: 'Occupancy (P/S/I)',
  attachment_type: 'ULDD Attachment Type',
  agency_case_number: 'Agency Case Number',
  loan_type: 'Loan Type',
  number_of_units: 'Subject Property Number Units',
  branch_id: 'Branch',
  investor_name: 'Investor Name',
  nmls_id: 'NMLS Id',
}

export default function AdditionalPropertyData(props) {
  const { additional_property_data } = props
  const fields = []
  for (var key in additional_property_data) {
    if (additional_fields[key]) {
      fields.push({ label: additional_fields[key], value: additional_property_data[key] })
    } else {
      // We should never fall back here, if we have another field then we should update the fields mapping
      fields.push({ label: key, value: additional_property_data[key] })
    }
  }
  return (
    <Fragment>
      {fields.map((field, idx) => {
        return <div key={idx} className="user-info">
          <div className="user-info__heading">{field.label}:</div>
          <div className="user-info__text">{field.value}</div>
        </div>
      })}
    </Fragment>
  )
}
