import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import API, { API_VERSION, REQUEST_METHOD } from '../../../api'
import STATUS from '../../../common/constants/reducer_statuses'
import { determineNextStatus } from '../../../common/utils/resource'
import { } from './orderSelectors'

export const initialState = {
  orderTabs: null,
  orderTimeline: [],
  minimumOrder: null,
  remainingOrder: {},
  status: STATUS.IDLE,
  minimumStatus: STATUS.IDLE,
  remainingStatus: STATUS.IDLE,
  timelineStatus: STATUS.IDLE,
  activeOrderTab: null,
  primaryOrderId: null,
  error: null,
  orderAssignedStatus: STATUS.IDLE,
  orderAssignedError: null,
  orderAssignedErrorStatus: null,
  paymentAllowedStatus: STATUS.IDLE,
  paymentAllowed: false,
  followUpAllocationDataStatus: STATUS.IDLE,
  followUpAllocationData: null,
  orderAuditLogDataStatus: STATUS.IDLE,
  orderAuditLogData: null,
  resolveLenderAttentionStatus: STATUS.IDLE,
  clearUnreadMessagesPipelineStatus: STATUS.IDLE,
}

export const fetchOrderTabs = createAsyncThunk('order/fetchOrderTabs', async (orderId, { rejectWithValue }) => {
  try {
    return await API(`order/${orderId}/tabs`, API_VERSION.TWO).request(REQUEST_METHOD.GET, {}, {})
  } catch (err) {
    return rejectWithValue(err.errors._error)
  }
})

export const fetchMinimumOrder = createAsyncThunk('order/fetchMinimumOrder', async (orderId, { rejectWithValue }) => {
  try {
    const data = await API(`order/minimum-order/${orderId}`, API_VERSION.TWO).request(REQUEST_METHOD.GET, {}, {})
    data.loan_file.consumers = data.loan_file.consumers.map(consumer => {
      if (!consumer.full_name) {
        if (!consumer.firstname && !consumer.lastname) {
          consumer.full_name = ' '
        } else if (!consumer.firstname) {
          consumer.full_name = consumer.lastname
        } else if (!consumer.lastname) {
          consumer.full_name = consumer.firstname
        } else {
          consumer.full_name = consumer.firstname + ' ' + consumer.lastname
        }
      }
      return consumer
    })
    return data
  } catch (err) {
    return rejectWithValue(err.errors._error)
  }
})

export const fetchRemainingOrder = createAsyncThunk('order/fetchRemainingOrder', async (orderId, { rejectWithValue }) => {
  try {
    return await API(
      `order/${orderId}?get_follow_up_orders_incomplete=true&get_submissions_incomplete=true&get_minimal_data=true`,
      API_VERSION.TWO
    ).request(REQUEST_METHOD.GET, {}, {})
  } catch (err) {
    return rejectWithValue(err.errors._error)
  }
})

export const fetchOrderTimeline = createAsyncThunk('order/fetchOrderTimeline', async (orderId, { rejectWithValue }) => {
  try {
    return await API(
      `order/${orderId}/timeline`,
      API_VERSION.TWO
    ).request(REQUEST_METHOD.GET, {}, {})
  } catch (err) {
    return rejectWithValue(err.errors._error)
  }
})

export const updateOrderAssigned = createAsyncThunk('order/updateOrderAssigned', async (kwargs, { rejectWithValue }) => {
  try {
    const assignURL = `order/assigned/${kwargs.order_id}`
    return await API(assignURL, API_VERSION.TWO).request(REQUEST_METHOD.PUT, {}, kwargs)
  } catch (err) {
    return rejectWithValue(err)
  }
})

export const fetchPaymentAllowed = createAsyncThunk('order/fetchPaymentAllowed', async (orderId, { rejectWithValue }) => {
  try {
    const url = `order/${orderId}/payment-allowed`
    return await API(url, API_VERSION.TWO).request(REQUEST_METHOD.GET, {}, {})
  } catch (err) {
    return rejectWithValue(err)
  }
})

export const fetchFollowUpAllocationData = createAsyncThunk('order/fetchFollowUpAllocationData', async ({ primaryOrderId, selectedProductId }, { rejectWithValue }) => {
  try {
    const url = `order/${primaryOrderId}/follow-up-allocation`
    return await API(url, API_VERSION.TWO).request(REQUEST_METHOD.GET, { selected_product: selectedProductId }, {})
  } catch (err) {
    return rejectWithValue(err)
  }
})

export const fetchOrderAuditLogData = createAsyncThunk('order/fetchOrderAuditLogData', async ({ orderId }, { rejectWithValue }) => {
  try {
    const url = `order/${orderId}/audit-logs`
    console.log(`url: ${url}`)
    return await API(url, API_VERSION.TWO).request(REQUEST_METHOD.GET, {}, {})
  } catch (err) {
    return rejectWithValue(err)
  }
})

export const resolveLenderAttention = createAsyncThunk('order/resolveLenderAttention', async ({ orderId, reason }, { rejectWithValue }) => {
  try {
    const url = `order/${orderId}/resolve-lender-attention`
    const data = { reason }
    return await API(url, API_VERSION.TWO).request(REQUEST_METHOD.POST, {}, data)
  } catch (err) {
    return rejectWithValue(err)
  }
})

export const clearUnreadMessagesPipeline = createAsyncThunk('order/clearUnreadMessagesPipeline', async (userId, { rejectWithValue }) => {
  try {
    const url = `order/messages/clear-unread/${userId}`
    return await API(url, API_VERSION.TWO).request(REQUEST_METHOD.POST, {}, {})
  } catch (err) {
    return rejectWithValue(err)
  }
})

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    resetOrder: () => {
      return initialState
    },
    orderSocketData: (state, action) => {
      const order = state.remainingOrder && state.remainingOrder.id === action.payload.objectID
        ? state.remainingOrder : false

      const updateData = { ...action.payload.objectUpdate }
      if (order) {
        if (action.payload.shouldIncrementStatus) {
          // we're updating an order and we want to increment its status so figure out what next status should be
          const currentStatusKey = order.statusKey
          const nextStatus = determineNextStatus(order.available_statuses, currentStatusKey)
          if (nextStatus) {
            updateData.statusKey = nextStatus.statusKey
            updateData.status = nextStatus.statusValue
          }
        }
        state.remainingOrder = {
          ...state.remainingOrder,
          ...updateData,
        }

      // Here we are handeling the case where the order loaded has follow up orders so we have to check whether or not the orders that need to be updated are the follow up orders
      }
    },
    updateOrder: (state, action) => {
      const {
        orderID,
        subOrderId,
        update,
      } = action.payload

      const order = state.remainingOrder && state.remainingOrder.id === orderID
      let orderUpdate
      // If we have found this order in the order or orders namespace then we can update it.
      if (order) {
        if (subOrderId) {
          const subOrder = order.follow_up_orders.find((o) => {
            return o.id === subOrderId
          })
          const orderIndex = order.follow_up_orders.findIndex((o) => {
            return o.id === subOrderId
          })
          if (subOrder) {
            orderUpdate = {
              ...order,
            }

            const primaryFollowUp = orderUpdate.follow_up_orders[orderIndex]

            orderUpdate.follow_up_orders[orderIndex] = {
              ...primaryFollowUp,
              ...update,
            }
          }
        } else {
          orderUpdate = {
            ...update,
          }
        }

        if (orderUpdate) {
          state.remainingOrder = {
            ...state.remainingOrder,
            ...orderUpdate,
          }
        }
      }
    },
    setActiveOrderTab: (state, action) => {
      state.activeOrderTab = action.payload
    },
    setPrimaryOrderId: (state, action) => {
      state.primaryOrderId = action.payload
    },
    updateMinimumOrder: (state, action) => {
      const { payload } = action

      state.minimumOrder = {
        ...state.minimumOrder,
        ...payload,
      }
    },
  },
  extraReducers: {
    [fetchOrderTabs.pending]: state => {
      state.status = STATUS.LOADING
    },
    [fetchOrderTabs.fulfilled]: (state, action) => {
      state.status = STATUS.SUCCEEDED
      // Add any fetched posts to the array
      state.orderTabs = action.payload || []
    },
    [fetchOrderTabs.rejected]: (state, action) => {
      state.status = STATUS.FAILED
      state.error = action.error.message
    },
    [fetchMinimumOrder.pending]: state => {
      state.minimumStatus = STATUS.LOADING
      // reset status from any previous request to update assigned, but keep error info
      state.orderAssignedStatus = STATUS.IDLE
    },
    [fetchMinimumOrder.fulfilled]: (state, action) => {
      state.minimumStatus = STATUS.SUCCEEDED
      // Add any fetched posts to the array
      state.minimumOrder = action.payload || {}
    },
    [fetchMinimumOrder.rejected]: (state, action) => {
      state.minimumStatus = STATUS.FAILED
      state.error = action.error.message
    },
    [fetchRemainingOrder.pending]: state => {
      state.remainingStatus = STATUS.LOADING
    },
    [fetchRemainingOrder.fulfilled]: (state, action) => {
      state.remainingStatus = STATUS.SUCCEEDED
      // Add any fetched posts to the array
      state.remainingOrder = action.payload || {}
    },
    [fetchRemainingOrder.rejected]: (state, action) => {
      state.remainingStatus = STATUS.FAILED
      state.error = action.error.message
    },
    [fetchOrderTimeline.pending]: state => {
      state.timelineStatus = STATUS.LOADING
    },
    [fetchOrderTimeline.fulfilled]: (state, action) => {
      state.timelineStatus = STATUS.SUCCEEDED
      // Add any fetched posts to the array
      state.orderTimeline = action.payload || {}
    },
    [fetchOrderTimeline.rejected]: (state, action) => {
      state.timelineStatus = STATUS.FAILED
      state.error = action.error.message
    },
    [updateOrderAssigned.pending]: state => {
      state.orderAssignedStatus = STATUS.LOADING
      state.orderAssignedError = null
      state.orderAssignedErrorStatus = null
    },
    [updateOrderAssigned.fulfilled]: state => {
      state.orderAssignedStatus = STATUS.SUCCEEDED
    },
    [updateOrderAssigned.rejected]: (state, action) => {
      state.orderAssignedStatus = STATUS.FAILED
      state.orderAssignedError = action.payload.error
      state.orderAssignedErrorStatus = action.payload.status
    },
    [fetchPaymentAllowed.pending]: state => {
      state.paymentAllowedStatus = STATUS.LOADING
    },
    [fetchPaymentAllowed.fulfilled]: (state, action) => {
      state.paymentAllowedStatus = STATUS.SUCCEEDED
      state.paymentAllowed = action.payload.payment_allowed
    },
    [fetchPaymentAllowed.rejected]: state => {
      state.paymentAllowedStatus = STATUS.FAILED
      // On an error, set paymentAllowed to true since we do not want
      // to block an order from being paid for entirely
      state.paymentAllowed = true
    },
    [fetchFollowUpAllocationData.pending]: state => {
      state.followUpAllocationDataStatus = STATUS.LOADING
      state.followUpAllocationData = null
    },
    [fetchFollowUpAllocationData.fulfilled]: (state, action) => {
      state.followUpAllocationDataStatus = STATUS.SUCCEEDED
      state.followUpAllocationData = action.payload
    },
    [fetchFollowUpAllocationData.rejected]: state => {
      state.followUpAllocationDataStatus = STATUS.FAILED
      state.followUpAllocationData = null
    },
    [fetchOrderAuditLogData.pending]: state => {
      state.orderAuditLogDataStatus = STATUS.LOADING
      state.orderAuditLogData = null
    },
    [fetchOrderAuditLogData.fulfilled]: (state, action) => {
      state.orderAuditLogDataStatus = STATUS.SUCCEEDED
      state.orderAuditLogData = action.payload
    },
    [fetchOrderAuditLogData.rejected]: state => {
      state.orderAuditLogDataStatus = STATUS.FAILED
      state.orderAuditLogData = null
    },
    [resolveLenderAttention.pending]: state => {
      state.resolveLenderAttentionStatus = STATUS.LOADING
    },
    [resolveLenderAttention.fulfilled]: state => {
      state.resolveLenderAttentionStatus = STATUS.SUCCEEDED
    },
    [resolveLenderAttention.rejected]: state => {
      state.resolveLenderAttentionStatus = STATUS.FAILED
    },
    [clearUnreadMessagesPipeline.pending]: state => {
      state.clearUnreadMessagesPipelineStatus = STATUS.LOADING
    },
    [clearUnreadMessagesPipeline.fulfilled]: state => {
      state.clearUnreadMessagesPipelineStatus = STATUS.SUCCEEDED
    },
    [clearUnreadMessagesPipeline.rejected]: state => {
      state.clearUnreadMessagesPipelineStatus = STATUS.FAILED
    },
  },
})

export const {
  orderSocketData,
  resetOrder,
  updateOrder,
  setActiveOrderTab,
  setPrimaryOrderId,
  updateMinimumOrder,
} = orderSlice.actions

export default orderSlice.reducer
