export default function alphabeticalCompare(valueA, valueB, reverse = false) {
  /**
    used with Array.sort() to order the elements of a list

    returns -1 if A should go first, 1 if B should go first, 0 if equivalent

    reverse=false is alphabetical, set reverse=true to order from Z to A instead

    sort order is case insensitive
    */
  const valueAFirst = -1
  const valueBFirst = 1

  const compareA = valueA.toLowerCase()
  const compareB = valueB.toLowerCase()

  let sortValue = 0
  if (compareA < compareB) {
    sortValue = reverse ? valueAFirst : valueBFirst
  } else if (compareA > compareB) {
    sortValue = reverse ? valueBFirst : valueAFirst
  }

  return sortValue
}
