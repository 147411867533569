import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Select, Button, Form, Alert } from 'antd'

import STATUS from '../../../common/constants/reducer_statuses'
import {
  fetchInspections,
  fetchForm,
  createInspectionSubmission,
  resetCreateInspectionSubmission,
  resetInspections,
} from '../../../store/reducers/inspection/inspectionSlice'

import {
  selectInspectionsData,
  selectInspectionsError,
  selectIsInspectionsLoading,
  selectCreateInspectionSubmissionStatus,
  selectCreateInspectionSubmissionError,
  selectInspectionSubmissionsData,
  selectInspectionSubmissionsError,
  selectIsFormLoading,
  selectFormVersion,
} from '../../../store/reducers/inspection/inspectionSelectors'

import Loader from '../../../common/layout/components/Loader'
import PdrSubmissionsView from './PdrSubmissionsView'

const PdrSubmissionsTab = ({ order: { id: orderId } }) => {
  const dispatch = useDispatch()

  const [inspectionId, setInspectionId] = useState('')
  const [formId, setFormId] = useState('')

  const inspectionsData = useSelector(selectInspectionsData)
  const inspectionsError = useSelector(selectInspectionsError)
  const isInspectionsLoading = useSelector(selectIsInspectionsLoading)
  const formVersion = useSelector(selectFormVersion)
  const isFormLoading = useSelector(selectIsFormLoading)

  const createInspectionSubmissionStatus = useSelector(selectCreateInspectionSubmissionStatus)
  const createInspectionSubmissionError = useSelector(selectCreateInspectionSubmissionError)

  const inspectionSubmissionsData = useSelector(selectInspectionSubmissionsData)
  const inspectionSubmissionsError = useSelector(selectInspectionSubmissionsError)

  useEffect(() => {
    dispatch(resetCreateInspectionSubmission())
    dispatch(resetInspections())
  }, [dispatch])

  useEffect(() => {
    const fetchInspectionsData = async () => {
      await dispatch(fetchInspections(orderId))
    }

    if (orderId) {
      fetchInspectionsData()
    }
  }, [orderId, dispatch])

  useEffect(() => {
    if (inspectionsData.length > 0) {
      setInspectionId(inspectionsData[0]._id)
      setFormId(inspectionsData[0].form_id)
    }
  }, [inspectionsData, dispatch])

  useEffect(() => {
    const fetchFormData = async () => {
      await dispatch(fetchForm(formId))
    }

    if (formId) {
      fetchFormData()
    }
  }, [formId, dispatch])

  const onSubmit = () => {
    dispatch(createInspectionSubmission({
      inspectionId,
      submission: {
        inspection_id: inspectionId,
        type: 'fannie',
        mapping_version: '6',
        status: 'pending',
      },
    }))
  }

  if (isInspectionsLoading || createInspectionSubmissionStatus === STATUS.LOADING || isFormLoading) {
    return <Loader />
  }

  if (inspectionsData.length === 0) {
    return (
      <div>
        <p style={{ textAlign: 'center' }}>No inspections found.</p>
        <p style={{ textAlign: 'center' }}>{inspectionsError?.message || ''}</p>
      </div>
    )
  }

  return (
    <>
      {inspectionSubmissionsError ? <Alert type="error" message="Error fetching submissions" /> : null}
      {createInspectionSubmissionError ? <Alert type="error" message="Error creating inspection submission" /> : null}
      <Form layout="inline">
        <Form.Item label="Inspections">
          <Select
            value={inspectionId}
            onChange={(value) => setInspectionId(value)}
            options={inspectionsData.map((inspection, index) => ({ value: inspection._id, label: `Inspection ${inspectionsData.length - index} - v${formVersion}` }))}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            disabled={
              !inspectionId ||
              createInspectionSubmissionStatus === STATUS.LOADING ||
              createInspectionSubmissionStatus === STATUS.SUCCEEDED ||
              (inspectionSubmissionsData.length > 0 && inspectionSubmissionsData[0].status === 'complete')
            }
            onClick={onSubmit}
            data-testid="submitInspectionButton"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
      <PdrSubmissionsView inspectionId={inspectionId} />
    </>
  )
}

export default PdrSubmissionsTab
