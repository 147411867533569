import React, { Component, Fragment } from 'react'
import { ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { Alert, Radio } from 'antd'
import Rephrase from '../../common/utils/Rephrase'
const RadioGroup = Radio.Group


class ConfirmPSModal extends Component {
    state = {
      affectsAppraisal: true,
      loading: false,
    };

    handleChange = (event) => {
      this.setState({
        affectsAppraisal: event.target.value,
      })
    }

    onSubmit = () => {
      this.setState({
        loading: true,
      })

      this.props.onSubmit(this.state.affectsAppraisal)
    }

    onCancel = () => {
      this.setState({
        loading: true,
      })

      this.props.onCancel()
    }

    render() {
      const { ...props } = this.props
      const {
        affectsAppraisal,
        loading,
      } = this.state

      return (
        <Fragment>
          <ModalHeader toggle={props.toggle}>P&S Upload</ModalHeader>
          <ModalBody>
            <form>
              <p>
                <Rephrase>
                  Does this updated P&S Agreement contain changes that may require revisions to the appraisal?
                </Rephrase>
              </p>
              <RadioGroup onChange={this.handleChange} value={affectsAppraisal}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </RadioGroup>
              {affectsAppraisal && <div className="mt-3" ><Alert type="warning" message={<Rephrase>Please note, the due date for this appraisal will be automatically extended by one day.</Rephrase>} /></div>}
            </form>
          </ModalBody>
          <ModalFooter className="border-0">
            <Button disabled={loading} type='button' color='success' onClick={this.onSubmit}>Submit</Button>
            <Button disabled={loading} onClick={this.onCancel} color='danger'>Cancel</Button>
          </ModalFooter>
        </Fragment>
      )
    }
}

export default ConfirmPSModal
