/* Vendor Libraies */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Col, Label } from 'reactstrap'
import { Input, notification } from 'antd'
import { useFlags } from 'launchdarkly-react-client-sdk'

/* Internal Components */
import RadiosInput from '../../../common/forms/inputs/RadiosInput'

/* Selectors */
import { selectIsAmcLender } from '../../../store/reducers/user/userSelectors'
import { selectVendorScore } from '../../../store/reducers/vendorScore/vendorScoreSelectors'
import { selectAllOrderData } from '../../../store/reducers/order/orderSelectors'

/* Actions */
import { closeModal, submitScore } from '../../../store/reducers/vendorScore/vendorScoreSlice'

const scoreOptions = ['A', 'B', 'C', 'F'].map(option => ({ label: option, value: option }))

const { TextArea } = Input

const VendorRatingModal = () => {
  const { vendorRating } = useFlags() || {}
  const isAmcLender = useSelector(selectIsAmcLender)
  const orderData = useSelector(selectAllOrderData)
  const { scoreRecord, showModal, status } = useSelector(selectVendorScore)
  const [score, setScore] = useState()
  const [notes, setNotes] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    if (showModal) {
      if (status === 'failed') {
        notification.error({
          message: 'Error submitting quality score',
          description: 'Please try again later',
        })
      } else if (status === 'submitted') {
        notification.success({
          message: 'Quality score submitted',
        })
        dispatch(closeModal())
      }
    }
  }, [showModal, status, dispatch])

  useEffect(() => {
    if (showModal && scoreRecord) {
      const { score, notes } = scoreRecord
      setScore(score)
      setNotes(notes || '')
    }
  }, [showModal, scoreRecord])

  if (!vendorRating || !isAmcLender || orderData.job_type !== 'amc' || !showModal) { return null }

  const isLoading = status === 'submitting'

  const readyToSubmit = score !== undefined

  const handleScoreChange = (value) => setScore(value)

  const handleNotesChange = (value) => setNotes(value)

  const handleCloseModal = () => {
    dispatch(closeModal())
  }

  const getScoreRecord = () => {
    if (scoreRecord) {
      return scoreRecord
    }
    const { id, accepted, lender } = orderData
    const {
      id: paenlistId,
      company: companyId,
      appraiser: appraiserUserId,
    } = accepted || {}
    const { id: lenderId } = lender || {}
    return {
      order_id: id,
      panelist_id: paenlistId,
      company_id: companyId,
      appraiser_user_id: appraiserUserId,
      lender_owner_id: lenderId,
    }
  }

  const handleSubmit = () => {
    const newRecord = { ...getScoreRecord(), score, notes }
    dispatch(submitScore(newRecord))
  }

  const isEditing = scoreRecord !== null

  const vendorName = orderData?.accepted?.panelist_name

  return (
    <Modal isOpen zIndex={1000}>
      <ModalHeader toggle={ isEditing && handleCloseModal }>Vendor Quality Score</ModalHeader>
      <ModalBody className="m-1">
        <FormGroup row>
          <Col xs="12">
            <Label>Select a quality score for {vendorName}</Label>
            <RadiosInput
              name="score"
              options={scoreOptions}
              valueKey="value"
              labelKey="label"
              value={score}
              onChange={handleScoreChange}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col xs="12">
            <p>Comments (Optional)</p>
            <TextArea
              placeholder="Comments..."
              autoSize={{ minRows: 4, maxRows: 15 }}
              type="text"
              name="notes"
              value={notes}
              onChange={(event) => handleNotesChange(event.target.value)}
            />
          </Col>
        </FormGroup>
      </ModalBody>
      <ModalFooter className="border-0">
        {!isEditing &&
          <Button disabled={isLoading} onClick={handleCloseModal} color="light">Skip</Button>
        }
        <Button
          disabled={isLoading || !readyToSubmit}
          type="button"
          color="primary"
          onClick={handleSubmit}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default VendorRatingModal
