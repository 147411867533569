import React from 'react'
import { useSelector } from 'react-redux'
import { compose } from 'redux'
import { connectResource } from '../../../common/utils/resource'
import { Alert, Tag } from 'antd'
import { Button } from 'reactstrap'
import ModalTrigger from '../../../common/modals/ModalTrigger'
import ModalConfirmation from '../../../common/modals/ModalConfirmation'
import { ESCALATION_ALERT, COUNTER_OFFER } from '../../../common/constants/alerts'
import { REQUEST_METHOD } from '@app/api'
import moment from 'moment'
import { loadableWithRetry } from '../../../common/utils/lazyLoad'
import { selectPaymentTypesData } from '../../../store/reducers/paymentTypes/paymentTypesSelectors'
import { JOB_TYPES } from '../../../common/constants/jobTypes'
import { OFFER_TYPES, OFFER_STATUSES } from '@app/common/constants/offer'
import { checkPermission } from '../../../common/utils/helpers'
import { useApiRequest } from '@app/common/hooks'
import {
  getWarningText,
  getPaymentTypeAvailability,
  checkEditAcceptDenyPermission,
} from './helpers'
import {
  selectAllOrderData,
  selectLatestLenderAttention,
  selectLoanOrderData,
  selectPendingOrProcessingOffer,
  selectProcessingOffer,
  selectAssignmentListRequest,
} from '@app/store/reducers/order/orderSelectors'
import { selectCurrentUserId, selectIsAmcLender, selectLenderId } from '@app/store/reducers/user/userSelectors'

const PaymentReceivedModal = loadableWithRetry(() => import('../../payments/PaymentReceivedModal'))
const EditOfferModal = loadableWithRetry(() => import('../EditOfferModal'))

const FeeEscalationAlert = (props) => {
  const orderData = useSelector(selectAllOrderData)
  const isAmcLender = useSelector(selectIsAmcLender)

  const {
    job_type,
  } = orderData
  const offer = useSelector(selectPendingOrProcessingOffer)

  if (!offer) {
    return null
  }

  const { status, is_pending_client_lender: isPendingClientLender } = offer
  const amcOrder = job_type === JOB_TYPES.AMC
  const offerProcessing = status === OFFER_STATUSES.PROCESSING
  const pendingClientLenderOrProcessing = offerProcessing || isPendingClientLender

  if (amcOrder && ((!isAmcLender && !pendingClientLenderOrProcessing) || (isAmcLender && offerProcessing))) {
    // -- AMC order logic --
    // For the client lender, only show the alert is the offer is pending client OR processing
    // For AMP, hide the alert is the offer is still processing (awaiting payment from client lender)
    return null
  }

  const Message = <AlertMessage {...props} />
  return <Alert className="mb-4" message={Message} showIcon type="warning" />
}

const AcceptDenyButtons = (props) => {
  const apiRequest = useApiRequest()
  const orderData = useSelector(selectAllOrderData)
  const isAmcLender = useSelector(selectIsAmcLender)

  const {
    job_type,
  } = orderData

  const assignmentListRequest = useSelector(selectAssignmentListRequest)
  const offer = useSelector(selectPendingOrProcessingOffer)
  if (!offer) {
    return null
  }

  const { is_pending_client_lender } = offer
  const orderId = orderData.id
  const offerId = offer.id

  const onDeny = async () => {
    const args = {
      order_id: orderId,
      assignment_list_request_id: assignmentListRequest.id,
      offer_id: offerId,
    }
    await apiRequest({
      endpoint: `offers/${offerId}/deny`,
      method: REQUEST_METHOD.POST,
      body: args,
      successMessage: 'Fee escalation successfully updated.',
    })
    return props.refreshOrder()
  }

  const onAccept = async () => {
    const args = {
      order_id: orderId,
      assignment_list_request_id: assignmentListRequest.id,
      offer_id: offerId,
    }
    await apiRequest({
      endpoint: `offers/${offerId}/accept`,
      method: REQUEST_METHOD.POST,
      body: args,
      successMessage: 'Fee escalation successfully updated.',
    })
    return props.refreshOrder()
  }

  if (job_type === JOB_TYPES.AMC && isAmcLender) {
    if (is_pending_client_lender) {
      return (
        <>
          <Tag>Pending Lender</Tag>
        </>
      )
    }

    return (
      <>
        <ModalTrigger
          component={EditOfferModal}
          refreshOrder={props.refreshOrder}
          backdrop='static'
          offerId={offerId}
          assignmentListRequestId={assignmentListRequest.id}
          offerType={OFFER_TYPES.FEE_ESCALATION}
        >
          <Button className='order-header-button btn-sm mx-2'>
            Request Lender
          </Button>
        </ModalTrigger>
        <Button
          className='order-header-button btn-sm mx-2'
          onClick={onDeny}
        >
          Deny
        </Button>
      </>
    )
  }

  return (
    <>
      <Button
        className='order-header-button btn-sm mx-2'
        onClick={onAccept}
      >
        Accept
      </Button>
      <Button
        className='order-header-button btn-sm mx-2'
        onClick={onDeny}
      >
        Deny
      </Button>
    </>
  )
}

const AlertMessage = (props) => {
  const {
    refreshOrder,
    user,
  } = props
  const apiRequest = useApiRequest()
  const userPaymentTypes = useSelector(selectPaymentTypesData)
  const orderData = useSelector(selectAllOrderData)
  const loanData = useSelector(selectLoanOrderData)
  const currentUserID = useSelector(selectCurrentUserId)
  const isAmcLender = useSelector(selectIsAmcLender)
  const offer = useSelector(selectPendingOrProcessingOffer)
  const processingOffer = useSelector(selectProcessingOffer)
  const lenderId = useSelector(selectLenderId)
  const lenderAttention = useSelector(state => selectLatestLenderAttention(
    state,
    isAmcLender ? null : lenderId,
    ESCALATION_ALERT,
  ))

  const {
    due_date,
    lender_due_date,
    assignment_list_request,
    job_type,
    reggora_amc_management_fee_dollars,
    total_appraiser_fee: totalDue,
    payment_summary: {
      total_paid: totalPaid,
    } = {},
  } = orderData

  // Use new LenderAttention document if present
  const { reason: lenderAttentionReason } = lenderAttention

  const borrowerPaymentAmount = parseFloat(loanData.borrower_payment_amount) / 100

  if (!offer) {
    return null
  }
  const appraiser_name = assignment_list_request.company.name

  const new_amount = parseFloat(offer.fee)
  let disclosed_amount
  if (loanData.disclosed_amount) {
    disclosed_amount = loanData.disclosed_amount / 100.00
  } else {
    disclosed_amount = null
  }

  // generate warning text and relevant fee delta
  // n_processing represents the number of offers with status === 'processing'
  let previous_amount
  let n_processing
  if (offer.status === 'pending') {
    previous_amount = parseFloat(totalDue)
    n_processing = 0
  } else {
    // fee_amount sums the values of all processing offers
    let fee_amount
    [fee_amount, n_processing] = assignment_list_request.offers.reduce((current, o) => {
      if ((o.status === 'processing') && o.fee_delta) {
        return [current[0] + parseFloat(o.fee_delta), current[1] + 1]
      } else {
        return current
      }
    }, [0, 0])
    previous_amount = totalDue - fee_amount
  }

  const lender_fee_delta = totalDue - totalPaid
  let borrower_fee_delta = lender_fee_delta
  // borrowerPaymentAmount is only relevant if the order has a disclosed amount
  if (disclosed_amount) {
    borrower_fee_delta = disclosed_amount - borrowerPaymentAmount
    if (borrower_fee_delta < 0) { borrower_fee_delta = lender_fee_delta }
  }

  const onCollectCloseSubmit = async () => {
    const { orderId } = props
    await apiRequest({
      endpoint: `offers/${processingOffer.id}/defer`,
      method: REQUEST_METHOD.POST,
      body: {
        order_id: orderId,
        assignment_list_request_id: assignment_list_request.id,
        offer_id: processingOffer.id,
      },
    })
    return props.refreshOrder()
  }

  const warningText = getWarningText(
    appraiser_name,
    previous_amount,
    new_amount,
    disclosed_amount,
    borrower_fee_delta,
    borrowerPaymentAmount,
    n_processing,
    totalDue,
    totalPaid,
    offer,
    job_type,
    lenderAttentionReason,
    // If a client lender is viewing the alert, show lender due date. If AMP
    // is viewing the alert show appraiser due date.
    !isAmcLender ? moment(lender_due_date) : moment(due_date),
    moment(offer.due_date),
    offer.reason,
    user.data,
    reggora_amc_management_fee_dollars,
    isAmcLender,
  )

  // render accept / deny
  if (offer.status === 'pending') {
    // render accept / deny for Fee Escalation alerts only (counter offer is through the requests tab)
    if (lenderAttentionReason === COUNTER_OFFER) {
      return (
        <div className="d-flex flex-column">
          <span>{warningText}</span>
        </div>
      )
    }
    if (checkEditAcceptDenyPermission(lenderAttentionReason)) {
      return (
        <div className="d-flex flex-row justify-content-between">
          <span data-testid='warning-text-span'>{warningText}</span>
          <div className="d-flex flex-row align-items-center">
            <AcceptDenyButtons {...props} />
          </div>
        </div>
      )
    } else {
      return (
        <div className="d-flex flex-column">
          <span>{warningText}</span>
          <span><em>You do not have sufficient permissions to reconcile this escalation.</em></span>
        </div>
      )
    }
  }

  const paymentMethods = []
  // render payment options
  if (offer.status === 'processing') {
    // used to prevent the "Pay with New Card" button from being clicked twice
    if (checkPermission('payments_can_send_payment_link')) {
      paymentMethods.push(<ModalTrigger
        default_amount={borrower_fee_delta.toFixed(2)}
        max_amount={disclosed_amount && !checkPermission('payments_charge_above_disclosed_amount_override') ? borrower_fee_delta : null}
        payment_type={'stripe'}
        editing={false}
        consumers={loanData.consumers}
        order_id={orderData.id}
        refreshOrder={refreshOrder}
        currentUserId={currentUserID}
        component={PaymentReceivedModal}
        is_fee_escalation_payment={true}
        pay_with_new_card={false}
        borrower_payment_amount={loanData.borrower_payment_amount}
        disclosed_amount={loanData.disclosed_amount}
        user_payment_types={userPaymentTypes}
      >
        <Button
          className='order-header-button btn-sm mr-1 my-md-0 fee-escalation-button'
          disabled={!getPaymentTypeAvailability(userPaymentTypes, 'stripe') || isResendButtonDisabled(borrower_fee_delta, new_amount, disclosed_amount)}
        >
          Send/Resend Link to Borrower
        </Button>
      </ModalTrigger>)
    }

    if (checkPermission('payment_received_can_use_branch_card') || checkPermission('payment_received_can_use_lender_card')) {
      paymentMethods.push(
        <ModalTrigger
          default_amount={lender_fee_delta}
          default_payment_type={'lender'}
          editing={false}
          consumers={loanData.consumers}
          order_id={orderData.id}
          borrower_payments_enabled={false}
          refreshOrder={refreshOrder}
          currentUserId={currentUserID}
          component={PaymentReceivedModal}
          is_fee_escalation_payment={true}
          pay_with_new_card={false}
          borrower_payment_amount={loanData.borrower_payment_amount}
          disclosed_amount={loanData.disclosed_amount}
          user_payment_types={userPaymentTypes}
        >
          <Button
            className='order-header-button btn-sm ml-1 my-1 mr-0 mr-md-1 my-md-0 fee-escalation-button'
            disabled={!getPaymentTypeAvailability(userPaymentTypes, 'lender')}
          >
            Pay with Corporate Card
          </Button>
        </ModalTrigger>
      )
    }
    if (checkPermission('payment_received_can_use_new_card')) {
      paymentMethods.push(
        <ModalTrigger
          default_amount={lender_fee_delta}
          editing={false}
          consumers={loanData.consumers}
          order_id={orderData.id}
          borrower_payments_enabled={false}
          refreshOrder={refreshOrder}
          currentUserId={currentUserID}
          component={PaymentReceivedModal}
          is_fee_escalation_payment={true}
          pay_with_new_card={true}
          borrower_payment_amount={loanData.borrower_payment_amount}
          disclosed_amount={loanData.disclosed_amount}
          user_payment_types={userPaymentTypes}
        >
          <Button
            disabled={!getPaymentTypeAvailability(userPaymentTypes, 'lender')}
            className='order-header-button btn-sm mr-1 mx-md-1 my-md-0 fee-escalation-button'
          >
            Pay with New Card
          </Button>

        </ModalTrigger>
      )
    }
    if (checkPermission('payment_received_can_recharge_card') && userPaymentTypes.find(e => e.value === 'recharge')) {
      paymentMethods.push(
        <ModalTrigger
          default_amount={lender_fee_delta}
          default_payment_type={'recharge'}
          editing={false}
          consumers={loanData.consumers}
          order_id={orderData.id}
          borrower_payments_enabled={false}
          refreshOrder={refreshOrder}
          currentUserId={currentUserID}
          component={PaymentReceivedModal}
          is_fee_escalation_payment={true}
          pay_with_new_card={false}
          user_payment_types={userPaymentTypes}
        >
          <Button
            className='order-header-button btn-sm mr-1 mx-md-1 my-md-0 fee-escalation-button'
            disabled={!getPaymentTypeAvailability(userPaymentTypes, 'recharge')}
          >
            Recharge Borrower Card
          </Button>

        </ModalTrigger>
      )
    }
    if (checkPermission('payments_can_switch_to_manual')) {
      paymentMethods.push(<ModalTrigger
        default_amount={borrower_fee_delta.toFixed(2)}
        max_amount={disclosed_amount && !checkPermission('payments_charge_above_disclosed_amount_override') ? borrower_fee_delta : null}
        default_payment_type={'manual'}
        editing={false}
        consumers={loanData.consumers}
        order_id={orderData.id}
        refreshOrder={refreshOrder}
        currentUserId={currentUserID}
        component={PaymentReceivedModal}
        is_fee_escalation_payment={true}
        pay_with_new_card={false}
        borrower_payment_amount={loanData.borrower_payment_amount}
        disclosed_amount={loanData.disclosed_amount}
        user_payment_types={userPaymentTypes}
      >
        <Button
          className='order-header-button btn-sm ml-1 my-1 mr-0 my-md-0 fee-escalation-button'
          disabled={!getPaymentTypeAvailability(userPaymentTypes, 'manual')}
        >
          Create Manual Payment
        </Button>
      </ModalTrigger>)
    }
    if (checkPermission('collect_escalation_at_close')) {
      paymentMethods.push(
        <ModalTrigger
          component={ModalConfirmation}
          onConfirm={onCollectCloseSubmit}
          confirmationText='Collect at close?'
          textBody='Are you sure you want the fee escalation on this order to be collected at close?'
          dismissBtn='Cancel'
          confirmBtn='Confirm'
          modalClassName='modal-primary'
        >
          <Button
            className='order-header-button btn-sm mr-1 mx-md-1 my-md-0 fee-escalation-button'
            disabled={false}
          >
            Collect at Close
          </Button>

        </ModalTrigger>
      )
    }
    return (
      <div className="d-flex flex-column">
        <div className="my-1">
          {warningText}
        </div>
        <div className="d-flex flex-row flex-wrap flex-md-nowrap align-items-center my-1">
          <div className="d-flex flex-col flex-md-row align-items-center flex-grow-1">
            {paymentMethods.length === 0
              ? <span><em>You do not have sufficient permissions to reconcile this escalation.</em></span>
              : paymentMethods.map(method => method)}
          </div>
        </div>
      </div>
    )
  }
}

function isResendButtonDisabled(borrower_fee_delta, new_amount, disclosed_amount) {
  if (!disclosed_amount) {
    return false
  } else if (checkPermission('payments_charge_above_disclosed_amount_override')) {
    return false
  } else {
    return borrower_fee_delta <= 0
  }
}

export default compose(
  connectResource({
    prefetch: false,
    namespace: 'payForAppraisal',
    endpoint: 'payment/pay-appraiser',
    apiVersion: 2,
    list: true,
    successMessage: {
      POST: 'Your payment has been created.',
    },
  }),
  connectResource({
    prefetch: true,
    async: true,
    namespace: 'paymentTypes',
    endpoint: 'payment/payment-type/:orderId',
    apiVersion: 2,
    list: true,
  }),
  connectResource({
    namespace: 'user',
    endpoint: 'users/current_user',
    prefetch: false,
    apiVersion: 2,
  }),
)(FeeEscalationAlert)
