import React from 'react'
import { render } from 'react-dom'

import { configureStore, history } from './app/init'
import { state as initialState } from './app/cache'
import App from './app/App'
import { configure } from './app/common/utils/helpers'

// This code was taken from the unregister function in the registerServiceWorkers.js file in most react apps
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready.then(registration => {
    registration.unregister()
  })
}

const store = configureStore(initialState)
configure(store)

render(
  <App store={store} history={history} />,
  document.getElementById('root'),
)

if (module.hot) {
  module.hot.accept('./app/App.js', () => {
    const NextRootContainer = require('./app/App.js').default
    render(
      <NextRootContainer store={store} history={history} />,
      document.getElementById('root'),
    )
  })
}
