import React, { Component } from 'react'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Orders from './Orders'
import { Container } from 'reactstrap'
import { connectResource } from '../../common/utils/resource'
import Loader from '../../common/layout/components/Loader'
import isEmpty from 'lodash/isEmpty'
import { checkPermission } from '../../common/utils/helpers'
import { PaymentStatuses } from '@app/common/constants/paymentStatuses'
import moment from 'moment'
import {
  DATE_RANGE_FILTER_TEXT,
  KEY_3_MONTHS,
  KEY_6_MONTHS,
  KEY_12_MONTHS,
  KEY_ALL_TIME,
  KEY_CUSTOM_RANGE,
} from '../../common/constants/dateFilter'
import { updateDateRangeText } from '../../store/reducers/orderFilters/orderFiltersSlice'

class OrdersContainer extends Component {
  state = {
    searchText: '',
    panelistSearchText: '',
    selectedDefaultViews: [],
    selectedPipelineViews: [],
    status_filter: [],
    officer_filter: [],
    assigned_filter: [],
    paymentStatusFilterOptions: Object.keys(PaymentStatuses).map(key => ({
      filter: PaymentStatuses[key],
      id: PaymentStatuses[key],
    })),
    productFilterOptions: [],
    lenderFilterOptions: [],
    cu_min: 1,
    cu_max: 5,
    cu_filter: false,
    fetchingFilter: false,
    builtList: false,
    selectedDoc: null,
    lastIdPerPage: {
      1: null,
    },
    activeTab: 'all',
    selectedAppraisers: [],
    selectedLoanOfficers: [],
    selectedAssignedUsers: [],
    flaggedCount: 0,
    openDatePicker: false,
    datePickerStatus: false,
    dateRangeText: DATE_RANGE_FILTER_TEXT[KEY_6_MONTHS],
    customDateRangeDisplay: false,
    popOverState: false,
    start_date: moment.utc(moment()).subtract(6, 'months').format('YYYYMMDD'),
    end_date: moment.utc(moment()).format('YYYYMMDD'),
    getNextQueueIsEmpty: false,
    showAssignedUsers: false,
  }

  componentDidMount() {
    let offset = 0
    let last_id = null
    const showAssignedUsers = checkPermission('display_assigned_filter_on_order_table')
    // grab offset and last_id from orders
    if (this.props?.orders?.filters?.offset &&
      this.props?.orders?.filters?.last_id) {
      offset = this.props.orders.filters.offset
      last_id = this.props.orders.filters.last_id
    }

    if (this.props.is_amc_lender) {
      this.props.lenders.fetch()
    }

    const mountedState = { ...this.state, showAssignedUsers }
    if (this.props.pipeline_column_settings &&
      this.props.pipeline_column_settings.loan_officer &&
      checkPermission('user_view_loan_officer')) {
      this.props.officers.fetch()
    }

    // Check for filters
    if (this.props.orders && this.props.orders.filters && this.props.orders.filters.filters) {
      const filters = this.props.orders.filters.filters.split(',')
      mountedState.selectedDefaultViews = filters.map(filter => {
        return this.titleCase(filter.replace(/_/g, ' '))
      })
    }

    // check for pipeline views filter
    if (this.props.orders && this.props.orders.filters && this.props.orders.filters.pipeline_views) {
      mountedState.selectedPipelineViews = this.props.orders.filters.pipeline_views
    }

    // Check for search text
    if (this.props.orders && this.props.orders.filters && this.props.orders.filters.search) {
      mountedState.searchText = this.props.orders.filters.search
    }

    if (
      (this.props.orders?.filters &&
        this.props.orders.filters.start_date &&
        this.props.orders.filters.end_date) ||
      (this.props.orders?.filters &&
        this.props.dateRangeText === DATE_RANGE_FILTER_TEXT[KEY_ALL_TIME])) {
      mountedState.start_date = this.props.orders.filters.start_date
      mountedState.end_date = this.props.orders.filters.end_date
      mountedState.dateRangeText = this.props.dateRangeText
    }

    // If search text or filters selected then filter the results else just regular fetch
    if (mountedState.searchText || mountedState.selectedDefaultViews || mountedState.selectedPipelineViews) {
      this.props.orders.filter({
        search: mountedState.searchText,
        filters: this.props.orders.filters.filters,
        pipeline_views: mountedState.selectedPipelineViews,
        offset,
        last_id,
        cu_filter: this.props.orders.filters.cu_filter,
        cu_min: this.props.orders.filters.cu_min,
        cu_max: this.props.orders.filters.cu_max,
        appraiser: this.props.orders.filters.appraiser,
        start_date: mountedState.start_date,
        end_date: mountedState.end_date,
      })
      this.props.filters.filter({
        search: mountedState.searchText,
        filters: this.props.orders.filters.filters,
        pipeline_views: mountedState.selectedPipelineViews,
        start_date: mountedState.start_date,
        end_date: mountedState.end_date,
        ...this.props.filters.filters,
      })
      if (showAssignedUsers) {
        this.props.assignedUsers.filter({
          search: mountedState.searchText,
          filters: this.props.orders.filters.filters,
          pipeline_views: mountedState.selectedPipelineViews,
          appraiser: this.props.orders.filters.appraiser,
          start_date: mountedState.start_date,
          end_date: mountedState.end_date,
          ...this.props.filters.filters,
        })
      }
    } else {
      this.props.orders.fetch({ offset, last_id })
      this.props.filters.fetch()
    }
    this.setState(mountedState)

    // Check loan officers filter
    const selected_panelists = this.props.filters.selected_panelists

    const officer_filter = this.props.officers.officer_filter

    let loan_officers = this.props.officers.filters.loan_officers

    const loan_officer_filter = []

    if (loan_officers) {
      loan_officers = loan_officers.split(',')
      loan_officers.forEach(function(loan_officer) {
        if (officer_filter.loan_officers) {
          officer_filter.loan_officers.forEach(function(loan_officer_item) {
            if (loan_officer_item.id === loan_officer) {
              const selected_loan_officer = {}
              selected_loan_officer.label = loan_officer_item.name
              selected_loan_officer.value = loan_officer_item.id
              loan_officer_filter.push(selected_loan_officer)
            }
          })
        }
      })
    }
    this.setState({ selectedLoanOfficers: loan_officer_filter })

    // Flagged
    if (this.props.flagged_orders_count && this.props.flagged_orders_count > 0) {
      this.setState({
        flaggedCount: this.props.flagged_orders_count,
      })
    }

    // Check appraisers filter
    let appraisers = this.props.orders.filters.appraiser
    const appraiser_filter = []
    if (appraisers) {
      appraisers = appraisers.split(',')
      appraisers.forEach(function(appraiser) {
        if (selected_panelists) {
          selected_panelists.forEach(function(selected_panelist) {
            if (selected_panelist.resource_id === appraiser) {
              const selected_appraiser = {}
              selected_appraiser.label = selected_panelist.panelist_name
              selected_appraiser.value = selected_panelist.resource_id
              appraiser_filter.push(selected_appraiser)
            }
          })
        }
        if (appraiser === 'amc') {
          const reggoraNetwork = {}
          reggoraNetwork.label = 'Reggora Network'
          reggoraNetwork.value = 'amc'
          reggoraNetwork.search = 'Reggora Network'
          appraiser_filter.push(reggoraNetwork)
        }
      })
    }
    this.setState({ selectedAppraisers: appraiser_filter })
    this.props.panelistSearch.post({ limit: 20, search: '', ordering: 'firm_name', offset: 0, active: false })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { orders } = nextProps
    const { filters } = orders
    const {
      cu_min,
      cu_max,
      cu_filter,
      product_filter,
      payment_status,
      lender_filter,
    } = filters
    const { status = [] } = nextProps.statuses
    const { loan_officers = [] } = nextProps.officers
    const { assigned = [] } = nextProps.assignedUsers

    const officerToInitialize = this.props.officers.filters

    this.setState({ cu_filter, cu_min, cu_max })

    if (!isEmpty(product_filter)) {
      const productFilterOptions = this.state.productFilterOptions.map(filter => {
        product_filter.includes(filter.id) ? filter.selected = true : filter.selected = false
        return filter
      })
      this.setState({ productFilterOptions })
    }

    if (!isEmpty(payment_status)) {
      const paymentStatusFilterOptions = this.state.paymentStatusFilterOptions.map(filter => {
        payment_status.includes(filter.id) ? filter.selected = true : filter.selected = false
        return filter
      })
      this.setState({ paymentStatusFilterOptions })
    }

    if (!isEmpty(lender_filter)) {
      const lenderFilterOptions = this.state.lenderFilterOptions.map(filter => {
        lender_filter.includes(filter.id) ? filter.selected = true : filter.selected = false
        return filter
      })
      this.setState({ lenderFilterOptions })
    }

    if (!isEmpty(status) && isEmpty(this.state.status_filter)) {
      const status_filter = status.map(filter => {
        if (typeof filter === 'string') {
          return { filter }
        }
      })
      this.setState({ status_filter })
    }

    if (!isEmpty(assigned)) {
      const assigned_filter = []
      assigned.forEach(user => {
        const name = `${user.first_name} ${user.last_name}`
        assigned_filter.push({
          filter: name,
          selected: false,
          name: name,
          ...user,
        })
      })
      this.setState({ assigned_filter })
    }

    if (!isEmpty(loan_officers) && isEmpty(this.state.officer_filter)) {
      const filterType = 'loan_officers'
      const officer_filter = loan_officers.map(filter => {
        let selected = false
        if (officerToInitialize[filterType] &&
          filter.id &&
          officerToInitialize[filterType].indexOf(filter.id) > -1) {
          selected = true
        }
        return { filter: filter.name, selected, ...filter }
      })
      this.setState({ officer_filter })
    }

    if (this.props.fees.data && this.state.productFilterOptions.length === 0) {
      const productFilterOptions = []
      const productList = this.props.fees.data

      productList.forEach(product => {
        productFilterOptions.push({
          filter: product.description,
          id: product.id,
        })
        return productFilterOptions
      })

      this.setState({ productFilterOptions })
    }

    if (this.props.lenders.data && this.state.lenderFilterOptions.length === 0) {
      const lenderFilterOptions = []
      const lenderList = this.props.lenders.data

      lenderList.forEach(lender => {
        lenderFilterOptions.push({
          filter: lender.name || '',
          id: lender._id,
        })
        return lenderFilterOptions
      })

      this.setState({ lenderFilterOptions })
    }

    // Flagged
    if (nextProps.flagged_orders_count !== this.props.flagged_orders_count) {
      this.setState({
        flaggedCount: nextProps.flagged_orders_count,
      })
    }
  }

  componentWillUnmount() {
    // kill the search timeout to avoid updating state of unmounted component
    if (this.panelistSearchTimeout) {
      clearTimeout(this.panelistSearchTimeout)
    }
  }

  titleCase(str) {
    if (str === 'no p&s contracts') {
      return 'No P&S Contracts'
    } else {
      return str.toLowerCase().split(' ').map(function(word) {
        return word.replace(word[0], word[0].toUpperCase())
      }).join(' ')
    }
  }

  onFilter = () => {
    const {
      selectedDefaultViews,
      selectedPipelineViews,
      searchText,
      selectedAppraisers,
      selectedLoanOfficers,
      selectedAssignedUsers,
      cu_min,
      cu_max,
      cu_filter,
      status_filter,
      officer_filter,
      start_date,
      end_date,
      productFilterOptions,
      paymentStatusFilterOptions,
      lenderFilterOptions,
      showAssignedUsers,
    } = this.state
    const filters = {}

    if (officer_filter) {
      officer_filter.map(filter => {
        if (filter.selected) {
          const filterType = 'loan_officers'
          if (filters[filterType]) {
            filters[filterType] += `,${filter.id || filter.filter}`
          } else { filters[filterType] = `${filter.id || filter.filter}` }
        }
      })
    }

    if (status_filter) {
      status_filter.map(filter => {
        if (filter.selected) {
          const filterType = 'status'
          if (filters[filterType]) {
            filters[filterType] += `,${filter.id || filter.filter}`
          } else { filters[filterType] = `${filter.id || filter.filter}` }
        }
      })
    }

    const tagFilters = selectedDefaultViews.map(tag => {
      tag = tag.replace(/\s/g, '_')
      return tag.toLowerCase()
    }).join(',')


    if (selectedAppraisers.length === 0) {
      filters.appraiser = ''
    }
    selectedAppraisers.forEach(appraiser => {
      if (filters.appraiser) {
        filters.appraiser += `,${appraiser.value}`
      } else {
        filters.appraiser = appraiser.value
      }
    })

    if (selectedLoanOfficers.length === 0) {
      filters.loan_officer = ''
    }
    selectedLoanOfficers.forEach(loanOfficer => {
      if (filters.loan_officer) {
        filters.loan_officer += `,${loanOfficer.value}`
      } else {
        filters.loan_officer = loanOfficer.value
      }
    })

    if (selectedAssignedUsers.length === 0) {
      filters.assigned = ''
    }
    selectedAssignedUsers.forEach(assignedUser => {
      if (filters.assigned) {
        filters.assigned += `,${assignedUser.value}`
      } else {
        filters.assigned = assignedUser.value
      }
    })

    if (start_date && end_date) {
      filters.start_date = start_date
      filters.end_date = end_date
    }

    const allFilters = {
      ...filters,
      filters: tagFilters,
      pipeline_views: selectedPipelineViews,
      search: searchText,
      cu_min: cu_min,
      cu_max: cu_max,
      cu_filter: cu_filter,
      product_filter: this.getSelectedFilters(productFilterOptions),
      payment_status: this.getSelectedFilters(paymentStatusFilterOptions),
      lender_filter: this.getSelectedFilters(lenderFilterOptions),
    }

    this.props.orders.filter(allFilters, true)
    this.props.filters.filter(allFilters, true)
    if (showAssignedUsers) {
      this.props.assignedUsers.filter(allFilters, true)
    }
  }

  cuHandler = (cu_filter, cu_min, cu_max) => {
    this.setState({
      cu_min: cu_min,
      cu_max: cu_max,
      cu_filter: cu_filter,
    }, this.onFilter)
  }

  clearFilters = () => {
    const { searchText } = this.state
    this.setState({
      selectedDefaultViews: [],
      selectedPipelineViews: [],
      selectedAssignedUsers: [],
      selectedAppraisers: [],
      selectedLoanOfficers: [],
    })
    const filters = {
      search: searchText,
      filters: '',
      pipeline_views: [],
      offset: 0,
      last_id: null,
      cu_filter: false,
      cu_min: 1,
      cu_max: 5,
      appraiser: '',
      loan_officer: '',
      assigned: '',
    }
    this.props.orders.filter(filters)
    this.props.filters.filter(filters)
    if (this.state.showAssignedUsers) {
      this.props.assignedUsers.filter(filters)
    }
  }

  onFieldChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  clearSearch = () => {
    this.setState({ searchText: '' })
    this.props.orders.filter({
      search: '',
      filters: this.props.orders.filters.filters,
      pipeline_views: this.props.orders.filters.pipeline_views,
      offset: 0,
      last_id: null,
    })
    this.props.filters.filter({
      search: '',
      filters: this.props.filters.filters.filters,
      pipeline_views: this.props.filters.filters.pipeline_views,
    })
    if (this.state.showAssignedUsers) {
      this.props.assignedUsers.filter({
        search: '',
        filters: this.props.filters.filters.filters,
        pipeline_views: this.props.filters.filters.pipeline_views,
      })
    }
  }

  onSearchSubmit = (event) => {
    const { searchText, showAssignedUsers } = this.state
    this.props.orders.filter({
      search: searchText,
      filters: this.props.orders.filters.filters,
      pipeline_views: this.props.orders.filters.pipeline_views,
      offset: 0,
      last_id: null,
    })
    this.props.filters.filter({
      search: searchText,
      filters: this.props.filters.filters.filters,
      pipeline_views: this.props.filters.filters.pipeline_views,
    })
    if (showAssignedUsers) {
      this.props.assignedUsers.filter({
        search: searchText,
        filters: this.props.filters.filters.filters,
        pipeline_views: this.props.filters.filters.pipeline_views,
      })
    }
    this.setState({ lastIdPerPage: {} })
  }

  onSelectionChange = selectedDoc => {
    this.setState({ selectedDoc })
  }

  handleChange = (tag, checked) => {
    const { selectedDefaultViews, searchText, selectedPipelineViews, showAssignedUsers } = this.state
    const nextSelectedTags = checked
      ? [...selectedDefaultViews, tag]
      : selectedDefaultViews.filter(t => t !== tag)
    this.setState({ selectedDefaultViews: nextSelectedTags, lastIdPerPage: {} })
    const filters = nextSelectedTags.map(tag => {
      tag = tag.replace(/\s/g, '_')
      return tag.toLowerCase()
    }).join(',')
    this.props.orders.filter({
      filters,
      pipeline_views: selectedPipelineViews,
      offset: 0,
      last_id: null,
      search: searchText,
    })
    this.props.filters.filter({
      filters,
      pipeline_views: selectedPipelineViews,
      search: searchText,
    })
    if (showAssignedUsers) {
      this.props.assignedUsers.filter({
        filters,
        pipeline_views: selectedPipelineViews,
        search: searchText,
      })
    }
  }

  handleViewChange = (viewId, checked) => {
    const { selectedDefaultViews, selectedPipelineViews, searchText, showAssignedUsers } = this.state
    const nextSelectedViews = checked
      ? [...selectedPipelineViews, viewId]
      : selectedPipelineViews.filter(t => t !== viewId)
    this.setState({ selectedPipelineViews: nextSelectedViews, lastIdPerPage: {} })

    const urlSafeSelectedDefaultViews = selectedDefaultViews.map(tag => {
      tag = tag.replace(/\s/g, '_')
      return tag.toLowerCase()
    }).join(',')

    this.props.orders.filter({
      pipeline_views: nextSelectedViews,
      offset: 0,
      last_id: null,
      search: searchText,
    })
    this.props.filters.filter({
      filters: urlSafeSelectedDefaultViews,
      pipeline_views: nextSelectedViews,
      search: searchText,
    })
    if (showAssignedUsers) {
      this.props.assignedUsers.filter({
        filters: urlSafeSelectedDefaultViews,
        pipeline_views: nextSelectedViews,
        search: searchText,
      })
    }
  }

  handleTabChange = (tab) => {
    const { searchText, showAssignedUsers } = this.state
    let due_in
    if (tab !== 'all' && tab !== 'today') { due_in = 7 - parseInt(tab) }
    if (tab === 'today') { due_in = 0 }
    this.props.orders.filter({
      filters: this.props.orders.filters.filters,
      pipeline_views: this.props.orders.filters.pipeline_views,
      due_in,
      offset: 0,
      last_id: null,
      search: searchText,
    })
    this.props.filters.filter({
      filters: this.props.filters.filters.filters,
      pipeline_views: this.props.filters.filters.pipeline_views,
      due_in,
      search: searchText,
    })
    if (showAssignedUsers) {
      this.props.assignedUsers.filter({
        filters: this.props.filters.filters.filters,
        pipeline_views: this.props.filters.filters.pipeline_views,
        due_in,
        search: searchText,
      })
    }
  }

  handlePanelistSearch = (panelistSearchText) => {
    // update the search text state and reset the selected appraisers
    this.setState({ panelistSearchText })

    this.props.panelistSearch.isLoading = true

    // dont request if search text is empty
    if (!panelistSearchText) {
      return
    }
    if (this.panelistSearchTimeout) {
      clearTimeout(this.panelistSearchTimeout)
      this.panelistSearchTimeout = setTimeout(() => {
        this.props.panelistSearch.post({
          limit: 20, search: panelistSearchText, ordering: 'firm_name', offset: 0, active: false,
        })
      }, 200)
    } else {
      this.panelistSearchTimeout = setTimeout(() => {
        this.props.panelistSearch.post({
          limit: 20, search: panelistSearchText, ordering: 'firm_name', offset: 0, active: false,
        })
      }, 200)
    }
  }

  handlePanelistChange = (selectedAppraisers) => {
    this.setState({
      selectedAppraisers,
    }, this.onFilter)
  }

  handleLoanOfficerChange = (selectedLoanOfficers) => {
    this.setState({
      selectedLoanOfficers,
    }, this.onFilter)
  }

  handleAssignedUserChange = (selectedAssignedUsers) => {
    this.setState({
      selectedAssignedUsers,
    }, this.onFilter)
  }

  getSelectedFilters = options => {
    return options
      .filter(filter => filter.selected)
      .map(filter => filter.id)
  }

  updateFlagCount = (newFlagCount) => {
    this.setState(prevState => {
      return { flaggedCount: prevState.flaggedCount + newFlagCount }
    })
  }

  handleDateFilterChange = (selectedItem) => {
    const dateRange = selectedItem.currentTarget.getAttribute('daterange')
    if (dateRange !== KEY_CUSTOM_RANGE) {
      this.props.actions.updateDateRangeText(DATE_RANGE_FILTER_TEXT[dateRange])
    }
    if (dateRange === KEY_3_MONTHS) {
      this.createPresetDateRequest(3)
      return
    }
    if (dateRange === KEY_6_MONTHS) {
      this.createPresetDateRequest(6)
      return
    }
    if (dateRange === KEY_12_MONTHS) {
      this.createPresetDateRequest(12)
      return
    }
    if (dateRange === KEY_ALL_TIME) {
      this.setState({
        start_date: null,
        end_date: null,
      }, this.onFilter)
      return
    }
    if (dateRange === KEY_CUSTOM_RANGE) {
      this.setState({ customDateRangeDisplay: true })
    }
  }

  createPresetDateRequest = (months) => {
    const start_date = moment.utc(moment()).subtract(months, 'months').format('YYYYMMDD')
    const end_date = moment.utc(moment()).format('YYYYMMDD')
    this.setState({
      start_date,
      end_date,
      popOverState: false,
    }, this.onFilter)
  }

  handleCustomDateFilterChange = (selectedItem) => {
    if (selectedItem.length === 2) {
      const start_date = selectedItem[0]
      const end_date = selectedItem[1]
      const start_date_formatted = start_date.format('YYYYMMDD')
      const end_date_formatted = end_date.format('YYYYMMDD')
      const dateRangeString = start_date.format('MM/DD/YYYY') + ' - ' + end_date.format('MM/DD/YYYY')
      this.props.actions.updateDateRangeText(dateRangeString)
      this.setState({
        start_date: start_date_formatted,
        end_date: end_date_formatted,
        customDateRangeDisplay: false,
        popOverState: false,
      }, this.onFilter)
    }
  }

  onOpenChange = (open) => {
    if (!open) {
      this.setState({
        customDateRangeDisplay: false,
      })
    }
  }

  togglePopOver = () => {
    this.setState(prevState => ({
      popOverState: !prevState.popOverState,
    }))
  }

  popOverStatus = status => {
    if (!status) {
      this.setState({
        popOverState: false,
      })
    }
  }

  handleReceivedNextOrder = nextOrder => {
    this.setState({
      getNextQueueIsEmpty: !nextOrder,
    })
  }

  handleCloseGetNextQueueEmpty = () => {
    this.setState({ getNextQueueIsEmpty: false })
  }

  render() {
    const { orders, ...props } = this.props
    const {
      searchText,
      selectedDoc,
      selectedDefaultViews,
      selectedPipelineViews,
      lastIdPerPage,
      activeTab,
      panelistSearchText,
    } = this.state

    if (!orders || !orders.data) { return <main className="main"><Container fluid><Loader/></Container></main> }

    const { numberOrdersByDay = {} } = orders


    return (
      <main className="main">
        <Orders
          {
            ...{
              status_filter: this.state.status_filter,
              officer_filter: this.state.officer_filter,
              assigned_filter: this.state.assigned_filter,
              selectedAppraisers: this.state.selectedAppraisers,
              selectedLoanOfficers: this.state.selectedLoanOfficers,
              selectedAssignedUsers: this.state.selectedAssignedUsers,
              paymentStatusFilterOptions: this.state.paymentStatusFilterOptions,
              productFilterOptions: this.state.productFilterOptions,
              lenderFilterOptions: this.state.lenderFilterOptions,
              cu_filter: this.state.cu_filter,
              cu_min: this.state.cu_min,
              cu_max: this.state.cu_max,
              cuHandler: this.cuHandler,
              numberOrdersByDay,
              lastIdPerPage,
              selectedDefaultViews,
              selectedPipelineViews,
              selectedDoc,
              orders,
              activeTab,
              flaggedCount: this.state.flaggedCount,
              openDatePicker: this.state.openDatePicker,
              dateRangeText: this.state.dateRangeText,
              customDateRangeDisplay: this.state.customDateRangeDisplay,
              popOverState: this.state.popOverState,
              getNextQueueIsEmpty: this.state.getNextQueueIsEmpty,
              ...props,
            }
          }
          handleTabChange={this.handleTabChange}
          onSelectionChange={this.onSelectionChange}
          searchText={searchText}
          panelistSearchText={panelistSearchText}
          onFilter={this.onFilter}
          handleChange={this.handleChange}
          handleViewChange={this.handleViewChange}
          clearSearch={this.clearSearch}
          clearFilters={this.clearFilters}
          onFieldChange={this.onFieldChange}
          onSearchSubmit={this.onSearchSubmit}
          handlePanelistChange={this.handlePanelistChange}
          handlePanelistSearch={this.handlePanelistSearch}
          handleLoanOfficerChange={this.handleLoanOfficerChange}
          handleAssignedUserChange={this.handleAssignedUserChange}
          onPaymentStatusFilter={this.onPaymentStatusFilter}
          onProductFilter={this.onProductFilter}
          onLenderFilter={this.onLenderFilter}
          updateFlagCount={this.updateFlagCount}
          togglePopOver={this.togglePopOver}
          popOverStatus={this.popOverStatus}
          handleDateFilterChange={this.handleDateFilterChange}
          handleCustomDateFilterChange={this.handleCustomDateFilterChange}
          onOpenChange={this.onOpenChange}
          handleReceivedNextOrder={this.handleReceivedNextOrder}
          handleCloseGetNextQueueEmpty={this.handleCloseGetNextQueueEmpty}
        />
        {orders.isLoading ? <Container><Loader /></Container> : null}
      </main>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    view_all_orders: state.resource.orders && state.resource.orders.view_all_orders,
    is_using_amc: state.resource.user.data.lender.is_using_amc,
    is_amc_lender: state.resource.user.data.lender.is_amc_lender,
    custom_panel: state.resource.user.data.lender.custom_panel,
    pipeline_column_settings: state.resource.user.data.lender.pipeline_column_settings,
    filter_settings: state.resource.user.data.role.filter_settings,
    current_user_role_id: state.resource.user.data.role.id,
    attention_required_count: state.resource.filters && state.resource.filters.attention_required_count,
    unread_messages_count: state.resource.filters && state.resource.filters.unread_messages_count,
    unapproved_messages_count: state.resource.filters && state.resource.filters.unapproved_messages_count,
    unanswered_messages_count: state.resource.filters && state.resource.filters.unanswered_messages_count,
    behind_schedule_count: state.resource.filters && state.resource.filters.behind_schedule_count,
    no_ps_contracts_order_count: state.resource.filters && state.resource.filters.no_ps_contracts_order_count,
    pending_offers_count: state.resource.filters && state.resource.filters.pending_offers_count,
    assigned_to_me_orders_count: state.resource.filters ? state.resource.filters.assigned_to_me_orders_count : null,
    flagged_orders_count: state.resource.filters && state.resource.filters.flagged_orders_count,
    has_amc_orders: state.resource.filters && state.resource.filters.has_amc_orders,
    dateRangeText: state.orderFilters && state.orderFilters.dateRangeText,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      updateDateRangeText,
    }, dispatch),
  }
}

export default compose(
  connect(mapStateToProps, null),
  connect(null, mapDispatchToProps),
  connectResource(
    {
      namespace: 'orders',
      endpoint: 'order/full/',
      list: true,
      refresh: true,
      async: true,
      useRouter: true,
      apiVersion: 2,
      prefetch: false,
      filters: {
        search: '',
        offset: 0,
        last_id: '',
        status: '',
        filters: '',
        pipeline_views: [],
        cu_filter: '',
        cu_min: '',
        cu_max: '',
        loan_officer: '',
        appraiser: '',
        start_date: '',
        end_date: '',
        lender_filter: [],
        product_filter: [],
        payment_status: [],
        assigned: '',
      },
    }
  ),
  connectResource(
    {
      namespace: 'filters',
      endpoint: 'order/filters',
      list: true,
      refresh: true,
      useRouter: true,
      apiVersion: 2,
      prefetch: false,
      filters: {
        search: '',
        status: '',
        filters: '',
        pipeline_views: [],
        cu_filter: '',
        cu_min: '',
        cu_max: '',
        loan_officer: '',
        appraiser: '',
        product_filter: [],
        payment_status: [],
        lender_filter: [],
        assigned: '',
      },
    }
  ),
  connectResource({
    namespace: 'assignedUsers',
    endpoint: 'order/assigned-filter',
    list: true,
    refresh: true,
    useRouter: true,
    apiVersion: 2,
    prefetch: false,
    filters: {
      search: '',
      status: '',
      filters: '',
      pipeline_views: [],
      cu_filter: '',
      cu_min: '',
      cu_max: '',
      start_date: '',
      end_date: '',
      loan_officer: '',
      appraiser: '',
      product_filter: [],
      payment_status: [],
      lender_filter: [],
    },
  }),
  connectResource(
    {
      namespace: 'fees',
      endpoint: 'fee/full/',
      list: true,
      refresh: true,
      async: true,
      useRouter: true,
      apiVersion: 2,
    }
  ),
  connectResource({
    namespace: 'pipelineViews',
    endpoint: 'lender/query_builders',
    prefetch: true,
    list: true,
    refresh: true,
    filters: {
      type: 'pipeline_views',
    },
    apiVersion: 2,
  }),
  connectResource({
    namespace: 'reviewerQueueQueryBuilders',
    endpoint: 'lender/query_builders',
    prefetch: true,
    list: true,
    refresh: true,
    filters: {
      type: 'reviewer_queues',
    },
    apiVersion: 2,
  }),
  connectResource({
    namespace: 'panelistSearch',
    endpoint: 'appraiser/by_zone',
    async: true,
    prefetch: false,
    apiVersion: 2,
  }),
  connectResource({
    namespace: 'officers',
    endpoint: 'order/officers-filter',
    list: true,
    refresh: true,
    useRouter: true,
    apiVersion: 2,
    prefetch: false,
  }),
  connectResource({
    namespace: 'statuses',
    endpoint: 'order/status-filter',
    list: true,
    refresh: true,
    useRouter: true,
    apiVersion: 2,
    prefetch: true,
  }),
  connectResource({
    namespace: 'lenders',
    endpoint: 'lender/',
    list: true,
    async: true,
    refresh: true,
    apiVersion: 2,
    prefetch: false,
    filters: {
      limit: 500,
      fields: ['id', 'name'],
    },
  }),
)(OrdersContainer)
