import React, { Component } from 'react'
import { FormGroup, Label, Input } from 'reactstrap'
import { Tooltip } from 'antd'
import analytics from '@helpers/segment'


export class Filter extends Component {
  state = {
    listVisible: false,
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef = (node) => {
    this.wrapperRef = node
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside = (event) => {
    const { listVisible } = this.state
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && listVisible) {
      this.setState({ listVisible: false })
    }
  }

  cutLongFilterName = (filterName) => {
    const MAX_LEN_FILTER_NAME = 20
    let smallName = filterName

    // Cutoff long text
    if (filterName.length > MAX_LEN_FILTER_NAME) {
      smallName = smallName.substring(0, MAX_LEN_FILTER_NAME) + '...'
      smallName = <Tooltip title={filterName}>{smallName}</Tooltip>
    }
    return smallName
  }

  select = (field, e) => {
    field.selected = !field.selected
    this.props.onFilter()
    e.preventDefault()
  }

  orderFilterSegmentTrack = (filterName, selected) => {
    // Because this component has no knowledge where it is being used, we have to just check to see if
    // the list of filters contains an order filter to make the determination if this is the orders filter
    if (!selected && this.props.list.filter(f => f.filter === 'Pending Approval').length > 0) {
      analytics.trackComponent('Orders Category Clicked', {
        category_name: filterName,
      })
    }
  }

  render() {
    // This prop is used to pre-check the selected filters if there are any
    const { checkedList, list } = this.props
    const checkedFilters = checkedList ? checkedList.split(',') : []

    // Change icon to blue if there are filters used
    let icon = <i data-fa-transform="shrink-8" className='fas fa-filter' ></i>
    if (checkedFilters.length > 0) {
      icon = <i data-fa-transform="shrink-8" className='fas fa-filter' style={{ color: '#3C88FD' }}></i>
    } else {
      for (let i = 0; i < list.length; i++) {
        if (list[i].selected) {
          icon = <i data-fa-transform="shrink-8" className='fas fa-filter' style={{ color: '#3C88FD' }}></i>
          break
        }
      }
    }

    return <div ref={this.setWrapperRef} className={'dropdown-container' + (this.state.listVisible ? ' show' : '')}>
      <button className={'dropdown-display' + (this.state.listVisible ? ' clicked' : '')} onClick={() => this.setState({ listVisible: !this.state.listVisible })}>
        {icon}
        <i className="fa fa-angle-down"></i>
      </button>
      <div className="dropdown-list">
        <FormGroup className="filter-form-check" check>
          {this.props.list.map((field, key) => {
            checkedFilters.map((checkedFilter) => {
              // eslint-disable-next-line no-unused-expressions
              field.filter === checkedFilter ? field.selected = true : field.selected
            })
            return (
              <Label className={((key !== this.props.list.length - 1) && 'filter-label') || ''} key={key} onClick={(e) => this.select(field, e)} check>
                <Input onClick={() => this.orderFilterSegmentTrack(field.filter, field.selected)} type="checkbox" checked={field.selected}/>
                {this.cutLongFilterName(field.filter)}
              </Label>
            )
          })}
        </FormGroup>
      </div>
    </div>
  }
}
