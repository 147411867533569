import React, { Component } from 'react'
import { compose } from 'redux'
import { connectResource } from '../../common/utils/resource'
import { connect } from 'react-redux'
import { Col, Row, Button } from 'reactstrap'
import { SubmissionUpload } from '../../../reggora_shared_ui/components'
import { ModalHeader, ModalBody, ModalFooter, ModalCloser } from '../../common/modals/antd/ModalComponents'
import ModalWrapper from '../../common/modals/antd/ModalWrapper'
import PdfViewModal from '../../common/widgets/PdfViewModal'

import Rephrase from '../../common/utils/Rephrase'

class ModalManualAppraisalUpload extends Component {
  state = {
    pdfFile: null,
    viewPDF: false,
  }

  closePdf = _ => {
    this.setState({ viewPDF: false })
  }

  onPDFChange = (file) => {
    /*
      file is a File type interface obj https://developer.mozilla.org/en-US/docs/Web/API/File
      Example: [
        extension: "pdf"
        id: "files-1"
        preview: {type: 'file'}
        sizeReadable: "2MB"
        lastModified: 1640791538633
        lastModifiedDate: Wed Dec 29 2021 10:25:38 GMT-0500 (Eastern Standard Time) {}
        name: "1_appraisal_with_invoice.pdf"
        size: 1135341
        type: "application/pdf"
        webkitRelativePath: ""
        [[Prototype]]: File
        length: 1
        [[Prototype]]: Array(0)
      ]
    */
    return this.setState({
      pdfFile: file,
      viewPDF: true,
    })
  }

  onPDFRemove = (idx) => {
    const { pdfFile } = this.state
    pdfFile.splice(idx, 1)
    this.setState({ pdfFile })
  }

  onSubmit = () => {
    const { pdfFile } = this.state
    const { modalApi, refreshOrder, orderId, vaSubmissions } = this.props

    // Close the modal first so the user gets immediate feedback
    modalApi.cancel()

    if (pdfFile) {
      const body = {
        pdf_file: pdfFile,
        order_id: orderId,
      }
      vaSubmissions.post(body)
        .then(_ => {
          return refreshOrder()
        }).catch(e => console.error(e))
    } else {
      modalApi.cancel()
    }
  }

  render() {
    const {
      modalApi,
    } = this.props

    const { pdfFile, viewPDF } = this.state

    const uploadSentence = 'You may upload revisions or follow-ups here at any time. If the invoice is included as the first page of the appraisal, it will be extracted and placed in the Invoice folder. All versions of the appraisal uploaded here will appear in the Submissions tab.'
    const modalContent = (
      <Col xs="12">
        <Row className='mt-2'>
          <h1 className="va-submission-header pt-3">
            <strong><Rephrase>Upload Appraisal PDF</Rephrase></strong>
          </h1>
          <div className='mt-2'>
            <Rephrase>{uploadSentence}</Rephrase>
          </div>
          <SubmissionUpload
            className='d-flex justify-content-around my-3'
            pdfRequired={true}
            isVA={true}
            onPDFChange={this.onPDFChange}
            onPDFRemove={this.onPDFRemove}
            pdfFile={pdfFile}
          />
        </Row>
      </Col>
    )

    const viewPDFContent = (
      <ModalWrapper
        modal={{ component: PdfViewModal, contentClass: 'pdf-view-modal', show: viewPDF, onCancel: () => this.closePdf(), size: 'large' }}
        pdf={{ fileUrl: pdfFile && pdfFile?.[0] ? URL.createObjectURL(pdfFile?.[0]) : undefined }}
      />
    )

    return (<div>
      <ModalHeader title={<h5><Rephrase>Manual Appraisal Upload</Rephrase></h5>}></ModalHeader>
      <ModalCloser onClose={modalApi.cancel} />
      <ModalBody>
        {modalContent}
        {viewPDFContent}
      </ModalBody>
      <ModalFooter className="border-0">
        <Button className='ml-3' onClick={modalApi.cancel}>Cancel</Button>
        <Button className='ml-3' onClick={this.onSubmit} color='primary'>Submit</Button>
      </ModalFooter>
    </div>
    )
  }
}
function mapStateToProps(state, props) {
  return {
    pdf_file: state.pdfFile,
    orderId: props.orderId,
  }
}

export default compose(
  connect(mapStateToProps, null),
  connectResource({
    namespace: 'vaSubmissions',
    endpoint: 'order/va/submission',
    apiVersion: 2,
    prefetch: false,
    successMessage: {
      POST: 'The submission has been uploaded to this order.',
    },
  })
)(ModalManualAppraisalUpload)
