// always import React first
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react'

// libraries
import jwtDecode from 'jwt-decode'
import { useFlags } from 'launchdarkly-react-client-sdk'

// internal dependencies
import UserTokenErrorModal from './UserTokenErrorModal'


const AUTH_TOKEN_STORAGE_KEY = 'reggora_lender_auth_token'

const getUserIDFromToken = (tokenValue) => {
  if (!tokenValue) {
    return null
  }
  const decodedValue = jwtDecode(tokenValue)
  return decodedValue.user_id
}


const UserTokenErrorModalWrapper = () => {
  const [shouldShowModal, setShouldShowModal] = useState(false)
  const flags = useFlags()
  const preventInteractionAfterUserTokenChangeFlag = flags?.preventInteractionAfterUserTokenChange
  const handleLocalStorageChange = useCallback((event) => {
    // storage event only fires across tabs, and if the auth token was changed by another tab, we want to prevent further action
    if (event.key === AUTH_TOKEN_STORAGE_KEY) {
      const {
        oldValue,
        newValue,
      } = event
      const oldUserID = getUserIDFromToken(oldValue)
      const newUserID = getUserIDFromToken(newValue)
      if (oldUserID !== newUserID) {
        // only show modal if the actual user ID changed
        setShouldShowModal(true)
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener('storage', handleLocalStorageChange)

    return () => {
      window.removeEventListener('storage', handleLocalStorageChange)
    }
  }, [handleLocalStorageChange])

  if (!preventInteractionAfterUserTokenChangeFlag) {
    // default legacy behavior, do not even check for token changes
    return null
  }

  if (shouldShowModal) {
    return <UserTokenErrorModal />
  }

  // most of the time we have nothing to show here
  return null
}

export default UserTokenErrorModalWrapper
