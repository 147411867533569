import React from 'react'
import { Popover, Tooltip, Tag } from 'antd'
import { checkCanViewAppraiserNameOnOrder } from '../../common/utils/helpers'

export default function CustomTimelineItem(props) {
  let {
    color,
    dot,
    description,
    subDescription,
    tagText,
    additionalInfo,
    viewReasonText,
    initiator,
    initiator_type,
    amcOrder,
    amcLender,
    tooltipPermission,
    hideTooltip,
    orderData,
  } = props

  const content = (<div>
    <small>Updated By:</small>
    <div>{initiator}</div>
  </div>)
  const permissionCheck = (initiator_type === 'appraiser' && orderData && checkCanViewAppraiserNameOnOrder(orderData))
  const amcCheck = !amcOrder || (amcOrder && amcLender)
  const showPopover = (amcCheck && permissionCheck) ||
    initiator_type === 'lender' ||
    initiator_type === 'System'
  tooltipPermission = !tooltipPermission || (tooltipPermission && showPopover)
  const timelineContent = (

    <div className='timeline-content-container'>
      {tagText && <Tag className='tag-text' color={color}>{tagText}</Tag>}
      <div className='icon-container' style={{ color: color }}>{dot}</div>
      <div className='text-container'>
        {description && <span className='title-text'>{description}</span>}
        <div className='mt-1'>
          {subDescription && <small className='float-left'>{subDescription}</small>}
          {additionalInfo && viewReasonText && tooltipPermission && !hideTooltip && <Tooltip title={additionalInfo}><small className='float-right ml-3 '><u>{viewReasonText}</u></small></Tooltip>}
          {additionalInfo && viewReasonText && hideTooltip && <small className='float-right ml-3 '><u>{viewReasonText}</u></small>}
        </div>
      </div>
    </div>
  )

  return (
    <li className='timeline-item' key={description}>
      <div className='horizontal-divider'></div>
      {false && <div className='timeline-tail'></div>}
      {initiator && showPopover
        ? <Popover placement="left" content={content}>
          {timelineContent}
        </Popover>
        : timelineContent
      }
    </li>
  )
}
