import { of } from 'rxjs/observable/of'
import { fromPromise } from 'rxjs/observable/fromPromise'
import {
  CREATE_CSV_LOANS
} from '../../pages/loans/actions'
import {
  setData
} from '../../common/utils/resource'

export default function createCsvLoans(action$, store, { API }) {
  return action$.ofType(CREATE_CSV_LOANS).mergeMap(action => {
    return fromPromise(API('loans/upload', 2).request('POST', undefined, { data: action.data })).switchMap(uploadRes => {
      console.log('upload result', uploadRes)
      const {
        number_loans_failed
      } = uploadRes
      let message = 'Your Excel spreadsheet has successfully been uploaded.'
      if(number_loans_failed) {
        message += ` Unfortunately, ${number_loans_failed} loans from this file could not be created.`
      }
      return fromPromise(API('loans/full/?limit=10&offset=0', 2).get()).switchMap(res => {
        return of(setData(res, { resource: { list: true, namespace: 'loans', successMessage: { 'POST': message } }, type: 'POST' }))
      })
    })
  })
}
