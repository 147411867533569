import { access } from '../../common/session'
import { loadableWithRetry } from '../../common/utils/lazyLoad'

const routes = [
  {
    path: '/',
    component: loadableWithRetry(() => import('./AppraisersContainer')),
    name: 'appraisers',
    exact: true,
    access: access.F_APPRAISERS,
    accessRedirectTo: '/dashboard',
  },
  {
    path: '/exclusionaryList',
    component: loadableWithRetry(() => import('./ExclusionaryListContainer')),
    name: 'exclusionaryList',
    exact: true,
    access: access.F_APPRAISERS,
    accessRedirectTo: '/dashboard',
  },
  {
    path: '/:id',
    name: 'singleAppraiser',
    component: loadableWithRetry(() => import('./SingleAppraiserContainer')),
    access: access.F_APPRAISERS_SINGLE_APPRAISER,
    accessRedirectTo: '/dashboard',
  },
]

export default routes
