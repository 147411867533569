import React, { Component } from 'react'
import Files from 'react-files'
import { Button, Table } from 'reactstrap'
import { FileUpload } from '../..'
import {
  FILE_TYPE_PDF,
  FILE_TYPE_XML
} from '../../../constants'


export default function SubmissionUpload(props) {
  /**
    Displays uploads for the pdf and xml files for a single submission
  */
    const {
      className,
      onPDFChange,
      onPDFRemove,
      onXMLChange,
      onXMLRemove,
      pdfFile,
      pdfRequired,
      xmlFile,
      xmlRequired,
      isVA
    } = props

    if(isVA){
      return (<div className={className}>
        <div className='d-flex flex-column va-upload-container'>
          <Files
          className='pdf-upload'
          onChange={onPDFChange}
          clickable
          id="pdfRequired"
          multiple={false}
          >
          <div className='w-100 flex-row'>
            <Button className='va-submission' onClick={(e) => e.preventDefault()} color='success'>{'Upload Appraisal'}</Button>
          </div>
          </Files>
      </div>

      <footer>
        <Table className='my-2'>
          <tbody>
            { pdfFile && pdfFile.length > 0
              ? pdfFile.map((file, idx) =>
                <tr key={file.id}>
                  <td className="w-100">
                    <p className="font-weight-bold ml-3 mr-3">{file.name}
                      <span className="font-weight-normal ml-3">{file.sizeReadable}</span>
                    </p>
                  </td>
                  <td className="td--padding-button d-flex">
                  <Button onClick={(event) => onPDFRemove(idx, file)} id={file.id} color="danger  margin-right-5">Remove</Button>
                  </td>
                </tr>
              )
              : null }
          </tbody>
        </Table>
      </footer>


      </div>)
    }

    return (<div className={className}>
      <FileUpload
        inputClassName="pdf-upload"
        fileList={pdfFile ? [pdfFile] : null}
        fileType={FILE_TYPE_PDF}
        onChange={onPDFChange}
        onRemove={onPDFRemove}
        required={pdfRequired}
        id="pdfRequired"
      />
      <FileUpload
        inputClassName="xml-upload"
        fileList={xmlFile ? [xmlFile] : null}
        fileType={FILE_TYPE_XML}
        onChange={onXMLChange}
        onRemove={onXMLRemove}
        required={xmlRequired}
        id="xmlRequired"
      />
    </div>)
}

