import { loadableWithRetry } from '../../common/utils/lazyLoad'

const routes = [
  {
    path: '/',
    component: loadableWithRetry(() => import('./AllNotificationsContainer')),
    exact: true,
  },
]

export default routes
