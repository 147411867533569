import React, { Component } from 'react'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
} from 'reactstrap'
import { Badge } from 'antd'
import { Link } from 'react-router-dom'
import { getNotificationText, checkPermission } from '../../../utils/helpers'
import { history } from '../../../../init'
import Rephrase from '../../../utils/Rephrase'

const isUnviewed = notification => {
  return !notification.viewed || notification.viewed === 'None'
}

export default class NotificationsDropdown extends Component {
    onLinkClick = (onclickEvent) => {
      const {
        viewNotifications,
        shouldRefreshSingleOrder,
      } = this.props
      viewNotifications()
      const relativeLinkPath = onclickEvent.currentTarget.pathname
      const currentPath = history.location.pathname
      if (relativeLinkPath === currentPath) {
        shouldRefreshSingleOrder()
      }
    }

    render() {
      const props = this.props
      const hasNotifications = props.notifications.data && props.notifications.count > 0
      const notifications = hasNotifications ? props.notifications.data : []
      const unviewed = notifications.find(isUnviewed)


      return (
        <Dropdown nav isOpen={props.isDropdownOpen} toggle={props.onToggle}>
          <DropdownToggle className='' onClick={props.viewNotifications} nav>
            <div className={`${unviewed ? 'animated swing infinite delay-2s slow' : ''}`}>
              <Badge dot={unviewed} className='mt-1'>
                <i className='icon-bell' />
              </Badge>
            </div>
          </DropdownToggle>
          <DropdownMenu style={{ width: '500px' }} right>
            <DropdownItem header tag='div' className='text-center'><strong>Notifications</strong></DropdownItem>
            {notifications.map(n => {
              let routeDict = '#'
              if (n.object_delta.primary_order_id) {
                routeDict = {
                  pathname: `/orders/${n.object_delta.primary_order_id}`,
                  state: {
                    followUpOrder: n.notification_object_id,
                  },
                }
              } else if (n.notification_object_id) {
                routeDict = {
                  pathname: `/orders/${n.notification_object_id}`,
                }
              }

              if (n.object_delta.OVERRIDE_PATHNAME) {
                routeDict = {
                  pathname: n.object_delta.OVERRIDE_PATHNAME,
                }
              }

              return (<Link pathname={routeDict.pathname} key={n.id} onClick={this.onLinkClick} className='dropdown-link' to={routeDict}>
                <DropdownItem className='border-bottom' style={{ wordWrap: 'break-word' }}>{isUnviewed(n) ? <Badge status="processing" /> : <i className={'icon-bell'}/>}<Rephrase>{getNotificationText(n)}</Rephrase></DropdownItem>
              </Link>)
            })}
            {!hasNotifications && <DropdownItem><Rephrase>{getNotificationText({ notification_type: 'no_notifications' })}</Rephrase></DropdownItem>}
            {
              hasNotifications && checkPermission('all_notifications_route')
                ? <DropdownItem header tag="div" className='text-center'>
                  <Link className='dropdown-link' to={{ pathname: '/notifications' }}>
                    <strong>Show all Notifications</strong>
                  </Link>
                </DropdownItem>
                : null
            }
          </DropdownMenu>
        </Dropdown>
      )
    }
}
