import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { BellOutlined, MailOutlined, MessageOutlined, SolutionOutlined } from '@ant-design/icons'
import { Tooltip, Popover } from 'antd'
import { UncontrolledTooltip, Container } from 'reactstrap'
import { ALERT_TEXT } from '../../common/constants/alerts'
import { STATUS_KEY_FINDING_APPRAISERS } from '@app/common/constants/orders'
import Rephrase from '../../common/utils/Rephrase'
import { selectLenderId } from '@app/store/reducers/user/userSelectors'
import { utcToLocal } from '../../common/utils/helpers'
import { JOB_TYPES } from '@app/common/constants/jobTypes'

const format = 'MMM Do YYYY hh:mm a'

const OrderAlerts = ({
  order,
  is_amc_lender,
}) => {
  const lenderId = useSelector(selectLenderId)
  // Use new LAR document if present, fall back to old one as well
  const {
    lender_attention_required_reason,
    lender_attention_required,
    lender_attentions,
  } = order

  let lenderAttentionRequired = false
  let lenderAttentionRequiredReason = ''
  let showLarAlert = false
  let offerCount = 0
  let lastSubmittedOffer = ''
  // Use new LenderAttention document if present, otherwise fall back to old one
  if (lender_attentions && lender_attentions.length > 0) {
    const lenderAttention = lender_attentions.find(la => la.lender_id === lenderId)
    if (lenderAttention) {
      const { reason } = lenderAttention
      lenderAttentionRequired = true
      lenderAttentionRequiredReason = reason
      showLarAlert = true
    }
  } else if (lender_attention_required) {
    lenderAttentionRequired = true
    lenderAttentionRequiredReason = lender_attention_required_reason
    showLarAlert = order.job_type !== JOB_TYPES.AMC || is_amc_lender
  }

  if (!order) {
    return <div></div>
  }

  const requiresAttentionReason = ALERT_TEXT[lenderAttentionRequiredReason]
  const alertMessage = `Requires Attention: ${requiresAttentionReason}`
  const is_requesting_appraiser = (is_amc_lender && order.job_type === JOB_TYPES.AMC) ||
    (!is_amc_lender && order.job_type !== JOB_TYPES.AMC)

  if (is_amc_lender && order.outstanding_offer && order.offers) {
    offerCount = order.offers.length
    const offerCreatedDate = order.offers.map(offer => offer.created).sort().pop()
    lastSubmittedOffer = offerCreatedDate ? utcToLocal(offerCreatedDate).format(format) : ''
  }

  let hasPendingClientLenderOffer = false
  // If the client lender is viewing an AMC order, look for any forwarded counter offers
  // (will have the user's lender_id on the offer object)
  if (order.statusKey === STATUS_KEY_FINDING_APPRAISERS &&
        !is_requesting_appraiser &&
        order.job_type === JOB_TYPES.AMC &&
        (order.offers || []).length > 0
  ) {
    const pendingClientLenderOffer = order.offers.find(offer => offer.lender_id === lenderId)
    if (pendingClientLenderOffer) {
      hasPendingClientLenderOffer = true
    }
  }

  return (
    <article className="order-alerts">
      {order && order.alerts && order.alerts.length > 0 &&
        <Fragment>
          <UncontrolledTooltip placement="top" target="alertsToolTip">
            {order && order.alerts && order.alerts.map((alert, index) =>
              <div key={`alert-${index}`} className='my-2'><Rephrase>{ALERT_TEXT[alert]}</Rephrase></div>
            )}
          </UncontrolledTooltip>
          <BellOutlined className='order-alert-badge text-danger' id='alertsToolTip' />
        </Fragment>
      }
      {lenderAttentionRequired && showLarAlert && (
        <Tooltip title={<Rephrase>{alertMessage}</Rephrase>}> <BellOutlined className='order-alert-badge text-danger' /> </Tooltip>
      )}
      {order.unread_messages > 0 &&
        <Tooltip title={`${order.unread_messages} unread message${order.unread_messages > 1 ? 's' : ''}`} >
          <MailOutlined className='order-alert-badge text-danger' />
        </Tooltip>
      }
      {order.unanswered_messages && order.unread_messages === 0 &&
        <Tooltip title={'Unanswered message'} >
          <MessageOutlined className='order-alert-badge text-danger' />
        </Tooltip>
      }
      {is_requesting_appraiser && order.appraiser_requested_information &&
        <Tooltip title={<Rephrase>Appraiser has requested additional information.</Rephrase>}>
          <SolutionOutlined className='order-alert-badge text-danger' />
        </Tooltip>
      }
      {is_requesting_appraiser && order.outstanding_offer && (
        is_amc_lender
          ? <Popover title="Pending Appraiser Offer." content={<Container>
            <div className='d-flex flex-row justify-content-start'>
              <div>Assigned Appraiser Count:</div>
              <div className='ml-1'>{order?.active_company_requests_count || 0}</div>
            </div>
            <div className='d-flex flex-row justify-content-start'>
              <div>Pending Offer Count:</div>
              <div className='ml-1'>{offerCount}</div>
            </div>
            <div className='d-flex flex-row justify-content-start'>
              <div>Last Submitted Offer:</div>
              <div className='ml-1'>{lastSubmittedOffer}</div>
            </div>
          </Container>}>
            <SolutionOutlined className='order-alert-badge text-danger' />
          </Popover>
          : <Tooltip title={<Rephrase>Pending Appraiser Offer.</Rephrase>}>
            <SolutionOutlined className='order-alert-badge text-danger' />
          </Tooltip>
      )}
      {hasPendingClientLenderOffer && (
        <Tooltip title={<Rephrase>Pending Appraiser Offer.</Rephrase>}>
          <SolutionOutlined className='order-alert-badge text-danger' />
        </Tooltip>
      )}
    </article>
  )
}

export default OrderAlerts
