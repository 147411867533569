import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { Tabs, Tooltip, Avatar } from 'antd'
import { Message } from 'react-chat-ui'
import OrderDetailsTab from './OrderDetailsTab'
import RevisionsTab from './RevisionsTab'
import SubmissionsTab from './SubmissionsTab'
import AppraiserRequestTab from './AppraiserRequestTab'
import PaymentsTab from './PaymentsTab'
import Evault from './Evault'
import MessagesTab from './MessagesTab'
import FloorplanTab from './FloorplanTab'
import PdrSubmissionsTab from './PdrSubmissionsTab'
import LoanDetailsTab from '../loans/LoanDetailsTab'
import OrderFeeHistoryTab from './OrderFeeHistoryTab/OrderFeeHistoryTab'
import ActivityLogTab from './ActivityLogTab/ActivityLogTab'
import TabLabel from '../../common/layout/components/TabLabel/TabLabel'
import { isPdrOrder } from '../../../helpers/pdr_orders'
import {
  checkPermission,
  checkCanViewOrderConversation,
  checkCanParticipateInOrderConversation,
  checkCanViewAppraiserNameOnOrder,
  utcToLocal,
  utcNow,
} from '../../common/utils/helpers'
import { useApiRequest } from '../../common/hooks'
import {
  setUnreadCount,
  approveMessage,
  updateMessage,
} from '../../store/reducers/conversation/conversationSlice'
import { USER_TYPES } from '../../common/constants/userTypes'
import { selectShowRevisions, selectShowSubmissions } from '../../store/reducers/order/orderSelectors'
import { selectLegacyConversation } from '@app/store/reducers/conversation/conversationSelectors'
import Rephrase from '../../common/utils/Rephrase'

const { TabPane } = Tabs

const getConversationTooltips = (conversation, orderData) => {
  const tooltips = []
  if (!conversation || !conversation.users) {
    return tooltips
  }
  let lender_users = []
  let amp_users = []
  const users = conversation.users.filter(u => u.type === USER_TYPES.APPRAISER)
  if (conversation.type === 'unified_conversation') {
    lender_users = conversation.users.filter(u => u.type === USER_TYPES.LENDER)
    amp_users = conversation.users.filter(u => u.type === USER_TYPES.AMC)
  } else {
    lender_users = conversation.users.filter(u => u.type === USER_TYPES.LENDER || u.type === USER_TYPES.AMC)
  }
  const custom_users = conversation.users.filter(u => u.type === USER_TYPES.CUSTOM)

  if (checkCanViewAppraiserNameOnOrder(orderData)) {
    users.forEach((user) => {
      tooltips.push(
        <Tooltip
          key={user.id}
          title={<span>{`${user.firstname || ''} ${user.lastname ||
            ''}`} <Rephrase>(Appraisal Firm)</Rephrase></span>}
        >
          <Avatar className="appraiser-chat-avatar">
            {user.firstname ? user.firstname[0] : ''}
            {user.lastname ? user.lastname[0] : ''}
          </Avatar>
        </Tooltip>,
      )
    })
  }

  custom_users.forEach((user) => {
    tooltips.push(
      <Tooltip
        key={user.id}
        title={`${user.firstname || ''} ${user.lastname || ''}`}
      >
        <Avatar className="appraiser-chat-avatar">
          {user.firstname ? user.firstname[0] : ''}
          {user.lastname ? user.lastname[0] : ''}
        </Avatar>
      </Tooltip>,
    )
  })

  lender_users.forEach((user) => {
    tooltips.push(
      <Tooltip
        key={user.id}
        title={`${user.firstname || ''} ${user.lastname || ''}`}
      >
        <Avatar>
          {user.firstname ? user.firstname[0] : ''}
          {user.lastname ? user.lastname[0] : ''}
        </Avatar>
      </Tooltip>,
    )
  })

  if (checkCanViewAppraiserNameOnOrder(orderData)) {
    amp_users.forEach((user) => {
      tooltips.push(
        <Tooltip
          key={user.id}
          title={`${user.firstname || ''} ${user.lastname || ''}`}
        >
          <Avatar className="appraiser-chat-avatar">
            {user.firstname ? user.firstname[0] : ''}
            {user.lastname ? user.lastname[0] : ''}
          </Avatar>
        </Tooltip>,
      )
    })
  }

  return tooltips
}

const prepConversationMessages = (
  conversation,
  currentUserID,
  currentUserType,
  isPastAppraiserConversation,
  shouldGetVendorNotes = false,
  orderData,
  handleApproveMessage = null,
  userCanApproveMessage = false,
) => {
  const sourceMessages = shouldGetVendorNotes ? [...conversation || []] : conversation.messages
  const messages = []
  const revisions = []
  const canViewApprovedBy = checkPermission('view_message_approver') && currentUserType === USER_TYPES.LENDER

  if (sourceMessages) {
    for (const msg of sourceMessages) {
      const user_is_current_user = msg.sender.id === currentUserID
      const senderName = user_is_current_user ? 'You' : msg.sender.firstname + ' ' + msg.sender.lastname
      const sender_type = msg.sender.type || msg.sender_type
      if (msg.is_revision) {
        revisions.push(
          <div className="revision">
            {checkCanViewAppraiserNameOnOrder(orderData) ? (
              <span>
                Revision from{' '}
                {msg.sender.firstname +
                  ' ' +
                  msg.sender.lastname +
                  ' - ' +
                  utcToLocal(msg.sent_time).fromNow()}
              </span>
            ) : (
              <span>Revision {utcToLocal(msg.sent_time).fromNow()}</span>
            )}
            <p>{msg.message}</p>
          </div>,
        )
      } else if (!msg.is_cancellation_request) {
        // Add this message to the regular chat messages array
        const lenderSender = sender_type.toUpperCase() === currentUserType
        messages.push(
          new Message({
            // This message id is purely to decide whether or not to move the message to the left of the right
            id: lenderSender ? 0 : msg.sender.id,
            senderName: (checkCanViewAppraiserNameOnOrder(orderData) || sender_type !== USER_TYPES.APPRAISER) ? `${senderName} - ${utcToLocal(msg.sent_time).format('MM/DD/YYYY h:mm a')} ` : utcToLocal(msg.sent_time).format('MM/DD/YYYY h:mm a'),
            message: msg.message,
          }),
        )

        const currentMessage = messages[messages.length - 1]

        currentMessage.timeago = utcToLocal(msg.sent_time).fromNow()
        currentMessage.showReadReceipts = msg.read_receipts && currentUserType === USER_TYPES.AMC
        currentMessage.readReceipts = msg.read_receipts
        currentMessage.created = msg.sent_time
        currentMessage.showUserSent = true
        currentMessage.sentByCurrentUser = user_is_current_user
        currentMessage.isTeamConversation = msg.is_team_conversation
        currentMessage.lender_sender =
          msg.sender && lenderSender ? msg.sender.id : null
        currentMessage.sender = msg.sender
        currentMessage.isPastAppraiserConversation =
          msg?.is_past_message ? msg.is_past_message : isPastAppraiserConversation
        currentMessage.visibleToInternal = msg.visible_to_internal
        currentMessage.visibleToVendor = msg.visible_to_vendor
        currentMessage.requiresApproval = msg.requires_approval
        currentMessage.approvedTimestamp = msg.approval_timestamp && ` - ${utcToLocal(
          msg.approval_timestamp,
        ).format('MM/DD/YYYY h:mm a')}`
        currentMessage.userCanApproveMessage = userCanApproveMessage
        currentMessage.conversationId = conversation?.id
        currentMessage.messageId = msg?.message_id
        currentMessage.handleApproveMessage = handleApproveMessage
        currentMessage.isInitialAmpAppraiserConversation = !!msg?.is_initial_amp_appraiser_conversation

        if (canViewApprovedBy && msg.approval_timestamp) {
          let approvedUserName = ''
          if (msg.approved_by?.firstname) {
            approvedUserName = msg.approved_by.firstname
          }
          if (msg.approved_by?.lastname) {
            if (approvedUserName) {
              approvedUserName = `${approvedUserName} `
            }
            approvedUserName = `${approvedUserName}${msg.approved_by.lastname}`
          }
          if (approvedUserName) {
            currentMessage.approvedBy = approvedUserName
            currentMessage.showUserApproved = true
          }
        }

        if (shouldGetVendorNotes && msg.created_date) {
          // Update currentMessage for current_vendor_message specific fields. Used to render current_vendor_message on the ChatFeed
          currentMessage.isVendorMessage = true
          currentMessage.timeago = utcToLocal(msg.created_date).fromNow()
          currentMessage.created = moment
            .utc(msg.created_date)
            .format('YYYY-MM-DD HH:mm:ss.SSSSSS')
          currentMessage.senderName = `${senderName} - ${utcToLocal(
            msg.created_date,
          ).format('MM/DD/YYYY h:mm a')}`
        }

        const currentMessageTime = moment(currentMessage.created)
        let hasPrecedingMessageWithSent = false

        messages.forEach((message, index) => {
          if (index === messages.length - 1) {
            // Dont do any comparison since this is the message itself
            return
          }

          // msg is the original version of the current message
          if (
            moment(currentMessageTime).diff(message.created, 'minutes') < 5 &&
            message.showUserSent &&
            currentMessage.sender.id === message.sender.id
          ) {
            // If the previous message is from the same person, is within a 5 minute time window, and
            // this message has a sent box above it, then we dont show it.
            hasPrecedingMessageWithSent = true
          }

          if (index === messages.length - 2) {
            // Looking at the message immediately above the message we are looking at.
            // If the previous message has a different sender, then
            if (currentMessage.sender.id !== message.sender.id) {
              // Dont do anything, because we definitely want the
              // This block will always be the last check because we are
              hasPrecedingMessageWithSent = false
            }
          }
        })
        currentMessage.showUserSent = !hasPrecedingMessageWithSent
      }
    }
  }
  const userBubbles = getConversationTooltips(conversation, orderData)

  return { messages: messages, revisions: revisions, userBubbles: userBubbles }
}

const SingleOrderTabs = (props) => {
  const {
    currentUserID,
    orderData,
    is_amc_lender,
    is_review_appraiser,
    refreshOrder,
    activeOrderTab,
    timeline,
    timelineStatus,
    order,
    primaryOrderId,
    should_show_consumer_payment_style_field,
    loanData,
    loanUpdate,
    using_amc_lender,
    isDragOver,
    dragLeave,
    conversation,
    review_conversation,
    use_up_to_amount,
    currentUser,
    address,
    team_conversation,
    viewRov,
    downloadRov,
    amc_conversation,
    conversationStatus,
    onHide,
    newMessage,
    onFocus,
    lenderData,
    actions,
    send_borrower_appraisal,
    auto_send_borrower_appraisal,
    auto_send_borrower_appraisal_delay,
    review_appraisal_hours_start,
    review_appraisal_hours_end,
    setActiveTab,
    activeTab,
    shouldShowFeeHistoryTab,
    shouldShowActivityHistoryTab,
  } = props

  const dispatch = useDispatch()
  const apiRequest = useApiRequest()

  const showRevisions = useSelector(selectShowRevisions)
  const showSubmissions = useSelector(selectShowSubmissions)
  const legacyConversation = useSelector(selectLegacyConversation)
  const isPDR = isPdrOrder(order)

  const markReadMessages = useCallback(async (conversationId, type) => {
    if (conversationId) {
      await apiRequest({
        endpoint: 'order/messages/read',
        method: 'PUT',
        body: { conversation_id: conversationId },
      })
      dispatch(
        setUnreadCount({
          type,
          unread_messages: 0,
        }),
      )
    }
  }, [dispatch, apiRequest])

  const handleApproveMessage = (messageRecord) => {
    let message = {
      requires_approval: false,
      approval_timestamp: utcNow(),
    }
    if (messageRecord.isApproved) {
      message = {
        ...message,
        visible_to_vendor: true,
        visible_to_internal: true,
      }
    }
    dispatch(updateMessage({
      conversationType: 'unified_conversation',
      messageId: messageRecord.messageId,
      message,
    }))
    dispatch(approveMessage(messageRecord))
  }
  let userCanApproveMessages = false
  try {
    // checkPermission can raise error if permission missing
    userCanApproveMessages = checkPermission('approve_conversation_messages')
  } catch {
    userCanApproveMessages = false
  }

  let messages = []
  let hasConversation = false
  let hasAMCConversation = false
  let hasTeamConversation = false
  let hasAmpUnifiedConversation = false
  let hasReviewConversation = false
  let teamConversationParticipation = false
  const canSeeSubmissionTab = showSubmissions
  let amc_messages = []
  let review_messages = []
  let team_messages = []
  let messagesBubbles
  let amcMessagesBubbles
  let reviewMessagesBubbles
  let teamMessagesBubbles
  let primaryConversationType
  let primaryConversationId
  let primaryMessages
  let pastAppraiserMessages
  let primaryMessagesBubbles
  let primaryMessagesParticipation
  let primaryUnreadMessages
  let conversationParticipation
  let amcConversationParticipation
  let reviewConversationParticipation
  let appraiserConversation
  let appraiserMessages
  let appraiserMessageBubbles

  const currentUserType = is_amc_lender ? USER_TYPES.AMC : USER_TYPES.LENDER
  if (orderData) {
    if (conversation) {
      appraiserConversation = conversation
      const isPastAppraiserConversation = false
      const m = prepConversationMessages(
        conversation,
        currentUserID,
        currentUserType,
        isPastAppraiserConversation,
        false,
        orderData,
        handleApproveMessage,
        userCanApproveMessages,
      )
      messages = m.messages
      messagesBubbles = m.userBubbles
      appraiserMessages = messages
      appraiserMessageBubbles = messagesBubbles
      // revisions = m['revisions']
      if (orderData.job_type === 'amc') {
        if (conversation.type === 'unified_conversation') {
          hasAmpUnifiedConversation = true
          // For AMP unified conversations, use the legacy convo for communication
          // between AMP and the appraiser
          if (is_amc_lender) {
            appraiserConversation = legacyConversation
            if (appraiserConversation) {
              const preppedAppraiserMessages = prepConversationMessages(
                legacyConversation,
                currentUserID,
                currentUserType,
                isPastAppraiserConversation,
                false,
                orderData,
                false,
                false,
              )
              appraiserMessages = preppedAppraiserMessages.messages
              appraiserMessageBubbles = preppedAppraiserMessages.userBubbles
            }
          }
          if (checkPermission('view_order_conversation')) {
            hasConversation = true
            if (checkPermission('participate_conversation')) {
              conversationParticipation = true
            }
          }
          conversationParticipation = true
        } else {
          if (checkPermission('view_amc_appraiser_conversation')) {
            hasConversation = true
            if (checkPermission('participate_amc_appraiser_conversation')) {
              conversationParticipation = true
            }
          }
        }
      } else {
        if (checkCanViewOrderConversation(orderData)) {
          hasConversation = true

          if (checkCanParticipateInOrderConversation(orderData)) {
            conversationParticipation = true
          }
        }
      }
    }

    if (amc_conversation) {
      const isPastAppraiserConversation = false
      const amc_m = prepConversationMessages(
        amc_conversation,
        currentUserID,
        currentUserType,
        isPastAppraiserConversation,
      )

      amc_messages = amc_m.messages
      amcMessagesBubbles = amc_m.userBubbles

      if (orderData.job_type === 'amc') {
        if (checkPermission('view_order_conversation')) {
          hasAMCConversation = true
          if (checkPermission('participate_conversation')) {
            amcConversationParticipation = true
          }
        }
      }
    }

    if (review_conversation) {
      const isPastAppraiserConversation = false
      const review_m = prepConversationMessages(
        review_conversation,
        currentUserID,
        currentUserType,
        isPastAppraiserConversation,
      )
      review_messages = review_m.messages
      reviewMessagesBubbles = review_m.userBubbles

      if (checkPermission('view_review_conversation')) {
        hasReviewConversation = true

        if (checkPermission('participate_review_conversation')) {
          reviewConversationParticipation = true
        }
      }
    }

    if (team_conversation) {
      const isPastAppraiserConversation = false
      const team_m = prepConversationMessages(
        team_conversation,
        currentUserID,
        currentUserType,
        isPastAppraiserConversation,
      )

      team_messages = team_m.messages
      teamMessagesBubbles = team_m.userBubbles

      if (checkPermission('view_team_conversation')) {
        hasTeamConversation = true
      }

      if (checkPermission('participate_team_conversation')) {
        teamConversationParticipation = true
      }
    }

    if (orderData.past_appraiser_conversations) {
      pastAppraiserMessages = []

      orderData.past_appraiser_conversations.forEach((conversation) => {
        const isPastAppraiserConversation = true
        const past_m = prepConversationMessages(
          conversation,
          currentUserID,
          currentUserType,
          isPastAppraiserConversation,
        )

        pastAppraiserMessages = [...pastAppraiserMessages, ...past_m.messages]
      })

      if (checkCanViewOrderConversation(orderData)) {
        hasConversation = true
      }
    }

    // Use this variable to change out the orderdata timeline view depending on the order type.
    if (orderData.job_type === 'amc' && hasAMCConversation && !hasAmpUnifiedConversation) {
      primaryConversationType = 'amc_conversation'
      primaryConversationId = amc_conversation?.id
      primaryMessages = amc_messages
      primaryMessagesBubbles = amcMessagesBubbles
      primaryMessagesParticipation = amcConversationParticipation
      primaryUnreadMessages = amc_conversation?.unread_messages || 0
    } else if (hasConversation) {
      primaryConversationType = conversation?.type || 'conversation'
      primaryConversationId = conversation?.id
      primaryMessages = messages
      primaryMessagesBubbles = messagesBubbles
      primaryMessagesParticipation = conversationParticipation
      primaryUnreadMessages = conversation?.unread_messages || 0
    }
  }

  useEffect(() => {
    if (activeTab === '1' && primaryConversationId && primaryUnreadMessages) {
      markReadMessages(primaryConversationId, primaryConversationType)
    }
  }, [activeTab, primaryConversationId, primaryConversationType, primaryUnreadMessages, markReadMessages])

  let lastSubmission = null
  if (orderData && orderData.submissions && orderData.submissions.length > 0) {
    orderData.submissions.forEach((submission) => {
      lastSubmission = submission
    })
  }

  const onTabChange = async (key) => {
    setActiveTab(key)

    switch (key) {
      case '3':
        markReadMessages(conversation.id, 'conversation')
        break
      case '9':
        markReadMessages(review_conversation.id, 'review_conversation')
        break
      case '12':
        markReadMessages(team_conversation.id, 'team_conversation')
        break
      default:
        break
    }
  }

  const shouldShowEvaultTab = () => {
    if (orderData) {
      if (is_amc_lender || orderData.job_type !== 'amc') {
        if (checkPermission('p_and_s_view') || (checkPermission('evault_view') && orderData.evault)) {
          return true
        }
      }
    }

    return false
  }

  const shouldShowAmcEvaultTab = () => {
    if (orderData && orderData.amc_evault) {
      if (checkPermission('evault_view') || checkPermission('p_and_s_view')) {
        return true
      }
    }

    return false
  }

  const nonAmcOrder = orderData && orderData.job_type !== 'amc'
  const notWFP = orderData && orderData.statusKey !== 'waiting_for_payment'
  const broadcastOrder = orderData && orderData.order_request_method === 'broadcast'
  const individuallyOrder = orderData && orderData.order_request_method === 'individually'
  const cascadeOrder = orderData && orderData.order_request_method === 'cascade'
  const allowCounterOffer = orderData && orderData.allow_counter_offer && orderData.statusKey === 'finding_appraisers'
  const orderHasPendingClientLenderOffer = orderData && orderData.assignment_list &&
    orderData.assignment_list.some((assignment) =>
      assignment.offers && assignment.offers.some((offer) => offer.is_pending_client_lender))

  const showAppraisalRequests = is_amc_lender || orderHasPendingClientLenderOffer ||
    (nonAmcOrder &&
    notWFP &&
    (broadcastOrder || ((individuallyOrder || cascadeOrder) && allowCounterOffer)))

  const ruccCode = is_amc_lender && 'rucc_code' in loanData ? loanData.rucc_code : 0

  return (
    <Tabs defaultActiveKey={activeTab} type="card" onChange={onTabChange}>
      {orderData && (
        <TabPane
          tab={<TabLabel label="Order Details" iconType="profile" />}
          key="1"
        >
          <OrderDetailsTab
            currentUserId={currentUserID}
            orderData={orderData}
            prepConversationMessages={prepConversationMessages}
            is_amc_lender={is_amc_lender}
            is_review_appraiser={is_review_appraiser}
            refreshOrder={refreshOrder}
            activeOrderTab={activeOrderTab}
            markReadMessages={markReadMessages}
            messages={messages}
            timelineData={timeline}
            timelineLoading={timelineStatus === 'loading'}
            order={order}
            currentUserType={currentUserType}
            primaryOrderId={primaryOrderId}
            primaryConversationType={primaryConversationType}
            primaryMessages={primaryMessages}
            pastAppraiserMessages={pastAppraiserMessages}
            primaryMessagesBubbles={primaryMessagesBubbles}
            allowMessageParticipation={primaryMessagesParticipation}
            should_show_consumer_payment_style_field={
              should_show_consumer_payment_style_field
            }
            branchData={loanData.branch}
            conversationStatus={conversationStatus}
            onHide={onHide}
            amc_conversation={amc_conversation}
            conversation={conversation}
            onFocus={onFocus}
            newMessage={newMessage}
            ruccCode={ruccCode}
          />
        </TabPane>
      )}
      <TabPane
        tab={<TabLabel label="Loan Details" iconType="folder" />}
        key="6"
      >
        <LoanDetailsTab
          loanData={loanData}
          is_amc_lender={is_amc_lender}
          hasOrder={!!orderData}
          loanUpdate={loanUpdate}
          refreshOrder={refreshOrder}
          order_due_date={orderData && (orderData.lender_due_date || orderData.due_date)}
          should_show_consumer_payment_style_field={
            should_show_consumer_payment_style_field
          }
          consumer_payment_style={
            orderData ? orderData.consumer_payment_style : null
          }
        />
      </TabPane>
      {showAppraisalRequests && (
        <TabPane
          tab={
            <TabLabel
              label={<Rephrase sayOrder>Appraisal Requests</Rephrase>}
              iconType="solution"
              badgeCount={
                !orderData.accepted ? orderData.outstanding_offer_count : 0
              }
            />
          }
          key="13"
        >
          <AppraiserRequestTab
            orderData={orderData}
            using_amc_lender={using_amc_lender}
            prepConversationMessages={prepConversationMessages}
            currentUserID={currentUserID}
            markReadMessages={markReadMessages}
            is_amc_lender={is_amc_lender}
            lenderData={lenderData}
            order={order}
            refreshOrder={refreshOrder}
          />
        </TabPane>
      )}
      {shouldShowEvaultTab() && (
        <TabPane
          tab={
            <TabLabel
              label={<Rephrase>{`eVault Documents ${is_amc_lender ? `${isPDR ? '(Vendor)' : '(Appraiser)'}` : ''}`}</Rephrase>}
              iconType="safety"
            />
          }
          key="2"
        >
          <Evault
            isDragOver={isDragOver}
            dragLeave={dragLeave}
            orderData={orderData}
            type="evault"
            primaryOrderId={primaryOrderId}
            subOrderId={activeOrderTab !== primaryOrderId && activeOrderTab}
            is_review_appraiser={is_review_appraiser}
            is_amc_lender={is_amc_lender}
          />
        </TabPane>
      )}
      {orderData && checkPermission('team_documents_view') && (
        <TabPane
          tab={<TabLabel label="Team Documents" iconType="safety" />}
          key="14"
        >
          <Evault
            isTeamDocs={true}
            isDragOver={isDragOver}
            dragLeave={dragLeave}
            orderData={orderData}
            type="team_documents"
            primaryOrderId={primaryOrderId}
            subOrderId={activeOrderTab !== primaryOrderId && activeOrderTab}
            is_review_appraiser={is_review_appraiser}
            is_amc_lender={is_amc_lender}
          />
        </TabPane>
      )}
      {shouldShowAmcEvaultTab() && (
        <TabPane
          tab={
            <TabLabel
              label={`eVault Documents ${is_amc_lender ? '(Lender)' : ''}`}
              iconType="safety"
            />
          }
          key="10"
        >
          <Evault
            isDragOver={isDragOver}
            dragLeave={dragLeave}
            orderData={orderData}
            type="amc_evault"
            primaryOrderId={primaryOrderId}
            subOrderId={activeOrderTab !== primaryOrderId && activeOrderTab}
            is_review_appraiser={is_review_appraiser}
            is_amc_lender={is_amc_lender}
          />
        </TabPane>
      )}
      {appraiserConversation && is_amc_lender && (
        <TabPane
          tab={
            <TabLabel
              label={<Rephrase>Appraiser Messages</Rephrase>}
              iconType="message"
              badgeCount={
                appraiserConversation ? appraiserConversation.unread_messages : 0
              }
            />
          }
          key="3"
        >
          <MessagesTab
            messagesBubbles={appraiserMessageBubbles}
            messages={appraiserMessages}
            conversationParticipation={conversationParticipation}
            conversation={appraiserConversation}
            markReadMessages={markReadMessages}
            is_amc_lender={is_amc_lender}
            subOrderId={activeOrderTab !== primaryOrderId && activeOrderTab}
            conversationType="conversation"
            isAppraiserMessages
            newMessageTitle="Conversation"
            order={order}
            orderData={orderData}
            refreshOrder={refreshOrder}
            onHide={onHide}
          />
        </TabPane>
      )}
      {hasReviewConversation && is_amc_lender && (
        <TabPane
          tab={
            <TabLabel
              label="Review Conversation"
              iconType="message"
              badgeCount={
                review_conversation ? review_conversation.unread_messages : 0
              }
            />
          }
          key="9"
        >
          <MessagesTab
            messagesBubbles={reviewMessagesBubbles}
            messages={review_messages}
            conversationParticipation={reviewConversationParticipation}
            conversation={review_conversation}
            markReadMessages={markReadMessages}
            conversationType="review_conversation"
            newMessageTitle="Review Conversation"
            is_amc_lender={is_amc_lender}
            order={order}
            orderData={orderData}
          />
        </TabPane>
      )}
      {orderData &&
        checkPermission('submission_view') &&
        canSeeSubmissionTab && (
        <TabPane
          tab={<TabLabel label="Submissions" iconType="inbox" />}
          key="4"
        >
          <SubmissionsTab
            orderData={orderData}
            loanData={loanData}
            is_amc_lender={is_amc_lender}
            lenderData={lenderData}
            actions={actions}
            send_borrower_appraisal={send_borrower_appraisal}
            auto_send_borrower_appraisal={auto_send_borrower_appraisal}
            auto_send_borrower_appraisal_delay={auto_send_borrower_appraisal_delay}
            review_appraisal_hours_start={review_appraisal_hours_start}
            review_appraisal_hours_end={review_appraisal_hours_end}
            order={order}
            currentUserId={currentUserID}
            refreshOrder={refreshOrder}
            is_review_appraiser={is_review_appraiser}
            onHide={onHide}
            canSeeSubmissionTab={canSeeSubmissionTab}
          />
        </TabPane>
      )}
      {showRevisions && (
        <TabPane
          tab={<TabLabel label="Revisions" iconType="schedule" />}
          key="5"
        >
          <RevisionsTab
            orderData={orderData}
            is_amc_lender={is_amc_lender}
            refreshOrder={refreshOrder}
            viewRov={viewRov}
            downloadRov={downloadRov}
            lastSubmission={lastSubmission}
            useOldROV={currentUser?.lender?.use_old_rov}
          />
        </TabPane>
      )}
      {orderData &&
        orderData.job_type !== 'review' &&
        checkPermission('payments_view') && (
        <TabPane
          tab={<TabLabel label="Payment" iconType="schedule" />}
          key="11"
        >
          <PaymentsTab
            orderData={orderData}
            order={order}
            currentUserID={currentUserID}
            loanData={loanData}
            refreshOrder={refreshOrder}
            use_up_to_amount={use_up_to_amount}
            currentUser={currentUser}
            address={address}
            is_amc_lender={is_amc_lender}
            lenderData={lenderData}
          />
        </TabPane>
      )}
      {hasTeamConversation && primaryConversationType !== 'unified_conversation' && (
        <TabPane
          tab={
            <TabLabel
              label="Team Conversation"
              iconType="message"
              badgeCount={
                team_conversation ? team_conversation.unread_messages : 0
              }
            />
          }
          key="12"
        >
          <MessagesTab
            messagesBubbles={teamMessagesBubbles}
            messages={team_messages}
            conversationParticipation={teamConversationParticipation}
            conversation={team_conversation}
            conversationType="team_conversation"
            markReadMessages={markReadMessages}
            subOrderId={activeOrderTab !== primaryOrderId && activeOrderTab}
            newMessageTitle="Team Conversation"
            is_amc_lender={is_amc_lender}
            order={order}
            orderData={orderData}
          />
        </TabPane>
      )}
      {isPDR && is_amc_lender && (<TabPane
        tab={
          <TabLabel
            label="Floorplan"
            iconType="layout"
          />
        }
        key="15"
      >
        <FloorplanTab order={order} />
      </TabPane>)}
      {isPDR && is_amc_lender && (<TabPane
        tab={
          <TabLabel
            label="PDR Submissions"
            iconType="inbox"
          />
        }
        key="16"
      >
        <PdrSubmissionsTab order={order} />
      </TabPane>)}
      {shouldShowFeeHistoryTab && (<TabPane
        tab={
          <TabLabel
            label="Fee History"
            iconType="dollar"
          />
        }
        key="17"
      >
        <OrderFeeHistoryTab orderFeeChanges={order.order_fee_changes} />
      </TabPane>)}
      {shouldShowActivityHistoryTab && (<TabPane
        tab={
          <TabLabel
            label="Activity Log"
            iconType="history"
          />
        }
        key="18"
      >
        <ActivityLogTab orderId={order.id} />
      </TabPane>)}
    </Tabs>
  )
}

export default SingleOrderTabs
