// always import React first
import React from 'react'

// libraries
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'


const UserTokenErrorModal = () => {
  /**
   * Simple UI component that prevents all interaction with site when user token changes unexpectedly.
   *
   * Diplays a modal to the user indicating the issue and recommending a refresh/login.
   */
  return (
    <Modal
      isOpen
    >
      <ModalHeader>
        User Token No Longer Valid
      </ModalHeader>
      <ModalBody>
        Your user token has changed. It may have expired, or you may have logged out in another tab.

        Please reload the page to log back in and continue using Reggora.
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => window.location.reload(true)}>Reload</Button>
      </ModalFooter>
    </Modal>
  )
}

export default UserTokenErrorModal
