import { loadableWithRetry } from '../../common/utils/lazyLoad'

const routes = [
  {
    path: '/',
    exact: true,
    name: 'active',
    publicRoute: true,
    redirectTo: '/auth/login',
  },
  {
    path: '/:id',
    name: 'singleActivate',
    publicRoute: true,
    component: loadableWithRetry(() => import('./ActivateContainer')),
  },
]

export default routes
