import React from 'react'

import { locationListToDict } from '../../../helpers'
import {
  stateFIPS, // {<state fips>: <state name>}
  countyFIPS, // {<state fips>: {<county fips>: <county name>}}
} from '../../../constants/locations'

import './style.scss'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Tree, Button } from 'antd'

const TreeNode = Tree.TreeNode

const CoverageDisplay = (props) => {
  const { coverage, onRemove, readOnly } = props
  const coverageAsDict = locationListToDict(coverage)
  if (!coverageAsDict) {
    return null
  }

  return (
    <Tree showLine showIcon={false}>
      {Object.keys(coverageAsDict).map((state) => {
        // State Level
        const counties = coverageAsDict[state]
        const stateReadable = stateFIPS[state]
        return (
          <TreeNode
            title={
              <span>
                {stateReadable}
                <small> - {Object.keys(counties).length} Counties</small>
              </span>
            }
            key={state}
          >
            {Object.keys(counties).map((county) => {
              // county level
              const zips = counties[county]
              const countyReadable = countyFIPS[state][county]
              return (
                <TreeNode
                  showIcon={false}
                  title={
                    <span>
                      {countyReadable}
                      <small> - {zips.length} ZIP Codes</small>
                    </span>
                  }
                  icon={null}
                  key={state + county}
                >
                  {zips.map((zip) => {
                    let zipTitle = zip
                    let className = ''
                    if (onRemove && !readOnly) {
                      zipTitle = (
                        <span className="removable-zip-tree-node-title">
                          <span>{zip}</span>
                          <Button
                            size="small"
                            icon={<CloseCircleOutlined />}
                            onClick={() => onRemove(zip, county, state, 'zip')}
                          />
                        </span>
                      )
                      className = 'removable-zip-tree-node'
                    }
                    return (
                      <TreeNode
                        className={className}
                        showIcon={false}
                        title={zipTitle}
                        icon={null}
                        key={state + county + zip}
                      ></TreeNode>
                    )
                  })}
                </TreeNode>
              );
            })}
          </TreeNode>
        );
      })}
    </Tree>
  );
}

export default CoverageDisplay
