import { UP } from './constants'

import alphabeticalCompare from './alphabeticalCompare'

export default function getSortedList(objectList, fieldToSort, directionToSort, defaultValue = null) {
  /**
    Returns a list that is a sorted version of objectList. Uses fieldToSort and directionToSort to determine the new list order.

    If objectList is missing or empty, returns empty list
    If fieldToSort or directionToSort are missing, returns objectList as is
    Otherwise returns a new list
    */
  if (!objectList || objectList.length === 0) {
    // nothing to sort
    return []
  }
  if (!fieldToSort || !directionToSort) {
    // without fieldToSort and directionToSort return unsorted list as is
    return objectList
  }
  const newList = objectList.slice()

  const reverseSort = directionToSort === UP

  newList.sort((objectA, objectB) => {
    if (objectA && objectA) {
      const valueA = objectA[fieldToSort] || defaultValue
      const valueB = objectB[fieldToSort] || defaultValue
      return alphabeticalCompare(valueA, valueB, reverseSort)
    }
  })

  return newList
}
