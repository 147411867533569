import { createSelector } from '@reduxjs/toolkit'
import { selectLoanData } from '../loan/loanSelectors'
import {
  selectIsAmcLender,
  selectHasPermission,
} from '../user/userSelectors'

export const selectMinimumOrderStatus = state => state.order.minimumStatus

export const selectRemainingOrderStatus = state => state.order.remainingStatus

export const selectOrderTabs = state => state.order.orderTabs

export const selectMinimumOrder = state => state.order.minimumOrder

export const selectRemainingOrder = state => state.order.remainingOrder

export const selectOrderTimeline = state => state.order.orderTimeline

export const selectTimelineStatus = state => state.order.timelineStatus

export const selectOrderSchedule = state => state.order.minimumOrder.schedule

export const selectPaymentAllowed = state => state.order.paymentAllowed

export const selectPaymentAllowedStatus = state => state.order.paymentAllowedStatus

export const selectLoanId = state => {
  const minimumOrder = selectMinimumOrder(state)
  return minimumOrder?.loan_file?.id ?? null
}

export const selectSubmissions = state => selectRemainingOrder(state).submissions || []

export const selectIsSubmissionDataIncomplete = state => {
  const submissions = selectSubmissions(state)
  return submissions.length === 0 || submissions.some(submission => submission.isIncomplete)
}

export const selectActiveOrderTab = state => state.order.activeOrderTab

export const selectPrimaryOrderId = state => state.order.primaryOrderId

export const selectIsPrimaryOrder = state => selectActiveOrderTab(state) === selectPrimaryOrderId(state)

export const selectAllOrderData = createSelector(
  selectMinimumOrder,
  selectRemainingOrder,
  (minimumOrder, remainingOrder) => {
    const minimumOrderData = minimumOrder?.order || {}
    return { ...minimumOrderData, ...remainingOrder }
  }
)

export const selectLatestLenderAttention = (state, lenderId, reasons) => {
  let lenderAttentions = selectAllOrderData(state).lender_attentions || []
  if (lenderId) {
    lenderAttentions = lenderAttentions.filter(lenderAttention => lenderAttention.lender_id === lenderId)
  }
  if (reasons && reasons.length > 0) {
    lenderAttentions = lenderAttentions.filter(lenderAttention => reasons.includes(lenderAttention.reason))
  }
  return lenderAttentions[0] || {}
}

export const selectOrderReviewEngineConfig = state => {
  return selectAllOrderData(state)?.review_engine || {}
}

export const selectOrderSubmissionReviewRulesetForUser = state => {
  const isAmcLender = selectIsAmcLender(state)
  const reviewEngineConfig = selectOrderReviewEngineConfig(state)
  if (!reviewEngineConfig) {
    return null
  }
  const {
    rule_sets, // new
    rule_set_keys, // old
  } = reviewEngineConfig
  if (rule_sets) {
    const targetRuleSetType = isAmcLender ? 'amp' : 'lender'
    const ruleSet = rule_sets?.find(ruleSet => ruleSet?.rule_set_type === targetRuleSetType)
    if (ruleSet) {
      return ruleSet.rule_set_key
    }
  }
  if (rule_set_keys) {
    return rule_set_keys?.[0]
  }
}

export const selectOffer = (state, assignmentListRequestId, offerId) => {
  const orderData = selectAllOrderData(state)
  const { assignment_list } = orderData
  if (!assignment_list) {
    return null
  }
  const assignmentListRequest = assignment_list.find(request => request.id === assignmentListRequestId)
  if (!assignmentListRequest) {
    return null
  }
  const { offers } = assignmentListRequest
  if (!offers) {
    return null
  }
  return offers.find(offer => offer.id === offerId)
}

export const selectAssignmentListRequest = state => {
  return selectAllOrderData(state)?.assignment_list_request || {}
}

export const selectPendingOrProcessingOffer = state => {
  const orderData = selectAllOrderData(state)
  const { assignment_list_request } = orderData
  if (!assignment_list_request) {
    return null
  }

  const offers = assignment_list_request.offers
  if (!offers) {
    return null
  }

  // look for outstanding offers. pending offers take precedent over processing offers
  let offer = null
  let processing_offer = null
  for (let i = 0; i < offers.length; i++) {
    if (offers[i].status === 'pending') {
      offer = offers[i]
      break
    } else if (offers[i].status === 'processing') {
      processing_offer = offers[i]
    }
  }

  if (!offer) {
    if (processing_offer) {
      offer = processing_offer
    } else {
      return null
    }
  }

  return offer
}

export const selectProcessingOffer = state => {
  const orderData = selectAllOrderData(state)
  const { assignment_list_request } = orderData
  if (!assignment_list_request) {
    return null
  }

  const offers = assignment_list_request.offers
  if (!offers) {
    return null
  }

  // look for outstanding offers. pending offers take precedent over processing offers
  let offer = null
  for (let i = 0; i < offers.length; i++) {
    if (offers[i].status === 'processing') {
      offer = offers[i]
      break
    }
  }

  return offer
}

export const selectLoanOrderData = state => selectMinimumOrder(state)?.loan_file || selectLoanData(state) || {}

export const selectPropertyAddress = state => {
  const orderData = selectAllOrderData(state)
  const loanData = selectLoanOrderData(state)

  let address = ''
  if (orderData && orderData.subject_property) {
    const property = orderData.subject_property
    address = `${property.property_address_full}`
    if (property.property_address_city) {
      address += `, ${property.property_address_city}`
    }
    if (property.property_address_state) {
      address += `, ${property.property_address_state}`
    }
    if (property.property_address_zip) {
      address += ` ${property.property_address_zip}`
    }
  } else if (orderData && orderData.manual_subject_property) {
    const property = orderData.manual_subject_property
    address = `${property.property_address_full}`
    if (property.property_address_city) {
      address += `, ${property.property_address_city}`
    }
    if (property.property_address_state) {
      address += `, ${property.property_address_state}`
    }
    if (property.property_address_zip) {
      address += ` ${property.property_address_zip}`
    }
  } else {
    address = `${loanData.subject_property_address}`
    if (loanData.subject_property_city) {
      address += `, ${loanData.subject_property_city}`
    }
    if (loanData.subject_property_state) {
      address += `, ${loanData.subject_property_state}`
    }
    if (loanData.subject_property_zip) {
      address += ` ${loanData.subject_property_zip}`
    }
  }

  return address
}

export const selectCurrentVeteranId = state => {
  const loanData = selectLoanOrderData(state)
  const veteranConsumer = loanData?.consumers?.find(consumer => consumer.is_veteran)
  return veteranConsumer?.id || null
}

export const selectCurrentPrimaryObligorId = state => {
  const loanData = selectLoanOrderData(state)
  const primaryObligorConsumer = loanData?.consumers?.find(consumer => consumer.is_primary_obligor)
  return primaryObligorConsumer?.id || null
}

export const selectIsUsingAmcLender = state => {
  const orderData = selectAllOrderData(state)
  const isAmcLender = selectIsAmcLender(state)

  return !isAmcLender && orderData?.job_type === 'amc'
}

export const selectShowRevisions = (state) => {
  const orderData = selectAllOrderData(state)
  const hasRevisionPermission = selectHasPermission(state, 'revisions_view')
  const { submissions } = orderData
  const hasSubmissions = submissions && submissions.length > 0

  return hasSubmissions && hasRevisionPermission
}


export const selectShowSubmissions = (state) => {
  // get relevant data
  const orderData = selectAllOrderData(state)
  const hasSubmissionPermission = selectHasPermission(state, 'submission_view')
  const { submissions } = orderData
  const hasSubmissions = submissions && submissions.length > 0

  return hasSubmissions && hasSubmissionPermission
}

export const selectOrderAssignedStatus = (state) => state.order.orderAssignedStatus
export const selectOrderAssignedError = (state) => state.order.orderAssignedError
export const selectOrderAssignedErrorStatus = (state) => state.order.orderAssignedErrorStatus

export const selectFollowUpAllocationData = (state) => state.order.followUpAllocationData

export const selectOrderAuditLogData = (state) => state.order.orderAuditLogData
