export const selectActiveConversationOrderId = state => state.conversation.orderId

export const selectAllConversations = state => state.conversation.conversations

export const selectConversationByType = (state, type) => {
  return state.conversation[type]
}
export const selectConversation = (state) => {
  // prioritize Unified Conversation, otherwise return legacy Conversation
  return state.conversation.unified_conversation || state.conversation.conversation
}

// Select the legacy appraiser conversation, use for AMP orders when we need unified + legacy
export const selectLegacyConversation = state => state.conversation.conversation

export const selectAMCConversation = state => state.conversation.amc_conversation

export const selectTeamConversation = state => state.conversation.team_conversation

export const selectReviewConversation = state => state.conversation.review_conversation

export const selectConversationStatus = state => state.conversation.status

export const selectConversationError = state => state.conversation.error
