import OrdersContainer from './OrdersContainer'
import SingleOrder from './SingleOrder'

// const routes = [
//   {
//     path: '/',
//     component: loadable(() =>import('./OrdersContainer'), {
//       fallback: <Loader />
//     }),
//     name: 'orders',
//     exact: true
//   },
//   {
//     path: '/:id/review/:version',
//     name: 'orderReviewViewer',
//     component: loadable(() =>import('./ReviewContainer'), {
//       fallback: <Loader />
//     })
//   },
//   {
//     path: '/:id',
//     name: 'singleOrder',
//     component: loadable(() =>import('./SingleOrder'), {
//       fallback: <Loader />
//     })
//   }
// ]

const routes = [
  {
    path: '/',
    component: OrdersContainer,
    name: 'orders',
    exact: true,
  },
  {
    path: '/:id',
    name: 'singleOrder',
    component: SingleOrder,
  },
]

export default routes
