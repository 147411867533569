import React, { useState, useMemo } from 'react'
import { Collapse, Button, Divider } from 'antd'
import { FileSearchOutlined } from '@ant-design/icons'
import { SpecialZoomLevel, Viewer } from '@react-pdf-viewer/core'
import RuleComment from '../ResultTable/CommentModal/RuleComment'
import ResultTableActions from '../ResultTable/ResultTableActions'
import AddComment from '../ResultTable/AddComment'
import { useDispatch } from 'react-redux'
import { postRuleComment } from '../../../../../store/reducers/submissionReviews/submissionReviewsSlice'
import ReviewDatapoints from '../ReviewDatapoints'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'

import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import SearchSidebar from './SearchSidebar'

export default function ResultView(props) {
  const {
    data,
    fileUrl,
    httpHeaders,
    RuleBadge,
  } = props

  const { Panel } = Collapse
  const [innerActive, setInnerActive] = useState([])
  const [outerActive, setOuterActive] = useState([])
  const [searchKeywords, setSearchKeywords] = useState('')
  const [documentLoadedTimeout, setDocumentLoadedTimeout] = useState(null)

  const flags = useFlags()


  const renderToolbar = (Toolbar) => {
    return (
      <Toolbar>
        {(slots) => {
          const {
            CurrentPageInput,
            GoToNextPage,
            GoToPreviousPage,
            NumberOfPages,
            Zoom,
            ZoomIn,
            ZoomOut,
          } = slots
          return (
            <div className='d-flex align-items-center justify-content-center w-100'>
              <div>
                <Divider type="vertical" />
              </div>

              <div style={{ maxWidth: '3rem' }}>
                <CurrentPageInput />
              </div>
              <div className='pl-1 pr-2'>
                <NumberOfPages>{(props) => (
                  <span>&nbsp; / {props.numberOfPages}</span>
                )}
                </NumberOfPages>
              </div>
              <div className='pl-2 pr-1'>
                <GoToPreviousPage />
              </div>
              <div className='pr-2 pl-1'>
                <GoToNextPage />
              </div>

              <div>
                <Divider type="vertical" />
              </div>

              <div className='pl-2 pr-1'>
                <ZoomOut />
              </div>
              <div className='pr-2 pl-1'>
                <ZoomIn />
              </div>
              <div className='px-2'>
                <Zoom />
              </div>
            </div>
          )
        }
        }
      </Toolbar>
    )
  }

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar: renderToolbar,
    sidebarTabs: (defaultTabs) =>
      [
        {
          content: (
            <SearchSidebar
              searchPluginInstance={
                defaultLayoutPluginInstance.toolbarPluginInstance.searchPluginInstance
              }
              searchKeywords={searchKeywords}
            />
          ),
          icon: <FileSearchOutlined />,
          title: 'Search',
        },
      ].concat(defaultTabs),
  })

  const search = (keyword) => {
    setSearchKeywords(keyword)
    defaultLayoutPluginInstance.activateTab(0)
  }

  const showReviewAnnotations = flags?.reggoraReviewAnnotations

  const handleInnerActive = (identifier) => {
    if (innerActive.includes(identifier)) {
      setInnerActive(innerActive.filter(n => n !== identifier))
    } else {
      setInnerActive([...innerActive, identifier])
    }
  }

  const handleOuterActive = (identifier) => {
    if (outerActive.includes(identifier)) {
      setOuterActive(outerActive.filter(n => n !== identifier))
    } else {
      setOuterActive([...outerActive, identifier])
    }
  }

  const filteredDataIntoBucketsByStatus = useMemo(() => {
    const filtered = {}
    let sortedData = {}
    const buckets = {
      Unknown: 'Unknown',
      Accepted: 'Accepted',
      Passed: 'Passed',
      Rejected: 'Rejected',
      Failed: 'Failed',
      Error: 'Error',
      NA: 'N/A',
    }
    for (const key in buckets) {
      const bucketData = data.filter((item) => {
        return item.fieldData.display_outcome === buckets[key]
      })
      filtered[key] = bucketData
    }
    const order = ['Error', 'Failed', 'Rejected', 'Unknown', 'Passed', 'Accepted', 'NA']
    sortedData = Object.fromEntries(
      order.map((key) => [key, filtered[key]])
    )
    return sortedData
  }, [data])

  const RuleCardHeader = (fieldData) => {
    const { fieldName } = fieldData
    return (
      <div className="flex">
        {RuleBadge(fieldData)}
        <span className="rule-card-header-label">
          {fieldName}
        </span>
      </div>
    )
  }

  const CommentThread = (fieldData, ruleIndex) => {
    const { fieldName, id, notes, currentUser, submissionReviewId, isEditable } = fieldData
    const [comment, setComment] = useState('')
    const handleCommentInputChange = (e) => {
      setComment(e.target.value)
    }
    const dispatch = useDispatch()

    const handleCancel = () => {
      setComment('')
    }

    const handleOk = () => {
      dispatch(postRuleComment({
        note: comment,
        ruleId: id,
        user_type: 'LENDER',
        user_id: currentUser.id,
        submissionReviewId: submissionReviewId,
      }))
    }
    return (
      <>
        <RuleComment
          fieldName={fieldName}
          commentData={notes}
          currentUser={currentUser}
          hideFieldName={true}
        />
        {isEditable &&
            <div>
              <AddComment
                comment={comment}
                onChange={handleCommentInputChange}
                minRows={1}
                maxRows={5}
              />
              {comment.length > 0 &&
                <div className='pdf-view-rule-buttons'>
                  <Button key="back" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button
                    key="submit"
                    type="primary"
                    onClick={handleOk}
                  >
                    Add
                  </Button>
                </div>
              }
            </div>
        }
      </>
    )
  }

  const trySearch = (e) => {
    search(e)
  }

  const DisplayRuleCard = (props) => {
    const { fieldData, ruleIndex, innerActive } = props
    return (
      <div className="rule-card">
        <Collapse
          defaultActiveKey={innerActive}
          onChange={() => { handleInnerActive(ruleIndex) }}
        >
          <Panel header={RuleCardHeader(fieldData)} key={ruleIndex}>
            <div className="rule-card-desc">{fieldData.desc}</div>
            <div className="rule-card-desc">
              {showReviewAnnotations ? (
                <ReviewDatapoints
                  assertions={fieldData.assertions}
                  data_points={fieldData.data_points}
                  engine_outcome={fieldData.engine_outcome}
                  onClick={(x) => trySearch(x)}
                />
              ) : (
                <ReviewDatapoints
                  assertions={fieldData.assertions}
                  data_points={fieldData.data_points}
                  engine_outcome={fieldData.engine_outcome}
                />
              )}
            </div>
            <div>{CommentThread(fieldData, ruleIndex)}</div>
            <ResultTableActions
              fieldData={fieldData}
              isPdfView={true}
            />
          </Panel>
        </Collapse>
      </div>
    )
  }

  const DisplayBucketCard = (bucketName, rule, outerIndex, indexHash) => {
    const headerStr = `${bucketName} (${rule.length})`
    return (
      <>
        {
          rule.length > 0 && (
            <Collapse
              className="outer-collapse"
              defaultActiveKey={outerActive}
              onChange={() => { handleOuterActive(indexHash) }}
              ghost
            >
              <Panel header={headerStr} key={outerIndex}>
                {rule.map((result, index) => {
                  return (
                    <DisplayRuleCard
                      key={`${result.fieldData.fieldName}${index}`}
                      ruleIndex={`${result.fieldData.fieldName}${index}`}
                      innerActive={innerActive}
                      fieldData={result.fieldData}
                    />
                  )
                })}
              </Panel>
            </Collapse>
          )
        }
      </>
    )
  }

  const documentLoaded = () => {
    if (documentLoadedTimeout) {
      console.log('documentLoaded:clearing existing timeout')
      clearTimeout(documentLoadedTimeout)
    }

    const timeout = setTimeout(() => {
      setupInitialSearchScope()
    }, 500)
    setDocumentLoadedTimeout(timeout)
  }

  const setupInitialSearchScope = async () => {
    const {
      highlight,
      setTargetPages,
      clearHighlights,
    } = defaultLayoutPluginInstance.toolbarPluginInstance.searchPluginInstance

    if (showReviewAnnotations) {
      const uadHeaders = await highlight(['Freddie Mac Form', 'Fannie Mae Form'])
      const pageIndices = uadHeaders.map(match => match.pageIndex)
      const startPage = Math.min(...pageIndices)
      const endPage = Math.max(...pageIndices)

      setTargetPages((targetPage) => targetPage.pageIndex >= startPage && targetPage.pageIndex <= endPage)
      clearHighlights()
    }
  }

  return <>
    <div className='row no-gutters result-container'>
      <div className='col-8 result-container-pdf'>
        <Viewer fileUrl={fileUrl} httpHeaders={httpHeaders} plugins={[
          defaultLayoutPluginInstance,
        ]}
        enableSmoothScroll={false}
        defaultScale={SpecialZoomLevel.PageWidth}
        onDocumentLoad={documentLoaded}
        />
      </div>
      <div className='col-4 pdf-rule-card-section'>
        {Object.entries(filteredDataIntoBucketsByStatus).map(([bucketName, value], outerIndex) => (
          DisplayBucketCard(bucketName, value, outerIndex, `${bucketName}${outerIndex}`)
        ))}
      </div>
    </div>
  </>
}
