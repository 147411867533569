import { Component } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'reactstrap'
const propTypes = {
  inputClassName: PropTypes.string,
  placeholder: PropTypes.string,
  pattern: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string
}
const defaultProps = {
  inputClassName: 'input-custom',
  readOnly: false
}

export default class BasicSelectInput extends Component {
  handleChange = (e) => {
    this.props.onChange(e.target.value)
  }
  render() {
    const { className = '', placeholder, options,
      required, disabled, readOnly, name, value, valueKey, labelKey } = this.props
    return (
      <Input
        type='select'
        className={className}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        onChange={this.handleChange}
        required={required}
        readOnly={readOnly}
        value={value}
      >
        <option></option>
        {
          options.map(option => (
            <option key={option[valueKey]} value={option[valueKey]}>{option[labelKey]}</option>
          ))
        }
      </Input>

    )
  }
}

BasicSelectInput.propTypes = propTypes
BasicSelectInput.defaultProps = defaultProps
