// libraries
import React from 'react'

// internal dependencies
import DownloadDropdown from 'app/common/widgets/DownloadDropdown'
import { apiToReadable } from 'app/common/utils/helpers'

// certain doc types should not be exposed here. SSRs are exposed elsewhere
const DOC_TYPES_TO_HIDE = [
  'fannie_ssr',
  'freddie_ssr',
  'ead_ssr',
]

/**
 * Component renders a UI for downloading documents associated with an external review process

 renders null if there are no documents to download

 does not handle permission checks, assumes it will not be rendered if user is missing the permissions
 */
const ExternalReviewDownload = ({
  downloadDocument,
  externalReviewResults,
  orderID,
  submissionVersion,
  classNames,
  showIcon,
  buttonColor,
  useAntButton,
}) => {
  // determine if we have anything to show
  if (!externalReviewResults || externalReviewResults.length === 0) {
    // loan has no external review results
    return null
  }
  let matchingExternalReviewResult
  for (const externalResult of externalReviewResults) {
    const isOrderMatching = externalResult.order_id === orderID
    const isVersionMatching = externalResult.review_data.approved_submission_version === submissionVersion
    if (isOrderMatching && isVersionMatching) {
      matchingExternalReviewResult = externalResult
      break
    }
  }
  if (!matchingExternalReviewResult) {
    // none of the external review results match this order and submission
    return null
  }
  const reviewFiles = matchingExternalReviewResult.review_files
  const downloadableFiles = []
  for (const documentType in reviewFiles) {
    if (DOC_TYPES_TO_HIDE.includes(documentType)) {
      // don't expose this file here
      continue
    }
    const readable = apiToReadable(documentType)
    const downloadInfo = {
      documentType,
      label: readable,
      downloadURL: reviewFiles[documentType],
    }
    downloadableFiles.push(downloadInfo)
  }

  if (!downloadableFiles || downloadableFiles.length === 0) {
    // no files to download
    return null
  }

  const onItemSelect = (item) => {
    downloadDocument(item.documentType, submissionVersion)
  }

  return (<span>
    <DownloadDropdown
      items={downloadableFiles}
      onItemSelect={onItemSelect}
      title=' Download External Review Document '
      classNames={classNames}
      showIcon={showIcon}
      buttonColor={buttonColor}
      useAntButton={useAntButton}
    />
  </span>)
}

export default ExternalReviewDownload
