import React from 'react'

const ToolbarTitle = ({ children }) => (
  <div className="flex-1">
    <h4 className="mb-0" style={{ lineHeight: '35px' }}>
      {children}
    </h4>
  </div>
)

export default ToolbarTitle
