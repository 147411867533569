/* Inspections helpers. */

export const getInspectionDetail = (inspectionDetailsMap, questionLocation) => {
  if (!inspectionDetailsMap) {
    return null
  }
  return inspectionDetailsMap[questionLocation] || null
}

export const countInspectionDetails = (inspectionDetailsMap, questionName) => {
  /*
    Gets the amount of items for a given inspection detail.
    I.e. structure.{counter}.full_question_location_path.0
  */
  let counter = 0
  let looking = true
  while (looking) {
    const tempQuestionName = questionName.replace('{counter}', counter)
    const match = getInspectionDetail(inspectionDetailsMap, tempQuestionName)
    if (!match) {
      looking = false
      break
    }
    counter += 1
  }
  return counter
}

export const getInspectionDetailValues = (inspectionDetailsMap, questionLocation) => {
  const detail = getInspectionDetail(inspectionDetailsMap, questionLocation)
  if (!detail) {
    return []
  }
  return detail.values || []
}

export const getInspectionDetailValue = (inspectionDetailsMap, questionLocation) => {
  const values = getInspectionDetailValues(inspectionDetailsMap, questionLocation)
  return values.length ? values[0] : ''
}

export const getValuesFromInspectionDetail = (detail) => {
  if (!detail) {
    return []
  }
  return detail.values || []
}

export const getValueFromInspectionDetail = (detail) => {
  const values = getValuesFromInspectionDetail(detail)
  return values.length ? values[0] : ''
}

export const sortLevelsByLevelNumber = (levelTypeInspectionDetails) => {
  /*
    Assumes levelTypeInspectionDetails are already ordered by section index.
    I.e. ["...structure-level.0...", "...structure-level.1...", ...]
  */
  const sortedLevelsMap = {
    basement: [],
    belowGrade: [],
    aboveGrade: [],
    attic: [],
  }

  for (const levelDetail of levelTypeInspectionDetails) {
    const levelType = getValueFromInspectionDetail(levelDetail)
    if (levelType === 'basement') {
      sortedLevelsMap.basement.unshift(levelDetail)
    } else if (levelType === 'below_grade') {
      sortedLevelsMap.belowGrade.unshift(levelDetail)
    } else if (levelType === 'above_grade') {
      sortedLevelsMap.aboveGrade.push(levelDetail)
    } else if (levelType === 'attic') {
      sortedLevelsMap.attic.push(levelDetail)
    }
  }

  return [
    ...sortedLevelsMap.basement,
    ...sortedLevelsMap.belowGrade,
    ...sortedLevelsMap.aboveGrade,
    ...sortedLevelsMap.attic,
  ]
}
