import React, { Component } from 'react'
import {
  Button,
  Nav,
  NavbarBrand,
  NavbarToggler,
} from 'reactstrap'
import HeaderDropdownContainer from './HeaderDropdownContainer'
import NotificationsDropdownContainer from './NotificationsDropdownContainer'
import host from '@elliemae/em-ssf-guest'
import { datadogRum } from '@datadog/browser-rum'
import SwitchAccountContainer from '../../../../pages/profile/SwitchAccountContainer'


export default class Header extends Component {
  mobileSidebarToggle = (e) => {
    this.props.mobileSidebarToggle()
    this.props.toggleMinimizeSidebar()
  }

  returnToEncompass = async () => {
    try {
      host.connect()
      const transactionObject = await host.getObject('transaction')
      transactionObject.close()
    } catch (error) {
      datadogRum.addError(`Error closing Encompass transaction for loan:${this.props.loanIdForEncompass}. Error: ${JSON.stringify(error)}`)
    }
  }

  render() {
    const returnToEncompassButton = (
      !!this.props.loanIdForEncompass &&
      <li className="nav-item">
        <Button
          color="secondary"
          onClick={this.returnToEncompass}
        >
          Return to Encompass
        </Button>
      </li>
    )
    return (
      <header className="app-header navbar">
        <NavbarToggler className="d-lg" onClick={this.mobileSidebarToggle}>
          <span className="navbar-toggler-icon"/>
        </NavbarToggler>
        <NavbarBrand href="/" className='navbar-header'>
          <span style={{ marginRight: 6 }}><b>Reggora</b></span> Lender
        </NavbarBrand>
        <b style={{ fontSize: '1.07375rem', color: '#29363d', marginBottom: 2 }} className="border-left pl-3">{this.props.user?.data?.lender?.name}</b>
        <Nav className="ml-auto" navbar>
          <NotificationsDropdownContainer />

          <HeaderDropdownContainer user={this.props.user}/>

          {returnToEncompassButton}

          <SwitchAccountContainer user={this.props.user}/>
        </Nav>
      </header>
    )
  }
}
