import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { ModalROV } from './ModalROV'
import { ModalROVOld } from './ModalROVOld'
import { connectResource } from '../../common/utils/resource'
import { convertStateToShort, convertStateToLong } from '../../common/utils/helpers'

class ModalROVContainer extends Component {
  state = {
    existingRevision: this.props?.revision || null,
    reason: this.props?.revision?.text || '',
    comps: this.props?.revision?.comparables || [],
    tab: 'comp #1',
    loading: false,
    showCloseModal: [],
    categories: this.props?.revision?.categories?.map((category) => category.category_type) || [],
    isBorrowerInitiated: typeof this.props?.revision?.is_borrower_initiated === 'boolean' ? this.props?.revision?.is_borrower_initiated : null,
    includeComparables: this.props?.revision?.comparables?.length > 0 ? true : (this.props?.revision ? false : null),
    confirmationText: '',
    showConfirmsubmit: false,
  }

  componentDidMount() {
    if (this.state.comps.length > 0) {
      // Update the State values of each comp to be an object with label and value to be compatible with the Select input
      const updatedComps = this.state.comps.map(comp => ({
        ...comp,
        state: {
          label: convertStateToLong(comp.state),
          value: comp.state,
        },
      }))

      this.setState({
        comps: updatedComps,
      })
    } else {
      this.addComp()
    }
  }

  addComp = () => {
    this.setState(prevState => {
      const newComp = {
        comp_number: '',
        property_address: '',
        city: '',
        state: '',
        zip: '',
        sales_price: '',
        date_of_sale: '',
        reported_heated_sq_footage: '',
        reported_actual_age: '',
        reported_site_size: '',
        source_of_data: '',
        apn_mls_number: '',
        additional_comments: '',
      }

      const nextComps = [...prevState.comps, newComp]
      const newTab = `comp #${nextComps.length}`
      const newShowCloseModal = [...prevState.showCloseModal, false]

      return {
        comps: nextComps,
        tab: newTab,
        showCloseModal: newShowCloseModal,
      }
    })
  }

  changeTab = tab => this.setState({ tab })

  onTextChange = ({ target: { value, name } }, compIdx) => {
    if (compIdx !== undefined) {
      const { comps } = this.state
      const temp = [...comps]
      temp[compIdx][name] = value
      this.setState({
        comps: temp,
      })
    } else {
      this.setState({ [name]: value })
    }
  }

  onSelect = (state, idx) => {
    this.setState(prevState => {
      const nextComps = [...prevState.comps]

      nextComps[idx] = {
        ...nextComps[idx],
        state: state,
      }

      return { comps: nextComps }
    })
  }

  onRemoveComp = idx => {
    const { comps, showCloseModal } = this.state
    const temp = [...comps]
    temp.splice(idx, 1)

    const showtemp = [...showCloseModal]
    showtemp.splice(idx, 1)

    const tab = temp.length ? `comp #${temp.length}` : 'reason'
    this.setState({
      comps: temp,
      tab,
      showCloseModal: showtemp,
    })
  }

  onSubmit = _ => {
    const {
      reason,
      comps,
      existingRevision,
      categories,
      includeComparables,
      isBorrowerInitiated,
    } = this.state
    const {
      rovRequest,
      order_id,
      submission_version,
      onHide,
      refreshOrder,
      useOldROV,
    } = this.props
    this.setState({ loading: true })
    const body = {
      rov_id: existingRevision?.id || null,
      reason,
      comps: useOldROV ? comps : (includeComparables ? comps.map(comp => {
        if (comp.state) {
          comp.state = convertStateToShort(comp.state.value)
        }
        return comp
      }) : []),
      categories: categories.map(category_type => {
        return { category_type, category_description: '' }
      }),
      is_borrower_initiated: isBorrowerInitiated,
      order_id,
      submission_version,
    }
    if (existingRevision) {
      rovRequest.put(body)
        .then(onHide)
        .then(refreshOrder)
        .finally(() => this.setState({ loading: false }))
    } else {
      rovRequest.create(body)
        .then(onHide)
        .then(refreshOrder)
        .finally(() => this.setState({ loading: false }))
    }
  }

  onCloseComp = (idx) => {
    const { showCloseModal } = this.state
    const showTem = [...showCloseModal]
    showTem.splice(idx, 1, true)
    this.setState({ showCloseModal: showTem })
  }

  onHideCompModal = (idx) => {
    const { showCloseModal } = this.state
    const showTem = [...showCloseModal]
    showTem.splice(idx, 1, false)

    this.setState({ showCloseModal: showTem })
  }

  onConfirmSubmit = _ => {
    const { comps } = this.state
    if (comps.length < 1) {
      const confirmationText = 'Are you sure you want to submit without comps ?'
      this.setState({
        showConfirmsubmit: true,
        confirmationText,
      })
    } else {
      const compMissingPropertyAddress = comps.find(comp => comp.property_address === '')
      if (compMissingPropertyAddress) {
        const confirmationText = 'You are missing comparable property information! Send anyway?'
        this.setState({
          showConfirmsubmit: true,
          confirmationText,
        })
      } else {
        this.onSubmit()
      }
    }
  }

  onHideSubmit = () => {
    this.setState({ showConfirmsubmit: false })
  }

  render() {
    const {
      loading,
      existingRevision,
      tab,
      comps,
      reason,
      categories,
      showCloseModal,
      includeComparables,
      isBorrowerInitiated,
      confirmationText,
      showConfirmsubmit,
    } = this.state
    const { onHide, is_amc_lender, useOldROV } = this.props

    return useOldROV ? <ModalROVOld
      existingRevision={existingRevision}
      addComp={this.addComp}
      onSelect={this.onSelect}
      onSubmit={this.onSubmit}
      changeTab={this.changeTab}
      onTextChange={this.onTextChange}
      onRemoveComp={this.onRemoveComp}
      loading={loading}
      onHide={onHide}
      tab={tab}
      comps={comps}
      reason={reason}
      onConfirmSubmit={this.onConfirmSubmit}
      onHideSubmit={this.onHideSubmit}
      onCloseComp={this.onCloseComp}
      onHideCompModal={this.onHideCompModal}
      showCloseModal={showCloseModal}
      confirmationText={confirmationText}
      showConfirmsubmit={showConfirmsubmit}
      is_amc_lender={is_amc_lender}
    /> : <ModalROV
      isReadOnly={existingRevision && !is_amc_lender}
      addComp={this.addComp}
      onSelect={this.onSelect}
      onSubmit={this.onSubmit}
      changeTab={this.changeTab}
      onTextChange={this.onTextChange}
      onRemoveComp={this.onRemoveComp}
      loading={loading}
      onHide={onHide}
      tab={tab}
      comps={comps}
      reason={reason}
      onCloseComp={this.onCloseComp}
      onHideCompModal={this.onHideCompModal}
      showCloseModal={showCloseModal}
      categories={categories}
      setCategories={(categories) => this.setState({ categories })}
      includeComparables={includeComparables}
      setIncludeComparables={(includeComparables) => this.setState({ includeComparables })}
      isBorrowerInitiated={isBorrowerInitiated}
      setIsBorrowerInitiated={(isBorrowerInitiated) => this.setState({ isBorrowerInitiated })}
    />
  }
}

const mapStateToProps = _ => ({})
const mapDispatchToProps = _ => ({})


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  connectResource({
    namespace: 'rovRequest',
    endpoint: 'order/rov',
    prefetch: false,
    apiVersion: 2,
  })
)(ModalROVContainer)
