import { of } from 'rxjs/observable/of'

import {
  SET_DATA
} from '../../common/utils/resource'

export const SET_MAP_OVERLAYS = 'SET_MAP_OVERLAYS'

export function setMapOverlays(countyOverlay, zipOverlay) {
  return {
    type: SET_MAP_OVERLAYS,
    countyOverlay,
    zipOverlay
  }
}

export default function mapOverlays(action$, store, { API }) {
  return action$.ofType(SET_DATA).mergeMap(action => {
    if(action.meta && action.meta.resource && action.meta.resource.namespace === 'fetchMapOverlays' && action.payload) {
      const currentStore = store.getState()
      const resourceState = currentStore.resource.fetchMapOverlays
      let fips = resourceState.filters.state
      let type = resourceState.filters.type
      const countyOverlay = { ...currentStore.mapOverlays.countyOverlay }
      const zipOverlay = { ...currentStore.mapOverlays.zipOverlay }
      if(type === 'zip') {
        fips += resourceState.filters.county
        zipOverlay[fips] = action.payload
      } else {
        countyOverlay[fips] = action.payload
      }
      return of(setMapOverlays(countyOverlay, zipOverlay))
    } else {
      return []
    }
  })
}
