import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { connectResource } from '../../../common/utils/resource'

import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Label,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
} from 'reactstrap'
import Rephrase from '../../../common/utils/Rephrase'

class BulkRevisionModal extends Component {
  state = {
    revisions: this.props.revisions,
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.revisions !== nextProps.revisions) {
      this.setState({
        revisions: nextProps.revisions,
      })
    }
  }

  setTitle = (idx, title) => {
    this.setState(prevState => {
      const rev = {
        ...prevState.revisions[idx],
        title,
      }
      const revisions = [...prevState.revisions]
      revisions[idx] = rev
      return { revisions }
    })
  }

  setText = (idx, text) => {
    this.setState(prevState => {
      const rev = {
        ...prevState.revisions[idx],
        text,
      }
      const revisions = [...prevState.revisions]
      revisions[idx] = rev
      return { revisions }
    })
  }

  onSubmit = () => {
    const {
      bulkRevisions,
      order_id,
      onHide,
    } = this.props
    const {
      revisions,
    } = this.state
    bulkRevisions.create({
      revisions,
      order_id,
    }).then(onHide)
  }

  renderRevision = (revision, idx) => {
    return (<Card className='mb-4 mt-1'>
      <CardBody>
        <Label>Title:</Label>
        <Input value={revision.title} onChange={e => this.setTitle(idx, e.target.value)} />
        <Label className='mt-3'>Request:</Label>
        <textarea className='w-100' type='text' value={revision.text} onChange={e => this.setText(idx, e.target.value)} />
      </CardBody>
    </Card>)
  }

  render() {
    const {
      onHide,
    } = this.props
    const {
      revisions,
    } = this.state
    return (<Fragment>
      <ModalHeader toggle={onHide}>Create Revisions From Review Errors</ModalHeader>
      <ModalBody>
        <Row>
          <Col xs='12'>
            <p>Please edit the review errors into revision requests.</p>
            {revisions.map(this.renderRevision)}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onHide}>Cancel</Button>
        <Button onClick={this.onSubmit}><Rephrase>Send Revisions To Appraiser</Rephrase></Button>
      </ModalFooter>
    </Fragment>)
  }
}

export default compose(
  connectResource({
    prefetch: false,
    namespace: 'bulkRevisions',
    endpoint: 'order/bulk-revisions',
    apiVersion: 2,
    successMessage: {
      POST: 'Your revisions have been created',
    },
  }),
)(BulkRevisionModal)
