import { Modal } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'

const modalPropTypes = PropTypes.shape({
  component: PropTypes.any.isRequired,
  contentClass: PropTypes.string,
  contentStyles: PropTypes.object,
  onClosed: PropTypes.func,
  onDismiss: PropTypes.func,
  show: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'full']),
})
const ant = 'ant'
const component = 'modal'
const prefixCls = `${ant}-${component}`

export default function ModalWrapper(props) {
  const { modal, ...restProps } = props
  const {
    component: WrappedComponent,
    contentClass,
    contentStyles,
    /**
     * Whether to close the modal dialog when the mask (area outside the modal) is clicked.
     * @type {boolean}
     */
    maskClosable = true,
    onCancel,
    onClosed,
    onConfirm,
    show,
    size,
  } = modal

  const handleConfirm = (...args) => {
    if (onConfirm) {
      return onConfirm(...args)
    }
    return false
  }

  const handleCancel = (...args) => {
    if (onCancel) {
      return onCancel(...args)
    }
    return false
  }

  /** @type {AntdModalApi} */
  const modalApi = {
    confirm: handleConfirm,
    cancel: handleCancel,
  }

  return (
    <Modal
      wrapClassName='ant-modal-wrap-reggora'
      className={classNames('ant-modal-reggora', size ?? null)}
      destroyOnClose={true}
      visible={show}
      maskClosable={maskClosable}
      modalRender={() => {
        return (
          <div className={classNames(`${prefixCls}-content`, contentClass)} style={contentStyles}>
            <WrappedComponent modalApi={modalApi} {...restProps} />
          </div>
        )
      }}
      afterClose={onClosed}
      onOk={handleConfirm}
      onCancel={handleCancel}
    />
  )
}

ModalWrapper.propTypes = {
  modal: modalPropTypes,
}
