import BaseFieldHOC from './BaseFieldHOC'
import BasicSelectInput from './inputs/BasicSelectInput'
import CheckboxInput from './inputs/CheckboxInput'
// import CurrencyInput from './inputs/CurrencyInput'
import DateInput from './inputs/DatePickerInput'
import NumberInput from './inputs/NumberInput'
import RadiosInput from './inputs/RadiosInput'
import SelectInput from './inputs/SelectInput'
import AsyncSelectInput from './inputs/AsyncSelectInput'
// import SelectRangeInput from './inputs/SelectRangeInput'
import TextInput from './inputs/TextInput'
import TextAreaInput from './inputs/TextAreaInput'

const CheckboxField = BaseFieldHOC(CheckboxInput)
// const CurrencyField = BaseFieldHOC(CurrencyInput)
const DateField = BaseFieldHOC(DateInput)
const NumberField = BaseFieldHOC(NumberInput)
const SelectField = BaseFieldHOC(BasicSelectInput)
const RadiosField = BaseFieldHOC(RadiosInput)
const ReactSelectField = BaseFieldHOC(SelectInput)
// const SelectRangeField = BaseFieldHOC(SelectRangeInput)
const AsyncReactSelectInput = BaseFieldHOC(AsyncSelectInput)
const TextField = BaseFieldHOC(TextInput)
const TextAreaField = BaseFieldHOC(TextAreaInput)

export {
  CheckboxField,
  // CurrencyField,
  DateField,
  NumberField,
  RadiosField,
  SelectField,
  ReactSelectField,
  // SelectRangeField,
  TextField,
  TextAreaField,
  AsyncReactSelectInput,
}
