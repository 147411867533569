export const KEY_3_MONTHS = '3months'
export const KEY_6_MONTHS = '6months'
export const KEY_12_MONTHS = '12months'
export const KEY_ALL_TIME = 'alltime'
export const KEY_CUSTOM_RANGE = 'custom'

export const DATE_RANGE_FILTER_TEXT = {
  [KEY_3_MONTHS]: 'Last 3 Months',
  [KEY_6_MONTHS]: 'Last 6 Months',
  [KEY_12_MONTHS]: 'Last 12 Months',
  [KEY_ALL_TIME]: 'All Time',
  [KEY_CUSTOM_RANGE]: 'Custom Range...',
}
