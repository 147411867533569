import React, { Component } from 'react'
import { compose } from 'redux'
import { Button } from 'reactstrap'
import { connect } from 'react-redux'
import SendConsumerModal from './ConsumerPermissionsModal'
import { connectResource } from '../../common/utils/resource'
import { selectOrderSchedule } from '../../store/reducers/order/orderSelectors'

class ConsumerPermissionsModalContainer extends Component {
  createConsumerList = () => {
    const {
      orderData,
    } = this.props

    const {
      schedule,
    } = orderData

    const checkedConsumers = {}

    schedule.consumers.forEach((consumer) => {
      checkedConsumers[consumer.id] = {
        checked: false,
        canResend: false,
        email: consumer.email,
        full_name: consumer.name,
        role: consumer.role,
      }
    })

    schedule.consumer_dates.forEach(consumer_date => {
      checkedConsumers[consumer_date.consumer].checked = true
      checkedConsumers[consumer_date.consumer].canResend = true
    })

    return checkedConsumers
  }

  state = {
    checkedConsumers: this.createConsumerList(),
  }


  resend = (consumer_id) => {
    const {
      orderData: {
        id: order_id,
      },
    } = this.props

    const data = {
      users: [consumer_id],
      order_id,
    }

    this.props.sendLoanInfo.put(data)
      .then(_ => {
        this.props.onHide()
        this.props.order.fetch()
      })
  }

  onSubmit = () => {
    const {
      checkedConsumers,
    } = this.state
    const {
      orderData: {
        id: order_id,
      },
    } = this.props

    const users = Object.entries(checkedConsumers).filter(([id, value]) => value.checked).map(([id, value]) => id)

    const data = {
      users,
      order_id,
    }

    this.props.sendLoanInfo.create(data)
      .then(_ => {
        this.props.onHide()
        this.props.refreshOrder()
      })
  }

  onChange = (checked, consumer_id) => {
    this.setState(prevState => {
      const checkedConsumers = { ...prevState.checkedConsumers }
      checkedConsumers[consumer_id] = {
        ...checkedConsumers[consumer_id],
        checked: checked,
      }
      return { checkedConsumers }
    })
  }

  resendButton = (consumer_id) => {
    return <Button onClick={() => this.resend(consumer_id)}>Resend</Button>
  }

  render() {
    return <SendConsumerModal
      {...this.props}
      {...this.state}
      resendButton={this.resendButton}
      onChange={this.onChange}
      onSubmit={this.onSubmit}
    />
  }
}
const mapStateToProps = ({
  resource: {
    user: { data: { lender: { stripe_payments_enabled } } },
  },
  ...restOfState
}) => ({ stripe_payments_enabled, schedule: selectOrderSchedule(restOfState) })

export default compose(
  connect(mapStateToProps, null),
  connectResource({ namespace: 'sendLoanInfo', endpoint: 'loans/send', prefetch: false, idKey: null, apiVersion: 2 }),
  connectResource({
    namespace: 'order',
    endpoint: 'order/:orderId',
    prefetch: false,
    async: true,
    includeQueryParams: true,
    apiVersion: 2,
    filters: {
      get_follow_up_orders_incomplete: true,
      get_submissions_incomplete: true,
      get_minimal_data: true,
    },
  }),
)(ConsumerPermissionsModalContainer)
