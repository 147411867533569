import { loadableWithRetry } from '../../common/utils/lazyLoad'

const routes = [
  {
    path: '/',
    exact: true,
    name: 'auth',
    publicRoute: true,
    redirectTo: 'auth/login',
  },
  {
    path: '/login',
    name: 'login',
    publicRoute: true,
    component: loadableWithRetry(() => import('./containers/LoginSSOContainer')),
  },
  {
    path: '/forgot',
    name: 'forgot',
    publicRoute: true,
    component: loadableWithRetry(() => import('./containers/ForgotPasswordContainer')),
  },
  {
    path: '/reset',
    name: 'reset',
    publicRoute: true,
    component: loadableWithRetry(() => import('./containers/ResetPasswordContainer')),
  },
  {
    path: '/reset-success',
    name: 'reset-success',
    publicRoute: true,
    component: loadableWithRetry(() => import('./containers/ResetSuccessContainer')),
  },
  {
    path: '/sso-login',
    name: 'sso-login',
    publicRoute: true,
    component: loadableWithRetry(() => import('./containers/SSOLoginContainer')),
  },
  {
    path: '/sso-login-password',
    name: 'sso-login-password',
    publicRoute: true,
    component: loadableWithRetry(() => import('./containers/LoginPasswordContainer')),
  },
  {
    path: '/sso-redirect',
    name: 'sso-redirect',
    publicRoute: true,
    component: loadableWithRetry(() => import('./components/LoginSSORedirect')),
  },
]

export default routes
