import jwtDecode from 'jwt-decode'
import { fromPromise } from 'rxjs/observable/fromPromise'
import { of } from 'rxjs/observable/of'
import * as sessionActions from '../session'
import {
  setData,
  setErrors,
  SET_DATA,
} from '../../common/utils/resource'
import { history } from '../../init'
import q from 'querystring'
import analytics from '../../../helpers/segment'
import {
  setLoanIdForEncompass,
  updateHamburgerMenu,
} from '../../common/socket/actions'
import { getRedirectLink } from '../../common/utils/helpers'


export default function loginGetUser(action$, store, { API }) {
  return action$.ofType(SET_DATA).mergeMap(action => {
    if (action.meta && action.meta.resource && action.meta.resource.namespace === 'session' && action.payload && action.payload.token) {
      const { error } = jwtDecode(action.payload.token)
      if (!error) {
        return fromPromise(API('users/current_user', 2).request('GET').catch(err => store.dispatch(sessionActions.logout(err)))).switchMap(res => {
          if (!res || (res && !res.lender)) {
            store.dispatch(sessionActions.logout())
            return of(setErrors('The email or password you entered is incorrect. Please try again.', { resource: { namespace: 'session' } }))
          }
          const lenderInfo = {}
          for (const key in res.lender) {
            if (!['blacklist', 'default_files', 'notification_settings'].includes(key)) {
              lenderInfo[`lender_${key}`] = res.lender[key]
            }
          }
          analytics.userLogIn(res)
          return of(setData(res, { resource: { namespace: 'user' } }))
        })
      } else {
        store.dispatch(sessionActions.logout())
        return of(setErrors({ error }, { resource: { namespace: 'session' } }))
      }
    } else if (action.meta && action.meta.resource && action.meta.resource.namespace === 'user' && (history.location.pathname === '/auth/login' || history.location.pathname === '/auth/sso-login-password')) {
      const current_redirect_url = (store.getState().app.redirect_url) || (q.parse(window.location.search).redirectUrl)
      const loanIdForEncompass = q.parse(window.location.search).loan_id_for_encompass
      const hamburgerClosed = q.parse(window.location.search).hamburger_closed
      if (loanIdForEncompass) {
        store.dispatch(setLoanIdForEncompass(loanIdForEncompass))
      }
      if (hamburgerClosed) {
        store.dispatch(updateHamburgerMenu(hamburgerClosed))
      }
      if (current_redirect_url) {
        history.push(current_redirect_url.toString())
      } else {
        const permission_based_redirect_url = getRedirectLink()
        history.push(permission_based_redirect_url)
      }
      return []
    } else {
      return []
    }
  })
}
