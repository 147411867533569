import React from 'react'
import { Button, ModalHeader } from 'reactstrap'

export default function ModalConfirmation(props) {
  const { onHide, onConfirm, confirmationText, dismissBtn, confirmBtn, confirmDisabled, textBody } = props
  return (
    <div>
      <ModalHeader toggle={props.onHide}>{confirmationText}</ModalHeader>
      <div className="modal-body">
        <p>
          {textBody}
        </p>
      </div>
      <div className="border-0 modal-footer">
        <Button onClick={onHide}>{dismissBtn}</Button>
        <Button disabled={confirmDisabled} onClick={onConfirm} className="btn-primary" color="primary">{confirmBtn}</Button>
      </div>
    </div>
  )
}
