import { of } from 'rxjs/observable/of'

import {
  NEW_NOTIFICATION
} from '../../common/utils/resource'
import {
  updateNotificationsActionCreator
} from '../../common/socket/notificationHandler'

export default function newNotificationEpic(action$, store) {
  return action$.ofType(NEW_NOTIFICATION).mergeMap(action => {
    const notifications = store.getState().resource.notifications.data
    const newNotifications = [
      action.notification,
      ...notifications
    ]
    return of(updateNotificationsActionCreator(newNotifications))
  })
}
