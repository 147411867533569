// constants for specific file types, these essentially act as keys
export const FILE_TYPE_PDF = 'pdf'
export const FILE_TYPE_XML = 'xml'

// readable file name displayed to users
export const FILE_TYPE_TO_READABLE = {
  [FILE_TYPE_PDF]: 'PDF',
  [FILE_TYPE_XML]: 'XML'
}
// accept string for an input to specify files allowed
export const FILE_TYPE_TO_ACCEPT_STRING = {
  [FILE_TYPE_PDF]: '.pdf, application/pdf',
  [FILE_TYPE_XML]: '.xml, text/xml, application/xml'
}
