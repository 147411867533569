import { loadableWithRetry } from '../../common/utils/lazyLoad'

const routes = [
  {
    path: '/',
    component: loadableWithRetry(() => import('./LoanFilesContainer')),
    name: 'loans',
    exact: true,
  },
  {
    path: '/:id',
    name: 'singleLoan',
    component: loadableWithRetry(() => import('./SingleLoanFile')),
  },
]

export default routes
