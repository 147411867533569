export const GSEBlockedErrorCodeToReadable = {
  missing_xml: 'XML file is missing',
  missing_appraised_value: 'Could not determine appraised value from XML file',
  low_appraised_value: 'The appraised value is lower than the value of the property on the loan file',
  missing_gse_number: 'Please update GSE Submission Settings to include all required numbers',
  missing_case_number: 'This loan has no FHA case number and could not be submitted to EAD',
  missing_submit_ucdp_setting: 'FHA loans by default are not submitted to UCDP',
}


// hardstop codes
export const GSE_HARDSTOP_ADDRESS_UNKNOWN = '302'

// override codes
const GSE_OVERRIDE_RQ30201 = 'RQ30201'
const GSE_OVERRIDE_RQ30202 = 'RQ30202'
const GSE_OVERRIDE_RQ30203 = 'RQ30203'
const GSE_OVERRIDE_RQ30204 = 'RQ30204'
const GSE_OVERRIDE_RQ30205 = 'RQ30205'
const GSE_OVERRIDE_RQ30299 = 'RQ30299'

export const GSE_HARDSTOP_CODES_TO_OVERRIDE_CODES = {
  [GSE_HARDSTOP_ADDRESS_UNKNOWN]: [
    { label: 'Recent/new construction', value: GSE_OVERRIDE_RQ30201 },
    { label: 'Subject address located in rural area', value: GSE_OVERRIDE_RQ30202 },
    { label: 'Address has recently changed and therefore may not be in your database', value: GSE_OVERRIDE_RQ30203 },
    { label: 'Address is correct and verified via local professional', value: GSE_OVERRIDE_RQ30204 },
    { label: 'Address is correct and verified by lender', value: GSE_OVERRIDE_RQ30205 },
    { label: 'Other (see comments)', value: GSE_OVERRIDE_RQ30299 },
  ],
}

// keep track of which override codes require a comment to be included
export const GSE_OVERRIDES_REQUIRING_COMMENT = [
  GSE_OVERRIDE_RQ30299,
]


export const REGGORA_SUPPORT_OVERRIDES_ERRORS = [
  GSE_HARDSTOP_ADDRESS_UNKNOWN,
]
