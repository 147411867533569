/* Inspections selectors. */
import STATUS from '../../../common/constants/reducer_statuses'

export const selectInspectionSlice = state => state.inspections

export const selectInspections = state => selectInspectionSlice(state).inspections
export const selectInspectionsData = state => selectInspections(state).data
export const selectInspectionsStatus = state => selectInspections(state).status
export const selectInspectionsError = state => selectInspections(state).error
export const selectIsInspectionsLoading = state => selectInspectionsStatus(state) === STATUS.LOADING

export const selectFormSlice = state => selectInspectionSlice(state).form
export const selectFormStatus = state => selectFormSlice(state).status
export const selectIsFormLoading = state => selectFormStatus(state) === STATUS.LOADING
export const selectFormData = state => selectFormSlice(state).data
export const selectFormVersion = state => selectFormData(state).version

export const selectInspection = state => selectInspectionSlice(state).inspection
export const selectInspectionData = state => selectInspection(state).data
export const selectInspectionStatus = state => selectInspection(state).status
export const selectInspectionError = state => selectInspection(state).error
export const selectIsInspectionLoading = state => selectInspectionStatus(state) === STATUS.LOADING

export const selectInspectionDetails = state => selectInspectionSlice(state).inspectionDetails
export const selectInspectionDetailsData = state => selectInspectionDetails(state).data
export const selectInspectionDetailsDataMap = state => selectInspectionDetails(state).dataMap
export const selectInspectionDetailsStatus = state => selectInspectionDetails(state).status
export const selectInspectionDetailsError = state => selectInspectionDetails(state).error
export const selectIsInspectionDetailsLoading = state => selectInspectionDetailsStatus(state) === STATUS.LOADING


export const selectCreateInspectionDetails = state => selectInspectionSlice(state).createInspectionDetails
export const selectCreateInspectionDetailsStatus = state => selectCreateInspectionDetails(state).status
export const selectIsCreateInspectionDetailsLoading = state => {
  return selectCreateInspectionDetailsStatus(state) === STATUS.LOADING
}
export const selectCreateInspectionDetailsError = state => selectCreateInspectionDetails(state).error

export const selectUpdateInspectionDetail = state => selectInspectionSlice(state).updateInspectionDetail
export const selectUpdateInspectionDetailStatus = state => selectUpdateInspectionDetail(state).status
export const selectIsUpdateInspectionDetailLoading = state => {
  return selectUpdateInspectionDetailStatus(state) === STATUS.LOADING
}
export const selectUpdateInspectionDetailError = state => selectUpdateInspectionDetail(state).error

export const selectCreateInspectionSubmission = state => selectInspectionSlice(state).createInspectionSubmission
export const selectCreateInspectionSubmissionStatus = state => selectCreateInspectionSubmission(state).status
export const selectIsCreateInspectionSubmissionLoading = state => {
  return selectCreateInspectionSubmissionStatus(state) === STATUS.LOADING
}
export const selectCreateInspectionSubmissionError = state => selectCreateInspectionSubmission(state).error

export const selectInspectionSubmissions = state => selectInspectionSlice(state).inspectionSubmissions
export const selectInspectionSubmissionsData = state => selectInspectionSubmissions(state).data
export const selectInspectionSubmissionsStatus = state => selectInspectionSubmissions(state).status
export const selectIsInspectionSubmissionsLoading = state => selectInspectionSubmissionsStatus(state) === STATUS.LOADING
export const selectInspectionSubmissionsError = state => selectInspectionSubmissions(state).error
export const selectInspectionSubmissionById = (state, submissionId) => {
  const inspectionSubmissionsData = selectInspectionSubmissionsData(state)
  const submission = inspectionSubmissionsData.find(submission => submission._id === submissionId)
  return submission || null
}

export const selectFanniePropertyStatus = state => selectInspectionSlice(state).fanniePropertyStatus
export const selectFanniePropertyStatusData = state => selectFanniePropertyStatus(state).data
export const selectFanniePropertyStatusStatus = state => selectFanniePropertyStatus(state).status
export const selectIsFanniePropertyStatusLoading = state => selectFanniePropertyStatusStatus(state) === STATUS.LOADING
export const selectFanniePropertyStatusError = state => selectFanniePropertyStatus(state).error
