import React from 'react'
import { Table, Checkbox } from 'antd'

const RevisionReggoraReviewStep = ({
  allChecked,
  onChangeAll,
  reggoraReviewData,
  onCheckFailedRule,
  setData,
  setAllChecked,
}) => {
  const columns = (onCheckFailedRule, reggoraReviewData, setData) => [
    {
      title: (
        <div style={{ textAlign: 'center' }}>
          <Checkbox
            checked={allChecked}
            onChange={() => onChangeAll(reggoraReviewData, !allChecked, setData, setAllChecked)}
          />
        </div>),
      dataIndex: 'isChecked',
      key: 'isChecked',
      width: 60,
      render: (text, record, index) => (
        <div style={{ textAlign: 'center' }}>
          <Checkbox
            checked={record.isChecked}
            onChange={() => onCheckFailedRule(reggoraReviewData, index, setData)}
          />
        </div>
      ),
    },
    {
      title: 'Field',
      dataIndex: 'field_name',
      key: 'field',
      width: 350,
    },
    {
      title: 'Revision Request',
      dataIndex: 'revision_request',
      key: 'revisionRequest',
    },
  ]

  return (
    <>
      <p>Select the review findings you would like to include in the revision below.</p>
      <Table
        className={'request-revision-table'}
        columns={columns(onCheckFailedRule, reggoraReviewData, setData)}
        dataSource={reggoraReviewData}
        rowKey={record => record.id}
        scroll={{ y: 350 }}
        sticky
        size='small'
        pagination={false}
      />
    </>
  )
}

export default RevisionReggoraReviewStep
