import { loadableWithRetry } from '../../common/utils/lazyLoad'

const routes = [
  {
    path: '/',
    exact: true,
    name: 'accounting',
    component: loadableWithRetry(() => import('./Accounting')),
  },
]

export default routes
