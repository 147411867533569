import React, { Component } from 'react'

import {
  UP,
  DOWN,
  CURRENT_DIRECTION_TO_NEXT_DIRECTION,
} from './constants'
import SortIcon from './SortIcon'

export default class SortButton extends Component {
  /**
    SortButton displays an icon, and any children if provided.
    Once clicked, determines new sort direction and calls onSort

    The icon will use sortDirection when fieldName === sortedField, otherwise the null icon is shown to indicate this field is unsorted
        null or "": sortIcon (double arrows)
        "down": downIcon (down arrow)
        "up": upIcon (up arrow)

    The onClick calculates the new sort direction for this icon based on the constant CURRENT_DIRECTION_TO_NEXT_DIRECTION

    props:
      fieldName     : indicates the field this sort button is associated with
      onSort        : called after a user click. Handles calculation of new sort direction. arguments (fieldName, sortDirection) => {}
      sortDirection : indicates the current direction this table is sorted. accepted values ["down", "up", "", null]
      sortedField   : indicates the current field this table is sorted by
    */

  getCurrentDirection = () => {
    // based on props sortDirection, fieldName, and sortedField, determine what direction the icon should currently be
    const {
      fieldName,
      sortDirection,
      sortedField,
    } = this.props
    let currentDirection = ''
    const isThisFieldSorted = sortedField === fieldName
    const isSortDirectionValid = [UP, DOWN].indexOf(sortDirection) >= 0
    if (isThisFieldSorted && isSortDirectionValid) {
      // since this field is currently the one sorted, we can use sortDirection
      currentDirection = sortDirection
    }
    return currentDirection
  }

  onClick = () => {
    const {
      fieldName,
      onSort,
    } = this.props
    const currentSortDirection = this.getCurrentDirection()
    const nextSortDirection = CURRENT_DIRECTION_TO_NEXT_DIRECTION[currentSortDirection]
    onSort(fieldName, nextSortDirection)
  }

  render() {
    const {
      children,
      className,
    } = this.props
    const currentSortDirection = this.getCurrentDirection()

    return (<span className={className || 'sort-button-wrapper'} onClick={this.onClick}>
      {children}
      <SortIcon
        sortDirection={currentSortDirection}
      />
    </span>)
  }
}
