import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  showSwitchAccountModal: false,
}

const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    showSwitchAccount: (state) => {
      state.showSwitchAccountModal = true
    },
    hideSwitchAccount: (state) => {
      state.showSwitchAccountModal = false
    },
  },
})

export const { showSwitchAccount, hideSwitchAccount } = userProfileSlice.actions
export default userProfileSlice.reducer
