import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import API, { API_VERSION, REQUEST_METHOD } from '../../../api'
import { } from './userSelectors'

export const initialState = {
  queues: [],
}

export const fetchReviewerQueues = createAsyncThunk('reviewer-queues', async (userId, { rejectWithValue }) => {
  try {
    return await API(
    `review-assignments/reviewer-queues/v1?reviewer_user_id=${userId}`,
    API_VERSION.SERVICE
    ).request(REQUEST_METHOD.GET, {}, {})
  } catch (err) {
    return rejectWithValue(err.errors._error)
  }
})

const reviewerQueuesSlice = createSlice({
  name: 'reviewerQueues',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchReviewerQueues.fulfilled]: (state, action) => {
      // A user can only have one reviewer-queue resource, so we can just take the first.
      state.queues = action.payload?.results?.[0]?.queues || []
    },
  },
})

export default reviewerQueuesSlice.reducer
