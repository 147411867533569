import apiClient from '@app/apiClient'
import { useCallback, useEffect, useState } from 'react'

/**
 * A custom hook for fetching and managing revision metadata based on the order ID.
 * @typedef {Object} Params - Defines parameters for the custom hook.
 * @property {string} Params.orderId - The unique identifier for the order.
 * @property {boolean} [Params.isHookEnabled=true] - A flag to enable or disable the functionality of the hook.
 * @param {Params} params - The parameters for the custom hook.
 */
export const useRevisionMetadata = ({ orderId, isHookEnabled = true }) => {
  const [revisionMetadata, setRevisionMetadata] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const onGetRevisionMetadata = useCallback(async () => {
    setIsLoading(true)
    const lenderBackendApi = apiClient(API2_URL)
    try {
      const result = await lenderBackendApi.get(`order/${orderId}/revision/metadata`)
      setRevisionMetadata(result)
    } finally {
      setIsLoading(false)
    }
  }, [orderId])

  useEffect(() => {
    if (isHookEnabled) {
      onGetRevisionMetadata()
    }
  }, [onGetRevisionMetadata, isHookEnabled])

  return { revisionMetadata, isLoading }
}
