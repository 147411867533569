export const COUNTEROFFER_OPTIONS = {
  LOW_FEE: 'Requested Fee is too low',
  DUE_DATE: 'Cannot meet the requested due date',
  COMPLEX_LOCATION:
  'Property location complexity requires additional time or resources',
  COMPLEX_IMPROVEMENT:
  'Improvement complexity requires higher fee, more time or additional resources',
  ACCESSORY_UNIT: 'Accessory Dwelling Unit',
  MARKET_COMPLEXITY:
  'Market complexity requires additional time or resources',
  OTHER: 'Other',
}

export const NO_REASON_PROVIDED = 'Reason not supplied'
