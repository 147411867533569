import { of } from 'rxjs/observable/of'
import { fromPromise } from 'rxjs/observable/fromPromise'

import {
  CREATE_CSV_USERS
} from '../../pages/users/actions'
import {
  setData
} from '../../common/utils/resource'

export default function createCsvUsers(action$, store, { API }) {
  return action$.ofType(CREATE_CSV_USERS).mergeMap(action => {
    return fromPromise(API('users/upload', 2).request('POST', undefined, { data: action.data })).switchMap(_ => {
      return fromPromise(API('users/', 2).request('GET')).switchMap(res => of(setData(res, { resource: { namespace: 'users', successMessage: { 'POST': 'Your csv has been successfully uploaded.' } }, type: 'POST' })))
    })
  })
}
