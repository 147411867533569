import React, { Component } from 'react'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { connectResource, setData } from '../../common/utils/resource'

import { hideSwitchAccount } from '../../store/reducers/user/userProfileSlice'
import { fetchSsoClientUser } from '../../store/reducers/login/loginSso'
import ModalWrapper from '../../common/modals/ModalWrapper'
import SwitchAccount from './SwitchAccount'
import { getRedirectLink } from '../../common/utils/helpers'
import { redirectUrlPostLogin } from '../../common/socket/actions'

class SwitchAccountContainer extends Component {
  state = {
    password: '',
    selectedLender: '',
    userEmail: '',
    ssoRedirectUrl: '',
    passwordDisplay: false,
    passwordEnabled: false,
    isLoading: false,
  }

  componentDidMount() {
    if (this.props.user.data) {
      const user = this.props.user.data
      this.setState({
        userEmail: user.email,
        selectedLender: user.lender.id,
      })

      if (!this.props.lenderSelect) {
        this.props.lenderSelect.filter({ email: encodeURIComponent(user.email) })
      }
    }
  }

  handleSelectChange = (selectedItem) => {
    this.setState({ selectedLender: selectedItem })
    this.props.session.setFilters({ lender: selectedItem })

    const parmas = {
      user_email: this.state.userEmail,
      lender: selectedItem,
    }
    this.props.actions.fetchSsoClientUser(parmas)
      .then(data => {
        if ('error' in data && data.error) {
          this.setState({
            passwordEnabled: true,
            ssoRedirectUrl: '',
          })
        } else {
          this.setState({
            passwordEnabled: false,
            ssoRedirectUrl: data.payload.ssoClientUser.idp_url,
          })
        }
      })
      .catch(error => {
        this.setState({
          passwordEnabled: true,
          ssoRedirectUrl: '',
        })
        console.log('IDP not set up', error)
      })
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handlePasswordDisplay = () => {
    this.setState({ passwordDisplay: !this.state.passwordDisplay })
  }

  onSubmit = (e) => {
    e.preventDefault()
    const {
      userEmail,
      password,
      ssoRedirectUrl,
    } = this.state

    this.setState({ isLoading: true })
    if (ssoRedirectUrl) {
      this.props.history.push({ pathname: '/auth/sso-redirect', state: { idp_url: ssoRedirectUrl, userEmail } })
    } else {
      this.props.session.create({ username: userEmail, password: password })
        .then((data) => {
          localStorage.setItem('reggora_lender_auth_token', data.token)
          const redirectLink = getRedirectLink()
          this.props.actions.redirectUrlPostLogin(redirectLink)
          this.props.history.push(redirectLink)
          this.props.actions.hideSwitchAccount()
          window.location.reload()
        })
        .catch(_ => this.setState({ isLoading: false }))
    }
  }

  render() {
    const { ...props } = this.props
    return <ModalWrapper
      {...props}
      {...this.state}
      modalClassName='modal-primary'
      component= {SwitchAccount}
      show={this.props.showModal}
      onSubmit= {this.onSubmit}
      handleChange= {this.handleChange}
      handleKeyPress= {this.handleKeyPress}
      handleSelectChange= {this.handleSelectChange}
      handlePasswordDisplay= {this.handlePasswordDisplay}
    />
  }
}
const mapStateToProps = (state) => ({
  showModal: state.userProfile.showSwitchAccountModal,
})

const mapDispatchToProps = (dispatch) => {
  return {
    postSubmit: setData,
    actions: bindActionCreators({
      hideSwitchAccount,
      fetchSsoClientUser,
      redirectUrlPostLogin,
    }, dispatch),
  }
}

export default compose(
  connectResource({
    namespace: 'session',
    endpoint: 'auth',
    prefetch: false,
    apiVersion: 2,
    includeQueryParams: true,
    filters: {
      lender: '',
    },
  }),
  connectResource({
    namespace: 'lenderSelect',
    endpoint: 'lender/lender-select-options',
    prefetch: false,
    list: true,
    apiVersion: 2,
    filters: {
      email: '',
    },
  }),
  connect(mapStateToProps, mapDispatchToProps),
)(withRouter(SwitchAccountContainer))
