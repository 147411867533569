import React, { useState, useEffect } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import classnames from 'classnames'
import { Menu, Tag } from 'antd'
import './SearchSuggestions.scss'

/*
Suggestions constants - This is the list of the supported field specific search.

Each item should contain:
- keyward - the actual keyword used before in the serach term before colon
- helpText - a callback to render the help text.  It is called with the search term value.

When more fields are supported by the order search, they should be aded here.
*/
let suggestions = [
  {
    keyword: 'Borrower',
    helpText: (value) => `Borrower names that contain "${value}"`,
  },
  {
    keyword: 'CoBorrower',
    helpText: (value) => `Co-Borrower names that contain "${value}"`,
  },
  {
    keyword: 'Address',
    helpText: (value) => `Order addresses contain "${value}"`,
  },
  {
    keyword: 'City',
    helpText: (value) => `City names that contain "${value}"`,
  },
  {
    keyword: 'State',
    helpText: (value) => `State names that contain "${value}"`,
  },
  {
    keyword: 'Zip',
    helpText: (value) => `Zip codes that contain "${value}"`,
  },
  {
    keyword: 'ID',
    helpText: (value) => `Order IDs that contain "${value}"`,
  },
]

/*
Search Suggestions Component - It renders a menu of suggestion items for the supported field specific search on orders.
Each item displays the keyword and a help text to explain the the targeted field  search.

Props
- menuId (string) - the id and test id for the `ul` html element of the suggestion menu.
- searchTerm (string) - the current focused search term.
- quote (string) - the quote, if any, used to enclose the search term.
- keyword (string) - the field keyword parsed from the search term if any.
- updateTerm ((updatedTerm) => {}) - The callback when a specfic targeted field is selected. It is called with the updated term.
- onMouseLevel (() => {}) - The callback when mouse leave the suggestion menu.
- onEnter ((searchTerm) => {}) - The callback when user press "Enter" while the menu is on focus. Called with the current search term.
- onBlur ((event) => {}) - The callback when the menu experiences a blur event.
- commonSearchClass (string) - A class name that will be applied to the menu and its items.
*/
const SearchSuggestions = ({
  menuId = '',
  searchTerm = '',
  quote = '',
  keyword = '',
  updateTerm = (updatedTerm) => {},
  onMouseLeave = () => {},
  onEnter = () => {},
  onBlur = () => {},
  commonSearchClass = '',
  searchSuggestions = [],
}) => {
  const { useImprovedLoanSearch } = useFlags() || {}
  const [selectedIndex, setSelectedIndex] = useState(-1)

  if (useImprovedLoanSearch) {
    suggestions = searchSuggestions
  }

  useEffect(() => {
    const newIndex = keyword ? suggestions.findIndex(item => item.keyword.toLowerCase() === keyword.toLowerCase()) : -1
    if (newIndex !== selectedIndex) {
      setSelectedIndex(newIndex)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword])

  if (!searchTerm) { return null }

  const getLineItem = (item) => (<><Tag>{`${item.keyword}:`}</Tag> {item.helpText(searchTerm)}</>)

  const handleSelect = (index) => {
    if (index >= 0) {
      const item = suggestions[index]
      const forceQuote = quote || '"'
      updateTerm(`${item.keyword}:${forceQuote}${searchTerm}${forceQuote}`)
    } else {
      updateTerm(`${quote}${searchTerm}${quote}`)
    }
    setSelectedIndex(index)
  }

  const adjustSelectedIndex = (value) => {
    let newIndex = selectedIndex + value
    if (newIndex < -1) {
      newIndex = suggestions.length - 1
    } else if (newIndex >= suggestions.length) {
      newIndex = -1
    }
    handleSelect(newIndex)
  }

  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      onEnter(searchTerm)
    }
    if (event.key === 'ArrowUp') {
      adjustSelectedIndex(-1)
    }
    if (event.key === 'ArrowDown') {
      adjustSelectedIndex(1)
    }
  }

  return (
    <Menu
      id={menuId}
      data-testid={menuId}
      className={`searchSuggestionsMenu ${commonSearchClass}`}
      selectable={false}
      onMouseLeave={onMouseLeave}
      onMouseEnter={() => document.getElementById(menuId).focus()}
      onKeyUp={handleKeyUp}
      onBlur={onBlur}
    >
      {suggestions.map((item, index) => (
        <Menu.Item
          className={classnames({ 'ant-menu-item-selected': index === selectedIndex }, commonSearchClass)}
          key={item.keyword}
          onClick={() => onEnter(searchTerm)}
          onMouseEnter={() => { handleSelect(index) }}
        >
          {getLineItem(item)}
        </Menu.Item>
      ))}
    </Menu>
  )
}

export default SearchSuggestions
