import { of } from 'rxjs/observable/of'

import {
  setData,
  SET_DATA,
} from '../../common/utils/resource'

export default function updateBranchZone(action$, store, { API }) {
  return action$.ofType(SET_DATA).mergeMap(action => {
    if (action.meta && action.meta.resource && action.meta.resource.namespace === 'updateBranchZone') {
      const oldData = action.meta.props.branch.data
      const newZone = action.payload.zones[0]
      const updatedZoneIndex = oldData.zones.findIndex(i => i.id === newZone.id)
      const newZones = [
        ...oldData.zones.slice(0, updatedZoneIndex),
        newZone,
        ...oldData.zones.slice(updatedZoneIndex + 1),
      ]
      const newData = { ...oldData, zones: newZones }
      return of(setData(newData, { resource: { namespace: 'branch' } }))
    } else {
      return []
    }
  })
};
