import React from 'react'
import { Tag, Dropdown, Menu, Popconfirm } from 'antd'
import { LoadingOutlined, DownCircleOutlined } from '@ant-design/icons'
import { Badge } from 'reactstrap'
const CheckableTag = Tag.CheckableTag


const SingleFilter = ({
  tag,
  isChecked,
  clearable,
  onClear,
  handleChange,
  isLoading,
  currentCount,
}) => {
  let Wrapper = ({ children }) => children
  if (clearable) {
    const menu = (
      <Menu className='clear-dropdown'>
        <Menu.Item
          className='clear-menu-item'
          onClick={() => handleChange(tag, !isChecked)}
          key={`apply-remove-${tag}`}
        >
          {isChecked ? 'Remove' : 'Apply'}
        </Menu.Item>
        <Menu.Item
          className='clear-menu-item'
          key={`clear-${tag}`}
        >
          <Popconfirm
            title={'Are you sure you want to clear your unread messages?'}
            onConfirm={onClear}
            okText='Yes'
            cancelText='No'
          >
            <div className='clear-menu-text'>Clear Unread</div>
          </Popconfirm>
        </Menu.Item>
      </Menu>
    )
    Wrapper = ({ children }) => (
      <Dropdown
        overlay={menu}
      >
        {children}
      </Dropdown>
    )
  }

  return (
    <div key={tag}>
      <Wrapper>
        <CheckableTag
          className='mr-0'
          title={tag}
          key={tag}
          checked={isChecked}
          onChange={checked => handleChange(tag, checked)}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <span className="pr-3 pipeline-view-name">{tag}</span>
          {!isLoading && (currentCount > 0) &&
            <>
              <p className="mb-0">
                {clearable &&
                  <DownCircleOutlined className='clear-icon' />
                }
                <Badge
                  pill color='danger'
                  className='ml-1'
                >
                  {currentCount}
                </Badge>
              </p>
            </>
          }

          {isLoading ? <LoadingOutlined className="ml-1" /> : null}
        </CheckableTag>
      </Wrapper>
    </div>
  )
}

export default SingleFilter
