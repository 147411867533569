import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row, Menu } from 'antd'
import {
  ClockCircleTwoTone,
  RocketTwoTone,
  CloseCircleTwoTone,
  CheckCircleTwoTone,
} from '@ant-design/icons'
import moment from 'moment'

import { apiToReadable } from '../../../common/utils/helpers'
import {
  fetchInspectionSubmissions,
  fetchFanniePropertyStatus,
  resetInspectionSubmissions,
  resetFanniePropertyStatus,
} from '../../../store/reducers/inspection/inspectionSlice'

import {
  selectInspectionSubmissionsData,
  selectIsInspectionSubmissionsLoading,
  selectInspectionSubmissionById,
  selectFanniePropertyStatusData,
  selectIsFanniePropertyStatusLoading,
  selectFanniePropertyStatusError,
} from '../../../store/reducers/inspection/inspectionSelectors'

import Loader from '../../../common/layout/components/Loader'

const GREEN = '#28a745'
const YELLOW = '#ffc107'
const RED = '#dc3545'

const getMenuItemIcon = (submissionStatus) => {
  const map = {
    pending: (
      <ClockCircleTwoTone twoToneColor={YELLOW} />
    ),
    in_progress: (
      <RocketTwoTone twoToneColor={YELLOW} />
    ),
    failed: (
      <CloseCircleTwoTone twoToneColor={RED} />
    ),
    complete: (
      <CheckCircleTwoTone twoToneColor={GREEN} />
    ),
  }
  return map[submissionStatus] || null
}

const PdrSubmissionsView = ({ inspectionId }) => {
  const dispatch = useDispatch()

  const [submissionId, setSubmissionId] = useState('')

  const inspectionSubmissionsData = useSelector(selectInspectionSubmissionsData)
  const isInspectionSubmissionsLoading = useSelector(selectIsInspectionSubmissionsLoading)

  const fanniePropertyStatusData = useSelector(selectFanniePropertyStatusData)
  const isFanniePropertyStatusLoading = useSelector(selectIsFanniePropertyStatusLoading)
  const fanniePropertyStatusError = useSelector(selectFanniePropertyStatusError)
  const submission = useSelector(state => selectInspectionSubmissionById(state, submissionId))

  useEffect(() => {
    const fetchInspectionSubmissionsData = async () => {
      await dispatch(resetInspectionSubmissions())
      await dispatch(fetchInspectionSubmissions(inspectionId))
    }

    if (inspectionId) {
      fetchInspectionSubmissionsData()
    }
  }, [inspectionId, dispatch])

  useEffect(() => {
    if (inspectionSubmissionsData.length > 0) {
      setSubmissionId(inspectionSubmissionsData[0]._id)
    }
  }, [inspectionSubmissionsData])

  useEffect(() => {
    const fetchFanniePropertyStatusData = async () => {
      await dispatch(resetFanniePropertyStatus())
      if (submission.integration_id) {
        await dispatch(fetchFanniePropertyStatus(submission.integration_id))
      }
    }

    if (submission) {
      fetchFanniePropertyStatusData()
    }
  }, [submission, dispatch])

  const renderSubmissions = () => {
    if (isInspectionSubmissionsLoading) {
      return (
        <>
          <Loader />
        </>
      )
    } else if (inspectionSubmissionsData.length === 0) {
      return (
        <>
          <p style={{ textAlign: 'center' }}>No submissions.</p>
        </>
      )
    }

    return (
      <Menu selectedKeys={[submissionId]} style={{ height: '100%' }}>
        {inspectionSubmissionsData.map((submission, index) => (
          <Menu.Item
            key={submission._id}
            onClick={() => setSubmissionId(submission._id)}
            icon={getMenuItemIcon(submission.status)}
          >
            {`Submission ${inspectionSubmissionsData.length - index}`}
          </Menu.Item>
        ))}
      </Menu>
    )
  }

  const renderSubmission = () => {
    if (isInspectionSubmissionsLoading) {
      return (
        <>
          <Loader />
        </>
      )
    } else if (!submission) {
      return null
    }

    return (
      <>
        <p><b>Reggora Submission</b></p>
        <Row>
          <Col span={12}>
            <p><b>Type:</b> {apiToReadable(submission.type)}</p>
            <p><b>Status:</b> {apiToReadable(submission.status)}</p>
            <p><b>Integration ID:</b> {submission.integration_id || 'null'}</p>
            <p><b>Created:</b> {moment(submission.created).format('MM/DD/YYYY')}</p>
          </Col>
          <Col span={12}>
            <p><b>Errors:</b></p>
            {submission.errors?.length > 0 ? (
              <ul>
                {submission.errors.map((error, index) => (
                  <li key={`error-${index}`}>
                    <span>{apiToReadable(error.type)}</span>
                    {error.error_message ? <span>: {error.error_message}</span> : null}
                    {error.response?.validationErrors?.length > 0 ? (
                      <ul>
                        {error.response.validationErrors.map((validationError, index) => (
                          <li key={`error-validationError-${index}`}>
                            JSON Path: {validationError.jsonPath} - {validationError.validationErrorMessage}
                          </li>
                        ))}
                      </ul>
                    ) : null}
                    {error.response?.appraisalRequirementsMessages?.length > 0 ? (
                      <ul>
                        {error.response.appraisalRequirementsMessages.map((appraisalRequirementsMessage, index) => (
                          <li key={`error-appraisalRequirementsMessage-${index}`}>
                            Code:
                            {appraisalRequirementsMessage.appraisalRequirementsCode} -
                            &nbsp;{appraisalRequirementsMessage.appraisalRequirementsDescription}
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </li>
                ))}
              </ul>
            ) : <p>None</p>}
          </Col>
        </Row>
      </>
    )
  }

  const renderFanniePropertyData = () => {
    if (isFanniePropertyStatusLoading) {
      return (
        <>
          <Loader />
        </>
      )
    } else if (fanniePropertyStatusError) {
      return (
        <>
          <hr />
          <p style={{ textAlign: 'center' }}>Error fetching Fannie submission.</p>
        </>
      )
    } else if (!fanniePropertyStatusData.inspectionId) {
      return null
    }

    return (
      <>
        <hr />
        <p><b>Fannie Submission</b></p>
        <Row>
          <Col span={12}>
            <p><b>Inspection ID:</b> {fanniePropertyStatusData.inspectionId}</p>
            <p><b>Status:</b> {fanniePropertyStatusData.status}</p>
            <p><b>Appraisal Requirements Messages:</b></p>
            {fanniePropertyStatusData.appraisalRequirementsMessages?.length > 0 ? (
              <ul>
                {fanniePropertyStatusData.appraisalRequirementsMessages.map((appraisalRequirementsMessage, index) => (
                  <li key={`appraisalRequirementsMessage-${index}`}>
                    Code:
                    {appraisalRequirementsMessage.appraisalRequirementsCode} -
                    &nbsp;{appraisalRequirementsMessage.appraisalRequirementsDescription}
                  </li>
                ))}
              </ul>
            ) : <p>None</p>}
          </Col>
          <Col span={12}>
            <p><b>Validation Errors:</b></p>
            {fanniePropertyStatusData.validationErrors?.length > 0 ? (
              <ul>
                {fanniePropertyStatusData.validationErrors.map((validationError, index) => (
                  <li key={`validationError-${index}`}>
                    JSON Path: {validationError.jsonPath} - {validationError.validationErrorMessage}
                  </li>
                ))}
              </ul>
            ) : <p>None</p>}
          </Col>
        </Row>
      </>
    )
  }

  return (
    <>
      <Row>
        <Col span={6}>
          {renderSubmissions()}
        </Col>
        <Col span={18} style={{ paddingLeft: '1rem' }}>
          {renderSubmission()}
          {renderFanniePropertyData()}
        </Col>
      </Row>
    </>
  )
}

export default PdrSubmissionsView
