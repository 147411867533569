// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tableRow_cceb55.ant-table-row td.ant-table-cell:first-child {\n  border-left: 10px solid transparent;\n}\n\n.tableRowChanged_3790a3.ant-table-row td.ant-table-cell:first-child {\n  border-left: 10px solid #21a8d8;\n}\n", "",{"version":3,"sources":["webpack://src/app/pages/orders/SubmissionsTab/ReggoraReview/ResultTable/ResultTable.module.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;AACrC;;AAEA;EACE,+BAA+B;AACjC","sourcesContent":[".tableRow:global(.ant-table-row) td:global(.ant-table-cell):first-child {\n  border-left: 10px solid transparent;\n}\n\n.tableRowChanged:global(.ant-table-row) td:global(.ant-table-cell):first-child {\n  border-left: 10px solid #21a8d8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableRow": "tableRow_cceb55",
	"tableRowChanged": "tableRowChanged_3790a3"
};
export default ___CSS_LOADER_EXPORT___;
