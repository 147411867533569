export const selectSubmissionFilesSlice = state => state.submissionFiles

export const selectSubmissionFiles = state => selectSubmissionFilesSlice(state)?.submissionFiles?.results

export const selectSubmissionFilesByVersion = (state, version) => {
  const files = selectSubmissionFiles(state)
  if (!version) {
    return []
  }
  return files?.filter(
    (file) => file.submission_version === version)
}

export const selectSubmissionFilesByVersionAndType = (state, version, type) => {
  const files = selectSubmissionFilesByVersion(state, version)
  if (!type) {
    return files
  }
  return files?.filter(
    (file) => file.file_type === type)
}
