import {
  SET_MAP_OVERLAYS
} from '../epics/mapOverlays'

const defaultState = {
  countyOverlay: {},
  zipOverlay: {}
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case SET_MAP_OVERLAYS:
      return {
        ...state,
        countyOverlay: action.countyOverlay,
        zipOverlay: action.zipOverlay
      }
    default:
      return state
  }
}
