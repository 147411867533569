import CheckableTag from 'antd/lib/tag/CheckableTag'
import React from 'react'

/**
 * @param {{
 *  availableTags?: {id: string, label: string}[],
 *  onChange: (i: string, checked: boolean) => void,
 *  selectedTags?: string[],
 * }} props
 * return {JSX.Element}
 */
export const ResultStatusFilter = ({ availableTags, onChange, selectedTags }) => {
  if (!availableTags || !selectedTags) {
    return null
  }

  return (
    <>
      {availableTags.map((tag) => (
        <CheckableTag
          checked={selectedTags.indexOf(tag.id) > -1}
          className="review-filter-tag"
          key={tag.id}
          onChange={(checked) => onChange(tag.id, checked)}
        >
          {tag.label}
        </CheckableTag>
      ))}
    </>
  )
}
