import React, { Component } from 'react'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
  connectResource,
  nuke
} from '../../../utils/resource'
import {
  viewNotifications,
  setShouldRefreshSingleOrder
} from './actions'

import NotificationsDropdown from './NotificationsDropdown'

class NotificationsDropdownContainer extends Component {
    state = {
      isDropdownOpen: false
    }

    componentDidMount() {
      if(this.props.user_id) {
        this.props.notifications.fetch()
      }
    }

    componentDidUpdate(prevProps) {
      if(prevProps.user_id !== this.props.user_id) {
        this.props.notifications.fetch()
      }
    }

    toggle = () => {
      if (this.state.isDropdownOpen) this.props.actions.viewNotifications()
      this.setState({
        isDropdownOpen: !this.state.isDropdownOpen
      })
    }

    shouldRefreshSingleOrder = () => {
      const {
        actions
      } = this.props

      // clicking a notification on single order page is buggy, so clear the order data
      const nukeOrderPayload = {}
      const nukeOrderMeta = {
        resource: {
          namespace: 'order'
        }
      }
      actions.nuke(nukeOrderPayload, nukeOrderMeta)


      // trigger the order refresh
      actions.setShouldRefreshSingleOrder(true)
    }

    render() {
      const {
        actions,
        ...props
      } = this.props
      const {
        isDropdownOpen
      } = this.state

      return (<NotificationsDropdown
        {...props}
        shouldRefreshSingleOrder={this.shouldRefreshSingleOrder}
        isDropdownOpen={isDropdownOpen}
        onToggle={this.toggle}
        viewNotifications={actions.viewNotifications}
      />)
    }
}

function mapStateToProps(state) {
  const user = state.resource.user
  return {
    user_id: user && user.data ? user.data.id : null
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      viewNotifications,
      setShouldRefreshSingleOrder,
      nuke
    }, dispatch)
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  connectResource(
    {
      namespace: 'notifications',
      endpoint: 'notifications/:user_id?',
      list: true,
      refresh: true,
      async: true,
      // prefetch should be false because we want to wait until we
      // have the user_id
      prefetch: false,
      idKey: 'user_id',
      apiVersion: 2
    }
  )
)(NotificationsDropdownContainer)
