import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

import HeaderDropdown from './HeaderDropdown'
import * as sessionActions from '../../../../store/session'
import { connectResource } from '../../../utils/resource'
import { showSwitchAccount } from '../../../../store/reducers/user/userProfileSlice'

const mapDispatchToProps = {
  logout: sessionActions.logout,
  showSwitchAccountModal: showSwitchAccount,
}


class HeaderDropdownContainer extends Component {
  state = {
    dropdownOpen: false,
  }

  componentDidMount() {
    if (!this.props.lenderSelect.data) {
      if (this.props.user.data?.email && this.props.lenderSelect.filters.email !== this.props.user.data.email) {
        this.props.lenderSelect.filter({ email: this.props.user.data.email })
      }
    }
  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    })
  }

  render() {
    const isSwitchAccountItem = this.props.lenderSelect.data && this.props.lenderSelect.data.length > 1
    const { ...props } = this.props
    return <HeaderDropdown
      {...props}
      dropdownOpen={this.state.dropdownOpen}
      toggle={this.toggle}
      isShowSwitchAccountItem={isSwitchAccountItem}
    />
  }
}

export default compose(
  connectResource({
    namespace: 'lenderSelect',
    endpoint: 'lender/lender-select-options',
    prefetch: false,
    list: true,
    apiVersion: 2,
    filters: {
      email: '',
    },
  }),
  connect(null, mapDispatchToProps),
)(HeaderDropdownContainer)
