import React from 'react'
import { RETAIL, WHOLESALE, ASSIGNED_TO_ME, UNREAD_MESSAGES, UNAPPROVED_MESSAGES, UNANSWERED_MESSAGES, NO_PS_CONTRACTS, FLAGGED } from '../../../common/constants/pipelineViews'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useSelector, useDispatch } from 'react-redux'
import SingleFilter from './SingleFilter'
import {
  selectUserFilterSettings,
  selectCurrentUserId,
} from '../../../store/reducers/user/userSelectors'
import {
  selectFilterData,
  selectFilterDataIsLoading,
} from '../../../store/reducers/orderFilters/orderFiltersSelectors'
import { clearUnreadMessagesPipeline } from '@app/store/reducers/order/orderSlice'

const DefaultFilters = ({
  handleChange,
  selectedDefaultViews,
}) => {
  const dispatch = useDispatch()
  const filterSettings = useSelector(selectUserFilterSettings)
  const filterData = useSelector(selectFilterData) || {}
  const flags = useFlags()
  const unreadForIndividualUser = flags?.unreadForIndividualUser
  const currentUserId = useSelector(selectCurrentUserId)
  const filterDataIsLoading = useSelector(selectFilterDataIsLoading)
  const filterTags = []
  const availableFilterTags = {
    retail: RETAIL,
    wholesale: WHOLESALE,
    assigned_to_me: ASSIGNED_TO_ME,
    unread_messages: UNREAD_MESSAGES,
    unapproved_messages: UNAPPROVED_MESSAGES,
    unanswered_messages: UNANSWERED_MESSAGES,
    no_ps_contracts: NO_PS_CONTRACTS,
    flagged: FLAGGED,
  }
  const onClearFunctions = {
    // Only populate the clear function for the unread messages filter if the user has the flag enabled
    [UNREAD_MESSAGES]: unreadForIndividualUser
      ? () => {
        dispatch(clearUnreadMessagesPipeline(currentUserId))
        // Reload the page to ensure the order count is updated
        // as refreshing orders will sometimes finish before the count is updated.
        window.location.reload()
      }
      : null,
  }

  // Dynamically create the filter list based on role settings
  for (var filter in filterSettings) {
    if (filterSettings[filter] &&
      filter in availableFilterTags &&
      !filterTags.includes(availableFilterTags[filter])) {
      filterTags.push(availableFilterTags[filter])
    }
  }

  const tagsToProps = {
    [RETAIL]: 'retail_branches_count',
    [WHOLESALE]: 'wholesale_branches_count',
    [ASSIGNED_TO_ME]: 'assigned_to_me_orders_count',
    [UNREAD_MESSAGES]: 'unread_messages_count',
    [UNAPPROVED_MESSAGES]: 'unapproved_messages_count',
    [UNANSWERED_MESSAGES]: 'unanswered_messages_count',
    [NO_PS_CONTRACTS]: 'no_ps_contracts_order_count',
    [FLAGGED]: 'flagged_orders_count',
  }

  return (
    <>
      <h6 className='ml-1 mt-2'>Default Filters</h6>
      {filterTags.map(tag => {
        const currentCount = filterData[tagsToProps[tag]]
        return (
          <SingleFilter
            key={tag}
            tag={tag}
            clearable={!!onClearFunctions[tag] && currentCount > 0}
            onClear={onClearFunctions[tag]}
            isChecked={selectedDefaultViews.indexOf(tag) > -1}
            handleChange={handleChange}
            isLoading={filterDataIsLoading}
            currentCount={currentCount}
          />
        )
      })}
    </>
  )
}

export default DefaultFilters
