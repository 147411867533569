import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { connectResource } from '../../common/utils/resource'
import ModalCreateLoanFile from './ModalCreateLoanFile'
import { convertToUTCDate } from '../../common/utils/helpers'
import ModalConfirmLoanFileChanges from './ModalConfirmLoanFileChanges'
import moment from 'moment'
import { stateShortToFIPS } from '../../../reggora_shared_ui/constants/locations'

class ModalCreateLoanFileContainer extends Component {
  getAppraisalType = (loan) => {
    if (!loan || !loan.appraisal_type) {
      return 'refinance'
    }
    if (['refinance', 'purchase', 'construction'].indexOf(loan.appraisal_type) === -1) {
      return 'other'
    } else {
      return loan.appraisal_type
    }
  }

  getConsumerPaymentStyle = () => {
    if (this.props.loanToEdit) {
      return this.props.loanToEdit.consumer_payment_style
    } else {
      return this.getInitialDefaultPaymentOption()
    }
  }

  // Sets the due date
  setDefaultDueDate = () => {
    if (this.props.loanToEdit) {
      if (this.props.loanToEdit.due_date) {
        this.setState({ due_date: moment(this.props.loanToEdit.due_date) })
        return
      }
    }

    this.props.getDefaultDueDate.filter({
      product_ids: null,
      state: null,
      zip: null,
      priority: null,
      loan_id: null,
    }).then(results => {
      const selectedDate = results.result ? moment(results.result) : moment()
      this.setState({ due_date: selectedDate })
    })
  }

  /**
   * Looks for a default payment override at the Branch Zone level.
   * Branch should match the LO's branch and the property's zip code.
   * If no Branch Zone override is set, returns 'branch_default'
   */
  getZonePaymentMethodAsync = async (selectedOfficer) => {
    if (!this.state.subject_property_zip) {
      return 'branch_default'
    }

    const lenderZones = await this.props.lenderZones.filter({
      branch_id: selectedOfficer.loan_officer_branch_id,
      zip: this.state.subject_property_zip,
      include_panelists: false, // The panelist info is unnecessary and expensive to fetch
    })

    for (const zone of lenderZones) {
      // Loop through branch_settings for a matching branch_id with the LO
      const matchedBranchSetting = zone.branch_settings
        .find((branch) => branch.branch_id === selectedOfficer.loan_officer_branch_id)

      // zone_payment_method = closing, ordering, internal or branch_default
      return matchedBranchSetting ? matchedBranchSetting.zone_payment_method : 'branch_default'
    }

    return 'branch_default' // If no matching zone is found, use the 'branch_default'
  }

  getInitialDefaultPaymentOption = () => {
    const { consumer_payment_options } = this.props

    let defaultPaymentOption = 'ordering'

    if (consumer_payment_options && consumer_payment_options.length > 0) {
      defaultPaymentOption = consumer_payment_options[0]
    }
    return defaultPaymentOption
  }

  /*
  * Returns the default payment option
  */
  getDefaultPaymentOption = () => {
    const { should_show_consumer_payment_style_field } = this.props
    const { officer } = this.state

    let defaultPaymentOption = 'closing' // DEFAULT is closing
    let selectedOfficer = null

    if (should_show_consumer_payment_style_field) { // If the lender-level master switch is on
      // TODO: We should move this default logic to pull from the appopriate place in the API
      defaultPaymentOption = this.getInitialDefaultPaymentOption() // Lender level switch on means collect before ordering

      if (officer) { // If an officer is selected
        selectedOfficer = this.getOfficerObjectById(officer) // Get the selected officer
        if (selectedOfficer && (selectedOfficer.branch_payment_method !== 'lender_default')) { // If branch isn't set to lender default
          defaultPaymentOption = selectedOfficer.branch_payment_method // Either closing or ordering
        }
        this.getZonePaymentMethodAsync(selectedOfficer).then(zone_payment_method => {
          if (zone_payment_method && (zone_payment_method !== 'branch_default')) { // If zone_payment_method exists AND isn't branch_default
            defaultPaymentOption = zone_payment_method
          }
          this.setState({ consumer_payment_style: defaultPaymentOption })
        })
        return
      }
    }
    this.setState({ consumer_payment_style: defaultPaymentOption })
  }

  getOfficerObjectById = (id) => {
    const { loanOfficers } = this.props
    let selectedOfficer

    Object.keys(loanOfficers).forEach(key => {
      const loanOfficer = loanOfficers[key]

      if (loanOfficer && loanOfficer.id === id) {
        selectedOfficer = loanOfficer
      }
    })
    return selectedOfficer
  }

  state = {
    collapse: false,
    appraisal_type: this.getAppraisalType(this.props.loanToEdit),
    appraisal_type_other: this.props.loanToEdit && this.props.loanToEdit.appraisal_type && ['refinance', 'purchase', 'construction'].indexOf(this.props.loanToEdit.appraisal_type) === -1 ? this.props.loanToEdit.appraisal_type : null,
    confirmStep: false,
    disabled: false,
    loanObjPreCheck: {},
    number: this.props.loanToEdit ? this.props.loanToEdit.number : '',
    subject_property_state: this.props.loanToEdit ? this.props.loanToEdit.subject_property_state : '',
    subject_property_address: this.props.loanToEdit ? this.props.loanToEdit.subject_property_address : '',
    subject_property_city: this.props.loanToEdit ? this.props.loanToEdit.subject_property_city : '',
    subject_property_zip: this.props.loanToEdit ? this.props.loanToEdit.subject_property_zip : '',
    selectedCounty: this.props.loanToEdit ? this.props.loanToEdit.coverage_county_fips : '',
    selectedStateFips: this.props.loanToEdit ? stateShortToFIPS[this.props.loanToEdit.subject_property_state] : '',
    counties: null,
    due_date: moment(),
    loan_type: this.props.loanToEdit ? this.props.loanToEdit.loan_type : '',
    officer: this.props.loanToEdit && this.props.loanToEdit.officer ? this.props.loanToEdit.officer.id : null,
    case_number: this.props.loanToEdit ? this.props.loanToEdit.case_number : '',
    consumer_payment_style: this.getConsumerPaymentStyle(),
    is_va_loan: this.props.loanToEdit ? this.props.loanToEdit.is_va_loan : false,
  }

  UNSAFE_componentWillUpdate(nextProps) {
    // If we finished the loan update action, and it didnt pass back a loan object with an id, then we can assume there is a diff
    // object and we are going to show the user a modal to confirm the changes that are about to happen.
    if (this.props.loanUpdate.isLoading && !nextProps.loanUpdate.isLoading) {
      if (nextProps.loanUpdate.errors && nextProps.loanUpdate.errors.error) {
        // We dont really have to explicitly do anything with this error case, the handler will pick it up
      } else {
        if (!nextProps.loanUpdate.data || !nextProps.loanUpdate.data.id) {
          this.setState({
            confirmStep: true,
          })
        } else {
          if (this.props.refreshOrder) {
            this.props.refreshOrder()
          } else {
            this.props.loan.fetch()
          }
        }
      }
    }
  }

  componentDidMount() {
    // account for both new loan and edit loan
    var loan_ids = JSON.stringify(this.props.loanToEdit ? [this.props.loanToEdit.id] : null)
    this.props.consumerPaymentOptions
      .filter({ loan_ids: loan_ids, branch_ids: null, order_ids: null })
      .then(response => {
        this.setState({
          consumerPaymentOptions: response[0],
        })
      })
    this.setDefaultDueDate()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.subject_property_state !== this.state.subject_property_state) {
      this.setState({
        selectedCounty: null,
      })
    }
  }

  onSubmit = () => {
    const {
      loanToEdit,
    } = this.props
    let {
      appraisal_type,
      appraisal_type_other,
      number,
      subject_property_address,
      subject_property_city,
      subject_property_zip,
      subject_property_state,
      selectedCounty,
      selectedStateFips,
      due_date,
      loan_type,
      case_number,
      officer,
      consumer_payment_style,
      is_va_loan,
    } = this.state

    const coverage_county_fips = selectedCounty

    if (due_date) {
      due_date = convertToUTCDate(due_date)
    } else {
      due_date = convertToUTCDate(moment())
    }

    if (appraisal_type === 'other') { appraisal_type = appraisal_type_other }

    const loanObj = {
      number,
      appraisal_type,
      subject_property_address,
      subject_property_city,
      subject_property_zip,
      subject_property_state,
      coverage_county_fips,
      coverage_state_fips: selectedStateFips,
      due_date,
      loan_type,
      case_number,
      officer,
      consumer_payment_style,
      is_va_loan,
    }
    if (loanToEdit) {
      this.setState({
        loanObjPreCheck: loanObj,
      })
      this.updateLoan({ ...loanObj, check: true })
    } else {
      this.props.loansCreate.create(loanObj).then(_ => {
        this.props.onHide()
        this.props.loans.fetch()
      })
    }
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse })
  }

  onAppraisalTypeChange = (e) => {
    this.setState({
      appraisal_type_other: e.target.value,
    })
  }

  selectAppraisalType = ({ value: appraisal_type }) => this.setState({ appraisal_type })
  updateStateFips = (selectedStateFips) => this.setState({ selectedStateFips })
  update_loan_type = (loan_type) => {
    if (loan_type === 'VA' || loan_type === 'va') {
      this.setState({ is_va_loan: true })
    } else {
      this.setState({ is_va_loan: false })
    }

    if (loan_type === 'FHA' || loan_type === 'fha') {
      this.setState({ is_fha_loan: true })
    } else {
      this.setState({ is_fha_loan: false })
    }
  }

  handleSelect = (name, value) => {
    this.setState({ [name]: value },
      () => {
        if (name === 'officer') {
          this.getDefaultPaymentOption()
        }
        if (name === 'subject_property_state') {
          this.updateStateFips(stateShortToFIPS[value])
        }
        if (name === 'loan_type') {
          this.update_loan_type(value)
        }
      })
  }


  handleChange = ({ target: { value, name } }) => {
    this.setState({ [name]: value },
      () => {
        if (name === 'subject_property_zip') {
          this.getDefaultPaymentOption()
        }
      })
  }


  handleCheck = ({ target: { name, checked } }) => this.setState({ [name]: checked })

  updateLoan = (loanObj) => {
    const {
      loanUpdate,
    } = this.props

    // indicate that we don't need the endpoint to return the serialized loan
    loanUpdate.put({ ...loanObj, return_loan_info: false })
  }

  confirmOnSubmit = () => {
    const { loanObjPreCheck } = this.state
    this.updateLoan(loanObjPreCheck)
  }

  render() {
    const { ...props } = this.props
    const {
      confirmStep,
      number,
      subject_property_state,
      subject_property_address,
      subject_property_city,
      subject_property_zip,
      due_date,
    } = this.state

    const disabled = !number ||
      !subject_property_state ||
      !subject_property_address ||
      !subject_property_city ||
      !subject_property_zip ||
      !(due_date && due_date.isValid())

    if (confirmStep) {
      return <ModalConfirmLoanFileChanges {...props} onSubmit={this.confirmOnSubmit} due_date={props.order_due_date} />
    }
    return <ModalCreateLoanFile
      {...props }
      {...this.state}
      disabled={disabled}
      toggle={this.toggle}
      onSubmit={this.onSubmit}
      addConsumer={this.addConsumer}
      handleCheck={this.handleCheck}
      handleChange={this.handleChange}
      handleSelect={this.handleSelect}
      removeConsumer={this.removeConsumer}
      selectAppraisalType={this.selectAppraisalType}
      onAppraisalTypeChange={this.onAppraisalTypeChange}
      consumerPaymentOptions={this.state.consumerPaymentOptions ? this.state.consumerPaymentOptions : []}
      consumer_payment_style={
        this.state.consumer_payment_style
          ? this.state.consumer_payment_style
          : this.props.consumer_payment_style
      }
    />
  }
}


function mapStateToProps(state) {
  return {
    should_show_consumer_payment_style_field: state.resource.user.data.lender.should_show_consumer_payment_style_field,
    consumer_payment_options: state.resource.user.data.lender.consumer_payment_options,
  }
}

export default compose(
  withLDConsumer(),
  connect(mapStateToProps, null),
  connectResource({
    namespace: 'loansCreate',
    endpoint: 'loans/full/',
    prefetch: false,
    apiVersion: 2,
    successMessage: {
      POST: 'Your loan has successfully been created.',
      PUT: 'Your loan has been updated.',
    },
  }),
  connectResource({
    namespace: 'loanUpdate',
    endpoint: 'loans/:loanId?',
    apiVersion: 2,
    prefetch: false,
  }),
  connectResource({
    namespace: 'loanOfficers',
    endpoint: 'users/officers',
    list: true,
    refresh: true,
    async: true,
    // useRouter: true,
    apiVersion: 2,
  }),
  connectResource({
    namespace: 'consumerPaymentOptions',
    endpoint: 'order/payment-options',
    prefetch: false,
    list: true,
    apiVersion: 2,
  }),
  connectResource({
    namespace: 'lenderZones',
    endpoint: 'lender/zones',
    apiVersion: 2,
    prefetch: false,
    list: true,
  }),
  connectResource({
    namespace: 'getDefaultDueDate',
    endpoint: 'fee/getDefaultDueDate',
    list: true,
    prefetch: false,
    async: false,
    apiVersion: 2,
  }),
)(ModalCreateLoanFileContainer)
