import { detect } from 'detect-browser'
import { ChromeOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import loadable from "@loadable/component";

const browser = detect()
const browserName = browser && browser.name
const browserVersion = browser && browser.version && browser.version.split('.')[0]

// Suported Browsers
// - Chrome
// - IE 11
// - Edge 15, 16, 17, 18

// Not Supported Browsers
// - Edge 14
// - IE 10 <=

export const fetchCrossBrowserCSS = () => {
  switch (browserName) {
    case 'ie':
      import('./styles/ie.scss')
      break
    case 'edge':
      import('./styles/edge.scss')
      break

    default:
      break
  }
}

export const isBadBrowser =
  (browserName === 'edge' && browserVersion === '14') ||
  (browserName === 'ie' && browserVersion !== '11')

export const renderBadBrowserMessage = (
  <div className='browser-support-message'>
    Sorry, your browser is not supported.
    <div className='browser-info'>
      You may be using an outdated browser, or one that does not support the modern web functionalities.
    </div>
    <div className='browser-info'>
      <Button type='primary' className='download-chrome' onClick={() => window.open('https://www.google.com/chrome/browser/features.html')}>
        <ChromeOutlined />
        Download Chrome
      </Button>
    </div>
  </div>
)
