import { logoutEpic, notFoundEpic } from '../pages/auth'
import {
  checkActiverEpic,
  afterSetActivateEpic
} from './session'
import regularEpics from './epics'
import reducers from './reducers'

const epics = [
  ...regularEpics,
  logoutEpic,
  notFoundEpic,
  checkActiverEpic,
  afterSetActivateEpic
]
export {
  epics,
  reducers
}
