import PropTypes from 'prop-types'
import React, { PureComponent, Children, cloneElement } from 'react'

import ModalWrapper from './ModalWrapper'

const propTypes = {
  children: PropTypes.object,
}

export default class ModalTrigger extends PureComponent {
  state = {
    toggled: false,
  }

  open = (e) => {
    e.stopPropagation()
    e.preventDefault()
    this.setState({ toggled: true })
    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  close = () => {
    this.setState({ toggled: false })
  }

  render() {
    const { children, ...restProps } = this.props

    // ensure that we have only one child (control element)
    const child = cloneElement(Children.only(children), {
      onClick: this.open,
      key: 'modal-control',
    })

    return [
      child,
      <ModalWrapper
        {...restProps}
        key="modal-dialog"
        onHide={this.close}
        show={this.state.toggled}
      />,
    ]
  }
}

ModalTrigger.propTypes = propTypes
