import React from 'react'
import { Table, Collapse } from 'antd'
import './style.css'

const OrderActivityLog = ({ auditLogData }) => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name_of_user',
      className: 'activity-log-info-cell',
      filters: [
        ...new Set(auditLogData.map(auditLog => auditLog.name_of_user)),
      ].map(name => ({ text: name, value: name })),
      filterMultiple: true,
      onFilter: (value, record) => record.name_of_user.includes(value),
      sorter: (a, b) => a.name_of_user.localeCompare(b.name_of_user),
      sortDirections: ['descend', 'ascend'],
      ellipsis: true,
    },
    {
      title: 'Change Time',
      dataIndex: 'change_time',
      className: 'activity-log-info-cell',
      sorter: (a, b) => new Date(a.change_time) - new Date(b.change_time),
      sortDirections: ['descend', 'ascend'],
      render: change_time =>
        typeof change_time === 'object'
          ? new Date(change_time).toLocaleString()
          : change_time,
      ellipsis: true,
    },
    {
      title: 'Attribute Name',
      dataIndex: 'display_name',
      className: 'activity-log-info-cell',
      filters: [
        ...new Set(auditLogData.map(auditLog => auditLog.display_name)),
      ].map(name => ({ text: name, value: name })),
      filterMultiple: true,
      onFilter: (value, record) => record.display_name.includes(value),
      ellipsis: true,
    },
    /* eslint-disable react/display-name */
    {
      title: 'New Value',
      dataIndex: 'new_value',
      className: 'activity-log-value-cell',
      width: '25%',
      render: new_value => (
        <div style={{ overflowX: 'auto' }}>
          <Collapse>
            <pre>{JSON.stringify(new_value, null, 2)}</pre>
          </Collapse>
        </div>
      ),
    },
    {
      title: 'Old Value',
      dataIndex: 'previous_value',
      className: 'activity-log-value-cell',
      render: previous_value => (
        <div style={{ overflowX: 'auto' }}>
          <Collapse>
            <pre>{JSON.stringify(previous_value, null, 2)}</pre>
          </Collapse>
        </div>
      ),
    },
    /* eslint-disable react/display-name */
  ]

  return (
    <div className='order-activity-log'>
      {auditLogData &&
            <Table columns={columns} dataSource={auditLogData} />
      }
    </div>
  )
}

export default OrderActivityLog
