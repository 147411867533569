import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { connectResource } from '../../common/utils/resource'
import { ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import ToggleSwitchInput from '../../common/forms/inputs/ToggleSwitchInput'
import Loader from '../../common/layout/components/Loader'
import { ellieMaeRequest } from '../../common/utils/helpers'

class ModalEncompassLoanSettings extends Component {
    state = {
      initDataLoad: true,
      isloanReplication: false,
      isPushToEncompassInitiated: false,
    }

    componentDidUpdate() {
      if (this.state.initDataLoad && this.props.loanReplicationStatus.data) {
        this.setState({
          initDataLoad: false,
          isloanReplication: this.props.loanReplicationStatus.data.loan_replication,
        })
      }
    }


    // Clear the props data
    componentWillUnmount() {
      if (this.props.loanReplicationStatus) {
        this.props.loanReplicationStatus.nuke()
      }
    }

    onLoanReplicationChanged = (value) => {
      this.setState({ isloanReplication: value })
    }

    onSubmit = () => {
      this.props.loanReplicationStatus.put({ loan_replication: this.state.isloanReplication })
        .then(() => this.props.onHide())
    }

    onPushToEncompass = async () => {
      this.setState({ isPushToEncompassInitiated: true })
      await ellieMaeRequest(`loan/${this.props.loanId}/export`, 'post')
    }

    render() {
      const { permissions } = this.props
      const { isPushToEncompassInitiated } = this.state

      const loader = this.props.loanReplicationStatus.isLoading && <Loader/>

      const body = !this.props.loanReplicationStatus.isLoading && <div>
        {permissions.encompass_loan_replication &&
        <div className="row">
          <div className="col-10" style={{ fontWeight: 'bold' }}>Stop data from being pushed to Encompass:</div>
          <div className="col-2" style={{ paddingTop: '4px' }}>
            <ToggleSwitchInput
              value = {this.state.isloanReplication}
              onToggleChange = { value => this.onLoanReplicationChanged(value) }
            >
            </ToggleSwitchInput>
          </div>
        </div>}
      </div>

      const actionsBody = permissions.encompass_manual_loan_push && <ModalBody>
        <hr/>
        <div>
          <Button
            onClick={this.onPushToEncompass}
            disabled={isPushToEncompassInitiated}
          >
            {isPushToEncompassInitiated ? 'Request sent. This may take a few minutes.' : 'Push Latest Data/Documents to Encompass Now'}
          </Button>
        </div>
      </ModalBody>

      return (
        <Fragment>
          <ModalHeader toggle={this.props.onHide}>Encompass Settings</ModalHeader>
          <ModalBody>
            {loader}
            {body}
          </ModalBody>
          <ModalFooter className="border-0">
            <Button onClick={this.props.onHide} >Cancel</Button>
            <Button onClick={this.onSubmit} data-test="submit-button" color='primary'>Save</Button>
          </ModalFooter>
          {actionsBody}
        </Fragment>
      )
    }
}

export default compose(
  connectResource({
    namespace: 'loanReplicationStatus',
    endpoint: 'loans/encompass/replication/:loanId?',
    async: true,
    prefetch: true,
    refresh: true,
    apiVersion: 2,
    successMessage: { PUT: 'Your loan file has been updated.' },
  }),
)(ModalEncompassLoanSettings)
