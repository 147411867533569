import React, {
  Fragment,
  useCallback,
  useEffect,
  useState,
} from 'react'
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import Loader from 'app/common/layout/components/Loader'
import { getSortedList, UP } from 'app/common/widgets/Sort'
import { updateOrderAssigned } from 'app/store/reducers/order/orderSlice'
import { fetchAssignableUsers } from 'app/store/reducers/user/usersSlice'
import {
  selectOrderAssignedErrorStatus,
  selectOrderAssignedStatus,
  selectRemainingOrderStatus,
} from 'app/store/reducers/order/orderSelectors'
import {
  selectFetchAssignableUsersStatus,
  selectAssignableUsers,
} from 'app/store/reducers/user/userSelectors'
import STATUS from 'app/common/constants/reducer_statuses'
import EditUserList from './EditUserList'


const getIDsFromUserList = (userList) => {
  if (!userList) {
    return []
  }
  return userList.map(p => p?.id)
}

const getErrorTextFromHTTPStatus = (httpErrorStatus) => {
  if (httpErrorStatus === 409) {
    return 'We cannot take this action because your order data is currently in process. Please refresh the page to view assigned users and re-attempt any changes.'
  } else {
    return 'We are sorry but we could not complete this action. If this problem persists, please contact us.'
  }
}

/**
 * Improved Modal Component for editing the assigned list of Lender Users on an Order.
 */
const EditAssignedModal = ({
  id,
  onHide,
  orderData,
  refreshOrder,
}) => {
  const dispatch = useDispatch()

  const orderAssignedStatus = useSelector(selectOrderAssignedStatus)
  const orderAssignedErrorStatus = useSelector(selectOrderAssignedErrorStatus)
  const fetchAssignableUsersStatus = useSelector(selectFetchAssignableUsersStatus)
  const assignableUsers = useSelector(selectAssignableUsers)
  const remainingOrderStatus = useSelector(selectRemainingOrderStatus)

  const [selectedAssigned, setSelectedAssigned] = useState([null])
  const [selectedAssignedIds, setSelectedIDs] = useState([null])
  const [autoFocus, setAutoFocus] = useState(false)

  const errorText = orderAssignedErrorStatus ? getErrorTextFromHTTPStatus(orderAssignedErrorStatus) : null


  useEffect(() => {
    // selected users should be based on assigned list from Order
    let newUserList
    if (orderData.assigned?.length) {
      newUserList = getSortedList(orderData.assigned, 'fullname', UP)
    } else {
      newUserList = [null]
    }
    const newIDList = getIDsFromUserList(newUserList)
    setSelectedAssigned(newUserList)
    setSelectedIDs(newIDList)
  }, [orderData.assigned])


  useEffect(() => {
    // fetch assignable users when component first renders
    dispatch(fetchAssignableUsers(id))
  }, [dispatch, id])

  useEffect(() => {
    // after successful update we reload Order
    if (orderAssignedStatus === STATUS.SUCCEEDED) {
      refreshOrder()
    }
  }, [orderAssignedStatus, refreshOrder])


  const submitRequest = useCallback(
    (kwargs) => {
      dispatch(updateOrderAssigned(kwargs))
    },
    [dispatch]
  )


  const onSubmit = () => {
    const previouslyAssignedIDs = getIDsFromUserList(orderData.assigned)
    const submitArgs = {
      assigned: selectedAssignedIds.filter(id => id),
      previously_assigned: previouslyAssignedIDs || [],
      order_id: orderData.id,
    }
    submitRequest(submitArgs)
  }


  const onAddAssignedRow = () => {
    // add another select input, initially no user selected, focus new input
    setSelectedAssigned([...selectedAssigned, null])
    setSelectedIDs([...selectedAssignedIds, null])
    setAutoFocus(true)
  }


  const onSelectUser = (value, key) => {
    if (selectedAssignedIds.includes(value.id)) {
      // new user was already previously selected, no action to take
      return
    }

    const newUserList = [...selectedAssigned]
    const newIDList = [...selectedAssignedIds]
    newUserList[key] = value
    newIDList[key] = value.id

    setSelectedAssigned(newUserList)
    setSelectedIDs(newIDList)
  }


  const onRowRemove = (key) => {
    // removing row to deselect a user
    const newUserList = [...selectedAssigned]
    newUserList.splice(key, 1)
    if (newUserList.length === 0) {
      newUserList.push(null)
    }
    const newIDList = getIDsFromUserList(newUserList)
    setSelectedAssigned(newUserList)
    setSelectedIDs(newIDList)
  }

  const allUsers = assignableUsers
  let unselectedUsers = []
  if (allUsers) {
    unselectedUsers = allUsers.filter(user => !selectedAssignedIds.includes(user.id))
  }

  const isDataLoading = (
    fetchAssignableUsersStatus === STATUS.LOADING ||
    orderAssignedStatus === STATUS.LOADING ||
    remainingOrderStatus === STATUS.LOADING
  )
  const showError = !isDataLoading && errorText
  const showEditUI = !isDataLoading && !showError
  const disableCancelButton = isDataLoading
  const showSubmitButton = showEditUI
  return (
    <Fragment>
      <ModalHeader>Order Assigned</ModalHeader>
      <ModalBody>
        {isDataLoading && (
          <Loader className="m-5" />
        )}
        {showError && (
          <div>
            {errorText}
          </div>
        )}
        {showEditUI && (
          <EditUserList
            onAddAssignedRow={onAddAssignedRow}
            onRowRemove={onRowRemove}
            onSelectUser={onSelectUser}
            selectedAssigned={selectedAssigned}
            selectedAssignedIds={selectedAssignedIds}
            showAddButton={selectedAssigned[0] && unselectedUsers.length > 0}
            unselectedUsers={unselectedUsers}
            autoFocus={autoFocus}
          />
        )}
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button disabled={disableCancelButton} onClick={onHide}>Cancel</Button>
        {showSubmitButton && <Button onClick={onSubmit} color="primary">Confirm</Button>}
      </ModalFooter>
    </Fragment>
  )
}


export default EditAssignedModal
