import NotFound from './pages/fallbacks/NotFound'
import { routes as dashboard } from './pages/dashboard'
import { routes as loans } from './pages/loans'
import { routes as orders } from './pages/orders'
import { routes as users } from './pages/users'
import { routes as appraisers } from './pages/appraisers'
import { routes as profile } from './pages/profile'
import { routes as auth } from './pages/auth'
import { routes as branches } from './pages/branches'
import { routes as fee } from './pages/fee'
import { routes as activate } from './pages/activate'
import { routes as adminSettings } from './pages/adminSettings'
import { routes as review } from './pages/reviewRules'
import { routes as accounting } from './pages/accounting'
import { routes as allNotifications } from './pages/notifications'
import { routes as permissions } from './pages/permissions'
import { routes as reviewSettings } from './pages/reviewSettings'


import EmptyLoyout from './common/layout/EmptyLoyout'
import BasicLayoutContainer from './common/layout/BasicLayoutContainer'

const appRoutes = [
  {
    path: '/',
    exact: true,
    name: 'root',
    publicRoute: true,
    redirectTo: '/dashboard',
  },
  {
    path: '/dashboard',
    layout: BasicLayoutContainer,
    routes: dashboard,
    accessRedirectTo: '/auth/login',
  },
  {
    path: '/loan-files',
    layout: BasicLayoutContainer,
    routes: loans,
    accessRedirectTo: '/auth/login',

  },
  {
    path: '/orders',
    layout: BasicLayoutContainer,
    routes: orders,
    accessRedirectTo: '/auth/login',

  },
  {
    path: '/appraisers',
    layout: BasicLayoutContainer,
    routes: appraisers,
    accessRedirectTo: '/auth/login',

  },
  {
    path: '/users',
    layout: BasicLayoutContainer,
    routes: users,
    accessRedirectTo: '/auth/login',

  },
  {
    path: '/review',
    layout: BasicLayoutContainer,
    routes: review,
    accessRedirectTo: '/auth/login',
  },
  {
    path: '/branches',
    layout: BasicLayoutContainer,
    routes: branches,
    accessRedirectTo: '/auth/login',
  },
  {
    path: '/profile',
    layout: BasicLayoutContainer,
    routes: profile,
    accessRedirectTo: '/auth/login',

  },
  {
    path: '/fee',
    layout: BasicLayoutContainer,
    routes: fee,
    accessRedirectTo: '/auth/login',
  },
  {
    path: '/accounting',
    layout: BasicLayoutContainer,
    routes: accounting,
    accessRedirectTo: '/dashboard',
  },
  {
    path: '/admin_settings',
    layout: BasicLayoutContainer,
    routes: adminSettings,
    accessRedirectTo: '/auth/login',
  },
  {
    path: '/review-settings',
    layout: BasicLayoutContainer,
    routes: reviewSettings,
    accessRedirectTo: '/auth/login',
  },
  {
    path: '/activate',
    layout: EmptyLoyout,
    routes: activate,
    publicRoute: true,
  },
  {
    path: '/auth',
    layout: EmptyLoyout,
    routes: auth,
    publicRoute: true,
    accessRedirectTo: '/dashboard',

  },
  {
    path: '/notifications',
    layout: BasicLayoutContainer,
    routes: allNotifications,
    publicRoute: true,
  },
  {
    path: '/permissions',
    layout: BasicLayoutContainer,
    routes: permissions,
    accessRedirectTo: '/dashboard',
  },
  {
    component: NotFound,
  },

]

export default appRoutes
export const namedRoutes = routesMap(appRoutes)

function routesMap(routes, basePath = '/') {
  return routes.reduce(function(acc, { name, path, routes }) {
    if (!path) {
      return acc
    }

    path = makePath(path, basePath)

    if (name) {
      acc = {
        ...acc,
        [name]: path,
      }
    }

    if (routes) {
      acc = {
        ...acc,
        ...(routesMap(routes, path)),
      }
    }
    return acc
  }, {})
}

function makePath(path, basePath) {
  return (basePath + path).replace(/\/+/g, '/')
}
