// eslint-disable-next-line max-len
const termRe = /(?<quotedGeneral>(?<generalQuote>"|').*?\k<generalQuote>)|(?<quotedField>(?<quotedKey>\w+):(?<fieldQuote>"|')(?<quotedValue>.*?)\k<fieldQuote>)|(?<field>(?<key>\w+):(?<value>\S*))|(?<general>\S+)/g

// Process each term.
const processResult = (result) => {
  const {
    quotedGeneral,
    quotedField,
    fieldQuote,
    quotedKey,
    quotedValue,
    field,
    key,
    value,
    general,
  } = result.groups || {}
  if (quotedGeneral) {
    return processGeneral(quotedGeneral, result.index, quotedGeneral[0])
  }
  if (quotedField) {
    return processField(quotedField, quotedKey, quotedValue, result.index, fieldQuote)
  }
  if (field) {
    return processField(field, key, value, result.index)
  }
  if (general) {
    return processGeneral(general, result.index)
  }
}

// Process general non-keyword term.
const processGeneral = (term, index, quote) => {
  let text
  if (quote) {
    text = term.slice(1, -1)
  } else if (term[0].match(/"|'/)) {
    text = term.slice(1)
  } else if (term[term.length - 1].match(/"|'/)) {
    text = term.slice(0, -1)
  } else {
    text = term
  }
  return {
    text,
    quote,
    offsetStart: index,
    offsetEnd: index + term.length,
  }
}

// Process term with keyword field
const processField = (term, keyword, value, index, quote = undefined) => {
  return {
    keyword,
    value,
    quote,
    offsetStart: index,
    offsetEnd: index + term.length,
  }
}

// Combine the parsed offsets from the end (i.e. from the right side of the terms) if they are general terms.
const combineGeneralOffsetsFromRight = (offsets) => {
  if (offsets.length < 2) {
    return offsets
  }
  const lastOffset = offsets[offsets.length - 1]
  const secondLastOffset = offsets[offsets.length - 2]
  if (lastOffset.quote || lastOffset.keyword || secondLastOffset.quote || secondLastOffset.keyword) {
    return offsets
  }
  const combinedOffset = {
    text: `${secondLastOffset.text} ${lastOffset.text}`,
    offsetStart: secondLastOffset.offsetStart,
    offsetEnd: lastOffset.offsetEnd,
  }
  return combineGeneralOffsetsFromRight([...offsets.slice(0, -2), combinedOffset])
}

// Main parsing function, applying RegEx and processing each matched result
const searchQueryParsing = (terms) => {
  const results = terms.matchAll(termRe)
  const offsets = []
  for (const result of results) {
    offsets.push(processResult(result))
  }
  return { offsets: combineGeneralOffsetsFromRight(offsets) }
}

/*
searchQueryParser - a search query parser inspired by the sarch-query-parser from npm.
It improves and fixes the edge cases of the original library.  The unnecssary options and config are removed.

Improvements:
- Remove the keyword input requirement; any word formed from [A-Za-z0-9_] are considered keyword.
- Fix the various offset start/end issues with edge cases.
- Provide the quote detected in the offset result.
- Combine all the non-keyword terms from the end into one term.
*/
const searchQueryParser = {
  parse: searchQueryParsing,
}

export default searchQueryParser
