import React, { Children, cloneElement, useState } from 'react'

import ModalWrapper from './ModalWrapper'
import PropTypes from 'prop-types'

const propTypes = {
  children: PropTypes.object,
  modal: ModalWrapper.propTypes.modal,
}

const ModalTrigger = ({ children, modal, ...restProps }) => {
  const [toggled, setToggled] = useState(false)

  const open = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setToggled(true)
  }

  const confirm = () => {
    setToggled(false)
  }

  const closed = () => {
    setToggled(false)
  }

  const cancel = () => {
    setToggled(false)
  }

  const modalProps = {
    ...modal,
    show: toggled,
    onConfirm: confirm,
    onCancel: cancel,
    onClosed: closed,
  }

  const child = cloneElement(Children.only(children), { onClick: open, key: 'modal-control' })
  return [
    child,
    <ModalWrapper modal={modalProps} {...restProps} key='modal-dialog' />,
  ]
}

ModalTrigger.propTypes = propTypes

export default ModalTrigger
