import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { connectResource } from '../../common/utils/resource'
import Loader from '../../common/layout/components/Loader'
import ModalWrapper from '../../common/modals/ModalWrapper'
import FileUploader from '../../common/forms/inputs/FileUploader'
import DropzoneFileUploader from '../../common/forms/inputs/DropzoneFileUploader'
import { checkPermission } from '../../common/utils/helpers'
import { updateOrder } from '../../store/reducers/order/orderSlice'
import ConfirmPSModal from './ConfirmPSModal'


class Evault extends Component {
  state = {
    files: [],
    tempFiles: [],
    fileUploading: false,
    pAndSUploading: false,
    existing_files: [],
    existing_p_and_s: [],
    showConfirmationModal: false,
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { existing_files, existing_p_and_s } = this.state
    const { orderData, type } = nextProps
    const props_existing_files = (orderData[type] && orderData[type].documents) || []
    const props_existing_p_and_s = (orderData.p_and_s && orderData.p_and_s.documents) || []

    if (props_existing_files.length !== existing_files.length || props_existing_p_and_s !== existing_p_and_s) {
      this.setState({ existing_files: props_existing_files, existing_p_and_s: props_existing_p_and_s })
    }
  }

  UNSAFE_componentWillMount() {
    const { orderData, type } = this.props
    const existing_files = (orderData[type] && orderData[type].documents) || []
    const existing_p_and_s = (orderData.p_and_s && orderData.p_and_s.documents) || []
    this.setState({ existing_files, existing_p_and_s })
  }

  onFilesChange = (files) => {
    this.setState({
      files: files,
    })
  }

  onFileSubmit = (files) => {
    const { type, orderData, primaryOrderId, subOrderId, updateOrder, evault, dragLeave } = this.props

    if (type === 'team_documents') {
      this.setState({ fileUploading: true })
      this.saveTeamDocument(files)
    } else {
      this.setState({ fileUploading: true })
      evault.put({ file: files, id: orderData[type].id })
        .then(data => {
          const existing_documents = orderData[type] ? orderData[type].documents : []

          const update = {
            [type]: {
              ...orderData[type],
              documents: [
                ...existing_documents,
                data.success,
              ],
            },
          }

          updateOrder({ orderID: orderData.id, update })
          this.setState({ fileUploading: false })
          if (dragLeave) { dragLeave() }
        }).catch(_ => {
          this.setState({
            fileUploading: false,
          })
        })
    }
  }

  // triggered after a P&S file is selected in the file uploader
  onPAndSSubmit = (files) => {
    const { orderData } = this.props

    if (orderData.job_type === 'amc' && files && (orderData.statusKey === 'inspection_completed' || orderData.statusKey === 'submitted' || orderData.statusKey === 'under_review')) {
      this.setState({
        tempFiles: files,
        showConfirmationModal: true, // triggers confirmation modal which asks ifP&S change wil affect appraisal
      })
    } else {
      this.setState({ pAndSUploading: true })
      this.savePAndS(files, false)
    }
  }

  onPSModalSubmit = (affectsAppraisal) => {
    const { tempFiles } = this.state

    if (affectsAppraisal) {
      this.setState({
        pAndSUploading: true,
      })
      this.savePAndS(tempFiles, true)
    } else {
      this.setState({ pAndSUploading: true })
      this.savePAndS(tempFiles, false)
    }
  }

  // sends P&S file to backend
  savePAndS = (files, affectsAppraisal) => {
    const { orderData, primaryOrderId, subOrderId, updateOrder, pAndS, dragLeave } = this.props

    if (!files || !files[0]) {
      this.setState({
        pAndSUploading: false,
        showConfirmationModal: false,
      })
      return
    }

    pAndS.create({ file: files, document_name: files[0].name, id: orderData.id, affects_appraisal: affectsAppraisal })
      .then(data => {
        const existing_documents = orderData.p_and_s ? orderData.p_and_s.documents : []

        const update = {
          p_and_s: {
            ...orderData.p_and_s,
            documents: [
              ...existing_documents,
              data.success.document,
            ],
            id: data.success.id,
          },
          due_date: data.success.due_date,
        }

        updateOrder({ orderID: orderData.id, update })
        // refreshOrder()
        this.setState({
          pAndSUploading: false,
          showConfirmationModal: false,
        })
        if (dragLeave) { dragLeave() }
      })
      .catch(_ => {
        this.setState({
          pAndSUploading: false,
        })
      })
  }

  onPSModalCancel = () => {
    this.setState({
      showConfirmationModal: false,
    })
  }

  saveTeamDocument = (files) => {
    const { orderData, primaryOrderId, subOrderId, updateOrder, teamDocuments, dragLeave } = this.props

    if (!files) {
      this.setState({
        fileUploading: false,
      })
      return
    }

    teamDocuments.create({
      file: files, document_name: files[0].name, id: orderData.id,
    }).then(data => {
      const existing_documents = orderData.team_documents ? orderData.team_documents.documents : []

      const update = {
        team_documents: {
          ...orderData.team_documents,
          documents: [
            ...existing_documents,
            data.success.document,
          ],
          id: data.success.id,
        },
      }

      updateOrder({ orderID: orderData.id, update })
      this.setState({ fileUploading: false })
      if (dragLeave) { dragLeave() }
    }).catch(_ => {
      this.setState({
        fileUploading: false,
      })
    })
  }

  existingFileRemove = (idx, file, p_and_s) => {
    const { type, primaryOrderId, subOrderId, updateOrder, orderData, evault } = this.props

    const { existing_files } = this.state
    let id
    let new_files = existing_files

    if (p_and_s) {
      id = orderData.p_and_s.id
    } else {
      id = orderData[type].id
      new_files = JSON.parse(JSON.stringify(existing_files))
      new_files.splice(idx, 1)
    }
    this.setState({ fileUploading: true })
    evault.remove({ id: id, document_id: file.id }).then(data => {
      if (p_and_s) {
        const update = {
          p_and_s: JSON.parse(JSON.stringify(orderData.p_and_s)),
        }
        update.p_and_s.documents.splice(idx, 1)
        updateOrder({ orderID: orderData.id, update })
      }

      this.setState({
        existing_files: new_files,
        fileUploading: false,
      })
      const update = {
        [type]: {
          ...orderData[type],
          documents: [
            ...new_files,
          ],
        },
      }
      updateOrder({ orderID: orderData.id, update })
    }).catch(_ => {
      this.setState({
        fileUploading: false,
      })
    })
  }

  fileRemove = (idx, file) => {
    const { files } = this.state

    files.splice(idx, 1)

    this.setState({
      files,
    })
  }

  onFilesError = (event) => {
  }

  shouldShowPAndS = () => {
    const { is_amc_lender, isTeamDocs, type } = this.props

    if (checkPermission('p_and_s_view')) {
      if (!isTeamDocs) {
        if (!is_amc_lender || type === 'evault') {
          return true
        }
      }
    }

    return false
  }

  shouldShowEvault = () => {
    const { orderData, isTeamDocs, type } = this.props

    if (checkPermission('evault_view')) {
      if (orderData[type]) {
        if (!isTeamDocs) {
          if (orderData.order_request_method !== 'broadcast' || orderData.accepted) {
            return true
          }
        }
      }
    }

    return false
  }

  render() {
    const { orderData, type, is_review_appraiser, isDragOver, isTeamDocs } = this.props
    const { files, existing_files } = this.state

    const existing_p_and_s = (orderData.p_and_s && orderData.p_and_s.documents) || []
    if (this.state.pAndSUploading || this.state.fileUploading) { return <div className='d-flex flex-column m-5'><Loader /></div> }
    return (
      <Fragment>
        <ModalWrapper
          show={this.state.showConfirmationModal}
          component={ConfirmPSModal}
          modalClassName='modal-primary'
          onSubmit={this.onPSModalSubmit}
          onCancel={this.onPSModalCancel}
        />
        <div className="d-flex flex-column" name={type}>
          {this.shouldShowPAndS() && <div>
            <h4>P&S Contract:</h4>
            <div className="file-box">
              {isDragOver
                ? <DropzoneFileUploader
                  existing_files={existing_p_and_s}
                  files={files}
                  onFilesChange={this.onPAndSSubmit}
                  fileRemove={this.fileRemove}
                  existingFileRemove={this.existingFileRemove}
                  onFilesError={this.onFilesError}
                  hasDownloadPermission={checkPermission('p_and_s_download')}
                  hasUploadPermission={checkPermission('p_and_s_upload')}
                  hasRemovePermission={checkPermission('p_and_s_delete')}
                  p_and_s
                  showEmptyMessage
                />
                : <FileUploader
                  existing_files={existing_p_and_s}
                  files={files}
                  onFilesChange={this.onPAndSSubmit}
                  fileRemove={this.fileRemove}
                  existingFileRemove={this.existingFileRemove}
                  onFilesError={this.onFilesError}
                  hasDownloadPermission={checkPermission('p_and_s_download')}
                  hasUploadPermission={checkPermission('p_and_s_upload')}
                  hasRemovePermission={checkPermission('p_and_s_delete')}
                  p_and_s
                  showEmptyMessage
                />
              }
            </div>
          </div>}
          {this.shouldShowEvault() && <div className="mt-3">
            <h4>Other Files:</h4>
            <div className="file-box">
              {isDragOver
                ? <DropzoneFileUploader
                  existing_files={existing_files}
                  files={files}
                  onFilesChange={this.onFileSubmit}
                  fileRemove={this.fileRemove}
                  existingFileRemove={this.existingFileRemove}
                  onFilesError={this.onFilesError}
                  is_review_appraiser={is_review_appraiser}
                  hasDownloadPermission={checkPermission('evault_download')}
                  hasUploadPermission={checkPermission('evault_upload')}
                  hasRemovePermission={checkPermission('evault_delete')}
                  showEmptyMessage />
                : <FileUploader
                  existing_files={existing_files}
                  files={files}
                  onFilesChange={this.onFileSubmit}
                  fileRemove={this.fileRemove}
                  existingFileRemove={this.existingFileRemove}
                  onFilesError={this.onFilesError}
                  hasDownloadPermission={checkPermission('evault_download')}
                  hasUploadPermission={checkPermission('evault_upload')}
                  hasRemovePermission={checkPermission('evault_delete')}
                  showEmptyMessage
                />}
            </div>
          </div>}
          {checkPermission('team_documents_view') && isTeamDocs && <div>
            <h4>Team Documents:</h4>
            <div className="file-box">
              {isDragOver
                ? <DropzoneFileUploader
                  existing_files={existing_files}
                  files={files}
                  onFilesChange={this.onFileSubmit}
                  fileRemove={this.fileRemove}
                  existingFileRemove={this.existingFileRemove}
                  onFilesError={this.onFilesError}
                  is_review_appraiser={is_review_appraiser}
                  hasDownloadPermission={checkPermission('team_documents_download')}
                  hasUploadPermission={checkPermission('team_documents_upload')}
                  hasRemovePermission={checkPermission('team_documents_delete')}
                  team_documents
                  showEmptyMessage
                />
                : <FileUploader
                  existing_files={existing_files}
                  files={files}
                  onFilesChange={this.onFileSubmit}
                  fileRemove={this.fileRemove}
                  existingFileRemove={this.existingFileRemove}
                  onFilesError={this.onFilesError}
                  hasDownloadPermission={checkPermission('team_documents_download')}
                  hasUploadPermission={checkPermission('team_documents_upload')}
                  hasRemovePermission={checkPermission('team_documents_delete')}
                  team_documents
                  showEmptyMessage
                />
              }
            </div>
          </div>}
        </div>
      </Fragment>
    )
  }
}


function mapStateToProps(state) {
  return {
    currentUserID: state.resource.user.data.id,
    conversationID: state.resource.order && state.resource.order.data &&
     state.resource.order.data.conversation ? state.resource.order.data.conversation.id : null,
    is_review_appraiser: state.resource.user.data.is_review_appraiser,
  }
}


export default compose(
  connectResource({
    namespace: 'evault',
    endpoint: 'order/evault',
    prefetch: false,
    navigateAfterSubmit: false,
    excludeID: true,
    apiVersion: 2,
    successMessage: {
      PUT: 'Your file has been uploaded to the evault.',
      DELETE: 'The file has been removed from the evault',
    },
  }),
  connectResource({
    namespace: 'pAndS',
    endpoint: 'order/p-and-s',
    prefetch: false,
    navigateAfterSubmit: false,
    excludeID: true,
    apiVersion: 2,
    successMessage: {
      POST: 'Your P&S has been uploaded.',
      DELETE: 'Your P&S has been deleted',
    },
  }),
  connectResource({
    namespace: 'teamDocuments',
    endpoint: 'order/team-documents',
    prefetch: false,
    navigateAfterSubmit: false,
    excludeID: true,
    apiVersion: 2,
    successMessage: {
      POST: 'Your document has been uploaded.',
      DELETE: 'Your document has been deleted',
    },
  }),
  connect(mapStateToProps, { updateOrder }),
)(Evault)
