import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import API, { API_VERSION, REQUEST_METHOD } from '../../../api'
import STATUS from '../../../common/constants/reducer_statuses'

const initialState = {
  data: {},
  status: STATUS.IDLE,
  error: null,
}

export const fetchDashboard = createAsyncThunk('dashboard/fetchDashboard', async () => {
  return await API('dashboard/', API_VERSION.TWO).request(REQUEST_METHOD.GET, {}, {})
})

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
  },
  extraReducers: {
    [fetchDashboard.pending]: (state, action) => {
      state.status = STATUS.LOADING
    },
    [fetchDashboard.fulfilled]: (state, action) => {
      state.status = STATUS.SUCCEEDED
      // Add any fetched posts to the array
      state.data = action.payload
    },
  },
})

export default dashboardSlice.reducer
