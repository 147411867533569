import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, Label, Input } from 'reactstrap'
const propTypes = {
  inputClassName: PropTypes.string,
  value: PropTypes.string,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  name: PropTypes.string,
}
const defaultProps = {
  inputClassName: 'radio-custom',
  valueKey: 'value',
  labelKey: 'label',
}

export default class RadiosInput extends Component {
  handleChange = (e) => {
    this.props.onChange(e.target.value)
  }

  render() {
    const {
      inputClassName, value, valueKey, labelKey,
      options, disabled, name, required, inline,
    } = this.props
    return (
      <div className='radio-wrapper'>
        {
          options.map((option) => (
            <FormGroup
              check
              className="radio mb-0"
              key={option[valueKey]}
              inline={inline}
            >
              <Label
                check
                className={`form-check-label ${inputClassName}`}
              >
                <Input
                  className="form-check-input"
                  type="radio"
                  name={name}
                  onChange={this.handleChange}
                  checked={value === option[valueKey]}
                  value={option[valueKey]}
                  disabled={disabled || option.disabled}
                  required={required}
                />
                { option[labelKey] }
              </Label>
            </FormGroup>
          ))
        }
      </div>
    )
  }
}

RadiosInput.propTypes = propTypes
RadiosInput.defaultProps = defaultProps
