import React, { useState, useEffect, useCallback } from 'react'
import { Button as AntButton } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import TextArea from 'antd/lib/input/TextArea'
import { useDebounce } from '@common/hooks/useDebounce'
import { fetchReviewNotes, updateReviewNote, toggleReviewNotes } from '@store/reducers/submissionReviews/submissionReviewsSlice'
import { selectReviewNotesByVersion, selectReviewNotesShownByVersion } from '@store/reducers/submissionReviews/submissionReviewsSelectors'
import { useSelector, useDispatch } from 'react-redux'
import { selectIsAmcLender } from '@store/reducers/user/userSelectors'
import { checkPermission } from '@common/utils/helpers'

export default function ReviewNotes(props) {
  const [note, setNote] = useState({})
  const isAmcLender = useSelector(selectIsAmcLender)
  const [notesContent, setNotesContent] = useState('')
  const [notesOpen, setNotesOpen] = useState(false)
  const [isEditable, setIsEditable] = useState(false)
  const dispatch = useDispatch()
  const reviewNotes = useSelector((state) =>
    selectReviewNotesByVersion(state, props.orderId, props.submissionVersion))
  const showNotes = useSelector((state) =>
    selectReviewNotesShownByVersion(state, props.orderId, props.submissionVersion))

  const editPermission = checkPermission('reggora_review_notes_edit')

  const closeNotes = () => {
    dispatch(toggleReviewNotes({ orderId: props.orderId, submissionVersion: props.submissionVersion }))
  }

  useEffect(() => {
    if (!props.isEditable) {
      setIsEditable(false)
      return
    }

    if (editPermission) {
      setIsEditable(true)
    } else {
      setIsEditable(false)
    }
  }, [props.isEditable, editPermission, setIsEditable])

  useEffect(() => {
    if (showNotes) {
      setNotesOpen(true)
    } else {
      setNotesOpen(false)
    }
  }, [showNotes])

  const saveNotes = useDebounce(() => {
    dispatch(updateReviewNote({
      id: note._id,
      body: notesContent,
      orderId: props.orderId,
      submissionVersion: props.submissionVersion,
    }))
  })

  const updateNotes = (e) => {
    setNotesContent(e.currentTarget.value)
    saveNotes()
  }

  const reloadNotes = useCallback(() => {
    if (props.submissionVersion && props.orderId) {
      dispatch(fetchReviewNotes({
        submissionVersion: props.submissionVersion,
        orderId: props.orderId,
        reviewNoteType: isAmcLender ? 'amp' : 'lender',
      }
      ))
    }
  }, [dispatch, props.submissionVersion, props.orderId, isAmcLender])

  useEffect(() => {
    if (reviewNotes?.hasNotes) {
      setNote(reviewNotes.note)
      setNotesContent(reviewNotes.note.body)
    }
    // This is fine for a dependency... https://github.com/facebook/react/issues/15924
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewNotes?.note?._id])

  useEffect(() => {
    if (props.submissionVersion && props.orderId) {
      reloadNotes()
    }
  }, [reloadNotes, props.submissionVersion, props.orderId, isAmcLender])

  const notesClass = `review-notes-container ${notesOpen ? 'show' : ''}`

  return (
    <>
      <div className={notesClass}>
        <div className='review-notes-header'>
          <b>Review Notes</b>

          <AntButton onClick={closeNotes} type="text"><CloseOutlined /></AntButton>
        </div>
        <div className='review-notes-panel'>
          <div className='review-notes-panel-content'>
            { isEditable
              ? (
                <TextArea autoSize={{ minRows: 50, maxRows: 100 }}
                  value={notesContent} onChange={updateNotes}></TextArea>
              ) : (
                <div className='review-notes-content-view-only'>{notesContent}</div>
              )
            }
          </div>
        </div>
      </div>
    </>
  )
}
