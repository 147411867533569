import React from 'react'
import NewMessage from './NewMessage'
import NewMessageTem from './NewMessageTem'
import { checkPermission } from 'app/common/utils/helpers'


export const NewMessageWrapper = (props) => {
  /**
  NewMessageWrapper component handles decision of which component to render.

  NewMessageTem is returned for Unified Conversations.

  NewMessage is returned for all other uses of this component.
  */
  let userCanApproveMessage = false

  try {
    // checkPermission will raise Error if permission not included
    userCanApproveMessage = checkPermission('approve_conversation_messages')
  } catch {
    userCanApproveMessage = false
  }
  if (props.conversationType === 'unified_conversation') {
    const userCanChooseRecipient = userCanApproveMessage && !props.isAMPUser
    return <NewMessageTem
      {...props}
      userCanApproveMessage={userCanApproveMessage}
      userCanChooseRecipient={userCanChooseRecipient}
    />
  } else {
    return <NewMessage
      {...props}
    />
  }
}
