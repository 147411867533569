import React from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Tag, Button } from 'antd'
import ModalTrigger from '@common/modals/ModalTrigger'
import { checkCounterOfferPermissions } from '@common/utils/helpers'
import EditOfferModal from './EditOfferModal'
import { JOB_TYPES } from '@app/common/constants/jobTypes'
import { OFFER_TYPES } from '@app/common/constants/offer'
import { useApiRequest } from '@app/common/hooks'


// if user can take action on offer, renders Accept and Decline buttons
const OfferActionButtons = ({
  offer,
  order,
  request,
  isAmcLender,
  refreshOrder,
}) => {
  const { counterOfferPermission } = useFlags()
  const apiRequest = useApiRequest()

  if (offer.status !== 'pending' || order.on_hold || order.lender_canceled || order.appraiser_requested_cancel) {
    // these scenarios prevent taking action on an offer, so we hide the buttons
    return null
  }

  if (counterOfferPermission) {
    const hasPermission = checkCounterOfferPermissions(order, offer)
    if (!hasPermission) {
      // flag is on and user lacks permissions, so we hide the buttons
      return null
    }
  }

  if (isAmcLender && offer.is_pending_client_lender) {
    return <Tag>Pending Lender</Tag>
  }

  const onSubmit = async action => {
    const baseUrl = `offers/${offer.id}`
    const url = action === 'accept' ? `${baseUrl}/accept` : `${baseUrl}/deny`
    const args = {
      order_id: order.id,
      assignment_list_request_id: request.id,
      offer_id: offer.id,
    }
    await apiRequest({
      endpoint: url,
      method: 'POST',
      body: args,
      successMessage: 'Offer successfully updated.',
    })
    refreshOrder()
  }

  // show Accept + Decline buttons
  return (
    <div>
      {order.job_type === JOB_TYPES.AMC && isAmcLender ? (
        <ModalTrigger
          component={EditOfferModal}
          refreshOrder={refreshOrder}
          offerType={OFFER_TYPES.COUNTER_OFFER}
          backdrop='static'
          assignmentListRequestId={request.id}
          offerId={offer.id}
        >
          <Button className='order-header-button btn-sm mx-2'>
            Request Lender
          </Button>
        </ModalTrigger>
      ) : (
        <Button
          onClick={() => onSubmit('accept')}
          className='mr-2'
          type='primary'
        >
          Accept Offer
        </Button>
      )}
      <Button
        onClick={() => onSubmit('deny')}
        type='danger'
      >
        Decline Offer
      </Button>
    </div>
  )
}

export default OfferActionButtons
