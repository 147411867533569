/* global GOOGLE_MAPS_API_KEY */
import React from 'react'

const GOOGLE_MAPS_BASE_URL = 'https://maps.googleapis.com/maps/api/staticmap'

const AddressMap = ({
    address: {
        address,
        city,
        state,
        zip,
    },
    width,
    height,
    scale = 2,
    zoom = 12,
    className = '',
    asImage = false,
}) => {
    let fullAddress = address
    if (city) {
        fullAddress = `${fullAddress} ${    city}`
    }
    if (state) {
        fullAddress = `${fullAddress}, ${state}`
    }
    if (zip) {
        fullAddress = `${fullAddress} ${zip}`
    }

    const urlParams = new URLSearchParams({
        center: fullAddress,
        zoom,
        format: 'png',
        maptype: 'roadmap',
        size: `${width}x${height}`,
        scale,
        markers: `size:mid|color:0x7092f1|label:|+${fullAddress}`,
        key: GOOGLE_MAPS_API_KEY,
    })
    const url = `${GOOGLE_MAPS_BASE_URL}?${urlParams.toString()}`

    if (asImage) {
        return <img src={url} className={className}/>
    }

    const reportStyle = {
        backgroundSize: 'cover',
        backgroundImage: `url(${url})`,
    }

    return <div style={reportStyle} className={className}></div>
}

export default AddressMap
