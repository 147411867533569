import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import API, { API_VERSION, REQUEST_METHOD } from '../../../api'
import { SubmissionReviewWorkflow } from '@app/pages/orders/SubmissionsTab/ReggoraReview/ReviewConstants'


export const initialState = {
  rulesets: {
    rulesets: [],
    isFetching: false,
  },
  submissionReviews: {},
  reviewNotes: {},
  reviewResults: {},
}

export const fetchRulesets = createAsyncThunk('submissionReviews/fetchSubmissionRulesets', async (data, { rejectWithValue }) => {
  try {
    const { lenderId } = data

    const rulesets = await API(
      'submission-reviews/rule-sets/v1',
      API_VERSION.SERVICE,
      SERVICE_URL).request(REQUEST_METHOD.GET,
      {
        lender_id: lenderId,
      },
      {}
    )

    return rulesets.results
  } catch (err) {
    return rejectWithValue(err.errors._error)
  }
})

export const fetchReviewNotes = createAsyncThunk('submissionReviewNotes/fetchSubmissionReviewNotes', async (data, { rejectWithValue }) => {
  try {
    const { orderId, submissionVersion, reviewNoteType } = data
    const reviewNotes = await API(
      'submission-reviews/notes/v1',
      API_VERSION.SERVICE,
      SERVICE_URL).request(REQUEST_METHOD.GET,
      {
        order_id: orderId,
        submission_version: submissionVersion,
        review_note_type: reviewNoteType,
      },
      {}
    )
    if (reviewNotes.count === 0) {
      return rejectWithValue('No Notes')
    }
    return reviewNotes.results[0]
  } catch (err) {
    return rejectWithValue(err.errors._error)
  }
})

export const updateReviewNote = createAsyncThunk('submissionReviewNotes/saveSubmissionReviewNote', async (data, { rejectWithValue }) => {
  try {
    const { id, body, orderId, submissionVersion } = data

    const updateNoteResponse = await API(
      `submission-reviews/notes/v1/${id}`,
      API_VERSION.SERVICE,
      SERVICE_URL).request(REQUEST_METHOD.PUT,
      {},
      {
        _id: id,
        body: body,
      }
    )
    updateNoteResponse.orderId = orderId
    updateNoteResponse.submissionVersion = submissionVersion

    return updateNoteResponse
  } catch (err) {
    return rejectWithValue(err.errors._error)
  }
})

export const fetchSubmissionReviews = createAsyncThunk('submissionReviews/fetchSubmissionReviews', async (data, { rejectWithValue }) => {
  try {
    const { orderId, is_amc_lender, viewWarrantyReviews } = data
    const submission_review_type = viewWarrantyReviews ? 'repurchase_warranty' : (is_amc_lender ? 'amp' : 'lender')
    const submissionReviews = await API(
      'submission-reviews/v1',
      API_VERSION.SERVICE,
      SERVICE_URL).request(REQUEST_METHOD.GET,
      {
        order_id: orderId,
        submission_review_type: submission_review_type,
        submission_review_workflow: [SubmissionReviewWorkflow.POST_SUBMISSION],
      },
      {}
    )
    return submissionReviews
  } catch (err) {
    return rejectWithValue(err.errors._error)
  }
})

export const fetchReviewResults = createAsyncThunk('submissionReviews/fetchReviewResults', async (data, { rejectWithValue }) => {
  try {
    const { result_id } = data
    const submissionReviews = await API(
      'submission-reviews/rule-results/v1',
      API_VERSION.SERVICE,
      SERVICE_URL).request(REQUEST_METHOD.GET,
      {
        submission_review_result_id: result_id,
        outcome: ['ERROR', 'PASS', 'FAIL', 'PREREQUISITE_FAIL', 'HARD_STOP', 'SOFT_STOP', 'NO_STOP', 'SKIP', 'MANUAL', 'ACCEPTED', 'REJECTED'],
        expand: ['data_points_by_submission', 'note_logs.user', 'outcome_override_logs.user'],
        limit: 9000,
      },
      {}
    )
    return submissionReviews
  } catch (err) {
    return rejectWithValue(err.errors._error)
  }
})

export const postRuleComment = createAsyncThunk('submissionReviews/postRuleComment', async (data, { rejectWithValue }) => {
  const { ruleId, note, user_id, user_type } = data
  try {
    const ruleComment = await API(`submission-reviews/rule-results/v1/${ruleId}/note`, API_VERSION.SERVICE, SERVICE_URL).request(REQUEST_METHOD.POST, {}, {
      note: note,
      user_id: user_id,
      user_type: user_type,
    })
    return ruleComment
  } catch (err) {
    return rejectWithValue(err.errors._error)
  }
})

export const postRuleOverride = createAsyncThunk('submissionReviews/postRuleOverride', async (data, { rejectWithValue, getState }) => {
  const {
    outcome,
    ruleId,
    submissionReviewId,
    user_id,
    user_type,
  } = data
  const currentState = getState()
  const rule = currentState
    ?.submissionReviews?.reviewResults?.[submissionReviewId]?.[ruleId]
  const version = Math.max(...rule?.outcome_override_logs?.map(o => o.version), 0)
  try {
    const ruleOverride = await API(
      `submission-reviews/rule-results/v1/${ruleId}/outcome`,
      API_VERSION.SERVICE,
      SERVICE_URL
    ).request(
      REQUEST_METHOD.POST,
      {
        expand: ['data_points_by_submission', 'outcome_override_logs.user'],
      },
      {
        current_version: version,
        outcome,
        user_id,
        user_type,
      }
    )
    return ruleOverride
  } catch (err) {
    return rejectWithValue(err.errors._error)
  }
})

export const postSubmissionReview = createAsyncThunk('submissionReviews/submission-reviews/v1', async (data, { rejectWithValue }) => {
  const { orderId, submissionVersion, submissionReviewTypes } = data
  try {
    const submissionReviewPostCall = await API('submission-reviews/v1', API_VERSION.SERVICE, SERVICE_URL).request(REQUEST_METHOD.POST, {}, {
      order_id: orderId,
      submission_version: submissionVersion,
      submission_review_types: submissionReviewTypes,
    })
    return submissionReviewPostCall
  } catch (err) {
    return rejectWithValue(err.errors._error)
  }
})

const submissionReviewsSlice = createSlice({
  name: 'submissionReviews',
  initialState,
  reducers: {
    resetSubmissionReviews: () => {
      return initialState
    },
    toggleReviewNotes: (state, action) => {
      const { payload } = action
      const { orderId, submissionVersion } = payload

      const noteKey = `${orderId}-${submissionVersion}`
      const existing = state.reviewNotes[noteKey]

      const updatedNote = {
        ...existing,
        showNotes: !existing?.showNotes,
      }

      state.reviewNotes = {
        ...state.reviewNotes,
        [noteKey]: updatedNote,
      }
    },
  },
  extraReducers: {
    [fetchReviewResults.fulfilled]: (state, action) => {
      const { result_id } = action.meta.arg

      state.reviewResults = {
        ...state.reviewResults,
        [result_id]:
          action.payload.result
            .reduce(
              (acc, curr) => {
                acc[curr._id] = curr
                return acc
              }, {}
            ),
      }
    },
    [fetchReviewResults.rejected]: (state, action) => {
      console.log('todo fetch review result failed', action)
    },
    [fetchRulesets.fulfilled]: (state, action) => {
      state.rulesets.rulesets = action.payload.map(ruleset => {
        return {
          _id: ruleset._id,
          key: ruleset.key,
          name: ruleset.rule_set_name,
          is_default: ruleset.is_default,
          date_modified: ruleset.date_modified,
        }
      })
      state.rulesets.isFetching = false
    },
    [fetchRulesets.pending]: (state, action) => {
      state.rulesets.isFetching = true
    },
    [fetchRulesets.rejected]: (state, action) => {
      state.rulesets.isFetching = false
    },
    [fetchReviewNotes.fulfilled]: (state, action) => {
      const { payload, meta } = action
      const { orderId, submissionVersion } = meta.arg

      const noteKey = `${orderId}-${submissionVersion}`

      state.reviewNotes = {
        ...state.reviewNotes,
        [noteKey]: {
          hasNotes: true,
          showNotes: false,
          note: { ...payload },
        },
      }
    },
    [fetchReviewNotes.rejected]: (state, action) => {
      const { meta } = action
      const { orderId, submissionVersion } = meta.arg
      const noteKey = `${orderId}-${submissionVersion}`

      state.reviewNotes = {
        ...state.reviewNotes,
        [noteKey]: {
          hasNotes: false,
          showNotes: false,
          note: null,
        },
      }
    },
    [updateReviewNote.rejected]: (state, action) => {
      console.warn('Review notes update failed')
    },
    [updateReviewNote.fulfilled]: (state, action) => {
      const { payload } = action
      const { orderId, submissionVersion } = payload

      const noteKey = `${orderId}-${submissionVersion}`
      const existing = state.reviewNotes[noteKey]

      const updatedNote = {
        ...existing,
        note: {
          ...existing.note,
          body: payload.body,
        },
      }
      state.reviewNotes = {
        ...state.reviewNotes,
        [noteKey]: updatedNote,
      }
    },
    [fetchSubmissionReviews.fulfilled]: (state, action) => {
      state.submissionReviews = {
        results: action.payload.results.map(res => {
          return {
            ...res,
            review_result: res.review_result,
          }
        }).reduce(
          (acc, curr) => {
            acc[curr._id] = curr
            return acc
          }, {}
        ),
        count: action.payload.count,
      }
    },
    [postRuleComment.fulfilled]: (state, action) => {
      const { payload } = action
      const insertionId = payload.submission_review_result_id
      const existing = state.reviewResults[insertionId]
      state.reviewResults = {
        ...state.reviewResults,
        [insertionId]: {
          ...existing,
          [payload._id]: payload,

        },
      }
    },
    [postRuleOverride.fulfilled]: (state, action) => {
      const { payload } = action

      const insertionId = payload.submission_review_result_id
      const existing = state.reviewResults[insertionId]
      state.reviewResults = {
        ...state.reviewResults,
        [insertionId]: {
          ...existing,
          [payload._id]: payload,

        },
      }
    },
  },
})

const { actions, reducer } = submissionReviewsSlice
export const { toggleReviewNotes, resetSubmissionReviews } = actions

export default reducer
