// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modalBody_271e42 > div:last-child {\n  margin-bottom: 0;\n}\n\n.modalBody_271e42 .ant-tag {\n  margin-right: 0;\n}\n\n.cardContent_fe620b {\n  display: flex;\n  flex-direction: column;\n}\n\n.cardContent_fe620b > div {\n  margin-bottom: 0.25rem;\n}\n\n.cardContent_fe620b .data-points-label {\n  color: inherit;\n}\n\n.tagContainer_3a559c {\n  display: flex;\n}\n\n.logCardItemLabel_bd4574 {\n  font-weight: 700;\n  margin-right: 8px;\n}\n\n.tagArrow_88e5ae {\n  margin: 0 8px;\n}\n", "",{"version":3,"sources":["webpack://src/app/pages/orders/SubmissionsTab/ReggoraReview/ReviewLog/ReviewLogModal.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".modalBody > div:last-child {\n  margin-bottom: 0;\n}\n\n.modalBody :global(.ant-tag) {\n  margin-right: 0;\n}\n\n.cardContent {\n  display: flex;\n  flex-direction: column;\n}\n\n.cardContent > div {\n  margin-bottom: 0.25rem;\n}\n\n.cardContent :global(.data-points-label) {\n  color: inherit;\n}\n\n.tagContainer {\n  display: flex;\n}\n\n.logCardItemLabel {\n  font-weight: 700;\n  margin-right: 8px;\n}\n\n.tagArrow {\n  margin: 0 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBody": "modalBody_271e42",
	"cardContent": "cardContent_fe620b",
	"tagContainer": "tagContainer_3a559c",
	"logCardItemLabel": "logCardItemLabel_bd4574",
	"tagArrow": "tagArrow_88e5ae"
};
export default ___CSS_LOADER_EXPORT___;
