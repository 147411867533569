export const OFFER_TYPES = {
  COUNTER_OFFER: 'counter_offer',
  FEE_ESCALATION: 'fee_escalation',
}

export const OFFER_STATUSES = {
  PENDING: 'pending',
  PROCESSING: 'processing',
  ACCEPTED: 'accepted',
}
