import React from 'react'
import OrderActivityLogContainer from './OrderActivityLogContainer'
import { Alert } from 'reactstrap'


const InternalFeatureBanner = () => {
  return (
    <Alert color="warning">
      <p>
      Internal Reggora Feature -
      the Activity Log tab is only visible to Reggora employees when using the Helpdesk impersonation link.
      </p>
    </Alert>
  )
}

const ActivityLogTab = (props) => {
  const { orderId } = props
  return (
    <>
      <InternalFeatureBanner />
      <OrderActivityLogContainer orderId={orderId} />
    </>
  )
}

export default ActivityLogTab
