import React from 'react'
import { Table, Checkbox } from 'antd'
import errorFlag from '../../../img/icons/errorFlag.png'
import warningFlag from '../../../img/icons/warningFlag.png'

const RevisionGseStep = ({
  allChecked,
  onChangeAll,
  onCheckFailedRule,
  gseData,
  gseType,
  setData,
  setAllChecked,
}) => {
  // Filter out duplicates based on the 'code' property
  const uniqueGseData = gseData.reduce((acc, current) => {
    const x = acc.find(item => item.code === current.code)
    if (!x) {
      return acc.concat([current])
    } else {
      return acc
    }
  }, [])

  const columns = [
    {
      title: (
        <div style={{ textAlign: 'center' }}>
          <Checkbox
            checked={allChecked}
            onChange={() => onChangeAll(uniqueGseData, !allChecked, setData, setAllChecked)}
          />
        </div>
      ),
      dataIndex: 'isChecked',
      key: 'isChecked',
      width: 60,
      render: (isChecked, item, index) => (
        <div style={{ textAlign: 'center' }}>
          <Checkbox
            checked={isChecked}
            onChange={() => onCheckFailedRule(uniqueGseData, index, setData, setAllChecked)}
          />
        </div>
      ),
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      width: 100,
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 70,
      render: (type) => (
        type === 'hard_stop' ? <img src={errorFlag} height={'18px'} /> : <img src={warningFlag} height={'18px'} />
      ),
    },
  ]

  return (
    <>
      <p>Select the {gseType.toUpperCase()} findings you would like to include in the revision below.</p>
      <Table
        className={'request-revision-table'}
        columns={columns}
        dataSource={uniqueGseData}
        rowKey={record => record.code}
        scroll={{ y: 350 }}
        size='small'
        pagination={false}
      />
    </>
  )
}

export default RevisionGseStep
