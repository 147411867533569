import { of } from 'rxjs/observable/of'
import { concat } from 'rxjs/observable/concat'

import {
  VIEW_NOTIFICATIONS
} from '../../common/utils/resource'
import {
  updateNotificationsActionCreator
} from '../../common/socket/notificationHandler'


export default function notificationsViewedEpic(action$, store, { API }) {
  return action$.ofType(VIEW_NOTIFICATIONS).mergeMap(action => {
    const notifications = store.getState().resource.notifications.data
    const userID = store.getState().resource.user.data.id
    const updatedIDs = []
    if(!notifications || !Array.isArray(notifications)) {
      return []
    }
    const newNotifications = notifications.map(n => {
      if(n.viewed && n.viewed !== 'None') {
        return n
      }
      updatedIDs.push(n.id)
      return {
        ...n,
        viewed: true
      }
    })
    if(updatedIDs.length > 0) {
      API(`notifications/${userID}`, 2).request('PUT', undefined, { id: updatedIDs })
      return concat(
        of(updateNotificationsActionCreator(newNotifications))
      )
    } else {
      return []
    }
  })
}
