import debounce from 'lodash/debounce'
import pick from 'lodash/pick'
import LocalStorageMock from './localStorageMock'

if (typeof localStorage === 'undefined' || localStorage === null) {
  global.localStorage = new LocalStorageMock()
}

// eslint-disable-next-line no-use-before-define
const CACHE_STATE_KEYS = JSON.parse(CACHE_STATE_KEYS || '{}')
// eslint-disable-next-line no-undef
const state = JSON.parse(localStorage.getItem(STORAGE_KEY) || '{}')

const middleware = store => next => action => {
  const result = next(action)
  const nextState = store.getState()

  cacheState(
    CACHE_STATE_KEYS ? pick(nextState, CACHE_STATE_KEYS) : nextState
  )

  return result
}

const cacheState = debounce(function(state) {
  localStorage.setItem(
    // eslint-disable-next-line no-undef
    STORAGE_KEY,
    JSON.stringify(state)
  )
})

export { middleware, state }
