import React from 'react'
import { Divider } from 'antd'
import { SpecialZoomLevel, Viewer } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'

import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'

export default function PdfViewer(props) {
  const { fileUrl, httpHeaders } = props

  const renderToolbar = (Toolbar) => {
    return (
      <Toolbar>
        {(slots) => {
          const {
            CurrentPageInput,
            GoToNextPage,
            GoToPreviousPage,
            NumberOfPages,
            ShowSearchPopover,
            Zoom,
            ZoomIn,
            ZoomOut,
          } = slots
          return (
            <div className='d-flex align-items-center justify-content-center w-100'>
              <div className='px-2'>
                <ShowSearchPopover />
              </div>

              <div>
                <Divider type="vertical" />
              </div>

              <div style={{ maxWidth: '3rem' }}>
                <CurrentPageInput />
              </div>
              <div className='pl-1 pr-2'>
                <NumberOfPages>{(props) => (
                  <span>&nbsp; / {props.numberOfPages}</span>
                )}
                </NumberOfPages>
              </div>
              <div className='pl-2 pr-1'>
                <GoToPreviousPage />
              </div>
              <div className='pr-2 pl-1'>
                <GoToNextPage />
              </div>

              <div>
                <Divider type="vertical" />
              </div>

              <div className='pl-2 pr-1'>
                <ZoomOut />
              </div>
              <div className='pr-2 pl-1'>
                <ZoomIn />
              </div>
              <div className='px-2'>
                <Zoom />
              </div>
            </div>
          )
        }}
      </Toolbar>
    )
  }

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar: renderToolbar,
  })
  const viewerPlugins = [defaultLayoutPluginInstance]

  return (
    <>
      <Viewer
        fileUrl={fileUrl}
        httpHeaders={httpHeaders}
        enableSmoothScroll={true}
        plugins={viewerPlugins}
        defaultScale={SpecialZoomLevel.PageWidth}
      />
    </>)
}
