import React, { useState, useEffect } from 'react'
import { Button, UncontrolledTooltip } from 'reactstrap'
import Rephrase from '../../common/utils/Rephrase'

const SaveCreateOrderButton = ({
  isReassignOrder,
  editing,
  outOfAppraisersAutomatic,
  checkValidity,
  selectedProducts,
  selectedAppraisers,
  jobType,
  xmlFile,
  pdfFile,
  submitting,
  primaryContactButton,
  values,
}) => {
  const [actionInProgress, setActionInProgress] = useState(false)
  const [currentValues, setCurrentValues] = useState(JSON.stringify(values))

  useEffect(() => {
    return () => setActionInProgress(false)
  }, [])

  useEffect(() => {
    // If the form was updated after an invalid submission, re-enable the button
    const newCurrentValues = JSON.stringify(values)
    if (actionInProgress) {
      if (newCurrentValues !== currentValues) {
        setActionInProgress(false)
      }
    }
    setCurrentValues(newCurrentValues)
  }, [actionInProgress, currentValues, values])

  const buttonOnClick = event => {
    if (actionInProgress) {
      return
    }

    setActionInProgress(true)
    // Manually click target, since button is now disabled
    event.target.click()
  }

  let buttonText
  if (isReassignOrder) {
    buttonText = 'Reassign'
  } else if (editing) {
    buttonText = 'Save'
  } else {
    buttonText = 'Create'
  }
  let buttonClass = ''
  if (primaryContactButton) {
    buttonClass = 'primaryContactButton'
  }

  const button = outOfAppraisersAutomatic ? (
    <Button
      disabled={actionInProgress || !checkValidity() || submitting}
      color="primary"
      onClick={buttonOnClick}
      type="submit"
    >
      Retry Automatic Assignment
    </Button>
  ) : (
    <Button
      className={buttonClass}
      type='submit'
      disabled={actionInProgress || !checkValidity() || submitting}
      color="primary"
      onClick={buttonOnClick}
    >
      {buttonText}
    </Button>
  )

  let toolTipContent
  if (selectedProducts[0] === null) {
    toolTipContent = 'Please select a product'
  } else if (selectedAppraisers.length === 0 && jobType === 'manually') {
    toolTipContent = 'Please select an appraiser'
  } else if (jobType === 'review' && xmlFile && !pdfFile) {
    // review runs on pdfs, so they cannot create a submission with only an xml
    toolTipContent = 'Review order submissions must include the PDF file.'
  }

  return toolTipContent ? (
    <div>
      <div className="toolTipWrapper" id="createSaveButton">
        {button}
      </div>
      <UncontrolledTooltip placement="top" target="createSaveButton">
        <Rephrase>{toolTipContent}</Rephrase>
      </UncontrolledTooltip>
    </div>
  ) : (
    button
  )
}

export default SaveCreateOrderButton
