import React, { useState } from 'react'
import { useApiRequest } from '../../common/hooks'

const UnansweredMessageInfo = (props) => {
  const [markedAnswered, setMarkedAnswered] = useState(false)

  const apiRequest = useApiRequest()

  const markAnswered = async (conversationId) => {
    await apiRequest({
      endpoint: 'order/messages/answered',
      method: 'PUT',
      body: { conversation_id: conversationId },
    })
    setMarkedAnswered(true)
  }

  const { primaryConversation, shouldGetMessages, is_amc_lender } = props

  let unansweredMessageInfo = <div></div>

  if (markedAnswered) {
    unansweredMessageInfo = <p>*Marked as answered</p>
  } else if (primaryConversation && shouldGetMessages) {
    if ((!is_amc_lender && primaryConversation.unanswered_messages) || primaryConversation.unanswered_amc_messages) {
      unansweredMessageInfo = (
        <p onClick={() => markAnswered(primaryConversation.id)}>
          <a>
            *<u>Mark this conversation as answered</u>
          </a>
        </p>
      )
    }
  }
  return (
    <div>
      {unansweredMessageInfo}
    </div>
  )
}

export default UnansweredMessageInfo
