import * as loading from './graph.svg'
import React from 'react'

export default function Loader(props) {
  return (
    <div className='loader-container' data-testid='loader'>
      <img width={100} src={loading}/>
    </div>
  )
}


/**
 * The default loader obscures the entire page, completely preventing a user from interacting
 * this component can be used to obscure the contents of a single element while the rest of the page
 * can be interacted with.
 */
export function SmallLoader() {
  return (
    <div className='small-loader-container w-100 p-4' role="image" aria-label='small-loader'>
      <img
        className='small-loader-spinner'
        src={loading}
        width={100}
      />
    </div>
  )
}
