import React from 'react'
import loadable from '@loadable/component'
import Loader from '../layout/components/Loader'

export const componentLoader = (importFunc, attemptsLeft) => new Promise((resolve, reject) => {
  importFunc()
    .then(resolve)
    .catch((error) => {
      // let us retry after 1500 ms
      setTimeout(() => {
        if (attemptsLeft === 1) {
          reject(error)
          return
        }
        componentLoader(importFunc, attemptsLeft - 1).then(resolve, reject)
      }, 1500)
    })
})

export const loadableWithRetry = (importFunc, retries = 3) => {
  return loadable(() => componentLoader(() => importFunc(), retries), {
    fallback: <Loader />,
  })
}
