export const SHOW_REFRESH_MODAL = 'SHOW_REFRESH_MODAL'
export const UPDATE_HAMBURGER_MENU = 'UPDATE_HAMBURGER_MENU'
export const REDIRECT_POST_LOGIN = 'REDIRECT_POST_LOGIN'
export const SET_LOAN_ID_FOR_ENCOMPASS = 'SET_LOAN_ID_FOR_ENCOMPASS'

export function showRefreshModal() {
  return {
    type: SHOW_REFRESH_MODAL,
    showRefreshModal: true,
  }
}

export function updateHamburgerMenu(status) {
  return {
    type: UPDATE_HAMBURGER_MENU,
    updateHamburgerMenu: status || 'open',
  }
}

export function redirectUrlPostLogin(redirect_url) {
  return {
    type: REDIRECT_POST_LOGIN,
    redirect_url,
  }
}

export function setLoanIdForEncompass(loanIdForEncompass) {
  return {
    type: SET_LOAN_ID_FOR_ENCOMPASS,
    loanIdForEncompass,
  }
}
