import React, { Component } from 'react'
import { CloseOutlined, FilterOutlined } from '@ant-design/icons'
import { Slider, Switch } from 'antd'
import { FormGroup } from 'reactstrap'

export class FilterSlider extends Component {
  state = {
    filterVisible: false,
    value: [this.props.settings.min, this.props.settings.max],
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef = (node) => {
    this.wrapperRef = node
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside = (event) => {
    const { filterVisible } = this.state
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && filterVisible) {
      this.setState({ filterVisible: false })
    }
  }

  /**
   * Filter the switch is on and the range slider is changed
   */
  slideFilterResults = () => {
    if (this.props.cu_filter) {
      this.props.cuHandler(true, this.state.value[0], this.state.value[1])
    }
  }

  /**
   * Filter the results when switch is clicked
   */
  filterResults = () => {
    if (this.props.cu_filter) {
      this.props.cuHandler(false, this.state.value[0], this.state.value[1])
    } else {
      this.props.cuHandler(true, this.state.value[0], this.state.value[1])
    }
  }

  render() {
    const { cu_filter, cu_min, cu_max } = this.props

    // Change icon to blue if there are filters used
    let icon = <i data-fa-transform="shrink-8" className='fas fa-filter' ></i>
    if (cu_filter) {
      icon = <i data-fa-transform="shrink-8" className='fas fa-filter' style={{ color: '#3C88FD' }}></i>
    }

    // Check for previous slider values
    let slider = <Slider
      range
      defaultValue={[this.props.settings.min, this.props.settings.max]}
      step={this.props.settings.step}
      min={this.props.settings.min}
      max={this.props.settings.max}
      onChange={value => this.setState({ value })}
      onAfterChange={this.slideFilterResults}
    />
    if (cu_min && cu_max) {
      const defaultValue = [cu_min, cu_max]
      slider = <Slider
        range
        defaultValue={defaultValue}
        step={this.props.settings.step}
        min={this.props.settings.min}
        max={this.props.settings.max}
        onChange={value => this.setState({ value })}
        onAfterChange={this.slideFilterResults}
      />
    }
    return (
      <div ref={this.setWrapperRef} className={'dropdown-container' + (this.state.filterVisible ? ' show' : '')}>
        <button
          className={'dropdown-display' + (this.state.filterVisible ? ' clicked' : '')}
          onClick={() => this.setState({ filterVisible: !this.state.filterVisible })}>
          {icon}
          <i className="fa fa-angle-down"></i>
        </button>
        <div className="dropdown-slider">
          <FormGroup className="filter-slider" check>
            {slider}
            <Switch onClick={this.filterResults}
              checked={cu_filter}
              unCheckedChildren={<FilterOutlined />}
              checkedChildren={<CloseOutlined />}
            />
          </FormGroup>
        </div>
      </div>
    )
  }
}
