import { Button, Card, Col, Row } from 'antd'
import {
  ModalBody,
  ModalCloser,
  ModalFooter,
  ModalHeader,
} from '@app/common/modals/antd/ModalComponents'
import React, { useMemo } from 'react'

import ReviewDatapoints from '../ReviewDatapoints'
import { RuleBadge } from '../RuleBadge'
import classnames from 'classnames'
import styles from './ReviewLogModal.module.css'
import { utcToLocal } from '@app/common/utils/helpers'
import { reviewLogType } from '../ReviewConstants'
import { USER_TYPES } from '@app/common/constants/userTypes'


/**
 * @param {Array.<Object>} [outcome_override_logs]
 */
const useParsedLogs = (outcome_override_logs) => {
  const logs = useMemo(() => {
    const logsReversed = Array.isArray(outcome_override_logs)
      ? outcome_override_logs.slice().reverse()
      : []

    return logsReversed.map((outcomeLog, index) => {
      const isLast = index === logsReversed.length - 1
      const isSystemLog = outcomeLog.user_type === USER_TYPES.SYSTEM
      const previousOutcomeLog =
        isLast || isSystemLog ? undefined : logsReversed[index + 1]

      return {
        outcomeLog,
        previousOutcomeLog,
        previousSubmission: isLast ? undefined : logsReversed[index + 1].submission_version,
      }
    })
  }, [outcome_override_logs])

  return logs
}

export const ReviewLogModal = ({ modalApi, fieldData }) => {
  const { currentUser, data_points_by_submission, desc, fieldName, outcome_override_logs } =
    fieldData
  const logs = useParsedLogs(outcome_override_logs)

  return (
    <>
      <ModalHeader title="Log" />
      <ModalCloser onClose={modalApi.cancel} />
      <ModalBody className={styles.modalBody}>
        <LogCardItem span={4} label="Field(s)">
          {fieldName}
        </LogCardItem>
        <LogCardItem span={4} className="mb-3" label="Findings">
          {desc}
        </LogCardItem>
        {logs.map(({ outcomeLog, previousOutcomeLog, previousSubmission }) => (
          <LogCard
            currentUser={currentUser}
            data_points={data_points_by_submission?.[outcomeLog.submission_version]}
            key={outcomeLog.created}
            outcomeLog={outcomeLog}
            previousOutcomeLog={previousOutcomeLog}
            previousSubmission={previousSubmission}
          />
        ))}
      </ModalBody>
      <ModalFooter>
        <Button type="default" onClick={modalApi.cancel}>
          Close
        </Button>
      </ModalFooter>
    </>
  )
}

const LogCard = ({
  currentUser,
  data_points,
  outcomeLog,
  previousOutcomeLog,
  previousSubmission,
}) => {
  const {
    created,
    display_outcome,
    outcome,
    user: originalUser,
  } = outcomeLog || {}
  const user = originalUser || currentUser

  return (
    <Card className="card">
      <Row align="middle" justify="space-between">
        <Col className={styles.tagContainer}>
          <span className="mr-2">
            Submission {outcomeLog.submission_version}
          </span>
          {previousOutcomeLog && (
            <>
              <RuleBadge
                display_outcome={previousOutcomeLog.display_outcome}
                outcome={previousOutcomeLog.outcome}
              />
              <span className="mr-2 ml-2">→</span>
            </>
          )}
          <RuleBadge display_outcome={display_outcome} outcome={outcome} />
        </Col>
      </Row>
      <div className={classnames('mt-3', styles.cardContent)}>
        {outcomeLog.user_type === USER_TYPES.SYSTEM ? (
          <>
            <SystemMessage log_type={outcomeLog.log_type} submission_version={previousSubmission} />
            <ReviewDatapoints data_points={data_points} />
          </>
        ) : (
          <>
            <LogCardItem label="Reviewer">{`${user?.firstname} ${user?.lastname}`}</LogCardItem>
            <LogCardItem label="Reviewed">
              {utcToLocal(created).format('lll')}
            </LogCardItem>
          </>
        )}
      </div>
    </Card>
  )
}

/**
 * @param {{ log_type: string, submission_version: number }} props
 */
const SystemMessage = ({ log_type, submission_version }) => {
  if (reviewLogType.PERSISTED_RETAINED !== log_type) { return null }

  return (
    <div>
      {`Action persisted from Submission ${submission_version}`}
    </div>
  )
}


const LogCardItem = ({ className, children, span = 5, label }) => {
  return (
    <Row className={className}>
      <Col className={styles.logCardItemLabel} span={span}>
        {label}:
      </Col>
      <Col>{children}</Col>
    </Row>
  )
}
