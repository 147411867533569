import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import API, { API_VERSION, REQUEST_METHOD } from '../../../api'
import STATUS from '../../../common/constants/reducer_statuses'

const initialState = {
  data: [],
  status: STATUS.IDLE,
  error: null,
}

export const fetchIntegrationLogs = createAsyncThunk(
  'integrationlogs/fetchIntegrationLogs',
  async (orderId, { rejectWithValue }) => {
    try {
      return await API(`integration-log/?id=${orderId}&status=failed`, API_VERSION.TWO).request(REQUEST_METHOD.GET, {}, {})
    } catch (err) {
      return rejectWithValue(err.errors._error)
    }
  },
)

const integrationLogsSlice = createSlice({
  name: 'integrationlogs',
  initialState,
  reducers: {
    resetIntegrationLogs: () => {
      return initialState
    },
  },
  extraReducers: {
    [fetchIntegrationLogs.pending]: (state, action) => {
      state.status = STATUS.LOADING
    },
    [fetchIntegrationLogs.fulfilled]: (state, action) => {
      state.status = STATUS.SUCCEEDED
      // Add any fetched posts to the array
      state.data = action.payload
    },
  },
})

export const { resetIntegrationLogs } = integrationLogsSlice.actions

export default integrationLogsSlice.reducer
