import React from 'react'

import {
  Tooltip,
} from 'antd'
import {
  Button,
} from 'reactstrap'

import BulkRevisionModal from './BulkRevisionModal'
import ModalTrigger from '../../../common/modals/ModalTrigger'
import { checkPermission } from '../../../common/utils/helpers'


export default function BulkRevisionButton(props) {
  // Displays button to open Bulk Revision Modal to request revisions based on GSE findings
  const {
    initializeRevisions,
    orderID,
    hasErrorsSelected,
  } = props
  const viewRevisionPermission = checkPermission('revisions_view')
  let disableRequestRevision = false
  let tooltipText = ''
  if (!viewRevisionPermission) {
    tooltipText = 'User does not have permission to request revisions.'
    disableRequestRevision = true
  } else if (!hasErrorsSelected) {
    tooltipText = 'Please select 1 or more errors in order to create revision request(s).'
    disableRequestRevision = true
  }

  const styleObject = { cursor: disableRequestRevision ? 'not-allowed' : 'pointer' }

  return (
    <Tooltip
      trigger={disableRequestRevision ? 'hover' : ''}
      placement='top'
      title={tooltipText}
    >
      <span>
        <ModalTrigger
          component={BulkRevisionModal}
          modalClassName='modal-primary'
          revisions={initializeRevisions()}
          order_id={orderID}
        >
          <Button
            disabled={disableRequestRevision}
            color='secondary'
            className='btn-secondary btn-sm mr-3'
            style={styleObject}
          >
            Request Revision(s) for Selected Findings
          </Button>
        </ModalTrigger>
      </span>
    </Tooltip>
  )
}
