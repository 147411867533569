import React, { useState, useEffect, useMemo } from 'react'
import { map, get, mapKeys } from 'lodash'
import { Modal, Tooltip, Spin } from 'antd'
import { CopyOutlined } from '@ant-design/icons'
import axios from 'axios'

const DEFAULT_ACTIVATION_KEY = 'H'
const DEFAULT_ACTIVATION_HOLD = 5000 // millieseconds
const DEFAULT_BE_PATH = 'info'

/*
  VerisionDetails - Secret Modal to show helpful info such as the git version/branch/commit of frontend and multiple backends
  Props:
  - activationKey: string // A single captical character designating the key to activate this secret modal, default to Big "H"
  - activationHold: number // The number of millieseconds the key must be held down to activate the modal, default to 5000
  - feDetails: { [key: string]: string } // The frontend details in the form of key-value pair object to be displayed in the modal.  
  - beFetches: {
      url: string // the endpoint to call to get the detail, like the healthchecks
      path: string // the dotted path of the response data to extra the details from, default to "info"
      prefix: string // A string to add to the front of each key in the response details
    }[] // an array of the backend fetching details to call to get backend info.  

  Note: The activation of the modal requires holding down the shift key + the activation key for a duration longer than the hold time.
        The backend calls are made one after another, and only made after this modal is activated.
*/
const VersionDetails = ({ activationKey, activationHold, feDetails, beFetches }) => {
  const [showModal, setShowModal] = useState(false)
  const [holdTime, setHoldTime] = useState({})
  const [copied, setCopied] = useState(false)
  const [beDetails, setBeDetails] = useState(undefined)


  useEffect(() => {
    const checkHold = (e) => {
      if (e.key === (activationKey ?? DEFAULT_ACTIVATION_KEY) && e.repeat && e.shiftKey) {
        if (holdTime.duration && holdTime.duration > (activationHold ?? DEFAULT_ACTIVATION_HOLD)) {
          setShowModal(true)
          setHoldTime({})
        } else if (holdTime.start) {
          setHoldTime({ ...holdTime, duration: Date.now() - holdTime.start })
        } else {
          setHoldTime({ start: Date.now() })
        }
      } else {
        setHoldTime({})
      }
    }

    document.addEventListener('keydown', checkHold)
    return () => {
      document.removeEventListener('keydown', checkHold)
    }
  }, [holdTime])

  useEffect(() => {
    const fetchBeDetails = async () => {
      for (const beFetch of beFetches) {
        const { url, prefix, path } = beFetch
        try {
          const response = await axios.request({
            headers: {
              'content-type': 'application/json',
            },
            method: 'get',
            url,
          })
          const info = get(response, `data.${path || DEFAULT_BE_PATH}`, {})
          setBeDetails({ ...(beDetails || {}), ...mapKeys(info, (_, key) => `${prefix} ${key}`) })
        } catch (err) {
          setBeDetails(beDetails || {})
        }
      }
    }
    if (showModal && beFetches && beFetches.length) {
      fetchBeDetails()
    } else {
      setBeDetails(undefined)
    }
  }, [beFetches, showModal])

  const details = useMemo(() => ({ ...feDetails, ...(beDetails || {}) }), [feDetails, beDetails])

  const handleCopy = () => {
    navigator.clipboard.writeText(map(details, (value, key) => `${key}: ${value}`).join('\n'))
    setCopied(true)
    setTimeout(() => setCopied(false), 1000)
  }

  return (
    <Modal
      visible={showModal}
      title="Version Details"
      onCancel={() => setShowModal(false)}
      footer={null}
      style={{ top: 20 }}
      zIndex={99980}
      destroyOnClose
    >
      <Tooltip style={{ float: 'right' }} visible={copied} title="Copied" zIndex={99990} placement="bottom">
        <CopyOutlined onClick={handleCopy} style={{ fontSize: '20px', float: 'right' }} />
      </Tooltip>
      <table>
        <tbody>
          {map(details, (value, key) => (
            <tr key={key}><td>{`${key}:`}</td><td><strong>{value}</strong></td></tr>
          ))}
        </tbody>
      </table>
      {beDetails === undefined && <Spin size="small" />}
    </Modal>
  )
}

export default VersionDetails
