import { of } from 'rxjs/observable/of'

import { setOrderDataFromSocket } from '../../common/socket/notificationHandler'
import { newMessage } from '../reducers/conversation/conversationSlice'
import {
  selectAllOrderData,
} from '../reducers/order/orderSelectors'

export default function newMessageEpic(action$, store) {
  return action$.ofType(newMessage.type).mergeMap((action) => {
    const { orderId, message, conversationType, subOrderId } = action.payload
    const state = store.getState()
    // Get Order
    const order = selectAllOrderData(state)


    if (!order) {
      return []
    }

    const currentUserID = state.resource.user.data.id
    let increment_unread = 1
    if (message.sender && currentUserID === message.sender.id) {
      increment_unread = 0
    }

    let update, subOrder, orderIndex
    if (subOrderId) {
      subOrder = order.follow_up_orders.find((o) => {
        return o.id === subOrderId
      })
      orderIndex = order.follow_up_orders.findIndex((o) => {
        return o.id === subOrderId
      })
    }

    if (subOrder) {
      update = {
        ...order,
      }

      const primaryFollowUp = update.follow_up_orders[orderIndex]
      if (primaryFollowUp[conversationType]) {
        // if the order is in waiting for payment, just append the new message to the current_vendor_messages array
        if (conversationType === 'current_vendor_messages') {
          update.follow_up_orders[orderIndex] = {
            ...primaryFollowUp,
            [conversationType]: [...primaryFollowUp[conversationType], message],
          }
        } else {
          // if the order is not in waiting for payment, add the new message to the conversation object
          update.follow_up_orders[orderIndex] = {
            ...primaryFollowUp,
            [conversationType]: {
              ...primaryFollowUp[conversationType],
              unread_messages:
                parseInt(primaryFollowUp[conversationType].unread_messages) +
                increment_unread,
              messages: [
                ...primaryFollowUp[conversationType].messages,
                message,
              ],
            },
          }
        }
        // If this field is defined, or if it is 0 then we have the order in the table view and we need to update this unread count
      } else if (
        primaryFollowUp.unread_messages ||
        primaryFollowUp.unread_messages === 0
      ) {
        update.follow_up_orders[orderIndex].unread_messages += increment_unread
      } else {
        return []
      }
    } else if (
      conversationType &&
      conversationType === 'current_vendor_messages'
    ) {
      // When a new message is sent, we use the update variable to compare existing current_vendor_messages with new update info & render an "updated" list
      if (order[conversationType]) {
        update = {
          [conversationType]: [...order[conversationType], message],
        }
      } else {
        // If order is WFP and current_vendor_messages is null
        update = {
          [conversationType]: [message],
        }
      }
    } else {
      if (conversationType.indexOf('offer') > -1) {
        const requestId = conversationType.split('.')[1]
        const assignment_list = order.assignment_list.map(
          (request) => {
            if (request.id !== requestId) {
              return request
            }

            return {
              ...request,
              conversation: {
                ...request.conversation,
                unread_messages:
                  parseInt(request.conversation.unread_messages) +
                  increment_unread,
                messages: [...request.conversation.messages, message],
              },
            }
          },
        )

        update = {
          assignment_list,
        }
      } else if (order.unread_messages || order.unread_messages === 0) {
        update = {
          unread_messages: (order.unread_messages += increment_unread),
        }
      } else {
        return []
      }
    }

    if (update) {
      return of(setOrderDataFromSocket(orderId, update))
    }

    return []
  })
}
