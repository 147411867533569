// Libraries
import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { DeleteOutlined, EditOutlined, UserOutlined } from '@ant-design/icons'
import { Alert, Avatar, Button, Input, Tooltip } from 'antd'

// Common
import { connectResource } from '../../common/utils/resource'
import { utcToLocal, checkPermission } from '../../common/utils/helpers'

import CommentTemplateDropdown from './CommentTemplateDropdown'
import Rephrase from '../../common/utils/Rephrase'

const InputGroup = Input.Group
const { TextArea } = Input

class Notes extends Component {
  state = {
    editing: null,
    editingNote: null,
    note: '',
    notes: this.props.notes || [],
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.notes !== this.props.notes) {
      this.setState({
        notes: this.props.notes,
      })
    }
  }

  editNote = (noteId, editingNote) => {
    this.setState({ editing: noteId, editingNote: editingNote })
  }

  saveNote = (idx) => {
    const {
      editingNote,
      notes,
      editing,
    } = this.state
    const { orderId } = this.props
    let promise
    if (orderId) {
      promise = this.props.note.put({
        note: editingNote,
        note_id: editing,
      })
    } else if (this.props.productId) {
      promise = this.props.productNote.put({
        note: editingNote,
        note_id: editing,
      })
    } else if (this.props.insideCreateFee) {
      this.props.handleEditNote(idx, editingNote)

      const updatedNote = {
        id: this.state.editing,
        note: editingNote,
      }

      const updatedNotes = [
        ...notes.slice(0, idx),
        updatedNote,
        ...notes.slice(idx + 1),
      ]

      this.setState({
        editingNote: '',
        notes: updatedNotes,
        editing: false,
      })
      return
    } else {
      promise = this.props.additionalNote.put({
        note: editingNote,
        note_id: editing,
      })
    }

    promise.then(({ note }) => {
      notes[idx].note = editingNote
      this.setState({ editingNote: '', notes, editing: false })
    })
  }

  postNote = () => {
    const {
      note,
      notes,
    } = this.state
    const { orderId } = this.props
    let promise

    if (orderId) {
      promise = this.props.note.create({
        note,
      })
    } else if (this.props.productId) {
      promise = this.props.productNote.create({
        note,
      })
    } else if (this.props.insideCreateFee) {
      const randomId = Math.floor((Math.random() * 1000000) + 1)

      const newNote = {
        note,
        id: randomId,
      }

      this.props.handleAddNote(newNote)

      this.setState({
        note: '',
        notes: [...notes, newNote],
      })
      return
    } else {
      promise = this.props.additionalNote.create({
        note,
      })
    }

    promise.then(({ note }) => {
      this.setState({ note: '', notes: [...notes, note] })
    })
  }

  deleteNote = (idx) => {
    const {
      notes,
    } = this.state
    const { orderId } = this.props

    if (orderId) {
      this.props.note.remove({
        note_id: notes[idx].id,
      })
    } else if (this.props.productId) {
      this.props.productNote.remove({
        note_id: notes[idx].id,
      })
    } else if (this.props.insideCreateFee) {
      this.props.handleDeleteNote(idx)
    } else {
      this.props.additionalNote.remove({
        note_id: notes[idx].id,
      })
    }
    const updatedNotes = [
      ...notes.slice(0, idx),
      ...notes.slice(idx + 1),
    ]
    this.setState({ notes: updatedNotes })
  }

  handleChange = ({ target: { name, value } }) => this.setState({ [name]: value })

  render() {
    const {
      note,
      notes,
    } = this.state

    const { orderId } = this.props

    const style = this.props.productId || this.props.insideCreateFee ? { width: '100%', marginLeft: '1rem', marginRight: '1rem' } : { justifyContent: 'flex-start' }

    return (
      <Fragment>
        <div className='notes-container' style={style}>
          {notes.length > 0 ? orderId && <div></div> : <Alert className='no-notes-alert' message={orderId ? <Rephrase>Internal Notes is a place to store information on a file internally as a team. This is not shared with appraisers.</Rephrase> : 'Add additional internal notes to your order forms below'} type="info" />}

          {notes.map((note, idx) => {
            return (
              <div key={idx} className='order-note'>
                { orderId && <div className='note-header'>
                  <Avatar icon={<UserOutlined />} />
                </div> }
                <div className={`note-right ${!orderId ? 'p-0' : ''}`}>
                  { orderId &&
                  <div className='note-content-header'>
                    <span className='note-user'>{note.user.name}</span>
                    <small className='note-date'>{utcToLocal(note.timestamp).format('MM/DD/YYYY')}</small>
                  </div>
                  }
                  <div className='note-body'>
                    <span className='note-content'>{this.state.editing === note.id ? <TextArea value={this.state.editingNote} onChange={this.handleChange} name="editingNote" autosize /> : note.note}</span>


                    { (note.user && note.user.id === this.props.currentUserId) || !orderId ? <div className='note-actions'>

                      { this.state.editing === note.id ? <Button type='primary' size='small' onClick={() => this.saveNote(idx)}>Save</Button>
                        : <span>
                          {checkPermission('notes_change') && <Tooltip title='Edit'>
                            <EditOutlined onClick={() => this.editNote(note.id, note.note)} />
                          </Tooltip>}
                          {checkPermission('notes_change') && <Tooltip title='Delete'>
                            <DeleteOutlined onClick={() => this.deleteNote(idx)} />
                          </Tooltip>}
                        </span>
                      }

                    </div>
                      : null}
                  </div>
                </div>
              </div>
            )
          })}

        </div>
        {(!orderId || checkPermission('notes_change')) && <InputGroup className='create-note' style={style}>
          <Input style={{ width: '80%' }} placeholder="Create a new note" name="note" value={note} onChange={this.handleChange} />
          <Button type='primary' style={{ width: '20%' }} disabled={!note} onClick={this.postNote}>Add Note</Button>
        </InputGroup>}
        {(!orderId || checkPermission('notes_change')) &&
         <div className='ml-auto mt-2'>
           <CommentTemplateDropdown
             name={'internal-notes-templates-list'}
             onHide={this.props.onHide}
             refreshOrder={this.props.refreshOrder}
             antdStyle={true}
             setTextToInsert={
               (templateText) => !this.state.note || this.state.note.slice(-1) === ' '
                 ? this.setState({ note: this.state.note + templateText })
                 : this.setState({ note: this.state.note + ' ' + templateText })
             }
           />
         </div>
        }
      </Fragment>
    )
  }
}

export default compose(
  connectResource(
    { namespace: 'note', endpoint: 'order/:orderId/note', successMessage: { POST: 'Note Added!' }, prefetch: false, refresh: true, async: true, useRouter: true, apiVersion: 2 }
  ),
  connectResource(
    { namespace: 'additionalNote', endpoint: 'lender/additional-note', successMessage: { POST: 'Note Added!' }, prefetch: false, refresh: true, async: true, useRouter: true, apiVersion: 2 }
  ),
  connectResource(
    { namespace: 'productNote', endpoint: 'fee/:productId/note', successMessage: { POST: 'Note Added!' }, prefetch: false, refresh: true, async: true, useRouter: true, apiVersion: 2 }
  )
)(Notes)
