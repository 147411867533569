import {
  SHOW_REFRESH_MODAL,
  UPDATE_HAMBURGER_MENU,
  REDIRECT_POST_LOGIN,
  SET_LOAN_ID_FOR_ENCOMPASS,
} from '../../common/socket/actions'

const defaultState = {
  showRefreshModal: false,
  updateHamburgerMenu: 'default',
  redirect_url: '',
  loanIdForEncompass: null,
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case SHOW_REFRESH_MODAL:
      return {
        ...state,
        showRefreshModal: action.showRefreshModal,
      }
    case UPDATE_HAMBURGER_MENU:
      return {
        ...state,
        updateHamburgerMenu: action.updateHamburgerMenu,
      }
    case REDIRECT_POST_LOGIN:
      return {
        ...state,
        redirect_url: action.redirect_url,
      }
    case SET_LOAN_ID_FOR_ENCOMPASS:
      return {
        ...state,
        loanIdForEncompass: action.loanIdForEncompass,
      }
    default:
      return state
  }
}
