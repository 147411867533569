import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import API, { API_VERSION, REQUEST_METHOD } from '../../../api'
import STATUS from '../../../common/constants/reducer_statuses'

export const initialState = {
  client_sso_Id: null,
  user: null,
}

export const fetchSsoClient = createAsyncThunk('login/fetchSsoClient', async (client_sso_key, { rejectWithValue }) => {
  try {
    const ssoClient = await API(`v1/auth/sso-settings/v1?sso_client_key=${client_sso_key}`, API_VERSION.SERVICE).request(REQUEST_METHOD.GET, {}, {})
    return {
      ssoClient,
    }
  } catch (err) {
    return rejectWithValue('VALUE', err.errors._error)
  }
})

export const fetchSsoClientUser = createAsyncThunk('login/fetchSsoClientEmail', async (params, { rejectWithValue }) => {
  try {
    const { user_email, lender } = params
    const endpointParam = lender ? `lender/v1?lender=${lender}` : `v1?email=${encodeURIComponent(user_email)}`
    const ssoClientUser = await API(`v1/auth/sso-settings/${endpointParam}`, API_VERSION.SERVICE).request(REQUEST_METHOD.GET, {}, {})
    return {
      ssoClientUser,
    }
  } catch (err) {
    return rejectWithValue('VALUE', err)
  }
})

const loginSsoSlice = createSlice({
  name: 'client_sso_key',
  initialState,
  reducers: {
    resetsso: () => {
      return initialState
    },
  },
  extraReducers: {
    [fetchSsoClient.pending]: state => {
      state.status = STATUS.LOADING
    },
    [fetchSsoClient.fulfilled]: (state, action) => {
      state.status = STATUS.SUCCEEDED
      // Add any fetched posts to the array
      state.client_sso_Id = action.payload.client_sso_Id || ''
    },
    [fetchSsoClient.rejected]: (state, action) => {
      state.status = STATUS.FAILED
      state.error = action.error.message
    },
    [fetchSsoClientUser.pending]: state => {
      state.status = STATUS.LOADING
    },
    [fetchSsoClientUser.fulfilled]: (state, action) => {
      state.status = STATUS.SUCCEEDED
      state.user = action.payload.user
    },
    [fetchSsoClientUser.rejected]: (state, action) => {
      state.status = STATUS.FAILED
      state.error = action.error.message
    },
  },
})

export const { resetsso } = loginSsoSlice.actions

export default loginSsoSlice.reducer
