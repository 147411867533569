import React from 'react'
import { Popover } from 'antd'
import { Link } from '../../common/router'
import { checkPermission, showBadVendorReason, getOutOfOfficeMessage } from '../../common/utils/helpers'

const AppraiserNameWithPopover = ({ panelistName, appraiser = {} }) => {
  const firmIcon = (<i className="fa fa-building" aria-hidden="true"></i>)
  const phoneIcon = (<i className="fa fa-phone-square" aria-hidden="true"></i>)
  const emailIcon = (<i className="fa fa-envelope" aria-hidden="true"></i>)
  const declineIcon = (<i className="fas fa-times-circle"></i>)
  const reasonIcon = (<i className="fas fa-ban"></i>)
  const outOfOfficeIcon = (<i className="far fa-calendar-times"></i>)
  const badVendorReason = showBadVendorReason(appraiser)

  let full_text = ''
  if (badVendorReason) {
    full_text = appraiser.bad_vendor_reasons.join(', ')
  }

  const outOfOfficeMessage = getOutOfOfficeMessage(appraiser)

  const popoverContent = (
    <div>
      {/* add firm name if panelist is appraiser panelist */}
      {appraiser.panelist_type === 'appraiser' && <div>
        <span className='col-1 p-0'>{firmIcon}</span>
        <span className='col-11'>{appraiser.firm_name}</span>
      </div>
      }
      <div>
        <span className='col-1 p-0'>{phoneIcon}</span>
        <span className='col-11'>{appraiser.phone ? appraiser.phone : 'N/A'}</span>
      </div>
      <div>
        <span className='col-1 p-0'>{emailIcon}</span>
        <span className='col-11'>{appraiser.email ? appraiser.email : 'N/A'}</span>
      </div>

      {outOfOfficeMessage && <div>
        <span className='col-1 p-0'>{outOfOfficeIcon}</span>
        <span className='col-11'>{outOfOfficeMessage}</span>
      </div>
      }

      {/* add decline reason if necessary */}
      {appraiser.is_denied && <div>
        <span className='col-1 p-0'>{declineIcon}</span>
        <span className='col-11'>{appraiser.denied_reason ? `Declined Reason: ${appraiser.denied_reason}` : 'Declined Reason: N/A'}</span>
      </div>
      }

      {/* Add additional appraiser info if needed */}
      {badVendorReason && <div>
        <span className='col-1 p-0'>{reasonIcon}</span>
        <span className='col-11'>{full_text || 'N/A'}</span>
      </div>
      }
    </div>
  )

  const linkLocation = appraiser.link_location || appraiser.id || 'none'

  // Appraiser Link Styling
  let nameLinkStyle = {}
  if (appraiser.is_denied) {
    nameLinkStyle = { color: 'red' }
  } else if (badVendorReason || appraiser.is_out_of_office) {
    nameLinkStyle = {
      color: 'grey',
    }
  }

  const firmName = checkPermission('singleAppraiser_route')
    ? (<div className='pl-1'><Link style={nameLinkStyle} target={'_blank'} to={'singleAppraiser'} id={linkLocation}>{panelistName}</Link></div>)
    : (<div>{panelistName}</div>)

  return (
    <Popover content={popoverContent} title={panelistName}>
      {firmName}
    </Popover>
  )
}

export default AppraiserNameWithPopover
