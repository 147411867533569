import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import API, { API_VERSION } from '../../../api'

export const initialState = {
  scoreRecord: null,
  showModal: false,
  status: 'init',
  error: undefined,
}

const VENDOR_SCORES_ROUTE = 'vendor-score/v1'

const fetchVendorScore = async ({ orderId, panelistId }, { rejectWithValue }) => {
  try {
    return await API(VENDOR_SCORES_ROUTE, API_VERSION.SERVICE).get({
      order_id: orderId,
      panelist_id: panelistId,
      limit: 1,
    })
  } catch (err) {
    return rejectWithValue(err)
  }
}

const submitVendorScore = async (vendorRecord, { rejectWithValue }) => {
  const { _id: id, score, notes } = vendorRecord
  try {
    if (id !== undefined) {
      return await API(`${VENDOR_SCORES_ROUTE}/${id}`, API_VERSION.SERVICE).patch({ score, notes })
    }
    return await API(VENDOR_SCORES_ROUTE, API_VERSION.SERVICE).post(vendorRecord)
  } catch (err) {
    return rejectWithValue(err)
  }
}

export const addScore = createAsyncThunk('vendorScore/addScore', fetchVendorScore)

export const editScore = createAsyncThunk('vendorScore/editScore', fetchVendorScore)

export const submitScore = createAsyncThunk('vendorScore/submitScore', submitVendorScore)

const setLoading = (state) => {
  state.status = 'loading'
  state.error = undefined
}

const installScoreRecord = (state, action) => {
  const { results } = action.payload
  let hasRecord = false
  if (results && results.length) {
    state.scoreRecord = results[0]
    hasRecord = true
  } else {
    state.scoreRecord = null
  }
  state.status = 'loaded'
  return hasRecord
}

const installFailedMessage = (state, action) => {
  const { message, _error } = action.payload || {}
  state.status = 'failed'
  state.error = _error || message
}

const vendorScoreSlice = createSlice({
  name: 'vendorScore',
  initialState,
  reducers: {
    closeModal: () => initialState,
  },
  extraReducers: {
    [addScore.pending]: setLoading,
    [addScore.fulfilled]: (state, action) => {
      const hasRecord = installScoreRecord(state, action)
      if (!hasRecord) {
        state.showModal = true
      }
    },
    [addScore.rejected]: installFailedMessage,
    [editScore.pending]: setLoading,
    [editScore.fulfilled]: (state, action) => {
      installScoreRecord(state, action)
      state.showModal = true
    },
    [editScore.rejected]: installFailedMessage,
    [submitScore.pending]: (state) => {
      state.status = 'submitting'
    },
    [submitScore.fulfilled]: (state, action) => {
      installScoreRecord(state, action)
      state.status = 'submitted'
    },
    [submitScore.rejected]: installFailedMessage,
  },
})

export const { closeModal } = vendorScoreSlice.actions

export default vendorScoreSlice.reducer
