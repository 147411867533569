import React from 'react'

const toggleswitchInput = (props) => {
  return (
    <div className='input-wrapper'>
      <label className='toggle-switch'>
        <input
          type='checkbox'
          checked={props.value}
          onChange={e => props.onToggleChange(e.target.checked)}
        />
        <span className="toggle-slider"></span>
      </label>
    </div>
  )
}


export default toggleswitchInput
