import jwtDecode from 'jwt-decode'
import { of } from 'rxjs/observable/of'
import q from 'querystring'

import {
  setData,
  TOKEN_PRESENT_ON_MOUNT,
} from '../../common/utils/resource'

export default function processToken(action$, store, { API }) {
  return action$.ofType(TOKEN_PRESENT_ON_MOUNT).mergeMap(action => {
    // Prefer token from URL.
    const token = q.parse(window.location.search)['?token'] || action.token
    if (typeof token !== 'string') {
      localStorage.setItem('reggora_lender_auth_token', '')
    } else {
      const decoded = jwtDecode(token)
      const expirationDate = Date.parse(decoded.exp)
      const now = new Date()
      if (now > expirationDate) {
        localStorage.setItem('reggora_lender_auth_token', '')
      } else {
        // Store token so that fetching user has correct token (in case it was on URL).
        localStorage.setItem('reggora_lender_auth_token', token)
        // valid token, set it on store which will trigger getting user
        return of(setData({ token }, { resource: { namespace: 'session' } }))
      }
    }
    return []
  })
}
