import React, { Fragment, useEffect } from 'react'
import { ModalHeader, ModalBody, FormGroup, Col, Label, ModalFooter, Button } from 'reactstrap'

export default function PaymentDeleteModal(props) {
  useEffect(() => {
    const { analytics_track_component, analytics_data } = props
    // Segment tracking
    const { firstname, lastname, role, lender } = analytics_data
    analytics_track_component('Opened Modal', {
      user: `${firstname} ${lastname}`,
      role: role.name,
      lender: lender.name,
      component: PaymentDeleteModal.name,
    })
  }, [])

  // Segment tracking
  function trackSubmit() {
    const { analytics_track_component, analytics_data } = props
    const { firstname, lastname, role, lender } = analytics_data
    analytics_track_component('Submitted Delete', {
      user: `${firstname} ${lastname}`,
      role: role.name,
      lender: lender.name,
      component: PaymentDeleteModal.name,
    })
  }

  return (
    <Fragment>
      <ModalHeader toggle={props.onHide}>Remove Payment</ModalHeader>
      <ModalBody>
        <FormGroup row>
          <Col xs="12">
            <Label>Are you sure you want to delete this payment? Any associated fees paid through your payment provider will be refunded.</Label>
          </Col>
        </FormGroup>
      </ModalBody>
      <ModalFooter className="border-0">
        <Button onClick={props.onHide} color="secondary">Cancel</Button>
        <Button color='danger' onClick={e => { props.deletePayment(props.payment); trackSubmit() }}>Delete</Button>
      </ModalFooter>
    </Fragment>
  )
}
