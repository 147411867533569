import React from 'react'
import { useDispatch } from 'react-redux'
import ModalConfirmation from '../../../../common/modals/ModalConfirmation'
import { postSubmissionReview } from '../../../../store/reducers/submissionReviews/submissionReviewsSlice'
export default function ConfirmReviewEngineModal(props) {
  const { order_id, submission_version, hasPreviousReviews, is_amc_lender, viewWarrantyReviews, ...restOfProps } = props
  const dispatch = useDispatch()
  let confirmationText = 'Confirm Re-evaluate Reggora Review'
  let textBody = 'Submitting for automated review will overwrite the review progress for the submission. This includes Comments, Actions and Overridden statuses.'
  if (!hasPreviousReviews) {
    confirmationText = 'Confirm Reggora Review Evaluation'
    textBody = ''
  }
  const onConfirm = () => {
    const submission_review_types = viewWarrantyReviews ? ['repurchase_warranty'] : (is_amc_lender ? ['amp'] : ['lender'])
    dispatch(postSubmissionReview({
      orderId: order_id,
      submissionVersion: submission_version,
      submissionReviewTypes: submission_review_types,
    }))
    restOfProps.onHide()
  }

  return (
    <ModalConfirmation
      confirmationText={confirmationText}
      textBody={textBody}
      dismissBtn={'Cancel'}
      confirmBtn={'Confirm'}
      onConfirm={onConfirm}
      {...restOfProps}
    />
  )
}
