import React from 'react'
import {
  Tooltip,
} from 'antd'
import {
  Button,
} from 'reactstrap'

import ModalTrigger from '../../../common/modals/ModalTrigger'
import SubmitOverrideRequestModal from './SubmitOverrideRequestModal'
import { REGGORA_SUPPORT_OVERRIDES_ERRORS } from '../../../common/constants/gse'

export default function OverrideRequestButton(props) {
  const {
    orderId,
    gseType,
    refreshOrder,
    version,
    appraisalNumber,
    hardStopsCodes,
    selectedRevisions,
  } = props

  var toolTipText = 'You have not selected an overridable error'
  var enableOverrideModal = false

  if (hardStopsCodes.length >= 1 && hardStopsCodes.length !== selectedRevisions.length) {
    toolTipText = 'You have selected 1 or more errors that cannot be overridden'
    enableOverrideModal = false
  }
  if (hardStopsCodes.length >= 1 && hardStopsCodes.length === selectedRevisions.length) {
    enableOverrideModal = true
  }
  hardStopsCodes.forEach(hardStopsCode => {
    if (!REGGORA_SUPPORT_OVERRIDES_ERRORS.includes(hardStopsCode.code)) {
      enableOverrideModal = false
    }
  })

  return (
    <Tooltip trigger={ !enableOverrideModal ? 'hover' : ''}
      placement="top" title={toolTipText}>
      <span style={{ cursor: !enableOverrideModal ? 'not-allowed' : 'pointer' }} >
        <ModalTrigger
          component={SubmitOverrideRequestModal}
          modalClassName='modal-primary'
          orderId={orderId}
          gseType={gseType}
          hardStopsCodes={hardStopsCodes}
          refreshOrder={refreshOrder}
          version={version}
          appraisalNumber={appraisalNumber}
        >
          <Button
            disabled={ !enableOverrideModal }
            color='secondary'
            className='btn-secondary btn-sm mr-3'
            style={{ pointerEvents: !enableOverrideModal ? 'none' : 'auto' }}
          >
            Request GSE override
          </Button>
        </ModalTrigger>
      </span>
    </Tooltip>
  )
}
