import notificationsViewed from './notificationsViewed'
import notificationsDecline from './notificationsDecline'
import notificationsNew from './notificationsNew'
import newMessage from './newMessage'
import createCsvAppraisers from './createAppraiserCsv'
import createCsvLoans from './createLoansCsv'
import createCsvUsers from './createUsersCsv'
import newFile from './newFile'
import loginGetUser from './loginGetUser'
import processToken from './processToken'
import setCSVUploadSettings from './setCSVUploadSettings'
import updateResource from './updateResource'
import mapOverlays from './mapOverlays'
import updateBranchZone from './updateBranchZone'
import updateAppraiserExclusionaryListEpic from './updateAppraiserExclusionaryList'

const epics = [
  notificationsViewed,
  notificationsDecline,
  notificationsNew,
  newMessage,
  createCsvAppraisers,
  createCsvLoans,
  createCsvUsers,
  newFile,
  loginGetUser,
  processToken,
  setCSVUploadSettings,
  updateResource,
  mapOverlays,
  updateBranchZone,
  updateAppraiserExclusionaryListEpic,
]

export default epics
