import React, { Fragment } from 'react'
import { Avatar, Comment } from 'antd'
import moment from 'moment'


export default function CommentThread(props) {
  const { userId, firstName, lastName, timestamp, messageBody } = props

  // This function hashes the id consistently into a number between 0 - 360 for the colorPicker
  // The color we assign will be the same each time.
  const hashTheId = (id) => {
    let hash = 0
    let chr
    if (id.length === 0) {
      return hash
    }
    for (let i = 0; i < id.length; i++) {
      chr = id.charCodeAt(i)
      hash = ((hash << 5) - hash) + chr
      hash |= 0 // Convert to 32bit integer
    }
    hash %= Math.abs(360)
    return hash
  }

  const divStyle = { color: 'white', backgroundColor: `hsl(${hashTheId(userId)}deg, 41%, 46%)` }

  // Format the timeStamp

  const date = moment(timestamp)
    .format('YYYY-MM-DD hh:mm a')

  return (
    <Fragment>
      <Comment
        className="comment-thread"
        author={firstName + ' ' + lastName}
        avatar={<Avatar size={32} shape='circle' style={ divStyle }>{firstName.charAt(0)}{lastName.charAt(0)}</Avatar>}
        content={
          <p className="comment-body-text">
            { messageBody }
          </p>
        }
        datetime={ date }
      />
    </Fragment>
  )
}
