/* eslint-disable no-unused-vars */
// Libraries
import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { Alert, Button } from 'reactstrap'

// Common
import { connectResource } from '../../common/utils/resource'
import { checkPermission } from '../../common/utils/helpers'

const error_types = {
  manual_coe_needed: 'We were unable to pull the COE from the VA portal automatically. Please double check the SSN and year of birth of the veteran. If you believe this to be a mistake you can force Reggora to try again, or proceed without the COE if it is pending.',
  primary_obligor_not_found: "We were unable to place this order in the VA portal automatically. This is because either the COE has not been requested from the VA portal, or the veteran's data has not been entered properly in Reggora. Please check and try again.",
}

const error_action_types = {
  manual_coe_needed: 'overrideCOE',
}
class VAAlerts extends Component {
  processActions = (va_actions) => {
    let errors = []
    const additionalActions = []
    for (const action of va_actions) {
      if (!action.data_validated) {
        if (action.errors && action.errors.length > 0) {
          errors = action.errors.map(e => {
            if (e.error_type) {
              if (error_action_types[e.error_type]) {
                additionalActions.push(error_action_types[e.error_type])
              }
              if (e.error_type === 'missing_information') {
                return `The following field is missing data: ${this.props.vaFieldsToReadable[e.field]}`
              } else if (e.error_type === 'invalid_information') {
                return `The following field has invalid data: ${this.props.vaFieldsToReadable[e.field]}`
              }
              return error_types[e.error_type]
            } else {
              return e.description
            }
          })
        }
      }
    }

    return {
      errors,
      additionalActions,
    }
  }

  onFileSubmit = (files) => {
    this.props.uploadCOE.put({ file: files })
      .then(data => {
        this.props.refreshOrder()
      }).catch(_ => {
        this.props.refreshOrder()
      })
  }

  onOverrideCOE = () => {
    this.props.overrideCOE.put()
      .then(data => {
        this.props.refreshOrder()
      }).catch(_ => {
        this.props.refreshOrder()
      })
  }

  isDataProcessing = (va_actions) => {
    for (const action of va_actions) {
      if (!action.data_validated) {
        if (action.data_validation_resolved) {
          return true
        }
      }
    }
  }

  validateData = () => {
    this.props.validateVAData.put().then(_ => {
      this.props.refreshOrder()
    })
  }

  render() {
    const {
      va_actions,
    } = this.props
    const processedActions = this.processActions(va_actions)
    const errors = processedActions.errors
    const additionalActions = processedActions.additionalActions
    if (errors.length < 1) { return null }
    return (
      <Alert color="primary">
        {!this.isDataProcessing(va_actions)
          ? <Fragment>
            Please review the following issues before submission to the VA portal:
            <ul>
              {errors.map((e, index) => <li key={index}>{e}</li>)}
            </ul>
            <span className='flex flex-row'>
              <Button disabled={this.props.validateVAData.isLoading || this.props.overrideCOE.isLoading}
                color="dark"
                className='my-2'
                onClick={_ => this.validateData()}>
                Try Again
              </Button>
              { additionalActions.indexOf('overrideCOE') > -1 &&
                checkPermission('order_proceed_without_active_coe_access') &&
                <Button
                  disabled={this.props.validateVAData.isLoading || this.props.overrideCOE.isLoading}
                  color="success"
                  className='my-2 ml-3'
                  onClick={_ => this.onOverrideCOE()}>
                    Proceed without active COE
                </Button>
              }
            </span>
          </Fragment>
          : 'Prepping data for submission to the VA portal.' }
      </Alert>)
  }
}

export default compose(
  connectResource({
    namespace: 'validateVAData',
    endpoint: 'order/validateVAData/:id',
    successMessage: { PUT: 'Revalidating VA data.' },
    prefetch: false,
    refresh: true,
    async: true,
    useRouter: true,
    apiVersion: 2,
  }),
  connectResource({
    namespace: 'overrideCOE',
    endpoint: 'order/override-coe/:id',
    successMessage: { PUT: 'Revalidating VA data.' },
    prefetch: false,
    refresh: true,
    async: true,
    useRouter: true,
    apiVersion: 2,
  }),
)(VAAlerts)
