export default function locationListToDict(list) {
  if(!list || list.length === 0) {
    return null
  }
  const asDict = {}
  for(let location of list) {
    const {
      state,
      county,
      zip
    } = location
    if(!asDict[state]) {
      asDict[state] = {}
    }
    if(!asDict[state][county]) {
      asDict[state][county] = []
    }
    asDict[state][county].push(zip)
  }
  return asDict
}
