import React, { useState, useEffect } from 'react'
import { Tooltip, Steps } from 'antd'
import { Button } from 'reactstrap'
import { ModalHeader, ModalBody, ModalCloser, ModalFooter } from '../../common/modals/antd/ModalComponents'
import moment from 'moment'
import RevisionReggoraReviewStep from './RevisionReggoraReviewStep'
import RevisionGseStep from './RevisionGseStep'
import RevisionLastStep from './RevisionLastStep'

const REVIEW_REQUEST_TEXT = 'Hello, thank you for your work. The following issues were found in your appraisal report. Please make the necessary updates and resubmit.'

const RevisionWithSteps = ({
  modalApi,
  initialReggoraReviewData,
  initialUcdpData,
  initialEadData,
  parseReviewRevisionRequestText,
  customRevisionRequestText,
  flags,
  existingRevision,
  isEditing,
  categories,
  categoryOptions,
  useCategories,
  onSubmit,
  handleRevisionCategoryChange,
  job_type,
  is_amc_lender,
  responsibleParty,
  handleResponsiblePartyChange,
  title,
  text,
  updateRequestTitle,
  updateRequestText,
}) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [reggoraReviewData, setReggoraReviewData] = useState(null)
  const [ucdpData, setUcdpData] = useState(null)
  const [eadData, setEadData] = useState(null)
  const [allCheckedReggoraReview, setAllCheckedReggoraReview] = useState(false)
  const [allCheckedUcdp, setAllCheckedUcdp] = useState(false)
  const [allCheckedEad, setAllCheckedEad] = useState(false)


  useEffect(() => {
    if (initialReggoraReviewData) {
      const newReggoraReviewData = initialReggoraReviewData.map(item => ({
        ...item,
        isChecked: item.isChecked || false,
      }))
      setReggoraReviewData(newReggoraReviewData)
    }

    if (initialUcdpData) {
      const newUcdpData = initialUcdpData.map(item => ({
        ...item,
        isChecked: item.isChecked || false,
      }))
      setUcdpData(newUcdpData)
    }

    if (initialEadData) {
      const newEadData = initialEadData.map(item => ({
        ...item,
        isChecked: item.isChecked || false,
      }))
      setEadData(newEadData)
    }
  }, [initialReggoraReviewData, initialUcdpData, initialEadData])

  const steps = [
    reggoraReviewData && { title: 'Reggora Review' },
    ucdpData && { title: 'UCDP' },
    eadData && { title: 'EAD' },
    { title: 'Submit Revision' },
  ].filter(Boolean) // remove steps if there is no data

  const isLastStep = currentStep === steps.length - 1

  const onChangeAll = (data, bool_value, setDataFunc, setAllCheckedFunc) => {
    const newData = data.map(item => ({
      ...item,
      isChecked: bool_value,
    }))
    setDataFunc(newData)
    setAllCheckedFunc(bool_value)
  }
  // Usage examples:
  // onChangeAll(reggoraReviewData, bool_value, setReggoraReviewData, setAllCheckedReggoraReview);
  // onChangeAll(ucdpData, bool_value, setUcdpData, setAllCheckedUcdp);
  // onChangeAll(eadData, bool_value, setEadData, setAllCheckedEad);

  const onCheckFailedRule = (data, index, setDataFunc) => {
    const nextData = [...data]
    nextData[index] = {
      ...nextData[index],
      isChecked: !nextData[index].isChecked,
    }
    setDataFunc(nextData)
  }

  const onBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1)
    }
  }

  const onContinue = () => {
    const date = moment().format('ll')
    const revision_title = 'Revision Request ' + date
    updateRequestTitle(revision_title)
    renderReviewRequests()
  }


  const renderReviewRequests = () => {
    let newText = parseReviewRevisionRequestText(
      customRevisionRequestText,
      REVIEW_REQUEST_TEXT
    )

    if (reggoraReviewData) {
      for (const item of reggoraReviewData) {
        if (item.isChecked) {
          newText += '- ' + item.field_name + ': ' + item.revision_request + '\n \n'
        }
      }
    }

    if (ucdpData) {
      for (const item of ucdpData) {
        if (item.isChecked) {
          newText += '- ' + item.message + '\n \n'
        }
      }
    }

    if (eadData) {
      for (const item of eadData) {
        if (item.isChecked) {
          newText += '- ' + item.message + '\n \n'
        }
      }
    }

    updateRequestText(newText)
    setCurrentStep(currentStep + 1)
  }

  let modalHeaderTitle = 'Create Revision'
  let submitText = 'Create'
  if (existingRevision && isEditing) {
    modalHeaderTitle = 'Edit Revision'
    submitText = 'Save'
  }

  let stepComponent
  const stepTitle = steps[currentStep].title
  if (stepTitle === 'Reggora Review') {
    stepComponent = <RevisionReggoraReviewStep
      allChecked={allCheckedReggoraReview}
      onChangeAll={onChangeAll}
      reggoraReviewData={reggoraReviewData}
      onCheckFailedRule={onCheckFailedRule}
      setData={setReggoraReviewData}
      setAllChecked={setAllCheckedReggoraReview}
    />
  } else if (stepTitle === 'UCDP') {
    stepComponent = <RevisionGseStep
      allChecked={allCheckedUcdp}
      onChangeAll={onChangeAll}
      gseData={ucdpData}
      gseType='ucdp'
      onCheckFailedRule={onCheckFailedRule}
      setData={setUcdpData}
      setAllChecked={setAllCheckedUcdp}
    />
  } else if (stepTitle === 'EAD') {
    stepComponent = <RevisionGseStep
      allChecked={allCheckedEad}
      onChangeAll={onChangeAll}
      gseData={eadData}
      gseType='ead'
      onCheckFailedRule={onCheckFailedRule}
      setData={setEadData}
      setAllChecked={setAllCheckedEad}
    />
  } else {
    stepComponent = <RevisionLastStep
      flags={flags}
      title={title}
      text={text}
      categories={categories}
      categoryOptions={categoryOptions}
      useCategories={useCategories}
      handleRevisionCategoryChange={handleRevisionCategoryChange}
      job_type={job_type}
      is_amc_lender={is_amc_lender}
      responsibleParty={responsibleParty}
      handleResponsiblePartyChange={handleResponsiblePartyChange}
      updateRequestTitle={updateRequestTitle}
      updateRequestText={updateRequestText}
    />
  }


  return (
    <>
      <ModalHeader title={modalHeaderTitle}/>
      <ModalCloser onClose={modalApi.cancel} />
      <ModalBody className="revision-modal-body">
        {steps.length !== 1 || steps[0].title !== 'Submit Revision' ? (
          <Steps current={currentStep} className='mb-5'>
            {steps.map(item => (
              <Steps.Step key={item.title} title={item.title} />
            ))}
          </Steps>
        ) : null}
        {stepComponent}
      </ModalBody>
      <ModalFooter>
        {currentStep !== 0 && <Button className='ml-3' data-testid='revision-back' onClick={onBack}>Back</Button>}
        {isLastStep ? <CreateRevisionButton
          hasCategories={Boolean(categories?.length)}
          isCategoriesRequired={useCategories && flags.disableCustomRevisionCategories}
          onClick={onSubmit}
        >
          {submitText}
        </CreateRevisionButton> : <Button className='ml-3' data-testid='revision-continue' color='primary' onClick={() => onContinue()}>Continue</Button>}
      </ModalFooter>
    </>
  )
}

/**
 * @param {{
*  children: React.ReactNode,
*  hasCategories: boolean,
*  isCategoriesRequired: boolean, //  The "revision-categories" and "disable-custom-revision-categories" should be enabled.
*  onClick: () => void,
* }} props
*/
export const CreateRevisionButton = ({ hasCategories, children, onClick, isCategoriesRequired }) => {
  const submitButtonProps = {
    className: 'ml-3',
    color: 'primary',
    'data-testid': 'revision-submit',
    onClick,
  }

  if (isCategoriesRequired) {
    return (
      <Tooltip title="Please select at least one revision category to proceed.">
        <span style={{ cursor: 'not-allowed' }}>
          <Button
            {...submitButtonProps}
            disabled={!hasCategories}
            style={{ pointerEvents: hasCategories ? 'auto' : 'none' }}
          >
            {children}
          </Button>
        </span>
      </Tooltip>
    )
  }

  return (
    <Button {...submitButtonProps}>
      {children}
    </Button>
  )
}

export default RevisionWithSteps
