/* eslint-disable react/no-unescaped-entities */
import React, { Component, Fragment } from 'react'
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap'
import { Input } from 'antd'
export default class CommentTemplateModal extends Component {
  state = {
    templateName: null,
    templateText: null,
  }

  onSubmit = () => {
    const {
      templateName,
      templateText,
    } = this.state

    const submitArgs = {
      name: templateName,
      text: templateText,
    }

    this.props.templates.post(submitArgs)
      .then(this.getTemplates)
      .then(this.props.refreshOrder)
  }

  getTemplates = () => {
    this.props.templates.fetch()
  }

  render() {
    const {
      templateName,
      templateText,
    } = this.state

    return (<Fragment>
      <ModalHeader>Create New Message Template</ModalHeader>
      <ModalBody>
        <Fragment>
          <div>
                Message Name
          </div>
          <Input
            className='my-2'
            onChange={(e) => this.setState({ templateName: e.target.value })}
            value={templateName}
          />

          <div>
                Message Text
          </div>
          <Input.TextArea
            className='my-2'
            autoSize={{ minRows: 4, maxRows: 6 }}
            rows={4}
            onChange={(e) => this.setState({ templateText: e.target.value })}
            value={templateText}
          />
        </Fragment>
      </ModalBody>
      <ModalFooter>
        <Button onClick={this.props.onHide}>Close</Button>
        <Button
          color='primary'
          onClick={this.onSubmit}
        >
            Confirm
        </Button>
      </ModalFooter>
    </Fragment>)
  }
}
