import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { connectResource } from '../../common/utils/resource'
import Loader from '../../common/layout/components/Loader'
import { Button, DatePicker, TimePicker, Form, Typography, Select } from 'antd'

import {
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import moment from 'moment-timezone'

import { GlobalOutlined } from '@ant-design/icons'

const { Option } = Select

const { Paragraph } = Typography
const TITLE = 'Inspection Date and Time'
const DAY_FORMAT = 'MMMM D, YYYY'
const TIME_FORMAT = 'h:mma'

const ALLOW_ABBR_TIMEZONES = {
  EST: 'America/New_York',
  EDT: 'America/New_York',
  CST: 'America/Chicago',
  CDT: 'America/Chicago',
  MST: 'America/Denver',
  MDT: 'America/Denver',
  PST: 'America/Los_Angeles',
  PDT: 'America/Los_Angeles',
  AST: 'America/Halifax',
  ADT: 'America/Halifax',
  HST: 'Pacific/Honolulu',
  HDT: 'Pacific/Honolulu',
  AKST: 'America/Anchorage',
  AKDT: 'America/Anchorage',
}

const TIMEZONES_SHORT_NAME = {
  'America/New_York': 'ET',
  'America/Chicago': 'CT',
  'America/Denver': 'MT',
  'America/Los_Angeles': 'PT',
  'America/Halifax': 'AT',
  'Pacific/Honolulu': 'HT',
  'America/Anchorage': 'AKT',
}

class ModalSetInspectionDate extends Component {
  loadCurrentTimeZone = () => {
    const timezone = moment.tz(moment.tz.guess(true)).zoneAbbr()
    let currentTimeZone = ALLOW_ABBR_TIMEZONES[timezone]
    if (!currentTimeZone) {
      currentTimeZone = 'America/New_York'
    }
    moment.tz.setDefault(currentTimeZone)
    return currentTimeZone
  }

  loadInspectionDateTime = (currentTimeZone) => {
    const inspectionDateTime = moment.utc(this.props.orderData.inspection_date
      ? this.props.orderData.inspection_date : undefined).tz(currentTimeZone)
    return inspectionDateTime
  }

  state = {
    inspectionDate: this.loadInspectionDateTime(this.loadCurrentTimeZone()),
    currentTimeZone: this.loadCurrentTimeZone(),
  };

  onSubmit = () => {
    const {
      inspectionDate,
    } = this.state

    const {
      orderData: {
        id,
      },
      setInspectionDate,
      refreshOrder,
      onHide,
    } = this.props

    const formattedDate = moment(inspectionDate).utc()

    setInspectionDate.put({ order_id: id, inspection_date: formattedDate })
      .then(refreshOrder)
      .then(onHide)
  }

  handlePickDate = (value) => {
    const { inspectionDate } = this.state
    if (value) {
      value.set({
        hour: inspectionDate.hour(),
        minute: inspectionDate.minute(),
        second: inspectionDate.second(),
      })
      this.setState({
        inspectionDate: value,
      })
    }
  }

  handleSelectTime = (value) => {
    const { inspectionDate, currentTimeZone } = this.state
    if (value) {
      value.tz(currentTimeZone)
      value.set({
        year: inspectionDate.year(),
        month: inspectionDate.month(),
        date: inspectionDate.date(),
      })
      this.setState({
        inspectionDate: value,
      })
    }
  }

  handleTimeZoneChange = (value) => {
    moment.tz.setDefault(value)
    this.setState({
      inspectionDate: this.loadInspectionDateTime(value),
      currentTimeZone: value,
    })
  }

  render() {
    const action = this.props.orderData.inspection_date ? 'Edit' : 'Set'
    const { inspectionDate, currentTimeZone } = this.state

    const footer = (
      <ModalFooter>
        <Button onClick={this.props.onHide}>Cancel</Button>
        <Button
          type="primary"
          onClick={this.onSubmit}
        >
          {action} {TITLE}
        </Button>
      </ModalFooter>
    )
    const inspectionDateForm = (
      <Form
        layout='inline'
      >
        <Form.Item className='mr-2'>
          <DatePicker
            format={DAY_FORMAT}
            defaultValue={moment(inspectionDate, DAY_FORMAT)}
            placeholder="Please select"
            style={{ width: '170px' }}
            onChange={this.handlePickDate}
            allowClear={false}
          />
        </Form.Item>
        <Form.Item>
          <span>Starting At</span>
        </Form.Item>
        <Form.Item className='mr-2'>
          <TimePicker
            value={moment(inspectionDate)}
            format={TIME_FORMAT}
            onChange={this.handleSelectTime}
            showNow={false}
            style={{ width: '110px' }}
          />
        </Form.Item>
        <Form.Item className='mr-1'>
          <Select
            suffixIcon={<GlobalOutlined />}
            value={TIMEZONES_SHORT_NAME[currentTimeZone]}
            onChange={this.handleTimeZoneChange}>
            <Option value='America/New_York'>ET</Option>
            <Option value='America/Chicago'>CT</Option>
            <Option value='America/Denver'>MT</Option>
            <Option value='America/Los_Angeles'>PT</Option>
            <Option value='America/Halifax'>AT</Option>
            <Option value='Pacific/Honolulu'>HT</Option>
            <Option value='America/Anchorage'>AKT</Option>
          </Select>
        </Form.Item>
      </Form>
    )

    const modelContent = (
      <>
        <Paragraph className='mt-3'><span className='text-red'>*</span> Inspection Date</Paragraph>
        {inspectionDateForm}
      </>
    )

    if (this.props.setInspectionDate.loading) {
      return (
        <Fragment>
          <ModalHeader>{action} Inspection Date</ModalHeader>
          <ModalBody>
            <Loader/>
          </ModalBody>
        </Fragment>
      )
    }
    return (
      <Fragment>
        <ModalHeader>{action} {TITLE}</ModalHeader>
        <ModalBody>
          {modelContent}
        </ModalBody>
        {footer}
      </Fragment>
    )
  }
}

export default compose(
  connectResource({
    namespace: 'setInspectionDate',
    endpoint: 'order/set-inspection-date',
    list: false,
    prefetch: false,
    successMessage: {
      PUT: 'Your order has been updated.',
    },
    apiVersion: 2,
  })
)(ModalSetInspectionDate)
