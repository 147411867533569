import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'reactstrap'
const propTypes = {
  inputClassName: PropTypes.string,
  placeholder: PropTypes.string,
  pattern: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
}
const defaultProps = {
  inputClassName: 'input-custom',
  readOnly: false,
  type: 'text',
}

export default class TextInput extends Component {
  handleChange = (e) => {
    this.props.onChange(e.target.value)
  }

  render() {
    const {
      inputClassName, placeholder, pattern,
      required, disabled, readOnly, name, value, type, autoComplete = 'nope',
    } = this.props
    return (
      <Input
        type={type}
        name={name}
        className={inputClassName}
        placeholder={placeholder}
        pattern={pattern}
        autoComplete={autoComplete}
        onChange={this.handleChange}
        required={required}
        disabled={disabled}
        readOnly={readOnly}
        value={ type === 'email' && value ? value.toLowerCase() : value }
      />
    )
  }
}

TextInput.propTypes = propTypes
TextInput.defaultProps = defaultProps
