import {COMMON_PASSWORDS} from '../constants'

export default function isCommonPassword(password) { 
  /* 
    Screen against the 10,000 most common passwords
    List from SecLists and filtered for 8 characters: 
    https://github.com/danielmiessler/SecLists/blob/master/Passwords/Common-Credentials/10-million-password-list-top-10000.txt
  */
  return COMMON_PASSWORDS.includes(password)
}
