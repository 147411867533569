import React, { Component } from 'react'
import { Provider, connect } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { connectResource, tokenPresentOnMount } from './common/utils/resource'
import { compose, bindActionCreators } from 'redux'
import Loader from './common/layout/components/Loader'
import AppContainer from './common/layout/AppContainer'
import { hot } from 'react-hot-loader'
import { fetchCrossBrowserCSS, isBadBrowser, renderBadBrowserMessage } from './common/crossBrowser'
import { withLDProvider, withLDConsumer } from 'launchdarkly-react-client-sdk'
import { ld_client_id } from './init'
import { getLDUser, privateLDAttributeNames } from '../helpers/ldHelpers'

fetchCrossBrowserCSS()

class AppProvider extends Component {
  state = {
    tokenPresent: false,
    ldUserId: null,
  }

  componentWillMount() {
    const token = localStorage.getItem('reggora_lender_auth_token')
    if (token) {
      this.setState({ ...this.state, tokenPresent: true })
      this.props.actions.tokenPresentOnMount(token)
    }
  }

  // needs to be in componentDidUpdate ldClient can be null until its
  // done being initialized by ldProvider
  componentDidUpdate() {
    const { ldClient, user } = this.props
    const { ldUserId } = this.state
    if (user?.data?.id && user?.data?.id !== ldUserId && ldClient) {
      ldClient.identify(getLDUser(user.data))
      this.setState({ ...this.state, ldUserId: user.data.id })
    }
  }

  render() {
    const {
      store,
      history,
      user,
    } = this.props
    const {
      tokenPresent,
    } = this.state
    if (isBadBrowser) { return renderBadBrowserMessage }
    if (tokenPresent && !user.data) { return <Loader/> }
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <AppContainer />
        </ConnectedRouter>
      </Provider>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({
      tokenPresentOnMount,
    }, dispatch),
  }
}

export default compose(
  connect(null, mapDispatchToProps),
  connectResource(
    { namespace: 'user', endpoint: 'user', list: true, prefetch: false }
  ),
  withLDProvider({
    clientSideID: ld_client_id,
    user: {
      key: 'unidentified_init_user',
      name: 'init_user',
      lender_name: 'init_lender',
      email: 'init_user@reggora.com',
      type: 'LENDER_USER',
      privateAttributeNames: privateLDAttributeNames,
    },
    options: { privateAttributeNames: privateLDAttributeNames },
  }),
  withLDConsumer(),
)(hot(module)(AppProvider))
