import { useMemo } from 'react'
import { reviewLogType } from '../ReviewConstants'

/**
 * It adds a 'hasSystemOverride' flag to each review result. This flag indicates the presence of a 'PERSISTED_CLEARED' override log.
 * If such a log is present, the 'Changed' filter will be activated.
 *
 * @param {{
 *  reviewResults?: Object<string, any>
 *  submissionVersion?: number
 * }} args
 *
 * @return {Object.<string, any> | undefined }
 */
export const useAddSystemOverrideFlag = ({ reviewResults, submissionVersion }) => {
  return useMemo(() => {
    if (!reviewResults) { return undefined }

    return Object.entries(reviewResults).reduce((acc, [id, result]) => {
      const hasSystemOverride = result.outcome_override_logs.some(
        (log) =>
          log.submission_version === submissionVersion &&
          log.log_type === reviewLogType.PERSISTED_CLEARED
      )
      acc[id] = hasSystemOverride
        ? { ...result, hasSystemOverride }
        : result

      return acc
    }, {})
  }, [reviewResults, submissionVersion])
}
