import React, { Fragment } from 'react'
import CommentThread from './CommentThread'

export default function RuleComment(props) {
  const { fieldName, commentData, currentUser, hideFieldName } = props
  return (
    <Fragment>
      {(hideFieldName) ? (
        <></>
      ) : (
        <div className="rule-comment-field-name">
          {fieldName}
        </div>
      )}
      {
        commentData.map((comment, index) => {
          const { user, created, note } = comment
          let _id, firstname, lastname
          if (user) {
            _id = user._id
            firstname = user.firstname
            lastname = user.lastname
          } else {
            firstname = currentUser.firstname
            lastname = currentUser.lastname
            _id = currentUser.id
          }
          return (
            <CommentThread
              userId={_id}
              firstName={firstname}
              lastName={lastname}
              timestamp={created}
              messageBody={note}
              key={index}
            />
          )
        })
      }
    </Fragment>
  )
}
