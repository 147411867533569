import React, { useState, useEffect } from 'react'
import { Dropdown, Input } from 'antd'
import { findIndex } from 'lodash'
import SearchSuggestions from '.'
import searchQuery from '../../common/utils/searchQueryParser'

export const ORDER_COMPONENT_DOMAIN = 'Order'
export const LOAN_COMPONENT_DOMAIN = 'Loan'

const FIELD_NAME = 'searchText'

const SearchSuggestionsContainer = (props) => {
  const {
    parentComponentDomain,
    searchSuggestions,
    handleSearch,
    searchText,
    onFieldChange,
  } = props
  const [focusIndex, setFocusIndex] = useState(undefined)
  const [dropDownVisible, setDropDownVisible] = useState(false)
  const [parsedTerms, setParsedTerms] = useState([])
  const [searchSuggestionsTerms, setSearchSuggestionsTerms] = useState({
    searchTerm: '',
    keyword: '',
    quote: '',
  })

  const menuId = `${parentComponentDomain.toLowerCase()}-search-suggestion-menu`
  const commonSearchClass = `${parentComponentDomain.toLowerCase()}-search-ui-with-suggestions-element`
  const readableFields = searchSuggestions.map((suggestion) => suggestion.readable)
  const placeHolderText = `Search by ${readableFields.join(', ')}`

  useEffect(() => {
    if (searchText) {
      setParsedTerms(searchQuery.parse(searchText))
    } else {
      setDropDownVisible(false)
    }
  }, [searchText])

  useEffect(() => {
    if (parsedTerms && parsedTerms.offsets && focusIndex !== undefined) {
      const offset = parsedTerms.offsets[focusIndex]
      if (offset) {
        setSearchSuggestionsTerms({
          searchTerm: offset.value || offset.text,
          keyword: offset.keyword,
          quote: offset.quote,
        })
      }
    }
  }, [parsedTerms, focusIndex])

  const handleChange = (event) => {
    event.target.value ? setDropDownVisible(true) : setDropDownVisible(false)
    onFieldChange(event)
  }

  const updateFocusIndex = (offset) => {
    if (parsedTerms && parsedTerms.offsets) {
      setFocusIndex(findIndex(parsedTerms.offsets, term => {
        const { offsetStart, offsetEnd } = term
        return offsetStart <= offset && offset <= offsetEnd
      }))
    }
  }

  const handleUpdateFocus = (event) => {
    if (!event.key || event.key !== 'Enter') {
      setDropDownVisible(true)
      updateFocusIndex(event.target.selectionStart)
    }
  }

  const getSearchTextWithUpdatedTerm = (updatedTerm, offset) => {
    const { offsetStart, offsetEnd } = offset
    return searchText.slice(0, offsetStart) + updatedTerm + searchText.slice(offsetEnd)
  }

  const handleUpdateTerm = (updatedTerm) => {
    if (searchText !== undefined && focusIndex !== undefined && parsedTerms && parsedTerms.offsets) {
      const offset = parsedTerms.offsets[focusIndex]
      if (offset) {
        const value = getSearchTextWithUpdatedTerm(updatedTerm, offset)
        onFieldChange({ target: { name: FIELD_NAME, value } })
      }
    }
  }

  const handleMenuFocus = (event) => {
    if (dropDownVisible && searchSuggestionsTerms.searchTerm) {
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        event.preventDefault()
        document.getElementById(menuId).focus()
      }
    }
  }


  const allOnBlur = (ev) => {
    const relatedTarget = ev?.relatedTarget

    if (!relatedTarget?.classList?.contains?.(commonSearchClass)) {
      // search UI is losing focus and it is moving outside of search UI, hide the dropdown
      setDropDownVisible(false)
    }
  }

  return (
    <Dropdown
      visible={dropDownVisible}
      overlay={
        <SearchSuggestions
          menuId={menuId}
          searchTerm={searchSuggestionsTerms.searchTerm}
          keyword={searchSuggestionsTerms.keyword}
          quote={searchSuggestionsTerms.quote}
          updateTerm={handleUpdateTerm}
          onMouseLeave={() => setDropDownVisible(false)}
          onEnter={((newValue) => {
            setDropDownVisible(false)
            handleSearch(newValue)
          })}
          commonSearchClass={commonSearchClass}
          onBlur={allOnBlur}
          searchSuggestions={searchSuggestions}
        />
      }
      overlayClassName="ant-select-dropdown"
    >
      <Input.Search
        enterButton
        placeholder={placeHolderText}
        name={FIELD_NAME}
        value={searchText}
        onChange={handleChange}
        onSearch={((newValue) => {
          setDropDownVisible(false)
          handleSearch(newValue)
        })}
        onClick={handleUpdateFocus}
        onKeyUp={handleUpdateFocus}
        onKeyDown={handleMenuFocus}
        autoComplete='off'
        onFocus={() => setDropDownVisible(true)}
        allowClear={true}
        onBlur={allOnBlur}
        className={commonSearchClass}
      />
    </Dropdown>
  )
}

export default SearchSuggestionsContainer
