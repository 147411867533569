import { upperFirst } from 'lodash'

export const getRephrasedText = (text, sayOrder) => {
  let rephrasedText = text

  // "(an) appraiser(s)" -> "(a) vendor(s)"
  // title case handled
  rephrasedText = rephrasedText.replace(
    /(an )?(a)ppraiser(s?)/ig,
    (match, p1, p2, p3) => `${(p1 || '') && `${p1[0]} `}${p2 === 'A' ? 'V' : 'v'}endor${p3}`
  )

  // "(an) appraisal firm(s)"" -> "(a) vendor firm(s)"
  // "(an) appraisal compan(y|ies)" -> "(a) vendor compan(y|ies)"
  // "(an) appraisal panel" -> "(a) vendor panel"
  // "(an) appraisal panelist(s)" -> "(a) vendor panelist(s)"
  // "(an) appraisal platform" -> "(a) vendor platform"
  // title case handled
  rephrasedText = rephrasedText.replace(
    /(an )?(a)ppraisal (firms?|company|companies|panel|panelists?|platform)/ig,
    (match, p1, p2, p3) => `${(p1 || '') && `${p1[0]} `}${p2 === 'A' ? 'V' : 'v'}endor ${p3}`
  )

  // "(an) appraisal report" -> "(a) report"
  // "(an) appraisal vendor" -> "(a) vendor"
  // title case handled
  rephrasedText = rephrasedText.replace(
    /(an )?(a)ppraisal (report|vendor)/ig,
    (match, p1, p2, p3) => `${(p1 || '') && `${p1[0]} `}${p2 === 'A' ? upperFirst(p3) : p3}`
  )

  // "(an) appraisal order(s)" -> "(an) order(s)"
  // "(an) appraisal assignment(s)" -> "(an) assignment(s)"
  // title case handled
  rephrasedText = rephrasedText.replace(
    /(an )?(a)ppraisal (orders?|assignments?)/ig,
    (match, p1, p2, p3) => `${p1 || ''}${p2 === 'A' ? upperFirst(p3) : p3}`
  )

  // "appraisal(s) cost" -> "order(s) cost"
  // "appraisal(s) fee" -> "order(s) fee"
  // "appraisal(s) payment" -> "order(s) payment"
  // "appraisal(s) stat(s)" -> "order(s) stat(s)"
  // "appraisal(s) request(s)" -> "order(s) request(s)"
  // title case handled
  rephrasedText = rephrasedText.replace(
    /appraisal(s?) (cost|fee|payment|stats?|requests?)/ig,
    (match, p1, p2) => `${match.startsWith('A') ? 'O' : 'o'}rder${p1} ${p2}`
  )

  // "schedule an appraisal(s)" -> "schedule an inspection(s)"
  // title case handled
  rephrasedText = rephrasedText.replace(
    /(schedule an )(a)ppraisal/ig,
    (match, p1, p2) => `${p1}${p2 === 'A' ? 'I' : 'i'}nspection`
  )

  // "refund for (an) appraisal(s)" -> "refund for (an) order(s)"
  // "residential appraisal(s)" -> "residential order(s)"
  // title case handled
  rephrasedText = rephrasedText.replace(
    /(refund for( an)?|residential) (a)ppraisal(s?)/ig,
    (match, p1, p2, p3, p4) => `${p1} ${p3 === 'A' ? 'O' : 'o'}rder${p4}`
  )

  // "(an) appraisal(s)" -> "(a) report(s)" if sayOrder is false
  // "(an) appraisal(s)" -> "(an) order(s)" if sayOrder is true
  // "appraisal management company" will NOT be altered.
  // title case handled.
  rephrasedText = rephrasedText.replace(
    /(an )?(a)ppraisal(s?)(?! management company)/ig,
    (match, p1, p2, p3) => {
      const isTitleCase = p2 === 'A'
      return sayOrder ? `${p1 || ''}${isTitleCase ? 'O' : 'o'}rder${p3}`
        : `${(p1 || '') && `${p1[0]} `}${isTitleCase ? 'R' : 'r'}eport${p3}`
    }
  )
  return rephrasedText
}
