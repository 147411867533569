import React from 'react'
import PdfViewer from './PdfViewer'
import { ModalHeader, ModalBody, ModalCloser } from '../../common/modals/antd/ModalComponents'

export default function PdfViewModal(props) {
  const { pdf, modalApi } = props
  const { fileUrl, httpHeaders } = pdf

  return (
    <>
      <ModalHeader className="pdf-view-modal-header"></ModalHeader>
      <ModalCloser onClose={modalApi.cancel} />
      <ModalBody className="pdf-view-modal-body">
        <PdfViewer fileUrl={fileUrl} httpHeaders={httpHeaders} />
      </ModalBody>
    </>
  )
}
