import React, { Fragment } from 'react'
import { ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { Switch } from 'antd'

export default function SendConsumerModal(props) {
  return (
    <Fragment>
      <ModalHeader toggle={props.toggle}>{props.report ? 'Send Consumer Report' : 'Scheduling / Payment Permissions'}</ModalHeader>
      <ModalBody>
        <table className="full-width">
          <thead>
            <th>Name</th>
            <th>Role</th>
            <th>Schedule</th>
            <th></th>
          </thead>
          {Object.entries(props.checkedConsumers).map(
            ([consumer_id, { checked, full_name, role, email, canResend }]) =>
              <tr key={consumer_id} style={{ border: '1px solid #cdcccc' }}>
                <td className='p-2'>{full_name}</td>
                <td>
                  {role
                    ? role.toLowerCase()
                      .split(' ')
                      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                      .join(' ')
                    : ''
                  }
                </td>
                <td>
                  <Switch
                    disabled={!email}
                    onChange={checked => props.onChange(checked, consumer_id)}
                    checked={checked}
                  />
                </td>
                <td>{canResend && props.resendButton(consumer_id)}</td>
              </tr>
          )}
        </table>
      </ModalBody>
      <ModalFooter className="border-0">
        <Button onClick={props.onHide}>Cancel</Button>
        <Button color='primary' onClick={props.onSubmit}>Save</Button>
      </ModalFooter>
    </Fragment>
  )
}
