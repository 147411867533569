import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import { Tooltip, Select, Input as AntdInput } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

import { connectResource } from '../../common/utils/resource'
import { apiToReadable, checkPermission } from '../../common/utils/helpers'
import Rephrase from '../../common/utils/Rephrase'

import { ModalHeader, ModalBody, ModalFooter, ModalCloser } from '../../common/modals/antd/ModalComponents'
import {
  Button,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Table,
} from 'reactstrap'
import { selectCustomReviewRevisionRequestText } from '../../store/reducers/user/userSelectors'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useRevisionMetadata } from '@app/common/hooks/useRevisionMetadata'
import { OverlayLoader } from '@app/common/layout/components/OverlayLoader'

const { TextArea } = AntdInput

export const revisionCategories = [
  'Subject property information incorrect',
  'Updated borrower information',
  'Loan information changed',
  'Comparable(s) address incorrect',
  'Comparable(s) selection',
  'Missing/incorrect photos',
  'Missing/incorrect adjustments',
  'Sketch does not match indicated features',
  'FHA requirements not met',
  'Inaccurate or contradictory comments',
  'Missing commentary',
]

export const REVIEW_REQUEST_TEXT = 'Hello, thank you for your work. The following issues were found in your appraisal report. Please make the necessary updates and resubmit.'

class RevisionModal extends Component {
  modalApi = this.props.modalApi
  useCategories = this.props.useCategories || false

  state = {
    title: this.props.revision ? this.props.revision.title : '',
    responsibleParty: this.props.revision ? this.props.revision.responsible_party : 'appraiser',
    categories: (this.props.revision && this.props.revision.categories)
      ? this.props.revision.categories.map((cat) => {
        if (cat.category_description && cat.category_description !== '') {
          return cat.category_description
        }
        return cat.category_type
      }) : [],
    categoryOptions: revisionCategories.map((category) => ({ value: category, label: category })),
    isEditing: false,
    resultData: this.props.resultData ? this.props.resultData : [],
    displayRuleSelect: this.props.displayRuleSelect ? this.props.displayRuleSelect : false,
    comingFromRuleSelect: false,
    selectAll: true,
  }

  constructor(props) {
    super(props)

    this.state.text = props.revision?.text ||
      this.parseReviewRevisionRequestText(props.custom_review_revision_request_text)
  }

  parseReviewRevisionRequestText = (custom_review_revision_request_text, default_text) => {
    if (!custom_review_revision_request_text && !default_text) {
      return ''
    }

    return (custom_review_revision_request_text || default_text) + '\n\n'
  }

  handleInputChange = (event) => {
    const { name, value } = event.target

    this.setState({
      [name]: value,
    })
  }

  handleResponsiblePartyChange = (selected) => {
    this.setState({ responsibleParty: selected })
  }

  handleRevisionCategoryChange = (categories) => {
    this.setState({ categories })
  }

  onSubmit = () => {
    const {
      modalApi,
      order_id,
      refetchOrder,
      revision,
      revisionsEndpoint,
      useCategories,
    } = this.props

    const {
      categories,
      responsibleParty,
      text,
      title,
    } = this.state

    const data = {
      categories,
      order_id,
      responsible_party: responsibleParty,
      text,
      title,
    }

    if (useCategories) {
      data.title = categories.join(', ')
    }

    if (revision) {
      data.id = revision.id
      data.lender_resolved = revision.lender_resolved
      revisionsEndpoint.put(data).then(_ => {
        modalApi.confirm()
        refetchOrder()
      })
    } else {
      revisionsEndpoint.create(data).then(_ => {
        modalApi.confirm()
        refetchOrder()
      })
    }
  }

  setLenderResolved = (lender_resolved) => {
    const {
      revisionsEndpoint,
      revision,
      order_id,
      modalApi,
      refetchOrder,
    } = this.props
    const data = {
      order_id,
      lender_resolved,
      id: revision.id,
    }
    revisionsEndpoint.put(data).then(_ => {
      modalApi.confirm()
      refetchOrder()
    })
  }

  setIsAppraiserAccessible = (is_accessible_to_appraiser) => {
    const {
      revisionsEndpoint,
      revision,
      order_id,
      modalApi,
      refetchOrder,
    } = this.props

    const data = {
      order_id,
      is_accessible_to_appraiser,
      id: revision.id,
    }

    revisionsEndpoint.put(data).then(_ => {
      modalApi.confirm()
      refetchOrder()
    })
  }

  onCancel = () => {
    const {
      revision,
      modalApi,
    } = this.props
    if (revision) {
      this.setState({ isEditing: false })
    } else {
      modalApi.cancel()
    }
  }

  renderResponsiblePartyForm = () => {
    if (this.props.job_type !== 'amc' || (this.props.job_type === 'amc' && this.props.is_amc_lender)) {
      return (
        <div data-testid='revision-party'>
          <label htmlFor='revision-responsible-party' className='mt-3'>Responsible Party:
            <Tooltip title='Identify who is responsible for the error this revision is addressing'>
              <InfoCircleOutlined className='text-muted ml-1' />
            </Tooltip>
          </label>
          <br />
          <Select
            id='revision-responsible-party'
            className='w-100'
            value={this.state.responsibleParty}
            onChange={this.handleResponsiblePartyChange}
            options={[{ value: 'appraiser', label: <Rephrase>Appraiser</Rephrase> }, { value: 'lender', label: 'Lender' }]}
          />
        </div>
      )
    }
    return null
  }

  renderReviewRequests = (resultData) => {
    let newText = this.parseReviewRevisionRequestText(
      this.props.custom_review_revision_request_text,
      REVIEW_REQUEST_TEXT
    )

    for (const item of resultData) {
      if (item.isChecked) {
        newText += '- ' + item.field + ': ' + item.vendor_description + '\n \n'
      }
    }

    this.setState({ text: newText })
  }

  onCheckFailedRule = (failed_data, index) => {
    const curr = failed_data.at(index).isChecked
    failed_data.at(index).isChecked = !curr
    this.setState({ failed_data: failed_data })
  }

  onContinue = (resultData) => {
    const date = moment().format('ll')
    const revision_title = 'Revision Request ' + date
    this.setState({ title: revision_title })
    this.setState({ displayRuleSelect: false })
    this.setState({ comingFromRuleSelect: true })
    this.renderReviewRequests(resultData)
  }

  onBack = () => {
    this.setState({ displayRuleSelect: true })
  }

  onChangeAll = (resultData, bool_value) => {
    for (const item of resultData) {
      item.isChecked = bool_value
    }
    this.setState({ resultData: resultData })
    this.setState({ selectAll: !bool_value })
  }

  startEditing = () => {
    this.setState({ isEditing: true })
  }

  render() {
    const {
      flags,
      modalApi,
      reviewNotes,
      revision,
    } = this.props
    const {
      text,
      title: revision_title,
      isEditing,
      displayRuleSelect,
      comingFromRuleSelect,
      resultData,
      selectAll,
    } = this.state

    let title = 'Create Revision'

    if (displayRuleSelect) {
      return (
        <>
          <ModalHeader title={title} className="revision-modal revision-modal-header" />
          <ModalCloser onClose={modalApi.cancel} />
          <ModalBody className="revision-modal">
            <p>Select the review findings you would like to include in the revision below.</p>
            <Table size='sm' hover className='revision-table'>
              <thead>
                <tr>
                  <th className="border-left no-border-bot head-border"></th>
                  <th className="no-border-bot head-border">
                    Field
                  </th>
                  <th className="border-right  no-border-bot head-border">
                    <span className="pr-5">
                      Revision Request
                    </span>
                    <span className="float-right select-all-text">
                      {selectAll
                        ? <a className="link-primary padding-right:25px" onClick={() => this.onChangeAll(resultData, true)}>Select All</a>
                        : <a className="link-primary padding-right:25px" onClick={() => this.onChangeAll(resultData, false)}>Deselect All</a>
                      }
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody className="bordered">
                {resultData.length > 0
                  ? (resultData.map((item, index) => (
                    <tr key={index}>
                      <td scope='row' className='th-border'>
                        <div className='form-check align-items-vert'>
                          <Input
                            className='form-check-input padding-left:15px'
                            name={'review_data_style'}
                            checked={
                              item.isChecked
                            }
                            onChange={() =>
                              this.onCheckFailedRule(
                                resultData,
                                index,
                              )
                            }
                            value={item.vendor_description}
                            type='checkbox'
                          />
                          <label
                            className='orm-check-label'
                            htmlFor='defaultCheck1'
                          >
                          </label>
                        </div>
                      </td>
                      <td>
                        {item.field}
                      </td>
                      <td>
                        {item.vendor_description}
                      </td>
                    </tr>
                  ))
                  ) : (
                    <tr>
                      <td colSpan={3}>
                        <p className="no-findings-text">
                            No failed findings were found during review.
                        </p>
                      </td>
                    </tr>
                  )}
              </tbody>
            </Table>
          </ModalBody>
          <ModalFooter className="revision-modal revision-modal-footer">
            <Button data-testid='revision-continue' color='primary' onClick={() => this.onContinue(resultData)}>Continue</Button>
          </ModalFooter>
        </>
      )
    }

    let submitText = 'Create'
    if (revision && isEditing) {
      title = 'Edit Revision'
      submitText = 'Save'
    } else if (revision && !isEditing) {
      return (
        <ViewRevisionModal
          is_amc_lender={this.props.is_amc_lender}
          job_type={this.props.job_type}
          modalApi={modalApi}
          revision={revision}
          setIsAppraiserAccessible={this.setIsAppraiserAccessible}
          setLenderResolved={this.setLenderResolved}
          startEditing={this.startEditing}
        />
      )
    }

    if (this.props.is_amc_lender && reviewNotes.text && !this.state.hasReviewNotesSet) {
      this.setState((state) => ({ hasReviewNotesSet: true, text: (state.text || '') + reviewNotes.text }))
    }


    return (<>
      <ModalHeader title={title} className="revision-modal revision-modal-header" />
      <ModalCloser onClose={modalApi.cancel} />
      <ModalBody className="revision-modal">
        {this.useCategories ? (
          <RevisionCategories
            allowCustomValues={!flags.disableCustomRevisionCategories}
            onChange={this.handleRevisionCategoryChange}
            options={this.state.categoryOptions}
            value={this.state.categories}
          />
        ) : (<>
          <label className='mb-3' htmlFor='revision-title'>Title:</label>
          <AntdInput id='revision-title' name='title' value={revision_title} onChange={this.handleInputChange} />
        </>)}
        <label className='mt-3' htmlFor="revision-text">Request:</label>
        <OverlayLoader isLoading={reviewNotes.isLoading}>
          <TextArea id='revision-text' rows={10} className='w-100 bordered' name='text' type='text' value={text} onChange={this.handleInputChange} />
        </OverlayLoader>
        {this.renderResponsiblePartyForm()}
      </ModalBody>
      <ModalFooter className="revision-modal revision-modal-footer">
        {comingFromRuleSelect
          ? <Button className='ml-3' data-testid='revision-back' onClick={this.onBack}>Back</Button>
          : <Button className='ml-3' data-testid='revision-cancel' onClick={this.onCancel}>Cancel</Button>
        }
        <CreateRevisionButton
          hasCategories={Boolean(this.state.categories?.length)}
          isCategoriesRequired={this.useCategories && flags.disableCustomRevisionCategories}
          onClick={this.onSubmit}
        >
          {submitText}
        </CreateRevisionButton>
      </ModalFooter>
    </>)
  }
}

const RevisionModalWithFlags = Component => {
  const FlagsComponent = (props) => {
    const flags = useFlags() || {}
    const { revisionMetadata, isLoading } = useRevisionMetadata({
      isHookEnabled: Boolean(props.is_amc_lender),
      orderId: props.order_id,
    })
    const reviewNotesTxt = revisionMetadata?.reggora_review_notes?.[0]?.body || ''

    return <Component flags={flags} reviewNotes={{ text: reviewNotesTxt, isLoading }} {...props} />
  }
  return FlagsComponent
}

export default compose(
  connect(selectCustomReviewRevisionRequestText),
  connectResource({
    prefetch: false,
    namespace: 'revisionsEndpoint',
    endpoint: 'order/revisions',
    successMessage: {
      POST: 'Your revision request has been submitted.',
      PUT: 'This revision request has been updated.',
    },
    apiVersion: 2,
  }),
)(RevisionModalWithFlags(RevisionModal))


const RevisionCategories = ({ value, onChange, options, allowCustomValues }) => {
  const toolTip = `Choose from revision categories${allowCustomValues ? ', custom values are allowed.' : '.'}`
  const mode = allowCustomValues ? 'tags' : 'multiple'

  return (<>
    <label htmlFor='revision-categories' className='mb-3'>Categories:
      <Tooltip title={toolTip}>
        <InfoCircleOutlined className='text-muted ml-1' />
      </Tooltip>
    </label>
    <Select
      className='w-100'
      id='revision-categories'
      mode={mode}
      onChange={onChange}
      options={options}
      value={value}
    />
  </>)
}


/**
 * @param {{
 *  children: React.ReactNode,
 *  hasCategories: boolean,
 *  isCategoriesRequired: boolean, //  The "revision-categories" and "disable-custom-revision-categories" should be enabled.
 *  onClick: () => void,
 * }} props
 */
const CreateRevisionButton = ({ hasCategories, children, onClick, isCategoriesRequired }) => {
  const submitButtonProps = {
    className: 'ml-3',
    color: 'primary',
    'data-testid': 'revision-submit',
    onClick,
  }

  if (isCategoriesRequired) {
    return (
      <Tooltip title="Please select at least one revision category to proceed.">
        <span style={{ cursor: 'not-allowed' }}>
          <Button
            {...submitButtonProps}
            disabled={!hasCategories}
            style={{ pointerEvents: hasCategories ? 'auto' : 'none' }}
          >
            {children}
          </Button>
        </span>
      </Tooltip>
    )
  }

  return (
    <Button {...submitButtonProps}>
      {children}
    </Button>
  )
}


const ViewRevisionModal = ({
  is_amc_lender,
  job_type,
  modalApi,
  revision,
  setIsAppraiserAccessible,
  setLenderResolved,
  startEditing,
}) => {
  const canCreateEditRevisions = checkPermission('revisions_create_edit')

  return (<>
    <ModalHeader title={'View Revision'} className="revision-modal revision-modal-header" />
    <ModalCloser onClose={modalApi.cancel} />
    <ModalBody className="revision-modal">
      <Row className='mb-2 align-items-center'>
        <Col xs='9'>
          <h5 className='mb-0'>{revision.title}</h5>
        </Col>
        <Col xs='3'>
          {canCreateEditRevisions && (
            <div className='d-flex justify-content-end'>
              <Button data-testid='revision-edit' disabled={revision.lender_resolved} onClick={startEditing}>Edit</Button>
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs='12'>
          <Card>
            <CardBody className='revision-text'>
              {revision.text}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {revision.responsible_party && (job_type !== 'amc' || (job_type === 'amc' && is_amc_lender)) ? <Row>
        <Col xs='12'>
          <p>Responsible Party: <Rephrase>{apiToReadable(revision.responsible_party)}</Rephrase></p>
        </Col>
      </Row> : null}
      {canCreateEditRevisions && (
        <Row>
          <Col xs='12' className='d-flex justify-content-end'>
            {revision.lender_resolved ? (
              <Button data-testid='lender-unresolve' onClick={() => setLenderResolved(false)}>Unresolve</Button>
            ) : (
              <Button data-testid='lender-resolve' onClick={() => setLenderResolved(true)}>Mark Resolved</Button>
            )}
          </Col>
          {job_type === 'amc' && is_amc_lender && !revision.is_accessible_to_appraiser && !revision.lender_resolved
            ? <Col xs='12' className='d-flex justify-content-end'>
              <Button data-testid='appraiser-accessible' onClick={() => setIsAppraiserAccessible(true)} color='info' style={{ marginTop: '10px' }}>Send Revision</Button>
            </Col>
            : null
          }
        </Row>
      )}
    </ModalBody>
  </>)
}
