import React from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { getRephrasedText } from './rephraseText'

const getRephrasedNode = (node, index, sayOrder) => {
  if (typeof node === 'string') { return getRephrasedText(node, sayOrder) }
  if (React.isValidElement(node)) {
    if (node.props.children) {
      return React.cloneElement(
        node,
        { key: index },
        [...React.Children.map(node.props.children, (item, i) => getRephrasedNode(item, i, sayOrder))]
      )
    } else {
      return node
    }
  }
  if (Array.isArray(node)) {
    return node.map((item, i) => getRephrasedNode(item, i, sayOrder))
  }
  return node
}

const Rephrase = (props) => {
  const { sayOrder } = props
  const { dontSayAppraiser } = useFlags() || {}
  const children = props.children || null
  if (!dontSayAppraiser) { return children }
  return getRephrasedNode(children, 0, sayOrder)
}

export default Rephrase
