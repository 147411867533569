import React from 'react'
import ReggoraChatBubble from './ReggoraBubble'
import { ReggoraChatBubble as NewChatBubble } from '@reggora-app/reggora-conversation-ui'
import { ChatFeed } from 'react-chat-ui'

export const ChatWrapper = ({
  total_chat_timeline,
  timelineData,
  isUnifiedConversation,
  isAMPUser,
}) => {
  const useNewChatBubble = isUnifiedConversation && !isAMPUser
  return (
    <ChatFeed
      messages={total_chat_timeline}
      chatBubble={ useNewChatBubble ? NewChatBubble : ReggoraChatBubble }
      showSenderName={false}
      timelineEvents={timelineData}
    />
  )
}
