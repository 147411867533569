import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'

import UserCardComponent from './UserCardComponent'
import { checkPermission } from '../utils/helpers'
class UserCardContainer extends Component {
  render() {
    let {
      img,
      name,
      hideLink,
    } = this.props
    name = name || null
    const imgCard = img || ''

    return (
      <Fragment>
        {
          name && <UserCardComponent
            {...this.props}
            img = { imgCard }
            showLink={checkPermission('singleUser_route') && this.props.id && !hideLink}
          />
        }
      </Fragment>
    )
  }
}

const mapStateToProps = () => ({})

export default compose(connect(mapStateToProps))(UserCardContainer)
