/* eslint-disable import/no-duplicates */
import React from 'react'
import { Button, Table } from 'reactstrap'
import Files from 'react-files'
import { UploadOutlined } from '@ant-design/icons'
import { Alert } from 'antd'
import { utcToLocal } from '../../utils/helpers'
import Rephrase from '../../utils/Rephrase'

export default function DropzoneFileUploader(props) {
  const isDisabled = props.isDisabled
  const buttonStyle = isDisabled ? { cursor: 'default' } : {}
  const disabledTitle = props?.disabledTitle ?? ''

  return (
    <div className="dropzone-drag-border">
      {!props.is_review_appraiser && <Files
        className='files-dropzone'
        onChange={props.onFilesChange}
        onError={props.onFilesError}
        multiple
        maxFiles={10}
        minFileSize={0}
        clickable={!isDisabled}
      >
        <div className="dropzone-drag-hover-text">
          <UploadOutlined className="dropzone-drag-hover-text-header" title={disabledTitle}/>
          <h3 className="dropzone-drag-hover-text-header">Drop files here to upload</h3>
        </div>
        <div className="dropzone-drag-hover">
          <div className='w-100 d-flex flex-row'>
            <Button color='success' disabled={isDisabled} style={buttonStyle}>Drop files here or click to upload</Button>
          </div>
          <Table className='my-2'>
            <tbody>
              {props.existing_files && props.existing_files.length > 0
                ? props.existing_files.map((file, idx) => {
                  if (props.deleted_files && props.deleted_files.indexOf(file.id) > -1) {
                    return null
                  }
                  return (<tr key={file.id}>
                    <td className="w-100">
                      <p className="font-weight-bold mb-0">{file.document_name}
                        <span className="font-weight-normal">    {utcToLocal(file.upload_datetime).format('llll')}</span>
                      </p>
                    </td>
                    <td className="td--padding-button d-flex">
                      <Button color="danger  margin-right-5">Remove</Button>
                      <Button type='button' color="primary">Download</Button>
                    </td>
                  </tr>)
                }
                )
                : null}

              {(!props.existing_files || props.existing_files.length === 0) && props.showEmptyMessage ? <Alert message={<Rephrase>No files have been uploaded yet. Click the button above to share files with this appraisal firm.</Rephrase>} type="info" /> : null}
            </tbody>
          </Table>

          <Table className='my-2'>
            <tbody>
              {props.files && props.files.length > 0
                ? props.files.map((file, idx) =>
                  <tr key={file.id}>
                    <td className="w-100">
                      <p className="font-weight-bold mb-0">{file.name}
                        <span className="font-weight-normal">{file.sizeReadable}</span>
                      </p>
                    </td>
                    {props.fileRemove
                      ? <td className="td--padding-button d-flex">
                        <Button id={file.id} color="danger  margin-right-5">Remove</Button>
                      </td>
                      : null}
                  </tr>
                )
                : null}
            </tbody>
          </Table>
        </div>
      </Files>}
    </div>
  )
}
