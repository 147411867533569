import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'reactstrap'

const propTypes = {
  inputClassName: PropTypes.string,
  placeholder: PropTypes.string,
  pattern: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  min: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
}
const defaultProps = {
  inputClassName: 'input-custom',
}

export default class NumberInput extends Component {
  handleChange = (e) => {
    this.props.onChange(e.target.value)
  }

  render() {
    const {
      inputClassName, placeholder, pattern,
      required, disabled, name, value, min, max, step,
    } = this.props
    return (
      <Input
        type='number'
        name={name}
        className={inputClassName}
        placeholder={placeholder}
        pattern={pattern}
        onChange={this.handleChange}
        required={required}
        disabled={disabled}
        value={value}
        min={min}
        max={max}
        step={step}
        onInput={this.props.onInput}
      />
    )
  }
}

NumberInput.propTypes = propTypes
NumberInput.defaultProps = defaultProps
