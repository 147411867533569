import { loadableWithRetry } from '../../common/utils/lazyLoad'

const routes = [
  {
    path: '/',
    exact: true,
    name: 'branches',
    component: loadableWithRetry(() => import('./BranchesContainer')),
  },
  {
    path: '/:id',
    name: 'singleBranch',
    component: loadableWithRetry(() => import('./BranchesContainer')),
  },
]

export default routes
