import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import API, { API_VERSION, REQUEST_METHOD } from '../../../api'


export const initialState = {
  submissionFiles: {},
}

export const fetchSubmissionFiles = createAsyncThunk('submissionFiles/fetchSubmissionFiles', async (orderId, { rejectWithValue }) => {
  try {
    const submissionFiles = await API('submission-files/v1',
      API_VERSION.SERVICE).request(REQUEST_METHOD.GET,
      {
        order_id: orderId,
      },
      {}
    )
    return submissionFiles
  } catch (err) {
    return rejectWithValue(err.errors._error)
  }
})

const submissionFilesSlice = createSlice({
  name: 'submissionFiles',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSubmissionFiles.fulfilled]: (state, action) => {
      state.submissionFiles = {
        results: action.payload.results,
        count: action.payload.count,
      }
    },
  },
})


export default submissionFilesSlice.reducer
