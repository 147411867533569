import React, { Component } from 'react'
import { FlagOutlined, FlagFilled } from '@ant-design/icons'
import { compose } from 'redux'
import { connectResource } from '../../common/utils/resource'
import { connect } from 'react-redux'
import { checkPermission } from '../../common/utils/helpers'
import { Tooltip } from 'antd'


class FlagContainer extends Component {
    state = {
      flag: false,
      flags: [],
    }

    componentDidMount() {
      const { order } = this.props
      if (order && order.flags && order.flags.length > 0) {
        const lastFlagItem = order.flags[order.flags.length - 1]
        this.setState({
          flags: order.flags,
          flag: lastFlagItem ? lastFlagItem.flagged : false,
        })
      }
    }

    componentDidUpdate(prevProps) {
      if (this.props.order && (this.props.order.latest_flag !== prevProps.order.latest_flag)) {
        this.setState({
          flag: this.props.order.latest_flag,
          flags: this.props.order.flags,
        })
      }
    }

    renderFlag = () => {
      if (this.state.flag) {
        return <FlagFilled
          onClick={this.updateFlag}
          style={{ fontSize: this.props.fontSize, color: '#f86c6b' }}
          className={'flag-item-anticon mt-1'}
        />
      }
      return <FlagOutlined
        onClick={this.updateFlag}
        style={{ fontSize: this.props.fontSize, color: 'gray' }}
        className={'flag-item-anticon mt-1'}
      />
    }

    updateFlag = () => {
      if (!checkPermission('order_flag_access')) {
        return
      }
      const newFlag = !this.state.flag
      this.setState({ flag: newFlag }, _ => {
        const flagCount = newFlag ? 1 : -1
        return this.props.updateFlagCount && this.props.updateFlagCount(flagCount)
      })
      if (this.state.flags.length > 0) {
        return this.props.flags.put({
          flags: newFlag,
        }).then(_ => {
          return this.props.refresh && this.props.refresh()
        }).catch(_ => {
          this.setState({ flag: !newFlag })
        })
      }
      this.setState(prevState => ({
        flags: [...prevState.flags, { flagged: true }],
      }))
      return this.props.flags.post().then(_ => {
        return this.props.refresh && this.props.refresh()
      }).catch(_ => {
        this.setState({ flag: !newFlag })
      })
    }

    render() {
      return <Tooltip trigger={ !checkPermission('order_flag_access') ? 'click' : ''}
        placement="top" title="Requires permission">
        <span style={{ cursor: !checkPermission('order_flag_access') ? 'not-allowed' : 'pointer' }} >
          {this.renderFlag()}
        </span>
      </Tooltip>
    }
}

FlagContainer.defaultProps = {
  fontSize: '20px',
}
export default compose(
  connect((state, props) => ({ id: props.order.id })),
  connectResource({
    namespace: 'flags',
    endpoint: 'order/:id/flags',
    prefetch: false,
    apiVersion: 2,
    successMessage: {
      PUT: 'Order has been updated',
      POST: 'Order has been updated',
    },
  })
)(FlagContainer)
