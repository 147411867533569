import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import API, { API_VERSION, REQUEST_METHOD } from '../../../api'
import STATUS from '../../../common/constants/reducer_statuses'

export const initialState = {
  orderRequirementsRuleSets: [],
  orderRequirementsRuleSetsStatus: STATUS.IDLE,
}

export const fetchOrderRequirementsRuleSets = createAsyncThunk(
  'lender/fetchOrderRequirementsRuleSets',
  async (category, { rejectWithValue }) => {
    try {
      const response = await API(
        `lender/rulesets?category=${category}`,
        API_VERSION.TWO
      ).request(REQUEST_METHOD.GET)
      return response.results
    } catch (err) {
      return rejectWithValue(err.errors._error)
    }
  }
)

const lenderSlice = createSlice({
  name: 'lender',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchOrderRequirementsRuleSets.pending]: (state) => {
      state.orderRequirementsRuleSetsStatus = STATUS.LOADING
    },
    [fetchOrderRequirementsRuleSets.fulfilled]: (state, action) => {
      state.orderRequirementsRuleSetsStatus = STATUS.SUCCEEDED
      state.orderRequirementsRuleSets = action.payload
    },
    [fetchOrderRequirementsRuleSets.rejected]: (state, action) => {
      state.orderRequirementsRuleSetsStatus = STATUS.FAILED
      state.orderRequirementsRuleSets = action.error
    },
  },
})

export default lenderSlice.reducer
