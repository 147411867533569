export const PAYMENT_STATUS_NO_PAYMENT_REQUESTED = 'No Payment Requested'
export const PAYMENT_STATUS_PAID_IN_FULL = 'Order Paid in Full'
export const PAYMENT_STATUS_PARTIAL_PAYMENT = 'Partial Payment'
export const PAYMENT_STATUS_PENDING_PAYMENTS = 'Pending Payments'
export const PAYMENT_STATUS_PAYMENT_REFUND = 'Payment Refund'
export const PAYMENT_STATUS_OVERPAID = 'Order Overpaid'

export const PaymentStatuses = {
  PAYMENT_STATUS_NO_PAYMENT_REQUESTED,
  PAYMENT_STATUS_PAID_IN_FULL,
  PAYMENT_STATUS_PARTIAL_PAYMENT,
  PAYMENT_STATUS_PENDING_PAYMENTS,
  PAYMENT_STATUS_PAYMENT_REFUND,
  PAYMENT_STATUS_OVERPAID,
}
