/* global GIT_VERSION */
/* global GIT_BRANCH */
/* global GIT_COMMITHASH */
import React from 'react'
import VersionDetails from '@reggora_shared_ui/components/VersionDetails'

const Versions = () => {
  const feDetails = {
    'FE version': GIT_VERSION,
    'FE branch': GIT_BRANCH,
    'FE commit': GIT_COMMITHASH,
    'LBE url': API2_URL,
  }

  const beFetches = [{
    url: `${API2_URL}healthchecks/check`,
    prefix: 'LBE',
  }]

  return (
    <VersionDetails
      feDetails={feDetails}
      beFetches={beFetches}
    />
  )
}

export default Versions
