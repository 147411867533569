import React, { Component, Fragment } from 'react'
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
} from 'reactstrap'
import { utcToLocal, downloadFile } from '../../common/utils/helpers'

import {
  DELIVERY_TIMESTAMP_FIELDS,
  DELIVERY_FIELD_TO_LABEL,
} from '../../common/constants/consumer'


class ConsumerInfoModal extends Component {
  renderModalContent = () => {
    const {
      loanNumber,
      consumerName,
      orderID,
      consumerID,
      consumerDeliveryTimestamps,
      maximumDeliveryVersion,
      appraisal_delivery_status_fields_toggles_settings,
    } = this.props
    return (<SubmissionDeliveryRecord
      loanNumber={loanNumber}
      consumerName={consumerName}
      orderID={orderID}
      consumerID={consumerID}
      maximumDeliveryVersion={maximumDeliveryVersion}
      consumerDeliveryTimestamps={consumerDeliveryTimestamps}
      appraisal_delivery_status_fields_toggles_settings={appraisal_delivery_status_fields_toggles_settings}
    />)
  }

  render() {
    const {
      onHide,
      consumerName,
    } = this.props

    return (<Fragment>
      <ModalHeader>{consumerName} Status</ModalHeader>
      <ModalBody>
        {this.renderModalContent()}
      </ModalBody>
      <ModalFooter>
        <Button onClick={onHide}>Cancel</Button>
      </ModalFooter>
    </Fragment>)
  }
}

class SubmissionDeliveryRecord extends Component {
  /*
  Helper component, I'm sorry it is in this file for now but our smaller components
  are poorly organized. We could create a separate folder for page-specific helper
  components, but for now my intent is that SubmissionDeliveryRecord will only be used
  within ConsumerInfoModal
  */

  generatePDF(versionNumber) {
    const {
      loanNumber,
      consumerName,
      orderID,
      consumerID,
    } = this.props

    let fileNameVersionString = ''
    let urlVersionString = ''
    if (versionNumber) {
      fileNameVersionString = ` V${versionNumber}`
      urlVersionString = `&submission_version=${versionNumber}`
    }

    const fileName = `${loanNumber}${fileNameVersionString} - ${consumerName} Delivery Status.pdf`

    const requestURL = `${API2_URL}order/${orderID}/delivery?consumer_id=${consumerID}${urlVersionString}`
    downloadFile(requestURL, fileName)
  }

  renderTimestampRow(timestampLabel, timestampValue) {
    let displayValue = 'Not Provided'
    if (timestampValue) {
      displayValue = utcToLocal(timestampValue).format('LLL')
    }

    return (
      <Row className='consumer-delivery-modal'>
        <h5>{timestampLabel} : </h5>
        <p>{displayValue}</p>
      </Row>
    )
  }

  renderMailedByRow(label, value) {
    const displayValue = value || 'Not Provided'
    return (
      <Row className='consumer-delivery-modal'>
        <h5>{label} : </h5>
        <p>{displayValue}</p>
      </Row>
    )
  }

  renderSectionForSubmissionVersion(versionNumber, consumerDeliveryTimestamps) {
    const timestampRows = []

    Object.values(DELIVERY_TIMESTAMP_FIELDS).forEach(fieldName => {
      const timestampLabel = DELIVERY_FIELD_TO_LABEL[fieldName]
      const timestampValue = consumerDeliveryTimestamps[fieldName]
      timestampRows.push(this.renderTimestampRow(timestampLabel, timestampValue))
    })
    timestampRows.push(this.renderMailedByRow('Mailed by', consumerDeliveryTimestamps.mailed_by))
    if (this.props.appraisal_delivery_status_fields_toggles_settings.ip_address) {
      if (consumerDeliveryTimestamps.accepted_consent_ip_address &&
        consumerDeliveryTimestamps.accepted_consent_ip_address !== '') {
        timestampRows.push(this.renderMailedByRow('Consent IP Address', consumerDeliveryTimestamps.accepted_consent_ip_address))
      }
      if (consumerDeliveryTimestamps.denied_consent_ip_address &&
        consumerDeliveryTimestamps.denied_consent_ip_address !== '') {
        timestampRows.push(this.renderMailedByRow('Deny Consent IP Address', consumerDeliveryTimestamps.denied_consent_ip_address))
      }
    }

    if (this.props.appraisal_delivery_status_fields_toggles_settings.branch_name &&
      consumerDeliveryTimestamps.branch_name) {
      timestampRows.push(this.renderMailedByRow('Branch Name', consumerDeliveryTimestamps.branch_name))
    }

    return (
      <div className='w-100'>
        <div className='d-flex justify-content-between my-2'>
          <h4>Delivery of Submission {versionNumber || ''}</h4>
          <Button color='primary' onClick={() => this.generatePDF(versionNumber)}>Download PDF</Button>
        </div>
        <div className='w-100'>
          {timestampRows}
        </div>
      </div>
    )
  }

  render() {
    const {
      consumerDeliveryTimestamps,
      maximumDeliveryVersion,
    } = this.props

    const submissionRows = []
    for (let i = 0; i <= maximumDeliveryVersion; i++) {
      if (consumerDeliveryTimestamps[i]) {
        submissionRows.push(this.renderSectionForSubmissionVersion(i, consumerDeliveryTimestamps[i]))
      }
    }

    return (
      <Row className='consumer-delivery-modal'>
        {submissionRows}
      </Row>
    )
  }
}

export default ConsumerInfoModal
