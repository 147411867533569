import React from 'react'
import { Tag } from 'antd'
import styles from './RuleBadge.module.css'

/**
 * @param {{ display_outcome: string, outcome: OutcomeType }} props
 */
export const RuleBadge = ({ display_outcome, outcome }) => {
  const color = resolveColor(outcome)

  return (
    <div className={styles.ruleBadge}>
      <Tag color={color}>
        <strong>{display_outcome}</strong>
      </Tag>
    </div>
  )
}

/**
 * @param {OutcomeType} outcome
 */
const resolveColor = (outcome) => {
  /**
   * @type {Record<OutcomeType, string>}
   */
  const colorMap = {
    // Green
    ACCEPTED: 'green',
    PASS: 'green',
    // Red
    HARD_STOP: 'red',
    SOFT_STOP: 'red',
    NO_STOP: 'red',
    REJECTED: 'red',
    // Orange
    MANUAL: 'orange',
    ERROR: 'orange',
    // Default
    FAIL: 'default',
    PREREQUISITE_FAIL: 'default',
    SKIP: 'default',
  }

  return colorMap[outcome] || 'default'
}
