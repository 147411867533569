import React from 'react'
import { Table } from 'antd'
import styles from './ResultTable.module.css'

export const ResultTable = (props) => {
  const {
    columns,
    data,
    isLoading,
  } = props

  const paginationSettings = {
    defaultPageSize: 50,
    pageSizeOptions: ['50', '100', '150'],
    showSizeChanger: true,
  }

  return (
    <Table
      bordered={true}
      columns={columns}
      dataSource={data}
      loading={isLoading}
      pagination={paginationSettings}
      rowClassName={(record) => {
        return record.fieldData.hasSystemOverride
          ? styles.tableRowChanged
          : styles.tableRow
      }}
      scroll={{ y: 500 }}
      size="middle"
    />
  )
}
