import React, { Component } from 'react'

import { Icon as LegacyIcon } from '@ant-design/compatible'

import { UNSORTED, UP, DOWN } from './constants'

const upIconType = 'caret-up'
const downIconType = 'caret-down'

// constants used within component and in tests
export const readableIconLabel = 'Sort'
export const unsortedIconClass = 'table-filter'
export const sortedIconClass = 'sort-button-icon'
export const sortDownClass = `anticon-${downIconType}`
export const sortUpClass = `anticon-${upIconType}`

export default class SortIcon extends Component {
  /**
     Displays an icon to indicate the sortDirection

     Currently displays a double arrow (unsorted) or a down arrow or an up arrow
     */
  render() {
    const {
      sortDirection,
      onClick,
    } = this.props
    let result = null
    switch (sortDirection) {
      case UNSORTED:
        result = (
          <i
            className={unsortedIconClass}
            onClick={onClick}
            aria-label={readableIconLabel}
          />
        )
        break
      case UP:
        result = (
          <LegacyIcon
            className={`m-1 ${sortedIconClass}`}
            type={upIconType}
            onClick={onClick}
            aria-label={readableIconLabel}
          />
        )
        break
      case DOWN:
        result = (
          <LegacyIcon
            className={`m-1 ${sortedIconClass}`}
            type={downIconType}
            onClick={onClick}
            aria-label={readableIconLabel}
          />
        )
        break
    }
    return result
  }
}
