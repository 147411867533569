import { push } from 'react-router-redux'
import { setData } from '../../common/utils/resource'
import { Observable } from 'rxjs'

const logoutEpic = (action$, store) => action$
  .ofType('LOGOUT')
  .mergeMap(_ => {
    const sso_client = localStorage.getItem('client_identity_redirect_url')
    return Observable
      .of(
        setData(
          {},
          { resource: { namespace: 'session' } }
        ),
        setData(
          {},
          { resource: { namespace: 'user' } }
        ),
        push(`/auth/login${sso_client || ''}`),
      )
  })
const notFoundEpic = (action$) => action$
  .ofType('NOT_FOUND')
  .mergeMap(_ => Observable
    .of(
      push('/404')
    )
  )

export {
  logoutEpic,
  notFoundEpic,
}
