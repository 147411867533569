import { Dropdown, Input } from 'antd'
import { findIndex } from 'lodash'
import React, { useState, useMemo, useContext } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import searchQuery from '../../../common/utils/searchQueryParser'
import SearchSuggestions, { SearchSuggestionsContainer, ORDER_COMPONENT_DOMAIN } from '../../SearchSuggestions'
import SearchInput from '../../../common/forms/SearchInput'
import analytics from '@helpers/segment'
import { TrackingContext } from '../TrackingContext'

const FIELD_NAME = 'searchText'

const menuId = 'order-search-suggestion-menu'

/*
OrderSearch Component - It renders an input field with a search icon button.
A suggestion dropdown is opened when there is any word typed in the field. The suggestion is based on the current focused word.
Clicking on any suggestion will transform the focused word to be targeted field search.

Props
- searchText (string) - The search terms
- clearSearch (() => void) - The callback to clear the search terms
- onFieldChange ((event) => void) - The callback whenever there is any change to the input field.
- onSearchSubmit (() => void) - The callback for when the search is submitted thru pressing "Enter" or clicking the search icon.

*/
const OrderSearch = ({
  searchText,
  clearSearch,
  onFieldChange,
  onSearchSubmit,
}) => {
  const props = {
    searchText,
    clearSearch,
    onFieldChange,
    onSearchSubmit,
  }
  const { orderSearchDropdown, useImprovedLoanSearch } = useFlags() || {}
  const [focusIndex, setFocusIndex] = useState(undefined)
  const [dropDownVisible, setDropDownVisible] = useState(false)
  const parsedTerms = useMemo(() => searchText ? searchQuery.parse(searchText) : undefined, [searchText])
  const { trackState, setTrackState } = useContext(TrackingContext)

  if (!orderSearchDropdown) {
    return (
      <SearchInput
        className='order-filter'
        placeholder='Search by order ID, borrower name, or address'
        { ...props }
      />
    )
  }

  const commonSearchClass = 'order-search-ui-with-suggestions-element'

  const handleSearch = (newValue) => {
    setDropDownVisible(false)
    if (searchText && newValue) {
      const subqueries = []
      const searchTexts = []
      parsedTerms.offsets.forEach(e => {
        if (e.keyword !== undefined && e.value !== undefined) {
          subqueries.push(e.keyword)
          searchTexts.push(e.value)
        }
      })
      if (subqueries.length > 0) {
        analytics.trackComponent('Orders Search Bar Subquery Searched', {
          search_filter_subquery: subqueries,
          search_text: searchTexts,
        })
      }
      setTrackState({
        searched: true,
        searchesUntilOrderClick: trackState.searchesUntilOrderClick + 1,
        pageClicksUntilOrderClick: trackState.pageClicksUntilOrderClick,
      })
      onSearchSubmit()
    } else {
      clearSearch()
    }
  }

  const handleChange = (event) => {
    setDropDownVisible(true)
    onFieldChange(event)
  }

  const updateFocusIndex = (offset) => {
    if (parsedTerms && parsedTerms.offsets) {
      setFocusIndex(findIndex(parsedTerms.offsets, term => {
        const { offsetStart, offsetEnd } = term
        return offsetStart <= offset && offset <= offsetEnd
      }))
    }
  }

  const handleUpdateFocus = (event) => {
    if (!event.key || event.key !== 'Enter') {
      setDropDownVisible(true)
      updateFocusIndex(event.target.selectionStart)
    }
  }

  const getSearchTextWithUpdatedTerm = (updatedTerm, offset) => {
    const { offsetStart, offsetEnd } = offset
    return searchText.slice(0, offsetStart) + updatedTerm + searchText.slice(offsetEnd)
  }

  const handleUpdateTerm = (updatedTerm) => {
    if (searchText !== undefined && focusIndex !== undefined && parsedTerms && parsedTerms.offsets) {
      const offset = parsedTerms.offsets[focusIndex]
      if (offset) {
        const value = getSearchTextWithUpdatedTerm(updatedTerm, offset)
        onFieldChange({ target: { name: FIELD_NAME, value } })
      }
    }
  }

  let searchTerm
  let keyword
  let quote
  if (parsedTerms && parsedTerms.offsets && focusIndex !== undefined) {
    const offset = parsedTerms.offsets[focusIndex]
    if (offset) {
      searchTerm = offset.value || offset.text
      keyword = offset.keyword
      quote = offset.quote
    }
  }

  const handleMenuFocus = (event) => {
    if (dropDownVisible && searchTerm) {
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        event.preventDefault()
        document.getElementById(menuId).focus()
      }
    }
  }


  const allOnBlur = (ev) => {
    const relatedTarget = ev?.relatedTarget

    if (!relatedTarget?.classList?.contains?.(commonSearchClass)) {
      // search UI is losing focus and it is moving outside of search UI, hide the dropdown
      setDropDownVisible(false)
    }
  }

  if (useImprovedLoanSearch) {
    const orderSearchSuggestions = [
      {
        keyword: 'Borrower',
        readable: 'Borrower',
        helpText: (value) => `Borrower names that contain "${value}"`,
      },
      {
        keyword: 'CoBorrower',
        readable: 'Co-Borrower',
        helpText: (value) => `Co-Borrower names that contain "${value}"`,
      },
      {
        keyword: 'Address',
        readable: 'Address',
        helpText: (value) => `Order addresses contain "${value}"`,
      },
      {
        keyword: 'City',
        readable: 'City',
        helpText: (value) => `City names that contain "${value}"`,
      },
      {
        keyword: 'State',
        readable: 'State',
        helpText: (value) => `State names that contain "${value}"`,
      },
      {
        keyword: 'Zip',
        readable: 'Zip',
        helpText: (value) => `Zip codes that contain "${value}"`,
      },
      {
        keyword: 'Branch',
        readable: 'Branch',
        helpText: (value) => `Branch names that contain "${value}"`,
      },
      {
        keyword: 'ID',
        readable: 'Order ID',
        helpText: (value) => `Order IDs that contain "${value}"`,
      },
    ]
    const searchSuggestionsContainerProps = {
      parentComponentDomain: ORDER_COMPONENT_DOMAIN,
      searchSuggestions: orderSearchSuggestions,
      handleSearch: handleSearch,
    }
    return (
      <SearchSuggestionsContainer
        {...searchSuggestionsContainerProps}
        {...props}
      />
    )
  }

  return (
    <Dropdown
      visible={dropDownVisible}
      overlay={
        <SearchSuggestions
          menuId={menuId}
          searchTerm={searchTerm}
          keyword={keyword}
          quote={quote}
          updateTerm={handleUpdateTerm}
          onMouseLeave={() => setDropDownVisible(false)}
          onEnter={handleSearch}
          commonSearchClass={commonSearchClass}
          onBlur={allOnBlur}
        />
      }
      overlayClassName="ant-select-dropdown"
    >
      <Input.Search
        enterButton
        placeholder='Search by Borrower, Co-Borrower, Address, City, State, Zip Code or Order ID'
        name={FIELD_NAME}
        value={searchText}
        onChange={handleChange}
        onSearch={handleSearch}
        onClick={handleUpdateFocus}
        onKeyUp={handleUpdateFocus}
        onKeyDown={handleMenuFocus}
        autoComplete='off'
        onFocus={() => setDropDownVisible(true)}
        allowClear={true}
        onBlur={allOnBlur}
        className={commonSearchClass}
      />
    </Dropdown>
  )
}

export default OrderSearch
