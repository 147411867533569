import React, { useState } from 'react'
import { Button } from 'reactstrap'

const SkipButton = ({
  onSkip,
}) => {
  const [submitting, setSubmitting] = useState(false)

  const onClick = () => {
    setSubmitting(true)
    onSkip()
  }

  return (
    <Button onClick={onClick} disabled={submitting}>Skip</Button>
  )
}

export default SkipButton
