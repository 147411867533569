import {
  VIEW_NOTIFICATIONS,
  SET_SHOULD_REFRESH_SINGLE_ORDER
} from '../../../utils/resource'

export function viewNotifications() {
  return {
    type: VIEW_NOTIFICATIONS
  }
}

export function setShouldRefreshSingleOrder(value) {
  return {
    type: SET_SHOULD_REFRESH_SINGLE_ORDER,
    payload: { value }
  }
}
