import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import PropTypes from 'prop-types'
import moment from 'moment/moment'
const propTypes = {
  startDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  selected: PropTypes.object,
  placeholderText: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  name: PropTypes.string,
}
const defaultProps = {
  readOnly: false,
}
export default class DatePickerInput extends Component {
  handleChange = (selectedOption) => {
    this.props.onChange(selectedOption ? selectedOption.toDate() : selectedOption)
  }

  render() {
    const {
      className,
      placeholderText, disabled, readOnly, name, required, minDate, startDate,
    } = this.props

    return (
      <DatePicker
        selected={moment(this.props.value || Date.now())}
        onChange={this.handleChange}
        className={className}
        minDate={minDate}
        startDate={startDate || moment()}
        placeholderText={placeholderText}
        disabled={disabled}
        readOnly={readOnly}
        name={name}
        required={required}
      />
    )
  }
}

DatePickerInput.propTypes = propTypes
DatePickerInput.defaultProps = defaultProps
