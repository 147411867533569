import { loadableWithRetry } from '../../common/utils/lazyLoad'

const routes = [
  {
    path: '/',
    component: loadableWithRetry(() => import('./FeeContainer')),
    name: 'fee',
    exact: true,
  },
]

export default routes
