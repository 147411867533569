import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input, Button, Form } from 'antd'
import {
  getInspectionDetail,
  getInspectionDetailValue,
  countInspectionDetails,
} from '../../../../helpers/inspections'
import { apiToReadable } from '../../../common/utils/helpers'
import { CAR_STORAGE, THIRD_PARTY } from './constants'

import {
  createInspectionDetails,
  updateInspectionDetail,
} from '../../../store/reducers/inspection/inspectionSlice'

import {
  selectInspectionDetailsDataMap,
  selectIsCreateInspectionDetailsLoading,
  selectIsUpdateInspectionDetailLoading,
} from '../../../store/reducers/inspection/inspectionSelectors'
import Loader from '../../../common/layout/components/Loader'

const getCarStoragesForLevel = (inspectionDetailsDataMap, levelQuestionLocationPrefix) => {
  const carStorages = []
  let carStorageNumber = 1
  const roomCount = countInspectionDetails(
    inspectionDetailsDataMap,
    `${levelQuestionLocationPrefix}.structure-level-room.{counter}.add_room@setup.0`,
  )
  for (let i = 0; i < roomCount; i += 1) {
    const { values } = getInspectionDetail(
      inspectionDetailsDataMap,
      `${levelQuestionLocationPrefix}.structure-level-room.${i}.add_room@setup.0`,
    )
    if (values[0] === CAR_STORAGE) {
      carStorages.push({
        roomLocation: `${levelQuestionLocationPrefix}.structure-level-room.${i}`,
        carStorageNumber: carStorageNumber,
      })
      carStorageNumber += 1
    }
  }
  return carStorages
}

const FloorplanLevelForm = ({ levelTypeInspectionDetail, levelNumber }) => {
  const dispatch = useDispatch()
  const [totalArea, setTotalArea] = useState('')
  const [finishedLivingArea, setFinishedLivingArea] = useState('')
  const [finishedNonGlaLivingArea, setFinishedNonGlaLivingArea] = useState('')

  const inspectionId = levelTypeInspectionDetail.inspection_id
  const levelQuestionLocationPrefix = levelTypeInspectionDetail.question_location.split('.').splice(0, 4).join('.')
  const totalAreaQuestionLocation = `${levelQuestionLocationPrefix}.total_area.0`
  const finishedLivingAreaQuestionLocation = `${levelQuestionLocationPrefix}.finished_living_area.0`
  const finishedNonGlaLivingAreaQuestionLocation = `${levelQuestionLocationPrefix}.finished_non_gla_living_area.0`

  const inspectionDetailsDataMap = useSelector(selectInspectionDetailsDataMap)

  const carStorages = getCarStoragesForLevel(inspectionDetailsDataMap, levelQuestionLocationPrefix)
  const existingSpaceAreaDetails = carStorages.map(({ roomLocation }) => {
    return getInspectionDetail(inspectionDetailsDataMap, `${roomLocation}.space_area.0`)
  }).filter(detail => detail)

  const [spaceAreas, setSpaceAreas] = useState(carStorages.map(() => ''))

  const existingTotalAreaDetail = getInspectionDetail(inspectionDetailsDataMap, totalAreaQuestionLocation)
  const existingFinishedLivingAreaDetail = getInspectionDetail(
    inspectionDetailsDataMap,
    finishedLivingAreaQuestionLocation
  )
  const existingFinishedNonGlaLivingAreaDetail = getInspectionDetail(
    inspectionDetailsDataMap,
    finishedNonGlaLivingAreaQuestionLocation,
  )

  const levelType = getInspectionDetailValue(inspectionDetailsDataMap, levelTypeInspectionDetail.question_location)

  const isCreateInspectionDetailsLoading = useSelector(selectIsCreateInspectionDetailsLoading)
  const isUpdateInspectionDetailLoading = useSelector(selectIsUpdateInspectionDetailLoading)

  const hasSpaceAreas = carStorages.length === 0 || (spaceAreas.filter(value => value).length === carStorages.length)

  useEffect(() => {
    if (existingSpaceAreaDetails.length > 0 && spaceAreas.filter(value => value).length === 0) {
      setSpaceAreas(carStorages.map(({ roomLocation }) => getInspectionDetailValue(
        inspectionDetailsDataMap,
        `${roomLocation}.space_area.0`,
      )))
    }
  }, [
    inspectionDetailsDataMap,
    carStorages,
    existingSpaceAreaDetails,
    spaceAreas,
  ])

  useEffect(() => {
    if (existingTotalAreaDetail) {
      setTotalArea(getInspectionDetailValue(inspectionDetailsDataMap, totalAreaQuestionLocation))
    }
    if (existingFinishedLivingAreaDetail) {
      setFinishedLivingArea(getInspectionDetailValue(inspectionDetailsDataMap, finishedLivingAreaQuestionLocation))
    }
    if (existingFinishedNonGlaLivingAreaDetail) {
      setFinishedNonGlaLivingArea(
        getInspectionDetailValue(inspectionDetailsDataMap, finishedNonGlaLivingAreaQuestionLocation)
      )
    }
  }, [
    existingTotalAreaDetail,
    existingFinishedLivingAreaDetail,
    existingFinishedNonGlaLivingAreaDetail,
    inspectionDetailsDataMap,
    totalAreaQuestionLocation,
    finishedLivingAreaQuestionLocation,
    finishedNonGlaLivingAreaQuestionLocation,
  ])

  const setSpaceArea = (value, index) => {
    const newSpaceAreas = [...spaceAreas]
    newSpaceAreas[index] = value
    setSpaceAreas(newSpaceAreas)
  }

  const handleSave = () => {
    if (existingTotalAreaDetail) {
      dispatch(updateInspectionDetail({
        inspectionId,
        inspectionDetailId: existingTotalAreaDetail._id,
        detail: {
          ...existingTotalAreaDetail,
          values: [totalArea],
        },
      }))
    } else {
      dispatch(createInspectionDetails({
        inspectionId,
        details: [
          {
            question_location: totalAreaQuestionLocation,
            inspection_id: inspectionId,
            values: [totalArea],
            metadata: {
              source: THIRD_PARTY,
            },
          },
        ],
      }))
    }

    if (existingFinishedLivingAreaDetail) {
      dispatch(updateInspectionDetail({
        inspectionId,
        inspectionDetailId: existingFinishedLivingAreaDetail._id,
        detail: {
          ...existingFinishedLivingAreaDetail,
          values: [finishedLivingArea],
        },
      }))
    } else {
      dispatch(createInspectionDetails({
        inspectionId,
        details: [
          {
            question_location: finishedLivingAreaQuestionLocation,
            inspection_id: inspectionId,
            values: [finishedLivingArea],
            metadata: {
              source: THIRD_PARTY,
            },
          },
        ],
      }))
    }

    if (existingFinishedNonGlaLivingAreaDetail) {
      dispatch(updateInspectionDetail({
        inspectionId,
        inspectionDetailId: existingFinishedNonGlaLivingAreaDetail._id,
        detail: {
          ...existingFinishedNonGlaLivingAreaDetail,
          values: [finishedNonGlaLivingArea],
        },
      }))
    } else {
      dispatch(createInspectionDetails({
        inspectionId,
        details: [
          {
            question_location: finishedNonGlaLivingAreaQuestionLocation,
            inspection_id: inspectionId,
            values: [finishedNonGlaLivingArea],
            metadata: {
              source: THIRD_PARTY,
            },
          },
        ],
      }))
    }

    if (existingSpaceAreaDetails.length > 0) {
      for (let i = 0; i < existingSpaceAreaDetails.length; i += 1) {
        const existingSpaceAreaDetail = existingSpaceAreaDetails[i]
        dispatch(updateInspectionDetail({
          inspectionId,
          inspectionDetailId: existingSpaceAreaDetail._id,
          detail: {
            ...existingSpaceAreaDetail,
            values: [spaceAreas[i]],
          },
        }))
      }
    } else if (carStorages.length > 0) {
      dispatch(createInspectionDetails({
        inspectionId,
        details: carStorages.map(({ roomLocation }, index) => ({
          question_location: `${roomLocation}.space_area.0`,
          inspection_id: inspectionId,
          values: [spaceAreas[index]],
          metadata: {
            source: THIRD_PARTY,
          },
        })),
      }))
    }
  }

  return (
    <div>
      {isCreateInspectionDetailsLoading || isUpdateInspectionDetailLoading ? <Loader /> : null}
      <p>
        Level {levelNumber}: {apiToReadable(levelType)}
      </p>
      <Form layout="vertical" style={{ maxWidth: '300px' }}>
        <Form.Item label="Total Area">
          <Input value={totalArea} type="number" onChange={e => setTotalArea(e.target.value)}/>
        </Form.Item>
        <Form.Item label="Finished Living Area">
          <Input value={finishedLivingArea} type="number" onChange={e => setFinishedLivingArea(e.target.value)} />
        </Form.Item>
        <Form.Item label="Finished Non-GLA Living Area">
          <Input value={finishedNonGlaLivingArea} type="number" onChange={e => setFinishedNonGlaLivingArea(e.target.value)} />
        </Form.Item>
        {carStorages.map(({ carStorageNumber, roomLocation }, index) => (
          <Form.Item label={`Car Storage ${carStorageNumber} - Space Area`} key={roomLocation}>
            <Input
              value={spaceAreas[index]}
              type="number"
              onChange={e => setSpaceArea(e.target.value, index)}
            />
          </Form.Item>
        ))}
        <Form.Item>
          <Button type="primary" onClick={handleSave} disabled={!totalArea || !finishedLivingArea || !finishedNonGlaLivingArea || !hasSpaceAreas}>
            Save Level Data
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}
export default FloorplanLevelForm
