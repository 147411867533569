import React, { useMemo } from 'react'
import './style.css'
import { List, Timeline, Row, Col, Divider, Collapse } from 'antd'
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  EditOutlined,
  MinusOutlined,
  PlusOutlined,
  SmallDashOutlined,
} from '@ant-design/icons'
import { utcToLocal } from '@app/common/utils/helpers'

const { Panel } = Collapse

const iconByStatus = {
  added: { color: '#d9f7be', Icon: PlusOutlined, label: 'Added' },
  removed: { color: '#fff1f0', Icon: MinusOutlined, label: 'Removed' },
  updated: {
    color: '#bee0f7',
    Icon: EditOutlined,
    valueColor: '#d9f7be',
    label: 'Updated',
  },
}

/**
 * @param {{ orderFeeChanges?: any[] }} props
 */
export const FeeHistory = ({ orderFeeChanges }) => {
  return (
    <div className="fee-timeline-container">
      <Timeline mode="left" pending={false}>
        {orderFeeChanges?.map((item) => {
          return (
            <Timeline.Item
              key={item.created}
              label={
                <div className="fee-timeline__item-label">
                  {utcToLocal(item.created).format('lll')}
                </div>
              }
            >
              <Collapse ghost>
                <Panel
                  showArrow={false}
                  header={
                    <>
                      <Row>
                        <Col span={6}>{item.user_name}</Col>
                        <Col span={18}>
                          {ChangeStatusIcon({
                            oldAmount: item.total_fee_before,
                            newAmount: item.total_fee_after,
                          })}
                          {`  $${item.total_fee_before.toFixed(2)}  →  $${item.total_fee_after.toFixed(2)}`}
                        </Col>
                      </Row>
                    </>
                  }
                >
                  <FeeDiffContentFeeChanges feeLog={item} />
                </Panel>
              </Collapse>
            </Timeline.Item>
          )
        })}
      </Timeline>
    </div>
  )
}

const FeeDiffContentFeeChanges = ({ feeLog }) => {
  // Adds status to each item in fee_changes array
  const feeChanges = useMemo(
    () => buildFeeChangesWithStatus(feeLog.fee_changes),
    [feeLog]
  )

  return (
    <>
      <Divider>Changes</Divider>
      <List
        size="small"
        dataSource={feeChanges}
        renderItem={(itemData) => {
          const { color, Icon, label } = iconByStatus[itemData.status] || {}
          const isUpdated = itemData.status === 'updated'
          const UpDownIcon =
            isUpdated &&
            ChangeStatusIcon({
              newAmount: itemData.new_amount,
              oldAmount: itemData.old_amount,
            })

          return (
            <List.Item>
              <div className="fee-timeline__changes-list-item">
                <span title={label}>
                  {Icon && (
                    <Icon
                      className="fee-timeline__state-icon"
                      style={{ background: color }}
                    />
                  )}
                </span>
                <span>{itemData.description}</span>
                <span>{UpDownIcon}</span>
                <span>
                  {isUpdated
                    ? `$${itemData.old_amount.toFixed(2)} → $${itemData.new_amount.toFixed(2)}`
                    : `$${itemData.amount.toFixed(2)}`}
                </span>
              </div>
            </List.Item>
          )
        }}
      />
    </>
  )
}

/**
 * @param {{ oldAmount: number, newAmount: number }} props
 */
const ChangeStatusIcon = ({ oldAmount, newAmount }) => {
  if (newAmount === oldAmount) {
    return <SmallDashOutlined className="fee-timeline__dash_outlined"/>
  }

  return newAmount > oldAmount ? (
    <ArrowUpOutlined className="fee-timeline__up-indicator" />
  ) : (
    <ArrowDownOutlined className="fee-timeline__down-indicator" />
  )
}

const buildFeeChangesWithStatus = (fee_changes) => {
  const addStatus = (status) => (item) => ({ ...item, status })

  return fee_changes.reduce((acc, feeChange) => {
    const { added, removed, updated } = feeChange
    const feeChangesWithStatus = [
      ...added.map(addStatus('added')),
      ...updated.map(addStatus('updated')),
      ...removed.map(addStatus('removed')),
    ]
    return acc.concat(feeChangesWithStatus)
  }, [])
}
