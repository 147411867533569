import React from 'react'
import { StarFilled, StarOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { Button } from 'reactstrap'
import ConsumerInfoModal from './ConsumerInfoModal'
import EditConsumerModal from '../loans/EditConsumerModal'
import ModalTrigger from '../../common/modals/ModalTrigger'
import UserCardContainer from '../../common/widgets/UserCardContainer'
import {
  checkPermission,
} from '../../common/utils/helpers'
import { useApiRequest } from '../../common/hooks'
import { DELIVERY_TIMESTAMP_FIELDS } from '../../common/constants/consumer'

const ConsumerContact = (props) => {
  const {
    hideFeatures,
    loanData,
    orderData,
    loan,
    consumer,
    address,
    appraisal_delivery_status_fields_toggles_settings,
    setLoader,
    refreshOrder,
    current_veteran,
    current_primary_obligor,
  } = props

  const apiRequest = useApiRequest()

  const handleNewPrimaryContactSelected = async (oldConsumer) => {
    await apiRequest({
      endpoint: `loans/${loanData.id}/consumers`,
      method: 'PUT',
      body: {
        consumer: {
          ...oldConsumer,
          is_primary_contact: true,
        },
      },
      successMessage: `Your ${orderData ? 'order' : 'loan'} has been updated.`,
    })

    refreshOrder()
  }

  // determine the delivery timestamps for each consumer
  const deliveryTimestampsByConsumer = {}
  let maximumDeliveryVersion = 0
  if (loanData && loanData.consumers && orderData) {
    // we have a loan, consumers, and an order, so we might have delivery timestamps
    Object.values(DELIVERY_TIMESTAMP_FIELDS).forEach((fieldName) => {
      // each field appears on order and contains a list of ConsumerSentReceipts
      // organize by consumer and then by submission version number
      if (fieldName !== 'sent_by' && orderData[fieldName]) {
        orderData[fieldName].forEach((sentReceipt) => {
          const { consumer_id, submission_version, time } = sentReceipt
          // first keep track of the highest version number
          const submissionVersion = submission_version || 0
          if (submissionVersion > maximumDeliveryVersion) {
            maximumDeliveryVersion = submissionVersion
          }

          // now organize this timestamp into deliveryTimestampsByConsumer
          if (!deliveryTimestampsByConsumer[consumer_id]) {
            // first timestamp for this consumer, add them to deliveryTimestampsByConsumer
            deliveryTimestampsByConsumer[consumer_id] = {}
          }
          if (!deliveryTimestampsByConsumer[consumer_id][submissionVersion]) {
            // add object for deliveries from this version number
            deliveryTimestampsByConsumer[consumer_id][submissionVersion] = {}
          }
          // const foundTimestamp = deliveryTimestampsByConsumer[consumer_id][submissionVersion][fieldName]
          // const shouldUpdateTimestamp = !foundTimestamp || (time > foundTimestamp)
          // if(shouldUpdateTimestamp) {
          // use this timestamp if it is the most recent one seen thus far
          deliveryTimestampsByConsumer[consumer_id][submissionVersion][
            fieldName
          ] = time

          if (fieldName === DELIVERY_TIMESTAMP_FIELDS.CONSENT_FIELD) {
            deliveryTimestampsByConsumer[consumer_id][
              submissionVersion
            ].accepted_consent_ip_address = sentReceipt.ip_address
          }
          if (fieldName === DELIVERY_TIMESTAMP_FIELDS.DENIED_FIELD) {
            deliveryTimestampsByConsumer[consumer_id][
              submissionVersion
            ].denied_consent_ip_address = sentReceipt.ip_address
          }
          // }
          if (fieldName === DELIVERY_TIMESTAMP_FIELDS.MAILED_FIELD) {
            deliveryTimestampsByConsumer[consumer_id][
              submissionVersion
            ].mailed_by = sentReceipt.mailed_by
          }

          if ([DELIVERY_TIMESTAMP_FIELDS.SENT_FIELD, DELIVERY_TIMESTAMP_FIELDS.MAILED_FIELD].includes(fieldName) &&
            'branch_name' in sentReceipt) {
            deliveryTimestampsByConsumer[consumer_id][
              submissionVersion
            ].branch_name = sentReceipt.branch_name
          }
        })
      }
    })
  }

  const badges = []

  let primaryContactStatus = checkPermission('consumer_edit') ? (
    <Tooltip placement="top" title="Designate as Primary Contact">
      <StarOutlined
        className='contact-star-icon'
        onClick={() => handleNewPrimaryContactSelected(consumer)}
        data-testid="primaryContactIcon"
      />
    </Tooltip>
  ) : null

  if (consumer.is_primary_contact) {
    primaryContactStatus = (
      <Tooltip placement="top" title="Primary Contact">
        <StarFilled className='contact-star-icon is-primary' />
      </Tooltip>
    )
  }

  if (consumer.is_veteran) {
    badges.push('Veteran')
  }
  if (consumer.is_primary_obligor) {
    badges.push('Is Primary Obligor')
  }
  if (consumer.last_4_ssn) {
    badges.push('SSN Uploaded')
  }

  const consumerName = consumer.full_name
    ? consumer.full_name
    : `${consumer.firstname || ''} ${consumer.lastname || ''}`

  const wasSubmissionSentToConsumer = !!deliveryTimestampsByConsumer[
    consumer.id
  ]

  const renderEditButton = () => {
    if (!hideFeatures) {
      return (
        checkPermission('consumer_edit') && (
          <ModalTrigger
            component={EditConsumerModal}
            modalClassName="modal-primary"
            consumer={consumer}
            loan_id={loanData.id}
            setLoader={setLoader}
            refetch={loan ? loan.fetch : refreshOrder}
            is_va_consumer={loanData.is_va_loan}
            // If there is already either of these, then we cant have multiple on the loan so disable the boxes
            disable_select_veteran={
              current_veteran && current_veteran !== consumer.id
            }
            disable_select_primary_obligor={
              current_primary_obligor &&
              current_primary_obligor !== consumer.id
            }
          >
            <a href="#">Edit</a>
          </ModalTrigger>
        )
      )
    }
  }

  return (
    <UserCardContainer
      position={
        consumer.role
          ? consumer.role[0].toUpperCase() +
            consumer.role.slice(1)
          : ''
      }
      name={consumerName}
      homePhone={consumer.home_phone}
      workPhone={consumer.work_phone}
      email={consumer.email}
      cellPhone={consumer.cell_phone}
      showDeliveryStatusButton={wasSubmissionSentToConsumer}
      badges={badges}
      primaryContactStatus={primaryContactStatus}
      checkConsumer={
        <ModalTrigger
          component={ConsumerInfoModal}
          modalClassName="modal-primary modal-l"
          consumerName={consumerName}
          consumerID={consumer.id}
          orderID={orderData ? orderData.id : null}
          loanNumber={loanData.number}
          address={address}
          consumerDeliveryTimestamps={
            deliveryTimestampsByConsumer[consumer.id]
          }
          maximumDeliveryVersion={maximumDeliveryVersion}
          appraisal_delivery_status_fields_toggles_settings={
            appraisal_delivery_status_fields_toggles_settings
          }
        >
          <Button color={'secondary'} size="sm">
            Delivery Status
          </Button>
        </ModalTrigger>
      }
      userButton={renderEditButton()}
    />
  )
}

export default ConsumerContact
