import React from 'react'
import {
  Button,
  Dropdown,
  Menu,
} from 'antd'

import { DownOutlined } from '@ant-design/icons'

export default function SubmissionDownloadActions(props) {
  const {
    canDownloadPdf,
    canDownloadXml,
    canDownloadInvoice,
    canDownloadAmcInvoice,
    onDownloadPdf,
    onDownloadXml,
    onDownloadInvoice,
    onDownloadAmcInvoice,
  } = props

  const handleMenuClick = (item) => {
    switch (item.key) {
      case 'pdf':
        onDownloadPdf()
        break
      case 'xml':
        onDownloadXml()
        break
      case 'invoice':
        onDownloadInvoice()
        break
      case 'amc-invoice':
        onDownloadAmcInvoice()
        break
      case 'all':
        (() => {
          onDownloadPdf()
          onDownloadXml()
          if (canDownloadAmcInvoice) {
            onDownloadAmcInvoice()
          } else if (canDownloadInvoice) {
            onDownloadInvoice()
          }
        })()
    }
  }

  const canDownloadAll = () => {
    return (canDownloadXml + canDownloadInvoice + canDownloadPdf + canDownloadAmcInvoice) > 1
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      { canDownloadXml &&
        <Menu.Item key='xml'>
          XML
        </Menu.Item>
      }
      { canDownloadPdf &&
        <Menu.Item key='pdf'>
          PDF
        </Menu.Item>
      }
      { canDownloadInvoice &&
        <Menu.Item key='invoice'>
          Invoice
        </Menu.Item>
      }
      {
        canDownloadAmcInvoice &&
        <Menu.Item key='amc-invoice'>
          Invoice
        </Menu.Item>
      }
      { canDownloadAll() &&
        <Menu.Item key='all'>
          Download All
        </Menu.Item>
      }
    </Menu>
  )

  return (
    <>
      <Dropdown
        overlay={menu}
        placement={'bottomRight'}
      >
        <Button className='mr-2'>
          Download Submission
          <DownOutlined />
        </Button>
      </Dropdown>
    </>
  )
}
