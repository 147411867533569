import React from 'react'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
} from 'reactstrap'
import { Link } from 'react-router-dom'


function HeaderDropdown(props) {
  return (
    <Dropdown nav isOpen={props.dropdownOpen} toggle={props.toggle}>
      { (props?.user?.data?.email) ? <DropdownToggle nav user={props.user.data.email}>
        <div className="d-flex flex-row align-items-center">
          <i className="icon-user"/>
          <p className="mb-0 mr-0 ml-2">{props.user.data.email}</p>
        </div>
      </DropdownToggle> : ''
      }

      <DropdownMenu right>
        <DropdownItem header tag="div" className="text-center"><strong>Settings</strong></DropdownItem>
        <Link className='dropdown-link' to={'/profile/main'}>
          <DropdownItem><i className="fa fa-user"/>
            Profile info
          </DropdownItem>
        </Link>
        {props.isShowSwitchAccountItem && <Link className='dropdown-link' to="#">
          <DropdownItem onClick={props.showSwitchAccountModal}>
            <i className="fa fa-exchange"/>Switch Account
          </DropdownItem>
        </Link>}
        <Link className='dropdown-link' to={'/profile/password'}>
          <DropdownItem><i className="fa fa-lock"/>Password</DropdownItem>
        </Link>
        <DropdownItem onClick={props.logout}><i className="fa fa-window-close"/>
          Logout
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default HeaderDropdown
