import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Input, InputNumber, Button, Form, DatePicker } from 'antd'
import { REQUEST_METHOD } from '@app/api'
import { useApiRequest } from '../../../common/hooks'
import { selectAllOrderData, selectOffer } from '../../../store/reducers/order/orderSelectors'
import { ACCEPTANCE_METHODS, REQUEST_METHODS } from '@app/common/constants/orders'

const labelCol = { span: 24 }

const EditOfferModal = ({
  toggle,
  onHide,
  refreshOrder,
  offerId,
  assignmentListRequestId,
}) => {
  const apiRequest = useApiRequest()

  const orderData = useSelector(selectAllOrderData)
  const offer = useSelector(state => selectOffer(state, assignmentListRequestId, offerId))
  const {
    reggora_amc_management_fee_dollars: originalAmcFee,
    total_appraiser_fee: originalAppraiserFee,
    due_date: originalDueDate,
    lender_due_date: lenderDueDate,
    id: orderId,
    appraiser_acceptance_method: appraiserAcceptanceMethod,
    order_request_method: orderRequestMethod,
  } = orderData
  const originalTotalFee = originalAppraiserFee + originalAmcFee
  const { fee: newAppraiserFee, due_date: dueDate, reason } = offer
  const parsedFee = parseFloat(newAppraiserFee)
  const escalatedTotalFee = parsedFee + originalAmcFee
  const bidOrder = orderRequestMethod === REQUEST_METHODS.BROADCAST &&
    appraiserAcceptanceMethod === ACCEPTANCE_METHODS.BID

  const [newAmcFee, setAmcFee] = useState(originalAmcFee)
  const [newTotalFee, setNewTotalFee] = useState(escalatedTotalFee)
  const [newAppraiserDueDate, setNewAppraiserDueDate] = useState(dueDate)
  const [newLenderDueDate, setNewLenderDueDate] = useState(lenderDueDate)
  const [error, setError] = useState(null)
  const [processing, setProcessing] = useState(false)
  const [ampNotes, setAmpNotes] = useState('')

  const newAppraiserDueDateObject = moment(newAppraiserDueDate)
  const originalLenderDueDateObject = moment(lenderDueDate)
  const newLenderDueDateObject = moment(newLenderDueDate)
  const lenderDueDateDiff = newLenderDueDateObject.diff(originalLenderDueDateObject, 'days')
  const appraiserLenderDueDateDiff = newLenderDueDateObject.diff(newAppraiserDueDateObject, 'days')
  // Always require client/lender approval for bid orders since they can set auto-accept conditions
  const needsClientLenderApproval = bidOrder || (newTotalFee > originalTotalFee || lenderDueDateDiff !== 0)

  const updateFees = (newAmcFeeInput) => {
    const newTotalFee = parsedFee + parseFloat(newAmcFeeInput || 0)
    setAmcFee(newAmcFeeInput)
    setNewTotalFee(newTotalFee)
  }

  const submit = () => {
    setProcessing(true)
    let errorMessage = ''
    if (!bidOrder && (newTotalFee < originalTotalFee)) {
      errorMessage = 'New fee cannot be less than original fee'
    } else if (appraiserLenderDueDateDiff < 0) {
      errorMessage = 'New appraiser due date cannot be after lender due date'
    } else {
      errorMessage = ''
    }

    setError(errorMessage)
    if (errorMessage) {
      setProcessing(false)
      return
    }

    offerSubmit()
  }

  const offerSubmit = async () => {
    const args = {
      order_id: orderId,
      offer_id: offerId,
      assignment_list_request_id: assignmentListRequestId,
      original_reggora_amc_management_fee: originalAmcFee,
      new_reggora_amc_management_fee: newAmcFee,
      new_appraiser_due_date: newAppraiserDueDateObject.format('YYYY-MM-DD'),
      new_lender_due_date: newLenderDueDateObject.format('YYYY-MM-DD'),
      amp_notes: ampNotes,
    }

    const baseUrl = `offers/${offerId}`
    const url = needsClientLenderApproval ? `${baseUrl}/forward` : `${baseUrl}/accept`
    await apiRequest({
      endpoint: url,
      method: REQUEST_METHOD.POST,
      body: args,
      successMessage: 'Offer successfully updated.',
    })
    refreshOrder()
  }

  return (
    <>
      <ModalHeader toggle={toggle}>Edit Fee Escalation / Due Date Request</ModalHeader>
      <ModalBody>
        <Form className='row edit-fee-form'>
          <div className='col-6'>
            <Form.Item label='Original Appraiser Fee' labelCol={labelCol}>
              <InputNumber
                precision={2}
                step={0.01}
                value={originalAppraiserFee}
                disabled
              />
            </Form.Item>
            <Form.Item label='Escalated Appraiser Fee' labelCol={labelCol}>
              <InputNumber
                precision={2}
                step={0.01}
                value={newAppraiserFee}
                disabled
              />
            </Form.Item>
            <Form.Item label='AMC Fee' labelCol={labelCol}>
              <InputNumber
                precision={2}
                controls={true}
                step={0.01}
                value={newAmcFee}
                onChange={value => updateFees(value)}
              />
            </Form.Item>
            <Form.Item label='Total Fee' labelCol={labelCol}>
              <InputNumber
                precision={2}
                step={0.01}
                value={newTotalFee}
                disabled
              />
            </Form.Item>
            <Form.Item label='Original Total Fee' labelCol={labelCol}>
              <InputNumber
                precision={2}
                step={0.01}
                value={originalTotalFee}
                disabled
              />
            </Form.Item>
          </div>
          <div className='col-6'>
            <Form.Item label='Original Appraiser Due Date' labelCol={labelCol}>
              <DatePicker defaultValue={moment(originalDueDate)} disabled />
            </Form.Item>
            <Form.Item label='Original Lender Due Date' labelCol={labelCol}>
              <DatePicker defaultValue={moment(lenderDueDate)} disabled />
            </Form.Item>
            <Form.Item label='New Appraiser Due Date' labelCol={labelCol}>
              <DatePicker
                defaultValue={moment(newAppraiserDueDate)}
                onChange={(_, dateString) => setNewAppraiserDueDate(dateString)}
              />
            </Form.Item>
            <Form.Item label='New Lender Due Date' labelCol={labelCol}>
              <DatePicker
                defaultValue={moment(newLenderDueDate)}
                onChange={(_, dateString) => setNewLenderDueDate(dateString)}
              />
            </Form.Item>
            <Form.Item label='Reason' labelCol={labelCol}>
              <Input.TextArea className='reason-input' value={reason} disabled />
            </Form.Item>
            <Form.Item label='AMP Notes' labelCol={labelCol}>
              <Input.TextArea
                className='reason-input'
                type='textarea'
                value={ampNotes}
                onChange={({ target: { value } }) => setAmpNotes(value)}
              />
            </Form.Item>
          </div>
          {error && (
            <div className="col-12">
              <div style={{ color: 'red' }}>
                {error}
              </div>
            </div>
          )}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={submit} type='primary' disabled={processing}>
          {needsClientLenderApproval ? 'Request Lender' : 'Accept'}
        </Button>
        <Button onClick={onHide}>Cancel</Button>
      </ModalFooter>
    </>
  )
}

export default EditOfferModal
