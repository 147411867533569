import React, { Component, Fragment } from 'react'
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
} from 'reactstrap'
import { InputNumber } from 'antd'
import { compose } from 'redux'
import { connectResource } from '../../common/utils/resource'
import { connect } from 'react-redux'
import { CURRENCY_FORMATTER, CURRENCY_PARSER } from '../../common/utils/fees'


class EditAMCManagementFeeModal extends Component {
    state = {
      totalFee: this.props.totalFee,
      amcManagementFee: this.props.amcManagementFee,
      isUpdatingFee: false,
    }

    getAppraiserFeeToDisplay = () => {
      const { totalFee, amcManagementFee } = this.state

      return totalFee - amcManagementFee
    }

    onChange = (value) => {
      this.setState({ amcManagementFee: value })
    }

    onBlur = () => this.setState((state) => {
      const { amcManagementFee } = state
      return {
        amcManagementFee: (
          amcManagementFee && amcManagementFee > 0 ? parseFloat(amcManagementFee) : 0
        ).toFixed(2),
      }
    })

    onSubmit = () => {
      this.setState({ isUpdatingFee: true })
      this.props.amcManagementFeeApi
        .put({ amc_management_fee: this.state.amcManagementFee * 100 })
        .then(() => this.props.refreshOrder())
        .finally(() => this.setState({ isUpdatingFee: false }))
    }

    render() {
      return <Fragment>
        <ModalHeader>Edit AMC Management Fee</ModalHeader>
        <ModalBody>
          <Row className='mt-1 mb-2'>
            <Col sm={4} className='mb-3 pr-0'>
              <b>Appraiser Fee</b>
            </Col>
            <Col sm={8} className=''>
              <InputNumber
                data-testid='appraiserFeeInput'
                value={this.getAppraiserFeeToDisplay()}
                readOnly={true}
                bordered={false}
                formatter={CURRENCY_FORMATTER}
                parser={CURRENCY_PARSER}
              />
            </Col>
          </Row>
          <Row className='mb-1'>
            <Col sm={4}>
              <b>AMC Fee</b>
            </Col>
            <Col sm={8}>
              <InputNumber
                data-testid='amcManagementFeeInput'
                value={this.state.amcManagementFee}
                onChange={(value) => this.onChange(value)}
                disabled={this.state.isUpdatingFee}
                formatter={CURRENCY_FORMATTER}
                parser={CURRENCY_PARSER}
                style={{ width: 100 }}
                min={0}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={this.props.onHide}>Cancel</Button>
          <Button color='primary' onClick={this.onSubmit} disabled={this.state.isUpdatingFee}>Save</Button>
        </ModalFooter>
      </Fragment>
    }
}


export default compose(
  connect((_state, props) => ({ orderId: props.orderId })),
  connectResource({
    namespace: 'amcManagementFeeApi',
    endpoint: 'fee/order/:orderId/amc-management-fee',
    prefetch: false,
    apiVersion: 2,
    successMessage: { PUT: 'Your AMC management fee has been saved.' },
  }),
)(EditAMCManagementFeeModal)
