import React, { Component } from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'

const propTypes = {
  inputClassName: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  required: PropTypes.bool,
  isDisabled: PropTypes.bool,
  clearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
}
const defaultProps = {
  inputClassName: 'select-custom',
  clearable: false,
  isMulti: false,
  isSearchable: false,
}

export default class SelectInput extends Component {
  handleChange = (selectedOption) => {
    this.props.onChange(selectedOption)
  }

  render() {
    const {
      inputClassName,
      placeholder,
      name,
      options,
      required,
      value,
      isDisabled,
      clearable,
      isMulti,
      isSearchable,
      valueKey,
      labelKey,
      multi,
      size,
      optionComponent,
      optionRenderer,
      valueComponent,
      removeSelected,
    } = this.props
    return (
      <Select
        name={name}
        className={inputClassName}
        placeholder={placeholder}
        value={value}
        onChange={this.handleChange}
        options={options}
        required={required}
        isDisabled={isDisabled}
        clearable={clearable}
        isMulti={isMulti}
        isSearchable={isSearchable}
        valueKey={valueKey}
        labelKey={labelKey}
        multi={multi}
        size={size}
        optionComponent={optionComponent}
        optionRenderer={optionRenderer}
        valueComponent={valueComponent}
        removeSelected={removeSelected}
      />
    )
  }
}

SelectInput.propTypes = propTypes
SelectInput.defaultProps = defaultProps
