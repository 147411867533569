import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Form,
  Button,
  Upload,
  Alert,
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { getInspectionDetail, getInspectionDetailValue } from '../../../../helpers/inspections'
import { getBearerToken } from '../../../../helpers/auth'
import { THIRD_PARTY } from './constants'

import {
  createInspectionDetails,
  updateInspectionDetail,
} from '../../../store/reducers/inspection/inspectionSlice'

import {
  selectInspectionDetailsDataMap,
  selectIsCreateInspectionDetailsLoading,
  selectIsUpdateInspectionDetailLoading,
} from '../../../store/reducers/inspection/inspectionSelectors'
import Loader from '../../../common/layout/components/Loader'

const FloorplanUploadForm = ({ inspectionId, orderId }) => {
  const dispatch = useDispatch()
  const [floorplanPdfUrl, setFloorplanPdfUrl] = useState('')
  const [fileList, setFileList] = useState([])
  const [isUploading, setIsUploading] = useState(false)
  const [uploadError, setUploadError] = useState('')

  const floorplanQuestionLocation = 'site.0.site-general.0.canvas_floor_plan_pdf.0'

  const inspectionDetailsDataMap = useSelector(selectInspectionDetailsDataMap)
  const existingFloorplanDetail = getInspectionDetail(inspectionDetailsDataMap, floorplanQuestionLocation)

  const isCreateInspectionDetailsLoading = useSelector(selectIsCreateInspectionDetailsLoading)
  const isUpdateInspectionDetailLoading = useSelector(selectIsUpdateInspectionDetailLoading)

  useEffect(() => {
    if (existingFloorplanDetail) {
      setFloorplanPdfUrl(getInspectionDetailValue(inspectionDetailsDataMap, floorplanQuestionLocation))
    }
  }, [
    existingFloorplanDetail,
    inspectionDetailsDataMap,
    floorplanQuestionLocation,
  ])

  const handleSave = () => {
    setIsUploading(true)
    const file = fileList[0]
    const formData = new FormData()
    formData.append('file', new File([file], `${orderId}/${Date.now()}.pdf`, {
      type: file.type,
    }))

    fetch(`${SERVICE_URL}inspections/inspection_images/v1`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `bearer ${getBearerToken()}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to upload PDF')
        }
        return res.json()
      })
      .then((data) => {
        const s3Url = data.url
        if (existingFloorplanDetail) {
          dispatch(updateInspectionDetail({
            inspectionId,
            inspectionDetailId: existingFloorplanDetail._id,
            detail: {
              ...existingFloorplanDetail,
              values: [s3Url],
            },
          }))
        } else {
          dispatch(createInspectionDetails({
            inspectionId,
            details: [
              {
                question_location: floorplanQuestionLocation,
                inspection_id: inspectionId,
                values: [s3Url],
                metadata: {
                  source: THIRD_PARTY,
                },
              },
            ],
          }))
        }
        setFileList([])
      })
      .catch((error) => {
        setUploadError(`${error}`)
      })
      .finally(() => {
        setIsUploading(false)
      })
  }
  const uploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)
      setFileList(newFileList)
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file])
      return false
    },
    fileList,
    multiple: false,
  }

  return (
    <div>
      {isUploading || isCreateInspectionDetailsLoading || isUpdateInspectionDetailLoading ? <Loader /> : null}
      {uploadError ? <Alert type="error" message={uploadError} /> : null}
      <p>Upload Floorplan PDF</p>
      <Form layout="inline">
        <Form.Item extra={floorplanPdfUrl}>
          <Upload {...uploadProps} >
            <Button icon={<UploadOutlined />}>
              Select file
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={handleSave} disabled={fileList.length === 0}>
            Upload
          </Button>
        </Form.Item>
      </Form>
      <hr />
    </div>
  )
}

export default FloorplanUploadForm
