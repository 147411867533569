import React, { Fragment } from 'react'

import HeaderContainer from './components/Header/HeaderContainer'
import Footer from './components/Footer/Footer'
import Sidebar from './components/Sidebar/Sidebar'


export default function BasicLayout(props) {
  return (
    <div id="app" className={props.getClass()}>
      <HeaderContainer
        toggleSidebar={props.toggleSidebar}
        toggleSidebarMobile={props.toggleSidebarMobile}
        user={props.user}
        toggleMinimizeSidebar={props.toggleMinimizeSidebar}
      />
      <div className="app-body">
        <Sidebar {...props} toggleMinimizeSidebar={props.toggleMinimizeSidebar}/>
        <Fragment>
          {props.children}
        </Fragment>
      </div>
      <Footer/>
    </div>
  )
}
