import { of } from 'rxjs/observable/of'
import { fromPromise } from 'rxjs/observable/fromPromise'
import {
  CREATE_CSV_APPRAISERS
} from '../../pages/appraisers/actions'
import {
  setData
} from '../../common/utils/resource'

export default function createCsvAppraisers(action$, store, { API }) {
  return action$.ofType(CREATE_CSV_APPRAISERS).mergeMap(action => {
    return fromPromise(API(`appraiser/upload`, 2).request('POST', undefined, { data: action.data })).switchMap(_ => {
      return fromPromise(API('appraiser/', 2).request('GET')).switchMap(res => of(setData(res['results'], { resource: { namespace: 'appraisers' } })))
    })
  })
};
