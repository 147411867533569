import { useRef, useEffect, useMemo } from 'react'
import { debounce } from 'lodash'

const DEFAULT_DEBOUNCE_MS = 1000

export const useDebounce = (callback, delay) => {
  const ref = useRef({ callback, delay })

  useEffect(() => {
    ref.current = { callback, delay }
  }, [callback, delay])

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.callback?.()
    }

    return debounce(func, delay ?? DEFAULT_DEBOUNCE_MS)
  }, [delay])

  return debouncedCallback
}
