import React, {
  useContext,
  useState,
} from 'react'
import Pagination from 'react-js-pagination'
import { Pagination as PaginationStrap } from 'reactstrap'
import {
  Button,
  Dropdown,
  Menu,
} from 'antd'
import { DownOutlined } from '@ant-design/icons'
import analytics from '@helpers/segment'
import { TrackingContext } from 'app/pages/orders/TrackingContext'

export default function PaginationTest(props) {
  let currentPage = ''
  let currentPageNumber = 0
  let state = {}

  let initialLimit = 10
  const limitFromURL = props.items?.filters?.limit
  if (props.canChangeLimit && limitFromURL) {
    initialLimit = Number(limitFromURL)
  }
  const [limit, setLimit] = useState(initialLimit)

  if (typeof props.pageName !== 'undefined') {
    currentPage = props.pageName
    if (currentPage === 'orders') {
      // tracking state for orders page
      // eslint-disable-next-line react-hooks/rules-of-hooks
      state = useContext(TrackingContext)
    }
  }

  const handleOpenPage = (nextPageNumber) => {
    if (currentPage === 'orders') {
      if (state.trackState.searched) {
        state.setTrackState({
          searched: state.trackState.searched,
          searchesUntilOrderClick: state.trackState.searchesUntilOrderClick,
          pageClicksUntilOrderClick: state.trackState.pageClicksUntilOrderClick + 1,
        })
      }
      analytics.trackComponent('Orders Next Page Clicked', {
        current_page_number: currentPageNumber,
        destination_page_number: nextPageNumber,
      })
    }

    currentPageNumber = nextPageNumber
    let last_id
    const offset = (nextPageNumber - 1) * limit
    const activePage = Math.ceil(offset / limit) + 1
    if (props.last_id && activePage > 1) {
      const { items } = props
      if (props.lastIdPerPage[activePage]) {
        last_id = props.lastIdPerPage[activePage]
      } else {
        last_id = items.data[items.data.length - 1].id
        props.lastIdPerPage[activePage] = last_id
      }
    }

    if (props.canChangeLimit) {
      props.items.filter({ offset, last_id, limit })
    } else {
      props.items.filter({ offset, last_id })
    }
  }

  const { count, filters } = props.items
  const offset = filters.offset || 0
  const activePage = Math.ceil(offset / limit) + 1
  currentPageNumber = activePage

  const paginationClasses = props.canChangeLimit ? 'm-0' : ''

  const basicPaginationUI = (
    <PaginationStrap className={paginationClasses}>
      <Pagination
        activePage={activePage}
        itemsCountPerPage={ limit }
        totalItemsCount={count}
        pageRangeDisplayed={5}
        onChange={handleOpenPage}
        nextPageText={'Next'}
        prevPageText={'Prev'}
      />
    </PaginationStrap>
  )

  let paginationUI = basicPaginationUI

  if (props.canChangeLimit) {
    // render UI with dropdown to select items per page
    const handleLimitSelect = (selectedOption) => {
      const newLimit = Number(selectedOption.key)
      setLimit(newLimit)
      const previousLimit = Number(limit)
      if (newLimit !== previousLimit) {
        // new limit selected, refetch items, force back to page 1
        props.items.filter({
          limit: newLimit,
          offset: 0,
        })
        if (props.limitSelectedHandler) {
          props.limitSelectedHandler(newLimit)
        }
      }
    }

    const menu = (
      <Menu onClick={handleLimitSelect}>
        {[10, 50, 100].map(i => (
          <Menu.Item key={i}>{i}</Menu.Item>
        ))}
      </Menu>
    )

    paginationUI = (
      <div className='d-flex justify-content-between align-items-center p-2 mt-3'>
        {basicPaginationUI}
        <div className='flex-1' />
        <div className='flex-1 d-flex justify-content-end align-items-center'>
          <Dropdown
            overlay={menu}
            className='pagination-limit-dropdown mr-3'
          >
            <Button aria-label='Change Items Per Page'>{limit} <DownOutlined /></Button>
          </Dropdown>
          <div>{props.limitSelectLabel || 'Items Per Page'}</div>
        </div>
      </div>
    )
  }

  return paginationUI
}
