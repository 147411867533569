import React, { Fragment } from 'react'

export default function ApproveSubmissionModal(props) {
  const { title, description } = props
  return (
    <Fragment>
      <div className="approve-submission-title">
        {title}
      </div>
      <div className="approve-submission-description">
        {description}
      </div>
    </Fragment>
  )
}
