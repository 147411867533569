import { ModalHeader, ModalBody, FormGroup, Col, ModalFooter, Button, Row } from 'reactstrap'
import React, { Fragment } from 'react'
import { Form } from 'react-final-form'
import { states, appraisal_type, checkPermission } from '../../common/utils/helpers'
import Loader from '../../common/layout/components/Loader'
import Select from 'react-select'
import { Input, Tooltip, Checkbox } from 'antd'
import RadiosInput from '../../common/forms/inputs/RadiosInput'
import DatePicker from 'react-datepicker'
import { paymentOptionsToReadable } from '../../common/constants/borrowerPaymentOptions'
import CountySelectInput from './CountySelectInput'
import { useFlags } from 'launchdarkly-react-client-sdk'
import Rephrase from '../../common/utils/Rephrase'

export default function ModalCreateLoanFile(props) {
  const title = props.loanToEdit ? `Edit Loan File #${props.loanToEdit.number || ''} : ${props.loanToEdit.subject_property_address || ''}` : 'Create Loan File'
  const submitText = props.loanToEdit ? 'Save' : 'Create'
  const loading = props.loanUpdate.isLoading || props.loanOfficers.isLoading || props.loansCreate.isLoading
  const branchPaymentMethodOptions = props.consumerPaymentOptions.map(paymentOption => {
    return { value: paymentOption, label: paymentOptionsToReadable[paymentOption] }
  })
  const { matchProductToLoanType } = useFlags() || false

  const onChangeAppraisalType = (val) => {
    props.handleSelect('appraisal_type', val ? val.value : null)
  }

  const onChangeLoanOfficer = (val) => {
    props.handleSelect('officer', val ? val.id : val)
  }

  const onChangeSubjectPropertyState = (val) => {
    props.handleSelect('subject_property_state', val ? val.short : val)
  }

  const onChangeDueDate = (value) => {
    props.handleChange({ target: { name: 'due_date', value } })
  }

  const formatLoanOfficers = (user) => {
    return ({ ...user, full_name: user.firstname + ' ' + user.lastname })
  }


  return (
    <Fragment>
      <Form
        onSubmit={props.onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <ModalHeader toggle={props.onHide}>{title}</ModalHeader>
            <ModalBody>
              <Row>
                {loading ? <div className='m-5'><Loader /></div> : <Col xs='12'>
                  <FormGroup row className="mt-2 mb-4">
                    <Col xs="6">
                      <h6>Loan Number:</h6>
                      <Input
                        tabIndex="1"
                        data-testid="loan-num-input"
                        value={props.number}
                        onChange={props.handleChange}
                        name={'number'}
                        placeholder={' Enter Loan Number'}
                        required={true}
                      />
                    </Col>
                    <Col xs="6">
                      <h6><Rephrase sayOrder>Appraisal Type:</Rephrase></h6>
                      <Select
                        tabIndex="2"
                        name='appraisal_type'
                        inputClassName='create-loan-select appraisal-type-input'
                        valueKey='value'
                        labelKey='label'
                        placeholder={<Rephrase sayOrder>Appraisal Type</Rephrase>}
                        value={props.appraisal_type}
                        onChange={onChangeAppraisalType}
                        options={appraisal_type}
                      />
                      {props.appraisal_type === 'other' && <Input className="mt-1" value={props.appraisal_type_other} name='appraisal_type_other' type="text" placeholder={<Rephrase>Enter Appraisal Type</Rephrase>} onChange={props.onAppraisalTypeChange}/>}
                    </Col>
                  </FormGroup>
                  <FormGroup row className='mb-4'>
                    <Col xs='12'>
                      <h6>Loan Officer:</h6>
                      <Select
                        tabIndex="3"
                        name='officer'
                        valueKey='id'
                        inputClassName='create-loan-select'
                        labelKey='full_name'
                        placeholder='Loan Officer'
                        value={props.officer}
                        onChange={onChangeLoanOfficer}
                        options={props.loanOfficers.data ? props.loanOfficers.data.map(formatLoanOfficers) : []}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row className="mb-4">
                    <Col xs="6">
                      <h6>Address:</h6>
                      <Input
                        tabIndex="4"
                        value={props.subject_property_address}
                        onChange={props.handleChange}
                        name={'subject_property_address'}
                        placeholder={'Enter Address'}
                        required={true}
                      />
                    </Col>
                    <Col xs="6">
                      <h6>City:</h6>
                      <Input
                        tabIndex="5"
                        value={props.subject_property_city}
                        onChange={props.handleChange}
                        name={'subject_property_city'}
                        placeholder={'Enter City'}
                        required={true}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row className="mb-4">
                    <Col xs="6">
                      <h6>State:</h6>
                      <Select
                        tabIndex="6"
                        name='subject_property_state'
                        valueKey={'short'}
                        labelKey={'long'}
                        inputClassName='create-loan-select'
                        placeholder={'Please select State'}
                        options={states}
                        onChange={onChangeSubjectPropertyState}
                        value={props.subject_property_state}
                        required={true}
                      />
                    </Col>
                    <Col xs="6">
                      <h6>Zip:</h6>
                      <Input
                        tabIndex="7"
                        value={props.subject_property_zip}
                        onChange={props.handleChange}
                        name={'subject_property_zip'}
                        placeholder={'Enter Zip'}
                        required={true}
                      />
                    </Col>
                  </FormGroup>

                  {props.loanToEdit &&
                    <FormGroup row className="mb-4">
                      <Col xs="6">
                        <h6 className='d-inline'>County: </h6>
                        <Tooltip title="County is used for vendor allocation">
                          <span><i className="fa fa-info-circle d-inline mr-1"></i></span>
                        </Tooltip>
                        <CountySelectInput
                          stateShort={props.subject_property_state}
                          handleSelect={props.handleSelect}
                          selectedCounty={props.selectedCounty}
                        />
                      </Col>
                    </FormGroup>}

                  {(!props.loanToEdit || !props.loanToEdit.has_related_order) && <FormGroup row className="mb-4">
                    <Col xs="12">
                      <h6>Due Date:</h6>
                      <DatePicker
                        tabIndex="8"
                        className='ant-input'
                        placeholderText='Due Date'
                        selected={props.due_date ? props.due_date : null}
                        onChange={onChangeDueDate}
                      />
                    </Col>
                  </FormGroup>}

                  { matchProductToLoanType ? (
                    <FormGroup row className="mb-4">
                      <Col xs="6" id='loan-type-form-component'>
                        <h6>Loan Type:</h6>
                        <div className='loan-type-radios'>
                          <RadiosInput
                            name='loan_type'
                            options={[
                              {
                                label: <span>FHA</span>,
                                value: 'FHA',
                              },
                              {
                                label: <span>USDA</span>,
                                value: 'USDA',
                              },
                              {
                                label: <span>VA</span>,
                                value: 'VA',
                              },
                              {
                                label: <span>Other (Conventional, HELOC, etc.)</span>,
                                value: 'OTHER',
                              },
                            ]}
                            valueKey='value'
                            labelKey='label'
                            value={props.loan_type}
                            inline={false}
                            onChange={(value) => props.handleSelect('loan_type', value)}
                            data-test="loan-type-radios"
                          />
                        </div>
                      </Col>
                      <Col xs="6">
                        <h6>Case Number:</h6>
                        <Input
                          tabIndex="10"
                          value={props.case_number}
                          onChange={props.handleChange}
                          name={'case_number'}
                          placeholder={'Case Number'}
                        />
                      </Col>
                    </FormGroup>
                  ) : (<FormGroup row className="mb-4">
                    <Col xs="6">
                      <h6>Loan Type:</h6>
                      <Input
                        tabIndex="9"
                        value={props.loan_type}
                        onChange={props.handleChange}
                        name='loan_type'
                        placeholder='Loan Type'
                        data-testid='loan_type_textbox'
                      />
                    </Col>
                    <Col xs="6">
                      <h6>Case Number:</h6>
                      <Input
                        tabIndex="10"
                        value={props.case_number}
                        onChange={props.handleChange}
                        name={'case_number'}
                        placeholder={'Case Number'}
                      />
                    </Col>
                  </FormGroup>
                  )}

                  <FormGroup row className="mb-1" >
                    {props.should_show_consumer_payment_style_field && !props.is_va_loan &&
                      <Col xs="6" id='job-form-component'>
                        <h6>Borrower Payment Option:</h6>
                        <div className='inspection-buttons'>
                          <RadiosInput
                            name='consumer_payment_style'
                            disabled={!checkPermission('payments_edit_consumer_payment_style')}
                            options={branchPaymentMethodOptions}
                            valueKey='value'
                            labelKey='label'
                            value={props.consumer_payment_style}
                            inline={false}
                            onChange={(value) => props.handleSelect('consumer_payment_style', value)}
                          />
                        </div>
                      </Col>}
                  </FormGroup>
                  { !matchProductToLoanType ? (<FormGroup row className="mb-4 is-va-checkbox">
                    <Col xs="12">
                      <Checkbox
                        type='checkbox'
                        checked={props.is_va_loan}
                        onChange={props.handleCheck}
                        name={'is_va_loan'}
                      >
                        VA Loan
                      </Checkbox>
                    </Col>
                  </FormGroup>) : null
                  }
                </Col>}
              </Row>
            </ModalBody>
            <ModalFooter className="border-0">
              <Button tabIndex="12" disabled={loading} onClick={props.onHide}>Cancel</Button>
              <Button tabIndex="11" disabled={loading} data-test="submit-button" color='primary'>{submitText}</Button>
            </ModalFooter>
          </form>
        )}
      />
    </Fragment>
  )
}
