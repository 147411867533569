import React, { Component } from 'react'
import { compose } from 'redux'
import { connectResource } from '../../common/utils/resource'
import { checkPermission } from '../../common/utils/helpers'
import { connect } from 'react-redux'
import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import Select from 'react-select'
import {
  Alert as AntAlert,
} from 'antd'
import Rephrase from '../../common/utils/Rephrase'

class ModalUpdateVAOrderStatus extends Component {
  state = {
    newStatusKey: null,
    newStatusValue: null,
  }

  setSelectedStatus = (statusObject) => {
    if (statusObject) {
      this.setState({
        newStatusKey: statusObject.value,
        newStatusValue: statusObject.label,
      })
    }
  }

  onSubmit = () => {
    const {
      onHide,
      refreshOrder,
      orderData,
      updateVAOrderStatus,
    } = this.props

    const body = {
      order_id: orderData.id,
      new_status_key: this.state.newStatusKey,
    }

    // Close the modal first so the user gets immediate feedback
    onHide()

    updateVAOrderStatus.put(body)
      .then(_ => {
        refreshOrder()
      })
      .catch(e => console.error(e))
  }

  isTotalFeeCoveredByPayments = (orderData) => {
    let totalFeeCovered = false
    let totalPaymentsPaid = 0
    if (orderData) {
      const orderFeeInCents = orderData.consumer_fee * 100

      orderData &&
        orderData.schedule &&
        orderData.schedule.payments &&
        orderData.schedule.payments.forEach((payment) => {
          if (payment.paid === true) {
            totalPaymentsPaid += parseFloat(payment.amount)
          }
        })

      if (totalPaymentsPaid >= orderFeeInCents) {
        totalFeeCovered = true
      }
    }
    return totalFeeCovered
  }

  render() {
    const {
      onHide,
      orderData,
    } = this.props

    const {
      newStatusKey,
    } = this.state

    const buildAndFormatStatusOptions = orderData.available_statuses
      .filter(statusObject => statusObject.statusKey !== orderData.statusKey && statusObject.statusKey !== 'submitted')
      .map(statusObject => ({
        value: statusObject.statusKey,
        label: statusObject.statusValue,
      }))

    const getWFPOverrideWarning = (newStatusValue) => {
      if (newStatusValue) {
        const message = `Moving the order to ${newStatusValue} will override Waiting For Payment. The order will be allowed to proceed regardless of if payment has been received.`
        const styling = 'mt-4'

        return (
          <div className={styling}>
            <AntAlert
              message={message}
              banner
            />
          </div>
        )
      }
    }

    let showWFPOverrideMessage = false
    if (orderData.statusKey === 'waiting_for_payment') {
      showWFPOverrideMessage = true
    }

    let submitText = 'Submit'
    const totalFeeCovered = this.isTotalFeeCoveredByPayments(orderData)

    const canOverrideWaitingForPayment = (orderData &&
       orderData.statusKey === 'waiting_for_payment' &&
       !orderData.lender_canceled &&
       checkPermission('order_override_waiting_for_payment') &&
       checkPermission('user_view_order_fee') &&
       !totalFeeCovered)

    const canApprovePendingOrder = (orderData &&
       orderData.statusKey === 'pending_approval' &&
       !orderData.lender_canceled &&
       checkPermission('order_approve_pending') &&
       !this.props.is_amc_lender)

    if (canOverrideWaitingForPayment) {
      submitText = 'Override and Submit'
    } else if (canApprovePendingOrder) {
      submitText = 'Approve and Submit'
    }

    const info_text = `Move the order to your desired status. To move the order into the Submitted status, please upload an appraisal via
    "Manual Appraisal Upload" Order Actions button.`

    return (<div className="updateOrderContainer">
      <ModalHeader>Update Order Status</ModalHeader>
      <ModalBody className='updateOrderStatusModalBody'>
        <p><Rephrase>{info_text}</Rephrase></p>
        <div className='updateOrderStatusContainer'>
          <div className="updateFromStatusContainer">
            <p>From:
              <span>{orderData.status}</span>
            </p>
          </div>
          <div className="updateToStatusContainer">
            <p>To:</p>
            <Select
              className='updateOrderStatusSelect'
              isClearable={true}
              name="availableStatuses"
              onChange={(value) => this.setSelectedStatus(value)}
              options={buildAndFormatStatusOptions}
              placeholder='New Status'
              required
              value={newStatusKey}
            />
          </div>

          {showWFPOverrideMessage && getWFPOverrideWarning(this.state.newStatusValue)}

        </div>
      </ModalBody>
      <ModalFooter className="border-0">
        <Button onClick={onHide}>Cancel</Button>
        <Button
          onClick={this.onSubmit}
          color='primary'
          disabled={newStatusKey === 'submitted'}
        >
          {submitText}
        </Button>
      </ModalFooter>
    </div>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    order_id: props.orderData.id,
    new_status_key: state.newStatusKey,
  }
}

export default compose(
  connect(mapStateToProps, null),
  connectResource({
    namespace: 'updateVAOrderStatus',
    endpoint: 'order/va/update-status',
    apiVersion: 2,
    prefetch: false,
    successMessage: {
      PUT: 'Order status updated successfully.',
    },
  })
)(ModalUpdateVAOrderStatus)
