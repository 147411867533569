import React, { Component, Fragment } from 'react'
import { compose } from 'redux'

import { CONSUMER_ROLES } from '../../common/constants/consumer'

import Select from 'react-select'
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  FormGroup,
  Label,
  Row,
  Col,
  Alert,
} from 'reactstrap'

import { connectResource } from '../../common/utils/resource'

const roleOptions = CONSUMER_ROLES

class EditConsumerModal extends Component {
  state = {
    firstname: '',
    lastname: '',
    email: '',
    role: 'Borrower',
    other_role: '',
    home_phone: '',
    cell_phone: '',
    work_phone: '',
    error: '',
    is_editing_ssn: false,
    ssn: '',
    is_primary_obligor: false,
    is_veteran: false,
    new_ssn: '',
    is_surviving_spouse: false,
    year_of_birth: null,
    disable_submit_btn: false,
  }

  componentWillMount() {
    const { consumer } = this.props
    if (!consumer) {
      return
    }

    let {
      email,
      role,
      home_phone,
      cell_phone,
      work_phone,
      last_4_ssn,
      is_primary_obligor,
      is_veteran,
      is_surviving_spouse,
      year_of_birth,
      is_primary_contact,
    } = consumer

    let firstname = ''
    let lastname = ''
    let other_role = ''
    let ssn = ''
    if (last_4_ssn) {
      ssn = `***-**-${last_4_ssn}`
    } else {
      ssn = 'No SSN supplied'
    }

    if (consumer.full_name) {
      const splitName = consumer.full_name.split(' ')
      firstname = splitName[0]
      if (splitName.length > 1) {
        lastname = splitName.slice(1).join(' ')
      }
    } else {
      if (consumer.firstname) {
        firstname = consumer.firstname
      }
      if (consumer.lastname) {
        lastname = consumer.lastname
      }
    }

    if (!roleOptions.map(({ name }) => name).includes(this.toTitle(role))) {
      other_role = role
      role = 'Other'
    }

    this.setState({
      firstname,
      lastname,
      email,
      role: this.toTitle(role),
      other_role,
      home_phone,
      cell_phone,
      work_phone,
      is_primary_obligor,
      is_veteran,
      ssn,
      is_surviving_spouse,
      year_of_birth,
      is_primary_contact,
    })
  }

  toTitle = (phrase) => {
    if (phrase) {
      return phrase.split(' ').filter(splitWord => splitWord !== '').map(word => `${word[0].toUpperCase()}${word.length > 1 ? word.slice(1) : ''}`).join(' ')
    } else {
      return ''
    }
  }

  onChange = (field, value) => {
    this.setState({
      [field]: value,
    })
  }

  onSubmit = () => {
    this.setState({
      disable_submit_btn: true,
    })
    const {
      loanConsumers,
      consumer: consumerFromProps,
      setLoader,
      refetch,
    } = this.props
    let {
      firstname,
      lastname,
      email,
      role,
      other_role,
      home_phone,
      cell_phone,
      work_phone,
      new_ssn,
      is_veteran,
      is_primary_obligor,
      is_surviving_spouse,
      year_of_birth,
      is_primary_contact,
    } = this.state

    if (!role || (role === 'Other' && !other_role)) {
      let name = firstname
      if (lastname) {
        name += ` ${lastname}`
      }
      this.setState({
        error: `Please supply a role for ${name || 'this contact'}.`,
      })
      return
    } else {
      this.setState({
        error: '',
      })
    }

    if (role === 'Other' && other_role) {
      role = other_role
    }

    const consumer = {
      email,
      role: role.toLowerCase(),
      home_phone,
      cell_phone,
      work_phone,
      full_name: firstname + ' ' + lastname,
      new_ssn,
      is_veteran,
      is_primary_obligor,
      is_surviving_spouse,
      year_of_birth,
      is_primary_contact,
    }

    const adding_new = !consumerFromProps
    setLoader(true)
    if (!adding_new) {
      consumer.id = consumerFromProps.id
      loanConsumers.put({ consumer }).then(_ => {
        setLoader(false)
        refetch()
      })
    } else {
      consumer.is_consumer_created_manually = true
      loanConsumers.post({ consumer }).then(_ => {
        setLoader(false)
        refetch()
      })
    }
  }

  render() {
    const {
      onHide,
      consumer,
      is_va_consumer,
      disable_select_veteran,
      disable_select_primary_obligor,
    } = this.props
    const {
      firstname,
      lastname,
      email,
      role,
      other_role,
      home_phone,
      cell_phone,
      work_phone,
      error,
      is_veteran,
      is_primary_obligor,
      ssn,
      is_editing_ssn,
      new_ssn,
      is_surviving_spouse,
      year_of_birth,
      last_4_ssn,
    } = this.state
    let title = 'Create Contact'
    let submitText = 'Create'
    if (consumer) {
      title = 'Edit Contact'
      submitText = 'Save'
    }

    return (<Fragment>
      <ModalHeader toggle={onHide}>{title}</ModalHeader>
      <ModalBody>
        {error ? <Row><Col xs='12'>
          <Alert color='danger'>
            {error}
          </Alert>
        </Col></Row> : null}
        <Row>
          <Col xs='6'>
            <FormGroup>
              <Label>First Name:</Label>
              <Input
                value={firstname}
                onChange={(e) => this.onChange('firstname', e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label>Role:</Label>
              <Select
                value={role}
                required
                valueKey='name'
                labelKey='name'
                onChange={value => this.onChange('role', value ? value.name : value)}
                options={roleOptions}
              />
              {role === 'Other' && <Input
                className='mt-2'
                value={other_role}
                onChange={(e) => this.onChange('other_role', e.target.value)}
              />}
            </FormGroup>
            <FormGroup>
              <Label>Cell Phone:</Label>
              <Input
                value={cell_phone}
                onChange={(e) => this.onChange('cell_phone', e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label>Home Phone:</Label>
              <Input
                value={home_phone}
                onChange={(e) => this.onChange('home_phone', e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col xs='6'>
            <FormGroup>
              <Label>Last Name:</Label>
              <Input
                value={lastname}
                onChange={(e) => this.onChange('lastname', e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label>Email:</Label>
              <Input
                value={email}
                onChange={e => this.onChange('email', e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label>Work Phone:</Label>
              <Input
                value={work_phone}
                onChange={(e) => this.onChange('work_phone', e.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
        { is_va_consumer &&
          <Row>
            <Col>
              <FormGroup>
                <Label>Is Veteran?:</Label>
                <Input
                  className='ml-0'
                  type="checkbox"
                  checked={is_veteran}
                  onChange={(e) => this.onChange('is_veteran', e.target.checked)}
                  disabled={disable_select_veteran}
                />
              </FormGroup>
              <FormGroup>
                <Label>Is Primary Obligor?:</Label>
                <Input
                  className='ml-0'
                  type="checkbox"
                  checked={is_primary_obligor}
                  onChange={(e) => this.onChange('is_primary_obligor', e.target.checked)}
                  disabled={disable_select_primary_obligor}
                />
              </FormGroup>
              <FormGroup>
                <Label>Is Surviving Spouse?:</Label>
                <Input
                  className='ml-0'
                  type="checkbox"
                  checked={is_surviving_spouse}
                  onChange={(e) => this.onChange('is_surviving_spouse', e.target.checked)}
                />
              </FormGroup>
              <FormGroup>
                <Label>Year of birth:</Label>
                <Input
                  value={year_of_birth}
                  onChange={(e) => this.onChange('year_of_birth', e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label>SSN:</Label>
                {is_editing_ssn ? <Input
                  value={new_ssn}
                  onChange={(e) => this.onChange('new_ssn', e.target.value)}
                /> : <span>{ssn}</span>}
                <Button color="link" onClick={e => this.setState({ is_editing_ssn: !is_editing_ssn, new_ssn: '' })}>{is_editing_ssn ? 'Cancel' : `${last_4_ssn ? 'Edit' : 'Add new'} SSN`}</Button>
              </FormGroup>
            </Col>
          </Row>}
      </ModalBody>
      <ModalFooter>
        <Button onClick={onHide}>Cancel</Button>
        <Button color='primary' disabled={this.state.disable_submit_btn} onClick={this.onSubmit}>{submitText}</Button>
      </ModalFooter>
    </Fragment>)
  }
}

export default compose(
  connectResource({
    namespace: 'loanConsumers',
    endpoint: 'loans/:loan_id/consumers',
    prefetch: false,
    apiVersion: 2,
  }),
)(EditConsumerModal)
