import TextareaAutosize from 'react-textarea-autosize'
import React from 'react'
import { checkPermission } from 'app/common/utils/helpers'

function onKeyPress(e, onSubmit) {
  if (e.key === 'Enter') {
    e.preventDefault()
    onSubmit(null)
  }
}

export default function NewMessage(props) {
  /**
  NewMessage component renders UI for creating a message.

  UI includes a text area for the user to type the message body, and a Send button.

  It supports the edge case where a vendor does not yet exist on the Order, which replaces the Send button with an Add Vendor Note button.

  Should not be used with Unified Conversations. Instead, use the NewMessageTem component.
  */
  const { orderData, onFocus, newMessageValue, handleInputChange, newMessageTitle, onSubmit } = props
  const assignmentMessageStatus = new Set(['pending_approval', 'waiting_for_payment'])
  const isVendorMessage = checkPermission('enable_current_vendor_message') && orderData && assignmentMessageStatus.has(orderData.statusKey)
  const onSubmitWrapper = () => { onSubmit({ }) }
  return (<main className='send-message'>
    <div className='w-100'>
      <section className="input-group my-3 send-message-input">
        <TextareaAutosize
          type='text'
          onKeyPress={(e) => onKeyPress(e, onSubmitWrapper)}
          onFocus={onFocus}
          value={newMessageValue}
          onChange={handleInputChange}
          className="form-control"
          placeholder=""
          title={newMessageTitle}
        />
        <article className="input-group-append">
          <button
            onClick={onSubmitWrapper}
            className="btn btn-primary"
            disabled={newMessageValue === ''}
            type="submit"
          >
            { isVendorMessage ? 'Add Vendor Note' : 'Send' }
          </button>
        </article>
      </section>
    </div>
  </main>)
}
