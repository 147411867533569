import React, { PureComponent } from 'react'

import { STATES_GEOJSON } from '../../../constants/geojson'

import './style.scss'

import { CheckCircleOutlined, LeftCircleOutlined } from '@ant-design/icons'

import { Button, Alert } from 'antd'
import { Map, TileLayer, GeoJSON, Pane } from 'react-leaflet'


/**
 * The LayeredMapSelector is used to view a map and select
 * areas at multiple levels: entire U.S. to state to county
 * to ZIP.
 *
 * Props:
 *   countyOverlay
 *   zipOverlay
 *   onFeatureClick
 *   onShiftClick
 *   renderInstructions
 *   renderSidebarContents
 *   styleFeature
 *   title
 */
class LayeredMapSelector extends PureComponent {
  state = {
    selectedStateCode: null,
    selectedCountyCode: null,
    mapCenter: [39.50, -98.35],
    mapZoom: 4,
    geoJsonKey: 0,
    overlayKey: 0,
    shiftDown: false,
    mapBounds: undefined
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onShiftDown)
    document.addEventListener('keyup', this.onShiftUp)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onShiftDown)
    document.removeEventListener('keyup', this.onShiftUp)
  }

  componentWillReceiveProps(nextProps) {
    const {
      countyOverlay,
      zipOverlay,
      readOnly,
    } = this.props
    if (
      countyOverlay !== nextProps.countyOverlay ||
      zipOverlay !== nextProps.zipOverlay ||
      readOnly !== nextProps.readOnly
    ) {
      this.setState((prevState) => ({ overlayKey: prevState.overlayKey + 1 }))
    }
  }

  onShiftDown = (e) => {
    if(e.key === 'Shift') {
      this.setState({ shiftDown: true })
    }
  }

  onShiftUp =(e) => {
    if(e.key === 'Shift') {
      this.setState({ shiftDown: false })
    }
  }

  goToNationalView = () => {
    const {
      setCurrentView,
    } = this.props
    const defaultNationalCenter = [39.50, -98.35]
    const defaultNationalZoom = 4
    this.setState({
      geoJsonKey: this.state.geoJsonKey + 1,
      mapCenter: defaultNationalCenter,
      selectedStateCode: null,
      mapZoom: defaultNationalZoom,
    })
    setCurrentView('national')
  }

  onEachFeature = (feature, layer) => {
    if (feature.properties) {
      const toolTipText =
        feature.properties.ZCTA5CE10 || feature.properties.NAME

      layer.bindTooltip(toolTipText, {
        direction: 'top',
        className: 'editor-tooltip',
        sticky: true,
        opacity: 1,
        offset: [0, -13],
      })
    }

    if (!this.props.readOnly) {
      layer.on('click', function() {
        // these properties come from styles returned in the
        // styleFeature function passed into the <GeoJson /> component
        const {
          selectedFillColor,
          selectedFillOpacity,
          unselectedFillColor,
          unselectedFillOpacity,
          checked,
        } = this.options

        const fillColor = !checked ? selectedFillColor : unselectedFillColor
        const fillOpacity = !checked ? selectedFillOpacity : unselectedFillOpacity

        this.setStyle({
          prevFillColor: fillColor,
          prevFillOpacity: fillOpacity,
          checked: !this.options.checked,
        })
      })
    }

    layer.on('mouseover', function() {
      this.setStyle({
        prevFillColor: this.options.fillColor,
        prevFillOpacity: this.options.fillOpacity,
        fillOpacity: this.options.hoveredFillOpacity,
      })
    })

    layer.on('mouseout', function() {
      this.setStyle({
        fillColor: this.options.prevFillColor,
        fillOpacity: this.options.prevFillOpacity,
      })
    })
  }

  onClick = (feature, featureType) => {
    const {
      onFeatureClick,
      onShiftClick,
      readOnly,
    } = this.props

    if (!readOnly && (this.state.shiftDown || featureType === 'zip')) {
      onShiftClick(feature, featureType)
    } else {
      onFeatureClick(feature, featureType)
      this.onZoomtoArea(feature, featureType)
    }

    // A re-render of this component is expense so we
    // had to make it a PureCompoent. Now that it's a PureComponent
    // it no longer re-renders on click so we have to force a
    // rerender.  This is generally bad practice but the benefits
    // of the optimization outway the risk here.
    this.forceUpdate()
  }

  onZoomtoArea = (feature, featureType) => {
    const {
      setCurrentView,
      hideSidebar
    } = this.props
    if(featureType === 'zip') {

    } else if(featureType === 'county') {
      const countyBounds = feature.layer.getBounds()
      const selectedCountyCode = feature.layer.feature.properties.COUNTY
      this.setState({
        geoJsonKey: this.state.geoJsonKey + 1,
        mapBounds: countyBounds,
        selectedCountyCode
      })
      setCurrentView(featureType)
    } else {
      let stateBounds = feature.layer.getBounds().getCenter()

      let northEast = feature.layer.getBounds()['_northEast']
      let southWest = feature.layer.getBounds()['_southWest']

      let stateWidth = northEast['lng'] - southWest['lng']
      let stateHeight = northEast['lat'] - southWest['lat']

      let stateSize = Math.max(...[stateWidth, stateHeight])

      let newZoom = 6
      if(!hideSidebar) {
        if(stateSize < 1.3) {
          newZoom = 10
        } else if(stateSize < 2) {
          newZoom = 9
        } else if(stateSize < 4) {
          newZoom = 8
        } else if(stateSize < 9 && stateHeight < 7) {
          newZoom = 7
        }
      } else {
        newZoom = 5
        if(stateSize < 1.3) {
          newZoom = 9
        } else if(stateSize < 2) {
          newZoom = 8
        } else if(stateSize < 5) {
          newZoom = 6
        } else if(stateSize < 9 && stateHeight < 7) {
          newZoom = 5
        }
      }


      let stateCenter = [stateBounds.lat, stateBounds.lng]

      // update what we are currently viewing
      let selectedStateCode = this.state.selectedStateCode
      let selectedCountyCode = this.state.selectedCountyCode
      let selectedStateFeature = this.state.selectedStateFeature
      if(featureType === 'state') {
        selectedStateCode = feature.layer.feature.properties.STATE
        selectedStateFeature = feature
      } else if(featureType === 'county') {
        selectedCountyCode = feature.layer.feature.properties.COUNTY
      }

      this.setState({
        geoJsonKey: this.state.geoJsonKey + 1,
        mapZoom: newZoom,
        mapCenter: stateCenter,
        mapBounds: undefined,
        selectedStateCode,
        selectedStateFeature,
        selectedCountyCode
      })

      setCurrentView(featureType)
    }
  }

  getBackButton = () => {
    const {
      currentView
    } = this.props
    const {
      selectedStateFeature
    } = this.state
    let backText = 'National View'
    let isDisabled = true
    let buttonAction = null

    if(currentView === 'state') {
      backText = 'National View'
      isDisabled = false
      buttonAction = this.goToNationalView
    } else if(currentView === 'county') {
      backText = 'State View'
      isDisabled = false
      buttonAction = () => this.onZoomtoArea(selectedStateFeature, 'state')
    }

    return <Button onClick={buttonAction} disabled={isDisabled} size={'large'}><LeftCircleOutlined /> Back to {backText}</Button>;
  }

  getLowerLevelOverlays = () => {
    const {
      countyOverlay,
      zipOverlay,
      styleFeature,
    } = this.props
    const {
      overlayKey,
      geoJsonKey,
      selectedStateCode,
      selectedCountyCode
    } = this.state
    const overlays = []
    const stateFIPS = selectedStateCode
    const fullFIPS = selectedCountyCode ? `${stateFIPS}${selectedCountyCode}` : ''
    const isCountyOverlay = countyOverlay && countyOverlay[stateFIPS]
    const isZipOverlay = zipOverlay && zipOverlay[fullFIPS]
    
    if (isCountyOverlay && this.isStateOrLower()) {
      overlays.push(
        <GeoJSON
          key={`county-${stateFIPS}-${overlayKey}-${geoJsonKey}`}
          data={countyOverlay[stateFIPS]}
          onClick={(feature) => this.onClick(feature, 'county')}
          style={(feature) => styleFeature(feature, 'county')}
          onEachFeature={this.onEachFeature}
        />,
      )
    }

    if (isZipOverlay && this.isCountyOrLower()) {
      overlays.push(
        <GeoJSON
          key={`zip-${fullFIPS}-${overlayKey}-${geoJsonKey}`}
          data={zipOverlay[fullFIPS]}
          onClick={(feature) => this.onClick(feature, 'zip')}
          style={(feature) => styleFeature(feature, 'zip')}
          onEachFeature={this.onEachFeature}
        />,
      )
    }

    return overlays
  }

  isStateOrLower = () => ['state', 'county', 'zip'].indexOf(this.props.currentView) > -1
  isCountyOrLower = () => ['county', 'zip'].indexOf(this.props.currentView) > -1

  render() {
    const {
      renderInstructions,
      renderSidebarContents,
      styleFeature,
      onSubmit,
      onCancel,
      title,
      submitText,
      disableSubmit,
      hideSidebar,
      readOnly,
    } = this.props
    const {
      mapBounds,
      mapCenter,
      mapZoom
    } = this.state
    const mapViewProps = {}
    if(mapBounds) {
      mapViewProps.bounds = mapBounds
    } else {
      mapViewProps.center = mapCenter
      mapViewProps.zoom = mapZoom
    }

    return (
      <div className={hideSidebar ? 'coverage-container-child' : 'coverage-container'}>
        <div className='coverage-back'>
          {this.getBackButton()}
        </div>

        <div className='coverage-holder'>

          <div className={`map-section${hideSidebar ? '-child' : ''} ${this.state.shiftDown && !readOnly ? 'add-territory' : 'zoom-territory'}`}>
            <Map
              {...mapViewProps}
              dragging={false}
              animate={true}
              zoomControl={false}
              scrollWheelZoom={false}
              boxZoom={false}
              keyboard={false}
              doubleClickZoom={false}
              touchZoom={false}
            >
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url='https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png'
              />

              {!this.isCountyOrLower() && (
                <GeoJSON
                  key={this.state.geoJsonKey.toString()}
                  data={STATES_GEOJSON}
                  style={feature => styleFeature(feature, 'state')}
                  onClick={feature => this.onClick(feature, 'state')}
                  onEachFeature={this.onEachFeature}
                />
              )}

              {this.getLowerLevelOverlays()}

              <Pane className='label-only-pane' style={{ zIndex: 800 }}>
                <TileLayer url='https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}{r}.png' />
              </Pane>

            </Map>
          </div>

          {!hideSidebar && <div className='ui-section'>
            <div className='ui-title'>
              <h3>{title}</h3>
            </div>
            <Alert
              message={'Instructions:'}
              description={renderInstructions()}
              type="info"
            />
            <div className='ui-content'>
              {renderSidebarContents()}
            </div>
            <div className='ui-footer'>
              {onCancel ? <Button size='large' onClick={onCancel}>Cancel</Button> : null}
              <Button disabled={disableSubmit} type='primary' icon={<CheckCircleOutlined />} size='large' onClick={onSubmit}>{submitText}</Button>
            </div>
          </div>}

        </div>

      </div>
    );
  }
}

LayeredMapSelector.defaultProps = {
  renderSidebarContents: () => {},
  renderInstructions: () => {},
  onSubmit: () => {},
  onCancel: () => {},
}

export default (LayeredMapSelector)
