import React, { Component, Fragment } from 'react'
import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Label,
  FormGroup,
} from 'reactstrap'
import { compose } from 'redux'
import { connectResource } from '../../common/utils/resource'
import NumberInput from '../../common/forms/inputs/NumberInput'


class RefundsModal extends Component {
    state = {
      amount: this.props.getMaxRefundAmount(this.props.payment) / 100,
      maxRefundAmount: this.props.getMaxRefundAmount(this.props.payment) / 100,
      isLoading: false,
    }

    isDisabled() {
      const {
        isLoading,
        amount,
        maxRefundAmount,
      } = this.state
      if (isLoading) {
        return true
      }

      if (amount > maxRefundAmount) {
        return true
      }
      if (amount <= 0) {
        return true
      }
      return false
    }

    onChange = (field, value) => {
      this.setState({
        [field]: value,
      })
    }

    onSubmit = () => {
      const {
        isLoading,
      } = this.state

      this.setState({
        isLoading: true,
      })
      if (!isLoading) {
        // payment.user is defined on broker payments and lender corp card payments
        this.props.consumerPaymentRefund.post({
          order_id: this.props.order_id,
          refund_amount: this.state.amount * 100,
          payment_id: this.props.payment.id,
        })
          .then(this.props.refreshOrder)
      } else {
        this.props.refreshOrder()
      }
    }

    render() {
      const {
        amount,
      } = this.state
      const {
        onHide,
      } = this.props
      return (<Fragment>
        <ModalHeader toggle={onHide}>Creating Refund Request</ModalHeader>
        <ModalBody>
          <Row>
            <Col xs="12">
              <FormGroup>
                <Label htmlFor="amount">Amount ($)</Label>
                <NumberInput
                  placeholder="Amount"
                  name={'amount'}
                  min={0}
                  value={amount}
                  onChange={value => this.onChange('amount', value)}
                  disabled={false}
                />
                {(parseFloat(amount) > this.state.maxRefundAmount) && <span className='text-danger my-2'>Refund amount cannot be greater than the outstanding payment amount.</span>}
                {(parseFloat(amount) <= 0) && <span className='text-danger my-2'>Refund amount must be greater than $0.00</span>}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onHide}>Cancel</Button>
          <Button onClick={this.onSubmit} disabled={this.isDisabled()}>Save</Button>
        </ModalFooter>
      </Fragment>)
    }
}

export default compose(
  connectResource({
    prefetch: false,
    namespace: 'consumerPaymentRefund',
    endpoint: 'payment/refund',
    successMessage: {
      POST: 'Refund Successfully Initiated.',
    },
    apiVersion: 2,
  })(RefundsModal)
)
