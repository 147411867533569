import React from 'react'
import moment from 'moment'
import { Link } from '../../common/router'
import { Badge } from 'reactstrap'
import Rephrase from '../utils/Rephrase'

export default function UserCardComponent(props) {
  let linkTo = 'singleUser'
  const linkProps = {
    id: props.id,
  }
  if (props.position === 'Appraiser') {
    linkTo = 'singleAppraiser'
  }

  const { style } = props
  const cardStyle = { ...style, width: '13rem' }

  return (
    <div className="card mr-4" style={cardStyle}>
      <div className="card-body">
        <div>
          <div className='user-card-top mb-1'>
            {props.primaryContactStatus || null}
            {props.userButton || null}
          </div>
          <div>
            <h5 className="card-title">{props.name}</h5>
            <h6 className="card-subtitle mb-2 text-muted"><Rephrase>{props.position}</Rephrase></h6>
          </div>
        </div>
        {props.company_name && <span className="card-text">{props.company_name}<br></br></span>}
        {props.homePhone ? <span className="card-text">H: {props.homePhone}<br></br></span> : null}
        {props.cellPhone ? <span className="card-text">C: {props.cellPhone}<br></br></span> : null}
        {props.workPhone ? <span className="card-text">W: {props.workPhone}<br></br></span> : null}
        {props.submissionSent &&
          // eslint-disable-next-line react/jsx-key
          <span className="card-text">Submission Sent:<br /> {props.submissionSent.map(submission => (<small style={{ marginLeft: '10%' }}>- {moment.utc(submission).local().format('lll')}</small>))}<br></br></span>
        }
        {props.email ? <span className="card-text"><a href={'mailto:' + props.email}>{props.email}</a><br /><br /></span> : null}
        {props.badges && props.badges.map((b, bIndex) => <Badge key={bIndex} color="primary" pill>{b}</Badge>)}
        {props.showLink ? (<Link to={linkTo} {...linkProps} className='card-link'>Profile</Link>) : null}
        {props.showDeliveryStatusButton && props.checkConsumer}
      </div>
    </div>
  )
}
