import { compose } from 'redux'
import { connect, useSelector } from 'react-redux'
import React, { useState, useMemo } from 'react'
import { Button, Modal, notification } from 'antd'
import { history } from '../../../init'
import { renderStatusBadge } from '../../../common/utils/helpers'
import { selectIsAmcLender } from '../../../store/reducers/user/userSelectors'
import apiClient, { statusCodes } from '../../../apiClient'
import { ReviewAssignmentType } from './constants'

const genericErrorMessage = 'We are sorry but we could not complete this action. If this problem persists, please contact us.'

const OrderGetNext = ({
  currentUser, handleReceivedNextOrder,
}) => {
  const reviewAssignmentsApi = useMemo(() => apiClient(SERVICE_URL), [])
  const getNextApi = useMemo(() => apiClient(API2_URL), [])
  const isAmcLender = useSelector(selectIsAmcLender)
  const [loadingNextOrder, setLoadingNextOrder] = useState(false)
  const [assigningOrder, setAssigningOrder] = useState(false)
  const [confirmationIsVisible, setConfirmationIsVisible] = useState(false)
  const [nextOrder, setNextOrder] = useState()

  const showErrorToast = (message) => {
    notification.open({
      message: message || genericErrorMessage,
      closeIcon: <></>,
      icon: errorIcon(),
    })
  }

  const handleGetNext = async () => {
    setLoadingNextOrder(true)
    try {
      const next = await getNextApi.get('order/get-next')
      setNextOrder(next)
      handleReceivedNextOrder(next)
      if (next) {
        setConfirmationIsVisible(true)
      }
    } catch (err) {
      showErrorToast()
    } finally {
      setLoadingNextOrder(false)
    }
  }

  const handleAssignOrder = async () => {
    setAssigningOrder(true)
    try {
      await reviewAssignmentsApi.post('review-assignments/v1', {}, {
        assignment_type: currentUser.lender?.is_amc_lender ? ReviewAssignmentType.AMP : ReviewAssignmentType.LENDER,
        order_id: nextOrder.id,
        ranking:
          currentUser.lender?.is_amc_lender
            ? nextOrder.current_amp_reviewer_assignment?.ranking + 1 || 0
            : nextOrder.current_reviewer_assignment?.ranking + 1 || 0,
        reviewer_user_id: currentUser.id,
      })

      history.push(`/orders/${nextOrder.id}`)
    } catch (err) {
      if (err.response) {
        if (err.response.status === statusCodes.RequestTimeout) {
          showErrorToast('Timed out occurred while assigning order. Please try again.')
        } else if (err.response.status === statusCodes.Conflict) {
          setConfirmationIsVisible(false)
          showErrorToast('Another user has already been assigned to this order. Please try again.')
        } else {
          showErrorToast()
        }
      } else {
        showErrorToast()
      }
    } finally {
      setAssigningOrder(false)
    }
  }

  const handleCancel = () => setConfirmationIsVisible(false)

  const errorIcon = () => (<i style={{ fontSize: 30, paddingRight: '3%', color: 'red' }} className="fas fa-times-circle"/>)

  return (
    <>
      <Button type="primary" onClick={handleGetNext} loading={loadingNextOrder}>
        Get Next Order
      </Button>

      {nextOrder && <Modal
        visible={confirmationIsVisible}
        onOk={handleAssignOrder}
        onCancel={handleCancel}
        title="Confirm Next Order"
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleAssignOrder}
            loading={assigningOrder}
          >
            Confirm
          </Button>,
        ]}
      >
        <p>
          Your next order is <b>#{nextOrder.number}</b> which is&nbsp;
          {renderStatusBadge(nextOrder.status, isAmcLender, nextOrder)}
        </p>
      </Modal>
      }
    </>
  )
}

const mapStateToProps = state => ({
  currentUser: state.resource?.user?.data,
})

export default compose(
  connect(mapStateToProps, null),
)(OrderGetNext)
