import CommentTemplateModal from './CommentTemplateModal'
import CommentTemplateAccessModal from './CommentTemplateAccessModal'
import ModalTrigger from '../../common/modals/ModalTrigger'
import { checkPermission } from '../../common/utils/helpers'
import { DownOutlined } from '@ant-design/icons'
import { Dropdown, Menu, Button as ButtonAntd } from 'antd'
import { Button } from 'reactstrap'
import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { connectResource } from '../../common/utils/resource'

class CommentTemplateDropdown extends Component {
  state = {
    visible: false,
  };

  setDropdownVisibility = (visible) => {
    this.setState({ visible })
  };

  menu = [
    checkPermission('edit_message_templates') && (
      <Menu.Item key="0" className="order-action-dropdown-div">
        <ModalTrigger
          component={CommentTemplateModal}
          onHide={this.props.onHide}
          templates={this.props.templates}
          refreshOrder={this.props.refreshOrder}
        >
          <div className="border border-light" color="light">
            <Button className="text-left order-dropdown-button" block>
              Create New Message Template
            </Button>
          </div>
        </ModalTrigger>
      </Menu.Item>
    ),
    checkPermission('edit_message_templates') && (
      <Menu.Item key="1" className="order-action-dropdown-div">
        <ModalTrigger
          component={CommentTemplateAccessModal}
          onHide={this.props.onHide}
          templates={this.props.templates}
          refreshOrder={this.props.refreshOrder}
          editing={true}
        >
          <div className="border border-light" color="light">
            <Button className="text-left order-dropdown-button" block>
              Edit Message Templates
            </Button>
          </div>
        </ModalTrigger>
      </Menu.Item>
    ),
    <Menu.Item key="2" className="order-action-dropdown-div">
      <ModalTrigger
        component={CommentTemplateAccessModal}
        onHide={this.props.onHide}
        templates={this.props.templates}
        refreshOrder={this.props.refreshOrder}
        setDropdownVisibility={this.setDropdownVisibility}
        setTextToInsert={this.props.setTextToInsert}
      >
        <div className="border border-light" color="light">
          <Button className="text-left order-dropdown-button" block>
            Insert Message Template
          </Button>
        </div>
      </ModalTrigger>
    </Menu.Item>,
  ];

  render() {
    return (
      checkPermission('view_message_templates') && (
        <Dropdown
          overlay={<Menu name={this.props.name}>{this.menu}</Menu>}
          trigger={['click']}
          onVisibleChange={this.setDropdownVisibility}
          visible={this.state.visible}
        >
          {this.props.antdStyle ? (
            <ButtonAntd>
              {' '}
              Manage Message Templates <DownOutlined />
            </ButtonAntd>
          ) : (
            <Button className="ml-auto width-50">
              {' '}
              Manage Message Templates <DownOutlined />
            </Button>
          )}
        </Dropdown>
      )
    )
  }
}

function mapStateToProps(state) {
  return {
    lenderId: state.resource.user.data.lender.id,
  }
}

export default compose(
  connect(mapStateToProps),
  connectResource({
    namespace: 'templates',
    endpoint: 'lender/messageTemplate/:lenderId',
    prefetch: true,
    apiVersion: 2,
  })
)(CommentTemplateDropdown)
