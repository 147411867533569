import { createSlice } from '@reduxjs/toolkit'
import { DATE_RANGE_FILTER_TEXT, KEY_6_MONTHS } from '../../../common/constants/dateFilter'

const orderFiltersSlice = createSlice({
  name: 'orderFilters',
  initialState: {
    dateRangeText: DATE_RANGE_FILTER_TEXT[KEY_6_MONTHS],
  },
  reducers: {
    updateDateRangeText: (state, action) => {
      state.dateRangeText = action.payload
    },
  },
})

export const { updateDateRangeText } = orderFiltersSlice.actions

export default orderFiltersSlice.reducer
