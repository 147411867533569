import React from 'react'

import { CheckCircleOutlined, DownloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

import { Table } from 'antd'
import {
  Alert,
  Button,
  Badge,
} from 'reactstrap'

// components
import BulkRevisionButton from './BulkRevisionButton'
import OverrideRequestButton from './OverrideRequestButton'
import Rephrase from '../../../common/utils/Rephrase'

// Images
import errorFlag from '../../../../img/icons/errorFlag.png'
import warningFlag from '../../../../img/icons/warningFlag.png'

// common
import { GSEBlockedErrorCodeToReadable } from '../../../common/constants/gse'
import { utcToLocal, checkPermission } from '../../../common/utils/helpers'

export default function SubmissionTableUcdp(props) {
  const getUcdpTable = () => {
    const {
      // vars
      selectedUcdpRevision,
      selectedUcdpOverrides,
      ucdpSubmission,
      ucdpAppraisalIndex,
      orderData,
      onUcdpRowSelection,
      canViewUCDPResults,
      canViewGSEDetails: canViewGSEDetailsFunc,
      downloadDocument,
      getCuScoreBadge,
      getLcaScoreBadge,
      initializeRevisions,
      refreshOrder,
      sub,
      flags,
    } = props
    // rowSelection object indicates the need for row selection
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        const requiresOverrides = []

        for (const selectedRow of selectedRows) {
          if (selectedRow.override_status !== 'APPROVED' && selectedRow.override_status !== 'AUTO-APPROVED' && selectedRow.type === 'Error') {
            requiresOverrides.push(selectedRow)
          }
        }
        onUcdpRowSelection(requiresOverrides, selectedRows)
      },
    }

    const data = []

    let dataIndex = data.length
    for (const ucdpError of ucdpSubmission.pathway_result.hard_stops) {
      dataIndex++
      let overrideStatus = ucdpError.override_status
      if (ucdpError.override_decision_description === 'Override automatically approved') {
        overrideStatus = 'AUTO-APPROVED'
      }

      const appraisal_identifier = 'APPRAISAL_' + (ucdpAppraisalIndex + 1).toString()
      if (ucdpError.relevant_appraisal === appraisal_identifier) {
        data.push({ key: dataIndex.toString(), code: ucdpError.code, property: null, error: ucdpError.description, type: 'Error', override_status: overrideStatus })
      }
    }
    let findingIndex = 0
    for (const hard_stop of ucdpSubmission.pathway_result.uad_compliance_errors) {
      findingIndex++
      data.push({ key: (findingIndex + dataIndex).toString(), code: hard_stop.code, property: hard_stop.property_affected, error: hard_stop.action_message, type: 'Warning', override_status: 'N/A' })
    }

    const columns = [{
      title: 'Code',
      dataIndex: 'code',
      width: 125,
      render: function renderCodeText(text) {
        return <small><b>{text}</b></small>
      },
    },
    {
      title: <span>Property Affected</span>,
      dataIndex: 'property',
      width: 150,
    },
    {
      title: <span>UCDP Findings</span>,
      dataIndex: 'error',
    },

    {
      title: 'Type',
      dataIndex: 'type',
      width: 150,
      filterMultiple: false,
      align: 'center',
      filters: [{ text: 'Warning', value: 'Warning' }, { text: 'Error', value: 'Error' }],
      onFilter: (value, record) => record.type === value,
      render: text => {
        if (text === 'Warning') {
          return <img src={warningFlag} height={'18px'} />
        } else if (text === 'Error') {
          return <img src={errorFlag} height={'18px'} />
        }
      },
    },
    {
      title: 'Override Status',
      dataIndex: 'override_status',
      width: 150,
      align: 'center',
      render: (value) => {
        if (value === 'AUTO-APPROVED') {
          return <CheckCircleOutlined className='text-success' />
        } else if (value === 'APPROVED') {
          return <CheckCircleOutlined className='text-success' />
        } else if (value === 'N/A') {
          return <CheckCircleOutlined className='text-success' />
        } else {
          return <small><Badge className='badge-danger'>Requires Override</Badge></small>
        }
      },
    },

    ]

    const pathway_request = ucdpSubmission.pathway_request
    const pathway_result = ucdpSubmission.pathway_result
    let pathwayHeaderRow = null
    let pathwayTable = null

    const canViewGSEDetails = canViewGSEDetailsFunc()
    const shouldRenderUCDPTable = pathway_result && canViewUCDPResults()

    if (shouldRenderUCDPTable) {
      const pathway_status = pathway_result.status
      const has_actual_errors = pathway_result.error_messages.length > 0
      let headerCUScore = null
      let headerLcaScore = null
      const showDate = pathway_request.requested_datetime || pathway_request.initialized_datetime
      const headerDate = (<div className='spacer'>
        <p><small>Date Requested:</small></p>
        <p>{utcToLocal(showDate).format('lll')}</p>
      </div>)
      let headerResult1 = null
      let headerResult2 = null
      let headerPDF1 = null
      let headerPDF2 = null

      if (pathway_status === 'success') {
        const canDownloadSubmission = checkPermission('submission_download')
        const has_veros_errors = pathway_result.uad_compliance_errors.length > 0 || pathway_result.hard_stops.length > 0
        if (!has_actual_errors) {
          // first show the pdfs to everyone
          if (pathway_result.freddie_pdf && canDownloadSubmission) {
            headerPDF1 = (<div className='spacer'>
              <Button color='light' className='mr-2 btn-light btn-sm' type='button' onClick={() => downloadDocument('freddie_pdf', sub.version)}><DownloadOutlined className='mr-1' /> Freddie PDF</Button>
            </div>)
          }
          if (pathway_result.fannie_pdf && canDownloadSubmission) {
            headerPDF2 = (<div className='spacer'>
              <Button color='light' className='mr-2 btn-light btn-sm' type='button' onClick={() => downloadDocument('fannie_pdf', sub.version)}><DownloadOutlined className='mr-1' /> Fannie PDF</Button>
            </div>)
          }
          if (pathway_result.fha_pdf && canDownloadSubmission) {
            // we're assuming fannie and freddie will be there or fha will be there, not all three
            headerPDF1 = (<div className='spacer'>
              <Button color='light' className='mr-2 btn-light btn-sm' type='button' onClick={() => downloadDocument('fha_pdf', sub.version)}><DownloadOutlined className='mr-1' /> EAD PDF</Button>
            </div>)
          }
          // now show details if applicable
          if (canViewGSEDetails) {
            headerCUScore = getCuScoreBadge()
            headerLcaScore = getLcaScoreBadge()
            if (pathway_result.freddie_result && pathway_result.freddie_result.condition) {
              headerResult1 = (<div className='spacer'>
                <p><small>Freddie Submission:</small></p>
                <p>{pathway_result.freddie_result && pathway_result.freddie_result.condition === 'SUCCESSFUL' ? <span><CheckCircleOutlined className='text-success mr-1' /><Badge className='badge-success'>Success</Badge></span> : <span><ExclamationCircleOutlined className='text-danger mr-1' /><span className='text-muted'>Submission Failed</span></span>}</p>
              </div>)
            }
            if (pathway_result.fannie_result && pathway_result.fannie_result.condition) {
              headerResult2 = (<div className='spacer'>
                <p><small>Fannie Submission:</small></p>
                <p>{pathway_result.fannie_result && pathway_result.fannie_result.condition === 'SUCCESSFUL' ? <span><CheckCircleOutlined className='text-success mr-1' /><Badge className='badge-success'>Success</Badge></span> : <span><ExclamationCircleOutlined className='text-danger mr-1' /><span className='text-muted'>Submission Failed</span></span>}</p>
              </div>)
            }
            if (pathway_result.fha_result && pathway_result.fha_result.condition) {
              headerResult1 = (<div className='spacer'>
                <p><small>UCDP Submission:</small></p>
                <p>{pathway_result.fha_result.condition === 'SUCCESSFUL' ? <span><CheckCircleOutlined className='text-success mr-1' /><Badge className='badge-success'>Success</Badge></span> : <span><ExclamationCircleOutlined className='text-danger mr-1' /><span className='text-muted'>Submission Failed</span></span>}</p>
              </div>)
            }

            if (has_veros_errors) {
              pathwayTable = (<Table
                scroll={{ y: 300 }}
                size={'middle'}
                pagination={false}
                rowSelection={rowSelection}
                footer={() => <div className='w-100 d-flex justify-content-between'>
                  <div>
                    <small><img src={errorFlag} height={'18px'} /> = Error</small>
                    <small className='ml-4'><img src={warningFlag} height={'18px'} /> = Warning</small>
                  </div>
                  <div>
                    <OverrideRequestButton
                      orderId={orderData.id}
                      gseType={'Ucdp'}
                      refreshOrder={refreshOrder}
                      version={sub.version}
                      appraisalNumber={ucdpAppraisalIndex + 1}
                      hardStopsCodes={selectedUcdpOverrides}
                      selectedRevisions={selectedUcdpRevision}
                    />

                    {checkPermission('revisions_create_edit') && !flags?.plat1860EnableMultiStepRevision && (
                      <BulkRevisionButton
                        hasErrorsSelected={(selectedUcdpRevision.length + selectedUcdpOverrides.length) >= 1}
                        initializeRevisions={initializeRevisions}
                        orderID={orderData.id}
                      />
                    )}

                    <Button style={{ display: 'none' }} disabled={selectedUcdpOverrides.length < 1}
                      color='secondary'
                      className='btn-secondary btn-sm'>Override Selected Hardstop(s)</Button>
                  </div>
                </div>}
                columns={columns}
                dataSource={data}
              />)
            }
          }
        }
      } else if (pathway_status === 'processing' || pathway_status === 'ready_to_submit') {
        pathwayTable = (<div className='p-2'>This submission is still processing. Check back later for the results.</div>)
        headerResult1 = (<div className='spacer'>
          <p><small>Submission Status:</small></p>
          <p><span><ExclamationCircleOutlined className='text-muted mr-1' /><span className='text-muted'>Submission Processing</span></span></p>
        </div>)
      } else if (pathway_status === 'error' && canViewGSEDetails) {
        if (has_actual_errors) {
          pathwayTable = (<div>
            <p className='p-2'>
                This submission has failed with the following error(s):
              <ul>
                {pathway_result.error_messages.map(err => <li key={err}>{err}</li>)}
              </ul>
            </p>
          </div>)

          const errorData = pathway_result.error_messages.map((err, idx) => ({ err, key: idx }))
          const errorCol = [{
            title: 'Submission Error',
            dataIndex: 'err',
          }]

          pathwayTable = (<Table
            scroll={{ y: 300 }}
            size={'middle'}
            pagination={false}
            columns={errorCol}
            dataSource={errorData}
          />)
        }
        headerResult1 = (<div className='spacer'>
          <p><small>Submission Status:</small></p>
          <p><span><ExclamationCircleOutlined className='text-danger mr-1' /><span className='text-muted'>Submission Failed</span></span></p>
        </div>)
      }
      pathwayHeaderRow = (<div className='ucdp-summary'>
        {headerCUScore}
        {headerLcaScore}
        {headerDate}
        {headerResult1}
        {headerResult2}
        {headerPDF1}
        {headerPDF2}
      </div>)
    }

    return (<div className='ucdp-results-table'>
      {pathwayHeaderRow}
      {pathwayTable}
    </div>)
  }

  const {
    // vars
    sub,
    hasUcdpSubmission,
  } = props

  if (hasUcdpSubmission) {
    return getUcdpTable()
  } else if (sub.gse_submission_for_ucdp_blocked_error) {
    // tell them that the automatic submission failed
    const readableError = GSEBlockedErrorCodeToReadable[sub.gse_submission_for_ucdp_blocked_error] || 'Something unexpected occurred'
    return <Alert color="light" className="mb-0"><ExclamationCircleOutlined className="mr-1" /><Rephrase> This appraisal was not automatically submitted to UCDP due to the following error: </Rephrase>{readableError}</Alert>
  }

  return <Alert color="light" className='mb-0'><ExclamationCircleOutlined className='mr-1' /><Rephrase> This appraisal has not been submitted to UCDP yet. </Rephrase></Alert>
}
