import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import API, { API_VERSION, REQUEST_METHOD } from '../../../api'
import STATUS from '../../../common/constants/reducer_statuses'

export const initialState = {
  fetchAssignableUsersStatus: STATUS.IDLE,
  assignableUsers: [],
}

export const fetchAssignableUsers = createAsyncThunk('users/fetchAssignableUsers', async (order_id, { rejectWithValue }) => {
  try {
    const assignURL = `order/assigned/${order_id}`
    return await API(assignURL, API_VERSION.TWO).request(REQUEST_METHOD.GET)
  } catch (err) {
    return rejectWithValue(err)
  }
})

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAssignableUsers.pending]: state => {
      state.fetchAssignableUsersStatus = STATUS.LOADING
      state.assignableUsers = []
    },
    [fetchAssignableUsers.fulfilled]: (state, action) => {
      state.fetchAssignableUsersStatus = STATUS.SUCCEEDED
      state.assignableUsers = action.payload
    },
    [fetchAssignableUsers.rejected]: state => {
      state.fetchAssignableUsersStatus = STATUS.FAILED
    },
  },
})

export default usersSlice.reducer
