import { of } from 'rxjs/observable/of'

import {
  setData,
  REQUEST_SUCCESS
} from '../../common/utils/resource'

export default function setCSVUploadSettings(action$, store) {
  return action$.ofType(REQUEST_SUCCESS).mergeMap(action => {
    if(action.meta && action.meta.resource && action.meta.resource.namespace === 'csvSettings') {
      const state = store.getState()
      const currentUser = { ...state.resource.user.data }
      const {
        mapping,
        custom_fields
      } = action.requestPayload
      const mappingReversed = {}
      for(let field of Object.keys(mapping)) {
        const header = mapping[field]
        mappingReversed[header] = field
      }
      currentUser.lender.loan_csv_mapping = mappingReversed
      currentUser.lender.loan_csv_custom_fields = custom_fields
      return of(setData(currentUser, { resource: { namespace: 'user' } }))
    }
    return []
  })
}
