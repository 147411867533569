/**
 * FileUpload is a simple component, a button for the input of files
 *
 * Users can click for a file selector or drop a file on the button
 *
 * Currently a wrapper around the antd Upload
 */
import React from 'react'

import { Icon as LegacyIcon } from '@ant-design/compatible'
import { DeleteOutlined, PaperClipOutlined } from '@ant-design/icons'

import { Button, Upload } from 'antd'

import {
  FILE_TYPE_TO_READABLE,
  FILE_TYPE_TO_ACCEPT_STRING
} from '../../constants'


// these are defaults that will be changed by supplying the right props
const DEFAULT_TEXT = 'Upload'
const DEFAULT_FILE_TEXT = 'File'
const DEFAULT_BUTTON_ICON = 'upload'


export default (props) => {
  /**
    props descriptions

    buttonIconType : override default 'upload' to show a different icon on the button (antd icons)
    buttonFileName : override default file name to show on the button
    className : className will get set on the Button to support custom styles
    fileList : list of files, the current value of this input
    fileType : controls display text and the files accepted
    onChange : called when a file is selected
    onRemove : called when user tries to remove a file
    required : shows a * on the button
  */
  const {
    buttonIconType,
    buttonFileName,
    className,
    fileList,
    fileType,
    onChange,
    onRemove,
    required,
    inputClassName
  } = props

  let acceptString = ''
  const displayIcon = buttonIconType || DEFAULT_BUTTON_ICON
  let displayFile = DEFAULT_FILE_TEXT
  const displayRequired = required ? '*' : ''

  if(fileType) {
    if(fileType in FILE_TYPE_TO_READABLE) {
      displayFile = FILE_TYPE_TO_READABLE[fileType]
    }
    if(fileType in FILE_TYPE_TO_ACCEPT_STRING) {
      acceptString = FILE_TYPE_TO_ACCEPT_STRING[fileType]
    }
  }

  if(buttonFileName) {
    displayFile = buttonFileName
  }

  const displayButtonText = `${DEFAULT_TEXT} ${displayFile}${displayRequired}`


  // button is actually visible to user
  const button = (
    <span>
      <label
        className={`ant-btn ant-btn-secondary ${className}`}
        style={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <LegacyIcon type={displayIcon} style={{ marginRight: '0.25rem' }} />
        {displayButtonText}
        <input
          accept={acceptString}
          onChange={onChange}
          onClick={(event)=> { 
            event.target.value = null
          }}
          type="file"
          style={{ display: 'none' }}
        />
      </label>
      <div className="ant-upload-list ant-upload-list-text">
        {fileList && fileList.map((file, key) => (
          <div style={{
            display: 'flex',
            alignItems: 'center',
            width: '125px'
          }}>
            <PaperClipOutlined />
            <span style={{
              flex: 1,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
              {file.name}
            </span>
            <DeleteOutlined style={{cursor: 'pointer'}} onClick={onRemove} />
          </div>
        ))}
      </div>
    </span>
  )

  // returns button with file upload
  return button
}
