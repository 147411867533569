import { loadableWithRetry } from '../../common/utils/lazyLoad'

const routes = [
  {
    path: '/',
    exact: true,
    name: 'permissions',
    component: loadableWithRetry(() => import('./PermissionsContainer')),
  },
]

export default routes
