import { access } from '../../common/session'
import { loadableWithRetry } from '../../common/utils/lazyLoad'

const routes = [
  {
    path: '/',
    exact: true,
    name: 'dashboard',
    component: loadableWithRetry(() => import('./Main')),
    access: access.F_ADMINISTRATION,
    accessRedirectTo: '/orders',
  },
]

export default routes
