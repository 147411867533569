const UNSORTED = ''
const UP = 'up'
const DOWN = 'down'

const CURRENT_DIRECTION_TO_NEXT_DIRECTION = {
  [UNSORTED]: UP,
  [DOWN]: UP,
  [UP]: DOWN,
}

export {
  UNSORTED,
  UP,
  DOWN,
  CURRENT_DIRECTION_TO_NEXT_DIRECTION,
}
