import React, { Fragment, useEffect } from 'react'
import { ModalHeader, ModalBody, FormGroup, Col, Label, ModalFooter, Button } from 'reactstrap'

export default function PaymentResendModal(props) {
  useEffect(() => {
    const { analytics_track_component, analytics_data } = props
    // Segment tracking
    const { firstname, lastname, role, lender } = analytics_data
    analytics_track_component('Opened Modal', {
      user: `${firstname} ${lastname}`,
      role: role.name,
      lender: lender.name,
      component: PaymentResendModal.name,
    })
  }, [])

  // Segment tracking
  function trackSubmit() {
    const { analytics_track_component, analytics_data } = props
    const { firstname, lastname, role, lender } = analytics_data
    analytics_track_component('Submitted Resend', {
      user: `${firstname} ${lastname}`,
      role: role.name,
      lender: lender.name,
      component: PaymentResendModal.name,
    })
  }

  return (
    <Fragment>
      <ModalHeader toggle={props.onHide}>Resend Payment Link</ModalHeader>
      <ModalBody>
        <FormGroup row>
          <Col xs="12">
            <Label>Are you sure you want to {props.payment.user ? 'reopen' : 'resend'} this payment link?</Label>
          </Col>
        </FormGroup>
      </ModalBody>
      <ModalFooter className="border-0">
        <Button onClick={props.onHide} color="secondary">Cancel</Button>
        <Button onClick={e => { props.resendPayment(props.payment); trackSubmit() }}>{props.payment.user ? 'Reopen' : 'Resend'}</Button>
      </ModalFooter>
    </Fragment>
  )
}
