import React from 'react'
import { Tooltip, Select, Input as AntdInput } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import Rephrase from '../../common/utils/Rephrase'
const { TextArea } = AntdInput

const RevisionLastStep = ({
  flags,
  title,
  text,
  categories,
  categoryOptions,
  useCategories,
  handleRevisionCategoryChange,
  job_type,
  is_amc_lender,
  responsibleParty,
  handleResponsiblePartyChange,
  updateRequestTitle,
  updateRequestText,
}) => {
  return (<>
    {useCategories ? (
      <RevisionCategories
        allowCustomValues={!flags.disableCustomRevisionCategories}
        onChange={handleRevisionCategoryChange}
        options={categoryOptions}
        value={categories}
      />
    ) : (<>
      <label className='mb-3' htmlFor='revision-title'>Title:</label>
      <AntdInput id='revision-title' name='title' value={title} onChange={(e) => updateRequestTitle(e.target.value)} />
    </>)}
    <label className='mt-3' htmlFor="revision-text">Request:</label>
    <TextArea id='revision-text' rows={10} className='w-100 bordered' name='text' type='text' value={text} onChange={(e) => updateRequestText(e.target.value)} />
    <ResponsiblePartyInput
      job_type={job_type}
      is_amc_lender={is_amc_lender}
      responsibleParty={responsibleParty}
      handleResponsiblePartyChange={handleResponsiblePartyChange}
    />
  </>)
}

const RevisionCategories = ({ value, onChange, options, allowCustomValues }) => {
  const toolTip = `Choose from revision categories${allowCustomValues ? ', custom values are allowed.' : '.'}`
  const mode = allowCustomValues ? 'tags' : 'multiple'

  return (<>
    <label htmlFor='revision-categories' className='mb-3'>Categories:
      <Tooltip title={toolTip}>
        <InfoCircleOutlined className='text-muted ml-1' />
      </Tooltip>
    </label>
    <Select
      className='w-100'
      id='revision-categories'
      mode={mode}
      onChange={onChange}
      options={options}
      value={value}
    />
  </>)
}

const ResponsiblePartyInput = ({ job_type, is_amc_lender, responsibleParty, handleResponsiblePartyChange }) => {
  if (job_type !== 'amc' || (job_type === 'amc' && is_amc_lender)) {
    return (
      <div data-testid='revision-party'>
        <label htmlFor='revision-responsible-party' className='mt-3'>Responsible Party:
          <Tooltip title='Identify who is responsible for the error this revision is addressing'>
            <InfoCircleOutlined className='text-muted ml-1' />
          </Tooltip>
        </label>
        <br />
        <Select
          id='revision-responsible-party'
          className='w-100'
          value={responsibleParty}
          onChange={handleResponsiblePartyChange}
          options={[{ value: 'appraiser', label: <Rephrase>Appraiser</Rephrase> }, { value: 'lender', label: 'Lender' }]}
        />
      </div>
    )
  }
  return null
}

export default RevisionLastStep
