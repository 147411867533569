export const CREATE_CSV_APPRAISERS = '@ds-resource/create-csv-appraisers'
export const UPDATE_APPRAISER_EXCLUSIONARY_LIST = '@ds-resource/update-appraiser-exclusionary-list'

export function createCsvAppraisers(data) {
  return {
    type: CREATE_CSV_APPRAISERS,
    data,
  }
}

export function updateAppraiserExclusionaryList(data) {
  return {
    type: UPDATE_APPRAISER_EXCLUSIONARY_LIST,
    data,
  }
}
