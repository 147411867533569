import React from 'react'

const identity = value => (value)

/*
 * The identity function ensures form values never get set to null,
 * but rather, empty strings.
 *
 * See https://github.com/final-form/react-final-form/issues/130
 */
export default props => <props.Component parse={identity} {...props}/>
