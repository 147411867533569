import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { connectResource } from '../../common/utils/resource'
import { apiToReadable, checkPermission } from '../../common/utils/helpers'
import Rephrase from '../../common/utils/Rephrase'
import { ModalHeader, ModalBody, ModalFooter, ModalCloser } from '../../common/modals/antd/ModalComponents'
import {
  Button,
  Row,
  Col,
  Card,
  CardBody,
} from 'reactstrap'
import { selectCustomReviewRevisionRequestText } from '../../store/reducers/user/userSelectors'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useRevisionMetadata } from '@app/common/hooks/useRevisionMetadata'
import { OverlayLoader } from '@app/common/layout/components/OverlayLoader'
import RevisionWithSteps, { CreateRevisionButton } from './RevisionWithSteps'
import RevisionLastStep from './RevisionLastStep'

export const revisionCategories = [
  'Subject property information incorrect',
  'Updated borrower information',
  'Loan information changed',
  'Comparable(s) address incorrect',
  'Comparable(s) selection',
  'Missing/incorrect photos',
  'Missing/incorrect adjustments',
  'Sketch does not match indicated features',
  'FHA requirements not met',
  'Inaccurate or contradictory comments',
  'Missing commentary',
]

class RevisionModal extends Component {
  modalApi = this.props.modalApi

  state = {
    title: this.props.revision ? this.props.revision.title : '',
    responsibleParty: this.props.revision ? this.props.revision.responsible_party : 'appraiser',
    categories: (this.props.revision && this.props.revision.categories)
      ? this.props.revision.categories.map((cat) => {
        if (cat.category_description && cat.category_description !== '') {
          return cat.category_description
        }
        return cat.category_type
      }) : [],
    categoryOptions: revisionCategories.map((category) => ({ value: category, label: category })),
    isEditing: false,
    selectAll: true,
    isSubmitting: false,
  }

  constructor(props) {
    super(props)

    this.state.text = props.revision?.text ||
      this.parseReviewRevisionRequestText(props.custom_review_revision_request_text)
  }

  parseReviewRevisionRequestText = (custom_review_revision_request_text, default_text) => {
    if (!custom_review_revision_request_text && !default_text) {
      return ''
    }

    return (custom_review_revision_request_text || default_text) + '\n\n'
  }

  updateRequestTitle = (title) => {
    this.setState({ title })
  }

  updateRequestText = (text) => {
    this.setState({ text })
  }

  handleResponsiblePartyChange = (selected) => {
    this.setState({ responsibleParty: selected })
  }

  handleRevisionCategoryChange = (categories) => {
    this.setState({ categories })
  }

  onSubmit = () => {
    const {
      modalApi,
      order_id,
      refetchOrder,
      revision,
      revisionsEndpoint,
      flags,
    } = this.props

    const {
      categories,
      responsibleParty,
      text,
      title,
    } = this.state

    const data = {
      categories,
      order_id,
      responsible_party: responsibleParty,
      text,
      title,
    }

    if (flags?.revisionCategories) {
      data.title = categories.join(', ')
    }

    if (revision) {
      data.id = revision.id
      data.lender_resolved = revision.lender_resolved
      this.setState({ isSubmitting: true })
      revisionsEndpoint.put(data).then(_ => {
        this.setState({ isSubmitting: false })
        modalApi.confirm()
        refetchOrder()
      })
    } else {
      this.setState({ isSubmitting: true })
      revisionsEndpoint.create(data).then(_ => {
        this.setState({ isSubmitting: false })
        modalApi.confirm()
        refetchOrder()
      })
    }
  }

  setLenderResolved = (lender_resolved) => {
    const {
      revisionsEndpoint,
      revision,
      order_id,
      modalApi,
      refetchOrder,
    } = this.props
    const data = {
      order_id,
      lender_resolved,
      id: revision.id,
    }
    revisionsEndpoint.put(data).then(_ => {
      modalApi.confirm()
      refetchOrder()
    })
  }

  setIsAppraiserAccessible = (is_accessible_to_appraiser) => {
    const {
      revisionsEndpoint,
      revision,
      order_id,
      modalApi,
      refetchOrder,
    } = this.props

    const data = {
      order_id,
      is_accessible_to_appraiser,
      id: revision.id,
    }

    revisionsEndpoint.put(data).then(_ => {
      modalApi.confirm()
      refetchOrder()
    })
  }

  onCancel = () => {
    const {
      revision,
      modalApi,
    } = this.props
    if (revision) {
      this.setState({ isEditing: false })
    } else {
      modalApi.cancel()
    }
  }

  startEditing = () => {
    this.setState({ isEditing: true })
  }

  render() {
    const {
      flags,
      modalApi,
      reviewNotes,
      revision,
      job_type,
      is_amc_lender,
      isLoadingMetadata,
      reggoraReview,
      ucdp,
      ead,
    } = this.props
    const {
      text,
      title,
      isEditing,
      categories,
      categoryOptions,
      responsibleParty,
      isSubmitting,
    } = this.state

    if (this.props.is_amc_lender && reviewNotes.text && !this.state.hasReviewNotesSet) {
      this.setState((state) => ({ hasReviewNotesSet: true, text: (state.text || '') + reviewNotes.text }))
    }

    if (!revision) {
      // Case: Creating new revision from inside the submissions tab
      const reggoraReviewResults = reggoraReview.results
      const ucdpResults = ucdp.results
      const eadResults = ead.results

      return (
        <OverlayLoader isLoading={isLoadingMetadata || isSubmitting}>
          <RevisionWithSteps
            modalApi={modalApi}
            initialReggoraReviewData={reggoraReviewResults}
            initialUcdpData={ucdpResults}
            initialEadData={eadResults}
            parseReviewRevisionRequestText={this.parseReviewRevisionRequestText}
            customRevisionRequestText={this.props.custom_review_revision_request_text}
            flags={flags}
            existingRevision={revision}
            isEditing={isEditing}
            categories={categories}
            categoryOptions={categoryOptions}
            useCategories={flags?.revisionCategories}
            onSubmit={this.onSubmit}
            handleRevisionCategoryChange={this.handleRevisionCategoryChange}
            job_type={job_type}
            is_amc_lender={is_amc_lender}
            responsibleParty={responsibleParty}
            handleResponsiblePartyChange={this.handleResponsiblePartyChange}
            title={title}
            text={text}
            updateRequestTitle={this.updateRequestTitle}
            updateRequestText={this.updateRequestText}
          />
        </OverlayLoader>
      )
    } else if (revision && !isEditing) {
      // Case: Has existing revision but not allowed to edit
      return (
        <ViewRevisionModal
          is_amc_lender={this.props.is_amc_lender}
          job_type={this.props.job_type}
          modalApi={modalApi}
          revision={revision}
          setIsAppraiserAccessible={this.setIsAppraiserAccessible}
          setLenderResolved={this.setLenderResolved}
          startEditing={this.startEditing}
        />
      )
    } else {
      // Case: editing an existing revision
      // In this case, we just show the basic revision form, without the Steps for Review, UCDP, EAD
      // This will not be live until we officially migrate the revisions tab Request Revision Modal to this new modal
      let modalHeaderTitle = 'Create Revision'
      let submitText = 'Create'
      if (revision && isEditing) {
        modalHeaderTitle = 'Edit Revision'
        submitText = 'Save'
      }

      return (
        <>
          <ModalHeader title={modalHeaderTitle}/>
          <ModalCloser onClose={modalApi.cancel}/>
          <ModalBody>
            <RevisionLastStep
              flags={flags}
              title={title}
              text={text}
              categories={categories}
              categoryOptions={categoryOptions}
              useCategories={flags?.revisionCategories}
              handleRevisionCategoryChange={this.handleRevisionCategoryChange}
              job_type={job_type}
              is_amc_lender={is_amc_lender}
              responsibleParty={responsibleParty}
              handleResponsiblePartyChange={this.handleResponsiblePartyChange}
              updateRequestTitle={this.updateRequestTitle}
              updateRequestText={this.updateRequestText}
            />
          </ModalBody>
          <ModalFooter>
            <CreateRevisionButton
              hasCategories={Boolean(categories?.length)}
              isCategoriesRequired={flags?.revisionCategories && flags.disableCustomRevisionCategories}
              onClick={this.onSubmit}
            >
              {submitText}
            </CreateRevisionButton>
          </ModalFooter>
        </>
      )
    }
  }
}

const ViewRevisionModal = ({
  is_amc_lender,
  job_type,
  modalApi,
  revision,
  setIsAppraiserAccessible,
  setLenderResolved,
  startEditing,
}) => {
  const canCreateEditRevisions = checkPermission('revisions_create_edit')

  return (<>
    <ModalHeader title={'View Revision'} className="revision-modal revision-modal-header" />
    <ModalCloser onClose={modalApi.cancel} />
    <ModalBody className="revision-modal">
      <Row className='mb-2 align-items-center'>
        <Col xs='9'>
          <h5 className='mb-0'>{revision.title}</h5>
        </Col>
        <Col xs='3'>
          {canCreateEditRevisions && (
            <div className='d-flex justify-content-end'>
              <Button data-testid='revision-edit' disabled={revision.lender_resolved} onClick={startEditing}>Edit</Button>
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col xs='12'>
          <Card>
            <CardBody className='revision-text'>
              {revision.text}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {revision.responsible_party && (job_type !== 'amc' || (job_type === 'amc' && is_amc_lender)) ? <Row>
        <Col xs='12'>
          <p>Responsible Party: <Rephrase>{apiToReadable(revision.responsible_party)}</Rephrase></p>
        </Col>
      </Row> : null}
      {canCreateEditRevisions && (
        <Row>
          <Col xs='12' className='d-flex justify-content-end'>
            {revision.lender_resolved ? (
              <Button data-testid='lender-unresolve' onClick={() => setLenderResolved(false)}>Unresolve</Button>
            ) : (
              <Button data-testid='lender-resolve' onClick={() => setLenderResolved(true)}>Mark Resolved</Button>
            )}
          </Col>
          {job_type === 'amc' && is_amc_lender && !revision.is_accessible_to_appraiser && !revision.lender_resolved
            ? <Col xs='12' className='d-flex justify-content-end'>
              <Button data-testid='appraiser-accessible' onClick={() => setIsAppraiserAccessible(true)} color='info' style={{ marginTop: '10px' }}>Send Revision</Button>
            </Col>
            : null
          }
        </Row>
      )}
    </ModalBody>
  </>)
}


const RevisionModalWithFlags = Component => {
  const FlagsComponent = (props) => {
    const flags = useFlags() || {}
    const { revisionMetadata, isLoading } = useRevisionMetadata({
      isHookEnabled: true,
      orderId: props.order_id,
      submissionVersion: props.submissionVersion,
    })
    const reviewNotesTxt = revisionMetadata?.reggora_review_notes?.[0]?.body || ''
    const reggoraReviewResults = revisionMetadata?.reggora_review_results
    const ucdpResults = revisionMetadata?.ucdp_results
    const eadResults = revisionMetadata?.ead_results

    return <Component
      flags={flags}
      reviewNotes={{ text: reviewNotesTxt }}
      reggoraReview={{ results: reggoraReviewResults }}
      ucdp={{ results: ucdpResults }}
      ead={{ results: eadResults }}
      isLoadingMetadata={isLoading}
      {...props}
    />
  }
  return FlagsComponent
}

export default compose(
  connect(selectCustomReviewRevisionRequestText),
  connectResource({
    prefetch: false,
    namespace: 'revisionsEndpoint',
    endpoint: 'order/revisions',
    successMessage: {
      POST: 'Your revision request has been submitted.',
      PUT: 'This revision request has been updated.',
    },
    apiVersion: 2,
  }),
)(RevisionModalWithFlags(RevisionModal))
