import React, { useState } from 'react'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Tooltip, Tag } from 'antd'
import { Button } from 'reactstrap'
import ModalTrigger from '../../common/modals/ModalTrigger'
import AntdModalTrigger from '../../common/modals/antd/ModalTrigger'
import LegacyRevisionModal from './LegacyRevisionModal'
import Table from '../../common/tables/Table'
import Column from '../../common/tables/Column'
import { checkPermission, utcToLocal } from '../../common/utils/helpers'
import { useApiRequest } from '../../common/hooks'
import ModalROVContainer from './ModalROVContainer'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { OverlayLoader } from '@app/common/layout/components/OverlayLoader'

const RevisionsTab = (props) => {
  const {
    orderData,
    is_amc_lender,
    refreshOrder,
    viewRov,
    downloadRov,
    lastSubmission,
  } = props

  const [isLoading, setIsLoading] = useState(false)

  const flags = useFlags()
  const useRevisionCategories = flags?.revisionCategories

  const apiRequest = useApiRequest()
  const canCreateEditRevisions = checkPermission('revisions_create_edit')

  const toggleRevisionResolved = async (revision_id, lender_resolved) => {
    setIsLoading(true)
    await apiRequest({
      endpoint: 'order/revisions',
      method: 'PUT',
      body: {
        order_id: orderData.id,
        id: revision_id,
        lender_resolved,
      },
      successMessage: 'This revision request has been updated.',
    })
    setIsLoading(false)
    refreshOrder()
  }

  const setRevisionIsAppraiserAccessible = async (revision_id, is_accessible_to_appraiser) => {
    setIsLoading(true)
    await apiRequest({
      endpoint: 'order/revisions',
      method: 'PUT',
      body: {
        order_id: orderData.id,
        id: revision_id,
        is_accessible_to_appraiser,
      },
      successMessage: 'This revision request has been updated.',
    })
    setIsLoading(false)
    refreshOrder()
  }

  const sendRovToAppraiser = async (rovId, submissionVersion) => {
    setIsLoading(true)
    await apiRequest({
      endpoint: 'order/rov',
      method: 'POST',
      body: {
        order_id: orderData.id,
        rov_id: rovId,
        submission_version: submissionVersion,
      },
      successMessage: 'This ROV request has been sent to the appraiser.',
    })
    setIsLoading(false)
    refreshOrder()
  }

  const checkRequestedRov = (lastSubmission, orderData) => {
    const revisions = orderData.revisions
    if (lastSubmission) {
      const rovRequested =
        revisions &&
        revisions.find((revision) => {
          return (
            revision.submission &&
            revision.submission === lastSubmission.version &&
            revision.title === 'Reconsideration of Value'
          )
        })
      return rovRequested
    }
    return null
  }

  const getFilteredAMCRevision = (revisions, is_amc_lender) => {
    // If current_user is NOT AMP, hide revision request
    // from the revisions tab if it was requested by Reggora AMC / AMP
    let filteredRevisionList = revisions
    if (!is_amc_lender && revisions) {
      filteredRevisionList = revisions.filter((r) => {
        return r.sender_type !== 'amc'
      })
    }
    return filteredRevisionList
  }

  const renderRequestButtons = () => (
    <>
      {canCreateEditRevisions && (
        <AntdModalTrigger
          modal={{
            component: LegacyRevisionModal,
            maskClosable: false,
          }}
          is_amc_lender={is_amc_lender}
          job_type={orderData.job_type}
          order_id={orderData.id}
          refetchOrder={refreshOrder}
          useCategories={useRevisionCategories}
        >
          <Button color="light" className="mb-3 btn-light float-right">
            Request Revision
          </Button>
        </AntdModalTrigger>
      )}
      {checkPermission('submission_request_reconsideration') &&
          orderData.job_type !== 'review' &&
          lastSubmission && (
        <Tooltip
          trigger={
            checkRequestedRov(lastSubmission, orderData) ? 'hover' : ''
          }
          placement="top"
          title="ROV already submitted for latest submission"
        >
          <span
            className="float-right"
            style={{
              cursor: checkRequestedRov(lastSubmission, orderData)
                ? 'not-allowed'
                : 'pointer',
            }}
          >
            <ModalTrigger
              backdrop='static'
              component={ModalROVContainer}
              modalClassName="modal-primary modal-lg"
              order_id={orderData.id}
              submission_version={lastSubmission.version}
              {...props}
            >
              <Button
                className="mb-3 mr-2 btn-light float-right"
                color="light"
                disabled={checkRequestedRov(lastSubmission, orderData)}
                style={{
                  pointerEvents: checkRequestedRov(
                    lastSubmission,
                    orderData
                  )
                    ? 'none'
                    : 'auto',
                }}
                type="button"
              >
                {' '}
                    Request Reconsideration Of Value
              </Button>
            </ModalTrigger>
          </span>
        </Tooltip>
      )}
    </>
  )

  let revisions = orderData.revisions
  if (orderData && orderData.revisions && orderData.job_type === 'amc') {
    revisions = getFilteredAMCRevision(orderData.revisions, is_amc_lender)
  }
  if (orderData.job_type === 'amc' && is_amc_lender) {
    revisions = (
      <div>
        {renderRequestButtons()}
        <Table striped list={{ results: orderData.revisions || [] }}>
          <Column
            title="Resolved"
            width="10%"
            field="lender_resolved"
            format={(value) => {
              return value ? (
                <CheckCircleOutlined className="green" />
              ) : (
                <CloseCircleOutlined className="red" />
              )
            }}
          />
          <Column
            width="15%"
            title="Revision Request"
            field="title"
            format={(value, item) => {
              if (item.submission) {
                return `${value} Submission ${item.submission} `
              }
              return value
            }}
          />
          <Column
            width="15%"
            title="Contents Preview"
            field="text"
            format={(value) => {
              const limit = 40
              if (!value) {
                return ''
              } else if (value.length < limit) {
                return value
              } else {
                return `${value.slice(0, limit - 3)}...`
              }
            }}
          />
          <Column
            width="15%"
            title="Date of Request"
            field="created"
            format={(value) => {
              return utcToLocal(value).format('LLL')
            }}
          />
          <Column
            width="30%"
            format={(value, item) => {
              let text = 'Mark Resolved'
              let resolved_value = true
              if (item.lender_resolved) {
                text = 'Mark Unresolved'
                resolved_value = false
              }
              if (item.attached_documents) {
                return (
                  <div className="revisions-tab-buttons">
                    <Button
                      onClick={() => {
                        const doc = item.attached_documents.documents.find(
                          (doc) =>
                            doc.document_name ===
                              'Reconsideration of Value.pdf' ||
                            doc.document_name === 'reconsideration_of_value.pdf'
                        )
                        if (doc) {
                          viewRov(doc)
                        }
                      }}
                    >
                      View
                    </Button>
                    <Button
                      onClick={() => {
                        const doc = item.attached_documents.documents.find(
                          (doc) =>
                            doc.document_name ===
                              'Reconsideration Of Value.pdf' ||
                            doc.document_name === 'reconsideration_of_value.pdf'
                        )
                        if (doc) {
                          downloadRov(doc)
                        }
                      }}
                    >
                      Download
                    </Button>
                    {canCreateEditRevisions && (
                      <Button
                        onClick={() =>
                          toggleRevisionResolved(item.id, resolved_value)
                        }
                      >
                        {text}
                      </Button>
                    )}

                    {/* 3 cases:
                    1) lender submitted and not approved => show "approve" button
                    2) lender submitted and approved => show tag in color A with text "Lender Revision"
                    3) amc submitted (approved) => show tag in color B with text "AMC Revision" */}
                    {canCreateEditRevisions && (
                      item.sender_type === 'lender' && !item.is_accessible_to_appraiser && (
                        <Button
                          color="info"
                          onClick={() =>
                            setRevisionIsAppraiserAccessible(item.id, true)
                          }
                        >
                          Send Revision
                        </Button>
                      )
                    )}
                    {item.sender_type === 'lender' &&
                      item.is_accessible_to_appraiser && (
                      <Tag color="#2db7f5">Lender Revision</Tag>
                    )}
                    {item.sender_type === 'amc' && (
                      <Tag color="#008962">AMC Revision</Tag>
                    )}
                  </div>
                )
              } else if (item.type === 'reconsideration_of_value') {
                // Case: ROVs without attached documents (AMP can still edit these)
                return (
                  <div className="revisions-tab-buttons">
                    <ModalTrigger
                      backdrop='static'
                      component={ModalROVContainer}
                      order_id={orderData.id}
                      submission_version={lastSubmission.version}
                      modalClassName="modal-primary modal-lg"
                      revision={item}
                      {...props}
                    >
                      <Button>View</Button>
                    </ModalTrigger>
                    <Button
                      onClick={() =>
                        toggleRevisionResolved(item.id, resolved_value)
                      }
                    >
                      {text}
                    </Button>
                    {is_amc_lender && !item.is_accessible_to_appraiser && (
                      <Button
                        color="info"
                        onClick={() =>
                          sendRovToAppraiser(item.id, lastSubmission.version)
                        }
                      >
                        Send ROV
                      </Button>
                    )}
                  </div>
                )
              }
              return (
                <div className="revisions-tab-buttons">
                  <AntdModalTrigger
                    modal={{
                      component: LegacyRevisionModal,
                    }}
                    useCategories={useRevisionCategories}
                    revision={item}
                    order_id={orderData.id}
                    refetchOrder={refreshOrder}
                    is_amc_lender={is_amc_lender}
                    job_type={orderData.job_type}
                  >
                    <Button>View</Button>
                  </AntdModalTrigger>
                  {canCreateEditRevisions && (
                    <Button
                      onClick={() =>
                        toggleRevisionResolved(item.id, resolved_value)
                      }
                    >
                      {text}
                    </Button>
                  )}
                  {/* 3 cases:
                  1) lender submitted and not approved => show "approve" button
                  2) lender submitted and approved => show tag in color A with text "Lender Revision"
                  3) amc submitted (approved) => show tag in color B with text "AMC Revision" */}
                  {canCreateEditRevisions && (
                    item.sender_type === 'lender' &&
                    item.lender_resolved === null &&
                    !item.is_accessible_to_appraiser && (
                      <Button
                        color="info"
                        onClick={() =>
                          setRevisionIsAppraiserAccessible(item.id, true)
                        }
                      >
                        Send Revision
                      </Button>
                    )
                  )}
                  {item.sender_type === 'lender' &&
                    item.is_accessible_to_appraiser && (
                    <Tag color="#2db7f5">Lender Revision</Tag>
                  )}
                  {item.sender_type === 'amc' && (
                    <Tag color="#008962">AMC Revision</Tag>
                  )}
                </div>
              )
            }}
          />
        </Table>
      </div>
    )
  } else {
    revisions = (
      <div>
        {renderRequestButtons()}
        <Table striped list={{ results: revisions || [] }}>
          <Column
            title="Resolved"
            width="10%"
            field="lender_resolved"
            format={(value) => {
              return value ? (
                <CheckCircleOutlined className="green" />
              ) : (
                <CloseCircleOutlined className="red" />
              )
            }}
          />
          <Column
            title="Revision Request"
            field="title"
            format={(value, item) => {
              if (item.submission) {
                return `${value} Submission ${item.submission} `
              }
              return value
            }}
          />
          <Column
            title="Contents Preview"
            field="text"
            format={(value) => {
              const limit = 40
              if (!value) {
                return ''
              } else if (value.length < limit) {
                return value
              } else {
                return `${value.slice(0, limit - 3)}...`
              }
            }}
          />
          <Column
            title="Date of Request"
            field="created"
            format={(value) => {
              return utcToLocal(value).format('LLL')
            }}
          />
          <Column
            format={(value, item) => {
              let text = 'Mark Resolved'
              let resolved_value = true
              if (item.lender_resolved) {
                text = 'Mark Unresolved'
                resolved_value = false
              }
              if (item.attached_documents) {
                return (
                  <div className="revisions-tab-buttons">
                    <Button
                      onClick={() => {
                        const doc = item.attached_documents.documents.find(
                          (doc) =>
                            doc.document_name ===
                              'Reconsideration Of Value.pdf' ||
                            doc.document_name === 'reconsideration_of_value.pdf'
                        )
                        if (doc) {
                          viewRov(doc)
                        }
                      }}
                    >
                      View
                    </Button>
                    <Button
                      onClick={() => {
                        const doc = item.attached_documents.documents.find(
                          (doc) =>
                            doc.document_name ===
                              'Reconsideration Of Value.pdf' ||
                            doc.document_name === 'reconsideration_of_value.pdf'
                        )
                        if (doc) {
                          downloadRov(doc)
                        }
                      }}
                    >
                      Download
                    </Button>
                    {canCreateEditRevisions && (
                      <Button
                        onClick={() =>
                          toggleRevisionResolved(item.id, resolved_value)
                        }
                      >
                        {text}
                      </Button>
                    )}
                  </div>
                )
              } else if (item.type === 'reconsideration_of_value') {
                // Case: ROVs without attached documents (AMP can still edit these). Lender cannot edit but can still view
                return (
                  <div className="revisions-tab-buttons">
                    <ModalTrigger
                      backdrop='static'
                      component={ModalROVContainer}
                      order_id={orderData.id}
                      submission_version={lastSubmission.version}
                      modalClassName="modal-primary modal-lg"
                      revision={item}
                      {...props}
                    >
                      <Button>View</Button>
                    </ModalTrigger>
                    <Button
                      onClick={() =>
                        toggleRevisionResolved(item.id, resolved_value)
                      }
                    >
                      {text}
                    </Button>
                  </div>
                )
              }
              return (
                <div className="revisions-tab-buttons">
                  <AntdModalTrigger
                    modal={{
                      component: LegacyRevisionModal,
                    }}
                    useCategories={useRevisionCategories}
                    revision={item}
                    order_id={orderData.id}
                    refetchOrder={refreshOrder}
                    is_amc_lender={is_amc_lender}
                    job_type={orderData.job_type}
                  >
                    <Button>View</Button>
                  </AntdModalTrigger>
                  {canCreateEditRevisions && (
                    <Button
                      onClick={() =>
                        toggleRevisionResolved(item.id, resolved_value)
                      }
                    >
                      {text}
                    </Button>
                  )}
                </div>
              )
            }}
          />
        </Table>
      </div>
    )
  }

  return (
    <>
      {isLoading ? <OverlayLoader isLoading={true}>{revisions}</OverlayLoader> : revisions}
    </>
  )
}

export default RevisionsTab
