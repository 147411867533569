import React, { Component } from 'react'
import { checkPermission } from '../../common/utils/helpers'
import { Button } from 'reactstrap'
import axios from 'axios'
import FileSaver from 'file-saver'

export const downloadFile = (url, fileName) => {
  const token = localStorage.getItem('reggora_lender_auth_token')
  axios({
    headers: { Authorization: `bearer ${token}`, 'Cache-Control': 'no-cache' },
    url: url,
    method: 'GET',
    responseType: 'blob', // important
  }).then((response) => {
    FileSaver.saveAs(new Blob([response.data]), fileName)
  })
}

export default class PaymentSummary extends Component {
    downloadPaymentSummary = () => {
      const { id, loan_file } = this.props.orderData
      downloadFile(`${API2_URL}order/${id}/payments/pdf`, `#${loan_file ? loan_file.number : ''} Payment Summary.pdf`)
    }

    render() {
      const { isAmcLender, orderData } = this.props
      const {
        fields_awaiting_consumers,
        payment_summary: {
          total_due,
          total_paid,
          total_refunded,
          total_sent,
        },
      } = orderData

      const fee_color = total_due > 0 ? 'text-danger' : ''


      return (
        <div className='card mb-3 payment-summary'>
          <div className='payment-summary_totals'>
            <h5>Total Due: <span className={fee_color}>
              {fields_awaiting_consumers && (
                fields_awaiting_consumers.includes('integration_fee') ||
                fields_awaiting_consumers.includes('order_created')
              ) ? 'Pending' : '$' + total_due.toFixed(2)}</span>
            </h5>
            <h5>Total Paid: ${total_paid.toFixed(2)}</h5>
            <h5>Total Refunded: ${total_refunded.toFixed(2)}</h5>
            {
              checkPermission('payments_accounts_payable_view') &&
              (orderData.job_type !== 'amc' || isAmcLender) &&
              <h5>Total Sent: ${total_sent.toFixed(2)}</h5>
            }
          </div>
          <Button
            className='btn-sm payment-summary_download-button'
            onClick={this.downloadPaymentSummary}
            disabled={this.props.disabled || !checkPermission('payments_accounts_payable_view')}
          >
            Download Payment Summary
          </Button>
        </div>
      )
    }
}
