/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { connectResource } from '../../common/utils/resource'
import { ModalHeader, ModalBody, FormGroup, Col, ModalFooter, Button, Row, Input } from 'reactstrap'
import Loader from '../../common/layout/components/Loader'


class ModalEditLoanFileVAFields extends Component {
  state = {
    formValues: {},
  }

  componentDidMount() {
    const {
      loanData,
    } = this.props

    const initialVaFields = {}

    Object.entries(loanData).forEach(([key, value]) => {
      if (key.indexOf('va_') === 0) {
        initialVaFields[key] = value
      }
    })

    this.setState({
      formValues: initialVaFields,
    })
  }

  onSubmit = () => {
    this.props.vaUpdate.put({ data: this.state.formValues }).then(_ => {
      this.props.onHide()
    })
  }

  handleSelectChange = (value, field) => {
    this.setState({
      formValues: {
        ...this.state.formValues,
        [field]: value,
      },
    })
  }

  handleChange = (e) => {
    this.setState({
      formValues: {
        ...this.state.formValues,
        [e.target.name]: e.target.value,
      },
    })
  }

  fieldIsValid = (fieldName, value) => {
    const {
      loanData,
    } = this.props
    const fieldValidation = loanData.validation_fields_for_va
    let isValid = true
    if (!value) {
      isValid = false
    } else {
      if (fieldName in fieldValidation) {
        isValid = false
        for (const allowedValue of fieldValidation[fieldName]) {
          if (String(value) === String(allowedValue.value)) {
            isValid = true
          }
        }
      }
    }
    return isValid
  }

  isValid = () => {
    const {
      formValues,
    } = this.state
    let isValid = true
    Object.entries(formValues).forEach(([key, value]) => {
      if (!this.fieldIsValid(key, value)) {
        isValid = false
      }
    })
    return isValid
  }

  render() {
    const {
      vaUpdate,
      onHide,
      loanData,
    } = this.props
    const {
      formValues,
    } = this.state

    const fieldValidation = loanData.validation_fields_for_va

    return (
      <Fragment>
        <ModalHeader toggle={onHide}>Edit VA Fields</ModalHeader>
        <ModalBody>
          <Row>
            {this.props.vaUpdate.isLoading ? <div className='m-5'><Loader /></div> : <Col xs='12'>
              {Object.entries(formValues).map(([fieldName, value], index) => {
                let field
                if (fieldName in fieldValidation) {
                  let options = [<option key={-1} value=''></option>]
                  options = [options, ...fieldValidation[fieldName].map(({ label, value }, index) => {
                    return <option key={index} value={value}>{label}</option>
                  })]
                  field = <Input
                    type='select'
                    name={fieldName}
                    value={value}
                    onChange={this.handleChange}
                  >
                    {options}
                  </Input>
                } else {
                  field = <Input
                    data-test="loan-num-input"
                    value={value}
                    onChange={this.handleChange}
                    name={fieldName}
                    required
                  />
                }
                return <FormGroup row key={index} className="mt-2 mb-4">
                  <Col xs="6">
                    <h6 className={`${!this.fieldIsValid(fieldName, value) ? 'text-danger' : ''}`}>{loanData.loan_va_fields_to_readable[fieldName]}:</h6>
                    {field}
                  </Col>
                </FormGroup>
              }
              )}
            </Col>}
          </Row>
        </ModalBody>
        <ModalFooter className="border-0">
          <Button disabled={vaUpdate.isLoading} onClick={onHide}>Cancel</Button>
          <Button disabled={vaUpdate.isLoading} onClick={this.onSubmit} data-test="submit-button" color='primary'>Save</Button>
        </ModalFooter>
      </Fragment>)
  }
}

export default compose(
  connectResource({
    namespace: 'vaUpdate',
    endpoint: 'loans/vaData/:id',
    prefetch: false,
    apiVersion: 2,
    successMessage: {
      PUT: 'Your loan has been updated.',
    },
  }),
)(ModalEditLoanFileVAFields)
