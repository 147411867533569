import React from 'react'
import { FeeHistory } from './FeeHistory'
import { Empty } from 'antd'
import { Alert } from 'reactstrap'


const InternalFeatureBanner = () => {
  return (
    <Alert color="warning">
      <p>
      Internal Reggora Feature -
      the Fee History tab is only visible to Reggora employees when using the Helpdesk impersonation link.
      The Fee History timeline tracks the following events:
      </p>
      <ul>
        <li>Product changes (lender adds, removes or updates the amount for a product in the Order Modal)</li>
        <li>Additional fee changes (lender adds, removes or updates the additional fees in the Order Modal)</li>
        <li>Reggora System overrides - Vendor, Geo, Vendor Geo, and Loan File Borrower Amount overrides </li>
      </ul>
    </Alert>
  )
}

export default function OrderFeeHistoryTab(props) {
  const { orderFeeChanges } = props

  if (orderFeeChanges.length === 0) {
    return (
      <>
        <InternalFeatureBanner />
        <Empty />
      </>
    )
  }

  return (
    <>
      <InternalFeatureBanner />
      <FeeHistory orderFeeChanges={orderFeeChanges} />
    </>
  )
}
