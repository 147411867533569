import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import API, { API_VERSION, REQUEST_METHOD } from '../../../api'
import STATUS from '../../../common/constants/reducer_statuses'

export const initialState = {
  orderId: null,
  conversations: [],
  conversation: null,
  team_conversation: null,
  amc_conversation: null,
  review_conversation: null,
  current_vendor_messages: null,
  status: STATUS.IDLE,
  error: null,
}


export const fetchConversations = createAsyncThunk('conversation/fetchConversations', async (orderId, { rejectWithValue }) => {
  try {
    const conversations = await API(`v1/conversation/order/${orderId}`, API_VERSION.SERVICE).request(REQUEST_METHOD.GET, {}, {})
    return {
      conversations,
      orderId,
    }
  } catch (err) {
    return rejectWithValue(err.errors._error)
  }
})


export const fetchIndividualConversation = createAsyncThunk('conversation/fetchIndividualConversation', async ({ id, type }, { rejectWithValue }) => {
  try {
    const conversation = await API(`v1/conversation/${id}`, API_VERSION.SERVICE).request(REQUEST_METHOD.GET, {}, {})
    return {

      conversation,
      type,
    }
  } catch (err) {
    return rejectWithValue(err.errors._error)
  }
})


export const fetchIndividualConversationV2 = createAsyncThunk('conversation/fetchIndividualConversationV2', async ({ id, type }, { rejectWithValue }) => {
  try {
    const conversation = await API(`conversation/v2/conversation/${id}`, API_VERSION.SERVICE).request(REQUEST_METHOD.GET, {}, {})
    return {
      conversation,
      type,
    }
  } catch (err) {
    return rejectWithValue(err.errors._error)
  }
})

const submitMessageApproval = async (messageRecord, { rejectWithValue }) => {
  const { conversationId, messageId, isApproved } = messageRecord
  try {
    return await API(`conversation/v2/${conversationId}/messages/${messageId}`, API_VERSION.SERVICE).put({ is_approved: isApproved })
  } catch (err) {
    return rejectWithValue(err)
  }
}

const postNewMessage = async ({ conversationId, messageRecord }, { rejectWithValue }) => {
  try {
    return await API(`conversation/v2/${conversationId}/messages`, API_VERSION.SERVICE).post(messageRecord)
  } catch (err) {
    return rejectWithValue(err)
  }
}

export const approveMessage = createAsyncThunk('conversation/approveMessage', submitMessageApproval)
export const submitMessage = createAsyncThunk('conversation/submitMessage', postNewMessage)


const conversationSlice = createSlice({
  name: 'conversation',
  initialState,
  reducers: {
    resetConversation: () => {
      return initialState
    },
    newMessage: (state, action) => {
      const conversation = state[action.payload.conversationType]
      const orderId = action.payload.orderId
      if (!conversation || state.orderId !== orderId) { return }

      const incrementCount = action.payload.increment_unread || 0

      state[action.payload.conversationType] = {
        ...conversation,
        unread_messages: parseInt(conversation.unread_messages) + incrementCount,
        messages: [
          ...conversation.messages,
          action.payload.message,
        ],
      }
    },
    updateMessage: (state, action) => {
      const conversation = state[action.payload.conversationType]
      if (!conversation) { return }

      state[action.payload.conversationType] = {
        ...conversation,
        messages: conversation.messages.map((message) => {
          if (message.message_id === action.payload.messageId) {
            return {
              ...message,
              ...action.payload.message,
            }
          }
          return message
        }),
      }
    },
    setUnreadCount: (state, action) => {
      if (!state[action.payload.type]) { return }
      state[action.payload.type].unread_messages = action.payload.unread_messages
    },
  },
  extraReducers: {
    [fetchConversations.pending]: state => {
      state.status = STATUS.LOADING
    },
    [fetchConversations.fulfilled]: (state, action) => {
      state.status = STATUS.SUCCEEDED
      // Add any fetched posts to the array
      state.conversations = action.payload.conversations || []
      state.orderId = action.payload.orderId
    },
    [fetchConversations.rejected]: (state, action) => {
      state.status = STATUS.FAILED
      state.error = action.error.message
    },
    [fetchIndividualConversation.pending]: state => {
      state.status = STATUS.LOADING
    },
    [fetchIndividualConversation.fulfilled]: (state, action) => {
      state.status = STATUS.SUCCEEDED
      // Add any fetched posts to the array
      state[action.payload.type] = action.payload.conversation || null
    },
    [fetchIndividualConversationV2.pending]: state => {
      state.status = STATUS.LOADING
    },
    [fetchIndividualConversationV2.fulfilled]: (state, action) => {
      state.status = STATUS.SUCCEEDED
      // Add any fetched posts to the array
      state[action.payload.type] = action.payload.conversation || null
    },
    // [fetchIndividualConversation.rejected]: (state, action) => {
    //   state.status = STATUS.FAILED
    //   state.error = action.error.message
    // }
    [approveMessage.pending]: state => {
      state.status = STATUS.LOADING
    },
    [approveMessage.fulfilled]: (state, action) => {
      state.status = STATUS.SUCCEEDED
    },
    [approveMessage.rejected]: (state, action) => {
      state.status = STATUS.FAILED
      state.error = action?.error?.message
    },
    [submitMessage.pending]: state => {
      state.status = STATUS.LOADING
    },
    [submitMessage.fulfilled]: (state, action) => {
      state.status = STATUS.SUCCEEDED
    },
    [submitMessage.rejected]: (state, action) => {
      state.status = STATUS.FAILED
      state.error = action?.error?.message
    },
  },
})


export const { newMessage, setUnreadCount, resetConversation, updateMessage } = conversationSlice.actions

export default conversationSlice.reducer
