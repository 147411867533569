import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col } from 'reactstrap'

const LoanOrderBreadCrumbs = (props) => {
  const {
    linkTo,
    linkText,
    loanNumber,
  } = props

  return (
    <Row className="justify-content-between align-items-center">
      <Col md="5">
        <ol className="breadcrumb" style={{ marginBottom: 0 }}>
          <Link className="breadcrumb-item breadcrumb-link" to={linkTo}>
            {linkText}
          </Link>
          <li className="breadcrumb-item">Loan #{loanNumber}</li>
        </ol>
      </Col>
    </Row>
  )
}

export default LoanOrderBreadCrumbs
