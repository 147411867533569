import { Input, InputGroup, InputGroupAddon, Button } from 'reactstrap'
import React from 'react'

export default function SearchInput(props) {
  let displayClass = 'visible'

  if(!props.searchText && props.clearSearch) {
    displayClass = 'invisible'
  }

  return (
    <InputGroup className={props.className || 'search-box-with-x-x'}>
      <Input
        className='search-input'
        type="text"
        id="input1-group2"
        name="searchText"
        placeholder={props.placeholder || ''}
        value={props.searchText}
        onChange={props.onFieldChange}
        onKeyUp={(event) => event.key === 'Enter' ? props.onSearchSubmit() : null}
      />

      <InputGroupAddon addonType="prepend">
        <div className="clear-search" onClick={props.clearSearch}>
          <i className={`fa fa-close opacity-5 ${displayClass}`}></i>
        </div>
        <Button data-test="searchbtn" type="button" color="primary" className='search-button' onClick={props.onSearchSubmit}><i className="fa fa-search"></i> Search</Button>
      </InputGroupAddon>
    </InputGroup>
  )
}
