import React from 'react'
import { Input } from 'antd'
import { checkPermission } from '../../../../../common/utils/helpers'

export default function AddComment(props) {
  const { onChange, comment, minRows, maxRows } = props
  const { TextArea } = Input
  return (
    <>
      {checkPermission('reggora_review_action') && (
        <>
          <label className="add-comment-label">Add Comment</label>
          <div className='add-comment-textarea'>
            <TextArea
              autoSize={{
                minRows: minRows || 1,
                maxRows: maxRows || 5,
              }}
              value={comment}
              onChange={onChange}
            />
          </div>
        </>
      )}
    </>
  )
}
