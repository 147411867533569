import React from 'react'
import { ChatBubble } from 'react-chat-ui'
import { utcToLocal } from '../utils/helpers'

class ReggoraChatBubble extends React.Component {
  /**
   * ReggoraChatBubble component renders a single message.
   *
   * ReggoraChatBubble is passed into the ChatFeed component of the react-chat-ui library as the chatBubble prop.
   *
   * ReggoraChatBubble is not intended to be used with Unified Conversations, instead use the component of the same name in the reggora-conversation-ui package.
   */
  getBubbleStyle = () => {
    const defaultStyle = { padding: '3px 14px' }
    const pastConversationStyle = {
      padding: '3px 14px',
      opacity: '0.3',
    }
    const vendorNoteStyle = {
      backgroundColor: 'rgb(0, 132, 255)',
      padding: '3px 14px',
    }

    const { isPastAppraiserConversation, isVendorMessage } = this.props.message

    if (isPastAppraiserConversation) {
      return pastConversationStyle
    } else if (isVendorMessage) {
      return vendorNoteStyle
    } else {
      return defaultStyle
    }
  }

  render() {
    let timeStampAlign = 'text-left'
    let messageAlign = ''

    if (this.props.message.id === 0) {
      timeStampAlign = 'text-right'
    }

    // set up alignment for team-chat messages
    const {
      isTeamConversation,
      sentByCurrentUser,
      isVendorMessage,
      isInitialAmpAppraiserConversation,
    } = this.props.message

    if (isTeamConversation) {
      if (sentByCurrentUser) {
        timeStampAlign = 'text-right'
        messageAlign = 'd-flex justify-content-end'
      } else {
        timeStampAlign = 'text-left'
        messageAlign = 'd-flex'
      }
    }

    if (isVendorMessage) {
      timeStampAlign = 'text-right'
      messageAlign = 'd-flex justify-content-end'
    }

    if (isInitialAmpAppraiserConversation) {
      timeStampAlign = 'text-right'
      messageAlign = 'd-flex justify-content-end'
    }

    // If we got passed an array, then we have some timeline stuff, if not then weve got a real message
    if (!Array.isArray(this.props.message)) {
      // Message Case
      return (
        <div>

          {/* Time Stamp */}
          {this.props.message.showUserSent ? <div className={`lender-chat-message ${timeStampAlign}`}>{this.props.message.senderName}</div> : null}

          {/* Actual Message */}
          <div className={`pb-2 message-bubble-wrapper ${messageAlign}`}>
            <ChatBubble
              bubbleStyles={{ chatbubble: this.getBubbleStyle() }}
              message={this.props.message}
            />
          </div>

          {/* Read Receipts */}
          <div className='lender-chat-message'>
            {this.props.message.showReadReceipts ? (
              this.props.message.readReceipts.length > 0 ? this.props.message.readReceipts.map((readReceipt, idx) => (
                // eslint-disable-next-line react/jsx-key
                <div>
                  <small className='read-receipt-text' key={idx}>
                    {`${readReceipt.user.firstname} ${
                      readReceipt.user.lastname} Read (${utcToLocal(readReceipt.timestamp).fromNow()})`}
                  </small>
                  {idx < this.props.message.readReceipts.length && <br />}
                </div>
              )) : this.props.message.id === 0 ? <small>Delivered</small> : '') : ''}
          </div>

        </div>)
    } else {
      // Timeline Event Case
      return <ul className='reggora-timeline'>{this.props.message}</ul>
    }
  }
}

export default ReggoraChatBubble
