import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { connectResource } from '../../common/utils/resource'
import Loader from '../../common/layout/components/Loader'
import FileUploader from '../../common/forms/inputs/FileUploader'
import { updateOrder } from '../../store/reducers/order/orderSlice'

class SingleEvault extends Component {
  state = {
    files: [],
    tempFiles: [],
    fileUploading: false,
    pAndSUploading: false,
    existing_files: [],
    showConfirmationModal: false,
    evaultId: null,
  }

  componentWillMount() {
    const { suppliedEvault } = this.props

    const existing_files = suppliedEvault.documents
    const evaultId = suppliedEvault.id

    this.setState({ existing_files, evaultId })
  }

  onFilesChange = files => {
    this.setState({
      files: files,
    })
  }

  onFileSubmit = files => {
    const { existing_files, evaultId } = this.state

    this.setState({ fileUploading: true })
    this.props.evault
      .put({ file: files, id: evaultId })
      .then(data => {
        existing_files.push(data.success)
        this.setState({ fileUploading: false, existing_files })
      })
      .catch(_ => {
        this.setState({
          fileUploading: false,
        })
      })
  }

  existingFileRemove = (idx, file, p_and_s) => {
    const { existing_files, evaultId } = this.state
    let new_files = existing_files

    const id = evaultId
    new_files = JSON.parse(JSON.stringify(existing_files))
    new_files.splice(idx, 1)
    this.setState({ fileUploading: true })

    this.props.evault
      .remove({ id: id, document_id: file.id })
      .then(_ => {
        this.setState({
          existing_files: new_files,
          fileUploading: false,
        })
      })
      .catch(_ => {
        this.setState({
          fileUploading: false,
        })
      })
  }

  fileRemove = (idx, file) => {
    const { files } = this.state

    files.splice(idx, 1)

    this.setState({
      files,
    })
  }

  onFilesError = event => {}

  render() {
    const { files, existing_files } = this.state
    if (this.state.fileUploading) {
      return (
        <div className='d-flex flex-column m-5'>
          <Loader />
        </div>
      )
    }
    return (
      <Fragment>
        <div className='flex-column' style={{ width: '100%' }}>
          <div className='mt-3'>
            <h4>Files:</h4>
            <div className='file-box'>
              <FileUploader
                existing_files={existing_files}
                files={files}
                onFilesChange={this.onFileSubmit}
                fileRemove={this.fileRemove}
                existingFileRemove={this.existingFileRemove}
                onFilesError={this.onFilesError}
                hasDownloadPermission={true}
                hasUploadPermission={true}
                hasRemovePermission={true}
                showEmptyMessage
              />
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    currentUserID: state.resource.user.data.id,
    is_review_appraiser: state.resource.user.data.is_review_appraiser,
  }
}

export default compose(
  connectResource({
    namespace: 'evault',
    endpoint: 'order/evault',
    prefetch: false,
    navigateAfterSubmit: false,
    excludeID: true,
    apiVersion: 2,
    successMessage: {
      PUT: 'Your file has been uploaded to the evault.',
      DELETE: 'The file has been removed from the evault',
    },
  }),
  connect(
    mapStateToProps,
    { updateOrder }
  )
)(SingleEvault)
