import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
/*
  Inspired by the `modalRender` custom renderer that rc-dialog provides, which Antd exposes for use.
  See https://github.com/react-component/dialog/blob/master/src/Dialog/Content/Panel.tsx
*/

const ant = 'ant'
const component = 'modal'
const prefixCls = `${ant}-${component}`

const headerPropTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  ariaId: PropTypes.string,
  className: PropTypes.string,
  styles: PropTypes.any,
}

const ModalHeader = ({ title, children, className, styles, ariaId }) => {
  return (
    <div className={classNames(`${prefixCls}-header`, className)} style={{ ...styles }}>
      <div className={`${prefixCls}-title`} id={ariaId}>
        {title}
      </div>
      {children}
    </div>
  )
}
ModalHeader.propTypes = headerPropTypes

const footerPropTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  styles: PropTypes.any,
}

const ModalFooter = ({ children, className, styles }) => {
  return (
    <div className={classNames(`${prefixCls}-footer`, className)} style={{ ...styles }}>
      {children}
    </div>
  )
}

ModalFooter.propTypes = footerPropTypes

const bodyPropTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  styles: PropTypes.any,
}

const ModalBody = ({ children, className, styles }) => {
  const bodyProps = {} // TODO: Find where this comes from

  return (
    <div className={classNames(`${prefixCls}-body`, className)} style={{ ...styles }} {...bodyProps}>
      {children}
    </div>
  )
}

ModalBody.propTypes = bodyPropTypes

const closerPropTypes = {
  onClose: PropTypes.func,
  closeIcon: PropTypes.node,
}
const ModalCloser = ({ onClose, closeIcon }) => {
  return (
    <button type="button" onClick={onClose} aria-label="Close" className={`${prefixCls}-close`}>
      {closeIcon || <span aria-hidden="true" className={`${prefixCls}-close-x`} style={{ fontSize: '21px', lineHeight: '56px' }}>x</span>}
    </button>
  )
}
ModalCloser.propTypes = closerPropTypes

export { ModalHeader, ModalFooter, ModalBody, ModalCloser }
