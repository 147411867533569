import React from 'react'
import { Button } from 'reactstrap'
import { DownOutlined } from '@ant-design/icons'
import { Dropdown, Menu } from 'antd'

const OrderActions = (props) => {
  const {
    menu,
  } = props

  return (
    <Dropdown
      overlay={<Menu>{menu}</Menu>}
      trigger={['click']}
      placement="bottomRight"
    >
      <Button className="order-action-buttons-padding">
        {' '}
        Order Actions <DownOutlined />
      </Button>
    </Dropdown>
  )
}

export default OrderActions
