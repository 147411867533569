import React from 'react'
import { Container, Row, Col, CardGroup } from 'reactstrap'


export default function EmptyLayout(props) {
  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="6" style={{ maxWidth: 416 }}>
            <CardGroup>
              {props.children}
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
